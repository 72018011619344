import React from 'react'
import SignDistributionSection from './components/SignDistributionSection'
import { MscLocationsProvider } from '../../context/mscLocationsContext'

const DistributionSection = (props: any) => (
  <MscLocationsProvider>
    <SignDistributionSection {...props} />
  </MscLocationsProvider>
)

export default DistributionSection
