import { useFormik } from 'formik'
import { KitRequest } from '../../../../../models/kits/KitRequest.model'

export const useKitDetailsForm = (
  kitRequest: KitRequest,
  kitInfoId: string,
  updateKitRequest: (kitInfoId: string, req: KitRequest) => void,
) => {
  const currentKitRequest = new KitRequest(kitRequest)

  return useFormik({
    enableReinitialize: true,
    initialValues: currentKitRequest,
    onSubmit(values) {
      const kit = new KitRequest({ ...values })
      updateKitRequest(kitInfoId, kit)
    },
  })
}
