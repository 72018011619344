import React, { Fragment, useState } from 'react'
import { Grid, Heading, Button, Form } from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { useSignDetailsContext } from '../../../context/signDetailsContext'
import { useSignBulkEditContext } from 'components/SignBulkEdit/context/signBulkEditContext'
import SignBulkCheckbox from '../../../../SignBulkEdit/components/SignBulkCheckbox'
import StoreCountModal from '../../modals/StoreCountModal'
import { getVendorOptions } from 'components/App/helpers/vendorHelpers'
import { SIGN_DETAILS_SECTION } from '../../../constants/signDetailsConstants'
import { useUserContext } from 'components/App/context/userContext'
import { useSignFormsContext } from '../../../context/signFormsContext'
import { fetchKitGroups } from '../../../helpers/kitGroupHelpers'
import { find } from 'lodash'
import { KitGroupQuantity } from '../../../../../models/signs/DistributionResponse.model'
import { useAppContext } from 'components/App/context/appContext'
import SignResponse from '../../../../../models/signs/SignResponse.model'
import EnterpriseIcon, { ProcessingIcon } from '@enterprise-ui/icons'
import { isEditable } from 'components/App/helpers/signEditHelper'

export interface Props {
  handleChange: (id: string, value: string) => void
  isBulkEdit?: boolean
  selectedSigns?: SignResponse[]
}

const SignKittingForm = ({
  handleChange,
  isBulkEdit,
  selectedSigns,
}: Props) => {
  const { setPageHasChanges } = useAppContext()!
  const {
    isInProject,
    currentSign,
    pogLocations,
    getPogLocations,
    isLoadingLocations,
    addToModifiedSections,
    project,
  } = useSignDetailsContext()!
  const {
    bulkEditFields,
    addBulkEditField,
    removeBulkEditField,
    selectedFields,
  } = useSignBulkEditContext()!
  const [storeModalVisible, setStoreModalVisible] = useState(false)
  const [kitGroupOptions, setkitGroupOptions] = useState()
  const { signDistributionFormik, signInfoFormik } = useSignFormsContext()!
  const { userPermissions, userCompany, userType } = useUserContext()!

  const [kitGroups, setKitGroups] = useState(
    signDistributionFormik.values.kit_groups?.map((kit) => ({
      id: kit,
      value: kit,
      label: kit,
    })),
  )

  const [clearFields, setClearFields] = useState<string[]>([])

  const handleKitGroupChange = (id: string, value: any) => {
    if (value.length < signDistributionFormik.values.kit_groups?.length) {
      signDistributionFormik.setFieldValue(
        'kit_group_quantities',
        signDistributionFormik.values.kit_group_quantities?.filter(
          (group: any) =>
            value.map((group: any) => group.id).includes(group.group_id),
        ),
      )
    } else {
      signDistributionFormik.setFieldValue('kit_group_quantities', [
        ...signDistributionFormik.values.kit_group_quantities!,
        new KitGroupQuantity({
          group_id: value.filter(
            (group: any) =>
              !signDistributionFormik.values.kit_groups.includes(group.id),
          )[0].value,
          quantity: 0,
        }),
      ])
    }
    setKitGroups(value)
    handleChange(
      id,
      value.map((kit: any) => kit.value),
    )
  }

  const handleBulkEditKitGroup = (id: string, value: any) => {
    setKitGroups(value)
    if (value.length > 0) {
      addBulkEditField('distribution', {
        ...bulkEditFields.distribution,
        kit_groups: value.map((kit: any) => kit.value),
        kit_group_quantities: value.map((kit: any) => ({
          group_id: kit.value,
          quantity: 0,
        })),
      })
    }
  }

  const handleBulkEditClear = (id: string, value: any) => {
    if (id === 'clear_kit_groups') {
      setKitGroups(
        signDistributionFormik.values.kit_groups?.map((kit) => ({
          id: kit,
          value: kit,
          label: kit,
        })),
      )
      if (value) {
        clearFields.push('clear_kit_groups')
        setClearFields(clearFields)
        addBulkEditField('distribution', {
          ...bulkEditFields.distribution,
          kit_groups: [],
          kit_group_quantities: [],
        })
      } else {
        clearFields.splice(clearFields.indexOf('clear_kit_groups'), 1)
        setClearFields(clearFields)
        removeBulkEditField('distribution.kit_groups')
      }
    } else {
      handleChange('archway_quantity', '')
      if (value) {
        clearFields.push('clear_archway_quantity')
        setClearFields(clearFields)
        addBulkEditField('distribution', {
          ...bulkEditFields.distribution,
          archway_quantity: null,
        })
      } else {
        clearFields.splice(clearFields.indexOf('clear_archway_quantity'), 1)
        setClearFields(clearFields)
        removeBulkEditField('distribution.archway_quantity')
      }
    }
  }

  const handleKitGroupQtyChange = (value: number, kitGroup: string) => {
    signDistributionFormik.setFieldValue(
      'kit_group_quantities',
      signDistributionFormik.values.kit_group_quantities?.map(
        (group: KitGroupQuantity) =>
          group.group_id === kitGroup
            ? { group_id: group.group_id, quantity: value }
            : group,
      ),
    )
    addToModifiedSections(SIGN_DETAILS_SECTION.DISTRIBUTION)
    setPageHasChanges(true)
  }

  const getKitGroupOptions = async (vendorId: string) => {
    if (!kitGroupOptions) {
      const temp = await fetchKitGroups(currentSign.project_id!, vendorId)
      setkitGroupOptions(temp)
      return temp
    } else {
      return kitGroupOptions
    }
  }

  const isDisabled = (field: string) => {
    if (isBulkEdit) {
      if (selectedSigns && selectedSigns.length > 0) {
        for (var i = 0; i < selectedSigns.length; i++) {
          if (
            !isEditable(
              selectedSigns[i],
              field,
              userPermissions,
              userCompany,
              userType,
              isInProject,
              project.workflow.status,
              undefined,
              project,
            )
          ) {
            return true
          }
        }
        return false
      }
      return true
    } else {
      return !isEditable(
        currentSign,
        field,
        userPermissions,
        userCompany,
        userType,
        isInProject,
        project.workflow.status,
        undefined,
        project,
      )
    }
  }

  return (
    <Grid.Item xs={6}>
      <Grid.Container
        align="center"
        className={`sem_StandardForm ${
          isInProject ? 'hc-mb-normal error_messaging_enabled' : ''
        }`}
      >
        <Grid.Item xs={isBulkEdit ? 12 : 6}>
          <Autocomplete
            id="kitting_vendor"
            label="Kitting Vendor"
            rightContent={
              isBulkEdit && (
                <SignBulkCheckbox
                  fieldType="distribution.kitting_vendor"
                  rightContent={true}
                  disabled={isDisabled('kitting_vendor')}
                />
              )
            }
            disabled={
              isBulkEdit
                ? !selectedFields.includes('distribution.kitting_vendor')
                : isDisabled('kitting_vendor')
            }
            options={(query: string) =>
              getVendorOptions(
                query,
                signDistributionFormik.values.kitting_vendor,
              )
            }
            value={{
              value: signDistributionFormik.values.kitting_vendor,
              label: signDistributionFormik.values.kitting_vendor,
            }}
            onUpdate={(id: string, value: any) => {
              if (
                value &&
                value.value !== signDistributionFormik.values.kitting_vendor
              ) {
                signDistributionFormik.setFieldValue(
                  'kitting_vendor_id',
                  value.id,
                )
                handleChange(id, value.value)
                if (isBulkEdit) {
                  addBulkEditField('distribution', {
                    kitting_vendor: value.value,
                    kitting_vendor_id: value.id,
                  })
                }
              }
            }}
          />
        </Grid.Item>
        {isBulkEdit && (
          <Fragment>
            <Grid.Item xs={8}>
              <Autocomplete
                id="kit_groups"
                label="Kit Groups"
                disabled={
                  !selectedFields.includes('distribution.kit_groups') ||
                  clearFields.includes('clear_kit_groups')
                }
                allowCustomInputValue
                multiselect
                options={() =>
                  getKitGroupOptions(
                    bulkEditFields.distribution?.kitting_vendor_id,
                  )
                }
                value={kitGroups}
                onUpdate={(id: string, value: any) =>
                  handleBulkEditKitGroup(id, value)
                }
              />
            </Grid.Item>
            <Grid.Item xs={4} style={{ marginTop: '-16px' }}>
              <SignBulkCheckbox
                fieldType="distribution.kit_groups"
                disabled={isDisabled('kit_groups')}
              />
              <Form.Field
                id="clear_kit_groups"
                type="chip"
                label="Clear Kit Groups"
                disabled={!selectedFields.includes('distribution.kit_groups')}
                onChange={(e: any) => {
                  handleBulkEditClear('clear_kit_groups', e.target.checked)
                }}
              />
            </Grid.Item>
            <Grid.Item xs={7}>
              <Form.Field
                type="number"
                id="archway_quantity"
                label="Archway Quantity"
                value={signDistributionFormik.values.archway_quantity}
                disabled={
                  !selectedFields.includes('distribution.archway_quantity') ||
                  clearFields.includes('clear_archway_quantity') ||
                  selectedSigns!.some((sign: SignResponse) => {
                    return (
                      (sign.sign_type === 'Value Sign' ||
                        sign.sign_type === 'Display Sign') &&
                      sign.messaging.sign_size !== 'OTHER'
                    )
                  })
                }
                onChange={(e: any) => {
                  handleChange(e.target.id, e.target.value)
                  addBulkEditField(
                    'distribution.archway_quantity',
                    parseInt(e.target.value),
                  )
                }}
              />
            </Grid.Item>
            <Grid.Item xs={5} style={{ marginTop: '-16px' }}>
              <SignBulkCheckbox
                fieldType="distribution.archway_quantity"
                disabled={isDisabled('archway_quantity')}
              />
              <Form.Field
                id="clear_archway_quantity"
                type="chip"
                label="Clear Archway Quantity"
                disabled={
                  !selectedFields.includes('distribution.archway_quantity')
                }
                onChange={(e: any) => {
                  handleBulkEditClear(
                    'clear_archway_quantity',
                    e.target.checked,
                  )
                }}
              />
            </Grid.Item>
          </Fragment>
        )}
        {!isBulkEdit && (
          <Fragment>
            <Grid.Item xs={6}>
              <Form.Field
                type="checkbox"
                id="kit_by_itself"
                label="Kit by Itself"
                checked={signDistributionFormik.values.kit_by_itself}
                disabled={isDisabled('kit_by_itself')}
                onChange={(e: any) =>
                  handleChange(e.target.id, e.target.checked)
                }
              />
            </Grid.Item>
            <Grid.Item xs={12}>
              <Autocomplete
                id="kit_groups"
                label="Kit Groups"
                allowCustomInputValue
                multiselect
                maxSelectedOptions={
                  signInfoFormik.values.on_pog_sign ? 1 : undefined
                }
                maxSelectedOptionsText="On POG Signs may only select 1 Kit Group."
                disabled={isDisabled('kit_groups')}
                options={() =>
                  getKitGroupOptions(
                    signDistributionFormik.values.kitting_vendor_id,
                  )
                }
                value={kitGroups}
                onUpdate={(id: string, value: any) => {
                  if (value && value.length !== kitGroups.length) {
                    handleKitGroupChange(id, value)
                  }
                }}
              />
            </Grid.Item>
            {!signInfoFormik.values.on_pog_sign &&
              signDistributionFormik.values.kit_groups.length > 0 && (
                <Grid.Item className="hc-pb-normal" xs={12}>
                  <Grid.Container
                    className={`sem_StandardForm ${
                      isInProject ? 'error_messaging_enabled' : ''
                    }`}
                  >
                    {signDistributionFormik.values.kit_groups.map(
                      (kit: string, index: number) => (
                        <Grid.Item xs={3} key={index}>
                          <Form.Field
                            type="number"
                            id="kit_group_quantities"
                            label={`${kit} Quantity`}
                            value={
                              signDistributionFormik.values
                                .kit_group_quantities &&
                              find(
                                signDistributionFormik.values
                                  .kit_group_quantities,
                                { group_id: kit },
                              )?.quantity
                            }
                            onChange={(e: any) =>
                              handleKitGroupQtyChange(
                                parseInt(e.target.value),
                                kit,
                              )
                            }
                          />
                        </Grid.Item>
                      ),
                    )}
                  </Grid.Container>
                </Grid.Item>
              )}
            <Grid.Item xs={6}>
              <Form.Field
                id="max_quantity_per_kit"
                label="Max Quantity Per Kit"
                value={
                  signDistributionFormik.values.max_quantity_per_kit !== null
                    ? signDistributionFormik.values.max_quantity_per_kit
                    : ''
                }
                disabled={isDisabled('max_quantity_per_kit')}
                onChange={(e: any) => handleChange(e.target.id, e.target.value)}
                error={
                  signDistributionFormik.errors.max_quantity_per_kit !==
                  undefined
                }
                errorText={signDistributionFormik.errors.max_quantity_per_kit}
              />
            </Grid.Item>
            <Grid.Item xs={6}>
              <Form.Field
                type="checkbox"
                id="include_new_stores"
                label="Include New Stores"
                checked={signDistributionFormik.values.include_new_stores}
                disabled={isDisabled('include_new_stores')}
                onChange={(e: any) =>
                  handleChange(e.target.id, e.target.checked)
                }
              />
            </Grid.Item>
            <Grid.Item xs={4}>
              <Form.Field
                type="number"
                id="archway_quantity"
                label="Archway Quantity"
                value={signDistributionFormik.values.archway_quantity}
                error={
                  !signDistributionFormik.values.archway_quantity &&
                  signDistributionFormik.values.archway_quantity !== 0 &&
                  currentSign.distribution.archway_quantity !== undefined
                }
                errorText={signDistributionFormik.errors.archway_quantity}
                highlight={
                  !signDistributionFormik.values.archway_quantity &&
                  signDistributionFormik.values.archway_quantity !== 0 &&
                  currentSign.distribution.archway_quantity !== undefined
                }
                disabled={isDisabled('archway_quantity')}
                onChange={(e: any) => handleChange(e.target.id, e.target.value)}
              />
            </Grid.Item>
            <Grid.Item xs={4}>
              <Form.Field
                type="number"
                id="nrsc_quantity"
                label="NRSC Quantity"
                value={signDistributionFormik.values.nrsc_quantity}
                disabled
              />
            </Grid.Item>
            <Grid.Item xs={4}>
              <Form.Field
                type="number"
                id="totalQuantity"
                label="Total Quantity"
                value={
                  (signDistributionFormik.values.nrsc_quantity
                    ? Number(signDistributionFormik.values.nrsc_quantity)
                    : 0) +
                  (signDistributionFormik.values.archway_quantity
                    ? Number(signDistributionFormik.values.archway_quantity)
                    : 0)
                }
                disabled
                onChange={() => {}}
              />
            </Grid.Item>
            <Grid.Item className="hc-pr-dense">
              <Heading className="hc-clr-grey02" size={6}>
                Total Store Count:
              </Heading>
            </Grid.Item>
            <Grid.Item xs={3} className="hc-pl-none">
              <Button
                type="primary"
                size="dense"
                disabled={signDistributionFormik.values.locations_count === 0}
                fullWidth
                onClick={() => {
                  getPogLocations()
                  setStoreModalVisible(true)
                }}
              >
                <Heading className="hc-clr-contrast-weak" size={6}>
                  {signDistributionFormik.values.locations_count}
                </Heading>
              </Button>
              <StoreCountModal
                locations={pogLocations}
                isVisible={storeModalVisible}
                toggleVisible={setStoreModalVisible}
                isLoadingLocations={isLoadingLocations}
              />
            </Grid.Item>
            <Grid.Item>
              {!isBulkEdit &&
                currentSign.distribution.location_update_status
                  ?.location_updated === false && (
                  <EnterpriseIcon
                    icon={ProcessingIcon}
                    pulse={true}
                    size="inline"
                  />
                )}
            </Grid.Item>
          </Fragment>
        )}
      </Grid.Container>
    </Grid.Item>
  )
}

export default SignKittingForm
