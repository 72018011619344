import React, { useEffect, useState } from 'react'
import {
  Grid,
  Card,
  Tooltip,
  Caption,
  useToaster,
} from '@enterprise-ui/canvas-ui-react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { BlueprintDetail } from '../../../../../models/blueprints/BlueprintDetail.model'
import {
  getHealthChipColor,
  getStatusChipColor,
} from 'components/App/helpers/userColor'
import axios from 'axios'
import { DASHBOARD_SERVICE_API_DOMAIN_URL } from 'components/App/constants/appConstants'
import { useEnv } from '@praxis/component-runtime-env'
import { BlueprintSummary } from '../../../../../models/dashboardV2/BlueprintSummary.model'
import { TOASTER_DEFAULTS } from 'components/App/constants/appConstants'
import RubixChip from 'components/common/RubixChip'

export interface Props {
  currentBlueprint: BlueprintDetail
}
export const BlueprintOverviewCard = ({ currentBlueprint }: Props) => {
  const navigate = useNavigate()
  const env = useEnv()
  const makeToast = useToaster()
  const [blueprintSummary, setBlueprintSummary] = useState(
    new BlueprintSummary(),
  )
  // once the blueprint is created, redirect to that blueprint's proper URL
  useEffect(() => {
    if (
      currentBlueprint.blueprint_id &&
      (window.location.pathname === '/blueprints/create' ||
        window.location.pathname.includes('duplicate'))
    ) {
      navigate(`/blueprints/${currentBlueprint.blueprint_id}`)
    }
    const getBlueprintSummary = async () => {
      try {
        const response = await axios.get(
          `${env.apiDomainUrl + DASHBOARD_SERVICE_API_DOMAIN_URL}/blueprints/${
            currentBlueprint.blueprint_id
          }`,
          {
            headers: { 'Content-Type': 'application/json' },
          },
        )
        response.data &&
          setBlueprintSummary(new BlueprintSummary(response.data))
      } catch (err: any) {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'error',
          heading: 'Failed to Get Blueprint Summary',
          message: err.response.data.message,
        })
      }
    }
    currentBlueprint.blueprint_id && getBlueprintSummary()
  }, [currentBlueprint, navigate, env.apiDomainUrl, makeToast])

  if (
    currentBlueprint.blueprint_id !== undefined &&
    currentBlueprint.blueprint_id !== ''
  ) {
    return (
      <Grid.Item xs={12}>
        <Card className="sem_OverviewCard" elevation={0}>
          <div className="hc-pa-normal hc-pv-none">
            <Grid.Container
              className="sem_Overview"
              align="center"
              justify="space-between"
            >
              <Grid.Item className="hc-pt-none">
                <Caption
                  uppercase
                  above="Initial Set Date "
                  className="hc-pt-normal"
                >
                  {currentBlueprint.set_date &&
                  moment(currentBlueprint.set_date).isValid()
                    ? moment(currentBlueprint.set_date).format('MM/DD/YYYY')
                    : ''}
                </Caption>
              </Grid.Item>
              <Grid.Item className="hc-pt-none">
                <Caption
                  uppercase
                  above="Campaign Type "
                  className="hc-pt-normal"
                >
                  {currentBlueprint.campaign?.name
                    ? currentBlueprint.campaign?.name
                    : 'None'}
                </Caption>
              </Grid.Item>
              <Grid.Item className="hc-pt-none">
                <Caption
                  uppercase
                  above="Blueprint Type "
                  className="hc-pt-normal"
                >
                  {currentBlueprint.type ? currentBlueprint.type : 'None'}
                </Caption>
              </Grid.Item>
              <Grid.Item className="hc-pt-none">
                <Caption
                  uppercase
                  above="Blueprint Status"
                  className="hc-pt-normal"
                >
                  <RubixChip
                    data-testid="statusChip"
                    color={getStatusChipColor(currentBlueprint.status)}
                  >
                    {currentBlueprint.status}
                  </RubixChip>
                </Caption>
              </Grid.Item>
              {blueprintSummary.current_milestone && (
                <Grid.Item className="hc-pt-none">
                  <Caption
                    uppercase
                    above="Current Phase "
                    className="hc-pt-normal"
                  >
                    <Tooltip
                      location="bottom"
                      content="Current Blueprint Phase"
                    >
                      <RubixChip
                        data-testid="statusChip"
                        color={getHealthChipColor(blueprintSummary.health)}
                      >
                        {blueprintSummary.current_milestone.project_phase ||
                          'None'}
                      </RubixChip>
                    </Tooltip>
                  </Caption>
                </Grid.Item>
              )}
              {blueprintSummary.current_milestone && (
                <Grid.Item className="hc-pt-none">
                  <Caption
                    uppercase
                    above="Current Milestone"
                    className="hc-pt-normal"
                  >
                    <RubixChip
                      data-testid="statusChip"
                      color={getHealthChipColor(blueprintSummary.health)}
                    >
                      {blueprintSummary.current_milestone.name || 'None'}
                    </RubixChip>
                  </Caption>
                </Grid.Item>
              )}
            </Grid.Container>
          </div>
        </Card>
      </Grid.Item>
    )
  } else {
    return <div></div>
  }
}

export default BlueprintOverviewCard
