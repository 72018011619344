import React from 'react'
import SignResponse from '../../../../../../../models/signs/SignResponse.model'

export interface Props {
  value: number
  data: SignResponse
}

const SetQuantityCell = ({ value, data }: Props) => (
  <div className="ag-cell-value">
    {data.unit_of_measure === 'ST' ? value : 'N/A'}
  </div>
)

export default SetQuantityCell
