import React, { Fragment, useEffect, useState } from 'react'
import {
  Avatar,
  Button,
  Card,
  Grid,
  Input,
  Tooltip,
  Form,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { CheckIcon, TrashIcon } from '@enterprise-ui/icons'
import { DateFormatter } from '@enterprise-ui/canvas-ui-react-date'
import moment from 'moment'
import { MilestoneTemplate } from '../../../../../models/milestones/MilestoneTemplate.model'
import { useProjectMilestoneTabContext } from '../context/projectMilestoneTabContext'
import { useProjectDetailsContext } from '../../../context/projectDetailsContext'
import { useUserContext } from 'components/App/context/userContext'
import { getMilestoneStatusChipColor } from 'components/App/helpers/userColor'
import { useAppContext } from 'components/App/context/appContext'
import { useMilestoneContext } from 'components/App/context/milestoneContext'
import {
  InternalUser,
  RosterUser,
} from '../../../../../models/roster/RosterUser.model'
import { BusinessPartner } from '../../../../../models/app/BusinessPartner.model'
import { DialogProps } from '../../../../../models/app/DialogProps.model'
import RubixChip from 'components/common/RubixChip'

export interface Props {
  milestone: MilestoneTemplate
}

export const ProjectMilestoneCard = ({ milestone }: Props) => {
  const { currentProject } = useProjectDetailsContext()!
  const { updateMilestone, saveProjectMilestones, projectMilestone } =
    useProjectMilestoneTabContext()!
  const { modifiedMilestoneIds, addToModifiedMilestoneIds } =
    useMilestoneContext()!
  const { setPageHasChanges, setDialogProps } = useAppContext()!
  const { userName, userPermissions, userEmail, userCompany } =
    useUserContext()!
  const [editDueDate, setEditDueDate] = useState(false)
  const [dueDate, setDueDate] = useState(
    moment(currentProject.set_date)
      .subtract(milestone.days_out, 'days')
      .format('yyyy-MM-DD'),
  )
  const [riskDate, setRiskDate] = useState(
    moment(currentProject.set_date).subtract(milestone.risk_days_out, 'days'),
  )

  const weeksOut = milestone.days_out
    ? milestone.days_out < 0
      ? 0
      : Math.floor(Math.abs(milestone.days_out) / 7)
    : 0

  const roleInfo = currentProject.roster
    ? currentProject.roster.filter(
        (roles: any) => roles.title === milestone.role,
      )[0]
    : null

  useEffect(() => {
    setRiskDate(
      moment(currentProject.set_date).subtract(milestone.risk_days_out, 'days'),
    )
  }, [currentProject.set_date, milestone.risk_days_out])

  useEffect(() => {
    setDueDate(
      moment(currentProject.set_date)
        .subtract(milestone.days_out, 'days')
        .format('yyyy-MM-DD'),
    )
  }, [currentProject.set_date, milestone.days_out])

  return (
    <Card
      className={`sem-MilestoneTemplateCard ${
        modifiedMilestoneIds.has(milestone.milestone_id) ? 'modified' : ''
      } hc-ph-normal`}
    >
      <Grid.Container
        align="center"
        justify="center"
        className="sem-MilestoneCardContainer"
      >
        <Grid.Item xs={1}>
          {!milestone.information_only &&
            (userPermissions.isProjectEditor ||
              (roleInfo &&
                roleInfo.users &&
                roleInfo.users.some((user: RosterUser) =>
                  user.type === 'INTERNAL'
                    ? (user as InternalUser).email === userEmail
                    : (user as BusinessPartner).name === userCompany,
                ))) &&
            currentProject.workflow.status !== 'Archive' &&
            currentProject.workflow.status !== 'Cancel' && (
              <Tooltip
                location="right"
                content={
                  milestone.status === 'Completed'
                    ? 'Completed'
                    : 'Mark As Complete'
                }
              >
                {/* Complete Button */}
                <Input.Checkbox
                  id={`${milestone.milestone_id} completed`}
                  checked={milestone.status === 'Completed'}
                  disabled={
                    milestone.auto_check && !userPermissions.isProjectAdmin
                  }
                  onChange={(e: any) => {
                    if (milestone.status !== 'Completed') {
                      updateMilestone({
                        ...milestone,
                        status: 'Completed',
                      })
                      addToModifiedMilestoneIds(milestone.milestone_id)
                      setPageHasChanges(true)
                    } else {
                      updateMilestone({
                        ...milestone,
                        status: undefined,
                      })
                    }
                    addToModifiedMilestoneIds(milestone.milestone_id)
                    setPageHasChanges(true)
                  }}
                />
              </Tooltip>
            )}
        </Grid.Item>
        <Grid.Item
          className={`hc-pr-none hc-pl-none hc-pb-none ${
            editDueDate ? 'hc-pt-normal' : 'hc-pt-none'
          }`}
          xs={true}
        >
          <Grid.Container spacing="none">
            <Grid.Item
              className={`hc-clr-grey02 hc-pr-none hc-pl-none hc-pb-min ${
                milestone.information_only && 'hc-pt-min'
              }`}
              xs={12}
            >
              <p className="title">{milestone.name}</p>
            </Grid.Item>
            <Grid.Item xs={12}>
              <p className="hc-clr-grey02 subtitle">{milestone.role}</p>
            </Grid.Item>
            <Grid.Item xs={12}>
              <p className="hc-clr-grey02 subtitle">{milestone.depends_on}</p>
            </Grid.Item>
            <Grid.Item className="hc-pr-none hc-pl-none" xs={12}>
              <p className={`hc-clr-grey02 ${''}`}>
                {milestone?.assigned_to?.map((user: string) => {
                  return (
                    user.length > 0 &&
                    user.substr(
                      0,
                      user.indexOf('@') >= 0 ? user.indexOf('@') : user.length,
                    ) + ' '
                  )
                })}
              </p>
            </Grid.Item>
            <Grid.Item xs={12}>
              <p
                className={`hc-clr-grey02 italicized ${
                  !milestone.information_only ? 'hc-pb-dense' : ''
                }`}
              >
                {`${
                  milestone.status === 'Past Due'
                    ? milestone.status_description
                    : milestone.status
                } ${
                  milestone.status === 'Completed' &&
                  moment(milestone.completed_date).isValid()
                    ? `${moment(milestone.completed_date).format('MM/DD/YYYY')}`
                    : ''
                }`}
              </p>
            </Grid.Item>
            {milestone.information_only && (
              <Grid.Item
                className={`hc-clr-grey02 hc-pr-none hc-pl-none ${'hc-pb-normal'}`}
                xs={12}
              >
                <p className="hc-clr-grey03 italicized">Information Only</p>
              </Grid.Item>
            )}
          </Grid.Container>
        </Grid.Item>
        <Fragment>
          {!editDueDate || milestone.status === 'Completed' ? (
            <Grid.Item className="hc-pt-dense" align="left" xs={3}>
              <Grid.Container spacing="normal">
                <Grid.Item className="hc-pr-none hc-ml-none hc-pb-none" xs={12}>
                  <span
                    className={
                      userPermissions.isProjectAdmin &&
                      currentProject.workflow.status !== 'Archive' &&
                      currentProject.workflow.status !== 'Cancel'
                        ? 'click-pointer'
                        : ''
                    }
                    onClick={
                      userPermissions.isProjectAdmin &&
                      currentProject.workflow.status !== 'Archive' &&
                      currentProject.workflow.status !== 'Cancel' &&
                      milestone.status !== 'Completed'
                        ? () => setEditDueDate(true)
                        : () => setEditDueDate(false)
                    }
                  >
                    <RubixChip
                      color={getMilestoneStatusChipColor(
                        milestone.information_only,
                        milestone.status,
                        milestone.status_description,
                      )}
                    >
                      <span className="riskDate-Span">Due:</span>
                      <DateFormatter
                        format="MM/DD/YY"
                        // @ts-ignore
                        date={
                          milestone.days_out || milestone.days_out === 0
                            ? dueDate
                            : ''
                        }
                      />
                    </RubixChip>
                  </span>
                </Grid.Item>
                <Grid.Item className="hc-ml-none hc-pb-dense" xs={12}>
                  {milestone.risk && (
                    <RubixChip
                      color={getMilestoneStatusChipColor(
                        milestone.information_only,
                        milestone.status,
                        milestone.status_description,
                      )}
                    >
                      <span className="riskDate-Span">Risk:</span>

                      <DateFormatter
                        format="MM/DD/YY"
                        // @ts-ignore
                        date={
                          (milestone.risk_days_out ||
                            milestone.risk_days_out === 0) &&
                          riskDate
                        }
                      />
                    </RubixChip>
                  )}
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
          ) : (
            <Grid.Item className="hc-pr-none" xs={5}>
              <Grid.Container align="center" spacing="dense">
                <Grid.Item className="hc-pr-none hc-pt-normal" xs>
                  <Form.Field
                    type="date"
                    className="sem_InlineDatePicker"
                    id="due_date"
                    label="Due Date:"
                    value={dueDate}
                    onChange={(event: any) => {
                      if (event.target.value !== '') {
                        setDueDate(event.target.value)
                      }
                    }}
                    location="bottom-left"
                  />
                </Grid.Item>
                <Grid.Item className="hc-pr-none hc-pt-normal hc-pl-dense">
                  <Button
                    type="ghost"
                    iconOnly
                    className="sem-MilestoneBtnRemove"
                    onClick={() => {
                      const newDaysOut = moment(currentProject.set_date).diff(
                        dueDate,
                        'days',
                      )
                      if (milestone.risk) {
                        updateMilestone({
                          ...milestone,
                          days_out: newDaysOut,
                        })
                        addToModifiedMilestoneIds(milestone.milestone_id)
                        setPageHasChanges(true)
                      } else {
                        updateMilestone({
                          ...milestone,
                          days_out: newDaysOut,
                        })
                      }
                      addToModifiedMilestoneIds(milestone.milestone_id)
                      setPageHasChanges(true)
                      setEditDueDate(false)
                    }}
                  >
                    <EnterpriseIcon color="blue" icon={CheckIcon} />
                  </Button>
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
          )}
        </Fragment>
        <Grid.Item className="hc-pt-normal" align="center" xs>
          {!editDueDate &&
            weeksOut > 0 &&
            (milestone.days_out || milestone.days_out === 0) &&
            milestone.status !== 'Completed' && (
              <Tooltip location="bottom" content="Weeks Out">
                <Avatar
                  aria-label="Weeks Out"
                  size="expanded"
                  // @ts-ignore
                  color={getMilestoneStatusChipColor(
                    milestone.information_only,
                    milestone.status,
                    milestone.status_description,
                  )}
                >
                  <span aria-hidden="true">{weeksOut}</span>
                </Avatar>
              </Tooltip>
            )}
        </Grid.Item>
        {!editDueDate && (
          <Grid.Item align="right" xs={1}>
            <Grid.Container spacing="none" justify="flex-end">
              <Grid.Item xs={12}>
                {userPermissions.isProjectAdmin &&
                  currentProject.workflow.status !== 'Archive' &&
                  currentProject.workflow.status !== 'Cancel' && (
                    <Tooltip location="bottom" content="Delete">
                      <Button
                        aria-label="Remove milestone"
                        iconOnly
                        type="ghost"
                        data-testid="removeMilestone"
                        className="sem-MilestoneBtnRemove"
                        onClick={() =>
                          setDialogProps(
                            new DialogProps({
                              headingText:
                                'Are you sure you want to remove this milestone?',
                              approveButtonText: 'Yes',
                              onApprove: () => {
                                saveProjectMilestones(
                                  currentProject.project_id,
                                  projectMilestone.map(
                                    (ms: MilestoneTemplate) => {
                                      return ms.milestone_id ===
                                        milestone.milestone_id
                                        ? { ...ms, disabled: true }
                                        : ms
                                    },
                                  ),
                                  userName,
                                )
                                setDialogProps(new DialogProps())
                              },
                              refuseButtonText: 'Cancel',
                              onRefuse: () => setDialogProps(new DialogProps()),
                            }),
                          )
                        }
                      >
                        <EnterpriseIcon
                          size="sm"
                          color="gray"
                          icon={TrashIcon}
                        />
                      </Button>
                    </Tooltip>
                  )}
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
        )}
      </Grid.Container>
    </Card>
  )
}

export default ProjectMilestoneCard
