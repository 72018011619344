import React, { Fragment, useEffect, useState } from 'react'
import {
  Grid,
  Button,
  Form,
  Heading,
  List,
  Input,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { CancelIcon } from '@enterprise-ui/icons'
import { capitalize, get } from 'lodash'
import { useMscLocationsContext } from '../../context/mscLocationsContext'
import { useSignDetailsContext } from '../../context/signDetailsContext'
import { useSignBulkEditContext } from '../../../SignBulkEdit/context/signBulkEditContext'
import InfiniteScroll from '../../../App/components/InfiniteScroll'
import { StoreLocation } from '../../../../models/signs/SharedMscFacets.model'
import { SEARCH_RESPONSE_SIZE } from '../../../App/constants/appConstants'

export interface Props {
  modalCategory: string
  toggleVisible: (category: string) => void
  handleFormChange: (id: string, value: any) => void
}

const LocationsModal = ({
  modalCategory = '',
  toggleVisible,
  handleFormChange,
}: Props) => {
  const { sharedMscFacets } = useMscLocationsContext()!
  const { currentSign } = useSignDetailsContext()!
  const { addBulkEditField } = useSignBulkEditContext()!

  const [locations, setLocations] = useState<StoreLocation[]>([])
  const [storeIdFilter, setStoreIdFilter] = useState('')
  const [storeNameFilter, setStoreNameFilter] = useState('')
  const [selectedLocationIds, setSelectedLocationIds] = useState([] as any)
  // default page number to 1 since the first batch will be loaded on page load
  const [currentPageNumber, setCurrentPageNumber] = useState(1)

  useEffect(() => {
    setLocations(sharedMscFacets.locations.slice(0, SEARCH_RESPONSE_SIZE))
  }, [sharedMscFacets.locations])

  useEffect(() => {
    const locationsPath = `distribution.location_filter_info[${modalCategory}_location_info].locations`
    setSelectedLocationIds(get(currentSign, locationsPath, []))
  }, [modalCategory, currentSign])

  const getFilteredLocations = (
    storeNameFilterValue: string,
    storeIdFilterValue: string,
  ) => {
    const allFilteredByStoreName = sharedMscFacets.locations.filter(
      (location: StoreLocation) =>
        location.location_name !== undefined &&
        location.location_name
          .toString()
          .toUpperCase()
          .includes(storeNameFilterValue.toUpperCase()),
    )
    return allFilteredByStoreName.filter(
      (location: StoreLocation) =>
        location.location_id !== undefined &&
        location.location_id
          .toString()
          .toUpperCase()
          .includes(storeIdFilterValue.toUpperCase()),
    )
  }

  const handleChange = (id: string, value: string) => {
    let newLocations: any = []
    if (id === 'location_id') {
      setStoreIdFilter(value)
      newLocations = getFilteredLocations(storeNameFilter, value)
    }
    if (id === 'location_name') {
      setStoreNameFilter(value)
      newLocations = getFilteredLocations(value, storeIdFilter)
    }

    setCurrentPageNumber(1)
    setLocations(newLocations.slice(0, SEARCH_RESPONSE_SIZE))
  }

  const handleSelect = (id: string, checked: boolean) => {
    if (id === 'all') {
      if (checked) {
        const locationsToAdd = locations
          .filter(
            (location: any) =>
              !selectedLocationIds.includes(location.location_id.toString()),
          )
          .map((location: any) => location.location_id.toString())
        setSelectedLocationIds([...selectedLocationIds, ...locationsToAdd])
      } else {
        setSelectedLocationIds([])
      }
    } else {
      if (checked) {
        setSelectedLocationIds([...selectedLocationIds, id])
      } else {
        setSelectedLocationIds(
          selectedLocationIds.filter((location: any) => location !== id),
        )
      }
    }
  }

  const handleClose = () => {
    setStoreIdFilter('')
    setStoreNameFilter('')
    toggleVisible('')
  }

  const handleSave = () => {
    handleFormChange(
      `location_filter_info[${modalCategory}_location_info].locations`,
      selectedLocationIds.sort((a: any, b: any) => a - b),
    )
    addBulkEditField(
      `distribution.location_filter_info[${modalCategory}_location_info].locations`,
      selectedLocationIds,
    )
    setStoreIdFilter('')
    setStoreNameFilter('')
    toggleVisible('')
  }

  return (
    <Fragment>
      <div
        className={`C-Modal ${modalCategory ? 'isVisible' : ''} sem_ListModal`}
      >
        <div className="C-Modal__container">
          <Grid.Container
            align="flex-start"
            justify="space-between"
            direction="row-reverse"
          >
            <Grid.Item className="sem_Modal__close">
              <Button
                type="ghost"
                iconOnly
                size="dense"
                className="sem_IconBtn"
                onClick={() => handleClose()}
              >
                <EnterpriseIcon icon={CancelIcon} />
              </Button>
            </Grid.Item>
            <Grid.Item xs={true}>
              <Heading className="hc-clr-grey01" size={4}>
                {`${capitalize(modalCategory)} Store Locations`}
              </Heading>
            </Grid.Item>
          </Grid.Container>
          <Grid.Container className="hc-mv-normal">
            <Grid.Item xs={3}>
              <Input.Checkbox
                className="hc-clr-grey02 hc-pl-dense"
                id={`${modalCategory}-all`}
                label="Select All"
                onChange={(e: any) =>
                  handleSelect(e.target.id.split('-')[1], e.target.checked)
                }
              />
            </Grid.Item>
            <Grid.Item xs={2}>
              <Form.Field
                id="location_id"
                value={storeIdFilter}
                placeholder="Store ID"
                onChange={(e: any) => handleChange(e.target.id, e.target.value)}
              />
            </Grid.Item>
            <Grid.Item xs={6}>
              <Form.Field
                id="location_name"
                value={storeNameFilter}
                placeholder="Store Name"
                onChange={(e: any) => handleChange(e.target.id, e.target.value)}
              />
            </Grid.Item>
          </Grid.Container>
          <Grid.Container className="sem_ListModal-Table sem_LocationsModalScroll">
            <Grid.Item className="hc-pl-none" xs={12}>
              <List>
                {locations.map((location: any, index: number) => (
                  <List.Item divider key={index}>
                    <Grid.Container>
                      <Grid.Item xs={3}>
                        <Input.Checkbox
                          id={`${modalCategory}-${location.location_id}`}
                          checked={selectedLocationIds.includes(
                            location.location_id.toString(),
                          )}
                          onChange={(e: any) =>
                            handleSelect(
                              e.target.id.split('-')[1],
                              e.target.checked,
                            )
                          }
                        />
                      </Grid.Item>
                      <Grid.Item xs={2}>{location.location_id}</Grid.Item>
                      <Grid.Item xs={6}>{location.location_name}</Grid.Item>
                    </Grid.Container>
                  </List.Item>
                ))}
              </List>
            </Grid.Item>
          </Grid.Container>
          <Grid.Container
            className="hc-mt-normal"
            align="center"
            direction="row-reverse"
          >
            <Grid.Item>
              <Button type="primary" onClick={() => handleSave()}>
                Save Selection
              </Button>
            </Grid.Item>
            <Grid.Item>
              <Button type="secondary" onClick={() => handleClose()}>
                Cancel
              </Button>
            </Grid.Item>
            <Grid.Item xs={true}>
              <Heading className="hc-clr-grey02" size={6}>
                {`Stores Selected: ${selectedLocationIds.length}`}
              </Heading>
            </Grid.Item>
          </Grid.Container>
        </div>
        <div
          className={`C-Overlay --background-dark ${
            modalCategory ? 'isVisible' : ''
          }`}
        />
      </div>

      <InfiniteScroll
        shouldShowSpinner={false}
        shouldHideScrollToTop={true}
        isAlreadyLoading={false}
        setIsLoadingMore={() => {}}
        currentPageNumber={currentPageNumber}
        totalNumberOfElements={sharedMscFacets.locations.length}
        handleNewResults={(newResults: StoreLocation[]) => {
          setLocations([...locations, ...newResults])
        }}
        loadMore={() => {
          const filteredLocations = getFilteredLocations(
            storeNameFilter,
            storeIdFilter,
          )
          const promise = new Promise((resolve) =>
            resolve(
              filteredLocations.slice(
                SEARCH_RESPONSE_SIZE * currentPageNumber,
                SEARCH_RESPONSE_SIZE * (currentPageNumber + 1),
              ),
            ),
          )
          setCurrentPageNumber(currentPageNumber + 1)
          return promise
        }}
        elementSelector=".sem_LocationsModalScroll"
      />
    </Fragment>
  )
}

export default LocationsModal
