export const DEFAULT_VIEW = [
  {
    type: ['selectedRow'],
  },
  {
    type: ['menu'],
    width: 66,
  },
  {
    field: 'print_vendor',
    headerName: 'Print Vendor',
    filter: 'agTextColumnFilter',
    width: 265,
    type: ['autocompleteEditor'],
  },
  {
    field: 'expenses_type',
    headerName: 'Expenses Type',
    width: 265,
    type: ['selectEditor'],
  },
  {
    field: 'cost',
    headerName: 'Cost',
    filter: 'agNumberColumnFilter',
    width: 265,
    type: ['numberEditor'],
  },
  {
    field: 'po_number',
    headerName: 'PO Number',
    filter: 'agTextColumnFilter',
    width: 265,
    type: ['textEditor'],
  },
  {
    field: 'vendor_notes',
    headerName: 'Vendor Notes',
    filter: 'agTextColumnFilter',
    type: ['notesEditor'],
    width: 265,
  },
  {
    field: 'expenses_status',
    headerName: 'Expenses Status',
    width: 265,
    editable: false,
  },
]

export const EXPORT_COLUMNS = [
  'print_vendor',
  'expenses_type',
  'cost',
  'po_number',
  'vendor_notes',
  'expenses_status',
]

export const PRINT_VENDOR_VIEW = [
  {
    type: ['selectedRow'],
  },
  {
    type: ['menu'],
    width: 66,
  },
  {
    field: 'expenses_type',
    headerName: 'Expenses Type',
    width: 265,
    type: ['selectEditor'],
  },
  {
    field: 'cost',
    headerName: 'Cost',
    filter: 'agNumberColumnFilter',
    width: 265,
    type: ['numberEditor'],
  },
  {
    field: 'po_number',
    headerName: 'PO Number',
    filter: 'agTextColumnFilter',
    width: 265,
    type: ['textEditor'],
  },
  {
    field: 'vendor_notes',
    headerName: 'Vendor Notes',
    filter: 'agTextColumnFilter',
    type: ['notesEditor'],
    width: 265,
  },
  {
    field: 'expenses_status',
    headerName: 'Expenses Status',
    width: 265,
    editable: false,
  },
]
