import React from 'react'
import { Grid, Modal, Spinner } from '@enterprise-ui/canvas-ui-react'
import { useBlueprintDetailsContainerContext } from '../../../../context/blueprintDetailsContainerContext'
import { useBlueprintProjectListContext } from '../../context/blueprintProjectListContext'
import AgGridProjectListTable from '../projectListTable/AgGridProjectListTable'

const FullScreenProjectList = () => {
  const { isLoadingSignProjects } = useBlueprintDetailsContainerContext()!
  const { isProjectListFullScreen, setIsProjectListFullScreen } =
    useBlueprintProjectListContext()!

  return (
    <Modal
      className="table-editor-modal"
      isVisible={isProjectListFullScreen}
      onRefuse={() => setIsProjectListFullScreen(false)}
    >
      {isLoadingSignProjects ? (
        <Grid.Container
          className="sign-projects-loading-container"
          direction="column"
          justify="center"
          align="center"
        >
          <Grid.Item>Loading Signs...</Grid.Item>
          <Grid.Item>
            <Spinner />
          </Grid.Item>
        </Grid.Container>
      ) : (
        <AgGridProjectListTable />
      )}
    </Modal>
  )
}

export default FullScreenProjectList
