import React, { useState, useContext, createContext, useCallback } from 'react'
import { connect } from 'react-redux'
import { useAppContext } from '../../../App/context/appContext'
import axios from 'axios'
import { useEnv } from '@praxis/component-runtime-env'
import {
  ADMIN_SERVICE_API_DOMAIN_URL,
  HEADER_OBJECT,
  BATCH_EDIT_ACTION,
  TOASTER_DEFAULTS,
} from '../../../App/constants/appConstants'
import { RowNode } from 'ag-grid-community'
import SignTemplateResponse from '../../../../models/signTemplates/SignTemplateResponse.model'
import SignTemplateBatchRequest, {
  SignTemplateRequest,
} from '../../../../models/signTemplates/SignTemplateBatchRequest.model'
import {
  SignFacetsResponse,
  SignFacet,
} from '../../../../models/signs/SignFacetsResponse.model'
import { removeIdFromNewTemplates } from '../helpers/signTemplateHelper'
import { useToaster } from '@enterprise-ui/canvas-ui-react'

type ContextType = {
  getSignTemplates: () => void
  signTemplates: SignTemplateResponse[]
  modifiedSignTemplates: SignTemplateBatchRequest[]
  setModifiedSignTemplates: Function
  modifiedSignTemplateIds: string[]
  setModifiedSignTemplateIds: Function
  signTemplatesModified: boolean
  setSignTemplatesModified: (isModified: boolean) => void
  saveModifiedSignTemplates: () => void
  signTypes: SignFacet[]
  setSignTypes: Function
  getSignTypes: () => void
  deleteSignTemplates: (templates: RowNode[]) => void
  isMoveSignsModalOpen: boolean
  setIsMoveSignsModalOpen: (isOpen: boolean) => void
  currentSignType: string
  setCurrentSignType: (signType: string) => void
  currentStandardSign: string
  setCurrentStandardSign: (standardSign: string) => void
  getSignTemplatesBySignType: Function
  moveStandardSignOptions: any[]
  setMoveStandardSignOptions: (options: any[]) => void
  moveStandardSigns: (newTemplateId: string) => void
}

export const StandardSignAdminContext = createContext<ContextType | undefined>(
  undefined,
)

type Props = {
  children: React.ReactNode
}

export const StandardSignAdminProviderComponent = ({ children }: Props) => {
  const { setFullPageLoadingMessage } = useAppContext()!
  const env = useEnv()!
  const makeToast = useToaster()

  const [signTemplates, setSignTemplates] = useState<SignTemplateResponse[]>([])
  const [modifiedSignTemplates, setModifiedSignTemplates] = useState<
    SignTemplateBatchRequest[]
  >([])
  const [modifiedSignTemplateIds, setModifiedSignTemplateIds] = useState<
    string[]
  >([])
  const [signTemplatesModified, setSignTemplatesModified] = useState(false)
  const [signTypes, setSignTypes] = useState<SignFacet[]>([])
  const [isMoveSignsModalOpen, setIsMoveSignsModalOpen] = useState(false)
  const [currentSignType, setCurrentSignType] = useState('')
  const [currentStandardSign, setCurrentStandardSign] = useState('')
  const [moveStandardSignOptions, setMoveStandardSignOptions] = useState<any[]>(
    [],
  )

  const getSignTemplates = async () => {
    setFullPageLoadingMessage('Loading sign templates...')
    try {
      const res = await axios.get(
        `${
          env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
        }/templates/sign_templates`,
      )
      setSignTemplates(res.data)
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Get Sign Templates',
        message: err.response.data.message,
      })
    }
    setFullPageLoadingMessage('')
  }

  const getSignTemplatesBySignType = useCallback(async () => {
    try {
      const res = await axios.get(
        `${
          env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
        }/templates/sign_templates/type?sign_type=${currentSignType}`,
      )
      setMoveStandardSignOptions(
        res.data
          .filter(
            (template: SignTemplateResponse) =>
              template.name !== currentStandardSign,
          )
          .map((template: SignTemplateResponse) => ({
            id: template.id,
            label: template.name,
            value: template.id,
          })),
      )
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Get Sign Templates',
        message: err.response.data.message,
      })
    }
  }, [currentSignType, env.apiDomainUrl, makeToast, currentStandardSign])

  const deleteSignTemplates = async (templates: RowNode[]) => {
    const templateRequests = templates.map(
      (template: RowNode) =>
        new SignTemplateBatchRequest({
          sign_template_dto: new SignTemplateRequest({
            ...template.data,
          }),
          action: BATCH_EDIT_ACTION.DELETE,
        }),
    )
    setFullPageLoadingMessage('Deleting Sign Template(s)')
    try {
      await axios.post(
        `${
          env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
        }/templates/sign_templates`,
        templateRequests,
        {
          ...HEADER_OBJECT,
        },
      )
      await getSignTemplates()
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'success',
        heading: 'Sign Template(s) Deleted',
        message: 'Successfully deleted sign template(s)',
      })
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Delete Sign Templates',
        message: err.response.data.message,
      })
    }
    setFullPageLoadingMessage('')
  }

  const saveModifiedSignTemplates = async () => {
    setFullPageLoadingMessage('Saving Sign Templates...')
    try {
      await axios.post(
        `${
          env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
        }/templates/sign_templates`,
        removeIdFromNewTemplates(modifiedSignTemplates),
        {
          ...HEADER_OBJECT,
        },
      )
      await getSignTemplates()
      setModifiedSignTemplates([])
      setModifiedSignTemplateIds([])
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'success',
        heading: 'Sign Templates Updated',
        message: 'Successfully updated sign templates',
      })
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Update Sign Templates',
        message: err.response.data.message,
      })
    }
    setFullPageLoadingMessage('')
  }

  const getSignTypes = async () => {
    try {
      const res: any = await axios.get(
        `${env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL}/facets/signs`,
      )
      const facetData: SignFacetsResponse = new SignFacetsResponse({
        ...res.data,
      })
      setSignTypes(facetData.sign_types)
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Get Sign Tyes',
        message: err.response.data.message,
      })
    }
  }

  const moveStandardSigns = async (newTemplateId: string) => {
    setFullPageLoadingMessage('Moving standard signs...')
    try {
      const currentStandardSignId = signTemplates.find(
        (template: SignTemplateResponse) =>
          template.name === currentStandardSign,
      )!!.id
      await axios.put(
        `${
          env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
        }/templates/sign_templates/move?originalTemplateId=${currentStandardSignId}&newTemplateId=${newTemplateId}`,
      )
      await getSignTemplates()
      setIsMoveSignsModalOpen(false)
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'success',
        heading: 'Signs Moved',
        message: 'Successfully moved signs to different template',
      })
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Move Signs',
        message: err.response.data.message,
      })
    }
    setFullPageLoadingMessage('')
  }

  return (
    <StandardSignAdminContext.Provider
      value={{
        getSignTemplates,
        signTemplates,
        modifiedSignTemplates,
        setModifiedSignTemplates,
        modifiedSignTemplateIds,
        setModifiedSignTemplateIds,
        signTemplatesModified,
        setSignTemplatesModified,
        saveModifiedSignTemplates,
        signTypes,
        setSignTypes,
        getSignTypes,
        deleteSignTemplates,
        isMoveSignsModalOpen,
        setIsMoveSignsModalOpen,
        currentSignType,
        setCurrentSignType,
        getSignTemplatesBySignType,
        moveStandardSignOptions,
        setMoveStandardSignOptions,
        currentStandardSign,
        setCurrentStandardSign,
        moveStandardSigns,
      }}
    >
      {children}
    </StandardSignAdminContext.Provider>
  )
}

export const StandardSignAdminProvider = connect(
  null,
  null,
)(StandardSignAdminProviderComponent)

export const useStandardSignAdminContext = () =>
  useContext(StandardSignAdminContext)
