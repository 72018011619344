import React from 'react'
import {
  Grid,
  Heading,
  Button,
  Tooltip,
  Spinner,
} from '@enterprise-ui/canvas-ui-react'
import { Gallery } from '@enterprise-ui/canvas-ui-react-gallery'
import { useSignDetailsContext } from '../context/signDetailsContext'
import { SIGN_DETAILS_SIDEBAR } from '../constants/signDetailsConstants'
import { useSignPreviewContext } from '../context/signPreviewContext'
import EnterpriseIcon, { CancelIcon, ImagesIcon } from '@enterprise-ui/icons'

const SignPreview = () => {
  const { openSidebar, setOpenSidebar } = useSignDetailsContext()!
  const { previewSrcUrl, isPreviewLoading } = useSignPreviewContext()!

  return (
    <Grid.Item
      className={`sem_GalleryTab ${
        openSidebar === SIGN_DETAILS_SIDEBAR.PREVIEW ? 'open' : ''
      }`}
    >
      <Grid.Container justify="center" className="hc-mb-dense">
        <Grid.Item
          className="hc-pb-none"
          xs={openSidebar === SIGN_DETAILS_SIDEBAR.PREVIEW ? 12 : false}
        >
          <div className="sem_GalleryBtn">
            <Button type="ghost" onClick={() => setOpenSidebar('')}>
              <EnterpriseIcon icon={ImagesIcon} size="lg" />
              <Heading
                className="hc-clr-grey02 hc-ml-normal hc-txt-capitalize"
                size={5}
              >
                Sign Preview
              </Heading>
            </Button>
            <div style={{ marginLeft: 'auto' }}>
              <Tooltip location="bottom-left" content="Hide Gallery Panel">
                <Button
                  aria-label="Close preview"
                  type="ghost"
                  iconOnly
                  className="sem_GalleryBtnClose"
                  onClick={() => setOpenSidebar('')}
                >
                  <EnterpriseIcon icon={CancelIcon} />
                </Button>
              </Tooltip>
            </div>
          </div>
        </Grid.Item>
        <Grid.Item
          xs={openSidebar === SIGN_DETAILS_SIDEBAR.PREVIEW ? 12 : false}
        >
          {isPreviewLoading ? (
            <Grid.Container align="center" justify="center">
              <Grid.Item>
                <Spinner />
              </Grid.Item>
            </Grid.Container>
          ) : (
            <Gallery
              className={`sem_Gallery sem_Preview ${
                openSidebar === SIGN_DETAILS_SIDEBAR.PREVIEW ? 'open' : ''
              }`}
              items={[
                {
                  alt: 'Sign Preview',
                  url: previewSrcUrl,
                },
              ]}
            />
          )}
        </Grid.Item>
      </Grid.Container>
    </Grid.Item>
  )
}

export default SignPreview
