import React, { useCallback } from 'react'
import EnterpriseIcon, {
  LockIcon,
  LockUnlockedIcon,
} from '@enterprise-ui/icons'
import { unlockColumnState, columnStateIsLocked } from 'utils/agGrid'

interface LockColumnsButtonProps {
  lsKey: string
}

export const LockColumnsButton = ({ lsKey }: LockColumnsButtonProps) => {
  const isLocked = columnStateIsLocked(lsKey)

  const onClick = useCallback(() => {
    if (isLocked) {
      unlockColumnState(lsKey)
    }
  }, [isLocked, lsKey])

  return (
    <div className="lock-columns-btn hc-clr-grey02" onClick={onClick}>
      <EnterpriseIcon
        size="inline"
        className="hc-mr-normal"
        icon={isLocked ? LockIcon : LockUnlockedIcon}
      />
      <span>Reset Column Order</span>
    </div>
  )
}
