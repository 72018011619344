import React from 'react'
import { ProjectSignInfo } from '../../../../../models/signLibrary'
import RubixChip from 'components/common/RubixChip'

export interface Props {
  data: ProjectSignInfo
}

const FinalizeLevelCell = ({ data = new ProjectSignInfo() }: Props) => {
  return (
    <RubixChip color={data.workflow.color}>{data.workflow.status}</RubixChip>
  )
}

export default FinalizeLevelCell
