import React, { useState } from 'react'
import {
  Button,
  Card,
  Grid,
  Form,
  Tooltip,
  Input,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, {
  MinusIcon,
  SaveIcon,
  TrashIcon,
} from '@enterprise-ui/icons'
import { MilestoneTemplate } from '../../../../../models/milestones/MilestoneTemplate.model'
import { useAdminMilestoneContext } from '../../context/adminMilestoneContext'
import { useMilestoneContext } from 'components/App/context/milestoneContext'
import { useAdminMilestoneForm } from '../../hooks/useAdminMilestoneForm'
import { useAppContext } from 'components/App/context/appContext'
import { DialogProps } from '../../../../../models/app/DialogProps.model'

export interface Props {
  milestone: MilestoneTemplate
}

const AdminMilestoneCard = ({ milestone }: Props) => {
  const { setDialogProps } = useAppContext()!
  const { removeMilestoneTemplate, saveMilestoneTemplate } =
    useAdminMilestoneContext()!
  const {
    modifiedMilestoneIds,
    addToModifiedMilestoneIds,
    milestoneTemplates,
    isDependency,
    milestoneFacets,
  } = useMilestoneContext()!
  const [editMode, setEditMode] = useState(false)

  const handleCardClick = () => {
    if (!editMode) {
      setEditMode(!editMode)
    }
  }

  const { setFieldValue, handleSubmit, values, errors } = useAdminMilestoneForm(
    milestone,
    saveMilestoneTemplate,
  )

  const milestoneTemplateFacets = milestoneTemplates.map(
    (template: MilestoneTemplate) => {
      return { value: template.name, label: template.name }
    },
  )
  milestoneTemplateFacets.unshift({ value: '', label: '' })

  return (
    <Card
      className={`sem-MilestoneTemplateCard ${
        modifiedMilestoneIds.has(milestone.milestone_id) ? 'modified' : ''
      } hc-mt-dense hc-mb-none`}
      data-testid="adminMilestoneCard"
      onClickCapture={() => handleCardClick()}
    >
      {editMode ? (
        <div className="hc-pa-dense toolbar-Container">
          <Grid.Container justify="flex-end" spacing="none">
            <Grid.Item xs>
              <Tooltip location="bottom-right" content="Collapse">
                <Button
                  aria-label="Collapse Milestone"
                  iconOnly
                  type="ghost"
                  size="dense"
                  data-testid="removeButton"
                  className="sem-MilestoneBtnRemove"
                  onClick={() => {
                    setEditMode(false)
                  }}
                >
                  <EnterpriseIcon size="inline" color="gray" icon={MinusIcon} />
                </Button>
              </Tooltip>
            </Grid.Item>

            <Grid.Item>
              <Tooltip location="bottom" content="Save">
                <Button
                  aria-label="Save Milestone"
                  iconOnly
                  type="primary"
                  size="dense"
                  className="sem-MilestoneBtnRemove"
                  onClick={() => {
                    handleSubmit()
                  }}
                >
                  <EnterpriseIcon size="inline" color="white" icon={SaveIcon} />
                </Button>
              </Tooltip>
            </Grid.Item>
            <Grid.Item>
              <Tooltip location="bottom" content="Delete">
                <Button
                  aria-label="Delete Milestone"
                  iconOnly
                  type="destructive"
                  size="dense"
                  className="sem-MilestoneBtnRemove"
                  onClick={() => {
                    if (isDependency(milestone.name, milestoneTemplates)) {
                      setDialogProps({
                        headingText:
                          'Other milestones are dependent on this milestone.  Please remove it as a dependency from all relevant milestones, then try again.',
                        approveButtonText: 'Ok',
                        onApprove: () => setDialogProps(new DialogProps()),
                        onRefuse: () => setDialogProps(new DialogProps()),
                      })
                    } else {
                      setDialogProps(
                        new DialogProps({
                          headingText:
                            'Are you sure you want to remove this milestone?',
                          approveButtonText: 'Yes',
                          onApprove: () =>
                            removeMilestoneTemplate(milestone.milestone_id),
                          refuseButtonText: 'Cancel',
                          onRefuse: () => setDialogProps(new DialogProps()),
                        }),
                      )
                    }
                  }}
                >
                  <EnterpriseIcon
                    size="inline"
                    color="white"
                    icon={TrashIcon}
                  />
                </Button>
              </Tooltip>
            </Grid.Item>
          </Grid.Container>

          <div className="hc-pa-dense">
            <Grid.Container spacing="dense">
              <Grid.Item xs>
                <Grid.Container spacing="dense" align="flex-end">
                  <Grid.Item className="hc-clr-grey02" xs={12}>
                    <Form.Field
                      id="milestone_name"
                      label="Name"
                      value={values.name}
                      error={errors.name !== undefined}
                      onChange={(e: any) => {
                        setFieldValue('name', e.target.value)
                        addToModifiedMilestoneIds(milestone.milestone_id)
                      }}
                      required
                    />
                  </Grid.Item>
                  <Grid.Item className="hc-pv-dense" xs={12}>
                    <Form.Field
                      type="select"
                      id="phase"
                      label="Project Phase"
                      value={values.project_phase}
                      error={errors.project_phase !== undefined}
                      options={milestoneFacets.project_phases}
                      required
                      onUpdate={(e: any, phase: string) => {
                        setFieldValue('project_phase', phase)
                        addToModifiedMilestoneIds(milestone.milestone_id)
                      }}
                    />
                  </Grid.Item>
                  <Grid.Item className="hc-clr-grey02" xs={12}>
                    <Form.Field
                      type="select"
                      id="milestone_role"
                      label="Role"
                      value={values.role}
                      options={milestoneFacets.roles}
                      error={errors.role !== undefined}
                      onUpdate={(e: any, role: string) => {
                        setFieldValue('role', role)
                        addToModifiedMilestoneIds(milestone.milestone_id)
                      }}
                      required
                    />
                  </Grid.Item>
                  <Grid.Item className="hc-clr-grey02" xs={12}>
                    <Form.Field
                      type="select"
                      id="dependsOn"
                      label="Depends On"
                      value={values.depends_on}
                      options={milestoneTemplateFacets.filter(
                        (option: any) => option.value !== milestone.name,
                      )}
                      error={errors.depends_on !== undefined}
                      onUpdate={(e: any, dependsOn: string) => {
                        setFieldValue('depends_on', dependsOn)
                        addToModifiedMilestoneIds(milestone.milestone_id)
                      }}
                    />
                  </Grid.Item>
                  <Grid.Item xs={4}>
                    <Input.Checkbox
                      id="critical"
                      label="Critical"
                      checked={values.critical}
                      onChange={(e: any) => {
                        setFieldValue('critical', !values.critical)
                        setFieldValue('risk', !values.risk)
                        addToModifiedMilestoneIds(milestone.milestone_id)
                      }}
                    />
                  </Grid.Item>
                  <Grid.Item xs={6}>
                    <Input.Checkbox
                      id="info_only"
                      label="Information Only"
                      checked={values.information_only}
                      onChange={(e: any) => {
                        setFieldValue(
                          'information_only',
                          !values.information_only,
                        )
                        addToModifiedMilestoneIds(milestone.milestone_id)
                      }}
                    />
                  </Grid.Item>
                </Grid.Container>
                <Grid.Container>
                  <Grid.Item xs={true}>
                    <Input.Checkbox
                      id="auto_check"
                      label="Auto-Completed"
                      checked={values.auto_check}
                      onChange={(e: any) => {
                        setFieldValue('auto_check', !values.auto_check)
                        addToModifiedMilestoneIds(milestone.milestone_id)
                      }}
                    />
                  </Grid.Item>
                </Grid.Container>
              </Grid.Item>
            </Grid.Container>
          </div>
        </div>
      ) : (
        <div className="hc-pa-dense">
          <Grid.Container align="flex-end" spacing="dense">
            <Grid.Item xs>
              <Grid.Container spacing="none">
                <Grid.Item className="hc-clr-grey02" xs={12}>
                  <p className="title">{milestone.name}</p>
                </Grid.Item>
                <Grid.Item className="hc-clr-grey02" xs={12}>
                  {milestone.role}
                </Grid.Item>
                <Grid.Item className="hc-clr-grey02" xs={12}>
                  {milestone.depends_on}
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
          </Grid.Container>
        </div>
      )}
    </Card>
  )
}

export default AdminMilestoneCard
