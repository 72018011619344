import React from 'react'
import { Divider, Grid } from '@enterprise-ui/canvas-ui-react'

interface Props {
  name: string
  greenCount: number
  yellowCount: number
  redCount: number
  greyCount: number
  blueCount: number
}

const ProjectManagerSection = ({
  name,
  greenCount,
  yellowCount,
  redCount,
  greyCount,
  blueCount,
}: Props) => {
  return (
    <Grid.Container>
      <Grid.Item className="sem_DashboardProjectManagerSectionName">
        {name}:
      </Grid.Item>
      <Grid.Item className="sem_DashboardProjectManagerCount sem_DashboardProjectManagerCount--white">
        {greenCount + yellowCount + redCount + greyCount + blueCount}
      </Grid.Item>
      {greenCount > 0 ||
      yellowCount > 0 ||
      redCount > 0 ||
      greyCount > 0 ||
      blueCount > 0 ? (
        <Divider
          className="sem_DashboardProjectManagerDivider"
          direction="vertical"
          width={2}
        />
      ) : null}
      {greenCount > 0 ? (
        <Grid.Item className="sem_DashboardProjectManagerCount sem_DashboardProjectManagerCount--green">
          {greenCount}
        </Grid.Item>
      ) : null}
      {yellowCount > 0 ? (
        <Grid.Item className="sem_DashboardProjectManagerCount sem_DashboardProjectManagerCount--yellow">
          {yellowCount}
        </Grid.Item>
      ) : null}
      {redCount > 0 ? (
        <Grid.Item className="sem_DashboardProjectManagerCount sem_DashboardProjectManagerCount--red">
          {redCount}
        </Grid.Item>
      ) : null}
      {greyCount > 0 ? (
        <Grid.Item className="sem_DashboardProjectManagerCount sem_DashboardProjectManagerCount--grey">
          {greyCount}
        </Grid.Item>
      ) : null}
      {blueCount > 0 ? (
        <Grid.Item className="sem_DashboardProjectManagerCount sem_DashboardProjectManagerCount--blue">
          {blueCount}
        </Grid.Item>
      ) : null}
    </Grid.Container>
  )
}

export default ProjectManagerSection
