import { SIGN_STATUS } from '../../components/App/constants/appConstants'

export default class ProjectSignList {
  project_id?: string
  project_name?: string
  sap_project_id?: string
  set_date: string
  sign_id?: string
  sign_status?: SIGN_STATUS

  constructor(props: any = {}) {
    this.project_id = props.project_id
    this.project_name = props.project_name
    this.sap_project_id = props.sap_project_id
    this.set_date = props.set_date || ''
    this.sign_id = props.sign_id
    this.sign_status = props.sign_status
  }
}
