import React from 'react'
import { Input } from '@enterprise-ui/canvas-ui-react'

export interface Props {
  value: boolean
}

const TableToggleComponent = ({ value }: Props) => (
  <Input.Toggle
    id="table_toggle"
    className="hc-ma-none hc-pv-dense"
    checked={value}
    disabled
  />
)

export default TableToggleComponent
