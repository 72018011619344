import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { Form, Grid } from '@enterprise-ui/canvas-ui-react'
import {
  Department,
  SignLibraryElementResponse,
} from '../../../../../models/signLibrary'
import { useAgGridSignLibraryContext } from '../../../context/agGridSignLibraryContext'
import { Column } from 'ag-grid-community'

export interface Props {
  value: Department
  column: Column
  data: SignLibraryElementResponse
}

const DeptCellEditor = forwardRef(({ value, column, data }: Props, ref) => {
  const { editingFacets } = useAgGridSignLibraryContext()!
  const [editValue, setEditValue] = useState(value ? value : new Department())
  const refInput = useRef<HTMLInputElement>(null)

  const handleChange = (id: string, value: Department) => setEditValue(value)

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return editValue
      },
      isPopup() {
        return true
      },
    }
  })

  return (
    <div className="hc-pa-dense">
      <Grid.Container className="sem_StandardForm error_messaging_enabled wide-form">
        <Grid.Item xs={12}>
          <Form.Field
            id="department_cell"
            type="select"
            label="Department"
            ref={refInput}
            value={editValue.department_id}
            options={editingFacets.departments.map((dept: Department) => ({
              value: dept.department_id,
              label: dept.department_display_name,
            }))}
            onUpdate={(id: string, value: number) => {
              const selected = editingFacets.departments.filter(
                (dept: Department) => dept.department_id === value,
              )
              handleChange(id, selected[0])
            }}
            size="dense"
          />
        </Grid.Item>
      </Grid.Container>
    </div>
  )
})

export default DeptCellEditor
