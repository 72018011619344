import React, { useEffect, useState } from 'react'
import { Grid, Popover, Table } from '@enterprise-ui/canvas-ui-react'
import { Column, ColumnEvent } from 'ag-grid-community'
import SignResponse from '../../../../../../../models/signs/SignResponse.model'
import { KitGroupQuantity } from '../../../../../../../models/signs/DistributionResponse.model'

export interface Props {
  value: string[]
  data: SignResponse
  column: Column
}

const KitGroupCell = ({ value, data, column }: Props) => {
  const [colWidth, setColWidth] = useState(column.getActualWidth())
  useEffect(() => {
    column.addEventListener('widthChanged', (event: ColumnEvent) => {
      setColWidth(column.getActualWidth())
    })
  }, [column])

  const popoverContent = (
    <Grid.Container>
      <Grid.Item className="hc-pv-dense" xs={12}>
        <Table className="hc-clr-grey03">
          <Table.Head>
            <Table.Row>
              <Table.Header xs={8}>KIT GROUP</Table.Header>
              <Table.Header className="hc-ta-right" xs={4}>
                KIT QUANTITY
              </Table.Header>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {data.distribution.kit_group_quantities?.map(
              (group: KitGroupQuantity, index: number) => (
                <Table.Row key={index}>
                  <Table.Data xs={8}>{group.group_id}</Table.Data>
                  <Table.Data className="hc-ta-right" xs={4}>
                    {group.quantity}
                  </Table.Data>
                </Table.Row>
              ),
            )}
          </Table.Body>
        </Table>
      </Grid.Item>
    </Grid.Container>
  )

  return data.on_pog_sign || data.distribution.kit_groups.length === 0 ? (
    <Grid.Container className="ag-cell-value" style={{ width: colWidth }}>
      <Grid.Item className="ag-cell-value" xs={12}>
        {value.join(', ')}
      </Grid.Item>
    </Grid.Container>
  ) : (
    <Popover content={popoverContent} size="expanded" location="bottom-right">
      <Grid.Container className="ag-cell-value" style={{ width: colWidth }}>
        <Grid.Item className="ag-cell-value" xs={12}>
          {value.join(', ')}
        </Grid.Item>
      </Grid.Container>
    </Popover>
  )
}

export default KitGroupCell
