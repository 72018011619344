import axios from 'axios'
import {
  ARRAY_BUFFER_CONFIG,
  SIGN_SERVICE_API_DOMAIN_URL,
} from '../../App/constants/appConstants'
import appConfig from '../../../config/appConfig'
import * as pdfjs from 'pdfjs-dist'
import MessagingResponse from '../../../models/signs/MessagingResponse.model'
import { isDpciFormat } from './signMessagingHelpers'
import { set } from 'lodash'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.mjs`

export const getMessagingErrors = (values: MessagingResponse) => {
  let errors: any = {}
  values.item_info.forEach((itemInfo, index) => {
    const dpci = values.item_info[index].product_dpci
    if (!dpci) {
      set(
        errors,
        `item_info[${index}].product_dpci`,
        'You must provide a Product DPCI',
      )
    } else if (!isDpciFormat(dpci)) {
      set(errors, `item_info[${index}].product_dpci`, 'Not a valid DPCI format')
    }
  })

  if (values.mpp && values.item_info.length < 2) {
    errors.mpp = 'MPP signs must have 2 or more items'
  }

  return errors
}

export const generatePreview = async (previewData: any) => {
  let res: any = {}
  const config = await appConfig()
  res = await axios.post(
    `${config.apiDomainUrl + SIGN_SERVICE_API_DOMAIN_URL}/preview_ess_signs`,
    previewData,
    // Response data will come back as an ArrayBuffer
    ARRAY_BUFFER_CONFIG,
  )

  // Load the PDF from the ArrayBuffer
  const loadingTask = pdfjs.getDocument(res.data)
  const pdf = await loadingTask.promise
  const page = await pdf.getPage(1)

  // Create canvas and render the PDF
  const viewport = page.getViewport({ scale: 1.5 })
  const canvas = document.createElement('canvas')
  canvas.height = viewport.height
  canvas.width = viewport.width

  await page.render({
    canvasContext: canvas.getContext('2d') as CanvasRenderingContext2D,
    viewport,
  }).promise

  // Covert rendered canvas to PNG
  return canvas.toDataURL('image/png')
}
