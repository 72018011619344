import React from 'react'
import { Column } from 'ag-grid-community'
import {
  getSignItemStatusChipColor,
  getSignStatusChipColor,
} from 'components/App/helpers/statusCalculation'
import RubixChip from 'components/common/RubixChip'

export interface Props {
  value: string
  column: Column
}

const SignStatusChip = ({ value, column }: Props) => {
  return value ? (
    <RubixChip
      color={
        column.getColId() === 'non_retail_item_info.sign_item_status'
          ? getSignItemStatusChipColor(value)
          : getSignStatusChipColor(value)
      }
    >
      {value}
    </RubixChip>
  ) : null
}

export default SignStatusChip
