import { get } from 'lodash'
import { Department } from '../merchandise/hierarchy/Department.model'

export class SignFacetsResponse {
  sign_types: SignFacet[]
  sign_statuses: SignFacet[]
  sign_item_statuses: SignFacet[]
  sign_creator: SignFacet[]
  sign_languages: SignFacet[]
  departments: Department[]
  new_sign_reason_code: SignFacet[]
  remove_sign_reason_code: SignFacet[]
  sign_substrate: SignFacet[]
  pog_unit_of_measure: SignFacet[]
  placement_on_shelf: SignFacet[]

  constructor(props: any = {}) {
    this.sign_types = get(props, 'sign_types', [{}]).map(
      (sign_type: any) => new SignFacet(sign_type),
    )
    this.sign_statuses = get(props, 'sign_statuses', [{}]).map(
      (status: any) => new SignFacet(status),
    )
    this.sign_item_statuses = get(props, 'sign_item_statuses', [{}]).map(
      (item_status: any) => new SignFacet(item_status),
    )
    this.sign_creator = get(props, 'sign_creator', [{}]).map(
      (creator: any) => new SignFacet(creator),
    )
    this.sign_languages = get(props, 'sign_languages', [{}]).map(
      (language: any) => new SignFacet(language),
    )
    this.departments = get(props, 'departments', [{}]).map(
      (department: any) => new Department(department),
    )
    this.new_sign_reason_code = get(props, 'new_sign_reason_code', [{}]).map(
      (new_sign_reason_code: any) => new SignFacet(new_sign_reason_code),
    )
    this.remove_sign_reason_code = get(props, 'remove_sign_reason_code', [
      {},
    ]).map(
      (remove_sign_reason_code: any) => new SignFacet(remove_sign_reason_code),
    )
    this.sign_substrate = get(props, 'sign_substrate', [{}]).map(
      (sign_substrate: any) => new SignFacet(sign_substrate),
    )
    this.pog_unit_of_measure = get(props, 'pog_unit_of_measure', [{}]).map(
      (pog_unit_of_measure: any) => new SignFacet(pog_unit_of_measure),
    )
    this.placement_on_shelf = get(props, 'placement_on_shelf', [{}]).map(
      (placement_on_shelf: any) => new SignFacet(placement_on_shelf),
    )
    this.pog_unit_of_measure = get(props, 'pog_unit_of_measure', [{}]).map(
      (pog_unit_of_measure: any) => new SignFacet(pog_unit_of_measure),
    )
  }
}

export class SignFacet {
  value?: string
  label: string

  constructor(props: any = {}) {
    this.value = props.value
    this.label = props.label || ''
  }
}
