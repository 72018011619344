import React, { Fragment, useState } from 'react'
import { useEffect } from 'react'
import lodash from 'lodash'
import { Grid, Spinner } from '@enterprise-ui/canvas-ui-react'
import ScrollToTop from './ScrollToTop'
import { SEARCH_RESPONSE_SIZE } from '../constants/appConstants'

export interface Props {
  shouldShowSpinner: boolean
  shouldHideScrollToTop?: boolean
  isAlreadyLoading: boolean
  setIsLoadingMore: (isLoadingMore: boolean) => void
  currentPageNumber: number
  totalNumberOfElements: number
  handleNewResults: (newResults: any[]) => void
  loadMore: () => Promise<any>
  elementSelector?: string
}

const InfiniteScroll = ({
  shouldShowSpinner,
  shouldHideScrollToTop,
  isAlreadyLoading,
  setIsLoadingMore,
  currentPageNumber,
  totalNumberOfElements,
  handleNewResults,
  loadMore,
  elementSelector,
}: Props) => {
  const [scrollPosition, setScrollPosition] = useState(0)
  const [element, setElement] = useState<Element | Window>()

  useEffect(() => {
    if (elementSelector) {
      const domElement = document.querySelector(elementSelector)
      domElement && setElement(domElement)
    } else {
      setElement(window)
    }
  }, [elementSelector])

  useEffect(() => {
    const throttledScrollHandler = lodash.throttle(scrollHandler, 500)
    element && element.addEventListener('scroll', throttledScrollHandler)
    return function cleanup() {
      element && element.removeEventListener('scroll', throttledScrollHandler)
    }
  })

  const scrollHandler = async () => {
    let shouldLoadMore

    if (element instanceof Window) {
      setScrollPosition(window.scrollY)
      shouldLoadMore =
        document.documentElement.offsetHeight <=
        window.innerHeight + window.scrollY + 200
    } else if (element) {
      setScrollPosition(element.scrollTop)
      shouldLoadMore =
        element &&
        element.scrollHeight <= window.innerHeight + element.scrollTop + 200
    }

    const hasMorePages =
      currentPageNumber < totalNumberOfElements / SEARCH_RESPONSE_SIZE

    if (hasMorePages && !isAlreadyLoading && shouldLoadMore) {
      setIsLoadingMore(true)
      const newResults = await loadMore()
      handleNewResults(newResults)
      setIsLoadingMore(false)
    }
  }

  return (
    <Fragment>
      {shouldShowSpinner && (
        <Grid.Container justify="center">
          <Grid.Item>
            <Spinner />
          </Grid.Item>
        </Grid.Container>
      )}
      {!shouldHideScrollToTop && element && scrollPosition > 200 && (
        <ScrollToTop
          element={
            element instanceof Window ? document.documentElement : element
          }
        />
      )}
    </Fragment>
  )
}

export default InfiniteScroll
