import React from 'react'
import { Chip, Grid, Spinner } from '@enterprise-ui/canvas-ui-react'
import { useProjectSignListContext } from '../../../context/projectSignListContext'
import { JobStatusNames } from '../../../../../../../models/job/JobStatus.model'

const StatusChip = () => {
  const { signImportResponse, signDuplicateResponse } =
    useProjectSignListContext()!

  return (
    <Grid.Item className="hc-ph-dense hc-pb-dense" xs={true} align="center">
      {signImportResponse?.status?.name === JobStatusNames.RUNNING ? (
        <Chip>
          <Spinner className="hc-mr-dense" size="dense" /> IMPORT IN PROGRESS
        </Chip>
      ) : null}
      {signDuplicateResponse?.status?.name === JobStatusNames.RUNNING ? (
        <Chip>
          <Spinner className="hc-mr-dense" size="dense" /> DUPLICATION IN
          PROGRESS
        </Chip>
      ) : null}
    </Grid.Item>
  )
}

export default StatusChip
