import React, { Fragment, useEffect, useState } from 'react'
import {
  Grid,
  ExpandableSection,
  Heading,
  Divider,
  Button,
  Form,
  Input,
} from '@enterprise-ui/canvas-ui-react'
import { useSignDetailsContext } from '../../../context/signDetailsContext'
import { useMscLocationsContext } from '../../../context/mscLocationsContext'
import { useSignBulkEditContext } from 'components/SignBulkEdit/context/signBulkEditContext'
import SignKittingForm from './SignKittingForm'
import IncludeLocations from './IncludeLocationsFilter'
import ExcludeLocations from './ExcludeLocationsFilter'
import { SIGN_DETAILS_SECTION } from '../../../constants/signDetailsConstants'
import { POST_KIT_FINALIZATION } from 'components/App/constants/appConstants'
import { useUserContext } from 'components/App/context/userContext'
import { useSignFormsContext } from '../../../context/signFormsContext'
import { useAppContext } from 'components/App/context/appContext'
import { LocationInfo } from '../../../../../models/signs/DistributionResponse.model'
import SignResponse from '../../../../../models/signs/SignResponse.model'
import { isEditable } from 'components/App/helpers/signEditHelper'

export interface Props {
  isBulkEdit?: boolean
  selectedSignsOnPog?: boolean
  selectedSignsNotOnPog?: boolean
  selectedSigns?: SignResponse[]
}

const SignDistributionSection = ({
  isBulkEdit = false,
  selectedSignsOnPog = false,
  selectedSignsNotOnPog = false,
  selectedSigns,
}: Props) => {
  const { setPageHasChanges } = useAppContext()!
  const {
    isInProject,
    currentSign,
    expandedSection,
    setExpandedSection,
    modifiedSections,
    addToModifiedSections,
    setActionType,
    setShowReasonCodeModal,
    project,
  } = useSignDetailsContext()!
  const { refreshSharedMscFacets } = useMscLocationsContext()!
  const { removeBulkEditField, selectedFields, setSelectedFields } =
    useSignBulkEditContext()!
  const { signDistributionFormik, signInfoFormik } = useSignFormsContext()!
  const { userPermissions, userCompany, userType } = useUserContext()!

  useEffect(() => {
    // refresh MSC facets only if it is not on pog sign or bulk edit mode
    refreshSharedMscFacets()
    if (
      currentSign.on_pog_sign &&
      (currentSign.distribution.location_filter_info.include_location_info
        .locations.length > 0 ||
        (currentSign.distribution.location_filter_info.include_location_info
          .department_location_groups.length > 0 &&
          currentSign.distribution.location_filter_info.include_location_info
            .department_location_groups[0].department_id !== ''))
    ) {
      setSelectedOnPogMscOption('include')
    } else if (
      currentSign.on_pog_sign &&
      (currentSign.distribution.location_filter_info.exclude_location_info
        .locations.length > 0 ||
        (currentSign.distribution.location_filter_info.exclude_location_info
          .department_location_groups.length > 0 &&
          currentSign.distribution.location_filter_info.exclude_location_info
            .department_location_groups[0].department_id !== ''))
    ) {
      setSelectedOnPogMscOption('exclude')
    }
  }, [
    refreshSharedMscFacets,
    isBulkEdit,
    currentSign.on_pog_sign,
    currentSign.distribution.location_filter_info,
  ])

  const handleChange = (id: string, value: any) => {
    if (id.includes('.department_id')) {
      signDistributionFormik.setFieldValue(id, value)
      signDistributionFormik.setFieldValue(
        id.slice(0, id.lastIndexOf('.')) + '.group_id',
        '',
      )
      signDistributionFormik.setFieldValue(
        id.slice(0, id.lastIndexOf('.')) + '.sub_group_ids',
        [],
      )
    } else if (id.includes('.group_id')) {
      signDistributionFormik.setFieldValue(id, value)
      signDistributionFormik.setFieldValue(
        id.slice(0, id.lastIndexOf('.')) + '.sub_group_ids',
        [],
      )
    } else {
      signDistributionFormik.setFieldValue(id, value)
    }
    addToModifiedSections(SIGN_DETAILS_SECTION.DISTRIBUTION)
    setPageHasChanges(true)
  }

  const resetSelections = (category: string) => {
    handleChange(
      `location_filter_info[${category}_location_info]`,
      new LocationInfo(),
    )
    category === 'include' && handleChange('quantity_per_store', 0)
  }

  const [selectedOnPogMscOption, setSelectedOnPogMscOption] =
    useState<string>('')
  const onPogMscOptions = [
    {
      value: 'include',
      label: 'Include Stores',
    },
    {
      value: 'exclude',
      label: 'Exclude Stores',
    },
  ]

  const isDisabled = (field: string) => {
    if (isBulkEdit) {
      if (selectedSigns && selectedSigns.length > 0) {
        for (var i = 0; i < selectedSigns.length; i++) {
          if (
            !isEditable(
              selectedSigns[i],
              field,
              userPermissions,
              userCompany,
              userType,
              isInProject,
              project.workflow.status,
              undefined,
              project,
            )
          ) {
            return true
          }
        }
        return false
      }
      return true
    } else {
      return !isEditable(
        currentSign,
        field,
        userPermissions,
        userCompany,
        userType,
        isInProject,
        project.workflow.status,
        undefined,
        project,
      )
    }
  }

  return (
    <Fragment>
      <Grid.Item
        className="hc-pv-none hc-ph-normal simple_error_messaging_enabled"
        xs={12}
      >
        <ExpandableSection
          padding="none"
          className={`sem_BlueprintFormCard ${
            modifiedSections.includes(SIGN_DETAILS_SECTION.DISTRIBUTION)
              ? 'modified'
              : ''
          }`}
          expanded={expandedSection === SIGN_DETAILS_SECTION.DISTRIBUTION}
          onExpand={() => {
            setExpandedSection(
              expandedSection === SIGN_DETAILS_SECTION.DISTRIBUTION
                ? ''
                : SIGN_DETAILS_SECTION.DISTRIBUTION,
            )
          }}
        >
          <Grid.Container justify="space-between" align="center">
            <Grid.Item xs={12}>
              <Heading className="hc-clr-grey01 hc-pl-normal" size={5}>
                Distribution & Kitting Information
              </Heading>
            </Grid.Item>
          </Grid.Container>
          <ExpandableSection.Content>
            <Divider className="hc-pa-dense" />
            <div className="hc-pa-dense">
              <Grid.Container
                className={`sem_StandardForm ${
                  isInProject ? 'hc-mb-normal error_messaging_enabled' : ''
                }`}
              >
                <SignKittingForm
                  handleChange={handleChange}
                  isBulkEdit={isBulkEdit}
                  selectedSigns={selectedSigns}
                />

                <Fragment>
                  <Divider
                    className="hc-pv-normal hc-mb-expanded"
                    direction="vertical"
                  />

                  {(isBulkEdit &&
                    (selectedSignsOnPog || selectedSignsNotOnPog)) ||
                  !isBulkEdit ? (
                    <Grid.Item xs={6}>
                      {((!isBulkEdit && signInfoFormik.values.on_pog_sign) ||
                        (isBulkEdit && selectedSignsOnPog)) && (
                        <Fragment>
                          <p className={'sem-info-alert-msg'}>
                            ** You can either add Include or Exclude section,
                            for a On-POG Sign. **
                          </p>
                          <Input.Radio
                            id="msc_selection"
                            value={selectedOnPogMscOption}
                            options={onPogMscOptions}
                            disabled={isDisabled('msc_selection')}
                            className="hc-mb-normal"
                            onUpdate={(id: string, value: string) => {
                              resetSelections(selectedOnPogMscOption)
                              setSelectedOnPogMscOption(value)
                            }}
                          />
                        </Fragment>
                      )}
                      {isBulkEdit &&
                        (selectedOnPogMscOption !== '' ||
                          selectedSignsNotOnPog) && (
                          <Form.Field
                            id="location_filter_info"
                            className="sem_mscCheckbox"
                            type="checkbox"
                            checked={selectedFields.includes(
                              'distribution.location_filter_info',
                            )}
                            disabled={isDisabled('location_filter_info')}
                            onChange={(e: any) => {
                              if (e.target.checked) {
                                setSelectedFields([
                                  ...selectedFields,
                                  'distribution.location_filter_info',
                                ])
                              } else {
                                removeBulkEditField(
                                  'distribution.location_filter_info',
                                )
                              }
                            }}
                          />
                        )}
                      <Grid.Container>
                        {((!isBulkEdit &&
                          signInfoFormik.values.on_pog_sign &&
                          selectedOnPogMscOption === 'include') ||
                          (isBulkEdit &&
                            selectedSignsOnPog &&
                            selectedOnPogMscOption === 'include') ||
                          (!isBulkEdit && !signInfoFormik.values.on_pog_sign) ||
                          (isBulkEdit && selectedSignsNotOnPog)) && (
                          <IncludeLocations
                            isBulkEdit={isBulkEdit}
                            onPogSign={
                              (!isBulkEdit &&
                                signInfoFormik.values.on_pog_sign) ||
                              (isBulkEdit && selectedSignsOnPog)
                            }
                            handleChange={handleChange}
                            errors={signDistributionFormik.errors}
                            resetSelections={resetSelections}
                            selectedSigns={selectedSigns}
                          />
                        )}
                        {((!isBulkEdit &&
                          signInfoFormik.values.on_pog_sign &&
                          selectedOnPogMscOption === 'exclude') ||
                          (isBulkEdit &&
                            selectedSignsOnPog &&
                            selectedOnPogMscOption === 'exclude') ||
                          (!isBulkEdit && !signInfoFormik.values.on_pog_sign) ||
                          (isBulkEdit && selectedSignsNotOnPog)) && (
                          <ExcludeLocations
                            isBulkEdit={isBulkEdit}
                            handleChange={handleChange}
                            resetSelections={resetSelections}
                            selectedSigns={selectedSigns}
                          />
                        )}
                      </Grid.Container>
                    </Grid.Item>
                  ) : (
                    <Grid.Item xs={6}>
                      <p className={'sem-info-alert-msg'}>
                        Store filters are not visible when both onPog and not
                        onPog signs are selected
                      </p>
                    </Grid.Item>
                  )}
                </Fragment>

                {isInProject && !isBulkEdit && (
                  <Divider className="hc-pa-dense" />
                )}
              </Grid.Container>
              {isInProject &&
                !isBulkEdit &&
                (userPermissions.isSignEditor ||
                  userPermissions.isKitEditor ||
                  currentSign.separator === userCompany) && (
                  <Grid.Container
                    className="hc-mt-normal"
                    direction="row-reverse"
                  >
                    <Grid.Item className="hc-pb-dense hc-pt-normal">
                      <Button
                        fullWidth
                        type="primary"
                        size="dense"
                        disabled={
                          !modifiedSections.includes(
                            SIGN_DETAILS_SECTION.DISTRIBUTION,
                          ) ||
                          Object.keys(signDistributionFormik.errors).length > 0
                        }
                        onClick={() => {
                          if (
                            POST_KIT_FINALIZATION.includes(
                              currentSign.workflow.status,
                            )
                          ) {
                            setActionType('Kit Edit')
                            setShowReasonCodeModal(true)
                          } else {
                            signDistributionFormik.handleSubmit()
                          }
                        }}
                      >
                        Save Changes
                      </Button>
                    </Grid.Item>
                  </Grid.Container>
                )}
            </div>
          </ExpandableSection.Content>
        </ExpandableSection>
      </Grid.Item>
      <Grid.Item className="hc-mv-dense" xs={12}>
        <Divider />
      </Grid.Item>
    </Fragment>
  )
}

export default SignDistributionSection
