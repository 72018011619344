import React, { Fragment } from 'react'
import { Button, Grid } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { CancelIcon, SaveIcon } from '@enterprise-ui/icons'
import { useProjectSignListContext } from '../../../context/projectSignListContext'
import { useProjectDetailsContext } from '../../../../../context/projectDetailsContext'
import { validateNoExceptions } from '../../../helpers/signListHelper'

const InlineEditButtons = () => {
  const { currentProject, editReasonRequired, getProjectSigns } =
    useProjectDetailsContext()!
  const {
    signsModified,
    setSignsModified,
    setModifiedSignIds,
    saveModifiedSigns,
    setShowReasonCodeModal,
    setActionType,
    modifiedSigns,
  } = useProjectSignListContext()!

  const handleCancel = () => {
    setModifiedSignIds([])
    setSignsModified(false)
    getProjectSigns(currentProject.project_id)
  }

  const handleSave = () => {
    if (
      editReasonRequired &&
      validateNoExceptions(modifiedSigns, currentProject.workflow.status)
    ) {
      setActionType('Table Editor Save')
      setShowReasonCodeModal(true)
    } else {
      saveModifiedSigns()
      setSignsModified(false)
    }
  }

  return (
    <Fragment>
      <Grid.Item className="hc-ph-dense hc-pb-dense">
        <Button
          type="ghost"
          disabled={!signsModified}
          onClick={() => handleCancel()}
        >
          <EnterpriseIcon className="hc-mr-dense" icon={CancelIcon} />
          Cancel Changes
        </Button>
      </Grid.Item>
      <Grid.Item className="hc-ph-dense hc-pb-dense">
        <Button
          type="ghost"
          disabled={!signsModified}
          onClick={() => handleSave()}
        >
          <EnterpriseIcon className="hc-mr-dense" icon={SaveIcon} />
          Save Changes
        </Button>
      </Grid.Item>
    </Fragment>
  )
}

export default InlineEditButtons
