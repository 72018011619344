import React, { Fragment } from 'react'
import { Card, Grid } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { PlusIcon } from '@enterprise-ui/icons'
import { useBlueprintMilestoneTabContext } from '../../context/blueprintMilestoneTabContext'

export const AddBlueprintMilestoneMenu = () => {
  const { setMilestoneMenu } = useBlueprintMilestoneTabContext()!
  return (
    <Fragment>
      <Card
        elevation={0}
        className="addMilestoneMenu-Card"
        onClick={() => {
          setMilestoneMenu('ADD_NEW_MILESTONE')
        }}
      >
        <Grid.Container spacing="none">
          <Grid.Item xs={1}>
            <EnterpriseIcon
              className="addMilestoneOptions-Icon hc-clr-grey02"
              icon={PlusIcon}
            />
          </Grid.Item>
          <Grid.Item xs>
            <p className="hc-fs-md hc-clr-grey02">
              Add Milestone from Existing Templates
            </p>
          </Grid.Item>
        </Grid.Container>
      </Card>
      <Card
        elevation={0}
        className="addMilestoneMenu-Card"
        onClick={() => {
          setMilestoneMenu('CREATE_NEW_MILESTONE')
        }}
      >
        <Grid.Container spacing="none">
          <Grid.Item xs={1}>
            <EnterpriseIcon
              className="addMilestoneOptions-Icon hc-clr-grey02"
              icon={PlusIcon}
            />
          </Grid.Item>
          <Grid.Item xs>
            <p className="hc-fs-md hc-clr-grey02">Add New Custom Milestone</p>
          </Grid.Item>
        </Grid.Container>
      </Card>
    </Fragment>
  )
}

export default AddBlueprintMilestoneMenu
