import { MarketingAgilePfn } from './MarketingAgilePfn.model'

export class SAPProject {
  project: string
  project_desc: string
  campaign: string
  campaign_desc: string
  channel_desc: string
  channel_id: string
  employee_resp_id: string
  run_date_end: string
  run_date_strt: string
  marketing_agile_pfn: MarketingAgilePfn[]
  sap_processed_date_time: string
  update_timestamp: number
  migrated: boolean

  constructor(props: any = {}) {
    this.project = props.project || ''
    this.project_desc = props.project_desc || ''
    this.campaign = props.campaign || ''
    this.campaign_desc = props.campaign_desc || ''
    this.channel_desc = props.channel_desc || ''
    this.channel_id = props.channel_id || ''
    this.employee_resp_id = props.employee_resp_id || ''
    this.run_date_end = props.run_date_end || ''
    this.run_date_strt = props.run_date_strt || ''
    this.marketing_agile_pfn = props.marketing_agile_pfn || []
    this.sap_processed_date_time = props.sap_processed_date_time || ''
    this.update_timestamp = props.update_timestamp || 0
    this.migrated = props.migrated || false
  }
}
