import { SignSearchFacets } from '../../../models/signs/SignSearchFacets.model'
import { DropdownOption } from '../../../models/app/DropdownOption.model'
import { SignLibraryFilterFacets } from '../../../models/signLibrary'

export const filterFacetOptions = (
  facetResults: SignLibraryFilterFacets,
  field: string,
) => {
  for (const [key, facets] of Object.entries(facetResults)) {
    if (key === field) {
      return facets
        ?.map(
          (facet: string) =>
            new DropdownOption({ id: facet, value: facet, label: facet }),
        )
        .sort()
    }
  }
}
export const autoCompleteOptions = (
  facetResults: SignSearchFacets,
  field: string,
) =>
  Object.keys(facetResults[field]).map(
    (option: any) =>
      new DropdownOption({ id: option, value: option, label: option }),
  )

export const autoCompleteValues = (values: []) =>
  values.map(
    (value: string) =>
      new DropdownOption({ id: value, value: value, label: value }),
  )

export const formatTextValues = (values: string[]) => {
  return values.map((value) => value.replaceAll(' ', ''))
}

export const formatDpcis = (dpcis: string[]) => {
  return dpcis.map((dpci) => {
    const standardDpci = dpci.replaceAll('-', '').padStart(9, '0')
    return standardDpci
      .substring(0, 3)
      .concat('-')
      .concat(standardDpci.substring(3, 5))
      .concat('-')
      .concat(standardDpci.substring(5, 9))
  })
}
