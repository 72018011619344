import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { Form, Grid } from '@enterprise-ui/canvas-ui-react'
import { Column } from 'ag-grid-community'
import { SIGN_STATUS_OPTION } from 'components/SignDetails/constants/signDetailsConstants'

export interface Props {
  value: any
  column: Column
}

const SignStatusEditor = forwardRef(({ value, column }: Props, ref) => {
  const [editValue, setEditValue] = useState(value)
  const refInput = useRef<HTMLInputElement>(null)

  useEffect(() => {
    setTimeout(() => refInput?.current?.focus())
  }, [])

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return editValue
      },
    }
  })

  return (
    <div className="hc-pa-dense">
      <Grid.Container className="sem_StandardForm error_messaging_enabled">
        <Grid.Item xs={12}>
          <Form.Field
            id="sign_status_editor"
            type="select"
            label={column.getColDef().headerName}
            ref={refInput}
            value={editValue}
            options={SIGN_STATUS_OPTION}
            onUpdate={(id: string, value: string) => setEditValue(value)}
            size="dense"
          />
        </Grid.Item>
      </Grid.Container>
    </div>
  )
})

export default SignStatusEditor
