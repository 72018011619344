import Axios from 'axios'
import React, { createContext, useState, useContext, useCallback } from 'react'
import { connect } from 'react-redux'
import {
  ADMIN_SERVICE_API_DOMAIN_URL,
  TOASTER_DEFAULTS,
} from '../../../App/constants/appConstants'
import { useEnv } from '@praxis/component-runtime-env'
import { CampaignTypeResponse } from '../../../../models/campaigns/CampaignTypeResponse.model'
import { CampaignTypeOptions } from '../constants/adminCampaignConstants'
import { ParentCampaignType } from '../../../../models/campaigns/ParentCampaignType.model'
import { ChildCampaignType } from '../../../../models/campaigns/ChildCampaignType.model'
import { RosterElement } from '../../../../models/roster/RosterElement.model'
import { cloneDeep, remove } from 'lodash'
import { RosterUser } from '../../../../models/roster/RosterUser.model'
import { useUserContext } from '../../../App/context/userContext'
import { CampaignTypeRequest } from '../../../../models/campaigns/CampaignTypeRequest.model'
import { useAppContext } from '../../../App/context/appContext'
import { DialogProps } from '../../../../models/app/DialogProps.model'
import { useToaster } from '@enterprise-ui/canvas-ui-react'

type ContextType = {
  allCampaignList: CampaignTypeResponse[]
  setAllCampaignList: Function
  getAllCampaignList: (pyramidIds?: Array<number>) => void

  parentCampaignList: CampaignTypeResponse[]
  setParentCampaignList: Function
  getParentCampaignList: (pyramidIds?: Array<number>) => void

  childCampaignList: CampaignTypeResponse[]
  setChildCampaignList: Function
  getChildCampaignList: (pyramidIds?: Array<number>) => void

  pyramidList: any[]
  setPyramidList: Function
  getPyramidList: () => void

  pyramidFilter: Array<number>
  setPyramidFilter: Function
  campaignType: string
  setCampaignType: Function

  currentCampaign: CampaignTypeResponse
  setCurrentCampaign: (campaign: CampaignTypeResponse) => void

  parentCampaign: ParentCampaignType
  setParentCampaign: Function
  getParentCampaign: (campaignId: string) => void

  childCampaign: ChildCampaignType
  setChildCampaign: Function
  getChildCampaign: (campaignId: string) => void

  updateCampaign: (campaignId: string, campaign: CampaignTypeRequest) => void
  deleteCampaign: (campaignId: string) => void
  deleteNewCampaign: () => void

  availableChildCampaignList: CampaignTypeResponse[]
  setAvailableChildCampaignList: Function
  getAvailableChildCampaignList: (pyramidIds: Array<number>) => void

  isLoadingParentCampaign: boolean
  setIsLoadingParentCampaign: Function
  isLoadingChildCampaign: boolean
  setIsLoadingChildCampaign: Function
  isLoadingCampaignList: boolean
  setIsLoadingCampaignList: Function

  newCampaign: ParentCampaignType
  setNewCampaignGroup: Function
  deleteFromRoster: (user: RosterUser, title: string) => void
  addNewCampaign: (campaign: ParentCampaignType) => void

  rosterTemplates: RosterElement[]
  setRosterTemplates: Function
  getRosterTemplates: () => void

  cascadeModalOpen: boolean
  setCascadeModalOpen: (isOpen: boolean) => void

  rosterWillCascade: boolean
  setRosterWillCascade: (willCascade: boolean) => void
}

export const AdminCampaignsContext = createContext<ContextType | undefined>(
  undefined,
)

type Props = {
  children: React.ReactNode
}

export const AdminCampaignsProviderComponent = ({ children }: Props) => {
  const { userName } = useUserContext()!
  const { setDialogProps, setFullPageLoadingMessage } = useAppContext()!
  const env = useEnv()
  const makeToast = useToaster()
  const [allCampaignList, setAllCampaignList] = useState<
    CampaignTypeResponse[]
  >([])
  const [parentCampaignList, setParentCampaignList] = useState<
    CampaignTypeResponse[]
  >([])
  const [childCampaignList, setChildCampaignList] = useState<
    ChildCampaignType[]
  >([])
  const [pyramidList, setPyramidList] = useState<any[]>([])
  const [pyramidFilter, setPyramidFilter] = useState<Array<number>>([])
  const [campaignType, setCampaignType] = useState(CampaignTypeOptions.ALL)
  const [currentCampaign, setCurrentCampaign] = useState(
    new CampaignTypeResponse(),
  )
  const [parentCampaign, setParentCampaign] = useState(new ParentCampaignType())
  const [childCampaign, setChildCampaign] = useState(new ChildCampaignType())
  const [availableChildCampaignList, setAvailableChildCampaignList] = useState<
    CampaignTypeResponse[]
  >([])
  const [isLoadingParentCampaign, setIsLoadingParentCampaign] = useState(false)
  const [isLoadingChildCampaign, setIsLoadingChildCampaign] = useState(false)
  const [isLoadingCampaignList, setIsLoadingCampaignList] = useState(false)
  const [newCampaign, setNewCampaignGroup] = useState(new ParentCampaignType())
  const [rosterTemplates, setRosterTemplates] = useState<RosterElement[]>([])
  const [cascadeModalOpen, setCascadeModalOpen] = useState(false)
  const [rosterWillCascade, setRosterWillCascade] = useState(false)

  const getAllCampaignList = useCallback(
    async (pyramidIds?: Array<number>) => {
      setIsLoadingCampaignList(true)
      try {
        if (
          (pyramidIds && pyramidIds.length === 0) ||
          pyramidIds === undefined
        ) {
          const response = await Axios.get(
            `${
              env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
            }/campaign_types/search`,
          )
          setAllCampaignList(
            response.data.map(
              (campaign: any) => new CampaignTypeResponse(campaign),
            ),
          )
          setCurrentCampaign(response.data[0])
        } else {
          const response = await Axios.get(
            `${
              env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
            }/campaign_types/search?pyramid_id=${pyramidIds}`,
          )
          setAllCampaignList(
            response.data.map(
              (campaign: any) => new CampaignTypeResponse(campaign),
            ),
          )
          setCurrentCampaign(response.data[0])
        }
      } catch (err: any) {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'error',
          heading: 'Failed to Get All Campaigns',
          message: err.response.data.message,
        })
      }
      setIsLoadingCampaignList(false)
    },
    [makeToast, env.apiDomainUrl],
  )

  const getParentCampaignList = useCallback(
    async (pyramidIds?: Array<number>) => {
      setIsLoadingCampaignList(true)
      try {
        if (
          (pyramidIds && pyramidIds.length === 0) ||
          pyramidIds === undefined
        ) {
          const response = await Axios.get(
            `${
              env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
            }/campaign_types/search?is_parent=true`,
          )
          setParentCampaignList(
            response.data.map(
              (campaign: any) => new ParentCampaignType(campaign),
            ),
          )
        } else {
          const response = await Axios.get(
            `${
              env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
            }/campaign_types/search?pyramid_id=${pyramidIds}&is_parent=true`,
          )
          setParentCampaignList(
            response.data.map(
              (campaign: any) => new ParentCampaignType(campaign),
            ),
          )
        }
      } catch (err: any) {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'error',
          heading: 'Failed to Get Parent Campaigns',
          message: err.response.data.message,
        })
      }
      setIsLoadingCampaignList(false)
    },
    [makeToast, env.apiDomainUrl],
  )

  const getChildCampaignList = useCallback(
    async (pyramidIds?: Array<number>) => {
      setIsLoadingCampaignList(true)
      try {
        if (
          (pyramidIds && pyramidIds.length === 0) ||
          pyramidIds === undefined
        ) {
          const response = await Axios.get(
            `${
              env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
            }/campaign_types/search?is_parent=false`,
          )
          setChildCampaignList(
            response.data.map(
              (campaign: any) => new ChildCampaignType(campaign),
            ),
          )
        } else {
          const response = await Axios.get(
            `${
              env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
            }/campaign_types/search?pyramid_id=${pyramidIds}&is_parent=false`,
          )
          setChildCampaignList(
            response.data.map(
              (campaign: any) => new ChildCampaignType(campaign),
            ),
          )
        }
      } catch (err: any) {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'error',
          heading: 'Failed to Get Child Campaigns',
          message: err.response.data.message,
        })
      }
      setIsLoadingCampaignList(false)
    },
    [makeToast, env.apiDomainUrl],
  )

  const getPyramidList = useCallback(async () => {
    try {
      const response = await Axios.get(
        `${
          env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
        }/merchandise_hierarchy/pyramids`,
      )
      setPyramidList(
        response.data.map((pyramid: any) => ({
          value: pyramid.group_id,
          label: pyramid.group_name,
        })),
      )
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Get Pyramids',
        message: err.response.data.message,
      })
    }
  }, [makeToast, env.apiDomainUrl])

  const getParentCampaign = useCallback(
    async (campaignId: string) => {
      setIsLoadingParentCampaign(true)
      try {
        const response = await Axios.get(
          `${
            env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
          }/campaign_types/${campaignId}`,
        )
        setParentCampaign(new ParentCampaignType(response.data))
      } catch (err: any) {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'error',
          heading: 'Failed to Get Parent Campaign',
          message: err.response.data.message,
        })
      }
      setIsLoadingParentCampaign(false)
    },
    [makeToast, env.apiDomainUrl],
  )

  const getChildCampaign = useCallback(
    async (campaignId: string) => {
      setIsLoadingChildCampaign(true)
      try {
        const response = await Axios.get(
          `${
            env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
          }/campaign_types/${campaignId}`,
        )
        if (response.data.roster && response.data.roster.length === 0) {
          setChildCampaign(
            new ChildCampaignType({
              ...response.data,
              roster: rosterTemplates,
            }),
          )
        } else {
          setChildCampaign(new ChildCampaignType(response.data))
        }
      } catch (err: any) {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'error',
          heading: 'Failed to Get Child Campaign',
          message: err.response.data.message,
        })
      }
      setIsLoadingChildCampaign(false)
    },
    [makeToast, rosterTemplates, env.apiDomainUrl],
  )

  const updateCampaign = async (
    campaignId: string,
    campaign: CampaignTypeRequest,
  ) => {
    setFullPageLoadingMessage('Loading...')
    try {
      await Axios.put(
        `${
          env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
        }/campaign_types/${campaignId}`,
        campaign,
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      setAllCampaignList((previousState: CampaignTypeResponse[]) =>
        previousState.map((campaignType: CampaignTypeResponse) =>
          campaignType.id === campaignId
            ? new CampaignTypeResponse({
                id: campaignId,
                name: campaign.name,
                parent: campaign.parent,
                pyramids: campaign.pyramids,
                type: campaignType.type,
                non_adjacency: campaignType.non_adjacency,
              })
            : campaignType,
        ),
      )
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'success',
        heading: 'Campaign Updated',
        message: 'Successfully updated campaign',
      })
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Save Campaign',
        message: err.response.data.message,
      })
    }
    setFullPageLoadingMessage('')
  }

  const deleteCampaign = async (campaignId: string) => {
    setFullPageLoadingMessage('Removing Campaign...')

    try {
      await Axios.delete(
        `${
          env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
        }/campaign_types/${campaignId}`,
      )
      setAllCampaignList((previousState: CampaignTypeResponse[]) =>
        previousState.filter((campaign) => campaign.id !== campaignId),
      )
      setParentCampaignList((previousState: CampaignTypeResponse[]) =>
        previousState.filter((campaign) => campaign.id !== campaignId),
      )
      setCurrentCampaign(parentCampaignList[0])
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Remove Campaign',
        message: err.response.data.message,
      })
    }
    setDialogProps(new DialogProps())
    setFullPageLoadingMessage('')
  }

  const deleteNewCampaign = () => {
    setFullPageLoadingMessage('Removing Campaign...')
    setNewCampaignGroup(new ParentCampaignType())
    setDialogProps(new DialogProps())
    setFullPageLoadingMessage('')
  }

  const getAvailableChildCampaignList = useCallback(
    async (pyramidIds?: Array<number>) => {
      setIsLoadingParentCampaign(true)
      try {
        if (
          (pyramidIds && pyramidIds.length === 0) ||
          pyramidIds === undefined
        ) {
          const response = await Axios.get(
            `${
              env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
            }/campaign_types/search?is_parent=false`,
          )
          setAvailableChildCampaignList(
            response.data.map(
              (campaign: any) => new CampaignTypeResponse(campaign),
            ),
          )
        } else {
          const response = await Axios.get(
            `${
              env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
            }/campaign_types/search?pyramid_id=${pyramidIds}&is_parent=false`,
          )
          setAvailableChildCampaignList(
            response.data.map(
              (campaign: any) => new CampaignTypeResponse(campaign),
            ),
          )
        }
      } catch (err: any) {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'error',
          heading: 'Failed to Get Available Child Campaigns',
          message: err.response.data.message,
        })
      }
      setIsLoadingParentCampaign(false)
    },
    [makeToast, env.apiDomainUrl],
  )

  const deleteFromRoster = (user: RosterUser, title: string) => {
    setChildCampaign((previousState: ChildCampaignType) => {
      const clonedState = cloneDeep(previousState)
      const currentRosterElement = clonedState.roster.find(
        (rosterElement: RosterElement) => rosterElement.title === title,
      )
      currentRosterElement && remove(currentRosterElement.users, user)
      return clonedState
    })
  }

  const addNewCampaign = async (campaign: ParentCampaignType) => {
    try {
      const requestBody = {
        name: campaign.name,
        pyramids: campaign.pyramids,
        roster: [],
        parent: true,
        non_adjacency: false,
        child_types: campaign.child_types,
        updated_by: userName,
      }
      await Axios.post(
        `${ADMIN_SERVICE_API_DOMAIN_URL}/campaign_types`,
        requestBody,
      )
      setNewCampaignGroup(new ParentCampaignType())
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Add New Campaign',
        message: err.response.data.message,
      })
    }
  }

  const getRosterTemplates = useCallback(async () => {
    try {
      const response = await Axios.get(
        `${
          env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
        }/campaign_types/roster_templates`,
      )
      setRosterTemplates(
        response.data.map((roster: any) => new RosterElement(roster)),
      )
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Get Roster Template',
        message: err.response.data.message,
      })
    }
  }, [makeToast, env.apiDomainUrl])

  return (
    <AdminCampaignsContext.Provider
      value={{
        allCampaignList,
        setAllCampaignList,
        getAllCampaignList,

        parentCampaignList,
        setParentCampaignList,
        getParentCampaignList,

        childCampaignList,
        setChildCampaignList,
        getChildCampaignList,

        pyramidList,
        setPyramidList,
        getPyramidList,

        pyramidFilter,
        setPyramidFilter,
        campaignType,
        setCampaignType,

        currentCampaign,
        setCurrentCampaign,

        parentCampaign,
        setParentCampaign,
        getParentCampaign,

        childCampaign,
        setChildCampaign,
        getChildCampaign,

        updateCampaign,
        deleteCampaign,
        deleteNewCampaign,

        availableChildCampaignList,
        setAvailableChildCampaignList,
        getAvailableChildCampaignList,

        isLoadingParentCampaign,
        setIsLoadingParentCampaign,
        isLoadingChildCampaign,
        setIsLoadingChildCampaign,
        isLoadingCampaignList,
        setIsLoadingCampaignList,

        newCampaign,
        setNewCampaignGroup,
        deleteFromRoster,
        addNewCampaign,

        rosterTemplates,
        setRosterTemplates,
        getRosterTemplates,

        cascadeModalOpen,
        setCascadeModalOpen,

        rosterWillCascade,
        setRosterWillCascade,
      }}
    >
      {children}
    </AdminCampaignsContext.Provider>
  )
}

export const useAdminCampaignsContext = () => useContext(AdminCampaignsContext)

export const AdminCampaignsProvider = connect(
  null,
  null,
)(AdminCampaignsProviderComponent)
