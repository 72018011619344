import Axios from 'axios'
import React, { createContext, useState, useContext, useCallback } from 'react'
import { connect } from 'react-redux'
import {
  PROJECT_SERVICE_API_DOMAIN_URL,
  TOASTER_DEFAULTS,
} from '../../App/constants/appConstants'
import { useEnv } from '@praxis/component-runtime-env'
import { BlueprintDetail } from '../../../models/blueprints/BlueprintDetail.model'
import { ProjectDetail } from '../../../models/projects/ProjectDetail.model'
import { useAppContext } from '../../App/context/appContext'
import { useToaster } from '@enterprise-ui/canvas-ui-react'

type ContextType = {
  currentBlueprint: BlueprintDetail
  setCurrentBlueprint: Function
  signProjects: ProjectDetail[]
  setSignProjects: Function
  getProjectList: () => void
  isLoadingSignProjects: boolean
  setIsLoadingSignProjects: (isLoading: boolean) => void
  refreshCurrentBlueprint: (id: string, type?: string) => void
  currentProjectId: string
  setCurrentProjectId: Function
}

export const BlueprintDetailsContainerContext = createContext<
  ContextType | undefined
>(undefined)

type Props = {
  children: React.ReactNode
}

export const BlueprintDetailsContainerProviderComponent = ({
  children,
}: Props) => {
  const { setFullPageLoadingMessage } = useAppContext()!
  const env = useEnv<any>()
  const makeToast = useToaster()
  const [currentBlueprint, setCurrentBlueprint] = useState(
    new BlueprintDetail(),
  )
  const [signProjects, setSignProjects] = useState<ProjectDetail[]>([])
  const [isLoadingSignProjects, setIsLoadingSignProjects] = useState(false)
  const [currentProjectId, setCurrentProjectId] = useState('')

  const refreshCurrentBlueprint = useCallback(
    async (id: string, type?: string) => {
      if (id === 'create') {
        setCurrentBlueprint(new BlueprintDetail())
      } else {
        setFullPageLoadingMessage('Loading Blueprint...')
        try {
          const res = await Axios.get(
            `${
              env.apiDomainUrl + PROJECT_SERVICE_API_DOMAIN_URL
            }/blueprints/${id}`,
          )

          setCurrentBlueprint(
            type === 'duplicate'
              ? new BlueprintDetail({ ...res.data, blueprint_id: undefined })
              : new BlueprintDetail(res.data),
          )
        } catch (err: any) {
          makeToast({
            ...TOASTER_DEFAULTS,
            type: 'error',
            heading: 'Failed to Get Blueprint',
            message: err.response.data.message,
          })
        }
        setFullPageLoadingMessage('')
      }
    },
    [setCurrentBlueprint, setFullPageLoadingMessage, makeToast, env],
  )

  const getProjectList = useCallback(async () => {
    setIsLoadingSignProjects(true)

    try {
      const res = await Axios.get(
        `${
          env.apiDomainUrl + PROJECT_SERVICE_API_DOMAIN_URL
        }/projects?blueprint_id=${currentBlueprint.blueprint_id}`,
      )

      setSignProjects(
        res.data.map((project: any) => new ProjectDetail(project)),
      )
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Get Sign Project',
        message: err.response.data.message,
      })
    }

    setIsLoadingSignProjects(false)
  }, [currentBlueprint.blueprint_id, makeToast, env])

  return (
    <BlueprintDetailsContainerContext.Provider
      value={{
        currentBlueprint,
        setCurrentBlueprint,
        signProjects,
        setSignProjects,
        getProjectList,
        isLoadingSignProjects,
        setIsLoadingSignProjects,
        refreshCurrentBlueprint,
        currentProjectId,
        setCurrentProjectId,
      }}
    >
      {children}
    </BlueprintDetailsContainerContext.Provider>
  )
}

export const useBlueprintDetailsContainerContext = () =>
  useContext(BlueprintDetailsContainerContext)

export const BlueprintDetailsContainerProvider = connect(
  null,
  null,
)(BlueprintDetailsContainerProviderComponent)
