import { useFormik } from 'formik'

export const useDuplicateSignForm = (handleSubmit: (values: any) => void) => {
  const validate = (values: any) => {
    const errors: any = {}
    if (values.numberOfDuplicates > 10) {
      errors.numberOfDuplicates = 'You cannot make more than 10 duplicates'
    }
    if (values.numberOfDuplicates < 1 || !values.numberOfDuplicates) {
      errors.numberOfDuplicates = 'You must make at least 1 duplicate'
    }
    return errors
  }

  return useFormik({
    enableReinitialize: true,
    initialValues: { numberOfDuplicates: 1 },
    validate,
    validateOnChange: false,
    onSubmit(values) {
      handleSubmit(values)
    },
  })
}
