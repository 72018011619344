import React, { Fragment } from 'react'
import { Button, Grid } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { CancelIcon, SaveIcon } from '@enterprise-ui/icons'
import { useUserContext } from '../../App/context/userContext'
import { useDashboardContext } from '../context/dashboardContext'
import { ProjectMilestoneCompleteRequest } from '../../../models/dashboard/ProjectMilestoneCompleteRequest.model'
import { ProjectMilestonesSummary } from '../../../models/dashboard/ProjectMilestoneSummary.model'
import { useAppContext } from 'components/App/context/appContext'
import { TabName } from '../constants/dashboardConstants'
import BlueprintMilestoneCompleteRequest from '../../../models/dashboard/BlueprintMilestoneCompleteRequest.model'

export interface Props {
  gridApi: any
}

const DashboardEditButtons = ({ gridApi }: Props) => {
  const { dashboardActiveTab, setPageHasChanges } = useAppContext()!
  const { userName } = useUserContext()!
  const {
    includeCompleteProjectMilestones,
    includeCompleteBpMilestones,
    modifiedProjects,
    setModifiedProjects,
    modifiedBlueprintMilestones,
    setModifiedBlueprintMilestones,
    saveProjectMilestoneStatus,
    saveBlueprintMilestoneStatus,
    getProjectMilestoneSummaryList,
    getBlueprintMilestoneSummaryList,
  } = useDashboardContext()!

  const handleCancel = () => {
    if (dashboardActiveTab === TabName.MY_PROJECT_MILESTONES) {
      setModifiedProjects([])
      getProjectMilestoneSummaryList(includeCompleteProjectMilestones)
    } else {
      setModifiedBlueprintMilestones([])
      getBlueprintMilestoneSummaryList(includeCompleteBpMilestones)
    }
    setPageHasChanges(false)
  }

  const handleSave = async () => {
    const allRowData: any[] = []
    gridApi.forEachNode((node: any) => allRowData.push(node.data))
    const modifiedRows = allRowData.filter((row) => row['modified'])
    if (dashboardActiveTab === TabName.MY_PROJECT_MILESTONES) {
      await saveProjectMilestoneStatus(
        modifiedRows.map(
          (project: ProjectMilestonesSummary) =>
            new ProjectMilestoneCompleteRequest({
              project_id: project.project_id,
              milestone_id: project.my_milestone?.milestone_id,
              completed: project.complete,
              updated_by: userName,
            }),
        ),
      )
      setModifiedProjects([])
      getProjectMilestoneSummaryList(includeCompleteProjectMilestones)
    } else {
      await saveBlueprintMilestoneStatus(
        modifiedRows.map(
          (blueprint: any) =>
            new BlueprintMilestoneCompleteRequest({
              blueprint_id: blueprint.blueprint_id,
              milestone_id: blueprint.current_milestone?.milestone_id,
              completed: true,
              updated_by: userName,
            }),
        ),
      )
      setModifiedBlueprintMilestones([])
      getBlueprintMilestoneSummaryList(includeCompleteBpMilestones)
    }
  }

  return (
    <Fragment>
      <Grid.Item className="hc-ph-dense hc-pb-dense">
        <Button type="ghost" onClick={() => handleCancel()}>
          <EnterpriseIcon className="hc-mr-dense" icon={CancelIcon} />
          Cancel Changes
        </Button>
      </Grid.Item>
      <Grid.Item className="hc-ph-dense hc-pb-dense">
        <Button
          type="ghost"
          disabled={
            modifiedProjects.length < 1 &&
            modifiedBlueprintMilestones.length < 1
          }
          onClick={() => handleSave()}
        >
          <EnterpriseIcon className="hc-mr-dense" icon={SaveIcon} />
          Save Changes
        </Button>
      </Grid.Item>
    </Fragment>
  )
}

export default DashboardEditButtons
