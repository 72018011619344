import { KitComments } from './KitComments.model'
import { KitReceiveDates } from './KitReceiveDates.model'
import { KitStatus, Carton } from './KitResponse.model'

export class KitRequest {
  kit_id?: string
  kit_vendor?: string
  kit_quantity?: number
  shipping_method?: string
  carton?: Carton
  status: KitStatus
  pre_assembled: boolean
  oversized: boolean
  comments?: KitComments
  receive_dates?: KitReceiveDates
  sign_quantities?: SignQuantity[]
  updated_by: string

  constructor(props: any = {}) {
    this.kit_id = props.kit_id
    this.kit_vendor = props.kit_vendor
    this.kit_quantity = props.kit_quantity
    this.shipping_method = props.shipping_method
    this.carton = props.carton
    this.status = props.status
    this.pre_assembled = props.pre_assembled
    this.oversized = props.oversized
    this.comments = props.comments
    this.receive_dates = props.receive_dates
    this.sign_quantities = props.sign_quantities
    this.updated_by = props.updated_by
  }
}

export class SignQuantity {
  sign_id: string
  sign_quantity: number
  updated_by?: string

  constructor(props: any = {}) {
    this.sign_id = props.sign_id
    this.sign_quantity = props.sign_quantity
    this.updated_by = props.updated_by
  }
}
