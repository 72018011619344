import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import moment from 'moment'
import { Form, Grid } from '@enterprise-ui/canvas-ui-react'
import SapTableProject from '../../../../../../../models/projects/SapTableProject.model'
import { Column } from 'ag-grid-community'

export interface Props {
  value: any
  data: SapTableProject
  column: Column
}

const DateCellEditor = forwardRef(({ value, data, column }: Props, ref) => {
  const [editValue, setEditValue] = useState(value ? value : undefined)
  const refInput = useRef<HTMLInputElement>(null)

  const handleChange = (id: string, value: string) => {
    if (column.getColId() === 'in_store_date') {
      const defaultInStoreDate = moment(data.set_date).subtract(6, 'days')
      if (defaultInStoreDate.isSameOrAfter(moment(value))) {
        setEditValue(value)
      }
    } else {
      setEditValue(value)
    }
  }

  const labelMap: any = {
    set_date: 'Set Date',
    in_store_date: 'In-Store Date',
  }

  useEffect(() => {
    setTimeout(() => refInput?.current?.focus())
  }, [])

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return editValue && moment(editValue).format('YYYY-MM-DD')
      },
      isPopup() {
        return true
      },
    }
  })

  return (
    <div className="hc-pa-dense">
      <Grid.Container className="sem_StandardForm error_messaging_enabled">
        <Grid.Item xs={12}>
          <Form.Field
            id="date_cell_editor"
            type="date"
            label={labelMap[column.getColId()]}
            ref={refInput}
            value={editValue && moment(editValue).format('YYYY-MM-DD')}
            onChange={(e: any) => handleChange(e.target.id, e.target.value)}
            size="dense"
          />
        </Grid.Item>
      </Grid.Container>
    </div>
  )
})

export default DateCellEditor
