import { get } from 'lodash'
import TableEditorFields from './TableEditorFields.model'

export default class TableEditorRequest {
  project_id: string
  reason_code?: string
  signs: TableEditedSign[]
  updated_by: string

  constructor(props: any = {}) {
    this.project_id = props.project_id || ''
    this.reason_code = props.reason_code
    this.signs = get(props, 'signs', []).map(
      (sign: any) => new TableEditedSign(sign),
    )
    this.updated_by = props.updated_by || 'system'
  }
}

export class TableEditedSign {
  action: string
  fields: TableEditorFields
  sign_id: string

  constructor(props: any = {}) {
    this.action = props.action || 'ADD'
    this.fields = new TableEditorFields(props.fields)
    this.sign_id = props.sign_id || ''
  }
}
