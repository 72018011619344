import React, { useState } from 'react'
import { AgGridReact, AgGridColumn } from 'ag-grid-react'
import { GridApi, ColumnApi } from 'ag-grid-community'
import { useAgGridSignListContext } from '../../context/agGridSignListContext'
import { MOVE_SIGNS_VIEW } from '../../views/AgGridMoveSignsView'
import { Column } from '../../../../../../models/agGrid/AgGridColumn.model'

const MoveSignsTable = () => {
  const { selectedRows } = useAgGridSignListContext()!
  const [, setGridApi] = useState<GridApi>()
  const [, setGridColumnApi] = useState<ColumnApi>()

  const onGridReady = (params: any) => {
    setGridApi(params.api)
    setGridColumnApi(params.columnApi)
  }

  const gridOptions = {
    defaultColDef: {
      resizable: true,
      sortable: true,
      filter: true,
      width: 248,
    },
  }

  return (
    <div className="ag-theme-rubix dashboard-table">
      <AgGridReact
        rowData={selectedRows}
        onGridReady={onGridReady}
        suppressCellSelection={true}
        suppressRowClickSelection={true}
        gridOptions={gridOptions}
        applyColumnDefOrder={true}
        animateRows={true}
      >
        {MOVE_SIGNS_VIEW.map((column: Column, index: number) => (
          <AgGridColumn
            field={column.field}
            headerName={column.headerName}
            width={column.width || 148}
            type={column.type}
            colId={column.colId}
            key={index}
          />
        ))}
      </AgGridReact>
    </div>
  )
}

export default MoveSignsTable
