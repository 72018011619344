import React, { Fragment } from 'react'
import { Button, Grid } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { CancelIcon, SaveIcon } from '@enterprise-ui/icons'
import { useBlueprintProjectListContext } from '../../../context/blueprintProjectListContext'
import { useAgGridProjectListContext } from '../../../context/agGridProjectListContext'
import { RowNode } from 'ag-grid-community'

const TableEditorButtons = () => {
  const {
    loadProjectList,
    projectsModified,
    setProjectsModified,
    setModifiedProjects,
    setModifiedProjectIds,
    setProjectModifiedFields,
  } = useBlueprintProjectListContext()!
  const { gridApi, validateProjectsAndSave } = useAgGridProjectListContext()!

  const handleSave = () => {
    const modifiedRowNodes: RowNode[] = []
    gridApi?.forEachNode((node: RowNode) => modifiedRowNodes.push(node))
    validateProjectsAndSave(
      modifiedRowNodes.filter((node) => node.data.modified),
    )
    setProjectsModified(false)
    setProjectModifiedFields([])
  }

  const handleCancel = () => {
    loadProjectList()
    setModifiedProjects([])
    setModifiedProjectIds([])
    setProjectsModified(false)
    setProjectModifiedFields([])
  }

  return (
    <Fragment>
      <Grid.Item className="hc-ph-dense hc-pb-dense">
        <Button
          type="ghost"
          disabled={!projectsModified}
          onClick={() => handleCancel()}
        >
          <EnterpriseIcon className="hc-mr-dense" icon={CancelIcon} />
          Cancel Changes
        </Button>
      </Grid.Item>
      <Grid.Item className="hc-ph-dense hc-pb-dense">
        <Button
          type="ghost"
          disabled={!projectsModified}
          onClick={() => handleSave()}
        >
          <EnterpriseIcon className="hc-mr-dense" icon={SaveIcon} />
          Save Changes
        </Button>
      </Grid.Item>
    </Fragment>
  )
}

export default TableEditorButtons
