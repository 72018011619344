import React from 'react'
import { Button, Dropdown, Grid } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { CaretDownIcon } from '@enterprise-ui/icons'
import { ScheduleTemplate } from '../../../../../../models/milestones/ScheduleTemplate'
import { useBlueprintMilestoneTabContext } from '../../context/blueprintMilestoneTabContext'
import { useAppContext } from 'components/App/context/appContext'
import { DialogProps } from '../../../../../../models/app/DialogProps.model'

export interface Props {
  scheduleTemplateByScheduleTemplateId: ScheduleTemplate
}
const ScheduleTemplateActionsDropdown = ({
  scheduleTemplateByScheduleTemplateId,
}: Props) => {
  const { setDialogProps } = useAppContext()!
  const { setIsScheduleTemplateOpen, removeCustomScheduleTemplate } =
    useBlueprintMilestoneTabContext()!

  return (
    <Grid.Item className="hc-pv-dense">
      <Dropdown size="dense" location="bottom-left">
        <Button type="ghost">
          Actions
          <EnterpriseIcon className="hc-ml-dense" icon={CaretDownIcon} />
        </Button>
        <Dropdown.Menu>
          <Dropdown.MenuItem onClick={() => setIsScheduleTemplateOpen(true)}>
            Save Custom Template
          </Dropdown.MenuItem>
          <Dropdown.MenuItem
            disabled={scheduleTemplateByScheduleTemplateId.master_template}
            onClick={() =>
              setDialogProps(
                new DialogProps({
                  headingText: 'Are you sure you want to remove this template?',
                  approveButtonText: 'Yes',
                  onApprove: () =>
                    scheduleTemplateByScheduleTemplateId.template_id &&
                    removeCustomScheduleTemplate(
                      scheduleTemplateByScheduleTemplateId.template_id,
                    ),
                  refuseButtonText: 'Cancel',
                  onRefuse: () => setDialogProps(new DialogProps()),
                }),
              )
            }
          >
            Remove Custom Template
          </Dropdown.MenuItem>
        </Dropdown.Menu>
      </Dropdown>
    </Grid.Item>
  )
}

export default ScheduleTemplateActionsDropdown
