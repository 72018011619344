import {
  SignFacet,
  SignFacetsResponse,
} from '../../../../../../../models/signs/SignFacetsResponse.model'
import {
  Button,
  Form,
  Grid,
  GridContainer,
  Heading,
  Input,
  Modal,
} from '@enterprise-ui/canvas-ui-react'
import React, { useEffect, useState } from 'react'
import EnterpriseIcon, { CancelIcon } from '@enterprise-ui/icons'
import { useNavigate } from 'react-router'
import SignResponse from '../../../../../../../models/signs/SignResponse.model'
import { useFormik } from 'formik'

export interface Props {
  projectId: string
  actionType: string
  showReasonCode: boolean
  setShowReasonCode: Function
  signIds?: string[]
  setReasonCode: Function
  setIsImportSignsFullScreen?: Function
  setSignForDuplicateModal?: Function
  signToDuplicate?: SignResponse
  deleteSigns?: Function
  signFacets: SignFacetsResponse
  saveModifiedSigns?: Function
  saveBulkSigns?: Function
  setModifiedSignIds?: Function
  setSignsModified?: Function
  updateInfo?: Function
  updateMessaging?: Function
  updatePrinting?: Function
  updateKitting?: Function
  selectedSigns?: SignResponse[]
  closeBulkEdit?: Function
  setSelectedSigns?: Function
  setSelectedStatus?: Function
  setSelectedFields?: Function
  refreshSignList?: Function
  setPageHasChanges?: Function
}

const ReasonCodeModal = ({
  projectId,
  actionType,
  showReasonCode,
  setShowReasonCode,
  signIds,
  setReasonCode,
  setIsImportSignsFullScreen,
  setSignForDuplicateModal,
  signToDuplicate,
  deleteSigns,
  signFacets,
  saveModifiedSigns = () => {},
  saveBulkSigns = () => {},
  setSignsModified = () => {},
  updateInfo = () => {},
  updateMessaging = () => {},
  updatePrinting = () => {},
  updateKitting = () => {},
  selectedSigns = [],
  closeBulkEdit = () => {},
  setSelectedSigns = () => {},
  setSelectedStatus = () => {},
  setSelectedFields = () => {},
  refreshSignList = () => {},
  setPageHasChanges = () => {},
}: Props) => {
  const [reasonCodes, setReasonCodes] = useState<SignFacet[]>([])
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues: { reasonCode: '' },
    onSubmit: (values) => {
      switch (actionType) {
        case 'DeleteSigns':
          deleteSigns!!(signIds!!, values.reasonCode)
          break
        case 'Import':
          setReasonCode(values.reasonCode)
          setIsImportSignsFullScreen!!(true)
          break
        case 'Add':
          setReasonCode(values.reasonCode)
          break
        case 'Duplicate':
          setReasonCode(values.reasonCode)
          setSignForDuplicateModal!!(signToDuplicate!!)
          break
        case 'Table Editor Save':
          setReasonCode(values.reasonCode)
          saveModifiedSigns()
          setSignsModified(false)
          break
        case 'Bulk':
          setReasonCode(values.reasonCode)
          saveBulkSigns(
            selectedSigns.map((sign: SignResponse) => sign.sign_id),
            projectId,
            values.reasonCode,
          )
          closeBulkEdit()
          setSelectedSigns([])
          setSelectedStatus('')
          setSelectedFields([])
          refreshSignList()
          setPageHasChanges(false)
          break
        case 'Info Edit':
          setReasonCode(values.reasonCode)
          updateInfo()
          break
        case 'Msg Edit':
          setReasonCode(values.reasonCode)
          updateMessaging()
          break
        case 'Print Edit':
          setReasonCode(values.reasonCode)
          updatePrinting()
          break
        case 'Kit Edit':
          setReasonCode(values.reasonCode)
          updateKitting()
          break
        default:
          break
      }
      formik.resetForm(undefined)
      setShowReasonCode(false)
    },
  })

  const reasonCodeLabel = (actionType: string) => {
    return actionType === 'Remove'
      ? 'Removing Sign(s)'
      : actionType === 'Restore'
      ? 'Restoring Sign(s)'
      : actionType === 'Import'
      ? 'Importing Sign(s)'
      : actionType === 'Add'
      ? 'Adding New Sign'
      : actionType === 'Duplicate'
      ? 'Duplicating Sign'
      : actionType === 'Kit Edit'
      ? 'Updating Kitting Info'
      : 'Updating Sign(s)'
  }

  const handleChange = (id: string, value: any) => {
    formik.setFieldValue(id, value)
  }

  const handleProceed = () => formik.handleSubmit()

  const handleCancel = () => {
    if (actionType === 'Add') {
      navigate(`/projects/${projectId}?tab=SIGN+LIST`)
    } else {
      formik.resetForm(undefined)
      setShowReasonCode(false)
    }
  }

  useEffect(() => {
    if (actionType === 'Remove') {
      setReasonCodes(signFacets.remove_sign_reason_code)
    } else {
      setReasonCodes(signFacets.new_sign_reason_code)
    }
  }, [actionType, signFacets])

  return (
    // WARNING: Canvas v7 - Modal "closeButton" prop has been deprecated.
    <Modal
      onRefuse={() => handleCancel()}
      isVisible={showReasonCode}
      className="sem_KitScenarioModal"
    >
      <div className="hc-pa-normal">
        <Grid.Container justify="space-between">
          <Grid.Item>
            <Heading className="hc-clr-grey01" size={4}>
              {`Select Reason for ${reasonCodeLabel(actionType)}`}
            </Heading>
          </Grid.Item>
          {actionType !== 'Add' && (
            <Grid.Item align="right">
              <Button
                aria-label="Cancel reason code"
                iconOnly
                onClick={() => {
                  formik.resetForm(undefined)
                  setShowReasonCode(false)
                }}
              >
                <EnterpriseIcon icon={CancelIcon} />
              </Button>
            </Grid.Item>
          )}
        </Grid.Container>
        <Form id="sem_KitScenarioForm">
          <GridContainer align="center">
            <Grid.Item xs={12}>
              <Input.Label>Reason Code</Input.Label>
              <Input.Select
                id="reasonCode"
                options={reasonCodes}
                onUpdate={(id: string, value: string) => {
                  handleChange(id, value)
                }}
                value={formik.values.reasonCode}
              />
            </Grid.Item>
          </GridContainer>
          <GridContainer className="hc-pv-expanded" justify="center">
            <Grid.Item xs={6} className="hc-pr-expanded">
              <Button fullWidth type="secondary" onClick={() => handleCancel()}>
                Cancel
              </Button>
            </Grid.Item>
            <Grid.Item xs={6} className="hc-pr-expanded">
              <Button
                fullWidth
                type="primary"
                disabled={
                  formik.values.reasonCode === undefined ||
                  formik.values.reasonCode === ''
                }
                onClick={() => handleProceed()}
              >
                Proceed
              </Button>
            </Grid.Item>
          </GridContainer>
        </Form>
      </div>
    </Modal>
  )
}

export default ReasonCodeModal
