import React from 'react'
import RubixChip from 'components/common/RubixChip'

export interface Props {
  value: string
}

const TableChip = ({ value = '' }: Props) => {
  const signStatusChipColor = (status: string) => {
    switch (status) {
      case 'New':
      case 'Active':
        return 'green'
      case 'Carry Forward':
      case 'In Development':
        return 'blue'
      case 'Reissue':
        return 'yellow'
      case 'Remove':
      case 'Obsolete':
        return 'red'
      default:
        return 'grey'
    }
  }

  return <RubixChip color={signStatusChipColor(value)}>{value}</RubixChip>
}

export default TableChip
