import { BlueprintCampaign } from './BlueprintCampaign.model'
import { RosterElement } from '../roster/RosterElement.model'

export class BlueprintRequest {
  name: string
  status: string
  set_date: string
  type: string
  planned_budget: number
  is_tier_one: boolean
  is_incremental: boolean
  campaign: BlueprintCampaign
  roster: RosterElement[]
  sign_volume_estimate: string
  version_complexity: string
  creative_approval_level: string
  updated_by: string

  constructor(props: any = {}) {
    this.name = props.name || ''
    this.status = props.status || ''
    this.set_date = props.set_date || ''
    this.type = props.type || ''
    this.planned_budget = props.planned_budget || 0
    this.is_tier_one = props.is_tier_one || false
    this.is_incremental = props.is_incremental || false
    this.campaign = props.campaign || null
    this.roster = props.roster || []
    this.sign_volume_estimate = props.sign_volume_estimate || ''
    this.version_complexity = props.version_complexity || ''
    this.creative_approval_level = props.creative_approval_level || ''
    this.updated_by = props.updated_by || ''
  }
}
