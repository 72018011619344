import React, { Fragment, useEffect } from 'react'
import { Button, Form, Grid, Input } from '@enterprise-ui/canvas-ui-react'
import { v4 as uuidv4 } from 'uuid'
import { useUserContext } from 'components/App/context/userContext'
import { useMilestoneContext } from 'components/App/context/milestoneContext'
import { MilestoneTemplate } from '../../../../../models/milestones/MilestoneTemplate.model'
import { useAdminMilestoneContext } from '../../context/adminMilestoneContext'
import { useNewAdminMilestoneForm } from '../../hooks/useNewAdminMilestoneForm'

const CreateAdminMilestoneForm = () => {
  const { userName } = useUserContext()!
  const { milestoneFacets, getMilestoneFacets, milestoneTemplates } =
    useMilestoneContext()!
  const { addNewMilestoneTemplate, closeMilestoneForm } =
    useAdminMilestoneContext()!

  useEffect(() => {
    getMilestoneFacets()
  }, [getMilestoneFacets])

  const projectOnlyPhases = ['Sign List Finalization', 'Production to Market']

  const newMilestone = new MilestoneTemplate({
    milestone_id: uuidv4(),
    name: '',
    project_phase: '',
    role: '',
    completion_level: '',
    custom_milestone: false,
    created_by: userName,
    created_date: new Date().toISOString(),
    updated_by: userName,
    updated_date: new Date().toISOString(),
    critical: false,
    risk: false,
    status: 'Upcoming',
    days_out: 0,
    information_only: false,
  })

  const { setFieldValue, handleSubmit, values, errors } =
    useNewAdminMilestoneForm(
      newMilestone,
      addNewMilestoneTemplate,
      closeMilestoneForm,
    )

  const milestoneTemplateFacets = milestoneTemplates.map(
    (template: MilestoneTemplate) => {
      return { value: template.name, label: template.name }
    },
  )
  milestoneTemplateFacets.unshift({ value: '', label: '' })

  return (
    <Fragment>
      <Form>
        <Grid.Container className="sem-CreateMilestoneForm" align="flex-end">
          <Grid.Item className="hc-pb-none" xs={12}></Grid.Item>
          <Grid.Item className="hc-pv-dense" xs>
            <Form.Field
              id="name"
              label="Milestone Name"
              value={values.name}
              error={errors.name !== undefined}
              onChange={(e: any) => setFieldValue('name', e.target.value)}
              required
            />
          </Grid.Item>
          <Grid.Item className="hc-pv-dense" xs={12}>
            <Form.Field
              type="select"
              id="phase"
              label="Project Phase"
              value={values.project_phase}
              error={errors.project_phase !== undefined}
              options={milestoneFacets.project_phases}
              required
              onUpdate={(e: any, phase: string) => {
                if (!projectOnlyPhases.includes(phase)) {
                  setFieldValue('completion_level', 'BLUEPRINT')
                } else {
                  setFieldValue('completion_level', 'PROJECT')
                }
                setFieldValue('project_phase', phase)
              }}
            />
          </Grid.Item>
          <Grid.Item className="hc-pv-dense" xs={12}>
            <Form.Field
              type="select"
              id="role"
              label="Role"
              value={values.role}
              error={errors.role !== undefined}
              options={milestoneFacets.roles}
              onUpdate={(e: any, role: string) => setFieldValue('role', role)}
              required
            />
          </Grid.Item>
          <Grid.Item className="hc-clr-grey02" xs={12}>
            <Form.Field
              type="select"
              id="dependsOn"
              label="Depends On"
              value={values.depends_on}
              options={milestoneTemplateFacets}
              error={errors.depends_on !== undefined}
              onUpdate={(e: any, dependsOn: string) => {
                setFieldValue('depends_on', dependsOn)
              }}
            />
          </Grid.Item>
          <Grid.Item xs={4}>
            <Input.Checkbox
              id="critical"
              label="Critical"
              checked={values.critical}
              onChange={(e: any) => {
                setFieldValue('critical', !values.critical)
                setFieldValue('risk', !values.risk)
              }}
            />
          </Grid.Item>
          <Grid.Item xs={6}>
            <Input.Checkbox
              id="info_only"
              label="Information Only"
              checked={values.information_only}
              onChange={(e: any) => {
                setFieldValue('information_only', !values.information_only)
              }}
            />
          </Grid.Item>

          <Grid.Item xs={12}>
            <Input.Checkbox
              id="auto_complete"
              label="Auto-Completed"
              checked={values.auto_check}
              onChange={(e: any) => {
                setFieldValue('auto_check', !values.auto_check)
              }}
            />
          </Grid.Item>

          <Grid.Item xs={12}>
            <Button
              fullWidth
              type="primary"
              className="save-Button"
              onClick={() => handleSubmit()}
            >
              Add Milestone
            </Button>
          </Grid.Item>
        </Grid.Container>
      </Form>
    </Fragment>
  )
}

export default CreateAdminMilestoneForm
