import React, { Fragment } from 'react'
import {
  Button,
  Grid,
  Form,
  ExpandableSection,
  Heading,
  Card,
  Divider,
  Spinner,
  Input,
} from '@enterprise-ui/canvas-ui-react'
import { useAdminCampaignsContext } from '../context/adminCampaignsContext'
import EditableRoster from '../../../Roster'
import { useUserContext } from '../../../App/context/userContext'
import { RosterUser } from '../../../../models/roster/RosterUser.model'
import { RosterElement } from '../../../../models/roster/RosterElement.model'
import { useAppContext } from '../../../App/context/appContext'
import { CampaignTypeRequest } from '../../../../models/campaigns/CampaignTypeRequest.model'
import CascadeModal from './CascadeModal'

const CampaignTypeForm = () => {
  const { userPermissions, userName } = useUserContext()!
  const { setPageHasChanges } = useAppContext()!
  const {
    pyramidList,
    childCampaign,
    setChildCampaign,
    updateCampaign,
    deleteFromRoster,
    isLoadingChildCampaign,
    setCascadeModalOpen,
  } = useAdminCampaignsContext()!

  const addToRoster = (user: RosterUser, userRole: string) => {
    setChildCampaign({
      ...childCampaign,
      roster: childCampaign.roster.map((rosterElement: RosterElement) => {
        return new RosterElement({
          type: rosterElement.type,
          title: rosterElement.title,
          users:
            rosterElement.title === userRole
              ? [...rosterElement.users, user]
              : rosterElement.users,
        })
      }),
    })
  }

  const childCampaignTypeRequest = new CampaignTypeRequest({
    name: childCampaign.name,
    pyramids: childCampaign.pyramids,
    roster: childCampaign.roster,
    parent: false,
    non_adjacency: childCampaign.non_adjacency,
    child_type: [],
    updated_by: userName,
  })

  return (
    <Fragment>
      <Grid.Container>
        <Grid.Item align="right" xs>
          <Button
            type="primary"
            className="saveCampaign-Button"
            onClick={() => {
              updateCampaign(childCampaign.id, childCampaignTypeRequest)
              setPageHasChanges(false)
            }}
          >
            Save Campaign Type
          </Button>
        </Grid.Item>
        <Grid.Item xs={12}>
          <div className="hc-pa-dense">
            <Grid.Container>
              <Grid.Item xs={5}>
                <Form.Field
                  id="name"
                  label="Campaign Type"
                  value={childCampaign.name}
                  onChange={(e: any) => {
                    setChildCampaign({
                      ...childCampaign,
                      name: e.target.value,
                    })
                    setPageHasChanges(true)
                  }}
                  required
                />
              </Grid.Item>
              <Grid.Item xs={2}>
                <label>Non-Adjacency</label>
                <Input.Toggle
                  aria-label="Non-Adjacency"
                  id="nonAdjacency"
                  checked={childCampaign.non_adjacency}
                  onChange={(e: any) => {
                    if (childCampaign.non_adjacency) {
                      setChildCampaign({
                        ...childCampaign,
                        non_adjacency: false,
                        pyramids: [
                          {
                            group_id: 0,
                            group_name: 'NON RETAIL',
                          },
                        ],
                      })
                      setPageHasChanges(true)
                    } else {
                      setChildCampaign({
                        ...childCampaign,
                        non_adjacency: true,
                        pyramids: null,
                      })
                      setPageHasChanges(true)
                    }
                  }}
                />
              </Grid.Item>
              <Grid.Item xs={5}>
                <Card className={'sem-CampaignPyramidCard'} elevation={0}>
                  <ExpandableSection padding="dense">
                    <Heading className="sem-RosterTitle hc-pr-normal" size={6}>
                      Pyramids
                    </Heading>
                    <ExpandableSection.Content>
                      <Divider />
                      <div className="hc-pa-normal">
                        <Input.Checkboxes
                          id="campaign_pyramids"
                          options={pyramidList}
                          onUpdate={(e: any, value: any) => {
                            if (value.includes(0)) {
                              const withoutZero = pyramidList
                                .filter((pyramid: any) =>
                                  value.find(
                                    (id: number) => pyramid.value === id,
                                  ),
                                )
                                .map((pyramid: any) => ({
                                  group_id: pyramid.value,
                                  group_name: pyramid.label,
                                }))
                              const firstPyramid: any = {
                                group_id: 0,
                                group_name: 'NON RETAIL',
                              }
                              const selectedPyramids =
                                withoutZero.concat(firstPyramid)
                              setChildCampaign({
                                ...childCampaign,
                                pyramids: selectedPyramids,
                              })
                              setPageHasChanges(true)
                            } else {
                              const selectedPyramids = pyramidList
                                .filter((pyramid: any) =>
                                  value.find(
                                    (id: number) => pyramid.value === id,
                                  ),
                                )
                                .map((pyramid: any) => ({
                                  group_id: pyramid.value,
                                  group_name: pyramid.label,
                                }))
                              setChildCampaign({
                                ...childCampaign,
                                pyramids: selectedPyramids,
                              })
                              setPageHasChanges(true)
                            }
                          }}
                          value={
                            childCampaign.pyramids &&
                            childCampaign.pyramids.map(
                              (pyramid: any) => pyramid.group_id,
                            )
                          }
                        />
                      </div>
                    </ExpandableSection.Content>
                  </ExpandableSection>
                </Card>
              </Grid.Item>
            </Grid.Container>
          </div>
        </Grid.Item>
        {isLoadingChildCampaign ? (
          <Grid.Container
            className="sign-projects-loading-container"
            direction="column"
            justify="center"
            align="center"
          >
            <Grid.Item>Loading Campaign Types...</Grid.Item>
            <Grid.Item>
              <Spinner />
            </Grid.Item>
          </Grid.Container>
        ) : (
          <Grid.Item xs={12}>
            <EditableRoster
              roster={childCampaign.roster}
              deleteFromRoster={deleteFromRoster}
              saveRoster={() => setCascadeModalOpen(true)}
              addToRoster={addToRoster}
              hasEditPermission={userPermissions.isSuperAdmin}
              resetTrigger={childCampaign.name}
            />
            <CascadeModal childCampaignTypeRequest={childCampaignTypeRequest} />
          </Grid.Item>
        )}
      </Grid.Container>
    </Fragment>
  )
}

export default CampaignTypeForm
