import { Pyramid } from '../merchandise/hierarchy/Pyramid.model'
import { get } from 'lodash'

export class BlueprintCampaign {
  id: string
  name: string
  parent: boolean
  pyramids: Pyramid[]
  type: string

  constructor(props: any = {}) {
    this.id = props.id || ''
    this.name = props.name || ''
    this.parent = props.parent || false
    this.pyramids = get(props, 'pyramids', [new Pyramid()]).map(
      (pyramid: any) => new Pyramid(pyramid),
    )
    this.type = props.type || ''
  }
}
