import React, { Fragment } from 'react'
import { Card, Grid } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { PlusIcon } from '@enterprise-ui/icons'
import { useBlueprintMilestoneTabContext } from '../../context/blueprintMilestoneTabContext'

export const AddScheduleTemplateMenu = () => {
  const { setTemplateMenu } = useBlueprintMilestoneTabContext()!
  return (
    <Fragment>
      <Card
        elevation={0}
        className="addMilestoneMenu-Card"
        onClick={() => {
          setTemplateMenu('MASTER_TEMPLATE')
        }}
      >
        <Grid.Container spacing="none">
          <Grid.Item xs={1}>
            <EnterpriseIcon
              className="addMilestoneOptions-Icon hc-clr-grey02"
              icon={PlusIcon}
            />
          </Grid.Item>
          <Grid.Item xs>
            <p className="hc-fs-md hc-clr-grey02">
              Add Template from Master Templates
            </p>
          </Grid.Item>
        </Grid.Container>
      </Card>
      <Card
        elevation={0}
        className="addMilestoneMenu-Card"
        onClick={() => {
          setTemplateMenu('CUSTOM_TEMPLATE')
        }}
      >
        <Grid.Container spacing="none">
          <Grid.Item xs={1}>
            <EnterpriseIcon
              className="addMilestoneOptions-Icon hc-clr-grey02"
              icon={PlusIcon}
            />
          </Grid.Item>
          <Grid.Item xs>
            <p className="hc-fs-md hc-clr-grey02">Add Custom Template</p>
          </Grid.Item>
        </Grid.Container>
      </Card>
    </Fragment>
  )
}

export default AddScheduleTemplateMenu
