export class ProductInfoResponse {
  product_dpci: string
  product_tcin: string
  product_description: ProductDescription

  constructor(props: any = {}) {
    this.product_dpci = props.alternateIdentifiers.dpci || ''
    this.product_tcin = props.tcin || ''
    this.product_description = new ProductDescription(props.description)
  }
}
export class ProductDescription {
  title: string
  bullet_descriptions: string[]
  soft_bullet_description: string

  constructor(props: any = {}) {
    this.title = props.title || ''
    this.bullet_descriptions = props.bullets || []
    this.soft_bullet_description = props.softBullet || ''
  }
}
