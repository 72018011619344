import React from 'react'
import { Grid, List, Popover } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { CaretDownIcon } from '@enterprise-ui/icons'

export interface Props {
  value: any[]
}

const PogCategoriesCell = ({ value }: Props) => {
  const popoverContent = (
    <Grid.Container>
      <Grid.Item className="hc-pv-dense" xs={12}>
        <List>
          {value
            ? value.map((cat: any, index: number) => (
                <List.Item
                  className="sem_ProductHighlights--ListItem"
                  key={index}
                  divider={index !== value.length - 1}
                >
                  {cat.pog_category_name}
                </List.Item>
              ))
            : null}
        </List>
      </Grid.Item>
    </Grid.Container>
  )

  return value && value.length > 1 ? (
    <Popover
      content={popoverContent}
      size="normal"
      location="bottom"
      onClick={(event: any) => event.stopPropagation()}
    >
      <div className="ag-cell-value">
        Show Categories List
        <EnterpriseIcon
          icon={CaretDownIcon}
          className="hc-ml-dense"
          size="sm"
        />
      </div>
    </Popover>
  ) : (
    <div className="ag-cell-value">
      {value && value.length > 0 ? value[0].pog_category_name : ''}
    </div>
  )
}

export default PogCategoriesCell
