import { get } from 'lodash'

export class ProjectFundInfo {
  year: number
  fiscal_start_date: string
  fiscal_end_date: string
  funds: ProjectFund[]

  constructor(props: any = {}) {
    this.year = props.year || 0
    this.fiscal_start_date = props.fiscal_start_date || ''
    this.fiscal_end_date = props.fiscal_end_date || ''
    this.funds = get(props, 'funds', []).map(
      (fund: any) => new ProjectFund(fund),
    )
  }
}

export class ProjectFund {
  fund_id: string
  fund_name: string

  constructor(props: any = {}) {
    this.fund_id = props.fund_id.toString() || ''
    this.fund_name = props.fund_name || ''
  }
}
