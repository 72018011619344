import React from 'react'
import PageChangesWrapper from '../../../App/components/PageChangesWrapper'
import { MilestoneProvider } from '../../../App/context/milestoneContext'
import BlueprintMilestoneTab from './components/BlueprintMilestoneTab'
import { BlueprintMilestoneTabProvider } from './context/blueprintMilestoneTabContext'

const BlueprintMilestoneTabWrapper = (props: any) => (
  <PageChangesWrapper>
    <MilestoneProvider>
      <BlueprintMilestoneTabProvider>
        <BlueprintMilestoneTab {...props} />
      </BlueprintMilestoneTabProvider>
    </MilestoneProvider>
  </PageChangesWrapper>
)

export default BlueprintMilestoneTabWrapper
