import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { Form, Grid } from '@enterprise-ui/canvas-ui-react'
import { Column } from 'ag-grid-community'

export interface Props {
  value: number | string
  column: Column
}

const NumberCellEditor = forwardRef(({ value, column }: Props, ref) => {
  const [editValue, setEditValue] = useState<string | number | null>(value)
  const refInput = useRef<HTMLInputElement>(null)

  const handleChange = (id: string, value: string) => {
    if (value === '') {
      setEditValue(null)
    } else {
      setEditValue(value)
    }
  }

  useEffect(() => {
    setTimeout(() => refInput?.current?.focus())
  }, [])

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return editValue
      },
    }
  })

  return (
    <div className="hc-pa-normal">
      <Grid.Container className="sem_StandardForm error_messaging_enabled">
        <Grid.Item xs={12}>
          <Form.Field
            id="number_cell_editor"
            type="number"
            label={column.getColDef().headerName}
            ref={refInput}
            value={editValue}
            onChange={(e: any) => handleChange(e.target.id, e.target.value)}
            size="dense"
            error={
              column.getColId() === 'distribution.max_quantity_per_kit' &&
              editValue === '0'
            }
            errorText="Quantity must be greater than zero"
          />
        </Grid.Item>
      </Grid.Container>
    </div>
  )
})

export default NumberCellEditor
