import { get } from 'lodash'
import {
  PROJECT_REASON_CODE,
  SIGN_STATUS,
} from '../../components/App/constants/appConstants'

export default class ProjectSignInfo {
  blueprint_id?: string
  blueprint_name?: string
  campaign?: BlueprintCampaign
  campaign_id?: string
  created_by: string // default: 'System'
  created_date: string
  file_approved?: boolean
  kitting_vendor?: string
  kitting_vendor_id?: string
  most_recent_sign: boolean
  non_retail_item_id?: string
  on_pog_sign: boolean // default: false
  print_vendor?: string
  print_vendor_id?: string
  project_id?: string
  project_name?: string
  project_type?: string
  reason_code?: PROJECT_REASON_CODE
  sap_project_id?: string
  set_date: string
  sign_id?: string
  sign_status: SIGN_STATUS
  update_by: string
  updated_date: string
  workflow: Workflow

  constructor(props: any = {}) {
    this.blueprint_id = props.blueprint_id
    this.blueprint_name = props.blueprint_name
    this.campaign = props.campaign
      ? new BlueprintCampaign(props.campaign)
      : undefined
    this.campaign_id = props.campaign_id
    this.created_by = props.created_by || 'System'
    this.created_date = props.created_date || ''
    this.file_approved = props.file_approved
    this.kitting_vendor = props.kitting_vendor
    this.kitting_vendor_id = props.kitting_vendor_id
    this.most_recent_sign = props.most_recent_sign || false
    this.non_retail_item_id = props.non_retail_item_id
    this.on_pog_sign = props.on_pog_sign || false
    this.print_vendor = props.print_vendor
    this.print_vendor_id = props.print_vendor_id
    this.project_id = props.project_id
    this.project_name = props.project_name
    this.project_type = props.project_type
    this.reason_code = props.reason_code
    this.sap_project_id = props.sap_project_id
    this.set_date = props.set_date || ''
    this.sign_id = props.sign_id
    this.sign_status = props.sign_status || SIGN_STATUS.NEW
    this.update_by = props.update_by || 'System'
    this.updated_date = props.updated_date || ''
    this.workflow = new Workflow(props.workflow)
  }
}

export class BlueprintCampaign {
  id: string
  name: string
  pyramids?: Group[]
  is_parent?: boolean
  type: string

  constructor(props: any = {}) {
    this.id = props.id || ''
    this.name = props.name || ''
    this.pyramids = get(props, 'pyramids', [{}]).map(
      (item: any) => new Group(item),
    )
    this.type = props.type || ''
  }
}

export class Group {
  group_id?: number
  group_name: string
  group_display_name?: string

  constructor(props: any = {}) {
    this.group_id = props.group_id
    this.group_name = props.group_name || ''
    this.group_display_name = props.group_display_name
  }
}

export class Workflow {
  status: string
  color: string
  reasons?: string[]

  constructor(props: any = {}) {
    this.status = props.status || 'Sign Initiated'
    this.color = props.color || 'blue'
    this.reasons = props.reasons
  }
}
