export class KitSignResponse {
  sign_id?: string
  version: string
  quantity?: number

  constructor(props: any = {}) {
    this.sign_id = props.sign_id
    this.version = props.version
    this.quantity = props.quantity
  }
}

export class KitSignView {
  project_id?: string
  sign_id?: string
  dpci?: string
  sign_name?: string
  sign_description?: string
  sign_status?: string
  total_stores?: number
  quantity_per_kit?: number
  nrsc_quantity?: number
  kit_by_itself?: boolean
  print_vendor?: string
  flat_height?: number
  flat_width?: number
  flat_depth?: number

  constructor(props: any = {}) {
    this.project_id = props.project_id
    this.sign_id = props.sign_id
    this.dpci = props.dpci
    this.sign_name = props.sign_name
    this.sign_description = props.sign_description
    this.sign_status = props.sign_status
    this.total_stores = props.total_stores
    this.quantity_per_kit = props.quantity_per_kit
    this.nrsc_quantity = props.nrsc_quantity
    this.kit_by_itself = props.kit_by_itself
    this.print_vendor = props.print_vendor
    this.flat_height = props.flat_height
    this.flat_width = props.flat_width
    this.flat_depth = props.flat_depth
  }
}
