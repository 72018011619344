import React from 'react'
import { Button, Divider, Form, Grid } from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { get, omit } from 'lodash'
import { useFilterContext } from '../../../App/context/filterContext'
import { useAgGridSignLibraryContext } from '../../context/agGridSignLibraryContext'
import {
  autoCompleteValues,
  autoCompleteOptions,
  filterFacetOptions,
} from '../../helpers/filterFormHelpers'
import { DropdownOption } from '../../../../models/app/DropdownOption.model'
import { useEnv } from '@praxis/component-runtime-env'

const FiltersForm = () => {
  const env = useEnv()
  const {
    signLibrarySearchRequest,
    signLibraryFilterModel,
    setSignLibraryFilterModel,
  } = useFilterContext()!
  const { gridApi, facetResults, filterFacets, resetAllFilters } =
    useAgGridSignLibraryContext()!

  const handleUpdate = (field: string, value: any) => {
    switch (field) {
      case 'non_retail_item_info.dpci':
        setSignLibraryFilterModel({
          ...signLibraryFilterModel,
          [field]: {
            filterType: 'text',
            type: 'dpciList',
            filter: value,
          },
        })
        break
      case 'non_retail_item_info.sign_description':
      case 'project_sign_info_list.sap_project_id':
      case 'project_sign_info_list.project_name':
      case 'project_sign_info_list.blueprint_name':
      case 'non_retail_item_info.upc':
        setSignLibraryFilterModel({
          ...signLibraryFilterModel,
          [field]: {
            filterType: 'text',
            type: 'contains',
            filter: value,
          },
        })
        break
      case 'non_retail_item_info.nominal_dimensions.width':
      case 'non_retail_item_info.nominal_dimensions.height':
      case 'non_retail_item_info.item_dimensions.width':
      case 'non_retail_item_info.item_dimensions.height':
        setSignLibraryFilterModel({
          ...signLibraryFilterModel,
          [field]: {
            filterType: 'number',
            type: 'equals',
            filter: value,
          },
        })
        break
      case 'department':
        setSignLibraryFilterModel({
          ...signLibraryFilterModel,
          'non_retail_item_info.department': {
            filterType: 'set',
            values: value,
          },
        })
        break
      default:
        if (value.length > 0) {
          setSignLibraryFilterModel({
            ...signLibraryFilterModel,
            [signLibrarySearchRequest.facets[field]]: {
              filterType: 'set',
              values: value,
            },
          })
        } else {
          setSignLibraryFilterModel(
            omit(signLibraryFilterModel, [
              signLibrarySearchRequest.facets[field],
            ]),
          )
        }
        break
    }
  }

  const handleDateUpdate = (field: string, date: string) => {
    switch (field) {
      case 'setDateFrom':
        setSignLibraryFilterModel({
          ...signLibraryFilterModel,
          'project_sign_info_list.set_date': {
            ...signLibraryFilterModel['project_sign_info_list.set_date'],
            dateFrom: date,
            filterType: 'date',
            type: 'inRange',
          },
        })
        break
      case 'setDateTo':
        setSignLibraryFilterModel({
          ...signLibraryFilterModel,
          'project_sign_info_list.set_date': {
            ...signLibraryFilterModel['project_sign_info_list.set_date'],
            dateTo: date,
            filterType: 'date',
            type: 'inRange',
          },
        })
        break
      case 'endDateFrom':
        setSignLibraryFilterModel({
          ...signLibraryFilterModel,
          'non_retail_item_info.end_date': {
            ...signLibraryFilterModel['non_retail_item_info.end_date'],
            dateFrom: date,
            filterType: 'date',
            type: 'inRange',
          },
        })
        break
      case 'endDateTo':
        setSignLibraryFilterModel({
          ...signLibraryFilterModel,
          'non_retail_item_info.end_date': {
            ...signLibraryFilterModel['non_retail_item_info.end_date'],
            dateTo: date,
            filterType: 'date',
            type: 'inRange',
          },
        })
        break
      case 'createdDateFrom':
        setSignLibraryFilterModel({
          ...signLibraryFilterModel,
          'non_retail_item_info.created_date': {
            ...signLibraryFilterModel['non_retail_item_info.created_date'],
            dateFrom: date,
            filterType: 'date',
            type: 'inRange',
          },
        })
        break
      case 'createdDateTo':
        setSignLibraryFilterModel({
          ...signLibraryFilterModel,
          'non_retail_item_info.created_date': {
            ...signLibraryFilterModel['non_retail_item_info.created_date'],
            dateTo: date,
            filterType: 'date',
            type: 'inRange',
          },
        })
        break
      default:
        break
    }
  }

  const handleResetFilters = () => {
    setSignLibraryFilterModel({})
    resetAllFilters()
  }

  const handleApplyFilters = () => {
    gridApi?.setFilterModel(signLibraryFilterModel)
  }

  const isEnterKey =
    (cb: () => void) => (event: React.KeyboardEvent<Element>) => {
      if (event.key === 'Enter') {
        cb()
      }
    }

  return (
    <div onKeyUp={isEnterKey(handleApplyFilters)} className="hc-pa-dense">
      <Grid.Container justify="space-between">
        <Grid.Item xs={2}>
          <Form.Field
            id="non_retail_item_info.dpci"
            label="DPCI #"
            value={get(
              signLibraryFilterModel,
              `['non_retail_item_info.dpci'].filter`,
              [],
            )}
            onChange={(e: any) => {
              handleUpdate(e.target.id, e.target.value)
            }}
          />
        </Grid.Item>
        <Grid.Item xs={3}>
          <Form.Field
            id="non_retail_item_info.sign_description"
            label="Sign Name"
            value={get(
              signLibraryFilterModel,
              `['non_retail_item_info.sign_description'].filter`,
              [],
            )}
            onChange={(e: any) => {
              handleUpdate(e.target.id, e.target.value)
            }}
          />
        </Grid.Item>
        <Grid.Item xs={2}>
          <Form.Field
            id="project_sign_info_list.sap_project_id"
            label="SAP Project ID#"
            value={get(
              signLibraryFilterModel,
              `['project_sign_info_list.sap_project_id'].filter`,
              [],
            )}
            onChange={(e: any) => handleUpdate(e.target.id, e.target.value)}
          />
        </Grid.Item>
        <Grid.Item xs={3}>
          <Form.Field
            id="project_sign_info_list.project_name"
            label="SAP Project Name"
            value={get(
              signLibraryFilterModel,
              `['project_sign_info_list.project_name'].filter`,
              [],
            )}
            onChange={(e: any) => handleUpdate(e.target.id, e.target.value)}
          />
        </Grid.Item>
        <Grid.Item xs={true}>
          <Form.Field
            id="project_sign_info_list.blueprint_name"
            label="Blueprint Name"
            value={get(
              signLibraryFilterModel,
              `['project_sign_info_list.blueprint_name'].filter`,
              [],
            )}
            onChange={(e: any) => handleUpdate(e.target.id, e.target.value)}
          />
        </Grid.Item>
      </Grid.Container>
      <Grid.Container justify="space-between">
        <Grid.Item xs={3}>
          <Autocomplete
            label="Sign Type"
            optionHeight="dense"
            multiselect
            options={() => filterFacetOptions(filterFacets, 'sign_type')}
            value={autoCompleteValues(
              get(
                signLibraryFilterModel,
                `['${signLibrarySearchRequest.facets?.sign_type}'].values`,
                [],
              ),
            )}
            onUpdate={(e: any, value: DropdownOption<string>[]) =>
              handleUpdate(
                'sign_type',
                value.map((option) => option.value),
              )
            }
          />
        </Grid.Item>
        <Grid.Item xs={env.beskarFeature ? 2 : 3}>
          {env.beskarFeature ? (
            <Autocomplete
              label="Sign Size"
              optionHeight="dense"
              multiselect
              options={() => filterFacetOptions(filterFacets, 'sign_size')}
              value={autoCompleteValues(
                get(
                  signLibraryFilterModel,
                  `['${signLibrarySearchRequest.facets?.sign_size}'].values`,
                  [],
                ),
              )}
              onUpdate={(e: any, value: DropdownOption<string>[]) =>
                handleUpdate(
                  'sign_size',
                  value.map((option) => option.value),
                )
              }
            />
          ) : (
            <Autocomplete
              label="Sign Template"
              optionHeight="dense"
              multiselect
              options={() => filterFacetOptions(filterFacets, 'sign_template')}
              value={autoCompleteValues(
                get(
                  signLibraryFilterModel,
                  `['${signLibrarySearchRequest.facets?.sign_template}'].values`,
                  [],
                ),
              )}
              onUpdate={(e: any, value: DropdownOption<string>[]) =>
                handleUpdate(
                  'sign_template',
                  value.map((option) => option.value),
                )
              }
            />
          )}
        </Grid.Item>
        {env.beskarFeature ? (
          <Grid.Item xs={3}>
            <Autocomplete
              label="Sign Definition"
              optionHeight="dense"
              multiselect
              options={() =>
                filterFacetOptions(filterFacets, 'sign_definition')
              }
              value={autoCompleteValues(
                get(
                  signLibraryFilterModel,
                  `['${signLibrarySearchRequest.facets?.sign_definition}'].values`,
                  [],
                ),
              )}
              onUpdate={(e: any, value: DropdownOption<string>[]) =>
                handleUpdate(
                  'sign_definition',
                  value.map((option) => option.value),
                )
              }
            />
          </Grid.Item>
        ) : null}
        <Grid.Item xs={2}>
          <Autocomplete
            label="Sign Item Status"
            optionHeight="dense"
            multiselect
            options={() => filterFacetOptions(filterFacets, 'sign_item_status')}
            value={autoCompleteValues(
              get(
                signLibraryFilterModel,
                `['${signLibrarySearchRequest.facets?.sign_item_status}'].values`,
                [],
              ),
            )}
            onUpdate={(e: any, value: DropdownOption<string>[]) =>
              handleUpdate(
                'sign_item_status',
                value.map((option) => option.value),
              )
            }
          />
        </Grid.Item>
        <Grid.Item xs={env.beskarFeature ? 2 : 3}>
          <Autocomplete
            label="Sign Status"
            optionHeight="dense"
            multiselect
            options={() => filterFacetOptions(filterFacets, 'sign_status')}
            value={autoCompleteValues(
              get(
                signLibraryFilterModel,
                `['${signLibrarySearchRequest.facets?.sign_status}'].values`,
                [],
              ),
            )}
            onUpdate={(e: any, value: DropdownOption<string>[]) =>
              handleUpdate(
                'sign_status',
                value.map((option) => option.value),
              )
            }
          />
        </Grid.Item>
      </Grid.Container>
      <Grid.Container justify="space-between">
        <Grid.Item xs={2}>
          <Form.Field
            id="non_retail_item_info.upc"
            label="Sign UPC"
            value={get(
              signLibraryFilterModel,
              `['non_retail_item_info.upc'].filter`,
              [],
            )}
            onChange={(e: any) => handleUpdate(e.target.id, e.target.value)}
          />
        </Grid.Item>
        <Grid.Item xs={3}>
          <Autocomplete
            label="Product Vendor"
            optionHeight="dense"
            multiselect
            options={() => autoCompleteOptions(facetResults, 'product_vendor')}
            value={autoCompleteValues(
              get(
                signLibraryFilterModel,
                `['${signLibrarySearchRequest.facets?.product_vendor}'].values`,
                [],
              ),
            )}
            onUpdate={(e: any, value: DropdownOption<string>[]) =>
              handleUpdate(
                'product_vendor',
                value.map((option) => option.value),
              )
            }
          />
        </Grid.Item>
        <Grid.Item xs={3}>
          <Autocomplete
            label="Separator"
            optionHeight="dense"
            multiselect
            options={() => autoCompleteOptions(facetResults, 'separator')}
            value={autoCompleteValues(
              get(
                signLibraryFilterModel,
                `['${signLibrarySearchRequest.facets?.separator}'].values`,
                [],
              ),
            )}
            onUpdate={(e: any, value: DropdownOption<string>[]) =>
              handleUpdate(
                'separator',
                value.map((option) => option.value),
              )
            }
          />
        </Grid.Item>
        <Grid.Item xs={1}>
          <Form.Field
            id="non_retail_item_info.nominal_dimensions.width"
            label="Flat Width"
            value={get(
              signLibraryFilterModel,
              `['non_retail_item_info.nominal_dimensions.width'].filter`,
              [],
            )}
            onChange={(e: any) => handleUpdate(e.target.id, e.target.value)}
          />
        </Grid.Item>
        <Grid.Item xs={1}>
          <Form.Field
            id="non_retail_item_info.nominal_dimensions.height"
            label="Flat Height"
            value={get(
              signLibraryFilterModel,
              `['non_retail_item_info.nominal_dimensions.height'].filter`,
              [],
            )}
            onChange={(e: any) => handleUpdate(e.target.id, e.target.value)}
          />
        </Grid.Item>
        <Grid.Item xs={1}>
          <Form.Field
            id="non_retail_item_info.item_dimensions.width"
            label="Finished Width"
            value={get(
              signLibraryFilterModel,
              `['non_retail_item_info.item_dimensions.width'].filter`,
              [],
            )}
            onChange={(e: any) => handleUpdate(e.target.id, e.target.value)}
          />
        </Grid.Item>
        <Grid.Item xs={1}>
          <Form.Field
            id="non_retail_item_info.item_dimensions.height"
            label="Finished Height"
            value={get(
              signLibraryFilterModel,
              `['non_retail_item_info.item_dimensions.height'].filter`,
              [],
            )}
            onChange={(e: any) => handleUpdate(e.target.id, e.target.value)}
          />
        </Grid.Item>
        <Divider className="hc-pa-dense" />
      </Grid.Container>
      <Grid.Container>
        <Grid.Item xs={2}>
          <Form.Field
            type="date"
            id="setDateFrom"
            label="Set Date From"
            value={get(
              signLibraryFilterModel,
              `['project_sign_info_list.set_date'].dateFrom`,
              '',
            )}
            onChange={(e: any) => handleDateUpdate(e.target.id, e.target.value)}
          />
        </Grid.Item>
        <Grid.Item xs={2}>
          <Form.Field
            type="date"
            id="setDateTo"
            label="Set Date To"
            value={get(
              signLibraryFilterModel,
              `['project_sign_info_list.set_date'].dateTo`,
              '',
            )}
            onChange={(e: any) => handleDateUpdate(e.target.id, e.target.value)}
          />
        </Grid.Item>
        <Divider direction="vertical" className="hc-ph-none hc-mt-normal" />
        <Grid.Item xs={2}>
          <Form.Field
            type="date"
            id="endDateFrom"
            label="End Date From"
            value={get(
              signLibraryFilterModel,
              `['non_retail_item_info.end_date'].dateFrom`,
              '',
            )}
            onChange={(e: any) => handleDateUpdate(e.target.id, e.target.value)}
          />
        </Grid.Item>
        <Grid.Item xs={2}>
          <Form.Field
            type="date"
            id="endDateTo"
            label="End Date To"
            value={get(
              signLibraryFilterModel,
              `['non_retail_item_info.end_date'].dateTo`,
              '',
            )}
            onChange={(e: any) => handleDateUpdate(e.target.id, e.target.value)}
          />
        </Grid.Item>
        <Divider direction="vertical" className="hc-ph-none hc-mt-normal" />
        <Grid.Item xs={2}>
          <Form.Field
            type="date"
            id="createdDateFrom"
            label="Created Date From"
            value={get(
              signLibraryFilterModel,
              `['non_retail_item_info.created_date'].dateFrom`,
              '',
            )}
            onChange={(e: any) => handleDateUpdate(e.target.id, e.target.value)}
          />
        </Grid.Item>
        <Grid.Item xs={2}>
          <Form.Field
            type="date"
            id="createdDateTo"
            label="Created Date To"
            value={get(
              signLibraryFilterModel,
              `['non_retail_item_info.created_date'].dateTo`,
              '',
            )}
            onChange={(e: any) => handleDateUpdate(e.target.id, e.target.value)}
          />
        </Grid.Item>
        <Divider className="hc-pa-dense" />
      </Grid.Container>
      <Grid.Container justify="space-between">
        <Grid.Item xs={4}>
          <Autocomplete
            label="Pyramid"
            optionHeight="dense"
            multiselect
            options={() => filterFacetOptions(filterFacets, 'pyramid')}
            value={autoCompleteValues(
              get(
                signLibraryFilterModel,
                `['${signLibrarySearchRequest.facets?.pyramid}'].values`,
                [],
              ),
            )}
            onUpdate={(e: any, value: DropdownOption<string>[]) =>
              handleUpdate(
                'pyramid',
                value.map((option) => option.value),
              )
            }
          />
        </Grid.Item>
        <Grid.Item xs={4}>
          <Autocomplete
            label="Division"
            optionHeight="dense"
            multiselect
            options={() => filterFacetOptions(filterFacets, 'division')}
            value={autoCompleteValues(
              get(
                signLibraryFilterModel,
                `['${signLibrarySearchRequest.facets?.division}'].values`,
                [],
              ),
            )}
            onUpdate={(e: any, value: DropdownOption<string>[]) =>
              handleUpdate(
                'division',
                value.map((option) => option.value),
              )
            }
          />
        </Grid.Item>
        <Grid.Item xs={4}>
          <Autocomplete
            label="Department"
            optionHeight="dense"
            multiselect
            options={() => filterFacetOptions(filterFacets, 'department')}
            value={autoCompleteValues(
              get(
                signLibraryFilterModel,
                `['non_retail_item_info.department'].values`,
                [],
              ),
            )}
            onUpdate={(e: any, value: DropdownOption<string>[]) =>
              handleUpdate(
                'department',
                value.map((option) => option.value),
              )
            }
          />
        </Grid.Item>
      </Grid.Container>
      <Grid.Container justify="space-between">
        <Grid.Item xs={3}>
          <Autocomplete
            label="Project Type"
            optionHeight="dense"
            multiselect
            options={() => filterFacetOptions(filterFacets, 'project_type')}
            value={autoCompleteValues(
              get(
                signLibraryFilterModel,
                `['${signLibrarySearchRequest.facets?.project_type}'].values`,
                [],
              ),
            )}
            onUpdate={(e: any, value: DropdownOption<string>[]) =>
              handleUpdate(
                'project_type',
                value.map((option) => option.value),
              )
            }
          />
        </Grid.Item>
        <Grid.Item xs={3}>
          <Autocomplete
            label="Campaign Type"
            optionHeight="dense"
            multiselect
            options={() => filterFacetOptions(filterFacets, 'campaign')}
            value={autoCompleteValues(
              get(
                signLibraryFilterModel,
                `['${signLibrarySearchRequest.facets?.campaign}'].values`,
                [],
              ),
            )}
            onUpdate={(e: any, value: DropdownOption<string>[]) =>
              handleUpdate(
                'campaign',
                value.map((option) => option.value),
              )
            }
          />
        </Grid.Item>
        <Grid.Item xs={3}>
          <Autocomplete
            label="Print Vendor"
            optionHeight="dense"
            multiselect
            options={() => autoCompleteOptions(facetResults, 'printing_vendor')}
            value={autoCompleteValues(
              get(
                signLibraryFilterModel,
                `['${signLibrarySearchRequest.facets?.printing_vendor}'].values`,
                [],
              ),
            )}
            onUpdate={(e: any, value: DropdownOption<string>[]) =>
              handleUpdate(
                'printing_vendor',
                value.map((option) => option.value),
              )
            }
          />
        </Grid.Item>
        <Grid.Item xs={3}>
          <Autocomplete
            label="Kitting Vendor"
            optionHeight="dense"
            multiselect
            options={() => autoCompleteOptions(facetResults, 'kitting_vendor')}
            value={autoCompleteValues(
              get(
                signLibraryFilterModel,
                `['${signLibrarySearchRequest.facets?.kitting_vendor}'].values`,
                [],
              ),
            )}
            onUpdate={(e: any, value: DropdownOption<string>[]) =>
              handleUpdate(
                'kitting_vendor',
                value.map((option) => option.value),
              )
            }
          />
        </Grid.Item>
        <Divider className="hc-pa-dense" />
      </Grid.Container>
      <Grid.Container className="hc-mt-dense" direction="row-reverse">
        <Grid.Item>
          <Button
            type="primary"
            size="dense"
            onClick={() => handleApplyFilters()}
          >
            Apply Filters
          </Button>
        </Grid.Item>
        <Grid.Item className="hc-pr-none">
          <Button
            type="secondary"
            size="dense"
            onClick={() => handleResetFilters()}
          >
            Reset Filters
          </Button>
        </Grid.Item>
      </Grid.Container>
    </div>
  )
}

export default FiltersForm
