import React from 'react'
import { Grid, Button, Dropdown } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { CaretDownIcon } from '@enterprise-ui/icons'
import { useAppContext } from 'components/App/context/appContext'
import { BLUEPRINT_PROJECT_LIST_VIEW } from 'components/App/constants/appConstants'

const ChangeViewButton = () => {
  const { blueprintProjectListView, setBlueprintProjectListView } =
    useAppContext()!

  return (
    <Grid.Item className="hc-ph-dense hc-pb-dense" xs={true}>
      <Dropdown size="normal" location="bottom-left">
        <Button type="ghost">
          View: {blueprintProjectListView}
          <EnterpriseIcon className="hc-ml-dense" icon={CaretDownIcon} />
        </Button>
        <Dropdown.Menu>
          <Dropdown.MenuItem
            onClick={() =>
              setBlueprintProjectListView(BLUEPRINT_PROJECT_LIST_VIEW.ACTIVE)
            }
          >
            {BLUEPRINT_PROJECT_LIST_VIEW.ACTIVE}
          </Dropdown.MenuItem>
          <Dropdown.MenuItem
            onClick={() =>
              setBlueprintProjectListView(BLUEPRINT_PROJECT_LIST_VIEW.ARCHIVED)
            }
          >
            {BLUEPRINT_PROJECT_LIST_VIEW.ARCHIVED}
          </Dropdown.MenuItem>
        </Dropdown.Menu>
      </Dropdown>
    </Grid.Item>
  )
}

export default ChangeViewButton
