import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { Input, Grid } from '@enterprise-ui/canvas-ui-react'
import { Column } from 'ag-grid-community'

export interface Props {
  value: boolean
  column: Column
}

const CompleteToggleEditor = forwardRef(({ value, column }: Props, ref) => {
  const [editValue, setEditValue] = useState(value)
  const refInput = useRef<HTMLInputElement>(null)

  const handleChange = (id: string, value: boolean) => setEditValue(value)

  useEffect(() => {
    setTimeout(() => refInput?.current?.focus())
  }, [])

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return editValue
      },
    }
  })

  return (
    <div className="hc-pa-dense">
      <Grid.Container className="sem_StandardForm error_messaging_enabled toggle_editor">
        <Grid.Item>
          <Input.Toggle
            id="toggle_complete_editor"
            ref={refInput}
            label={column.getColDef().headerName}
            checked={editValue}
            onChange={(e: any) => handleChange(e.target.id, e.target.checked)}
          />
          <p className="C-FormField__FieldInfo" />
        </Grid.Item>
      </Grid.Container>
    </div>
  )
})

export default CompleteToggleEditor
