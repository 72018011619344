import React, { createContext, useContext, useState } from 'react'
import { SearchRequest } from '../../../models/app/SearchRequest.model'
import { BlueprintSearchFacets } from '../../../models/blueprints/BlueprintSearchFacets.model'
import { ProjectSearchFacets } from '../../../models/projects/ProjectSearchFacets.model'
import { SignLibrarySearchFacets } from '../../../models/signLibrary'
import { SignSearchFacets } from '../../../models/signs/SignSearchFacets.model'

type ContextType = {
  blueprintFilters: SearchRequest<BlueprintSearchFacets>
  setBlueprintFilters: (filters: SearchRequest<BlueprintSearchFacets>) => void
  projectFilters: SearchRequest<ProjectSearchFacets>
  setProjectFilters: (filters: SearchRequest<ProjectSearchFacets>) => void
  /* === Old Sign List Filter state  === */
  signFilters: SearchRequest<SignSearchFacets>
  setSignFilters: Function
  /* === Sign Library Filter state  === */
  signLibrarySearchRequest: SearchRequest<SignLibrarySearchFacets>
  setSignLibrarySearchRequest: Function
  signLibraryFilterModel: any
  setSignLibraryFilterModel: Function
  /* === Sign Import Filter state  === */
  signImportSearchRequest: SearchRequest<SignLibrarySearchFacets>
  setSignImportSearchRequest: Function
  signImportFilterModel: any
  setSignImportFilterModel: Function
  projectDetailGridFilterModel: any
  setProjectDetailGridFilterModel: Function
  currentNotificationsView: string
  setCurrentNotificationsView: (view: string) => void
}

export const FilterContext = createContext<ContextType | undefined>(undefined)

type Props = {
  children: React.ReactNode
}

export const FilterProvider = ({ children }: Props) => {
  const [blueprintFilters, setBlueprintFilters] = useState(
    new SearchRequest<BlueprintSearchFacets>({
      facets: new BlueprintSearchFacets(),
      sort_fields: { set_date: 'desc' },
    }),
  )

  const [projectFilters, setProjectFilters] = useState(
    new SearchRequest<ProjectSearchFacets>({
      facets: new ProjectSearchFacets(),
      sort_fields: { set_date: 'desc' },
    }),
  )

  const [signFilters, setSignFilters] = useState(
    new SearchRequest<SignSearchFacets>({
      facets: new SignSearchFacets(),
    }),
  )

  const [signLibrarySearchRequest, setSignLibrarySearchRequest] = useState(
    new SearchRequest<SignLibrarySearchFacets>({
      facets: new SignLibrarySearchFacets(),
      sort_fields: { id: 'asc' },
    }),
  )
  const [signLibraryFilterModel, setSignLibraryFilterModel] = useState({})

  const [signImportSearchRequest, setSignImportSearchRequest] = useState(
    new SearchRequest<SignLibrarySearchFacets>({
      facets: new SignLibrarySearchFacets(),
      sort_fields: { id: 'asc' },
    }),
  )
  const [signImportFilterModel, setSignImportFilterModel] = useState({})
  const [projectDetailGridFilterModel, setProjectDetailGridFilterModel] =
    useState({})

  const [currentNotificationsView, setCurrentNotificationsView] =
    useState('UNREAD')

  return (
    <FilterContext.Provider
      value={{
        blueprintFilters,
        setBlueprintFilters,
        projectFilters,
        setProjectFilters,
        signFilters,
        setSignFilters,
        signLibrarySearchRequest,
        setSignLibrarySearchRequest,
        signLibraryFilterModel,
        setSignLibraryFilterModel,
        signImportSearchRequest,
        setSignImportSearchRequest,
        signImportFilterModel,
        setSignImportFilterModel,
        projectDetailGridFilterModel,
        setProjectDetailGridFilterModel,
        currentNotificationsView,
        setCurrentNotificationsView,
      }}
    >
      {children}
    </FilterContext.Provider>
  )
}

export const useFilterContext = () => useContext(FilterContext)
