import { ColumnMovedEvent, GridReadyEvent } from 'ag-grid-community'

export const saveColumnState = (key: string) => (params: ColumnMovedEvent) => {
  const columnState = JSON.stringify(params.columnApi.getColumnState())
  localStorage.setItem(key, columnState)
}

export const loadColumnState =
  (key: string, cb?: (params: GridReadyEvent) => void) =>
  (params: GridReadyEvent) => {
    const columnState = JSON.parse(localStorage.getItem(key) || 'null')
    if (columnState && columnStateIsLocked(key)) {
      params.columnApi.applyColumnState({
        state: columnState,
        applyOrder: true,
      })
    }

    cb?.(params)
  }

export const columnStateIsLocked = (key: string) => {
  const columnState = localStorage.getItem(key)

  return !!columnState
}

export const unlockColumnState = (key: string) => {
  localStorage.removeItem(key)
  window.location.reload()
}
