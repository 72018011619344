import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useBlueprintProjectListContext } from '../../../context/blueprintProjectListContext'
import SapTableProject from '../../../../../../../models/projects/SapTableProject.model'
import { Form, Grid } from '@enterprise-ui/canvas-ui-react'
import { useBlueprintDetailsContainerContext } from '../../../../../context/blueprintDetailsContainerContext'
import { DropdownOption } from '../../../../../../../models/app/DropdownOption.model'
import { RowNode } from 'ag-grid-community'
import { BlueprintCampaign } from '../../../../../../../models/signs/SignResponse.model'

export interface Props {
  value: BlueprintCampaign
  data: SapTableProject
  node: RowNode
}

const CampaignCellEditor = forwardRef(({ value, data, node }: Props, ref) => {
  const { currentBlueprint } = useBlueprintDetailsContainerContext()!
  const { loadCampaigns, blueprintCampaigns, loadCampaignIds } =
    useBlueprintProjectListContext()!
  const [editValue, setEditValue] = useState(value)
  const refInput = useRef<HTMLElement>(null)

  useEffect(() => {
    setTimeout(() => refInput?.current?.focus())
  }, [])

  useEffect(() => {
    loadCampaigns(
      data.set_date ? data.set_date : currentBlueprint.set_date,
      currentBlueprint.campaign.name,
    )
    // loading will continue to occur unless we do not include dependencies
    // eslint-disable-next-line
  }, [])

  const handleUpdate = async (campaignId: string) => {
    const selectedCampaign = blueprintCampaigns.find(
      (campaign: BlueprintCampaign) => campaign.id === campaignId,
    )
    node.setDataValue('campaign', selectedCampaign)
    if (selectedCampaign) {
      setEditValue(selectedCampaign)
      const newCampaignId = await loadCampaignIds(
        selectedCampaign.name,
        data.set_date,
      )
      node.setDataValue('campaign_id', newCampaignId)
    }
  }

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return editValue
      },
    }
  })

  return (
    <div className="hc-pa-dense">
      <Grid.Container className="sem_StandardForm error_messaging_enabled">
        <Grid.Item className="hc-pb-normal" xs={12}>
          <Form.Field
            id="campaign_cell_editor"
            label="Campaign Type*"
            ref={refInput}
            type="select"
            value={editValue.id}
            options={blueprintCampaigns.map(
              (campaign: BlueprintCampaign) =>
                new DropdownOption({
                  id: campaign.id,
                  value: campaign.id,
                  label: campaign.name,
                }),
            )}
            onUpdate={(e: any, campaignId: string) => handleUpdate(campaignId)}
            size="dense"
          />
        </Grid.Item>
      </Grid.Container>
    </div>
  )
})

export default CampaignCellEditor
