import React from 'react'
import { Layout } from '@enterprise-ui/canvas-ui-react'
import PageChangesWrapper from '../../App/components/PageChangesWrapper'
import { AdminMilestoneProvider } from './context/adminMilestoneContext'
import AdminMilestone from './components/AdminMilestone'
import { MilestoneProvider } from '../../App/context/milestoneContext'

const AdminMilestones = (props: any) => (
  <PageChangesWrapper>
    <MilestoneProvider>
      <AdminMilestoneProvider>
        <Layout.Body className="hc-pa-expanded" includeRail>
          <AdminMilestone {...props} />
        </Layout.Body>
      </AdminMilestoneProvider>
    </MilestoneProvider>
  </PageChangesWrapper>
)

export default AdminMilestones
