import React, { Fragment } from 'react'
import {
  Heading,
  Caption,
  GridContainer,
  GridItem,
  Card,
} from '@enterprise-ui/canvas-ui-react'

import { KitInfoResponse } from '../../../../../models/kits/KitInfoResponse.model'

export interface Props {
  kittingInfo: KitInfoResponse
}

const ProjectDistributionSummary = ({ kittingInfo }: Props) => {
  return (
    <Fragment>
      <Card
        padding="none"
        className="sem_ProjectKitSummaryCard hc-pt-none hc-pb-none"
      >
        <GridContainer
          className="hc-pa-dense"
          justify="space-between"
          align="center"
        >
          <GridItem>
            <Heading size={4}>Summary</Heading>
          </GridItem>
          <GridItem>
            <Caption uppercase above="Total Kits">
              <Heading size={6}>{kittingInfo.kit_summary?.total_kits}</Heading>
            </Caption>
          </GridItem>
          <GridItem>
            <Caption uppercase above="Total Kit Versions">
              <Heading size={6}>
                {kittingInfo.kit_summary?.total_kit_versions}
              </Heading>
            </Caption>
          </GridItem>
          <GridItem>
            <Caption uppercase above="Total Kit Cost">
              <Heading size={6}>
                {kittingInfo.kit_summary?.total_kit_cost}
              </Heading>
            </Caption>
          </GridItem>
          <GridItem>
            <Caption uppercase above="Total Sign Cost">
              <Heading size={6}>
                {kittingInfo.kit_summary?.total_sign_cost}
              </Heading>
            </Caption>
          </GridItem>
          <GridItem>
            <Caption uppercase above="Total extra signs sent (waste)">
              <Heading size={6}>
                {kittingInfo.kit_summary?.total_extra_signs}
              </Heading>
            </Caption>
          </GridItem>
          <GridItem>
            <Caption uppercase above="Total cost of waste">
              <Heading size={6}>
                {kittingInfo.kit_summary?.total_waste_cost}
              </Heading>
            </Caption>
          </GridItem>
          <GridItem>
            <Caption uppercase above="Total Stores">
              <Heading size={6}>
                {kittingInfo.kit_summary?.total_store_count}
              </Heading>
            </Caption>
          </GridItem>
        </GridContainer>
      </Card>
    </Fragment>
  )
}

export default ProjectDistributionSummary
