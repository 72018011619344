import {
  SIGN_ITEM_STATUS,
  SIGN_STATUS,
} from '../../SignDetails/constants/signDetailsConstants'

export const getSignStatusChipColor = (status?: string) => {
  switch (status) {
    case SIGN_STATUS.NEW:
      return 'green'
    case SIGN_STATUS.CARRY_FORWARD:
      return 'blue'
    case SIGN_STATUS.REMOVE:
      return 'red'
    case SIGN_STATUS.REISSUE:
      return 'yellow'
    default:
      return 'blue'
  }
}

export const getSignItemStatusChipColor = (status: string) => {
  switch (status) {
    case SIGN_ITEM_STATUS.ACTIVE:
      return 'green'
    case SIGN_ITEM_STATUS.IN_DEVELOPMENT:
      return 'blue'
    case SIGN_ITEM_STATUS.OBSOLETE:
      return 'red'
    default:
      return 'grey'
  }
}
