import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import SignResponse from 'models/signs/SignResponse.model'
import { useAgGridSignListContext } from '../context/agGridSignListContext'
import {
  SIGN_SERVICE_API_DOMAIN_URL,
  TOASTER_DEFAULTS,
} from 'components/App/constants/appConstants'
import { useEnv } from '@praxis/component-runtime-env'
import Axios from 'axios'
// @ts-ignore
import html2pdf from 'html2pdf.js'
import { forkJoin, from, map, Observable, of, switchMap, tap } from 'rxjs'
import { useObservableState } from 'observable-hooks'
import {
  getPreview,
  signHasPreview,
} from 'components/ProjectDetails/components/ProjectSignList/helpers/previewHelper'
import { DescriptionListConfig } from 'components/common/DescriptionList/types'
import { imagesFound$ } from '../components/signListViews/components/ActionsDropdown'
import moment from 'moment'
import { useToaster } from '@enterprise-ui/canvas-ui-react'

const tz = Intl.DateTimeFormat().resolvedOptions().timeZone

export const dlConfig: DescriptionListConfig<SignResponseWithImage>[] = [
  {
    label: 'Sign Description',
    key: 'description',
  },
  {
    label: 'DPCI #',
    key: 'dpci',
  },
  {
    label: 'TCIN #',
    key: 'tcin',
  },
  {
    label: 'Sign Type',
    key: 'sign_type',
  },
  {
    label: 'Sign Status',
    key: 'sign_status',
  },
  {
    label: 'Finished Height',
    key: 'height',
  },
  {
    label: 'Finished Width',
    key: 'width',
  },
]

export type SignResponseWithImage = SignResponse & {
  image: string
  dpci?: string
  tcin?: string
  height?: string
  width?: string
}

const getImageMeta: any = (sign: SignResponse) => {
  const { dpci, tcin } = sign.non_retail_item_info
  const { description, sign_type, sign_status } = sign
  const { width, height } = sign.non_retail_item_info.item_dimensions
  return {
    dpci,
    tcin,
    description,
    sign_type,
    sign_status,
    width,
    height,
  }
}

type ExportPDFState = {
  exportImages: () => void
  currentImage: string
  setImageIndex: (index: number | boolean) => () => void
  selectedIndex: number
  images: SignResponseWithImage[]
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
}

export const useExportPDFState = (
  PID: string = '',
  setDate: string = '',
  Desc: string = '',
): ExportPDFState => {
  const { selectedRows } = useAgGridSignListContext()!
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [loading, setLoading] = useState(false)

  const env = useEnv()
  const makeToast = useToaster()

  const [images, next] = useObservableState(
    (input$: Observable<SignResponseWithImage[]>) =>
      input$.pipe(
        tap(() => setLoading(true)),
        switchMap((signs) =>
          forkJoin(
            signs.map((sign) =>
              sign?.image
                ? of({ ...getImageMeta(sign), image: sign.image })
                : from(getPreview(sign)).pipe(
                    map((image) => ({
                      ...getImageMeta(sign),
                      image,
                    })),
                  ),
            ),
          ),
        ),
        tap(() => setLoading(false)),
      ),
    [] as any,
  )

  useEffect(() => {
    console.log({ images })
    imagesFound$.next(images.length)
  }, [images])

  useEffect(() => {
    console.log({ selectedRows })
    next(
      selectedRows.reduce((acc, sign) => {
        sign.non_retail_item_info.storage_urls.forEach((image) => {
          acc.push({
            ...sign,
            image: `${image}?key=${env.assethubApiKey}`,
          })
        })
        if (signHasPreview(sign)) {
          acc.push({
            ...sign,
          } as SignResponseWithImage)
        }
        return acc
      }, [] as SignResponseWithImage[]),
    )
  }, [selectedRows]) // eslint-disable-line react-hooks/exhaustive-deps

  const setImageIndex = useCallback(
    (next: number | boolean) => () => {
      if (next === true && selectedIndex !== images?.length - 1) {
        setSelectedIndex(selectedIndex + 1)
      } else if (next === false && selectedIndex !== 0) {
        setSelectedIndex(selectedIndex - 1)
      } else if (typeof next === 'number') {
        if (next >= 0 && next < images?.length) {
          setSelectedIndex(next)
        }
      }
    },
    [selectedIndex, images],
  )

  const exportImages = async () => {
    setLoading(true)
    const resolvedImages = await Promise.allSettled(
      images.map(async (image: SignResponseWithImage) => {
        if (image.image.includes('base64')) return image
        // fetch image as blob
        const splitAssetUrl = image.image.split('/')
        const assetId = splitAssetUrl[splitAssetUrl.length - 1].split('?')[0]
        const res = await Axios.get(
          env.apiDomainUrl +
            SIGN_SERVICE_API_DOMAIN_URL +
            '/assethub/' +
            assetId,
          {
            responseType: 'blob',
          },
        ).catch((err) => {
          return Promise.reject(err)
        })
        const blob = res.data
        const file = new File([blob], `image.png`, { type: 'image/png' })
        // convert blob to base64
        const base64Image = await new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.addEventListener(
            'load',
            () => {
              // convert image file to base64 string
              resolve(reader.result)
            },
            false,
          )
          reader.onerror = reject
          reader.readAsDataURL(file)
        })
        return {
          ...image,
          image: base64Image,
        }
      }),
    )

    // remove failed images
    const failedImages = resolvedImages.filter(
      ({ status }) => status === 'rejected',
    ).length

    if (failedImages) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Export Images Failed',
        message: `${failedImages} images could not be loaded`,
      })
    }

    const htmlString = (resolvedImages || [])
      .filter(({ status }) => status === 'fulfilled')
      .map(({ value: image }: any, idx: number) => {
        return `
        <div class="page-start" style="display:flex;height:88.25vh; flex-direction: column;">
          <dl style="margin-right: 28px;display: flex;">
            ${dlConfig
              .slice(0, 1)
              .map((item, index) => {
                return `
                  <div key="${index}" style="margin-bottom: 24px; display: flex; justify-content: space-between;">
                      <dt style="white-space: nowrap;color: #888;">${
                        item.label
                      }</dt>
                      <dd style="text-overflow: ellipsis;overflow: hidden;white-space: nowrap; margin-left: 50px;">${
                        image[item.key]
                      }</dd>
                  </div>`
              })
              .join('\n\r')}
          </dl>
          <div style="display: flex;">
            <dl style="margin-right: 28px;display: flex; flex-direction: column;">
                ${dlConfig
                  .slice(1)
                  .map((item, index) => {
                    return `
                    <div key="${index}" style="margin-bottom: 24px; display: flex; justify-content: space-between;">
                        <dt style="white-space: nowrap;color: #888;">${
                          item.label
                        }</dt>
                        <dd style="text-overflow: ellipsis;overflow: hidden;white-space: nowrap;width: 150px; margin-left: 50px;">${
                          image[item.key]
                        }</dd>
                    </div>`
                  })
                  .join('\n\r')}
            </dl>
            <figure style="width: 100%; height: 100%; display: flex; justify-content: center; border: none;">
                <img style="width: 100%;
                  max-height: 550px;
                  border: none;
                  background-image: url(${image.image});
                  background-size: contain;
                  background-position: center center;
                  background-repeat: no-repeat;" crossOrigin="Anonymous" />
            </figure>
          </div>
          <div class="break"></div>
          ${
            idx !== resolvedImages.length - 1 ? `<div class="break"></div>` : ''
          }
        </div>
    `
      })
      .join('\n\r')

    const container = document.createElement('div')

    container.innerHTML = htmlString

    html2pdf(container, {
      margin: 1,
      pagebreak: { mode: ['css'], before: ['.break'] },
      filename: `${moment(setDate)
        .tz(tz)
        .format('MM-DD-YYYY')}_${PID}_${Desc}.pdf`,
      image: { type: 'png', quality: 0.98 },
      html2canvas: {
        windowWidth: 1487,
        windowHeight: 707,
        useCORS: true,
      },
      jsPDF: {
        unit: 'in',
        format: 'letter',
        orientation: 'landscape',
        compress: true,
      },
    }).then(() => setLoading(false))
  }

  const currentImage = images?.[selectedIndex]?.image || ''

  return {
    exportImages,
    currentImage,
    setImageIndex,
    selectedIndex,
    images,
    loading,
    setLoading,
  }
}
