import React from 'react'
import { SignLibraryResponse } from '../../../../../models/signLibrary'

export interface Props {
  value: string
  data: SignLibraryResponse
}

const DpciCell = ({ value, data }: Props) => (
  <div className="ag-cell-value">{`${value} (${data.project_sign_info_list.length})`}</div>
)

export default DpciCell
