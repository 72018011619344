export class SignSearchFacets {
  [index: string]: any
  campaign: any
  department: any
  division: any
  kitting_vendor: any
  printing_vendor: any
  pyramid: any
  sign_item_status: any
  sign_type: any
  sign_status: any
  project_type: any

  constructor(props: any = {}) {
    this.campaign = props.campaign || 'campaign.name'
    this.department = props.department || 'department.department_display_name'
    this.division = props.division || 'department.division_display_name'
    this.kitting_vendor = props.kitting_vendor || 'distribution.kitting_vendor'
    this.printing_vendor = props.printing_vendor || 'printing.print_vendor'
    this.pyramid = props.pyramid || 'department.group_display_name'
    this.sign_item_status =
      props.sign_item_status || 'non_retail_item_info.sign_item_status'
    this.sign_type = props.sign_type || 'sign_type'
    this.sign_status = props.sign_status || 'sign_status'
    this.project_type = props.project_type || 'project_type'
  }
}
