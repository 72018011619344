import { Card } from '@enterprise-ui/canvas-ui-react'
import React from 'react'

interface ErrorCardProps {
  text: string
}

export const ErrorCard = ({ text }: ErrorCardProps) => {
  return (
    <Card className="hc-pa-normal hc-bg-error">
      <p className="text-center hc-fs-md hc-clr-white">{text}</p>
    </Card>
  )
}
