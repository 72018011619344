import React from 'react'
import { Department } from '../../../../../models/signLibrary'

export interface Props {
  value: Department
}

const DepartmentCell = ({ value }: Props) => (
  <div className="ag-cell-value">
    {value ? value.department_display_name : ''}
  </div>
)

export default DepartmentCell
