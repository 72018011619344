import { Table } from '@enterprise-ui/canvas-ui-react'
import React from 'react'
import EnterpriseIcon, {
  ArrowDownIcon,
  ArrowUpIcon,
} from '@enterprise-ui/icons'

export interface Props {
  text: string
  xs: number | boolean
  searchPath: string
  className?: string
  sortFields: any
  submitSort: (searchPath: string, newSortDirection: string) => void
}

const SortableTableHeader = ({
  text,
  xs,
  className = '',
  searchPath,
  sortFields,
  submitSort,
}: Props) => {
  return (
    <Table.Header
      className={`sem-hover-pointer ${className}`}
      xs={xs}
      onClick={() => {
        const newSortDirection =
          sortFields[searchPath] === 'asc' ? 'desc' : 'asc'
        submitSort(searchPath, newSortDirection)
      }}
    >
      {text}{' '}
      {sortFields[searchPath] === 'asc' && (
        <EnterpriseIcon icon={ArrowUpIcon} />
      )}
      {sortFields[searchPath] === 'desc' && (
        <EnterpriseIcon icon={ArrowDownIcon} />
      )}
    </Table.Header>
  )
}

export default SortableTableHeader
