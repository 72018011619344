import { useFormik } from 'formik'
import { MilestoneTemplate } from '../../../../models/milestones/MilestoneTemplate.model'

export const useNewAdminMilestoneForm = (
  newMilestone: MilestoneTemplate,
  addNewMilestone: Function,
  closeMilestoneForm: Function,
) => {
  const validate = (values: MilestoneTemplate) => {
    const errors: any = {}
    if (!values.name) {
      errors.name = 'Required'
    } else if (!values.project_phase) {
      errors.project_phase = 'Required'
    } else if (!values.role) {
      errors.role = 'Required'
    }
    return errors
  }

  return useFormik({
    enableReinitialize: false,
    initialValues: newMilestone,
    validate,
    validateOnChange: false,
    onSubmit(values) {
      addNewMilestone(
        new MilestoneTemplate({
          ...newMilestone,
          name: values.name,
          project_phase: values.project_phase,
          role: values.role,
          completion_level: values.completion_level,
          critical: values.critical,
          depends_on: values.depends_on,
          risk: values.risk,
          days_out: 0,
          status: values.status,
          status_description: values.status_description,
          information_only: values.information_only,
          auto_check: values.auto_check,
          risk_days_out: 0,
        }),
      )
      closeMilestoneForm()
    },
  })
}
