import Axios from 'axios'
import { connect } from 'react-redux'
import _, { cloneDeep, remove } from 'lodash'
import React, { createContext, useState, useContext, useCallback } from 'react'
import { ProjectDetail } from '../../../models/projects/ProjectDetail.model'
import { RosterElement } from '../../../models/roster/RosterElement.model'
import { RosterUser } from '../../../models/roster/RosterUser.model'
import {
  ADMIN_SERVICE_API_DOMAIN_URL,
  DASHBOARD_SERVICE_API_DOMAIN_URL,
  HEADER_OBJECT,
  PROJECT_SERVICE_API_DOMAIN_URL,
  SIGN_SERVICE_API_DOMAIN_URL,
  TOASTER_DEFAULTS,
} from '../../App/constants/appConstants'
import { useEnv } from '@praxis/component-runtime-env'
import { ProjectFundInfo } from '../../../models/projects/ProjectFundInfo.model'
import { useAppContext } from '../../App/context/appContext'
import { useUserContext } from '../../../components/App/context/userContext'
import { editReasonStatuses } from '../../SignDetails/constants/signDetailsConstants'
import { ProjectSummary } from '../../../models/dashboardV2/ProjectSummary.model'
import { useToaster } from '@enterprise-ui/canvas-ui-react'
import SignResponse from '../../../models/signs/SignResponse.model'

type ContextType = {
  currentProject: ProjectDetail
  setCurrentProject: Function
  currentProjectSummary: ProjectSummary
  getProjectSummary: (project_id: string) => void
  deleteFromRoster: (user: RosterUser, title: string) => void
  projectFundInfo?: ProjectFundInfo
  setProjectFundInfo: (info: ProjectFundInfo) => void
  refreshProjectFunds: (date: string) => void
  saveProjectRoster: (
    id: string,
    roster: RosterElement[],
    updatedBy: string,
  ) => void
  refreshCurrentProject: (id: string) => void
  showKitAlert: boolean
  editReasonRequired: boolean
  warningStatuses: string[]
  projectSignList: SignResponse[]
  getProjectSigns: (id: string) => void
  setProjectSignList: Function
  setIsLoadingSignList: (isLoading: boolean) => void
  DPCIList: string[]
  signListLoaded: boolean
  isLoadingSignList: boolean
  projectGroupList: string[]
  setProjectGroupList: (list: string[]) => void
}

export const ProjectDetailsContext = createContext<ContextType | undefined>(
  undefined,
)

type Props = {
  children: React.ReactNode
}

export const ProjectDetailsProviderComponent = ({ children }: Props) => {
  const { setFullPageLoadingMessage } = useAppContext()!
  const { userPermissions, userCompany } = useUserContext()!
  const env = useEnv()
  const makeToast = useToaster()

  const [currentProject, setCurrentProject] = useState(new ProjectDetail())
  const [currentProjectSummary, setCurrentProjectSummary] =
    useState<ProjectSummary>(new ProjectSummary())
  const [projectFundInfo, setProjectFundInfo] = useState<ProjectFundInfo>()
  const [showKitAlert, setShowKitAlert] = useState<boolean>(false)
  const [editReasonRequired, setEditReasonRequired] = useState<boolean>(false)
  const [projectSignList, setProjectSignList] = useState<SignResponse[]>([])
  const [isLoadingSignList, setIsLoadingSignList] = useState(false)
  const [DPCIList, setDPCIList] = useState<string[]>([])
  const [projectGroupList, setProjectGroupList] = useState<string[]>([])
  const [signListLoaded, setSignListLoaded] = useState(false)

  const warningStatuses = [
    'Recreate Kits (Finalization)',
    'Kits Modified (Finalization)',
    'Recreate Kits',
  ]

  const validateProject = (projectDetail: ProjectDetail) => {
    if (projectDetail.project_id) {
      projectDetail.workflow.status === 'Recreate Kits' ||
      projectDetail.workflow.status === 'Recreate Kits (Finalization)'
        ? setShowKitAlert(true)
        : setShowKitAlert(false)
      editReasonStatuses.some(
        (status) => status === projectDetail.workflow.status,
      )
        ? setEditReasonRequired(true)
        : setEditReasonRequired(false)
    }
    setCurrentProject(projectDetail)
  }

  const refreshCurrentProject = useCallback(
    async (id: string) => {
      setFullPageLoadingMessage('Loading Project...')
      try {
        const res = await Axios.get(
          `${env.apiDomainUrl + PROJECT_SERVICE_API_DOMAIN_URL}/projects/${id}`,
        )

        validateProject(new ProjectDetail(res.data))
      } catch (err: any) {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'error',
          heading: 'Failed to Get Project',
          message: err.response.data.message,
        })
      }
      setFullPageLoadingMessage('')
    },
    // eslint-disable-next-line
    [setFullPageLoadingMessage, makeToast],
  )

  const getProjectSummary = useCallback(
    async (project_id: string) => {
      try {
        const res = await Axios.get(
          `${
            env.apiDomainUrl + DASHBOARD_SERVICE_API_DOMAIN_URL
          }/projects/${project_id}`,
          HEADER_OBJECT,
        )
        setCurrentProjectSummary(new ProjectSummary(res.data))
      } catch (err: any) {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'error',
          heading: 'Failed to Get Project Summary',
          message: err.response.data.message,
        })
      }
    },
    [env.apiDomainUrl, makeToast],
  )

  const deleteFromRoster = (user: RosterUser, title: string) => {
    setCurrentProject((previousState: ProjectDetail) => {
      const clonedState = cloneDeep(previousState)
      const currentRosterElement = clonedState.roster.find(
        (rosterElement: RosterElement) => rosterElement.title === title,
      )
      currentRosterElement && remove(currentRosterElement.users, user)
      return clonedState
    })
  }

  const refreshProjectFunds = useCallback(
    async (date: string) => {
      try {
        const res = await Axios.get(
          `${
            env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
          }/funds?set_date=${date}`,
        )
        setProjectFundInfo(new ProjectFundInfo(res.data[0]))
      } catch (err: any) {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'error',
          heading: 'Failed to Get Project Funds',
          message: err.response.data.message,
        })
      }
    },
    [makeToast, env.apiDomainUrl],
  )

  const saveProjectRoster = async (
    id: string,
    roster: RosterElement[],
    updatedBy: string,
  ) => {
    try {
      setFullPageLoadingMessage('Saving Roster...')
      await Axios.put(
        `${
          env.apiDomainUrl + PROJECT_SERVICE_API_DOMAIN_URL
        }/projects/${id}/rosters`,
        {
          roster: roster,
          updated_by: updatedBy,
        },
        HEADER_OBJECT,
      )
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'success',
        heading: 'Roster Saved',
        message: 'Successfully saved the roster',
      })
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Save Roster',
        message: err.response.data.message,
      })
    }
    setFullPageLoadingMessage('')
  }

  const getProjectSigns = useCallback(
    async (id: string) => {
      setIsLoadingSignList(true)
      try {
        const res = await Axios.get(
          `${
            env.apiDomainUrl + SIGN_SERVICE_API_DOMAIN_URL
          }/signs?project_id=${id}`,
        )
        const userFilterResult =
          userPermissions.isPrintVendor ||
          userPermissions.isKitVendor ||
          userPermissions.isSAPVendor ||
          userPermissions.isAgencySeparator ||
          userPermissions.isTPSVendor
            ? res.data.filter(
                (sign: SignResponse) =>
                  sign.printing.print_vendor === userCompany,
              )
            : res.data
        const sortedSigns = userFilterResult.sort((a: any, b: any) =>
          a.created_date < b.created_date ? 1 : -1,
        )
        setProjectSignList(
          sortedSigns.map((sign: any) => new SignResponse(sign)),
        )
        setDPCIList(
          sortedSigns
            .filter((sign: SignResponse) => sign.non_retail_item_info.dpci)
            .map((sign: SignResponse) => sign.non_retail_item_info.dpci),
        )
        const rawGroupList = res.data
          .filter((sign: any) => sign.group)
          .map((sign: any) => sign.group)
        setProjectGroupList(_.uniq(rawGroupList))
      } catch (err: any) {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'error',
          heading: 'Failed to Get Project Signs',
          message: err.response.data.message,
        })
      }
      setSignListLoaded(true)
      setIsLoadingSignList(false)
    },
    [makeToast, env.apiDomainUrl],
  )

  return (
    <ProjectDetailsContext.Provider
      value={{
        currentProject,
        setCurrentProject,
        currentProjectSummary,
        getProjectSummary,
        deleteFromRoster,
        projectFundInfo,
        setProjectFundInfo,
        refreshProjectFunds,
        saveProjectRoster,
        refreshCurrentProject,
        showKitAlert,
        editReasonRequired,
        warningStatuses,
        projectSignList,
        getProjectSigns,
        setProjectSignList,
        setIsLoadingSignList,
        DPCIList,
        signListLoaded,
        isLoadingSignList,
        projectGroupList,
        setProjectGroupList,
      }}
    >
      {children}
    </ProjectDetailsContext.Provider>
  )
}

export const ProjectDetailsProvider = connect(
  null,
  null,
)(ProjectDetailsProviderComponent)

export const useProjectDetailsContext = () => useContext(ProjectDetailsContext)
