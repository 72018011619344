import { Column } from '../../../../../models/agGrid/AgGridColumn.model'
import { AgGridSignListView } from '../../../../../models/agGrid/AgGridSignListView.model'

export const STANDARD_VIEW_OLD = new AgGridSignListView({
  viewKey: 'standard',
  label: 'Standard',
  columns: [
    new Column({
      type: ['selectedRow', 'fixed'],
    }),
    new Column({
      type: ['signMenu', 'fixed'],
    }),
    new Column({
      field: 'workflow.status',
      headerName: 'Finalized Level',
      width: 176,
      type: ['finalizeCell', 'fixed'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.dpci',
      headerName: 'DPCI #',
      type: ['fixed'],
    }),
    new Column({
      field: 'description',
      headerName: 'Sign Description',
      width: 280,
      type: ['fixed', 'signDetailLink', 'textEditor'],
    }),
    new Column({
      field: 'sign_type',
      colId: 'sign_types',
      headerName: 'Sign Type',
      width: 206,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'sign_template_info.template_name',
      headerName: 'Standard Sign',
      width: 206,
      type: ['autocompleteEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'sign_status',
      headerName: 'Sign Status',
      width: 154,
      type: ['tableChip', 'signStatusEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.item_dimensions.width',
      headerName: 'Finished Width',
      width: 214,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.item_dimensions.height',
      headerName: 'Finished Height',
      width: 220,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.item_dimensions.depth',
      headerName: 'Finished Depth',
      width: 215,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.width',
      headerName: 'Flat Width',
      width: 186,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.height',
      headerName: 'Flat Height',
      width: 192,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.depth',
      headerName: 'Flat Depth',
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.end_date',
      headerName: 'End Date',
      width: 154,
      type: ['date', 'dateEditor'],
      filter: 'agDateColumnFilter',
    }),
    new Column({
      field: 'sign_notes.snp_note',
      headerName: 'POG Placement Notes',
      width: 330,
      type: ['notes', 'notesEditor'],
    }),
    new Column({
      field: 'sign_notes.separator_note.content',
      headerName: 'Separator Notes',
      colId: 'hiddenSeparatorNotes',
      type: ['hiddenColumn'],
    }),
    new Column({
      field: 'sign_notes.planner_note.content',
      headerName: 'Planner/VC Notes',
      colId: 'hiddenPlanner',
      type: ['hiddenColumn'],
    }),
    new Column({
      field: 'sign_notes.ipp_note.content',
      headerName: 'IPP Proofing Notes',
      colId: 'hiddenIpp',
      type: ['hiddenColumn'],
    }),
  ],
  exportColumns: [
    'workflow.status',
    'non_retail_item_info.dpci',
    'description',
    'sign_types',
    'non_retail_item_info.item_dimensions.width',
    'non_retail_item_info.item_dimensions.height',
    'sign_status',
    'non_retail_item_info.end_date',
    'sign_notes.snp_note',
    'hiddenSeparatorNotes',
    'hiddenPlanner',
    'hiddenIpp',
  ],
})

export const STANDARD_VIEW = new AgGridSignListView({
  viewKey: 'standard',
  label: 'Standard',
  columns: [
    new Column({
      type: ['selectedRow', 'fixed'],
    }),
    new Column({
      type: ['signMenu', 'fixed'],
    }),
    new Column({
      field: 'workflow.status',
      headerName: 'Finalized Level',
      width: 176,
      type: ['finalizeCell', 'fixed'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.dpci',
      headerName: 'DPCI #',
      type: ['fixed'],
    }),
    new Column({
      field: 'description',
      headerName: 'Sign Description',
      width: 280,
      type: ['fixed', 'signDetailLink', 'textEditor'],
    }),
    new Column({
      field: 'sign_type',
      colId: 'sign_types',
      headerName: 'Sign Type',
      width: 206,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'sign_size',
      headerName: 'Sign Size',
      width: 170,
      type: ['autocompleteEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'sign_definition',
      headerName: 'Sign Definition',
      width: 400,
      type: ['autocompleteEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'sign_status',
      headerName: 'Sign Status',
      width: 154,
      type: ['tableChip', 'signStatusEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.item_dimensions.width',
      headerName: 'Finished Width',
      width: 214,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.item_dimensions.height',
      headerName: 'Finished Height',
      width: 220,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.item_dimensions.depth',
      headerName: 'Finished Depth',
      width: 215,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.width',
      headerName: 'Flat Width',
      width: 186,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.height',
      headerName: 'Flat Height',
      width: 192,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.depth',
      headerName: 'Flat Depth',
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.end_date',
      headerName: 'End Date',
      width: 154,
      type: ['date', 'dateEditor'],
      filter: 'agDateColumnFilter',
    }),
    new Column({
      field: 'sign_notes.snp_note',
      headerName: 'Placement Notes',
      width: 330,
      type: ['notes', 'notesEditor'],
    }),
    new Column({
      field: 'sign_notes.separator_note.content',
      headerName: 'Separator Notes',
      colId: 'hiddenSeparatorNotes',
      type: ['hiddenColumn'],
    }),
    new Column({
      field: 'sign_notes.planner_note.content',
      headerName: 'Planner/VC Notes',
      colId: 'hiddenPlanner',
      type: ['hiddenColumn'],
    }),
    new Column({
      field: 'sign_notes.ipp_note.content',
      headerName: 'IPP Proofing Notes',
      colId: 'hiddenIpp',
      type: ['hiddenColumn'],
    }),
  ],
  exportColumns: [
    'workflow.status',
    'non_retail_item_info.dpci',
    'description',
    'sign_types',
    'sign_size',
    'sign_definition',
    'non_retail_item_info.item_dimensions.width',
    'non_retail_item_info.item_dimensions.height',
    'sign_status',
    'non_retail_item_info.end_date',
    'sign_notes.snp_note',
    'hiddenSeparatorNotes',
    'hiddenPlanner',
    'hiddenIpp',
  ],
})

export const FINALIZATION_VIEW_OLD = new AgGridSignListView({
  viewKey: 'finalization',
  label: 'Finalization',
  columns: [
    new Column({
      type: ['selectedRow', 'fixed'],
    }),
    new Column({
      type: ['signMenu', 'fixed'],
    }),
    new Column({
      field: 'workflow.status',
      headerName: 'Finalized Level',
      width: 176,
      type: ['finalizeCell', 'fixed'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.dpci',
      headerName: 'DPCI #',
      type: ['fixed'],
    }),
    new Column({
      field: 'description',
      headerName: 'Sign Description',
      width: 224,
      type: ['fixed', 'signDetailLink', 'textEditor'],
    }),
    new Column({
      field: 'sign_type',
      colId: 'sign_types',
      headerName: 'Sign Type',
      width: 206,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'sign_template_info.template_name',
      headerName: 'Standard Sign',
      width: 206,
      type: ['autocompleteEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'sign_status',
      headerName: 'Sign Status',
      width: 154,
      type: ['tableChip', 'signStatusEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'on_pog_sign',
      headerName: 'On POG Sign',
      type: ['toggle', 'toggleEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.sign_item_status',
      headerName: 'Sign Item Status',
      width: 182,
      type: ['tableChip'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'sign_creator',
      headerName: 'Creation Owner',
      width: 255,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'department',
      headerName: 'Department',
      width: 288,
      type: ['departmentEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'unit_of_measure',
      headerName: 'Unit of Measure',
      width: 186,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'set_quantity',
      headerName: 'Set Quantity',
      type: ['setQuantity', 'numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.item_dimensions.width',
      headerName: 'Finished Width',
      width: 214,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.item_dimensions.height',
      headerName: 'Finished Height',
      width: 220,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.item_dimensions.depth',
      headerName: 'Finished Depth',
      width: 215,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.width',
      headerName: 'Flat Width',
      width: 186,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.height',
      headerName: 'Flat Height',
      width: 192,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.depth',
      headerName: 'Flat Depth',
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'separator',
      headerName: 'Separator',
      width: 224,
      type: ['autocompleteEditor'],
    }),
    new Column({
      field: 'printing.print_vendor',
      headerName: 'Print Vendor',
      width: 272,
      type: ['autocompleteEditor'],
    }),
    new Column({
      field: 'third_party_shipping',
      headerName: 'Third Party Shipping',
      type: ['toggle', 'toggleEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'printing.planned_sides',
      headerName: 'Planned Sides',
      width: 212,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'sign_notes.snp_note',
      headerName: 'POG Placement Notes',
      width: 330,
      type: ['notes', 'notesEditor'],
    }),
    new Column({
      field: 'pog_categories',
      headerName: 'POG Categories',
      width: 220,
      type: ['pogCategoriesCell', 'pogCategoriesEditor'],
    }),
    new Column({
      field: 'placement_on_shelf',
      headerName: 'SPT Placement on Shelf',
      width: 192,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'quantity_per_placement',
      headerName: 'Quantity Per Placement',
      width: 220,
      type: ['textEditor'],
    }),
    new Column({
      field: 'pog_unit_of_measure',
      headerName: 'POG Unit of Measure',
      width: 220,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'tie_to_product_dpci',
      headerName: 'Tie to Product DPCI',
      width: 220,
      type: ['textEditor'],
    }),
    new Column({
      field: 'tie_to_hardware_dpci',
      headerName: 'Tie to Hardware DPCI',
      width: 220,
      type: ['textEditor'],
    }),
    new Column({
      field: 'pog_exclusions',
      headerName: 'POG Exclusions',
      width: 192,
      type: ['textEditor'],
    }),
    new Column({
      field: 'sign_notes.separator_note',
      headerName: 'Separator Notes',
      width: 330,
      type: ['notes', 'notesEditor'],
    }),
    new Column({
      field: 'non_retail_item_info.upc',
      headerName: 'Sign UPC',
    }),
    new Column({
      field: 'non_retail_item_info.end_date',
      headerName: 'End Date',
      width: 154,
      type: ['date'],
      filter: 'agDateColumnFilter',
    }),
  ],
  exportColumns: [
    'workflow.status',
    'non_retail_item_info.dpci',
    'description',
    'sign_types',
    'sign_status',
    'non_retail_item_info.sign_item_status',
    'sign_notes.snp_note',
    'unit_of_measure',
    'printing.planned_sides',
    'non_retail_item_info.item_dimensions.width',
    'non_retail_item_info.item_dimensions.height',
    'non_retail_item_info.item_dimensions.depth',
    'non_retail_item_info.nominal_dimensions.width',
    'non_retail_item_info.nominal_dimensions.height',
    'non_retail_item_info.nominal_dimensions.depth',
    'sign_notes.snp_note',
    'pog_categories',
    'placement_on_shelf',
    'quantity_per_placement',
    'pog_unit_of_measure',
    'tie_to_product_dpci',
    'tie_to_hardware_dpci',
    'pog_exclusions',
    'sign_notes.separator_note',
    'printing.print_vendor',
    'sign_creator',
    'department.department_name',
    'non_retail_item_info.upc',
    'non_retail_item_info.end_date',
  ],
})

export const FINALIZATION_VIEW = new AgGridSignListView({
  viewKey: 'finalization',
  label: 'Finalization',
  columns: [
    new Column({
      type: ['selectedRow', 'fixed'],
    }),
    new Column({
      type: ['signMenu', 'fixed'],
    }),
    new Column({
      field: 'workflow.status',
      headerName: 'Finalized Level',
      width: 176,
      type: ['finalizeCell', 'fixed'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.dpci',
      headerName: 'DPCI #',
      type: ['fixed'],
    }),
    new Column({
      field: 'description',
      headerName: 'Sign Description',
      width: 224,
      type: ['fixed', 'signDetailLink', 'textEditor'],
    }),
    new Column({
      field: 'sign_type',
      colId: 'sign_types',
      headerName: 'Sign Type',
      width: 206,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'sign_size',
      headerName: 'Sign Size',
      width: 170,
      type: ['autocompleteEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'sign_definition',
      headerName: 'Sign Definition',
      width: 400,
      type: ['autocompleteEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'sign_status',
      headerName: 'Sign Status',
      width: 154,
      type: ['tableChip', 'signStatusEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'on_pog_sign',
      headerName: 'On POG Sign',
      type: ['toggle', 'toggleEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.sign_item_status',
      headerName: 'Sign Item Status',
      width: 182,
      type: ['tableChip'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'sign_creator',
      headerName: 'Creation Owner',
      width: 255,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'department',
      headerName: 'Department',
      width: 288,
      type: ['departmentEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'unit_of_measure',
      headerName: 'Unit of Measure',
      width: 186,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'set_quantity',
      headerName: 'Set Quantity',
      type: ['setQuantity', 'numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.item_dimensions.width',
      headerName: 'Finished Width',
      width: 214,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.item_dimensions.height',
      headerName: 'Finished Height',
      width: 220,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.item_dimensions.depth',
      headerName: 'Finished Depth',
      width: 215,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.width',
      headerName: 'Flat Width',
      width: 186,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.height',
      headerName: 'Flat Height',
      width: 192,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.depth',
      headerName: 'Flat Depth',
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'separator',
      headerName: 'Separator',
      width: 224,
      type: ['autocompleteEditor'],
    }),
    new Column({
      field: 'printing.print_vendor',
      headerName: 'Print Vendor',
      width: 272,
      type: ['autocompleteEditor'],
    }),
    new Column({
      field: 'third_party_shipping',
      headerName: 'Third Party Shipping',
      type: ['toggle', 'toggleEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'printing.planned_sides',
      headerName: 'Planned Sides',
      width: 212,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'sign_notes.snp_note',
      headerName: 'Placement Notes',
      width: 330,
      type: ['notes', 'notesEditor'],
    }),
    new Column({
      field: 'pog_categories',
      headerName: 'POG Categories',
      width: 220,
      type: ['pogCategoriesCell', 'pogCategoriesEditor'],
    }),
    new Column({
      field: 'placement_on_shelf',
      headerName: 'SPT Placement on Shelf',
      width: 192,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'quantity_per_placement',
      headerName: 'Quantity Per Placement',
      width: 220,
      type: ['textEditor'],
    }),
    new Column({
      field: 'pog_unit_of_measure',
      headerName: 'POG Unit of Measure',
      width: 220,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'tie_to_product_dpci',
      headerName: 'Tie to Product DPCI',
      width: 220,
      type: ['textEditor'],
    }),
    new Column({
      field: 'tie_to_hardware_dpci',
      headerName: 'Tie to Hardware DPCI',
      width: 220,
      type: ['textEditor'],
    }),
    new Column({
      field: 'pog_exclusions',
      headerName: 'POG Exclusions',
      width: 192,
      type: ['textEditor'],
    }),
    new Column({
      field: 'sign_notes.separator_note',
      headerName: 'Separator Notes',
      width: 330,
      type: ['notes', 'notesEditor'],
    }),
    new Column({
      field: 'non_retail_item_info.upc',
      headerName: 'Sign UPC',
    }),
    new Column({
      field: 'non_retail_item_info.end_date',
      headerName: 'End Date',
      width: 154,
      type: ['date'],
      filter: 'agDateColumnFilter',
    }),
  ],
  exportColumns: [
    'workflow.status',
    'non_retail_item_info.dpci',
    'description',
    'sign_types',
    'sign_size',
    'sign_definition',
    'sign_status',
    'non_retail_item_info.sign_item_status',
    'sign_notes.snp_note',
    'unit_of_measure',
    'printing.planned_sides',
    'non_retail_item_info.item_dimensions.width',
    'non_retail_item_info.item_dimensions.height',
    'non_retail_item_info.item_dimensions.depth',
    'non_retail_item_info.nominal_dimensions.width',
    'non_retail_item_info.nominal_dimensions.height',
    'non_retail_item_info.nominal_dimensions.depth',
    'sign_notes.snp_note',
    'pog_categories',
    'placement_on_shelf',
    'quantity_per_placement',
    'pog_unit_of_measure',
    'tie_to_product_dpci',
    'tie_to_hardware_dpci',
    'pog_exclusions',
    'sign_notes.separator_note',
    'printing.print_vendor',
    'sign_creator',
    'department.department_name',
    'non_retail_item_info.upc',
    'non_retail_item_info.end_date',
  ],
})

export const ESTIMATION_VIEW_OLD = new AgGridSignListView({
  viewKey: 'estimation',
  label: 'Estimation',
  columns: [
    new Column({
      type: ['selectedRow', 'fixed'],
    }),
    new Column({
      type: ['signMenu', 'fixed'],
    }),
    new Column({
      field: 'workflow.status',
      headerName: 'Finalized Level',
      width: 176,
      type: ['finalizeCell', 'fixed'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.dpci',
      headerName: 'DPCI #',
      type: ['fixed'],
    }),
    new Column({
      field: 'description',
      headerName: 'Sign Description',
      width: 224,
      type: ['fixed', 'signDetailLink', 'textEditor'],
    }),
    new Column({
      field: 'sign_type',
      colId: 'sign_types',
      headerName: 'Sign Type',
      width: 206,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'sign_status',
      headerName: 'Sign Status',
      width: 154,
      type: ['tableChip', 'signStatusEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'printing.print_vendor',
      headerName: 'Print Vendor',
      width: 272,
      type: ['autocompleteEditor'],
    }),
    new Column({
      field: 'distribution.kitting_vendor',
      headerName: 'Kitting Vendor',
      width: 272,
      type: ['autocompleteEditor'],
    }),
    new Column({
      field: 'pricing.estimated_quantity',
      headerName: 'Est Qty',
      type: ['numberEditor'],
    }),
    new Column({
      field: 'pricing.estimated_unit_price',
      headerName: 'Est Unit Price',
      width: 209,
      type: ['numberEditor'],
    }),
    new Column({
      field: 'total_estimated_cost',
      headerName: 'Total Est Cost',
      width: 170,
      type: ['totalEstCost'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'distribution.nrsc_quantity',
      headerName: 'NRSC Qty',
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'pricing.final_unit_price',
      headerName: 'Final Unit Price',
      width: 177,
      type: ['numberEditor'],
    }),
    new Column({
      field: 'total_final_cost',
      headerName: 'Total Final Cost',
      width: 226,
      type: ['totalFinalCost'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'pricing.po_number',
      headerName: 'PO Number',
      width: 190,
      type: ['textEditor'],
    }),
    new Column({
      field: 'sign_notes.snp_note',
      headerName: 'Placement Notes',
      width: 330,
      type: ['notes', 'notesEditor'],
    }),
    new Column({
      field: 'non_retail_item_info.item_dimensions.width',
      headerName: 'Finished Width',
      width: 214,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.item_dimensions.height',
      headerName: 'Finished Height',
      width: 220,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.item_dimensions.depth',
      headerName: 'Finished Depth',
      width: 215,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.width',
      headerName: 'Flat Width',
      width: 186,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.height',
      headerName: 'Flat Height',
      width: 192,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.depth',
      headerName: 'Flat Depth',
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'printing.planned_sides',
      headerName: 'Planned Sides',
      width: 212,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'printing.number_of_colors',
      headerName: '# of Colors',
      width: 197,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'printing.substrate1',
      headerName: 'Substrate 1',
      width: 196,
      type: ['autocompletesubstrateEditor'],
    }),
    new Column({
      field: 'printing.substrate2',
      headerName: 'Substrate 2',
      width: 196,
      type: ['autocompletesubstrateEditor'],
    }),
    new Column({
      field: 'printing.finishing',
      headerName: 'Finishing',
      width: 177,
      type: ['textEditor'],
    }),
    new Column({
      field: 'printing.coating',
      headerName: 'Coating',
      width: 169,
      type: ['textEditor'],
    }),
    new Column({
      field: 'printing.die_cut',
      headerName: 'Die Cut',
      type: ['textEditor'],
    }),
    new Column({
      field: 'sign_notes.separator_note',
      headerName: 'Separator Notes',
      width: 330,
      type: ['notes', 'notesEditor'],
    }),
    new Column({
      field: 'sign_creator',
      headerName: 'Creation Owner',
      width: 181,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
  ],
  exportColumns: [
    'workflow.status',
    'non_retail_item_info.dpci',
    'description',
    'sign_types',
    'sign_status',
    'printing.print_vendor',
    'distribution.kitting_vendor',
    'pricing.estimated_quantity',
    'pricing.estimated_unit_price',
    'total_estimated_cost',
    'distribution.nrsc_quantity',
    'pricing.final_unit_price',
    'total_final_cost',
    'pricing.po_number',
    'sign_notes.snp_note',
    'non_retail_item_info.item_dimensions.width',
    'non_retail_item_info.item_dimensions.height',
    'non_retail_item_info.item_dimensions.depth',
    'non_retail_item_info.nominal_dimensions.width',
    'non_retail_item_info.nominal_dimensions.height',
    'non_retail_item_info.nominal_dimensions.depth',
    'printing.planned_sides',
    'printing.number_of_colors',
    'printing.substrate1',
    'printing.substrate2',
    'printing.finishing',
    'printing.coating',
    'printing.die_cut',
    'sign_notes.separator_note',
    'sign_creator',
  ],
})

export const ESTIMATION_VIEW = new AgGridSignListView({
  viewKey: 'estimation',
  label: 'Estimation',
  columns: [
    new Column({
      type: ['selectedRow', 'fixed'],
    }),
    new Column({
      type: ['signMenu', 'fixed'],
    }),
    new Column({
      field: 'workflow.status',
      headerName: 'Finalized Level',
      width: 176,
      type: ['finalizeCell', 'fixed'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.dpci',
      headerName: 'DPCI #',
      type: ['fixed'],
    }),
    new Column({
      field: 'description',
      headerName: 'Sign Description',
      width: 224,
      type: ['fixed', 'signDetailLink', 'textEditor'],
    }),
    new Column({
      field: 'sign_type',
      colId: 'sign_types',
      headerName: 'Sign Type',
      width: 206,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'sign_size',
      headerName: 'Sign Size',
      width: 170,
      type: ['autocompleteEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'sign_definition',
      headerName: 'Sign Definition',
      width: 400,
      type: ['autocompleteEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'sign_status',
      headerName: 'Sign Status',
      width: 154,
      type: ['tableChip', 'signStatusEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'printing.print_vendor',
      headerName: 'Print Vendor',
      width: 272,
      type: ['autocompleteEditor'],
    }),
    new Column({
      field: 'distribution.kitting_vendor',
      headerName: 'Kitting Vendor',
      width: 272,
      type: ['autocompleteEditor'],
    }),
    new Column({
      field: 'pricing.estimated_quantity',
      headerName: 'Est Qty',
      type: ['numberEditor'],
    }),
    new Column({
      field: 'pricing.estimated_unit_price',
      headerName: 'Est Unit Price',
      width: 209,
      type: ['numberEditor'],
    }),
    new Column({
      field: 'total_estimated_cost',
      headerName: 'Total Est Cost',
      width: 170,
      type: ['totalEstCost'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'distribution.nrsc_quantity',
      headerName: 'NRSC Qty',
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'pricing.final_unit_price',
      headerName: 'Final Unit Price',
      width: 177,
      type: ['numberEditor'],
    }),
    new Column({
      field: 'total_final_cost',
      headerName: 'Total Final Cost',
      width: 226,
      type: ['totalFinalCost'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'pricing.po_number',
      headerName: 'PO Number',
      width: 190,
      type: ['textEditor'],
    }),
    new Column({
      field: 'sign_notes.snp_note',
      headerName: 'Placement Notes',
      width: 330,
      type: ['notes', 'notesEditor'],
    }),
    new Column({
      field: 'non_retail_item_info.item_dimensions.width',
      headerName: 'Finished Width',
      width: 214,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.item_dimensions.height',
      headerName: 'Finished Height',
      width: 220,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.item_dimensions.depth',
      headerName: 'Finished Depth',
      width: 215,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.width',
      headerName: 'Flat Width',
      width: 186,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.height',
      headerName: 'Flat Height',
      width: 192,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.depth',
      headerName: 'Flat Depth',
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'printing.planned_sides',
      headerName: 'Planned Sides',
      width: 212,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'printing.sides_print',
      headerName: 'Sides Print',
      width: 178,
      type: ['toggle', 'toggleEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'printing.number_of_colors',
      headerName: '# of Colors',
      width: 197,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'printing.substrate1',
      headerName: 'Substrate 1',
      width: 196,
      type: ['autocompletesubstrateEditor'],
    }),
    new Column({
      field: 'printing.substrate2',
      headerName: 'Substrate 2',
      width: 196,
      type: ['autocompletesubstrateEditor'],
    }),
    new Column({
      field: 'printing.finishing',
      headerName: 'Finishing',
      width: 177,
      type: ['textEditor'],
    }),
    new Column({
      field: 'printing.coating',
      headerName: 'Coating',
      width: 169,
      type: ['textEditor'],
    }),
    new Column({
      field: 'printing.die_cut',
      headerName: 'Die Cut',
      type: ['textEditor'],
    }),
    new Column({
      field: 'sign_notes.separator_note',
      headerName: 'Separator Notes',
      width: 330,
      type: ['notes', 'notesEditor'],
    }),
    new Column({
      field: 'sign_creator',
      headerName: 'Creation Owner',
      width: 181,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'sign_template_info.source_id',
      headerName: 'ISM #',
      type: ['hiddenColumn'],
    }),
  ],
  exportColumns: [
    'workflow.status',
    'non_retail_item_info.dpci',
    'description',
    'sign_types',
    'sign_template_info.source_id',
    'sign_size',
    'sign_definition',
    'sign_status',
    'printing.print_vendor',
    'distribution.kitting_vendor',
    'pricing.estimated_quantity',
    'pricing.estimated_unit_price',
    'total_estimated_cost',
    'distribution.nrsc_quantity',
    'pricing.final_unit_price',
    'total_final_cost',
    'pricing.po_number',
    'sign_notes.snp_note',
    'non_retail_item_info.item_dimensions.width',
    'non_retail_item_info.item_dimensions.height',
    'non_retail_item_info.item_dimensions.depth',
    'non_retail_item_info.nominal_dimensions.width',
    'non_retail_item_info.nominal_dimensions.height',
    'non_retail_item_info.nominal_dimensions.depth',
    'printing.planned_sides',
    'printing.sides_print',
    'printing.number_of_colors',
    'printing.substrate1',
    'printing.substrate2',
    'printing.finishing',
    'printing.coating',
    'printing.die_cut',
    'sign_notes.separator_note',
    'sign_creator',
  ],
})

export const SPT_VIEW = new AgGridSignListView({
  viewKey: 'spt',
  label: 'SPT',
  columns: [
    new Column({
      type: ['selectedRow', 'fixed'],
    }),
    new Column({
      type: ['signMenu', 'fixed'],
    }),
    new Column({
      field: 'workflow.status',
      headerName: 'Finalized Level',
      width: 176,
      type: ['finalizeCell', 'fixed'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'pog_categories',
      headerName: 'POG Categories',
      width: 220,
      type: ['fixed', 'pogCategoriesCell', 'pogCategoriesEditor'],
    }),
    new Column({
      field: 'spt_sign_on_pog',
      headerName: 'SPT on POG',
      width: 148,
      type: ['fixed', 'pogCell', 'pogEditorCell'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'sign_status',
      headerName: 'Sign Status',
      width: 154,
      type: ['tableChip', 'signStatusEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'sign_type',
      headerName: 'Sign Type',
      width: 206,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'description',
      headerName: 'Sign Description',
      width: 224,
      type: ['fixed', 'signDetailLink', 'textEditor'],
    }),
    new Column({
      field: 'non_retail_item_info.dpci',
      headerName: 'DPCI #',
      type: ['fixed'],
    }),
    new Column({
      field: 'placement_on_shelf',
      headerName: 'SPT Placement on Shelf',
      width: 192,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'quantity_per_placement',
      headerName: 'Quantity Per Placement',
      width: 220,
      type: ['textEditor'],
    }),
    new Column({
      field: 'pog_unit_of_measure',
      headerName: 'POG Unit of Measure',
      width: 220,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'tie_to_product_dpci',
      headerName: 'Tie to Product DPCI',
      width: 220,
      type: ['textEditor'],
    }),
    new Column({
      field: 'tie_to_hardware_dpci',
      headerName: 'Tie to Hardware DPCI',
      width: 220,
      type: ['textEditor'],
    }),
    new Column({
      field: 'sign_notes.snp_note',
      headerName: 'Placement Notes',
      width: 330,
      type: ['notes', 'notesEditor'],
    }),
    new Column({
      field: 'pog_exclusions',
      headerName: 'POG Exclusions',
      width: 192,
      type: ['textEditor'],
    }),
    new Column({
      field: 'sign_notes.spt_note',
      headerName: 'SPT Notes',
      width: 330,
      type: ['notes', 'notesEditor'],
    }),
    new Column({
      field: 'non_retail_item_info.item_dimensions.width',
      headerName: 'Finished Width',
      width: 186,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.item_dimensions.height',
      headerName: 'Finished Height',
      width: 192,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.end_date',
      headerName: 'End Date',
      width: 154,
      type: ['date'],
      filter: 'agDateColumnFilter',
    }),
    new Column({
      field: 'distribution.total_quantity',
      headerName: 'Total Qty',
      width: 180,
      type: ['totalQty'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'distribution.locations_count',
      headerName: 'Total Store Count',
      width: 198,
    }),
    new Column({
      field: 'distribution.nrsc_quantity',
      headerName: 'POG Qty',
      filter: 'agNumberColumnFilter',
    }),
  ],
  exportColumns: [
    'workflow.status',
    'pog_categories',
    'spt_sign_on_pog',
    'sign_status',
    'sign_types',
    'description',
    'non_retail_item_info.dpci',
    'placement_on_shelf',
    'quantity_per_placement',
    'pog_unit_of_measure',
    'tie_to_product_dpci',
    'tie_to_hardware_dpci',
    'sign_notes.snp_note',
    'pog_exclusions',
    'sign_notes.spt_note',
    'non_retail_item_info.item_dimensions.width',
    'non_retail_item_info.item_dimensions.height',
    'non_retail_item_info.end_date',
    'distribution.total_quantity',
    'distribution.locations_count',
    'distribution.nrsc_quantity',
  ],
})

export const SPT_SPLN_VIEW = new AgGridSignListView({
  viewKey: 'spt',
  label: 'SPT',
  columns: [
    new Column({
      type: ['selectedRow', 'fixed'],
    }),
    new Column({
      type: ['signMenu', 'fixed'],
    }),
    new Column({
      field: 'workflow.status',
      headerName: 'Finalized Level',
      width: 176,
      type: ['finalizeCell', 'fixed'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'pog_categories',
      headerName: 'POG Categories',
      width: 220,
      type: ['fixed', 'pogCategoriesCell', 'pogCategoriesEditor'],
    }),
    new Column({
      field: 'spt_sign_on_pog',
      headerName: 'SPT on POG',
      width: 148,
      type: ['fixed', 'pogCell', 'pogEditorCell'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'sign_status',
      headerName: 'Sign Status',
      width: 154,
      type: ['tableChip', 'signStatusEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'sign_type',
      headerName: 'Sign Type',
      width: 206,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'description',
      headerName: 'Sign Description',
      width: 224,
      type: ['fixed', 'signDetailLink', 'textEditor'],
    }),
    new Column({
      field: 'non_retail_item_info.dpci',
      headerName: 'DPCI #',
      type: ['fixed'],
    }),
    new Column({
      field: 'priority',
      headerName: 'Priority',
      width: 172,
      type: ['textEditor'],
    }),
    new Column({
      field: 'program_name',
      headerName: 'Program Name',
      width: 216,
      type: ['textEditor'],
    }),
    new Column({
      field: 'endcap_type',
      headerName: 'Endcap Type',
      width: 200,
      type: ['textEditor'],
    }),
    new Column({
      field: 'ism_strategy',
      headerName: 'ISM Strategy',
      width: 203,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'placement_on_shelf',
      headerName: 'SPT Placement on Shelf',
      width: 192,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'quantity_per_placement',
      headerName: 'Quantity Per Placement',
      width: 220,
      type: ['textEditor'],
    }),
    new Column({
      field: 'pog_unit_of_measure',
      headerName: 'POG Unit of Measure',
      width: 220,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'tie_to_product_dpci',
      headerName: 'Tie to Product DPCI',
      width: 220,
      type: ['textEditor'],
    }),
    new Column({
      field: 'tie_to_hardware_dpci',
      headerName: 'Tie to Hardware DPCI',
      width: 220,
      type: ['textEditor'],
    }),
    new Column({
      field: 'sign_notes.snp_note',
      headerName: 'Placement Notes',
      width: 330,
      type: ['notes', 'notesEditor'],
    }),
    new Column({
      field: 'pog_exclusions',
      headerName: 'POG Exclusions',
      width: 192,
      type: ['textEditor'],
    }),
    new Column({
      field: 'sign_notes.spt_note',
      headerName: 'SPT Notes',
      width: 330,
      type: ['notes', 'notesEditor'],
    }),
    new Column({
      field: 'non_retail_item_info.item_dimensions.width',
      headerName: 'Finished Width',
      width: 186,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.item_dimensions.height',
      headerName: 'Finished Height',
      width: 192,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.end_date',
      headerName: 'End Date',
      width: 154,
      type: ['date'],
      filter: 'agDateColumnFilter',
    }),
    new Column({
      field: 'distribution.total_quantity',
      headerName: 'Total Qty',
      width: 180,
      type: ['totalQty'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'distribution.locations_count',
      headerName: 'Total Store Count',
      width: 198,
    }),
    new Column({
      field: 'distribution.nrsc_quantity',
      headerName: 'POG Qty',
      filter: 'agNumberColumnFilter',
    }),
  ],
  exportColumns: [
    'workflow.status',
    'pog_categories',
    'spt_sign_on_pog',
    'sign_status',
    'sign_types',
    'description',
    'non_retail_item_info.dpci',
    'priority',
    'program_name',
    'endcap_type',
    'ism_strategy',
    'placement_on_shelf',
    'quantity_per_placement',
    'pog_unit_of_measure',
    'tie_to_product_dpci',
    'tie_to_hardware_dpci',
    'sign_notes.snp_note',
    'pog_exclusions',
    'sign_notes.spt_note',
    'non_retail_item_info.item_dimensions.width',
    'non_retail_item_info.item_dimensions.height',
    'non_retail_item_info.end_date',
    'distribution.total_quantity',
    'distribution.locations_count',
    'distribution.nrsc_quantity',
  ],
})

export const VC_VIEW = new AgGridSignListView({
  viewKey: 'vc',
  label: 'VC',
  columns: [
    new Column({
      type: ['selectedRow', 'fixed'],
    }),
    new Column({
      type: ['signMenu', 'fixed'],
    }),
    new Column({
      field: 'non_retail_item_info.dpci',
      headerName: 'DPCI #',
      type: ['fixed'],
    }),
    new Column({
      field: 'description',
      headerName: 'Sign Description',
      width: 280,
      type: ['fixed', 'signDetailLink', 'textEditor'],
    }),
    new Column({
      field: 'sign_type',
      headerName: 'Sign Type',
      width: 206,
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'product_dpci',
      headerName: 'Product DPCI #',
      width: 218,
      type: ['productInfo'],
    }),
    new Column({
      field: 'non_retail_item_info.item_dimensions.width',
      headerName: 'Finished Width (VMG calls length)',
      width: 345,
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.item_dimensions.height',
      headerName: 'Finished Height',
      width: 220,
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      headerName: 'Planner/VC Notes',
      colId: 'sign_notes.planner_note',
      width: 330,
      type: ['notes', 'notesEditor'],
    }),
    new Column({
      field: 'sign_notes.snp_note',
      headerName: 'Placement Notes',
      width: 330,
      type: ['notes', 'notesEditor'],
    }),
    new Column({
      field: 'sign_status',
      headerName: 'Status',
      width: 154,
      type: ['tableChip', 'signStatusEditor'],
      filter: 'agSetColumnFilter',
    }),
  ],
  exportColumns: [
    'non_retail_item_info.dpci',
    'description',
    'non_retail_item_info.item_dimensions.width',
    'non_retail_item_info.item_dimensions.height',
    'product_dpci',
    'sign_notes.planner_note',
    'sign_notes.snp_note',
    'sign_status',
  ],
})

export const PRINT_VIEW_OLD = new AgGridSignListView({
  viewKey: 'print',
  label: 'Print',
  columns: [
    new Column({
      type: ['selectedRow', 'fixed'],
    }),
    new Column({
      type: ['signMenu', 'fixed'],
    }),
    new Column({
      field: 'workflow.status',
      headerName: 'Finalized Level',
      width: 176,
      type: ['finalizeCell', 'fixed'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.dpci',
      headerName: 'DPCI #',
      type: ['fixed'],
    }),
    new Column({
      field: 'description',
      headerName: 'Sign Description',
      width: 280,
      type: ['fixed', 'signDetailLink', 'textEditor'],
    }),
    new Column({
      field: 'sign_type',
      headerName: 'Sign Type',
      width: 206,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'sign_status',
      headerName: 'Sign Status',
      width: 154,
      type: ['tableChip', 'signStatusEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'printing.print_vendor',
      headerName: 'Print Vendor',
      width: 272,
      type: ['autocompleteEditor'],
    }),
    new Column({
      field: 'sign_creator',
      headerName: 'Creation Owner',
      width: 255,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'unit_of_measure',
      headerName: 'Unit of Measure',
      width: 186,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'printing.planned_sides',
      headerName: 'Planned Sides',
      width: 212,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'printing.file_approved',
      headerName: 'File Approved',
      width: 209,
      type: ['toggle', 'toggleEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.item_dimensions.width',
      headerName: 'Finished Width',
      width: 214,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.item_dimensions.height',
      headerName: 'Finished Height',
      width: 220,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.item_dimensions.depth',
      headerName: 'Finished Depth',
      width: 215,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.width',
      headerName: 'Flat Width',
      width: 186,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.height',
      headerName: 'Flat Height',
      width: 192,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'sign_notes.separator_note',
      headerName: 'Separator Notes',
      width: 330,
      type: ['notes', 'notesEditor'],
    }),
    new Column({
      field: 'printing.number_of_colors',
      headerName: '# of Colors',
      width: 197,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'printing.substrate1',
      headerName: 'Substrate 1',
      width: 196,
      type: ['autocompletesubstrateEditor'],
    }),
    new Column({
      field: 'printing.substrate2',
      headerName: 'Substrate 2',
      width: 196,
      type: ['autocompletesubstrateEditor'],
    }),
    new Column({
      field: 'printing.finishing',
      headerName: 'Finishing',
      width: 177,
      type: ['textEditor'],
    }),
    new Column({
      field: 'printing.coating',
      headerName: 'Coating',
      width: 169,
      type: ['textEditor'],
    }),
    new Column({
      field: 'printing.die_cut',
      headerName: 'Die Cut',
      type: ['textEditor'],
    }),
    new Column({
      field: 'printing.pre_assembled',
      headerName: 'Pre-Assembled',
      width: 218,
      type: ['toggle', 'toggleEditor'],
      filter: 'agSetColumnFilter',
    }),
  ],
  exportColumns: [
    'workflow.status',
    'non_retail_item_info.dpci',
    'description',
    'sign_types',
    'sign_status',
    'printing.print_vendor',
    'sign_creator',
    'unit_of_measure',
    'printing.planned_sides',
    'printing.file_approved',
    'non_retail_item_info.item_dimensions.width',
    'non_retail_item_info.item_dimensions.height',
    'non_retail_item_info.item_dimensions.depth',
    'non_retail_item_info.nominal_dimensions.width',
    'sign_notes.separator_note',
    'printing.number_of_colors',
    'printing.substrate1',
    'printing.substrate2',
    'printing.finishing',
    'printing.coating',
    'printing.die_cut',
    'printing.pre_assembled',
  ],
})

export const PRINT_VIEW = new AgGridSignListView({
  viewKey: 'print',
  label: 'Print',
  columns: [
    new Column({
      type: ['selectedRow', 'fixed'],
    }),
    new Column({
      type: ['signMenu', 'fixed'],
    }),
    new Column({
      field: 'workflow.status',
      headerName: 'Finalized Level',
      width: 176,
      type: ['finalizeCell', 'fixed'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.dpci',
      headerName: 'DPCI #',
      type: ['fixed'],
    }),
    new Column({
      field: 'description',
      headerName: 'Sign Description',
      width: 280,
      type: ['fixed', 'signDetailLink', 'textEditor'],
    }),
    new Column({
      field: 'sign_type',
      headerName: 'Sign Type',
      width: 206,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'sign_size',
      headerName: 'Sign Size',
      width: 170,
      type: ['autocompleteEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'sign_definition',
      headerName: 'Sign Definition',
      width: 400,
      type: ['autocompleteEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'sign_status',
      headerName: 'Sign Status',
      width: 154,
      type: ['tableChip', 'signStatusEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'printing.print_vendor',
      headerName: 'Print Vendor',
      width: 272,
      type: ['autocompleteEditor'],
    }),
    new Column({
      field: 'sign_creator',
      headerName: 'Creation Owner',
      width: 255,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'unit_of_measure',
      headerName: 'Unit of Measure',
      width: 186,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'printing.planned_sides',
      headerName: 'Planned Sides',
      width: 212,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'printing.file_approved',
      headerName: 'File Approved',
      width: 209,
      type: ['toggle', 'toggleEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.item_dimensions.width',
      headerName: 'Finished Width',
      width: 214,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.item_dimensions.height',
      headerName: 'Finished Height',
      width: 220,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.item_dimensions.depth',
      headerName: 'Finished Depth',
      width: 215,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.width',
      headerName: 'Flat Width',
      width: 186,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.height',
      headerName: 'Flat Height',
      width: 192,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.depth',
      headerName: 'Flat Depth',
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'sign_notes.separator_note',
      headerName: 'Separator Notes',
      width: 330,
      type: ['notes', 'notesEditor'],
    }),
    new Column({
      field: 'printing.number_of_colors',
      headerName: '# of Colors',
      width: 197,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'printing.substrate1',
      headerName: 'Substrate 1',
      width: 196,
      type: ['autocompletesubstrateEditor'],
    }),
    new Column({
      field: 'printing.substrate2',
      headerName: 'Substrate 2',
      width: 196,
      type: ['autocompletesubstrateEditor'],
    }),
    new Column({
      field: 'printing.finishing',
      headerName: 'Finishing',
      width: 177,
      type: ['textEditor'],
    }),
    new Column({
      field: 'printing.coating',
      headerName: 'Coating',
      width: 169,
      type: ['textEditor'],
    }),
    new Column({
      field: 'printing.die_cut',
      headerName: 'Die Cut',
      type: ['textEditor'],
    }),
    new Column({
      field: 'printing.sides_print',
      headerName: 'Sides Print',
      width: 178,
      type: ['toggle', 'toggleEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'printing.pre_assembled',
      headerName: 'Pre-Assembled',
      width: 218,
      type: ['toggle', 'toggleEditor'],
      filter: 'agSetColumnFilter',
    }),
  ],
  exportColumns: [
    'workflow.status',
    'non_retail_item_info.dpci',
    'description',
    'sign_types',
    'sign_status',
    'printing.print_vendor',
    'sign_creator',
    'unit_of_measure',
    'printing.planned_sides',
    'printing.file_approved',
    'non_retail_item_info.item_dimensions.width',
    'non_retail_item_info.item_dimensions.height',
    'non_retail_item_info.item_dimensions.depth',
    'non_retail_item_info.nominal_dimensions.width',
    'non_retail_item_info.nominal_dimensions.height',
    'non_retail_item_info.nominal_dimensions.depth',
    'sign_notes.separator_note',
    'printing.number_of_colors',
    'printing.substrate1',
    'printing.substrate2',
    'printing.finishing',
    'printing.coating',
    'printing.die_cut',
    'printing.sides_print',
    'printing.pre_assembled',
  ],
})

export const IPP_DEMAND_VIEW = new AgGridSignListView({
  viewKey: 'ipp',
  label: 'IPP Demand',
  columns: [
    new Column({
      type: ['selectedRow', 'fixed'],
    }),
    new Column({
      type: ['signMenu', 'fixed'],
    }),
    new Column({
      field: 'workflow.status',
      headerName: 'Finalized Level',
      width: 176,
      type: ['finalizeCell', 'fixed'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'spt_sign_on_pog',
      headerName: 'SPT on POG',
      width: 148,
      type: ['fixed', 'pogCell', 'pogEditorCell'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.dpci',
      headerName: 'DPCI #',
      type: ['fixed'],
    }),
    new Column({
      field: 'description',
      headerName: 'Sign Description',
      width: 224,
      type: ['fixed', 'signDetailLink', 'textEditor'],
    }),
    new Column({
      field: 'sign_type',
      colId: 'sign_types',
      headerName: 'Sign Type',
      width: 206,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'sign_status',
      headerName: 'Sign Status',
      width: 154,
      type: ['tableChip', 'signStatusEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'sign_creator',
      headerName: 'Creation Owner',
      width: 255,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'on_pog_sign',
      headerName: 'On POG Sign',
      type: ['toggle', 'toggleEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.width',
      headerName: 'Flat Width',
      width: 186,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.height',
      headerName: 'Flat Height',
      width: 192,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'printing.print_vendor',
      headerName: 'Print Vendor',
      width: 272,
      type: ['autocompleteEditor'],
    }),
    new Column({
      field: 'distribution.kitting_vendor',
      headerName: 'Kitting Vendor',
      width: 272,
      type: ['autocompleteEditor'],
    }),
    new Column({
      field: 'third_party_shipping',
      headerName: 'Third Party Shipping',
      type: ['toggle', 'toggleEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'distribution.kit_by_itself',
      headerName: 'Kit by Itself',
      width: 150,
      type: ['toggle', 'toggleEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'distribution.kit_groups',
      headerName: 'Kit Groups',
      type: ['kitGroups'],
    }),
    new Column({
      field: 'distribution.include_new_stores',
      headerName: 'Include New Stores',
      width: 200,
      type: ['toggle', 'toggleEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'distribution.locations_count',
      headerName: 'Total Store Count',
      width: 242,
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'distribution.max_quantity_per_kit',
      headerName: 'Max Qty per Kit',
      width: 219,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'distribution.quantity_per_store',
      headerName: 'Qty per Store',
      width: 210,
      type: ['quantityCell'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'distribution.nrsc_quantity',
      headerName: 'NRSC Qty',
      width: 178,
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'distribution.archway_quantity',
      headerName: 'Archway Qty',
      width: 203,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'distribution.total_quantity',
      headerName: 'Total Qty',
      width: 180,
      type: ['totalQty'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'sign_notes.snp_note',
      headerName: 'Placement Notes',
      width: 330,
      type: ['notes', 'notesEditor'],
    }),
  ],
  exportColumns: [
    'workflow.status',
    'spt_sign_on_pog',
    'non_retail_item_info.dpci',
    'description',
    'sign_types',
    'sign_status',
    'non_retail_item_info.nominal_dimensions.width',
    'non_retail_item_info.nominal_dimensions.height',
    'printing.print_vendor',
    'distribution.kitting_vendor',
    'distribution.kit_by_itself',
    'distribution.kit_groups',
    'distribution.include_new_stores',
    'distribution.locations_count',
    'distribution.max_quantity_per_kit',
    'distribution.quantity_per_store',
    'distribution.nrsc_quantity',
    'distribution.archway_quantity',
    'distribution.total_quantity',
    'sign_notes.snp_note',
  ],
})

export const SEPARATOR_AGENCY_VIEW = new AgGridSignListView({
  viewKey: 'separator',
  label: 'Separator',
  columns: [
    new Column({
      type: ['selectedRow', 'fixed'],
    }),
    new Column({
      type: ['signMenu', 'fixed'],
    }),
    new Column({
      field: 'workflow.status',
      headerName: 'Finalized Level',
      width: 176,
      type: ['finalizeCell', 'fixed'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.dpci',
      headerName: 'DPCI #',
      type: ['fixed'],
    }),
    new Column({
      field: 'description',
      headerName: 'Sign Description',
      width: 224,
      type: ['fixed', 'signDetailLink', 'textEditor'],
    }),
    new Column({
      field: 'printing.planned_sides',
      headerName: 'Planned Sides',
      width: 212,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'sign_type',
      colId: 'sign_types',
      headerName: 'Sign Type',
      width: 206,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.width',
      headerName: 'Flat Width',
      width: 186,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.height',
      headerName: 'Flat Height',
      width: 192,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.depth',
      headerName: 'Flat Depth',
      width: 215,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'printing.number_of_colors',
      headerName: '# of Colors',
      width: 197,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'sign_status',
      headerName: 'Sign Status',
      width: 154,
      type: ['tableChip', 'signStatusEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'unit_of_measure',
      headerName: 'Unit of Measure',
      width: 186,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'sign_notes.separator_note',
      headerName: 'Separator Notes',
      width: 330,
      type: ['notes', 'notesEditor'],
    }),
    new Column({
      field: 'printing.print_vendor',
      headerName: 'Print Vendor',
      width: 272,
      type: ['autocompleteEditor'],
    }),
    new Column({
      field: 'separator',
      headerName: 'Separator',
      width: 224,
      type: ['autocompleteEditor'],
    }),
    new Column({
      field: 'printing.substrate1',
      headerName: 'Substrate 1',
      width: 196,
      type: ['autocompletesubstrateEditor'],
    }),
  ],
  exportColumns: [
    'workflow.status',
    'non_retail_item_info.dpci',
    'description',
    'printing.planned_sides',
    'sign_types',
    'non_retail_item_info.nominal_dimensions.width',
    'non_retail_item_info.nominal_dimensions.height',
    'non_retail_item_info.nominal_dimensions.depth',
    'printing.number_of_colors',
    'sign_status',
    'unit_of_measure',
    'sign_notes.separator_note',
    'printing.print_vendor',
    'separator',
    'printing.substrate1',
  ],
})

export const IRL_VIEW_OLD = new AgGridSignListView({
  viewKey: 'irl',
  label: 'IRL',
  columns: [
    new Column({
      type: ['selectedRow', 'fixed'],
    }),
    new Column({
      type: ['signMenu', 'fixed'],
    }),
    new Column({
      field: 'workflow.status',
      headerName: 'Finalized Level',
      width: 176,
      type: ['finalizeCell', 'fixed'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.dpci',
      headerName: 'DPCI #',
      type: ['fixed'],
    }),
    new Column({
      field: 'description',
      headerName: 'Sign Description',
      width: 224,
      type: ['fixed', 'signDetailLink', 'textEditor'],
    }),
    new Column({
      field: 'sign_type',
      headerName: 'Sign Type',
      width: 206,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'unit_of_measure',
      headerName: 'Unit of Measure',
      width: 186,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.width',
      headerName: 'Flat Width',
      width: 186,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.height',
      headerName: 'Flat Height',
      width: 192,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'printing.print_vendor',
      headerName: 'Print Vendor',
      width: 272,
      type: ['autocompleteEditor'],
    }),
    new Column({
      field: 'distribution.kitting_vendor',
      headerName: 'Kitting Vendor',
      width: 272,
      type: ['autocompleteEditor'],
    }),
    new Column({
      field: 'third_party_shipping',
      headerName: 'Third Party Shipping',
      type: ['toggle', 'toggleEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'distribution.nrsc_quantity',
      headerName: 'NRSC Qty',
      width: 178,
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'distribution.archway_quantity',
      headerName: 'Archway Qty',
      width: 203,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'distribution.total_quantity',
      headerName: 'Total Qty',
      width: 180,
      type: ['totalQty'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'pricing.final_unit_price',
      headerName: 'Final Unit Price',
      width: 221,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
  ],
  exportColumns: [
    'workflow.status',
    'non_retail_item_info.dpci',
    'description',
    'sign_types',
    'unit_of_measure',
    'non_retail_item_info.nominal_dimensions.width',
    'non_retail_item_info.nominal_dimensions.height',
    'printing.print_vendor',
    'distribution.kitting_vendor',
    'distribution.nrsc_quantity',
    'distribution.archway_quantity',
    'distribution.total_quantity',
    'pricing.final_unit_price',
  ],
})

export const IRL_VIEW = new AgGridSignListView({
  viewKey: 'irl',
  label: 'IRL',
  columns: [
    new Column({
      type: ['selectedRow', 'fixed'],
    }),
    new Column({
      type: ['signMenu', 'fixed'],
    }),
    new Column({
      field: 'workflow.status',
      headerName: 'Finalized Level',
      width: 176,
      type: ['finalizeCell', 'fixed'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.dpci',
      headerName: 'DPCI #',
      type: ['fixed'],
    }),
    new Column({
      field: 'description',
      headerName: 'Sign Description',
      width: 224,
      type: ['fixed', 'signDetailLink', 'textEditor'],
    }),
    new Column({
      field: 'sign_type',
      headerName: 'Sign Type',
      width: 206,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'sign_size',
      headerName: 'Sign Size',
      width: 170,
      type: ['autocompleteEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'sign_definition',
      headerName: 'Sign Definition',
      width: 400,
      type: ['autocompleteEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'unit_of_measure',
      headerName: 'Unit of Measure',
      width: 186,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.width',
      headerName: 'Flat Width',
      width: 186,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.height',
      headerName: 'Flat Height',
      width: 192,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'printing.print_vendor',
      headerName: 'Print Vendor',
      width: 272,
      type: ['autocompleteEditor'],
    }),
    new Column({
      field: 'distribution.kitting_vendor',
      headerName: 'Kitting Vendor',
      width: 272,
      type: ['autocompleteEditor'],
    }),
    new Column({
      field: 'third_party_shipping',
      headerName: 'Third Party Shipping',
      type: ['toggle', 'toggleEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'distribution.nrsc_quantity',
      headerName: 'NRSC Qty',
      width: 178,
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'distribution.archway_quantity',
      headerName: 'Archway Qty',
      width: 203,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'distribution.total_quantity',
      headerName: 'Total Qty',
      width: 180,
      type: ['totalQty'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'pricing.final_unit_price',
      headerName: 'Final Unit Price',
      width: 221,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
  ],
  exportColumns: [
    'workflow.status',
    'non_retail_item_info.dpci',
    'description',
    'sign_types',
    'unit_of_measure',
    'non_retail_item_info.nominal_dimensions.width',
    'non_retail_item_info.nominal_dimensions.height',
    'printing.print_vendor',
    'distribution.kitting_vendor',
    'distribution.nrsc_quantity',
    'distribution.archway_quantity',
    'distribution.total_quantity',
    'pricing.final_unit_price',
  ],
})

export const SEPARATOR_AGENCY_VIEW_COMBINED = new AgGridSignListView({
  viewKey: 'agency_separator',
  label: `Agency/Separator`,
  columns: [
    new Column({
      type: ['selectedRow', 'fixed'],
    }),
    new Column({
      type: ['signMenu', 'fixed'],
    }),
    new Column({
      field: 'non_retail_item_info.dpci',
      headerName: 'DPCI #',
      type: ['fixed'],
    }),
    new Column({
      field: 'description',
      headerName: 'Sign Description',
      width: 224,
      type: ['fixed', 'textEditor', 'textFilter'],
    }),
    new Column({
      field: 'sign_type',
      colId: 'sign_types',
      headerName: 'Sign Type',
      width: 206,
      type: ['selectEditor', 'fixed'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'sign_template_info.source_id',
      headerName: 'ISM #',
      width: 180,
    }),
    new Column({
      field: 'sign_size',
      headerName: 'Sign Size',
      width: 170,
      type: ['autocompleteEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'sign_definition',
      headerName: 'Sign Definition',
      width: 400,
      type: ['autocompleteEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'sign_status',
      headerName: 'Sign Status',
      width: 154,
      type: ['tableChip', 'signStatusEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'printing.print_vendor',
      headerName: 'Print Vendor',
      width: 272,
      type: ['autocompleteEditor'],
    }),
    new Column({
      field: 'separator',
      headerName: 'Separator',
      width: 224,
      type: ['autocompleteEditor'],
    }),
    new Column({
      field: 'non_retail_item_info.item_dimensions.width',
      headerName: 'Finished Width',
      width: 186,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.item_dimensions.height',
      headerName: 'Finished Height',
      width: 192,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.item_dimensions.depth',
      headerName: 'Finished Depth',
      width: 215,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.width',
      headerName: 'Flat Width',
      width: 186,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.height',
      headerName: 'Flat Height',
      width: 192,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.depth',
      headerName: 'Flat Depth',
      width: 215,
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'printing.planned_sides',
      headerName: 'Planned Sides',
      width: 212,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'printing.sides_print',
      headerName: 'Sides Print',
      width: 178,
      type: ['toggle', 'toggleEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'printing.number_of_colors',
      headerName: '# of Colors',
      width: 197,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'printing.die_cut',
      headerName: 'Die Cut',
      type: ['textEditor'],
    }),
    new Column({
      field: 'sign_template_info.dieline_name',
      headerName: 'Dieline Name',
      type: ['textEditor'],
    }),
    new Column({
      field: 'sign_creator',
      headerName: 'Creation/Production Owner',
      width: 255,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'on_pog_sign',
      headerName: 'On POG Sign',
      type: ['toggle', 'toggleEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.upc',
      headerName: 'Sign UPC',
    }),
    new Column({
      field: 'sign_notes.separator_note',
      headerName: 'Separator Notes',
      width: 330,
      type: ['notes', 'notesEditor'],
    }),
    new Column({
      field: 'sign_notes.snp_note',
      headerName: 'POG Placement Notes',
      width: 330,
      type: ['notes', 'notesEditor'],
    }),
  ],
  exportColumns: [
    'non_retail_item_info.dpci',
    'description',
    'sign_types',
    'sign_template_info.source_id',
    'sign_size',
    'sign_definition',
    'sign_status',
    'printing.print_vendor',
    'separator',
    'non_retail_item_info.item_dimensions.width',
    'non_retail_item_info.item_dimensions.height',
    'non_retail_item_info.item_dimensions.depth',
    'non_retail_item_info.nominal_dimensions.width',
    'non_retail_item_info.nominal_dimensions.height',
    'non_retail_item_info.nominal_dimensions.depth',
    'printing.planned_sides',
    'printing.sides_print',
    'printing.number_of_colors',
    'printing.die_cut',
    'sign_template_info.dieline_name',
    'sign_creator',
    'on_pog_sign',
    'non_retail_item_info.upc',
    'sign_notes.separator_note',
    'sign_notes.snp_note',
  ],
})

export const SPLN_VIEW = new AgGridSignListView({
  viewKey: 'spln',
  label: 'SPLN',
  columns: [
    new Column({
      type: ['selectedRow', 'fixed'],
    }),
    new Column({
      type: ['signMenu', 'fixed'],
    }),
    new Column({
      field: 'workflow.status',
      headerName: 'Finalized Level',
      width: 176,
      type: ['finalizeCell', 'fixed'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.dpci',
      headerName: 'DPCI #',
      type: ['fixed'],
    }),
    new Column({
      field: 'description',
      headerName: 'Sign Description',
      width: 224,
      type: ['fixed', 'signDetailLink', 'textEditor'],
    }),
    new Column({
      field: 'priority',
      headerName: 'Priority',
      width: 172,
      type: ['textEditor'],
    }),
    new Column({
      field: 'program_name',
      headerName: 'Program Name',
      width: 216,
      type: ['textEditor'],
    }),
    new Column({
      field: 'endcap_type',
      headerName: 'Endcap Type',
      width: 200,
      type: ['textEditor'],
    }),
    new Column({
      field: 'ism_strategy',
      headerName: 'ISM Strategy',
      width: 203,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'sign_notes.planner_note',
      headerName: 'Planner/VC Notes',
      width: 330,
      type: ['notes', 'notesEditor'],
    }),
    new Column({
      field: 'separator',
      headerName: 'Merchant Contact',
      width: 244,
      type: ['autocompleteEditor'],
    }),
    new Column({
      field: 'sign_type',
      colId: 'sign_types',
      headerName: 'Sign Type',
      width: 224,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.width',
      headerName: 'Flat Width',
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.height',
      headerName: 'Flat Height',
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'sign_status',
      headerName: 'Sign Status',
      width: 154,
      type: ['tableChip', 'signStatusEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.end_date',
      headerName: 'End Date',
      width: 154,
      type: ['date'],
      filter: 'agDateColumnFilter',
    }),
    new Column({
      field: 'sign_notes.snp_note',
      headerName: 'Placement Notes',
      width: 330,
      type: ['notes', 'notesEditor'],
    }),
  ],
  exportColumns: [
    'workflow.status',
    'non_retail_item_info.dpci',
    'description',
    'priority',
    'program_name',
    'endcap_type',
    'ism_strategy',
    'sign_notes.planner_note',
    'separator',
    'sign_types',
    'non_retail_item_info.nominal_dimensions.width',
    'non_retail_item_info.nominal_dimensions.height',
    'sign_status',
    'non_retail_item_info.end_date',
    'sign_notes.snp_note',
  ],
})

export const DISPLAY_SIGN_VIEW = new AgGridSignListView({
  viewKey: 'displaySign',
  label: 'Display Sign',
  columns: [
    new Column({
      type: ['selectedRow', 'fixed'],
    }),
    new Column({
      type: ['signMenu', 'fixed'],
    }),
    new Column({
      field: 'workflow.status',
      headerName: 'Finalized Level',
      width: 176,
      type: ['finalizeCell', 'fixed'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.dpci',
      headerName: 'DPCI #',
      type: ['fixed'],
    }),
    new Column({
      field: 'description',
      headerName: 'Sign Description',
      width: 224,
      type: ['fixed', 'signDetailLink', 'textEditor'],
    }),
    new Column({
      field: 'non_retail_item_info.product_vendor',
      headerName: 'Product Vendor',
      width: 224,
      type: ['autocompleteEditor'],
    }),
    new Column({
      field: 'separator',
      headerName: 'Merchant Contact',
      width: 244,
      type: ['autocompleteEditor'],
    }),
    new Column({
      field: 'product_dpci',
      headerName: 'Product DPCI #',
      width: 218,
      type: ['productInfo', 'productInfoEditor'],
    }),
    new Column({
      field: 'product_tcin',
      headerName: 'Product TCIN #',
      width: 217,
      type: ['productInfo'],
    }),
    new Column({
      field: 'sign_type',
      colId: 'sign_types',
      headerName: 'Sign Type',
      width: 224,
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'sign_status',
      headerName: 'Sign Status',
      width: 154,
      type: ['tableChip', 'signStatusEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.width',
      headerName: 'Flat Width',
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.height',
      headerName: 'Flat Height',
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'attributes[0]',
      headerName: 'Attribute 1',
      type: ['productInfo', 'productInfoEditor'],
    }),
    new Column({
      field: 'attributes[1]',
      headerName: 'Attribute 2',
      type: ['productInfo', 'productInfoEditor'],
    }),
    new Column({
      field: 'attributes[2]',
      headerName: 'Attribute 3',
      type: ['productInfo', 'productInfoEditor'],
    }),
    new Column({
      field: 'attributes[3]',
      headerName: 'Attribute 4',
      type: ['productInfo', 'productInfoEditor'],
    }),
    new Column({
      field: 'messaging.sign_size',
      headerName: 'Sign Size',
      type: ['signSize', 'selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'messaging.mpp',
      headerName: 'Multiple Price Point',
      type: ['toggle', 'toggleEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'messaging.headline',
      headerName: 'Headline',
      width: 330,
      type: ['textEditor'],
    }),
    new Column({
      field: 'messaging_type',
      headerName: 'Sign Messaging Type',
      type: ['productInfo', 'productInfoEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'messaging_value.price',
      headerName: 'Price',
      type: ['productInfo', 'productInfoEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'messaging_value.lowest_price',
      headerName: 'Lowest Price',
      type: ['productInfo', 'productInfoEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'messaging_value.highest_price',
      headerName: 'Highest Price',
      type: ['productInfo', 'productInfoEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'messaging.manual_price_override',
      headerName: 'Manually Override Regular Price',
      type: ['toggle', 'toggleEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'messaging.topper_brand',
      headerName: 'Topper Brand',
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'messaging.disclaimer',
      headerName: 'Disclaimer',
      width: 244,
      type: ['textAreaEditor'],
    }),
    new Column({
      field: 'messaging.logo_url',
      headerName: 'Brand Logo URL',
      width: 244,
      type: ['textEditor'],
    }),
    new Column({
      field: 'sign_notes.snp_note',
      headerName: 'Placement Notes',
      width: 330,
      type: ['notes', 'notesEditor'],
    }),
    new Column({
      field: 'printing.print_vendor',
      headerName: 'Print Vendor',
      width: 244,
      type: ['autocompleteEditor', 'hiddenColumn'],
    }),
    new Column({
      field: 'distribution.kitting_vendor',
      headerName: 'Kitting Vendor',
      width: 244,
      type: ['autocompleteEditor', 'hiddenColumn'],
    }),
  ],
  exportColumns: [
    'workflow.status',
    'non_retail_item_info.dpci',
    'description',
    'non_retail_item_info.product_vendor',
    'separator',
    'product_dpci',
    'product_tcin',
    'sign_types',
    'sign_status',
    'non_retail_item_info.nominal_dimensions.width',
    'non_retail_item_info.nominal_dimensions.height',
    'messaging.item_info.attributes[0]',
    'messaging.item_info.attributes[1]',
    'messaging.item_info.attributes[2]',
    'messaging.item_info.attributes[3]',
    'messaging.sign_size',
    'messaging.mpp',
    'messaging.headline',
    'messaging.item_info.messaging_type',
    'messaging.item_info.messaging_value.price',
    'messaging.item_info.messaging_value.lowest_price',
    'messaging.item_info.messaging_value.highest_price',
    'messaging.manual_price_override',
    'messaging.topper_brand',
    'messaging.disclaimer',
    'messaging.logo_url',
    'sign_notes.snp_note',
  ],
})

export const CREATIVE_AGENCY_VIEW = new AgGridSignListView({
  viewKey: 'agency',
  label: 'Agency View',
  columns: [
    new Column({
      type: ['selectedRow', 'fixed'],
    }),
    new Column({
      type: ['signMenu', 'fixed'],
    }),
    new Column({
      field: 'workflow.status',
      headerName: 'Finalized Level',
      width: 176,
      type: ['finalizeCell', 'fixed'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.dpci',
      headerName: 'DPCI #',
      type: ['fixed'],
    }),
    new Column({
      field: 'description',
      headerName: 'Sign Description',
      width: 224,
      type: ['fixed', 'signDetailLink', 'textEditor'],
    }),
    new Column({
      field: 'sign_type',
      colId: 'sign_types',
      headerName: 'Sign Type',
      width: 224,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.width',
      headerName: 'Flat Width',
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.height',
      headerName: 'Flat Height',
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.depth',
      headerName: 'Flat Depth',
      type: ['numberEditor'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'sign_status',
      headerName: 'Sign Status',
      width: 154,
      type: ['tableChip', 'signStatusEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'sign_notes.snp_note',
      headerName: 'Placement Notes',
      width: 330,
      type: ['notes', 'notesEditor'],
    }),
    new Column({
      field: 'sign_notes.separator_note',
      headerName: 'Separator Notes',
      width: 330,
      type: ['notes', 'notesEditor'],
    }),
    new Column({
      field: 'printing.planned_sides',
      headerName: 'Planned Sides',
      width: 212,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'unit_of_measure',
      headerName: 'Unit of Measure',
      width: 186,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'printing.print_vendor',
      headerName: 'Print Vendor',
      width: 272,
      type: ['autocompleteEditor'],
    }),
    new Column({
      field: 'separator',
      headerName: 'Merchant Contact',
      width: 244,
      type: ['autocompleteEditor'],
    }),
  ],
  exportColumns: [
    'workflow.status',
    'non_retail_item_info.dpci',
    'description',
    'sign_types',
    'non_retail_item_info.nominal_dimensions.width',
    'non_retail_item_info.nominal_dimensions.height',
    'non_retail_item_info.nominal_dimensions.depth',
    'sign_status',
    'sign_notes.snp_note',
    'sign_notes.separator_note',
    'printing.planned_sides',
    'unit_of_measure',
    'printing.print_vendor',
    'separator',
  ],
})
