import React, { useState } from 'react'
import { Button, Form, Grid, Modal } from '@enterprise-ui/canvas-ui-react'
import { useBlueprintProjectListContext } from '../../context/blueprintProjectListContext'
import { useAgGridProjectListContext } from '../../context/agGridProjectListContext'

const NewProjectModal = () => {
  const { newProjectModal, setNewProjectModal } =
    useBlueprintProjectListContext()!
  const { addNewProject, duplicateSelectedProject } =
    useAgGridProjectListContext()!
  const [quantity, setQuantity] = useState(1)

  const handleSubmit = () => {
    if (newProjectModal?.type === 'add') {
      addNewProject(quantity, newProjectModal?.node?.rowIndex || null)
    }
    if (newProjectModal?.type === 'duplicate') {
      duplicateSelectedProject(newProjectModal.node!, quantity)
    }
    setNewProjectModal(undefined)
    setQuantity(1)
  }

  const handleClose = () => {
    setNewProjectModal(undefined)
    setQuantity(1)
  }

  return (
    // WARNING: Canvas v7 - Modal "closeButton" prop has been deprecated.
    <Modal
      className="new-project-modal"
      isVisible={newProjectModal !== undefined}
      onRefuse={() => handleClose()}
    >
      <div className="hc-pa-dense">
        <Grid.Container
          className="sem_StandardForm error_messaging_enabled"
          justify="space-between"
        >
          <Grid.Item xs={10}>
            <Form.Field
              id="number_of_projects"
              label={`Number of ${
                newProjectModal?.type === 'add' ? 'New' : 'Duplicate'
              } Projects`}
              type="number"
              value={quantity}
              hintText="Maximum of 10"
              min="1"
              max="10"
              onChange={(e: any) => setQuantity(e.target.value)}
            />
          </Grid.Item>
        </Grid.Container>
        <Grid.Container justify="flex-end">
          <Grid.Item>
            <Button
              type="secondary"
              className="hc-mr-normal"
              size="dense"
              onClick={() => handleClose()}
            >
              Cancel
            </Button>
            <Button type="primary" size="dense" onClick={() => handleSubmit()}>
              {newProjectModal?.type === 'add'
                ? 'Add Projects'
                : 'Duplicate Project'}
            </Button>
          </Grid.Item>
        </Grid.Container>
      </div>
    </Modal>
  )
}

export default NewProjectModal
