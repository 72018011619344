import React, { useEffect, Fragment } from 'react'
import { Grid, Divider, Card } from '@enterprise-ui/canvas-ui-react'
import MainSectionHeader from '../../../App/components/MainSectionHeader'
import CampaignList from './CampaignList'
import CampaignDetails from './CampaignDetails'
import { useAdminCampaignsContext } from '../context/adminCampaignsContext'

const AdminCampaigns = () => {
  const { getAllCampaignList, currentCampaign, getPyramidList } =
    useAdminCampaignsContext()!

  useEffect(() => {
    getAllCampaignList()
  }, [getAllCampaignList])

  useEffect(() => {
    getPyramidList()
  }, [getPyramidList])

  return (
    <Fragment>
      <MainSectionHeader sectionTitle="Campaign Types" />
      <Card className="hc-pa-normal">
        <Grid.Container>
          <Grid.Item xs={3}>
            <CampaignList />
          </Grid.Item>
          <Divider className="hc-pt-normal" direction="vertical" />
          <Grid.Item xs={9}>{currentCampaign && <CampaignDetails />}</Grid.Item>
        </Grid.Container>
      </Card>
    </Fragment>
  )
}

export default AdminCampaigns
