import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  SetStateAction,
  Dispatch,
  useEffect,
} from 'react'
import Axios from 'axios'
import { connect } from 'react-redux'
import {
  ADMIN_SERVICE_API_DOMAIN_URL,
  HEADER_OBJECT,
  SIGN_SERVICE_API_DOMAIN_URL,
  PROJECT_SERVICE_API_DOMAIN_URL,
  TOASTER_DEFAULTS,
} from 'components/App/constants/appConstants'
import { useEnv } from '@praxis/component-runtime-env'
import SignResponse, {
  PogInfo,
} from '../../../../../models/signs/SignResponse.model'
import { useAppContext } from 'components/App/context/appContext'
import { SignFacetsResponse } from '../../../../../models/signs/SignFacetsResponse.model'
import { ProjectDetail } from '../../../../../models/projects/ProjectDetail.model'
import { Division } from '../../../../../models/merchandise/hierarchy/Division.model'
import { useProjectDetailsContext } from '../../../context/projectDetailsContext'
import { SignNote } from '../../../../../models/signs/SignNote.model'
import SignDuplicateRequest from '../../../../../models/signs/SignDuplicateRequest.model'
import SignDuplicateResponse from '../../../../../models/signs/SignDuplicateResponse.model'
import { DialogProps } from '../../../../../models/app/DialogProps.model'
import TableEditorRequest, {
  TableEditedSign,
} from '../../../../../models/signs/TableEditorRequest.model'
import MoveSignRequest from '../../../../../models/signs/MoveSignRequest.model'
import { useUserContext } from 'components/App/context/userContext'
import { SignNotes } from '../../../../../models/signs/SignNotes.model'
import { PogSelectionRequest } from '../../../../../models/signs/PogSelectionRequest.modet'
import { getProjectBySapProjectId } from '../helpers/projectListHelpers'
import {
  Department,
  SignImportRequest,
  SignImportResponse,
} from '../../../../../models/signLibrary'
import { useToaster } from '@enterprise-ui/canvas-ui-react'
import { ContractCore } from '../../../../../models/contracts/ContractCore.model'
import SignTemplateResponse from '../../../../../models/signTemplates/SignTemplateResponse.model'

type ContextType = {
  signFacets: SignFacetsResponse
  refreshSignFacets: (project: ProjectDetail) => void
  finalizeSign: (
    signId: string,
    projectId: string | undefined,
    username: string,
  ) => void
  finalizeAllSigns: (projectId: string, username: string) => void
  importSigns: (importRequest: SignImportRequest) => void
  getSignImportInfo: Function
  getDuplicateInfo: Function
  signImportResponse: SignImportResponse
  setSignImportResponse: Function
  signDuplicateResponse: SignDuplicateResponse
  setSignDuplicateResponse: Function
  selectedSigns: SignResponse[]
  setSelectedSigns: Function
  isFinalizingSign: boolean
  setIsFinalizingSign: (isFinalizing: boolean) => void
  isFinalizingAllSigns: boolean
  setIsFinalizingAllSigns: (isFinalizing: boolean) => void
  signIdsToImport: string[]
  setSignIdsToImport: Function
  duplicateSign: (
    duplicateRequest: SignDuplicateRequest,
    setFieldValue: (field: string, value: any) => void,
  ) => void
  signForDuplicateModal: SignResponse | undefined
  setSignForDuplicateModal: Function
  selectedStatus: string
  setSelectedStatus: Function
  isImportSignsFullScreen: boolean
  setIsImportSignsFullScreen: (isFullScreen: boolean) => void
  isTableEditorFullScreen: boolean
  setIsTableEditorFullScreen: (isFullScreen: boolean) => void
  isSignGroupOpen: boolean
  setIsSignGroupOpen: (isOpen: boolean) => void
  isBulkEditOpen: boolean
  setIsBulkEditOpen: (isOpen: boolean) => void
  allSignsFinalized: boolean
  setAllSignsFinalized: (isFinalized: boolean) => void
  allSignReadyToFinalize: boolean
  setAllSignsReadyToFinalize: (isReadyToFinalize: boolean) => void
  allSignsReadyForKitting: boolean
  setAllSignsReadyForKitting: (isReadyForKitting: boolean) => void
  deleteSigns: (signIds: string[], reasonCode?: string) => void
  saveModifiedSigns: () => void
  signsModified: boolean
  setSignsModified: (signsModified: boolean) => void
  modifiedSigns: TableEditedSign[]
  setModifiedSigns: Function
  modifiedSignIds: string[]
  setModifiedSignIds: Function
  reasonCode: string
  setReasonCode: Function
  showReasonCodeModal: boolean
  setShowReasonCodeModal: Function
  actionType: string
  setActionType: Function
  signIds: string[]
  setSignIds: Function
  signToDuplicate: SignResponse | undefined
  setSignToDuplicate: Function
  pogs: PogInfo[]
  setPogs: (input: PogInfo[]) => void
  getPogs: (signId: string) => void
  includePogs: (
    signId: string,
    pogSelectionRequest: PogSelectionRequest,
  ) => void
  excludePogs: (
    signId: string,
    pogSelectionRequest: PogSelectionRequest,
  ) => void
  isPogModalOpen: any
  setIsPogModalOpen: ({
    open,
    sign_id,
  }: {
    open: boolean
    sign_id?: string
  }) => void
  isPogModalLoading: boolean
  setIsPogModalLoading: (isLoading: boolean) => void
  signNotesModal: any
  setSignNotesModal: ({
    open,
    filter,
  }: {
    open: boolean
    filter?: string
  }) => void
  signNotes: SignNotes
  setSignNotes: (notes: SignNotes) => void
  refreshSignNotes: (signId: string) => void
  loadingSignNotes: boolean
  setLoadingSignNotes: (isLoading: boolean) => void
  editProjectSignNote: (note: SignNote, signId: string) => void
  deleteProjectSignNote: (note: SignNote, signId: string) => void
  currentSignId: string
  setCurrentSignId: (id: string) => void
  currentSign: SignResponse | undefined
  setCurrentSign: (sign: SignResponse | undefined) => void
  isSignNotesFormOpen: boolean
  setIsSignNotesFormOpen: (open: boolean) => void
  refreshPogs: () => void
  moveModalOpen: boolean
  setMoveModalOpen: (isOpen: boolean) => void
  moveSigns: (
    signIds: string[],
    sourceProjectId: string,
    targetProjectId: string,
  ) => void
  signTemplateOptions: SignTemplateResponse[]
  getSignTemplatesByType: (type: string) => void
  showExportPdfModal: boolean
  setShowExportPdfModal: Dispatch<SetStateAction<boolean>>
  currentProject?: ProjectDetail
  activeContracts: ContractCore[]
}

export const ProjectSignListContext = createContext<ContextType | undefined>(
  undefined,
)

type Props = {
  children: React.ReactNode
}

export const ProjectSignListProviderComponent = ({ children }: Props) => {
  const env = useEnv()
  const makeToast = useToaster()
  const { userInfo } = useUserContext()!
  const { setFullPageLoadingMessage, setDialogProps, getActiveContracts } =
    useAppContext()!
  const {
    currentProject,
    refreshCurrentProject,
    setProjectSignList,
    getProjectSigns,
    setIsLoadingSignList,
  } = useProjectDetailsContext()!

  const [signFacets, setSignFacets] = useState(new SignFacetsResponse())
  const [selectedSigns, setSelectedSigns] = useState<SignResponse[]>([])
  const [isFinalizingSign, setIsFinalizingSign] = useState(false)
  const [isFinalizingAllSigns, setIsFinalizingAllSigns] = useState(false)
  const [signIdsToImport, setSignIdsToImport] = useState<string[]>([])
  const [signForDuplicateModal, setSignForDuplicateModal] = useState()
  const [selectedStatus, setSelectedStatus] = useState('')
  const [isImportSignsFullScreen, setIsImportSignsFullScreen] = useState(false)
  const [isTableEditorFullScreen, setIsTableEditorFullScreen] = useState(false)
  const [isSignGroupOpen, setIsSignGroupOpen] = useState(false)
  const [isBulkEditOpen, setIsBulkEditOpen] = useState(false)
  const [allSignsFinalized, setAllSignsFinalized] = useState(false)
  const [allSignReadyToFinalize, setAllSignsReadyToFinalize] = useState(false)
  const [allSignsReadyForKitting, setAllSignsReadyForKitting] = useState(false)
  const [signsModified, setSignsModified] = useState(false)
  const [modifiedSigns, setModifiedSigns] = useState<TableEditedSign[]>([])
  const [modifiedSignIds, setModifiedSignIds] = useState<string[]>([])
  const [reasonCode, setReasonCode] = useState<string>('')
  const [showReasonCodeModal, setShowReasonCodeModal] = useState<boolean>(false)
  const [actionType, setActionType] = useState<string>('')
  const [signIds, setSignIds] = useState<string[]>([])
  const [pogs, setPogs] = useState<PogInfo[]>([])
  const [signToDuplicate, setSignToDuplicate] = useState()
  const [showExportPdfModal, setShowExportPdfModal] = useState(false)
  const [isPogModalOpen, setIsPogModalOpen] = useState({
    open: false,
    sign_id: '',
  } as any)
  const [isPogModalLoading, setIsPogModalLoading] = useState(false)
  const [signNotesModal, setSignNotesModal] = useState<{
    open: boolean
    filter?: string
  }>({
    open: false,
    filter: 'All',
  } as any)
  const [loadingSignNotes, setLoadingSignNotes] = useState(false)
  const [signNotes, setSignNotes] = useState<SignNotes>(new SignNotes())
  const [currentSignId, setCurrentSignId] = useState('')
  const [currentSign, setCurrentSign] = useState<SignResponse | undefined>()
  const [isSignNotesFormOpen, setIsSignNotesFormOpen] = useState(false)
  const [signImportResponse, setSignImportResponse] = useState(
    new SignImportResponse(),
  )
  const [signDuplicateResponse, setSignDuplicateResponse] = useState(
    new SignDuplicateResponse(),
  )
  const [moveModalOpen, setMoveModalOpen] = useState(false)
  const [signTemplateOptions, setSignTemplateOptions] = useState<any[]>([])
  const [activeContracts, setActiveContracts] = useState<ContractCore[]>([])

  useEffect(() => {
    getActiveContracts().then((data: ContractCore[]) =>
      setActiveContracts(data),
    )
  }, [getActiveContracts])

  const refreshSignFacets = useCallback(
    async (project: ProjectDetail) => {
      try {
        const facetRes: any = await Axios.get(
          `${env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL}/facets/signs`,
        )
        if (project.project_id) {
          const departmentRes = await Axios.get(
            `${
              env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
            }/merchandise_hierarchy/departments`,
            {
              params: {
                division_id: project.divisions
                  .map((division: Division) => division.division_id)
                  .toString(),
                include_defaults: true,
              },
            },
          )
          setSignFacets(
            new SignFacetsResponse({
              ...facetRes.data,
              departments: departmentRes.data.sort(
                (a: Department, b: Department) =>
                  a.department_id > b.department_id ? 1 : -1,
              ),
            }),
          )
        } else {
          setSignFacets(new SignFacetsResponse({ ...facetRes.data }))
        }
      } catch (err: any) {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'error',
          heading: 'Failed to Get Sign Facets',
          message: err.response.data.message,
        })
      }
    },
    [makeToast, env.apiDomainUrl],
  )

  const finalizeSign = async (
    signId: string,
    projectId: string | undefined,
    username: string,
  ) => {
    setIsFinalizingSign(true)
    try {
      const res = await Axios.put(
        `${
          env.apiDomainUrl + SIGN_SERVICE_API_DOMAIN_URL
        }/signs/${signId}/finalize`,
        {
          project_id: projectId,
          updated_by: username,
        },
      )
      const signs = await Axios.get(
        `${
          env.apiDomainUrl + SIGN_SERVICE_API_DOMAIN_URL
        }/signs?project_id=${projectId}`,
      )
      const sortedSigns = signs.data.sort((a: any, b: any) =>
        a.created_date < b.created_date ? 1 : -1,
      )
      setProjectSignList([
        ...sortedSigns.map((sign: any) =>
          sign.sign_id === res.data.sign_id
            ? new SignResponse(res.data)
            : new SignResponse(sign),
        ),
      ])
      refreshCurrentProject(currentProject.project_id)
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Finalize Signs',
        message: err.response.data.message,
      })
    }
    setIsFinalizingSign(false)
  }

  const finalizeAllSigns = async (projectId: string, username: string) => {
    setIsFinalizingAllSigns(true)

    try {
      const res = await Axios.put(
        `${env.apiDomainUrl + SIGN_SERVICE_API_DOMAIN_URL}/signs/finalize_all`,
        {
          project_id: projectId,
          updated_by: username,
          finalized: true,
        },
      )
      const sortedSigns = res.data.sort((a: any, b: any) =>
        a.created_date < b.created_date ? 1 : -1,
      )
      setProjectSignList(sortedSigns.map((sign: any) => new SignResponse(sign)))
      refreshCurrentProject(currentProject.project_id)
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Finalize All Signs',
        message: err.response.data.message,
      })
    }
    setIsFinalizingAllSigns(false)
  }

  const importSigns = async (importRequest: SignImportRequest) => {
    setFullPageLoadingMessage('Importing signs...')
    try {
      var url = `${env.apiDomainUrl + SIGN_SERVICE_API_DOMAIN_URL}/signs/import`
      const res = await Axios.post(url, importRequest, {
        headers: { 'Content-Type': 'application/json' },
      })
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'success',
        heading: 'Import Started',
        message: 'Sign import in progress',
      })
      setSignImportResponse(res.data)
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Import Signs',
        message: err.response.data.message,
      })
    }
    setFullPageLoadingMessage('')
  }

  const getSignImportInfo = async (projectId: string) => {
    setFullPageLoadingMessage('Loading import info...')
    try {
      const res = await Axios.get(
        `${
          env.apiDomainUrl + SIGN_SERVICE_API_DOMAIN_URL
        }/signs/import/${projectId}`,
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      setSignImportResponse(res.data)
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Get Import Info',
        message: err.response.data.message,
      })
    }
    setFullPageLoadingMessage('')
  }

  const duplicateSign = async (
    duplicateRequest: SignDuplicateRequest,
    setFieldValue: (field: string, value: any) => void,
  ) => {
    setFullPageLoadingMessage('Duplicating Sign...')
    var url = `${
      env.apiDomainUrl + SIGN_SERVICE_API_DOMAIN_URL
    }/signs/duplicate`
    var newRequest = duplicateRequest
    if (reasonCode !== undefined || reasonCode !== '') {
      newRequest = { ...duplicateRequest, reason_code: reasonCode }
    }
    try {
      const res = await Axios.post(url, newRequest, HEADER_OBJECT)
      setSignDuplicateResponse(res.data)
      refreshCurrentProject(currentProject.project_id)
      setSignForDuplicateModal(undefined)
      setFieldValue('numberOfDuplicates', 1)
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'success',
        heading: 'Sign Duplication Begun',
        message: 'Sign duplication in progress',
      })
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Could Not Duplicate Sign',
        message: err.response.data.message,
      })
    }
    setFullPageLoadingMessage('')
  }

  const getDuplicateInfo = async (projectId: String) => {
    setFullPageLoadingMessage('Loading duplicate info...')
    try {
      const res = await Axios.get(
        `${
          env.apiDomainUrl + SIGN_SERVICE_API_DOMAIN_URL
        }/signs/duplicate/${projectId}`,
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      setSignDuplicateResponse(res.data)
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Get Duplicate Info',
        message: err.response.data.message,
      })
    }
    setFullPageLoadingMessage('')
  }

  const deleteSigns = async (signIds: string[], reasonCode?: string) => {
    try {
      setFullPageLoadingMessage('Deleting Sign...')
      const res = await Axios.put(
        `${env.apiDomainUrl + SIGN_SERVICE_API_DOMAIN_URL}/signs/batch`,
        new TableEditorRequest({
          project_id: currentProject.project_id,
          reason_code: reasonCode ? reasonCode : undefined,
          signs: signIds.map((id: string) => ({
            action: 'DELETE',
            sign_id: id,
          })),
          updated_by: userInfo.name,
        }),
      )
      const rejected_sign_messages: string[] = res.data.signs
        .filter((signRes: any) => signRes.edit_status.status === 'REJECTED')
        .map((signRes: any) => signRes.edit_status.error_message)
      const deletedSignIds = res.data.signs.map(
        (signRes: any) => signRes.edit_status.sign_id,
      )
      setProjectSignList((previousState: SignResponse[]) =>
        previousState
          .filter(
            (existingSign: SignResponse) =>
              !deletedSignIds.includes(existingSign.sign_id),
          )
          .sort((a: any, b: any) => (a.created_date < b.created_date ? 1 : -1)),
      )
      getProjectSigns(currentProject.project_id)
      refreshCurrentProject(currentProject.project_id)
      if (rejected_sign_messages.length > 0) {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'alert',
          heading: 'Some Sign(s) could not be deleted',
          message: rejected_sign_messages.join('\n'),
        })
      } else {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'success',
          heading: 'Sign(s) Deleted',
          message: `Successfully deleted ${
            res.data.signs.length > 1
              ? `${res.data.signs.length} Signs`
              : '1 Sign'
          } from this Project`,
        })
      }
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Delete Signs',
        message: err.response.data.message,
      })
    }
    setFullPageLoadingMessage('')
  }

  const saveModifiedSigns = async () => {
    setIsLoadingSignList(true)
    try {
      const res = await Axios.put(
        `${env.apiDomainUrl + SIGN_SERVICE_API_DOMAIN_URL}/signs/batch`,
        new TableEditorRequest({
          project_id: currentProject.project_id,
          reason_code: reasonCode ? reasonCode : undefined,
          signs: modifiedSigns,
          updated_by: userInfo.name,
        }),
        HEADER_OBJECT,
      )

      const rejected_sign_messages: string[] = res.data.signs
        .filter((signRes: any) => signRes.edit_status.status === 'REJECTED')
        .map((signRes: any) => signRes.edit_status.error_message)
      getProjectSigns(currentProject.project_id)
      setModifiedSigns([])
      setModifiedSignIds([])
      if (rejected_sign_messages.length > 0) {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'alert',
          heading: 'Some Sign(s) could not be updated',
          message: rejected_sign_messages.join('\n'),
        })
      } else {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'success',
          heading: 'Sign(s) Updated',
          message: `Successfully updated ${
            res.data.signs.length > 1
              ? `${res.data.signs.length} Signs`
              : '1 Sign'
          } in this Project`,
        })
      }
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Update Signs',
        message: err.response.data.message,
      })
    }
    setIsLoadingSignList(false)
  }

  const getPogs = async (signId: string) => {
    setIsPogModalLoading(true)
    try {
      const response = await Axios.get(
        `${env.apiDomainUrl + SIGN_SERVICE_API_DOMAIN_URL}/sign_pogs/${signId}`,
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      response.data &&
        setPogs(
          response.data.map((result: PogInfo) => {
            return new PogInfo(result)
          }),
        )
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Get Sign on POGs',
        message: err.response.data.message,
      })
    }
    setIsPogModalLoading(false)
  }

  const excludePogs = async (
    signId: string,
    pogSelectionRequest: PogSelectionRequest,
  ) => {
    setIsPogModalLoading(true)
    console.log('in exclude pogs ')
    try {
      const response = await Axios.put(
        `${
          env.apiDomainUrl + SIGN_SERVICE_API_DOMAIN_URL
        }/signs/pog_selections/${signId}`,
        pogSelectionRequest,
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      response.data &&
        setPogs(
          response.data.map((result: PogInfo) => {
            return new PogInfo(result)
          }),
        )
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'success',
        heading: 'POGs Excluded',
        message: 'Successfully excluded POGs',
      })
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Exclude POGs',
        message: err.response.data.message,
      })
    }
    setIsPogModalLoading(false)
  }

  const includePogs = async (
    signId: string,
    pogSelectionRequest: PogSelectionRequest,
  ) => {
    setIsPogModalLoading(true)
    try {
      const response = await Axios.put(
        `${
          env.apiDomainUrl + SIGN_SERVICE_API_DOMAIN_URL
        }/signs/pog_selections/${signId}`,
        pogSelectionRequest,
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      response.data &&
        setPogs(
          response.data.map((result: PogInfo) => {
            return new PogInfo(result)
          }),
        )
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'success',
        heading: 'POGs Included',
        message: 'Successfully included POGs',
      })
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Include POGs',
        message: err.response.data.message,
      })
    }
    setIsPogModalLoading(false)
  }
  const refreshSignNotes = async (signId: string) => {
    setLoadingSignNotes(true)
    try {
      const res = await Axios.get(
        `${
          env.apiDomainUrl + SIGN_SERVICE_API_DOMAIN_URL
        }/signs/${signId}/notes`,
        HEADER_OBJECT,
      )
      setSignNotes(new SignNotes(res.data))
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Get Sign Notes',
        message: err.response.data.message,
      })
    }
    setLoadingSignNotes(false)
  }

  const editProjectSignNote = async (note: SignNote, signId: string) => {
    try {
      setFullPageLoadingMessage('Updating note...')
      await Axios.put(
        `${
          env.apiDomainUrl + SIGN_SERVICE_API_DOMAIN_URL
        }/signs/${signId}/notes`,
        note,
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      await refreshSignNotes(signId)
      await getProjectSigns(currentProject.project_id)
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'success',
        heading: 'Note Updated',
        message: 'Successfully edited sign note',
      })
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Add Note',
        message: err.response.data.message,
      })
    }
    setFullPageLoadingMessage('')
  }

  const deleteProjectSignNote = async (note: SignNote, signId: string) => {
    try {
      setFullPageLoadingMessage('Deleting note...')
      await Axios.delete(
        `${
          env.apiDomainUrl + SIGN_SERVICE_API_DOMAIN_URL
        }/signs/${signId}/notes/${note.id}`,
      )
      await refreshSignNotes(signId)
      await getProjectSigns(currentProject.project_id)
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'success',
        heading: 'Note Deleted',
        message: 'Successfully deleted sign note',
      })
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Delete Note',
        message: err.response.data.message,
      })
    }
    setDialogProps(new DialogProps())
    setFullPageLoadingMessage('')
  }

  const refreshPogs = async () => {
    try {
      setFullPageLoadingMessage('Refreshing POGs...')
      await Axios.post(
        `${
          env.apiDomainUrl + SIGN_SERVICE_API_DOMAIN_URL
        }/signs/publish_signs?nri_refresh=false&project_id=${
          currentProject.project_id
        }`,
      )
      await Axios.put(
        `${
          env.apiDomainUrl + PROJECT_SERVICE_API_DOMAIN_URL
        }/projects/update_status`,
        {
          project_id: currentProject.project_id,
          kits_created: true,
          kits_finalized: false,
        },
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      refreshCurrentProject(currentProject.project_id)
      await getProjectSigns(currentProject.project_id)
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'success',
        heading: 'POGs Refreshed',
        message: 'Successfully refreshed all POGs',
      })
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Refresh POGs',
        message: err.response.data.message,
      })
    }
    setFullPageLoadingMessage('')
  }

  const moveSigns = async (
    signIds: string[],
    sourceProjectId: string,
    targetProjectId: string,
  ) => {
    try {
      setFullPageLoadingMessage('Moving Signs...')
      const projectIdFromSapProjectId =
        await getProjectBySapProjectId(targetProjectId)
      await Axios.put(
        `${
          env.apiDomainUrl + SIGN_SERVICE_API_DOMAIN_URL
        }/signs/transfer_signs`,
        new MoveSignRequest({
          source_project_id: sourceProjectId,
          target_project_id: projectIdFromSapProjectId,
          sign_ids: signIds,
          updated_by: userInfo.name,
        }),
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      refreshCurrentProject(currentProject.project_id)
      await getProjectSigns(currentProject.project_id)
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'success',
        heading: 'Signs Moved',
        message: 'Successfully moved all signs',
      })
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Move Signs',
        message: err.response.data.message,
      })
    }
    setFullPageLoadingMessage('')
  }

  const getSignTemplatesByType = async (type: string) => {
    try {
      const res = await Axios.get(
        `${
          env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
        }/templates/sign_templates/type?sign_type=${encodeURIComponent(type)}`,
      )
      setSignTemplateOptions(res.data)
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Get Sign Templates',
        message: err.response.data.message,
      })
    }
  }

  return (
    <ProjectSignListContext.Provider
      value={{
        signFacets,
        refreshSignFacets,
        finalizeAllSigns,
        finalizeSign,
        selectedSigns,
        setSelectedSigns,
        importSigns,
        isFinalizingSign,
        setIsFinalizingSign,
        isFinalizingAllSigns,
        setIsFinalizingAllSigns,
        signIdsToImport,
        setSignIdsToImport,
        duplicateSign,
        signForDuplicateModal,
        setSignForDuplicateModal,
        selectedStatus,
        setSelectedStatus,
        isImportSignsFullScreen,
        setIsImportSignsFullScreen,
        isTableEditorFullScreen,
        setIsTableEditorFullScreen,
        isSignGroupOpen,
        setIsSignGroupOpen,
        isBulkEditOpen,
        setIsBulkEditOpen,
        allSignsFinalized,
        setAllSignsFinalized,
        allSignReadyToFinalize,
        setAllSignsReadyToFinalize,
        allSignsReadyForKitting,
        setAllSignsReadyForKitting,
        deleteSigns,
        signsModified,
        setSignsModified,
        modifiedSigns,
        setModifiedSigns,
        modifiedSignIds,
        setModifiedSignIds,
        saveModifiedSigns,
        reasonCode,
        setReasonCode,
        showReasonCodeModal,
        setShowReasonCodeModal,
        actionType,
        setActionType,
        signIds,
        setSignIds,
        signToDuplicate,
        setSignToDuplicate,
        pogs,
        setPogs,
        getPogs,
        includePogs,
        excludePogs,
        isPogModalOpen,
        setIsPogModalOpen,
        isPogModalLoading,
        setIsPogModalLoading,
        signNotesModal,
        setSignNotesModal,
        loadingSignNotes,
        setLoadingSignNotes,
        signNotes,
        refreshSignNotes: refreshSignNotes,
        setSignNotes,
        editProjectSignNote,
        deleteProjectSignNote,
        currentSign,
        setCurrentSign,
        currentSignId,
        setCurrentSignId,
        isSignNotesFormOpen,
        setIsSignNotesFormOpen,
        signImportResponse,
        setSignImportResponse,
        getSignImportInfo,
        signDuplicateResponse,
        setSignDuplicateResponse,
        getDuplicateInfo,
        refreshPogs,
        moveModalOpen,
        setMoveModalOpen,
        moveSigns,
        signTemplateOptions,
        getSignTemplatesByType,
        showExportPdfModal,
        setShowExportPdfModal,
        currentProject,
        activeContracts,
      }}
    >
      {children}
    </ProjectSignListContext.Provider>
  )
}

export const useProjectSignListContext = () =>
  useContext(ProjectSignListContext)

export const ProjectSignListProvider = connect(
  null,
  null,
)(ProjectSignListProviderComponent)
