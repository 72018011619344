import { Input, Grid, Heading } from '@enterprise-ui/canvas-ui-react'
import { isEqual } from 'lodash'
import React from 'react'
import { useExportOptionsContext } from '../../../context/exportOptionsContext'
import {
  allProjectValues,
  allProjectValuesForTPSVendor,
  defaultProjectValues,
  PROJECT_INFO_EXPORT_FIELDS,
  EXCLUDE_FIELDS_FOR_EXT_VENDORS,
  sptProjectValues,
} from '../../../helpers/exportOptionHelpers'
import { useUserContext } from 'components/App/context/userContext'

const ProjectSignInfoFields = () => {
  const {
    projectSignSelectedFields,
    setProjectSignSelectedFields,
    allProjectSelected,
    setAllProjectSelected,
    defaultProjectSelected,
    setDefaultProjectSelected,
    sptProjectSelected,
    setSptProjectSelected,
  } = useExportOptionsContext()!
  const { userPermissions } = useUserContext()!

  const handleSelectAll = (selectAll: boolean) => {
    setAllProjectSelected(selectAll)
    setProjectSignSelectedFields(
      selectAll
        ? userPermissions.isTPSVendor
          ? allProjectValuesForTPSVendor
          : allProjectValues
        : [],
    )
    setDefaultProjectSelected(false)
    setSptProjectSelected(false)
  }

  const handleSelectDefault = (selectDefault: boolean) => {
    setDefaultProjectSelected(selectDefault)
    setProjectSignSelectedFields(selectDefault ? defaultProjectValues : [])
    setAllProjectSelected(false)
    setSptProjectSelected(false)
  }

  const handleSelectSpt = (selectDefault: boolean) => {
    setSptProjectSelected(selectDefault)
    setProjectSignSelectedFields(selectDefault ? sptProjectValues : [])
    setAllProjectSelected(false)
    setDefaultProjectSelected(false)
  }

  const handleSelect = (selectedFields: string[]) => {
    setProjectSignSelectedFields(selectedFields)
    setAllProjectSelected(
      isEqual(
        selectedFields.sort(),
        userPermissions.isTPSVendor
          ? allProjectValuesForTPSVendor.sort()
          : allProjectValues.sort(),
      ),
    )
    setDefaultProjectSelected(
      isEqual(selectedFields.sort(), defaultProjectValues.sort()),
    )
    setSptProjectSelected(
      isEqual(selectedFields.sort(), sptProjectValues.sort()),
    )
  }

  return (
    <Grid.Container spacing="none">
      <Grid.Item xs={12}>
        <Heading size={5} className="hc-clr-grey01 hc-pv-normal">
          Project Sign Fields
        </Heading>
      </Grid.Item>
      <Grid.Item xs={12}>
        <Input.Checkbox
          id="selectAllProjects"
          checked={allProjectSelected}
          label="All Project Signs Fields"
          onUpdate={(id: string, value: boolean) => handleSelectAll(value)}
        />
      </Grid.Item>
      <Grid.Item xs={12}>
        <Input.Checkbox
          id="selectDefaultProjects"
          checked={defaultProjectSelected}
          label="Default Project Signs Fields"
          onUpdate={(id: string, value: boolean) => handleSelectDefault(value)}
        />
      </Grid.Item>
      <Grid.Item xs={12}>
        <Input.Checkbox
          id="selectSptProjects"
          checked={sptProjectSelected}
          label="SPT Project Signs Fields"
          onUpdate={(id: string, value: boolean) => handleSelectSpt(value)}
        />
      </Grid.Item>
      <Grid.Item xs={12} className="hc-ml-expanded">
        <Input.Checkboxes
          id="selectProjectFields"
          value={projectSignSelectedFields}
          onUpdate={(id: string, value: string[]) => handleSelect(value)}
          options={
            userPermissions.isTPSVendor
              ? PROJECT_INFO_EXPORT_FIELDS.filter(
                  (field: any) =>
                    !EXCLUDE_FIELDS_FOR_EXT_VENDORS.includes(field.value),
                )
              : PROJECT_INFO_EXPORT_FIELDS
          }
        />
      </Grid.Item>
    </Grid.Container>
  )
}

export default ProjectSignInfoFields
