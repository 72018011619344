import React from 'react'
import { Tooltip } from '@enterprise-ui/canvas-ui-react'
import { ProjectMilestonesSummary } from '../../../../models/dashboard/ProjectMilestoneSummary.model'
import RubixChip from 'components/common/RubixChip'

export interface Props {
  value: string
  data: ProjectMilestonesSummary
}

const ProjectMilestoneHealthChip = ({ value, data }: Props) => {
  return (
    <Tooltip
      location="bottom"
      content={data.my_milestone_health_info.description}
    >
      <RubixChip color={data.my_milestone_health}>{value}</RubixChip>
    </Tooltip>
  )
}

export default ProjectMilestoneHealthChip
