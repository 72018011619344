export class DialogProps {
  headingText: string
  approveButtonText: string
  refuseButtonText?: string
  bodyText?: string
  onApprove: () => void
  onRefuse: () => void

  constructor(props: any = {}) {
    this.headingText = props.headingText
    this.bodyText = props.bodyText
    this.approveButtonText = props.approveButtonText
    this.refuseButtonText = props.refuseButtonText
    this.onApprove = props.onApprove
    this.onRefuse = props.onRefuse
  }
}
