import React, { PropsWithChildren, useEffect } from 'react'
import { Column } from 'ag-grid-community'
import {
  Avatar,
  Chip,
  Grid,
  List,
  Popover,
} from '@enterprise-ui/canvas-ui-react'

export interface Props {
  value: string[]
  style?: React.CSSProperties
  children: (chip: string) => React.ReactNode
  chip?: boolean
}

export interface PopoverProps {
  value: string[]
  renderItem: (val: string) => React.ReactNode
  chip?: boolean
}

export const PopoverContent = ({
  value,
  renderItem,
  chip = false,
}: PopoverProps) => {
  const Component = chip ? Chip : Avatar
  return (
    <Grid.Container>
      <Grid.Item className="hc-pv-none" xs={12}>
        <List>
          {value.map((val: string, index: number) => (
            <List.Item
              className="sem_ProductHighlights--ListItem"
              divider={index !== value.length - 1}
              key={index}
            >
              <Grid.Container spacing="space-between">
                <Grid.Item xs={true}>
                  <Component aria-label="product_highlights" size="dense">
                    {renderItem(val)}
                  </Component>
                </Grid.Item>
                <Grid.Item>{val}</Grid.Item>
              </Grid.Container>
            </List.Item>
          ))}
        </List>
      </Grid.Item>
    </Grid.Container>
  )
}

export const AvatarPopover = ({
  value,
  renderItem,
  children,
  chip = false,
}: PropsWithChildren<PopoverProps>) => {
  return (
    <Popover
      content={
        <PopoverContent chip={chip} value={value} renderItem={renderItem} />
      }
      size="normal"
      location="bottom-left"
    >
      {children}
    </Popover>
  )
}
export default function AvatarList({
  value,
  style = {},
  children,
  chip = false,
}: PropsWithChildren<Props>) {
  const Component = chip ? Chip : Avatar

  return (
    <div className="departments-chips-container" style={style}>
      {value.map((val: string, index: number) => (
        <Component aria-label="avatar" size="dense" key={index}>
          {children?.(val)}
        </Component>
      ))}
    </div>
  )
}

interface ResizableColProps {
  column: Column
  onSizeChange?: (size: number) => void
}

export const ResizableColWidthContainer = ({
  column,
  children,
  onSizeChange,
}: PropsWithChildren<ResizableColProps>) => {
  useEffect(() => {
    column.addEventListener('widthChanged', () => {
      onSizeChange?.(column.getActualWidth() - 20)
    })
  }, [column]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Grid.Container className="ag-cell-value">
      <Grid.Item>{children}</Grid.Item>
    </Grid.Container>
  )
}
