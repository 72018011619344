import React, { Fragment } from 'react'
import { Grid, Heading, Table } from '@enterprise-ui/canvas-ui-react'
import ProjectListTableItem from './ProjectListTableItem'
import { ProjectDetail } from '../../../models/projects/ProjectDetail.model'
import { useProjectListContext } from '../context/projectListContext'
import { useProjectFilterContext } from '../context/projectFilterContext'
import { SearchResult } from '../../../models/app/SearchResponse.model'
import SortableTableHeader from '../../App/components/SortableTableHeader'

const ProjectListTable = () => {
  const { projectSearchResponse } = useProjectListContext()!
  const { submitSort } = useProjectFilterContext()!

  return (
    <Fragment>
      <Grid.Item xs={12}>
        <Table className="sem-Table" name="Project List">
          <Table.Head>
            <Table.Row justify="space-between">
              <Table.Header className="sem_Table--IconHeader hc-mr-normal" />

              <SortableTableHeader
                searchPath="sap_project_id"
                text="Project Id"
                xs={1}
                sortFields={projectSearchResponse.sort_fields}
                submitSort={submitSort}
              />

              <SortableTableHeader
                searchPath="set_date"
                text="Set Date"
                xs={1}
                sortFields={projectSearchResponse.sort_fields}
                submitSort={submitSort}
              />

              <SortableTableHeader
                searchPath="name"
                text="Name"
                xs={2}
                sortFields={projectSearchResponse.sort_fields}
                submitSort={submitSort}
              />

              <Table.Header xs={1}>Status</Table.Header>

              <SortableTableHeader
                searchPath="blueprint_name"
                text="Blueprint Name"
                xs={2}
                sortFields={projectSearchResponse.sort_fields}
                submitSort={submitSort}
              />

              <SortableTableHeader
                searchPath="project_type"
                text="Type"
                xs={true}
                sortFields={projectSearchResponse.sort_fields}
                submitSort={submitSort}
              />

              <SortableTableHeader
                searchPath="tactic"
                text="Tactic"
                xs={1}
                sortFields={projectSearchResponse.sort_fields}
                submitSort={submitSort}
              />

              <SortableTableHeader
                searchPath="campaign.name"
                text="Campaign Type"
                xs={1}
                sortFields={projectSearchResponse.sort_fields}
                submitSort={submitSort}
              />
              <SortableTableHeader
                searchPath="departments.department_name"
                text="Department(s)"
                xs={1}
                sortFields={projectSearchResponse.sort_fields}
                submitSort={submitSort}
              />
              <SortableTableHeader
                searchPath="divisions.division_name"
                text="Division(s)"
                xs={1}
                sortFields={projectSearchResponse.sort_fields}
                submitSort={submitSort}
              />
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {projectSearchResponse.search.length > 0 ? (
              projectSearchResponse.search.map(
                (searchResult: SearchResult<ProjectDetail>, index: number) => (
                  <ProjectListTableItem
                    project={searchResult.result}
                    key={index}
                  />
                ),
              )
            ) : (
              <Table.Row
                className="sem_TableCardItem disabled"
                justify="space-around"
              >
                <Table.Data xs={4} className="hc-pv-dense">
                  <Heading size={6} className="hc-ta-center">
                    No Results to Display
                  </Heading>
                </Table.Data>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Grid.Item>
    </Fragment>
  )
}

export default ProjectListTable
