export class SearchRequest<T> {
  query_text: string
  strict_search: boolean
  facets: T
  include_fields?: string[]
  sort_fields: any
  range_filters?: any
  partial_filters?: any
  include_filters?: any
  exclude_filters?: any
  exists_filters?: any
  search_after?: any[]

  constructor(props: any = {}) {
    this.query_text = props.query_text || '*'
    this.strict_search = props.strict_search === false ? false : true
    this.facets = props.facets
    this.include_fields = props.include_fields
    this.sort_fields = props.sort_fields || {}
    this.range_filters = props.range_filters
    this.partial_filters = props.partial_filters
    this.include_filters = props.include_filters
    this.exclude_filters = props.exclude_filters
    this.exists_filters = props.exists_filters
    this.search_after = props.search_after
  }
}
