import React, { useCallback, useEffect } from 'react'
import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import { Grid } from '@enterprise-ui/canvas-ui-react'
import { useAppContext } from '../../../App/context/appContext'
import { useAgGridSignLibraryContext } from '../../context/agGridSignLibraryContext'
import { Column } from '../../../../models/agGrid/AgGridColumn.model'
import { loadColumnState, saveColumnState } from 'utils/agGrid'

export const SL_LS_KEY = 'AgGridSignLibraryTableColumnState'

interface SignLibraryTableProps {
  forceUpdate: any
}

const AgGridSignLibraryTable = ({ forceUpdate }: SignLibraryTableProps) => {
  const { signLibraryView } = useAppContext()!
  const {
    facetsAreLoaded,
    readyToLoadGrid,
    setReadyToLoadGrid,
    gridOptions,
    onGridReady,
    onCellValueChange,
  } = useAgGridSignLibraryContext()!

  const setLoadGridStatus = useCallback(
    (value: boolean) => {
      facetsAreLoaded && setReadyToLoadGrid(value)
    },
    [facetsAreLoaded, setReadyToLoadGrid],
  )

  useEffect(() => {
    setLoadGridStatus(true)

    return () => {
      setLoadGridStatus(false)
    }
  }, [setLoadGridStatus])

  return (
    <Grid.Item className="hc-pt-none" xs={12}>
      {readyToLoadGrid ? (
        <div className="ag-theme-rubix sign-library-table">
          <AgGridReact
            onColumnMoved={forceUpdate(saveColumnState(SL_LS_KEY))}
            gridOptions={gridOptions}
            onGridReady={loadColumnState(SL_LS_KEY, onGridReady)}
            rowHeight={48}
            rowModelType="serverSide"
            serverSideStoreType="partial"
            enableRangeSelection
            enableFillHandle
            fillHandleDirection="y"
            onCellValueChanged={onCellValueChange}
          >
            {signLibraryView.columns.map((column: Column, index: number) => (
              <AgGridColumn
                headerName={column.headerName}
                field={column.field}
                width={column.width || 148}
                type={column.type}
                filter={column.filter || 'agTextColumnFilter'}
                colId={column.colId}
                key={index}
              />
            ))}
          </AgGridReact>
        </div>
      ) : null}
    </Grid.Item>
  )
}

export default AgGridSignLibraryTable
