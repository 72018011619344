import React from 'react'
import { SignLibraryResponse } from '../../../../../models/signLibrary'

export interface Props {
  value: number
  data: SignLibraryResponse
}

const SetQuantityCell = ({ value, data }: Props) => (
  <div className="ag-cell-value">
    {data.non_retail_item_info.unit_of_measure === 'ST' ? value : 'N/A'}
  </div>
)

export default SetQuantityCell
