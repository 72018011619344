import { useMemo, useCallback, useState } from 'react'
import { ImageConfig } from './types'

export const useImageSelector = <T extends Record<string, any>>(
  selectedIndex: number,
  images: ImageConfig<T>[],
) => {
  const [hovering, setHovering] = useState(-1)

  const getIndex = useCallback(
    (next: number) => {
      return next < 0
        ? images.length + next
        : next > images.length - 1
        ? 0 + next - images.length
        : next
    },
    [images],
  )

  const left = useMemo(
    () => (images.length > 1 ? getIndex(selectedIndex - 1) : null),
    [selectedIndex, images, getIndex],
  )
  const right = useMemo(
    () => (images.length > 1 ? getIndex(selectedIndex + 1) : null),
    [selectedIndex, images, getIndex],
  )

  const farLeft = useMemo(
    () => (images.length > 3 ? getIndex(selectedIndex - 2) : null),
    [selectedIndex, images, getIndex],
  )

  const farRight = useMemo(
    () => (images.length > 3 ? getIndex(selectedIndex + 2) : null),
    [selectedIndex, images, getIndex],
  )

  const carouselImages = useMemo(() => {
    return [farLeft, left, selectedIndex, right, farRight].filter(
      (x) => x !== null,
    ) as number[]
  }, [farLeft, left, right, farRight, selectedIndex])

  return {
    carouselImages,
    selectedIndex,
    hovering,
    setHovering,
  }
}
