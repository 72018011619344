import { RosterUserType } from '../../components/Roster/constants/rosterConstants'

export interface RosterUser {
  id: string
  type: RosterUserType
}

export class InternalUser implements RosterUser {
  id: string
  email: string
  type: RosterUserType

  constructor(props: any = {}) {
    this.id = props.id || ''
    this.email = props.email || ''
    this.type = RosterUserType.INTERNAL
  }
}
