import React from 'react'
import { Link } from 'react-router-dom'
import { Anchor } from '@enterprise-ui/canvas-ui-react'
import { BlueprintSummary } from '../../../../models/dashboardV2/BlueprintSummary.model'

export interface Props {
  value: string
  data: BlueprintSummary
}

const BlueprintNameLink = ({ data, value }: Props) => {
  return (
    <div className="ag-cell-value">
      <Anchor
        className="sem_DashboardLink"
        as={Link}
        to={`/blueprints/${data.blueprint_id}`}
      >
        {value}
      </Anchor>
    </div>
  )
}

export default BlueprintNameLink
