import React, { useEffect, useState } from 'react'
import {
  Button,
  Form,
  Grid,
  GridContainer,
  GridItem,
  Table,
  Spinner,
  Modal,
  Input,
} from '@enterprise-ui/canvas-ui-react'
import { useAuth } from '@praxis/component-auth'
import { get } from 'lodash'
import { useProjectDetailsContext } from '../../../context/projectDetailsContext'
import {
  RunKitScenarioProvider,
  useRunKitScenarioContext,
} from '../context/runKitScenarioContext'
import { KitScenarioRequest } from '../../../../../models/kits/KitScenarioRequest.model'
import { useNavigate } from 'react-router-dom'
import { KIT_SCENARIO_OPTIONS, OTHERS } from '../constants/KitScenarioOptions'
import { useRunKitScenarioForm } from '../hooks/useRunKitScenarioForm'
import {
  KitScenario,
  KitScenarioConfig,
} from '../../../../../models/kits/KitScenario.model'
export interface Props {
  show_warning: boolean
  isRunKitScenarioOpen: boolean
  setRunKitScenarioOpen: Function
}

export const RunKitScenarioForm = ({
  show_warning = false,
  isRunKitScenarioOpen = false,
  setRunKitScenarioOpen,
}: Props) => {
  const { session } = useAuth()
  const username = get(session, 'userInfo.fullName', '')
  const { currentProject } = useProjectDetailsContext()!
  const { kittingVendors, getKittingVendors, kitInfoResponse, runKitScenario } =
    useRunKitScenarioContext()!

  const navigate = useNavigate()

  useEffect(() => {
    if (kitInfoResponse.id !== undefined) {
      navigate(
        `/projects/${currentProject.project_id}?tab=KITTIING/DISTRIBUTION`,
      )
      window.location.reload()
    }
  }, [kitInfoResponse, currentProject.project_id, navigate])

  useEffect(() => {
    if (kittingVendors === undefined) {
      getKittingVendors(currentProject.project_id)
    }
  }, [kittingVendors, currentProject.project_id, getKittingVendors])

  const [kitScenarioRequest, setKitScenarioRequest] = useState(
    new KitScenarioRequest({
      project_id: currentProject.project_id,
      updated_by: username,
      force: show_warning && show_warning,
      override_current_scenario: show_warning && show_warning,
    }),
  )

  const { handleSubmit, setFieldValue, values } = useRunKitScenarioForm(
    kitScenarioRequest,
    runKitScenario,
  )

  const [showOthers, setShowOthers] = useState(false)

  const kitScenarioOptionChange = (optionValue: string) => {
    if (optionValue === OTHERS) {
      setShowOthers(true)
      setKitScenarioRequest({
        ...kitScenarioRequest,
        kit_scenario: new KitScenario(),
      })
    } else {
      setShowOthers(false)
      setKitScenarioRequest({
        ...kitScenarioRequest,
        kit_scenario: new KitScenario({ no_adjustment: true }),
      })
    }
  }

  const disableRunButton = () => {
    if (
      kitScenarioRequest.kit_scenario === undefined ||
      (kitScenarioRequest.kit_scenario.no_adjustment === undefined &&
        values.kit_scenario.max_kits === undefined &&
        values.kit_scenario.sign_roundup === undefined)
    ) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    getKittingVendors(currentProject.project_id)
  }, [currentProject.project_id, getKittingVendors])

  const disabled = (
    kitVendor: string,
    kitScenarioConfigs?: KitScenarioConfig[],
  ) => {
    return kitScenarioConfigs
      ?.filter((value) => value !== undefined)
      ?.some(
        (value) =>
          value.kit_vendor === kitVendor &&
          value.quantity !== undefined &&
          value.quantity > 0,
      )
  }

  return (
    <Modal
      headingText="Run Kit Scenario"
      onRefuse={() => setRunKitScenarioOpen(false)}
      isVisible={isRunKitScenarioOpen}
      className="sem_KitScenarioModal"
    >
      <div className="hc-pa-normal">
        {show_warning && (
          <p className="hc-clr-error hc-pb-normal">
            Warning! The current Kits will be deleted when running new Scenario.
          </p>
        )}
        <Form id="sem_KitScenarioForm">
          <GridContainer align="center">
            <Grid.Item xs={12}>
              <Input.Label>Select Kit Scenario Type</Input.Label>
              <Input.Select
                id="kit_scenario"
                options={KIT_SCENARIO_OPTIONS}
                onUpdate={(id: any, value: string) => {
                  kitScenarioOptionChange(value)
                }}
              />
            </Grid.Item>
            {showOthers && (
              <GridItem xs={12}>
                {kittingVendors && kittingVendors.length > 0 ? (
                  <Table
                    name="quantity per kit vendor"
                    className="sem_KitScenarioTable"
                  >
                    <Table.Head>
                      <Table.Row>
                        <Table.Header xs={4} className="hc-pl-normal">
                          Kitting Vendor
                        </Table.Header>
                        <Table.Header xs={4} className="hc-pl-normal">
                          Roundup Sign Quantity Difference
                        </Table.Header>
                        <Table.Header xs={4} className="hc-pl-normal">
                          Max. Number of Kits
                        </Table.Header>
                      </Table.Row>
                    </Table.Head>
                    <Table.Body>
                      {kittingVendors.map((kitVendor, index) => (
                        <Table.Row key={index}>
                          <Table.Data xs={4} className="hc-pl-normal">
                            {kitVendor.kit_vendor}
                          </Table.Data>
                          <Table.Data xs={4} className="hc-pl-normal">
                            <Input.Text
                              id={`${kitVendor.kit_vendor}_sign_roundup`}
                              disabled={disabled(
                                kitVendor.kit_vendor,
                                values.kit_scenario.max_kits,
                              )}
                              type="number"
                              onChange={(e: any) => {
                                setFieldValue(
                                  `kit_scenario.sign_roundup[${index}]`,
                                  new KitScenarioConfig({
                                    kit_vendor: kitVendor.kit_vendor,
                                    quantity: +e.target.value,
                                  }),
                                )
                              }}
                            />
                          </Table.Data>
                          <Table.Data xs={4} className="hc-pl-normal">
                            <Input.Text
                              id={`${kitVendor.kit_vendor}_max_kits`}
                              disabled={disabled(
                                kitVendor.kit_vendor,
                                values.kit_scenario.sign_roundup,
                              )}
                              type="number"
                              onChange={(e: any) => {
                                setFieldValue(
                                  `kit_scenario.max_kits[${index}]`,
                                  new KitScenarioConfig({
                                    kit_vendor: kitVendor.kit_vendor,
                                    quantity: +e.target.value,
                                  }),
                                )
                              }}
                            />
                          </Table.Data>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                ) : (
                  <Spinner />
                )}
              </GridItem>
            )}
          </GridContainer>
          <GridContainer className="hc-pv-expanded" justify="center">
            <Grid.Item xs={true} className="hc-pr-expanded">
              <Button
                fullWidth
                type="primary"
                disabled={disableRunButton()}
                onClick={() => {
                  handleSubmit()
                  setRunKitScenarioOpen(false)
                }}
              >
                Run
              </Button>
            </Grid.Item>
          </GridContainer>
        </Form>
      </div>
    </Modal>
  )
}

const Component = (props: any) => (
  <RunKitScenarioProvider>
    <RunKitScenarioForm {...props} />
  </RunKitScenarioProvider>
)

export default Component
