import React, { Fragment, useEffect, useState } from 'react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { Button } from '@enterprise-ui/canvas-ui-react'
import { useMilestoneContext } from '../../../../App/context/milestoneContext'
import { useAdminMilestoneContext } from '../../context/adminMilestoneContext'
import { MilestoneTemplate } from '../../../../../models/milestones/MilestoneTemplate.model'

const AddScheduleMilestoneForm = () => {
  const {
    milestoneTemplates,
    getMilestoneTemplates,
    scheduleTemplateByScheduleTemplateId,
  } = useMilestoneContext()!
  const { addMilestones } = useAdminMilestoneContext()!
  const [selectedMilestoneIds, setSelectedMilestoneIds] = useState<string[]>([])
  const [, setFilteredMilestoneIds] = useState<string[]>([])
  useEffect(() => {
    getMilestoneTemplates()
  }, [getMilestoneTemplates])

  const milestoneOptions = milestoneTemplates
    .filter(
      (milestone: MilestoneTemplate) =>
        scheduleTemplateByScheduleTemplateId.milestones &&
        !scheduleTemplateByScheduleTemplateId.milestones.find(
          ({ name }) => milestone.name === name,
        ),
    )
    .map((milestone: MilestoneTemplate) => ({
      id: milestone.milestone_id,
      value: milestone.milestone_id,
      label: milestone.name,
    }))

  return (
    <Fragment>
      <Button
        fullWidth
        type="primary"
        className="addMilestone-Button"
        data-testid="addMilestones"
        onClick={() => {
          addMilestones(selectedMilestoneIds)
          setSelectedMilestoneIds([])
          setFilteredMilestoneIds([])
        }}
      >
        Click here to Add Milestones
      </Button>
      <Autocomplete
        id="add_milestone"
        label="Choose a Milestone(s) to add to this schedule"
        options={milestoneOptions}
        value={milestoneTemplates
          .filter((milestoneTemplate: MilestoneTemplate) =>
            selectedMilestoneIds.includes(milestoneTemplate.milestone_id),
          )
          .map((milestone: MilestoneTemplate) => ({
            id: milestone.milestone_id,
            value: milestone.milestone_id,
            label: milestone.name,
          }))}
        optionHeight="dense"
        multiselect
        onUpdate={(event: any, value: any) => {
          let selectedIds = value.map((option: any) => option.id)
          setSelectedMilestoneIds(value.map((option: any) => option.id))
          if (value.length > 0) {
            setFilteredMilestoneIds(
              milestoneTemplates
                .filter(
                  (milestone: MilestoneTemplate) =>
                    !selectedIds.includes(milestone.milestone_id),
                )
                .map((milestone: MilestoneTemplate) => milestone.milestone_id),
            )
          }
        }}
      />
    </Fragment>
  )
}

export default AddScheduleMilestoneForm
