export class Pyramid {
  group_id: number
  group_name: string
  group_display_name: string

  constructor(props: any = {}) {
    this.group_id = props.group_id || 0
    this.group_name = props.group_name || ''
    this.group_display_name = props.group_display_name || ''
  }
}
