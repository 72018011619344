import Axios from 'axios'
import { cloneDeep, get } from 'lodash'
import React, { createContext, useCallback, useContext, useState } from 'react'
import { connect } from 'react-redux'
import {
  SIGN_SERVICE_API_DOMAIN_URL,
  TOASTER_DEFAULTS,
} from '../../App/constants/appConstants'
import { useEnv } from '@praxis/component-runtime-env'
import { PreviewRequest } from '../../../models/signs/PreviewRequest.model'
import { ProductInfoResponse } from '../../../models/signs/ProductInfoResponse.model'
import { generatePreview } from '../helpers/previewHelper'
import { SIGN_DETAILS_SIDEBAR } from '../constants/signDetailsConstants'
import { useSignDetailsContext } from './signDetailsContext'
import MessagingResponse, {
  MessagingItemInfo,
} from '../../../models/signs/MessagingResponse.model'
import { useToaster } from '@enterprise-ui/canvas-ui-react'

type ContextType = {
  previewSrcUrl: string
  setPreviewSrcUrl: (url: string) => void
  productInfoList: ProductInfoResponse[]
  setProductInfoList: Function
  refreshProductInfo: (
    productDpci: string,
    itemIndex: number,
    itemInfo: MessagingItemInfo,
    handleChange: (id: string, value: string) => void,
  ) => void
  isPreviewLoading: boolean
  setIsPreviewLoading: (isLoading: boolean) => void
  refreshSignPreview: (values: MessagingResponse) => void
  previewSize: string
  setPreviewSize: (size: string) => void
  isItemLoading: boolean
  setIsItemLoading: (isLoading: boolean) => void
  fetchAllProductsInfo: (productDpci: string[]) => void
}

export const SignPreviewContext = createContext<ContextType | undefined>(
  undefined,
)

type Props = {
  children: React.ReactNode
}

const SignPreviewProviderComponent = ({ children }: Props) => {
  const { currentSign, setOpenSidebar } = useSignDetailsContext()!
  const env = useEnv()
  const makeToast = useToaster()

  const [previewSrcUrl, setPreviewSrcUrl] = useState('')
  const [productInfoList, setProductInfoList] = useState<ProductInfoResponse[]>(
    [],
  )
  const [isPreviewLoading, setIsPreviewLoading] = useState(false)
  const [previewSize, setPreviewSize] = useState('')
  const [isItemLoading, setIsItemLoading] = useState(false)

  // change to fetchAllProductInfo (used on page load only)
  const fetchAllProductsInfo = useCallback(
    async (productDpci: string[]) => {
      try {
        const promises = productDpci.map((dpci) => {
          return Axios.get(
            `${
              env.apiDomainUrl + SIGN_SERVICE_API_DOMAIN_URL
            }/signs/digital_items`,
            {
              params: {
                dpci,
              },
            },
          )
        })
        const allResults = await Promise.all(promises)
        const newProductInfoList = allResults.map(
          (res: any) => new ProductInfoResponse(res.data.data.items[0]),
        )
        setProductInfoList(newProductInfoList)
      } catch (err: any) {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'error',
          heading: 'Failed to Get Sign Product Info',
          message: err.response.data.message,
        })
      }
    },
    [makeToast, env.apiDomainUrl],
  )

  const refreshProductInfo = async (
    productDpci: string,
    itemIndex: number,
    itemInfo: MessagingItemInfo,
    handleChange: (id: string, value: any) => void,
  ) => {
    setIsItemLoading(true)
    try {
      const res = await Axios.get(
        `${env.apiDomainUrl + SIGN_SERVICE_API_DOMAIN_URL}/signs/digital_items`,
        {
          params: {
            dpci: productDpci,
          },
        },
      )
      const newProductInfo = new ProductInfoResponse(res.data.data.items[0])

      handleChange(
        `item_info[${itemIndex}]`,
        new MessagingItemInfo({
          ...itemInfo,
          product_dpci: productDpci,
          product_tcin: get(newProductInfo, 'product_tcin', ''),
        }),
      )
      setProductInfoList((previousState: ProductInfoResponse[]) => {
        const clonedState = cloneDeep(previousState)
        clonedState[itemIndex] = newProductInfo
        return clonedState
      })
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Get Product Info',
        message: err.response.data.message,
      })
    }
    setIsItemLoading(false)
  }

  const refreshSignPreview = async (messagingResponse: MessagingResponse) => {
    try {
      setIsPreviewLoading(true)
      setOpenSidebar(SIGN_DETAILS_SIDEBAR.PREVIEW)

      const previewUrl = await generatePreview(
        new PreviewRequest({
          set_date: currentSign.set_date,
          sign_type: currentSign.sign_type,
          sign_id: currentSign.sign_id,
          sign_dpci: get(currentSign, 'non_retail_item_info.dpci', ''),
          sign_tcin: get(currentSign, 'non_retail_item_info.tcin', ''),
          sign_barcode_number: get(currentSign, 'non_retail_item_info.upc', ''),
          locations: ['3'],
          topper_brand: messagingResponse.topper_brand,
          disclaimer: messagingResponse.disclaimer,
          inclusions: messagingResponse.inclusions,
          exclusions: messagingResponse.exclusions,
          logo_url: messagingResponse.logo_url,
          sign_size: messagingResponse.sign_size,
          headline: messagingResponse.headline,
          mpp: messagingResponse.mpp,
          item_list: messagingResponse.item_info,
        }),
      )
      setPreviewSize(messagingResponse.sign_size)
      setPreviewSrcUrl(previewUrl)
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Generate Preview',
        message: err.response.data.message,
      })
    }
    setIsPreviewLoading(false)
  }

  return (
    <SignPreviewContext.Provider
      value={{
        previewSrcUrl,
        setPreviewSrcUrl,
        productInfoList,
        setProductInfoList,
        refreshProductInfo,
        isPreviewLoading,
        setIsPreviewLoading,
        refreshSignPreview,
        previewSize,
        setPreviewSize,
        isItemLoading,
        setIsItemLoading,
        fetchAllProductsInfo,
      }}
    >
      {children}
    </SignPreviewContext.Provider>
  )
}

export const SignPreviewProvider = connect(
  null,
  null,
)(SignPreviewProviderComponent)

export const useSignPreviewContext = () => useContext(SignPreviewContext)
