import { useFormik } from 'formik'
import { get } from 'lodash'
import MessagingResponse from '../../../models/signs/MessagingResponse.model'
import SignResponse from '../../../models/signs/SignResponse.model'
import { SIGN_SIZE_FACETS } from '../constants/signMessagingFacets'
import { getMessagingErrors } from '../helpers/previewHelper'

export const useSignPreviewForm = (
  currentSign: SignResponse,
  refreshSignPreview: (values: MessagingResponse) => void,
) => {
  const isValidSize = get(
    SIGN_SIZE_FACETS,
    `[${currentSign.messaging.sign_size}].validSignTypes`,
    [],
  ).includes(currentSign.sign_type)

  const validate = (values: MessagingResponse) => {
    return getMessagingErrors(values)
  }

  return useFormik({
    enableReinitialize: true,
    initialValues: new MessagingResponse({
      ...currentSign.messaging,
      sign_size: isValidSize ? currentSign.messaging.sign_size : '',
    }),
    validate,
    validateOnMount: true,
    validateOnChange: true,
    onSubmit(values) {
      refreshSignPreview(values)
    },
  })
}
