import { MilestoneTemplate } from '../milestones/MilestoneTemplate.model'

export class BlueprintMilestoneSummary {
  id: string
  blueprint_id: string
  name: string
  set_date: string
  health: string
  milestone_health: string
  current_milestone?: MilestoneTemplate
  completed?: boolean
  campaign_type: string
  type: string
  tier_one: boolean
  incremental_project: boolean
  status: string

  constructor(props: any = {}) {
    this.id = props.id
    this.blueprint_id = props.blueprint_id || ''
    this.name = props.name || ''
    this.set_date = props.set_date || ''
    this.health = props.health || ''
    this.milestone_health = props.milestone_health || ''
    this.current_milestone = props.current_milestone || new MilestoneTemplate()
    this.completed = props.completed
    this.campaign_type = props.campaign_type || ''
    this.type = props.type || ''
    this.tier_one = props.tier_one
    this.incremental_project = props.incremental_project
    this.status = props.status
  }
}
