import React, { Fragment } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import {
  Table,
  Button,
  Anchor,
  Dropdown,
  Popover,
} from '@enterprise-ui/canvas-ui-react'
import { DateFormatter } from '@enterprise-ui/canvas-ui-react-date'
import EnterpriseIcon, {
  ArchiveIcon,
  FilesIcon,
  OverflowMenuVerticalIcon,
  VisibleShowIcon,
} from '@enterprise-ui/icons'
import { truncate } from 'lodash'
import { get } from 'lodash'
import { BlueprintDetail } from '../../../models/blueprints/BlueprintDetail.model'
import { Pyramid } from '../../../models/merchandise/hierarchy/Pyramid.model'
import { DialogProps } from '../../../models/app/DialogProps.model'
import { useUserContext } from '../../App/context/userContext'
import { useBlueprintListContext } from '../context/blueprintListContext'
import { useAppContext } from '../../App/context/appContext'

export interface Props {
  blueprint: BlueprintDetail
}

export const BlueprintListTableItem = ({ blueprint }: Props) => {
  const { cancelBlueprint } = useBlueprintListContext()!
  const { userPermissions } = useUserContext()!
  const { setDialogProps } = useAppContext()!

  const navigate = useNavigate()

  const handleViewClick = (id: string, type: string) => {
    type !== ''
      ? navigate(`/blueprints/${id}/${type}`)
      : navigate(`/blueprints/${id}`)
  }

  const reducer = (accumulator: any, currentValue: any) =>
    accumulator + currentValue

  const pyramids = get(blueprint, 'campaign.pyramids', [])
    .map(
      (pyramid: Pyramid, index: Number) =>
        (index ? ', ' : '') + pyramid.group_name,
    )
    .reduce(reducer, '')

  return (
    <Fragment>
      <Table.Row
        className="sem_TableCardItem"
        noWrap={true}
        justify="space-between"
      >
        <Table.Data className="hc-pv-dense" key={blueprint.blueprint_id}>
          <Dropdown location="bottom-left">
            <Button
              aria-label="Blueprint Row Dropdown"
              type="ghost"
              className="sem_IconBtn-dark"
              data-testid="openDropdown"
              iconOnly
              size="dense"
            >
              <EnterpriseIcon icon={OverflowMenuVerticalIcon} size="lg" />
            </Button>
            <Dropdown.Menu>
              <Dropdown.MenuItem
                data-testid="openBlueprint"
                onClick={() => handleViewClick(blueprint.blueprint_id, '')}
              >
                <EnterpriseIcon
                  className="hc-mr-dense"
                  icon={VisibleShowIcon}
                />
                View Blueprint Details
              </Dropdown.MenuItem>
              {userPermissions.isBlueprintAdmin && (
                <Dropdown.MenuItem
                  data-testid="duplicateBlueprint"
                  onClick={() =>
                    handleViewClick(blueprint.blueprint_id, 'duplicate')
                  }
                >
                  <EnterpriseIcon className="hc-mr-dense" icon={FilesIcon} />
                  Duplicate Blueprint
                </Dropdown.MenuItem>
              )}
              {userPermissions.isBlueprintAdmin &&
                blueprint.status !== 'Archive' &&
                blueprint.status !== 'Cancel' && (
                  <Dropdown.MenuItem
                    onClick={() =>
                      setDialogProps(
                        new DialogProps({
                          headingText:
                            'Are you sure you want to cancel this blueprint?',
                          approveButtonText: 'Yes',
                          onApprove: () =>
                            cancelBlueprint(blueprint.blueprint_id),
                          refuseButtonText: 'Cancel',
                          onRefuse: () => setDialogProps(new DialogProps()),
                        }),
                      )
                    }
                  >
                    <EnterpriseIcon
                      className="hc-mr-dense"
                      icon={ArchiveIcon}
                    />
                    Cancel Blueprint
                  </Dropdown.MenuItem>
                )}
            </Dropdown.Menu>
          </Dropdown>
        </Table.Data>
        <Table.Data className="hc-pl-dense" xs={1}>
          <DateFormatter format="MM/DD/YYYY" date={blueprint.set_date} />
        </Table.Data>
        <Table.Data xs={3}>
          <Anchor as={Link} to={`/blueprints/${blueprint.blueprint_id}`}>
            {blueprint.name}
          </Anchor>
        </Table.Data>
        <Table.Data className="hc-pv-dense" xs={1}>
          {blueprint.status}
        </Table.Data>
        <Table.Data xs={2}>
          {blueprint.campaign && blueprint.campaign.name}
        </Table.Data>
        <Table.Data xs={true}>{blueprint.type}</Table.Data>
        <Table.Data xs={true}>
          <Popover content={pyramids} key={pyramids}>
            {truncate(pyramids)}
          </Popover>
        </Table.Data>
      </Table.Row>
    </Fragment>
  )
}

export default BlueprintListTableItem
