import JobStatus from '../job/JobStatus.model'

export default class SignDuplicateResponse {
  id: string
  project_id: string
  status: JobStatus
  updated_by: string
  updated_date: string

  constructor(props: any = {}) {
    this.id = props.id
    this.project_id = props.project_id
    this.status = props.status
    this.updated_by = props.updated_by
    this.updated_date = props.updated_date
  }
}
