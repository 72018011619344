import React from 'react'
import { ProjectSignInfo } from '../../../../../models/signLibrary'
import { Link } from 'react-router-dom'
import { Anchor } from '@enterprise-ui/canvas-ui-react'

export interface Props {
  value: string
  data: ProjectSignInfo
}

const BlueprintNameCell = ({ value, data }: Props) => (
  <div className="ag-cell-value">
    <Anchor as={Link} to={`/blueprints/${data.blueprint_id}`}>
      {value}
    </Anchor>
  </div>
)

export default BlueprintNameCell
