export default class SignDuplicateRequest {
  project_id: string
  sign_id: string
  times: number
  reason_code?: string
  updated_by: string

  constructor(props: any = {}) {
    this.project_id = props.project_id
    this.sign_id = props.sign_id
    this.times = props.times
    this.reason_code = props.reason_code
    this.updated_by = props.updated_by || ''
  }
}
