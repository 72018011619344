import { BATCH_EDIT_ACTION } from '../../components/App/constants/appConstants'

export default class SignTemplateBatchRequest {
  sign_template_dto: SignTemplateRequest
  action: BATCH_EDIT_ACTION

  constructor(props: any = {}) {
    this.sign_template_dto = props.sign_template_dto
    this.action = props.action
  }
}

export class SignTemplateRequest {
  id?: string
  name: string
  type: string
  finished_width: string
  finished_length: string
  finished_depth: string
  flat_width: string
  flat_length: string
  flat_depth: string
  size_editable: boolean

  constructor(props: any = {}) {
    this.id = props.id
    this.name = props.name
    this.type = props.type
    this.finished_width = props.finished_width
    this.finished_length = props.finished_length
    this.finished_depth = props.finished_depth
    this.flat_width = props.flat_width
    this.flat_length = props.flat_length
    this.flat_depth = props.flat_depth
    this.size_editable = props.size_editable
  }
}
