import React from 'react'
import { Chip } from '@enterprise-ui/canvas-ui-react'

interface Props {
  color?: string
  children: any
}

const RubixChip = ({ color, children }: Props) => {
  return (
    <Chip size="dense" className={`rbx-chip ${color}`}>
      {children}
    </Chip>
  )
}

export default RubixChip
