import Axios from 'axios'
import React, { createContext, useState, useContext, useCallback } from 'react'
import { connect } from 'react-redux'
import {
  PROJECT_SERVICE_API_DOMAIN_URL,
  TOASTER_DEFAULTS,
} from 'components/App/constants/appConstants'
import { useEnv } from '@praxis/component-runtime-env'
import { MilestoneTemplate } from '../../../../../models/milestones/MilestoneTemplate.model'
import { useProjectDetailsContext } from '../../../context/projectDetailsContext'
import { useAppContext } from 'components/App/context/appContext'
import { ProjectDetail } from '../../../../../models/projects/ProjectDetail.model'
import { useMilestoneContext } from 'components/App/context/milestoneContext'
import { useToaster } from '@enterprise-ui/canvas-ui-react'

type ContextType = {
  projectMilestone: MilestoneTemplate[]
  setProjectMilestone: (milestones: MilestoneTemplate[]) => void
  getProjectMilestones: () => void
  saveProjectMilestones: (
    projectId: string,
    milestonesToAdd: MilestoneTemplate[],
    updatedBy: string,
  ) => void

  milestonesInProjectPhase: MilestoneTemplate[]
  getMilestonesInProjectPhase: () => void

  addMilestones: (milestoneIdsToAdd: string[]) => void
  addNewMilestone: (milestoneToAdd: MilestoneTemplate) => void
  updateMilestone: (milestoneIdToUpdate: MilestoneTemplate) => void

  addMilestone: boolean
  setAddMilestone: Function
  createMilestone: boolean
  setCreateMilestone: Function
  isOpen: boolean
  setIsOpen: Function
  closeMilestoneForm: Function
}

export const ProjectMilestoneTabContext = createContext<
  ContextType | undefined
>(undefined)

type Props = {
  children: React.ReactNode
}

export const ProjectMilestoneTabProviderComponent = ({ children }: Props) => {
  const { currentProject, setCurrentProject } = useProjectDetailsContext()!
  const { setFullPageLoadingMessage, setPageHasChanges } = useAppContext()!
  const { setModifiedMilestoneIds } = useMilestoneContext()!
  const env = useEnv<any>()
  const makeToast = useToaster()
  const [milestonesInProjectPhase, setMilestonesInProjectPhase] = useState<
    MilestoneTemplate[]
  >([])
  const [projectMilestone, setProjectMilestone] = useState<MilestoneTemplate[]>(
    [],
  )
  const [addMilestone, setAddMilestone] = useState(false)
  const [createMilestone, setCreateMilestone] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const getProjectMilestones = useCallback(async () => {
    if (currentProject.milestones && currentProject.milestones.length > 0) {
      setFullPageLoadingMessage('Loading Milestone...')

      try {
        const res = await Axios.get(
          `${env.apiDomainUrl + PROJECT_SERVICE_API_DOMAIN_URL}/projects/${
            currentProject.project_id
          }/milestones`,
        )
        setProjectMilestone(
          res.data.map((milestone: any) => new MilestoneTemplate(milestone)),
        )
      } catch (err: any) {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'error',
          heading: 'Failed to Get Project Milestones',
          message: err.response.data.message,
        })
      }
      setFullPageLoadingMessage('')
    }
  }, [
    makeToast,
    currentProject.project_id,
    currentProject.milestones,
    setFullPageLoadingMessage,
    env,
  ])

  const saveProjectMilestones = async (
    projectId: string,
    milestonesToAdd: MilestoneTemplate[],
    updatedBy: string,
  ) => {
    setFullPageLoadingMessage('Saving Project Milestone...')
    const ProjectMilestonesRequest = {
      milestones: milestonesToAdd,
      updated_by: updatedBy,
    }
    try {
      const res = await Axios.put(
        `${
          env.apiDomainUrl + PROJECT_SERVICE_API_DOMAIN_URL
        }/projects/${projectId}/milestones`,
        ProjectMilestonesRequest,
      )
      setCurrentProject(new ProjectDetail(res.data))
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'success',
        heading: 'Project Milestones Saved',
        message: 'Successfully saved project milestones',
      })
      setModifiedMilestoneIds(new Set())
      setPageHasChanges(false)
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Save Project Milestones',
        message: err.response.data.message,
      })
    }
    setFullPageLoadingMessage('')
  }

  const getMilestonesInProjectPhase = useCallback(async () => {
    try {
      const res = await Axios.get(
        `${env.apiDomainUrl + PROJECT_SERVICE_API_DOMAIN_URL}/blueprints/${
          currentProject.blueprint_id
        }/milestones`,
      )
      const filteredMilestones = res.data.milestones.filter(
        (milestone: any) =>
          milestone.project_phase === 'Sign List Finalization' ||
          milestone.project_phase === 'Production to Market',
      )
      setMilestonesInProjectPhase(
        filteredMilestones.map(
          (milestone: any) => new MilestoneTemplate(milestone),
        ),
      )
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Get Milestones in Project Phase',
        message: err.response.data.message,
      })
    }
  }, [makeToast, currentProject.blueprint_id, env])

  const addMilestones = (milestoneIdsToAdd: string[]) => {
    const milestonesToAdd = milestonesInProjectPhase.filter(
      (milestone: MilestoneTemplate) =>
        milestoneIdsToAdd.find((id: string) => milestone.milestone_id === id),
    )
    setProjectMilestone((previousState: MilestoneTemplate[]) => [
      ...previousState,
      ...milestonesToAdd,
    ])
  }

  const addNewMilestone = (milestoneToAdd: MilestoneTemplate) => {
    setProjectMilestone((previousState: MilestoneTemplate[]) => [
      ...previousState,
      milestoneToAdd,
    ])
  }

  const updateMilestone = (milestoneToUpdate: MilestoneTemplate) => {
    setProjectMilestone(
      projectMilestone.map((milestone: MilestoneTemplate) =>
        milestone.milestone_id === milestoneToUpdate.milestone_id
          ? milestoneToUpdate
          : milestone,
      ),
    )
  }

  const closeMilestoneForm = () => {
    setAddMilestone(false)
    setCreateMilestone(false)
    setIsOpen(false)
  }

  return (
    <ProjectMilestoneTabContext.Provider
      value={{
        projectMilestone,
        setProjectMilestone,
        getProjectMilestones,
        saveProjectMilestones,

        milestonesInProjectPhase,
        getMilestonesInProjectPhase,

        addMilestones,
        addNewMilestone,
        updateMilestone,

        addMilestone,
        setAddMilestone,
        createMilestone,
        setCreateMilestone,
        isOpen,
        setIsOpen,
        closeMilestoneForm,
      }}
    >
      {children}
    </ProjectMilestoneTabContext.Provider>
  )
}

export const useProjectMilestoneTabContext = () =>
  useContext(ProjectMilestoneTabContext)

export const ProjectMilestoneTabProvider = connect(
  null,
  null,
)(ProjectMilestoneTabProviderComponent)
