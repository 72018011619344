import { MY_MILESTONE_HEALTH } from '../../components/App/constants/appConstants'
import { MilestoneTemplate } from '../milestones/MilestoneTemplate.model'

export class ProjectMilestonesSummary {
  sap_project_id?: string
  project_id?: string
  project_name: string
  blueprint_name?: string
  set_date: string
  my_milestone?: MilestoneTemplate
  due_date: string
  complete: boolean
  my_milestone_health: string
  my_milestone_health_info: MyMilestoneHealthInfo
  dependency_milestone?: MilestoneTemplate
  dependency_due_date?: string
  dependency_owner?: string[]
  dependency_complete: string
  campaign: string
  budget: number

  constructor(props: any = {}) {
    this.sap_project_id = props.sap_project_id
    this.project_id = props.project_id
    this.project_name = props.project_name || ''
    this.blueprint_name = props.blueprint_name
    this.set_date = props.set_date || ''
    this.my_milestone = props.my_milestone
      ? new MilestoneTemplate(props.my_milestone)
      : new MilestoneTemplate({})
    this.due_date = props.my_due_date || ''
    this.complete = props.complete || false
    this.my_milestone_health = props.my_milestone_health
    this.my_milestone_health_info = new MyMilestoneHealthInfo(
      props.my_milestone_health,
    )
    this.dependency_milestone = props.dependency_milestone
      ? new MilestoneTemplate(props.dependency_milestone)
      : new MilestoneTemplate({})
    this.dependency_due_date = props.dependency_due_date
    this.dependency_owner = props.dependency_owner
    this.dependency_complete = props.dependency_complete === true ? 'Yes' : 'No'
    this.campaign = props.campaign || ''
    this.budget = props.budget
  }
}
export class MyMilestoneHealthInfo {
  color: string
  label: string
  description: string

  constructor(props: string = '') {
    this.color = props
    this.label =
      props === MY_MILESTONE_HEALTH.RED
        ? 'At Risk'
        : props === MY_MILESTONE_HEALTH.YELLOW
        ? 'Past Due'
        : props === MY_MILESTONE_HEALTH.BLUE
        ? 'Due Soon'
        : props === MY_MILESTONE_HEALTH.GREEN
        ? 'Completed'
        : props === MY_MILESTONE_HEALTH.GREY
        ? 'Upcoming'
        : ''
    this.description =
      props === MY_MILESTONE_HEALTH.RED
        ? 'More than 7 days past due.'
        : props === MY_MILESTONE_HEALTH.YELLOW
        ? '1 to 7 days past due.'
        : props === MY_MILESTONE_HEALTH.BLUE
        ? 'Due in the next 3 weeks.'
        : props === MY_MILESTONE_HEALTH.GREEN
        ? 'Milestone completed.'
        : props === MY_MILESTONE_HEALTH.GREY
        ? 'More than 3 weeks out.'
        : ''
  }
}
