import PageChangesWrapper from 'components/App/components/PageChangesWrapper'
import React from 'react'
import AddOnExpensesTab from 'components/ProjectDetails/components/AddOnExpenses/AddOnExpensesTab'
import { AgGridAddOnProvider } from 'components/ProjectDetails/components/AddOnExpenses/context/agGridAddOnContext'
import { AddOnProvider } from 'components/ProjectDetails/components/AddOnExpenses/context/addOnContext'

const AddOnExpensesContainer = (props: any) => (
  <PageChangesWrapper>
    <AddOnProvider>
      <AgGridAddOnProvider>
        <AddOnExpensesTab {...props} />
      </AgGridAddOnProvider>
    </AddOnProvider>
  </PageChangesWrapper>
)

export default AddOnExpensesContainer
