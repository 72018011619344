import React from 'react'
import { Dropdown, Grid, Button } from '@enterprise-ui/canvas-ui-react'
import { useAgGridStandardSignAdminContext } from '../../context/agGridStandardSignAdminContext'
import { useStandardSignAdminContext } from '../../context/standardSignAdminContext'
import EnterpriseIcon, {
  CaretDownIcon,
  PlusIcon,
  TrashIcon,
} from '@enterprise-ui/icons'

const ActionsDropdown = () => {
  const { addNewSignTemplate, selectedRows } =
    useAgGridStandardSignAdminContext()!
  const { deleteSignTemplates } = useStandardSignAdminContext()!
  return (
    <Grid.Item className="hc-ph-dense hc-pb-dense" xs={true}>
      <Dropdown size="expanded" location="bottom-left">
        <Button type="ghost">
          Actions
          <EnterpriseIcon className="hc-ml-dense" icon={CaretDownIcon} />
        </Button>
        <Dropdown.Menu>
          <Dropdown.MenuItem
            className="sem_DropdownMenuItem--action"
            onClick={() => addNewSignTemplate(null)}
          >
            <EnterpriseIcon
              size="inline"
              className="hc-mr-normal"
              icon={PlusIcon}
            />
            Add New Sign Template
          </Dropdown.MenuItem>
          <Dropdown.MenuItem
            className="sem_DropdownMenuItem--action"
            onClick={() => deleteSignTemplates(selectedRows)}
            disabled={selectedRows.length === 0}
          >
            <EnterpriseIcon
              size="inline"
              className="hc-mr-normal"
              icon={TrashIcon}
            />
            Delete Selected Sign Templates
          </Dropdown.MenuItem>
        </Dropdown.Menu>
      </Dropdown>
    </Grid.Item>
  )
}

export default ActionsDropdown
