import Axios from 'axios'
import React, { createContext, useState, useContext, useCallback } from 'react'
import { connect } from 'react-redux'
import {
  PROJECT_SERVICE_API_DOMAIN_URL,
  TOASTER_DEFAULTS,
} from 'components/App/constants/appConstants'
import { useEnv } from '@praxis/component-runtime-env'
import { ProjectDetail } from '../../../../../models/projects/ProjectDetail.model'
import { ProjectNote } from '../../../../../models/projects/ProjectNote.model'
import { useProjectDetailsContext } from '../../../context/projectDetailsContext'
import { useAppContext } from 'components/App/context/appContext'
import { useToaster } from '@enterprise-ui/canvas-ui-react'

type ContextType = {
  isProjectNotesOpen: boolean
  setIsProjectNotesOpen: Function
  isProjectNotesFormOpen: boolean
  setIsProjectNotesFormOpen: (isOpen: boolean) => void
  addProjectNote: (id: string, note: ProjectNote) => void
  updateProjectNote: (id: string, note: ProjectNote) => void
  deleteProjectNote: (id: string, noteId: string) => void
  getProjectNotes: (id: string) => void
}

export const ProjectNotesContext = createContext<ContextType | undefined>(
  undefined,
)

type Props = {
  children: React.ReactNode
}

export const ProjectNotesProviderComponent = ({ children }: Props) => {
  const { setFullPageLoadingMessage } = useAppContext()!
  const { setCurrentProject } = useProjectDetailsContext()!
  const env = useEnv<any>()
  const makeToast = useToaster()

  const [isProjectNotesOpen, setIsProjectNotesOpen] = useState(false)
  const [isProjectNotesFormOpen, setIsProjectNotesFormOpen] = useState(false)

  const getProjectNotes = useCallback(
    async (id: string) => {
      try {
        const res = await Axios.get(
          `${
            env.apiDomainUrl + PROJECT_SERVICE_API_DOMAIN_URL
          }/projects/${id}/notes`,
        )
        const sortedNotes = res.data.sort((a: any, b: any) =>
          a.timestamp < b.timestamp ? 1 : -1,
        )

        setCurrentProject(
          (previousState: ProjectDetail) =>
            new ProjectDetail({
              ...previousState,
              notes: sortedNotes,
            }),
        )
      } catch (err: any) {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'error',
          heading: 'Failed to Get Project Notes',
          message: err.response.data.message,
        })
      }
    },
    [setCurrentProject, makeToast, env],
  )

  const addProjectNote = async (id: string, note: ProjectNote) => {
    try {
      setFullPageLoadingMessage('Saving note...')
      await Axios.put(
        `${
          env.apiDomainUrl + PROJECT_SERVICE_API_DOMAIN_URL
        }/projects/${id}/notes`,
        note,
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      await getProjectNotes(id)
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'success',
        heading: 'Note Added',
        message: 'Successfully added a project note',
      })
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Add Note',
        message: err.response.data.message,
      })
    }
    setFullPageLoadingMessage('')
  }

  const updateProjectNote = async (id: string, note: ProjectNote) => {
    try {
      setFullPageLoadingMessage('Saving note...')
      await Axios.put(
        `${
          env.apiDomainUrl + PROJECT_SERVICE_API_DOMAIN_URL
        }/projects/${id}/notes`,
        note,
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      await getProjectNotes(id)
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'success',
        heading: 'Note Updated',
        message: 'Successfully updated the project note',
      })
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Add Note',
        message: err.response.data.message,
      })
    }
    setFullPageLoadingMessage('')
  }

  const deleteProjectNote = async (id: string, noteId: string) => {
    try {
      setFullPageLoadingMessage('Saving note...')
      await Axios.delete(
        `${
          env.apiDomainUrl + PROJECT_SERVICE_API_DOMAIN_URL
        }/projects/${id}/notes/${noteId}`,
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      await getProjectNotes(id)
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'success',
        heading: 'Note Deleted',
        message: 'Successfully deleted the project note',
      })
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Delete Note',
        message: err.response.data.message,
      })
    }
    setFullPageLoadingMessage('')
  }

  return (
    <ProjectNotesContext.Provider
      value={{
        isProjectNotesOpen,
        setIsProjectNotesOpen,
        isProjectNotesFormOpen,
        setIsProjectNotesFormOpen,
        addProjectNote,
        deleteProjectNote,
        updateProjectNote,
        getProjectNotes,
      }}
    >
      {children}
    </ProjectNotesContext.Provider>
  )
}

export const useProjectNotesContext = () => useContext(ProjectNotesContext)

export const ProjectNotesProvider = connect(
  null,
  null,
)(ProjectNotesProviderComponent)
