import React, { createContext, useContext, useState } from 'react'
import { useDashboardContext } from 'components/DashboardV2/context/dashboardContext'
import { useEnv } from '@praxis/component-runtime-env'
import { IServerSideGetRowsParams } from 'ag-grid-community'
import { DashboardRequest } from '../../../models/dashboardV2/DashboardRequest.model'
import { isEmpty, set } from 'lodash'
import Axios from 'axios'
import {
  ALERT,
  DASHBOARD_SERVICE_API_DOMAIN_URL,
  HEADER_OBJECT,
  TOASTER_DEFAULTS,
} from 'components/App/constants/appConstants'
import { ProjectMilestonesSummary } from '../../../models/dashboardV2/ProjectMilestoneSummary.model'
import { connect } from 'react-redux'
import { getHealthFromLabel } from 'components/DashboardV2/helpers/dashboardHelper'
import { useToaster } from '@enterprise-ui/canvas-ui-react'

type ContextType = {
  projectMilestoneDashboardSSRMServer: () => void
  currentProjectMilestoneRequest: DashboardRequest | undefined
}

export const ProjectMilestoneDashboardSSRMServerContext = createContext<
  ContextType | undefined
>(undefined)

type Props = {
  children: React.ReactNode
}

export const ProjectMilestoneDashboardSSRMServerComponent = ({
  children,
}: Props) => {
  const { managers, includeCompleteProjectMilestones } = useDashboardContext()!
  const env = useEnv()
  const makeToast = useToaster()
  const [currentProjectMilestoneRequest, setCurrentProjectMilestoneRequest] =
    useState<DashboardRequest>()

  const projectMilestoneDashboardSSRMServer = () => {
    const buildSearchRequest = (params: IServerSideGetRowsParams) => {
      const { request } = params
      const sortFields =
        request.sortModel.length > 0
          ? Object.assign(
              {},
              ...request.sortModel.map((sortItem: any) => ({
                [sortItem.colId === 'health_info.label'
                  ? 'health'
                  : sortItem.colId]: sortItem.sort,
              })),
            )
          : undefined
      const filters = {}
      for (const [key, value] of Object.entries(request.filterModel)) {
        const { filter, values, dateFrom, dateTo } = value as any
        if (
          key === 'sap_project_id' ||
          key === 'project_name' ||
          key === 'blueprint_name' ||
          key === 'my_milestone.name' ||
          key === 'dependency_milestone.name' ||
          key === 'campaign'
        ) {
          set(filters, [key], [filter])
        } else if (key === 'my_milestone_health_info.label') {
          set(
            filters,
            ['my_milestone_health'],
            values.map((label: string) => getHealthFromLabel(label)),
          )
        } else if (
          key === 'set_date' ||
          key === 'my_due_date' ||
          key === 'in_store_date'
        ) {
          set(
            filters,
            [key],
            [dateFrom.substring(0, 10), dateTo.substring(0, 10)],
          )
        } else {
          set(filters, [key], values)
        }
      }
      return new DashboardRequest({
        filters: isEmpty(filters) ? undefined : filters,
        sort_fields: sortFields,
        user_names: managers.map((manager: any) => manager.email),
        include_complete: includeCompleteProjectMilestones,
      })
    }

    return {
      getData: async (params: IServerSideGetRowsParams) => {
        const dashboardRequest = buildSearchRequest(params)
        setCurrentProjectMilestoneRequest(dashboardRequest)
        try {
          const pageNumber =
            params.request.endRow !== undefined
              ? Math.ceil(params.request.endRow / 50)
              : null
          const res = await Axios.post(
            `${
              env.apiDomainUrl + DASHBOARD_SERVICE_API_DOMAIN_URL
            }/project_milestones/search?page=${pageNumber}`,
            dashboardRequest,
            HEADER_OBJECT,
          )
          return {
            success: true,
            data: res.data,
            rows: res.data.search.map(
              (searchResult: any) =>
                new ProjectMilestonesSummary(searchResult.result),
            ),
            headers: res.headers,
          }
        } catch (err: any) {
          makeToast({
            ...TOASTER_DEFAULTS,
            type: ALERT.ERROR,
            heading: 'Failed to get project milestone summary',
            message: err.response.data.message,
          })
        }
      },
    }
  }

  return (
    <ProjectMilestoneDashboardSSRMServerContext.Provider
      value={{
        projectMilestoneDashboardSSRMServer,
        currentProjectMilestoneRequest,
      }}
    >
      {children}
    </ProjectMilestoneDashboardSSRMServerContext.Provider>
  )
}

export const ProjectMilestoneDashboardSSRMServerProvider = connect(
  null,
  {},
)(ProjectMilestoneDashboardSSRMServerComponent)

export const useProjectMilestoneDashboardSSRMServerContext = () =>
  useContext(ProjectMilestoneDashboardSSRMServerContext)
