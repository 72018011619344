import React from 'react'
import EnterpriseIcon, { ChevronUpIcon } from '@enterprise-ui/icons'
import { Button } from '@enterprise-ui/canvas-ui-react'

export interface Props {
  element: Element
}

const ScrollToTop = ({ element }: Props) => {
  const returnToTop = () => {
    element.scrollTop = 0
  }

  return (
    <Button
      aria-label="Scroll To Top"
      className="sem_ScrollToTop"
      iconOnly
      type="primary"
      onClick={returnToTop}
    >
      <EnterpriseIcon icon={ChevronUpIcon} />
    </Button>
  )
}

export default ScrollToTop
