import React, { useState } from 'react'
import {
  Chip,
  Divider,
  ExpandableSection,
  Grid,
  Heading,
  Spinner,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { FilterIcon } from '@enterprise-ui/icons'
import { useAgGridSignImportContext } from '../../context/agGridSignImportContext'
import FilterChips from './ImportFilterChips'
import FilterForm from './ImportFilterForm'

const SignImportFilterCard = () => {
  const { totalResults } = useAgGridSignImportContext()!
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <Grid.Item className="hc-ph-dense" xs={12}>
      <ExpandableSection
        className="filterCard"
        padding="dense"
        expanded={isExpanded}
        onExpand={() => setIsExpanded(!isExpanded)}
      >
        <Grid.Container spacing="dense" justify="space-between" align="center">
          <Grid.Item>
            <Heading className="hc-clr-interaction" size={6}>
              <EnterpriseIcon
                size="inline"
                className="hc-clr-interaction hc-mr-dense"
                icon={FilterIcon}
              />
              Sign Filters
            </Heading>
          </Grid.Item>
          <FilterChips />
          <Grid.Item className="hc-pa-dense">
            <Chip className="hc-mr-none" size="dense">
              <span className="sem_StrongText hc-txt-uppercase hc-mr-dense">
                Results:
              </span>
              {totalResults === undefined ? (
                <Spinner size="inline" />
              ) : (
                <span>{totalResults.toLocaleString('en-US')}</span>
              )}
            </Chip>
          </Grid.Item>
        </Grid.Container>
        <ExpandableSection.Content>
          <Divider />
          <FilterForm />
        </ExpandableSection.Content>
      </ExpandableSection>
    </Grid.Item>
  )
}

export default SignImportFilterCard
