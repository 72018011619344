export default class PricingRequest {
  sign_id?: string
  estimated_quantity: number
  estimated_unit_price: number
  final_unit_price: number
  po_number: string
  updated_by: string

  constructor(props: any = {}) {
    this.sign_id = props.sign_id
    this.estimated_quantity = props.estimated_quantity || null
    this.estimated_unit_price = props.estimated_unit_price || null
    this.final_unit_price = props.final_unit_price || null
    this.po_number = props.po_number || null
    this.updated_by = props.updated_by || ''
  }
}
