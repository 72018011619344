import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { Form, Grid } from '@enterprise-ui/canvas-ui-react'
import { Column } from 'ag-grid-community'
import SignResponse from '../../../../../../../models/signs/SignResponse.model'
import { SIGN_MESSAGE_FACETS } from 'components/SignDetails/constants/signMessagingFacets'

export interface Props {
  data: SignResponse
  value: string
  column: Column
}

const ProductInfoEditor = forwardRef(({ data, value, column }: Props, ref) => {
  const [editValue, setEditValue] = useState(value)
  const refInput = useRef<HTMLInputElement>(null)

  const handleChange = (id: string, value: string) => setEditValue(value)

  useEffect(() => {
    setTimeout(() => refInput?.current?.focus())
  }, [])

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return editValue
      },
    }
  })

  return (
    <div className="hc-pa-dense">
      <Grid.Container className="sem_StandardForm error_messaging_enabled">
        <Grid.Item xs={12}>
          {column.getColId() === 'messaging_type' ? (
            <Form.Field
              id="sign_size"
              ref={refInput}
              label={column.getColDef().headerName}
              value={editValue}
              type="select"
              options={SIGN_MESSAGE_FACETS(data.messaging?.sign_size)}
              onUpdate={(id: string, value: string) => setEditValue(value)}
              size="dense"
            />
          ) : column.getColId().includes('price') ? (
            <Form.Field
              id="price"
              type="number"
              label={column.getColDef().headerName}
              ref={refInput}
              value={editValue}
              onChange={(e: any) => setEditValue(e.target.value)}
              size="dense"
            />
          ) : (
            <Form.Field
              id="product_info"
              ref={refInput}
              label={column.getColDef().headerName}
              value={editValue}
              onChange={(e: any) => handleChange(e.target.id, e.target.value)}
              size="dense"
            />
          )}
        </Grid.Item>
      </Grid.Container>
    </div>
  )
})

export default ProductInfoEditor
