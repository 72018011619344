import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { Form, Grid } from '@enterprise-ui/canvas-ui-react'
import { useProjectSignListContext } from '../../../context/projectSignListContext'
import { Department } from '../../../../../../../models/merchandise/hierarchy/Department.model'

export interface Props {
  value: Department
}

const DepartmentCellEditor = forwardRef(({ value }: Props, ref) => {
  const { signFacets } = useProjectSignListContext()!
  const [editValue, setEditValue] = useState(value)
  const refInput = useRef<HTMLInputElement>(null)

  const handleChange = (id: string, value: Department) => {
    setEditValue(value)
  }

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return editValue
      },
    }
  })

  return (
    <div className="hc-pa-dense">
      <Grid.Container className="sem_StandardForm error_messaging_enabled">
        <Grid.Item xs={12}>
          <Form.Field
            id="dept_cell_editor"
            ref={refInput}
            type="select"
            label="Department"
            value={editValue.department_id}
            options={signFacets.departments.map((dept: Department) => ({
              value: dept.department_id,
              label: dept.department_display_name,
            }))}
            onUpdate={(id: string, value: number) => {
              const selected = signFacets.departments.filter(
                (department: Department) => department.department_id === value,
              )
              handleChange(id, selected[0])
            }}
            size="dense"
          />
        </Grid.Item>
      </Grid.Container>
    </div>
  )
})

export default DepartmentCellEditor
