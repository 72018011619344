export const BASELINE = 'default'
export const OTHERS = 'Round up Sign Quantities / Select number of Kits'

export const KIT_SCENARIO_OPTIONS = [
  {
    value: BASELINE,
    label: BASELINE,
    disabled: false,
  },
  {
    value: OTHERS,
    label: OTHERS,
    disabled: false,
  },
]
