import {
  Department,
  ItemDimensions,
  NominalDimensions,
  SignPrintingInfo,
} from '.'
import { NRI_UNIT_OF_MEASURE } from '../../components/App/constants/appConstants'
import { SignTemplateInfo } from '../signs/SignResponse.model'

export default class SignElementFields {
  sign_template_info?: SignTemplateInfo
  department?: Department
  end_date?: string | null
  item_dimensions?: ItemDimensions
  nominal_dimensions?: NominalDimensions
  printing_info?: SignPrintingInfo
  product_vendor?: string
  reinstated?: boolean
  separator?: string
  set_quantity?: number
  sign_description?: string
  sign_type?: string
  sign_size?: string
  sign_definition?: string
  unit_of_measure?: NRI_UNIT_OF_MEASURE

  constructor(props: any = {}) {
    this.sign_template_info = props.sign_template_info
      ? new SignTemplateInfo(props.sign_template_info)
      : undefined
    this.department = props.department
      ? new Department(props.department)
      : undefined
    this.end_date = props.end_date === '' ? null : props.end_date
    this.item_dimensions = props.item_dimensions
      ? new ItemDimensions(props.item_dimensions)
      : undefined
    this.nominal_dimensions = props.nominal_dimensions
      ? new NominalDimensions(props.nominal_dimensions)
      : undefined
    this.printing_info = props.printing_info
      ? new SignPrintingInfo(props.printing_info)
      : undefined
    this.product_vendor =
      props.product_vendor === '' ? undefined : props.product_vendor
    this.reinstated = props.reinstated
    this.separator = props.separator === '' ? undefined : props.separator
    this.set_quantity = props.set_quantity
    this.sign_description = props.sign_description
    this.sign_type = props.sign_type
    this.sign_size = props.sign_size
    this.sign_definition = props.sign_definition
    this.unit_of_measure = props.unit_of_measure
  }
}
