import React, { useState, useEffect } from 'react'
import { useAuth } from '@praxis/component-auth'
import {
  Layout,
  Grid,
  Heading,
  TargetLogo,
  Tooltip,
  Button,
  Dropdown,
  Badge,
} from '@enterprise-ui/canvas-ui-react'
import { NavLink } from 'react-router-dom'
import EnterpriseIcon, {
  BellIcon,
  CaretDownIcon,
  LogoutIcon,
} from '@enterprise-ui/icons'
import {
  DASHBOARD_MANAGERS_LOCAL_STORAGE_KEY,
  UserType,
} from '../constants/appConstants'
import { useUserContext } from '../context/userContext'
import { useAppContext } from '../context/appContext'
import { useEnv } from '@praxis/component-runtime-env'

const AppBar = () => {
  const { userCompany, userName, userType, userPermissions } = useUserContext()!
  const { notificationCount, getNotificationCount } = useAppContext()!
  const { logout } = useAuth()
  const [showAdminMenu, setShowAdminMenu] = useState(false)
  const [showLibraryMenu, setShowLibraryMenu] = useState(false)
  const env = useEnv()
  useEffect(() => {
    if (env.notificationFeature) getNotificationCount()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout.Header as="header" className="sem_AppBar hc-ph-expanded">
      <Grid.Container align="center" spacing="dense" className="hc-pa-none">
        <Grid.Item>
          <NavLink end to={'/'}>
            <Heading className="sem-appHeading" size={2}>
              <TargetLogo size="inline" color="white" className="hc-mr-dense" />
              RUBIX
            </Heading>
          </NavLink>
        </Grid.Item>
        <Grid.Item className="hc-mr-expanded">
          <p className="subheading">In-Store Marketing System</p>
        </Grid.Item>
        <Grid.Item className="sem_MenuItem hc-pa-none">
          <Button
            fullWidth
            type="ghost"
            className="hc-clr-grey07 hc-mv-none sem_Button"
            as={NavLink}
            to={'/'}
          >
            MY DASHBOARD
          </Button>
        </Grid.Item>
        {userType === UserType.INTERNAL && (
          <Grid.Item className="sem_MenuItem hc-pa-none">
            <Dropdown location="bottom-left" size="dense">
              <Button
                fullWidth
                type="ghost"
                className="hc-clr-grey07 sem_Button"
                onMouseEnter={() => setShowLibraryMenu(true)}
                onMouseLeave={() => setShowLibraryMenu(false)}
              >
                {' '}
                <span>LIBRARY</span>
                <EnterpriseIcon className="hc-ml-dense" icon={CaretDownIcon} />
              </Button>
              <Dropdown.Menu
                className={showLibraryMenu ? 'isVisible' : ''}
                onMouseEnter={() => setShowLibraryMenu(true)}
                onMouseLeave={() => setShowLibraryMenu(false)}
              >
                <Dropdown.MenuItem as={NavLink} to={'/blueprints'}>
                  Blueprints
                </Dropdown.MenuItem>

                <Dropdown.MenuItem as={NavLink} to={'/projects'}>
                  Projects
                </Dropdown.MenuItem>
                <Dropdown.MenuItem as={NavLink} to={'/signs'}>
                  Signs
                </Dropdown.MenuItem>
              </Dropdown.Menu>
            </Dropdown>
          </Grid.Item>
        )}
        {userPermissions.isSuperAdmin && (
          <Grid.Item className="sem_MenuItem hc-pa-none">
            <Dropdown location="bottom-left" size="dense">
              <Button
                fullWidth
                type="ghost"
                className="hc-clr-grey07 sem_Button"
                onMouseEnter={() => setShowAdminMenu(true)}
                onMouseLeave={() => setShowAdminMenu(false)}
              >
                <span>ADMIN</span>
                <EnterpriseIcon className="hc-ml-dense" icon={CaretDownIcon} />
              </Button>
              <Dropdown.Menu
                className={showAdminMenu ? 'isVisible' : ''}
                onMouseEnter={() => setShowAdminMenu(true)}
                onMouseLeave={() => setShowAdminMenu(false)}
              >
                <Dropdown.MenuItem as={NavLink} to={'/admin-campaign-upload'}>
                  Campaign Upload
                </Dropdown.MenuItem>
                <Dropdown.MenuItem as={NavLink} to={'/admin-campaign-types'}>
                  Campaign Types
                </Dropdown.MenuItem>
                <Dropdown.MenuItem as={NavLink} to={'/admin-milestones'}>
                  Milestones
                </Dropdown.MenuItem>
                <Dropdown.MenuItem as={NavLink} to={'/admin-roles'}>
                  Roles
                </Dropdown.MenuItem>
                {env.signStandardizationFeature && (
                  <Dropdown.MenuItem
                    as={NavLink}
                    to={'/standard-sign-templates'}
                  >
                    Standard Sign Templates
                  </Dropdown.MenuItem>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </Grid.Item>
        )}
        <Grid.Item xs={true} />
        {env.notificationFeature && (
          <Grid.Item className="hc-mr-normal sem_IconMenu">
            <Button
              className="hc-clr-grey07"
              type="ghost"
              as={NavLink}
              to="/notifications"
            >
              <Badge
                aria-label="enterprise_icon"
                className={notificationCount === 0 ? 'sem-badge-hidden' : ''}
                color="error"
                content={notificationCount.toString()}
                location="top-right"
              >
                <EnterpriseIcon
                  icon={BellIcon}
                  className="hc-mh-dense sem-notification-bell"
                  size="md"
                />
              </Badge>
            </Button>
          </Grid.Item>
        )}
        <Grid.Item className="sem-LeftNavItem-UserName hc-pb-dense">
          <Heading className="hc-clr-grey07" size={5}>
            {userName}
          </Heading>
          <p className="sem_CompanyName hc-clr-grey03 hc-pt-none">
            {userCompany}
          </p>
        </Grid.Item>
        <Grid.Item className="sem_IconMenu hc-mr-normal">
          <Tooltip location="bottom" content="Logout">
            <Button
              aria-label="Logout"
              iconOnly
              type="ghost"
              className="hc-clr-grey07"
              onClick={() => {
                localStorage.removeItem(DASHBOARD_MANAGERS_LOCAL_STORAGE_KEY)
                logout()
              }}
            >
              <EnterpriseIcon
                icon={LogoutIcon}
                size="sm"
                className="logout-icon"
              />
            </Button>
          </Tooltip>
        </Grid.Item>
      </Grid.Container>
    </Layout.Header>
  )
}

export default AppBar
