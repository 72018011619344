import { Column } from '../../../models/agGrid/AgGridColumn.model'
import { AgGridSignListView } from '../../../models/agGrid/AgGridSignListView.model'
import { SIGN_LIBRARY_VIEW } from '../../App/constants/appConstants'

export const SIGN_IMPORT_VIEW = new AgGridSignListView({
  viewKey: 'import',
  label: SIGN_LIBRARY_VIEW.IMPORT,
  columns: [
    new Column({
      type: ['selectedRow', 'fixed'],
    }),
    new Column({
      type: ['signMenu', 'fixed'],
    }),
    new Column({
      field: 'non_retail_item_info.dpci',
      headerName: 'DPCI#',
      width: 192,
      type: ['fixed', 'dpci'],
    }),
    new Column({
      field: 'non_retail_item_info.sign_description',
      headerName: 'Sign Name',
      width: 320,
      type: ['fixed', 'textFilter'],
    }),
    new Column({
      field: 'non_retail_item_info.sign_type',
      headerName: 'Sign Type',
      width: 210,
      type: ['fixed', 'signType'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.sign_item_status',
      headerName: 'Sign Item Status',
      width: 225,
      type: ['signItemStatus'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.sign_template_info.template_name',
      headerName: 'Sign Template',
      width: 210,
      type: ['signTemplate'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'selected_project_sign_info.sap_project_id',
      headerName: 'Selected SAP Project ID#',
      width: 206,
      type: ['nonFilterable'],
    }),
    new Column({
      field: 'selected_project_sign_info.sign_status',
      headerName: 'Selected Project Sign Status',
      width: 236,
      cellRenderer: 'StatusChip',
      type: ['selectedSignStatus', 'nonFilterable'],
    }),
    new Column({
      field: 'selected_project_sign_info.set_date',
      headerName: 'Selected Project Set Date',
      width: 215,
      type: ['date', 'nonFilterable'],
    }),
    new Column({
      field: 'non_retail_item_info.set_date',
      headerName: 'Original Set Date',
      width: 210,
      type: ['date'],
      filter: 'agDateColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.end_date',
      headerName: 'End Date',
      width: 174,
      type: ['date'],
      filter: 'agDateColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.department',
      headerName: 'Department',
      width: 220,
      type: ['department'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.width',
      headerName: 'Flat Width',
      width: 214,
      type: ['numberFilter'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.height',
      headerName: 'Flat Height',
      width: 220,
      type: ['numberFilter'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.depth',
      headerName: 'Flat Depth',
      width: 220,
      type: ['numberFilter'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.item_dimensions.width',
      headerName: 'Finished Width',
      width: 214,
      type: ['numberFilter'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.item_dimensions.height',
      headerName: 'Finished Height',
      width: 220,
      type: ['numberFilter'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.upc',
      headerName: 'Sign UPC',
      width: 175,
      type: ['textFilter'],
    }),
    new Column({
      field: 'non_retail_item_info.product_vendor',
      headerName: 'Product Vendor',
      width: 228,
      type: ['productVendor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.separator',
      headerName: 'Separator',
      width: 244,
      type: ['separator'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      headerName: 'SAP Project ID#',
      field: 'project_sign_info_list.sap_project_id',
      type: ['textFilter', 'hiddenColumn'],
    }),
    new Column({
      headerName: 'SAP Project Name',
      field: 'project_sign_info_list.project_name',
      type: ['textFilter', 'hiddenColumn'],
    }),
    new Column({
      headerName: 'Blueprint Name',
      field: 'project_sign_info_list.blueprint_name',
      type: ['textFilter', 'hiddenColumn'],
    }),
    new Column({
      headerName: 'Sign Status',
      field: 'project_sign_info_list.sign_status',
      type: ['signStatus', 'hiddenColumn'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      headerName: 'Pyramid',
      field: 'non_retail_item_info.department.group_display_name',
      type: ['pyramid'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      headerName: 'Division',
      field: 'non_retail_item_info.department.division_display_name',
      type: ['division'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      headerName: 'Project Type',
      field: 'project_sign_info_list.project_type',
      type: ['projectType'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      headerName: 'Campaign Type',
      field: 'project_sign_info_list.campaign.name',
      type: ['campaignType'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      headerName: 'Print Vendor',
      field: 'non_retail_item_info.printing_info.print_vendor',
      type: ['printVendor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      headerName: 'Kitting Vendor',
      field: 'project_sign_info_list.kitting_vendor',
      type: ['kittingVendor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.created_date',
      headerName: 'Created Date',
      width: 182,
      type: ['date', 'hiddenColumn'],
      filter: 'agDateColumnFilter',
    }),
    new Column({
      field: 'exclude_dpcis',
      type: ['hiddenColumn'],
    }),
  ],
})

export const DETAIL_PROJECT_LIST = [
  new Column({
    type: ['selectedRow', 'fixed'],
  }),
  new Column({
    headerName: 'SAP Project ID#',
    field: 'sap_project_id',
    width: 230,
    type: ['fixed'],
    filter: 'agSetColumnFilter',
  }),
  {
    headerName: 'SAP Project Name',
    field: 'project_name',
    width: 314,
    cellRenderer: 'ProjectName',
    type: ['textFilter', 'fixed'],
  },
  {
    headerName: 'Sign Status',
    field: 'sign_status',
    width: 205,
    type: ['signStatus', 'fixed'],
    filter: 'agSetColumnFilter',
  },
  {
    headerName: 'Blueprint Name',
    field: 'blueprint_name',
    width: 314,
    type: ['textFilter'],
  },
  {
    headerName: 'Project Set Date',
    field: 'set_date',
    width: 231,
    type: ['detailDate'],
    filter: 'agDateColumnFilter',
  },
  {
    headerName: 'Campaign ID',
    field: 'campaign_id',
    width: 200,
    filter: 'agSetColumnFilter',
  },
  {
    headerName: 'Campaign Type',
    field: 'campaign.name',
    width: 278,
    filter: 'agSetColumnFilter',
  },
  {
    headerName: 'Finalized Level',
    field: 'workflow.status',
    width: 218,
    cellRenderer: 'FinalizedCell',
    filter: 'agSetColumnFilter',
  },
  {
    headerName: 'Project Type',
    field: 'project_type',
    width: 185,
    filter: 'agSetColumnFilter',
  },
  {
    headerName: 'On POG Sign',
    field: 'on_pog_sign',
    width: 136,
    type: ['toggle'],
    filter: 'agSetColumnFilter',
  },
  {
    headerName: 'Print Vendor',
    field: 'print_vendor',
    width: 210,
    filter: 'agSetColumnFilter',
  },
  {
    headerName: 'Kitting Vendor',
    field: 'kitting_vendor',
    width: 210,
    filter: 'agSetColumnFilter',
  },
]
