import React from 'react'
import { Link } from 'react-router-dom'
import { Anchor } from '@enterprise-ui/canvas-ui-react'
import { ICellRendererParams } from 'ag-grid-community'

const ProjectCellName = ({ data, value }: ICellRendererParams) => {
  return (
    <Anchor
      as={Link}
      to={`/projects/${data.project_id}?tab=SIGN+LIST`}
      target="_blank"
    >
      {value}
    </Anchor>
  )
}

export default ProjectCellName
