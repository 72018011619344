import { UserType } from '../../components/App/constants/appConstants'
import { UserPermissions } from './UserPermissions.model'

export class UserInformation {
  permissions: UserPermissions
  name: string
  id: string
  email: string
  company: string
  type: UserType

  constructor(props: any = {}) {
    this.permissions = new UserPermissions(props.permissions)
    this.name = props.name || ''
    this.id = props.id || ''
    this.email = props.email || ''
    this.company = props.company || ''
    this.type = props.type || UserType.INTERNAL
  }
}
