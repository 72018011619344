import React, { Fragment } from 'react'
import { Card, Grid } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { PlusIcon } from '@enterprise-ui/icons'
import { MilestoneTemplate } from '../../../../../models/milestones/MilestoneTemplate.model'

export interface Props {
  milestonesInProjectPhase: MilestoneTemplate[]
  setAddMilestone: Function
  setCreateMilestone: Function
}

export const AddProjectMilestoneMenu = ({
  milestonesInProjectPhase,
  setAddMilestone,
  setCreateMilestone,
}: Props) => {
  return (
    <Fragment>
      <Card
        elevation={0}
        className={
          'addMilestoneMenu-Card' +
          (milestonesInProjectPhase.length === 0 ? '--Disabled' : '')
        }
        onClick={() => {
          if (milestonesInProjectPhase.length > 0) {
            setAddMilestone(true)
            setCreateMilestone(false)
          }
        }}
      >
        <Grid.Container spacing="none">
          <Grid.Item xs={1}>
            <EnterpriseIcon
              className="addMilestoneOptions-Icon hc-clr-grey02"
              icon={PlusIcon}
            />
          </Grid.Item>
          <Grid.Item xs>
            <p className="hc-fs-md hc-clr-grey02">
              Add Milestone from Existing Templates
            </p>
          </Grid.Item>
        </Grid.Container>
      </Card>
      <Card
        elevation={0}
        className="addMilestoneMenu-Card"
        onClick={() => {
          setAddMilestone(false)
          setCreateMilestone(true)
        }}
      >
        <Grid.Container spacing="none">
          <Grid.Item xs={1}>
            <EnterpriseIcon
              className="addMilestoneOptions-Icon hc-clr-grey02"
              icon={PlusIcon}
            />
          </Grid.Item>
          <Grid.Item xs>
            <p className="hc-fs-md hc-clr-grey02">Add New Custom Milestone</p>
          </Grid.Item>
        </Grid.Container>
      </Card>
    </Fragment>
  )
}

export default AddProjectMilestoneMenu
