import Axios from 'axios'
import React, { createContext, useState, useContext, useCallback } from 'react'
import { connect } from 'react-redux'
import { DialogProps } from '../../../models/app/DialogProps.model'
import {
  PROJECT_SERVICE_API_DOMAIN_URL,
  SEARCH_RESPONSE_SIZE,
  TOASTER_DEFAULTS,
} from '../../App/constants/appConstants'
import { useEnv } from '@praxis/component-runtime-env'
import { SearchRequest } from '../../../models/app/SearchRequest.model'
import {
  SearchResponse,
  SearchResult,
} from '../../../models/app/SearchResponse.model'
import { ProjectDetail } from '../../../models/projects/ProjectDetail.model'
import { ProjectRequest } from '../../../models/projects/ProjectRequest.model'
import { ProjectSearchFacets } from '../../../models/projects/ProjectSearchFacets.model'
import { useAppContext } from '../../App/context/appContext'
import { useToaster } from '@enterprise-ui/canvas-ui-react'
type ContextType = {
  isLoadingMore: boolean
  setIsLoadingMore: (isLoadingMore: boolean) => void
  projectSearchResponse: SearchResponse<ProjectSearchFacets, ProjectDetail>
  setProjectSearchResponse: Function
  submitProjectSearch: (filters?: SearchRequest<ProjectSearchFacets>) => void
  handleNewResults: (results: any) => void
  loadMoreProjects: (
    filters: SearchRequest<ProjectSearchFacets>,
  ) => Promise<SearchResult<ProjectDetail>[]>
  cancelProject: (id: string) => void
}
export const ProjectListContext = createContext<ContextType | undefined>(
  undefined,
)
type Props = {
  children: React.ReactNode
}
const ProjectListProviderComponent = ({ children }: Props) => {
  const { setFullPageLoadingMessage, setDialogProps } = useAppContext()!
  const env = useEnv<any>()
  const makeToast = useToaster()
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [projectSearchResponse, setProjectSearchResponse] = useState(
    new SearchResponse<ProjectSearchFacets, ProjectDetail>({
      facets: new ProjectSearchFacets(),
    }),
  )

  // pass filters as an argument, otherwise it would need to be in the dependency array and would cause extra API calls
  const submitProjectSearch = useCallback(
    async (filters?: SearchRequest<ProjectSearchFacets>) => {
      setFullPageLoadingMessage('Loading Projects...')
      try {
        const res: any = await Axios.post(
          `${
            env.apiDomainUrl + PROJECT_SERVICE_API_DOMAIN_URL
          }/projects/search?per_page=${SEARCH_RESPONSE_SIZE}`,
          new SearchRequest({
            facets: (filters && filters.facets) || new ProjectSearchFacets(),
            sort_fields: (filters && filters.sort_fields) || {
              set_date: 'desc',
            },
            ...filters,
          }),
          {
            headers: { 'Content-Type': 'application/json' },
          },
        )
        setProjectSearchResponse(new SearchResponse(res.data))
      } catch (err: any) {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'error',
          heading: 'Failed to Get Projects',
          message: err.response.data.message,
        })
      }
      setFullPageLoadingMessage('')
    },
    [setFullPageLoadingMessage, makeToast, env],
  )
  const handleNewResults = (
    newProjectSearchResults: SearchResult<ProjectDetail>[],
  ) => {
    setProjectSearchResponse(
      new SearchResponse({
        ...projectSearchResponse,
        page: projectSearchResponse.page + 1,
        search: [...projectSearchResponse.search, ...newProjectSearchResults],
      }),
    )
  }
  const loadMoreProjects = async (
    filters: SearchRequest<ProjectSearchFacets>,
  ) => {
    try {
      const res = await Axios.post(
        `${
          env.apiDomainUrl + PROJECT_SERVICE_API_DOMAIN_URL
        }/projects/search?per_page=${SEARCH_RESPONSE_SIZE}&page=${
          projectSearchResponse.page + 1
        }`,
        new SearchRequest<ProjectSearchFacets>(filters),
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      const response = new SearchResponse<ProjectSearchFacets, ProjectDetail>(
        res.data,
      )
      return response.search
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Load More Projects',
        message: err.response.data.message,
      })
      return []
    }
  }
  const cancelProject = async (projectId: string) => {
    setFullPageLoadingMessage('Archiving Project...')
    try {
      const res = await Axios.get(
        `${
          env.apiDomainUrl + PROJECT_SERVICE_API_DOMAIN_URL
        }/projects/${projectId}`,
      )
      await Axios.put(
        `${
          env.apiDomainUrl + PROJECT_SERVICE_API_DOMAIN_URL
        }/projects/cancel?project_id=${projectId}`,
        {
          ...new ProjectRequest(res.data),
          status: 'Cancel',
        },
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      setProjectSearchResponse(
        new SearchResponse({
          ...projectSearchResponse,
          search: projectSearchResponse.search.map(
            (searchResult: SearchResult<ProjectDetail>) =>
              searchResult.result.project_id === projectId
                ? new SearchResult<ProjectDetail>({
                    result: new ProjectDetail({
                      ...searchResult.result,
                      status: 'Cancel',
                    }),
                  })
                : searchResult,
          ),
        }),
      )
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'success',
        heading: 'Project Cancelled',
        message: 'Successfully cancelled the project',
      })
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Cancel Projects',
        message: err.response.data.message,
      })
    }
    setDialogProps(new DialogProps())
    setFullPageLoadingMessage('')
  }
  return (
    <ProjectListContext.Provider
      value={{
        isLoadingMore,
        setIsLoadingMore,
        projectSearchResponse,
        setProjectSearchResponse,
        submitProjectSearch,
        handleNewResults,
        loadMoreProjects,
        cancelProject,
      }}
    >
      {children}
    </ProjectListContext.Provider>
  )
}
export const ProjectListProvider = connect(
  null,
  null,
)(ProjectListProviderComponent)
export const useProjectListContext = () => useContext(ProjectListContext)
