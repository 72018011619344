import React, { useEffect, Fragment } from 'react'
import { Grid, Heading, Button, Divider } from '@enterprise-ui/canvas-ui-react'
import { RosterElement } from '../../../../../models/roster/RosterElement.model'
import EditableRoster from '../../../../Roster/components/EditableRoster'
import { RosterUser } from '../../../../../models/roster/RosterUser.model'
import { useBlueprintForm } from '../hooks/useBlueprintForm'
import { useNavigate } from 'react-router-dom'
import BlueprintFormCard from './BlueprintFormCard'
import { useBlueprintDetailsTabContext } from '../context/blueprintDetailsTabContext'
import { useUserContext } from 'components/App/context/userContext'
import { useBlueprintDetailsContainerContext } from '../../../context/blueprintDetailsContainerContext'
import { BlueprintRequest } from '../../../../../models/blueprints/BlueprintRequest.model'

export const BlueprintDetailsTab = () => {
  const { currentBlueprint, setCurrentBlueprint } =
    useBlueprintDetailsContainerContext()!
  const {
    deleteFromRoster,
    updateBlueprint,
    createBlueprint,
    getCampaignList,
    campaignList,
    saveBlueprintRoster,
  } = useBlueprintDetailsTabContext()!
  const { userPermissions, userName, userId } = useUserContext()!

  const { handleSubmit, setFieldValue, values, errors } = useBlueprintForm(
    currentBlueprint,
    (values: BlueprintRequest) => {
      currentBlueprint.blueprint_id
        ? updateBlueprint(currentBlueprint.blueprint_id, values)
        : createBlueprint(values)
    },
    userName,
  )

  const navigate = useNavigate()
  useEffect(() => {
    if (
      currentBlueprint.blueprint_id &&
      (window.location.pathname === '/blueprints/create' ||
        window.location.pathname.includes('duplicate'))
    ) {
      navigate(`/blueprints/${currentBlueprint.blueprint_id}`)
    }
  }, [currentBlueprint, navigate])

  // on first load and any time the selected date changes, get the campaign list for that date
  useEffect(() => {
    values.set_date && getCampaignList(values.set_date)
  }, [getCampaignList, values.set_date])

  const addToRoster = (user: RosterUser, userRole: string) => {
    setCurrentBlueprint({
      ...currentBlueprint,
      roster: currentBlueprint.roster.map((rosterElement: RosterElement) => {
        return new RosterElement({
          type: rosterElement.type,
          title: rosterElement.title,
          users:
            rosterElement.title === userRole
              ? [...rosterElement.users, user]
              : rosterElement.users,
        })
      }),
    })
  }

  return (
    <Fragment>
      <Grid.Container className="hc-pb-expanded">
        <Grid.Item xs={8}>
          <Heading className="hc-clr-grey02" size={5}>
            {`${values.name || 'New Blueprint'} Configuration`}
          </Heading>

          <BlueprintFormCard
            values={values}
            errors={errors}
            setFieldValue={setFieldValue}
          />

          {userPermissions.isBlueprintAdmin &&
            currentBlueprint.status !== 'Archive' &&
            window.location.pathname.includes('duplicate') && (
              <Grid.Container justify="center" className="hc-mb-normal">
                <Grid.Item xs={5}>
                  <Button
                    className="hc-mt-expanded"
                    type="primary"
                    fullWidth
                    onClick={() => navigate(`/blueprints`)}
                  >
                    Cancel
                  </Button>
                </Grid.Item>
                <Grid.Item xs={5}>
                  <Button
                    className="hc-mt-expanded"
                    type="primary"
                    fullWidth
                    onClick={() => handleSubmit()}
                  >
                    Duplicate Blueprint
                  </Button>
                </Grid.Item>
              </Grid.Container>
            )}
          {userPermissions.isBlueprintEditor &&
            currentBlueprint.status !== 'Archive' && (
              <Grid.Container justify="center" className="hc-mb-normal">
                <Grid.Item xs={5}>
                  <Button
                    className="hc-mt-expanded"
                    type="primary"
                    fullWidth
                    onClick={() => handleSubmit()}
                  >
                    {currentBlueprint.blueprint_id ? 'Update' : 'Create'}{' '}
                    Blueprint
                  </Button>
                </Grid.Item>
              </Grid.Container>
            )}
        </Grid.Item>

        <Divider className="hc-pt-normal" direction="vertical" />

        <Grid.Item xs={true} className="sem-BpRosterTab open">
          <EditableRoster
            currentBlueprint={currentBlueprint}
            roster={
              campaignList.find(
                (campaign) => campaign.name === currentBlueprint.campaign.name,
              )
                ? currentBlueprint.roster
                : undefined
            }
            deleteFromRoster={deleteFromRoster}
            saveRoster={() =>
              saveBlueprintRoster(
                currentBlueprint.blueprint_id,
                currentBlueprint.roster,
                userId,
              )
            }
            addToRoster={addToRoster}
            hasEditPermission={userPermissions.isBlueprintEditor}
            resetTrigger={currentBlueprint.campaign.name}
          />
        </Grid.Item>
      </Grid.Container>
    </Fragment>
  )
}

export default BlueprintDetailsTab
