import React from 'react'
import { Grid, Modal, Spinner } from '@enterprise-ui/canvas-ui-react'
import { useProjectSignListContext } from '../../context/projectSignListContext'
import AgGridSignListTable from '../signListViews/AgGridSignListTable'
import { useProjectDetailsContext } from 'components/ProjectDetails/context/projectDetailsContext'

const FullScreenTableEditor = () => {
  const { isLoadingSignList } = useProjectDetailsContext()!
  const { isTableEditorFullScreen, setIsTableEditorFullScreen } =
    useProjectSignListContext()!
  return (
    <Modal
      size="expanded"
      className="table-editor-modal"
      isVisible={isTableEditorFullScreen}
      onRefuse={() => setIsTableEditorFullScreen(false)}
    >
      <div className="hc-pa-dense">
        {isLoadingSignList ? (
          <Grid.Container
            className="sign-projects-loading-container"
            direction="column"
            justify="center"
            align="center"
          >
            <Grid.Item>Loading Signs...</Grid.Item>
            <Grid.Item>
              <Spinner />
            </Grid.Item>
          </Grid.Container>
        ) : (
          <AgGridSignListTable />
        )}
      </div>
    </Modal>
  )
}

export default FullScreenTableEditor
