import Axios from 'axios'
import { SIGN_SERVICE_API_DOMAIN_URL } from '../../App/constants/appConstants'
import appConfig from '../../../config/appConfig'

export const fetchKitGroups = async (
  projectId: string,
  kitVendorId: string,
) => {
  const config = await appConfig()
  const res: any = await Axios.get(
    `${
      config.apiDomainUrl + SIGN_SERVICE_API_DOMAIN_URL
    }/signs/kit_groups?kit_vendor_id=${kitVendorId}&project_id=${projectId}`,
  )
  return res.data.map((kit: string) => ({
    id: kit,
    value: kit,
    label: kit,
  }))
}
