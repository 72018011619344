export enum SIGN_PREVIEW_TYPES {
  VALUE = 'Value Sign',
  DISPLAY = 'Display Sign',
}

export const SIGN_SIZE_FACETS: any = {
  FIVE_BY_THREE: {
    label: '5x3',
    value: 'FIVE_BY_THREE',
    validSignTypes: [SIGN_PREVIEW_TYPES.DISPLAY, SIGN_PREVIEW_TYPES.VALUE],
    allowMpp: false,
  },
  SIX_BY_THREE: {
    label: '6.625x3',
    value: 'SIX_BY_THREE',
    validSignTypes: [SIGN_PREVIEW_TYPES.DISPLAY],
    allowMpp: false,
  },
  EIGHT_BY_TWO: {
    label: '8x2.5',
    value: 'EIGHT_BY_TWO',
    validSignTypes: [SIGN_PREVIEW_TYPES.DISPLAY],
    allowMpp: false,
  },
  SEVEN_BY_ELEVEN: {
    label: '11x7',
    value: 'SEVEN_BY_ELEVEN',
    validSignTypes: [SIGN_PREVIEW_TYPES.VALUE],
    allowMpp: true,
  },
  OTHER: {
    label: 'Other',
    value: 'OTHER',
    validSignTypes: [SIGN_PREVIEW_TYPES.DISPLAY, SIGN_PREVIEW_TYPES.VALUE],
    allowMpp: false,
  },
}

export const SIGN_MESSAGE_FACETS = (size: string) => [
  { value: 'Regular Price', label: 'Regular Price' },
  {
    value: 'Price Range',
    label: 'Price Range (11x7 signs only)',
    disabled: size !== 'SEVEN_BY_ELEVEN',
  },
]

export const TOPPER_BRAND_FACETS = [
  { value: null, label: 'Please select...' },
  { value: 'AllInMotion', label: 'All In Motion' },
  { value: 'ANewDay', label: 'A New Day' },
  { value: 'ArtClass', label: 'Art Class' },
  { value: 'Auden', label: 'Auden' },
  { value: 'AVAAndVIV', label: 'AVA & VIV' },
  { value: 'BootsAndBarkley', label: 'Boots And Barkley' },
  { value: 'Brightroom', label: 'Brightroom' },
  { value: 'Casaluna', label: 'Casaluna' },
  { value: 'CatAndJack', label: 'Cat & Jack' },
  { value: 'CloudIsland', label: 'Cloud Island' },
  { value: 'Colsie', label: 'Colsie' },
  { value: 'Dia', label: 'Dia' },
  { value: 'Figmint', label: 'Figmint' },
  { value: 'Goodfellow', label: 'Goodfellow' },
  { value: 'HearthAndHand', label: 'Hearth & Hand' },
  { value: 'Heyday', label: 'Heyday' },
  { value: 'HydeAndEek_Logo', label: 'Hyde And Eek' },
  { value: 'JoyLab', label: 'Joy Lab' },
  { value: 'Kindfull', label: 'Kindfull' },
  { value: 'KnoxRose', label: 'Knox Rose' },
  { value: 'KonaSol', label: 'Kona Sol' },
  { value: 'MadeByDesign', label: 'Made By Design' },
  { value: 'MondoLlama', label: 'Mondo Llama' },
  { value: 'MoreThanMagic', label: 'More than Magic' },
  { value: 'Opalhouse', label: 'Opalhouse' },
  { value: 'OpalhouseJungalow', label: 'Opalhouse Jungalow' },
  { value: 'OpenStory', label: 'OpenStory' },
  { value: 'OriginalUse', label: 'Original Use' },
  { value: 'OurGeneration', label: 'Our Generation' },
  { value: 'Pillowfort', label: 'Pillowfort' },
  { value: 'Project62', label: 'Project 62' },
  { value: 'RoomEssentials', label: 'Room Essentials' },
  { value: 'ShadeAndShore', label: 'Shade & Shore' },
  { value: 'Spritz', label: 'Spritz' },
  { value: 'StarsAbove', label: 'Stars Above' },
  { value: 'SunSquad', label: 'Sun Squad' },
  { value: 'Threshold', label: 'Threshold' },
  { value: 'ThresholdStudioMcgee', label: 'Threshold Studio Mcgee' },
  { value: 'UniversalThread', label: 'Universal Thread' },
  { value: 'WildFable', label: 'Wild Fable' },
  { value: 'Wondershop', label: 'Wondershop' },
  { value: 'WondershopWhite', label: 'Wondershop White' },
  { value: 'Xhilaration', label: 'Xhilaration' },
]
