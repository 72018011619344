import React, { Fragment } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import {
  Table,
  Button,
  Anchor,
  Dropdown,
  Popover,
} from '@enterprise-ui/canvas-ui-react'
import { DateFormatter } from '@enterprise-ui/canvas-ui-react-date'
import { ProjectDetail } from '../../../models/projects/ProjectDetail.model'
import { DialogProps } from '../../../models/app/DialogProps.model'
import { useUserContext } from '../../App/context/userContext'
import { useProjectListContext } from '../context/projectListContext'
import { useAppContext } from '../../App/context/appContext'
import { Department } from '../../../models/merchandise/hierarchy/Department.model'
import { Division } from '../../../models/merchandise/hierarchy/Division.model'
import Truncate from 'react-truncate'
import EnterpriseIcon, {
  ArchiveIcon,
  OverflowMenuVerticalIcon,
  VisibleShowIcon,
} from '@enterprise-ui/icons'

export interface Props {
  project: ProjectDetail
}

export const ProjectListTableItem = ({ project }: Props) => {
  const { cancelProject } = useProjectListContext()!
  const { userPermissions } = useUserContext()!
  const { setDialogProps } = useAppContext()!

  const navigate = useNavigate()

  const handleViewClick = (id: string) => {
    navigate(`/projects/${id}`)
  }
  const reducer = (accumulator: any, currentValue: any) =>
    accumulator + currentValue

  const departments =
    project.departments && project.departments.length > 0
      ? project.departments
          .map(
            (department: Department, index: Number) =>
              (index ? ', ' : '') + department.department_name,
          )
          .reduce(reducer)
      : ''

  const divisions =
    project.divisions && project.divisions.length > 0
      ? project.divisions
          .map(
            (division: Division, index: Number) =>
              (index ? ', ' : '') + division.division_name,
          )
          .reduce(reducer)
      : ''
  return (
    <Fragment>
      <Table.Row
        className="sem_TableCardItem"
        noWrap={true}
        justify="space-between"
      >
        <Table.Data className="hc-pv-dense">
          <Dropdown location="bottom-left">
            <Button
              aria-label="Project dropdown menu"
              type="ghost"
              className="sem_IconBtn-dark"
              data-testid="openDropdown"
              iconOnly
              size="dense"
            >
              <EnterpriseIcon icon={OverflowMenuVerticalIcon} size="md" />
            </Button>
            <Dropdown.Menu>
              <Dropdown.MenuItem
                data-testid="openProject"
                onClick={() => handleViewClick(project.project_id)}
              >
                <EnterpriseIcon
                  className="hc-mr-dense"
                  icon={VisibleShowIcon}
                />
                View Project Details
              </Dropdown.MenuItem>
              {userPermissions.isProjectAdmin &&
                project.workflow.status !== 'Archive' &&
                project.workflow.status !== 'Cancel' && (
                  <Dropdown.MenuItem
                    onClick={() =>
                      setDialogProps(
                        new DialogProps({
                          headingText:
                            'Are you sure you want to cancel this project?',
                          approveButtonText: 'Yes',
                          onApprove: () => cancelProject(project.project_id),
                          refuseButtonText: 'Cancel',
                          onRefuse: () => setDialogProps(new DialogProps()),
                        }),
                      )
                    }
                  >
                    <EnterpriseIcon
                      className="hc-mr-dense"
                      icon={ArchiveIcon}
                    />
                    Cancel Project
                  </Dropdown.MenuItem>
                )}
            </Dropdown.Menu>
          </Dropdown>
        </Table.Data>
        <Table.Data xs={1} sortValue={project.sap_project_id}>
          {project.sap_project_id}
        </Table.Data>
        <Table.Data sortValue={project.set_date} className="hc-pl-dense" xs={1}>
          <DateFormatter format="MM/DD/YYYY" date={project.set_date} />
        </Table.Data>
        <Table.Data sortValue={project.name} xs={2} className="truncate">
          <Anchor as={Link} to={`/projects/${project.project_id}`}>
            {project.name}
          </Anchor>
        </Table.Data>
        <Table.Data
          sortValue={project.workflow.status}
          className="hc-pv-dense"
          xs={1}
        >
          {project.workflow.status}
        </Table.Data>
        <Table.Data sortValue={project.blueprint_name} xs={2}>
          <Anchor as={Link} to={`/blueprints/${project.blueprint_id}`}>
            {project.blueprint_name}
          </Anchor>
        </Table.Data>
        <Table.Data sortValue={project.project_type} xs={true}>
          {project.project_type}
        </Table.Data>
        <Table.Data sortValue={project.tactic} xs={1}>
          {project.tactic}
        </Table.Data>
        <Table.Data sortValue={project.campaign.name} xs={1}>
          <Popover content={project.campaign.name} key={project.campaign.name}>
            <Truncate width={120}>{project.campaign.name}</Truncate>
          </Popover>
        </Table.Data>
        <Table.Data sortValue={departments} xs={1}>
          <Popover content={departments} key={departments}>
            <Truncate width={120}>{departments}</Truncate>
          </Popover>
        </Table.Data>
        <Table.Data sortValue={divisions} xs={1}>
          <Popover content={divisions} key={divisions}>
            <Truncate width={120}>{divisions}</Truncate>
          </Popover>
        </Table.Data>
      </Table.Row>
    </Fragment>
  )
}

export default ProjectListTableItem
