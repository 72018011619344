import Axios from 'axios'
import React, { createContext, useState, useContext, useCallback } from 'react'
import { connect } from 'react-redux'
import { DashboardRequest } from '../../../models/dashboardV2/DashboardRequest.model'
import { ProjectSummary } from '../../../models/dashboardV2/ProjectSummary.model'
import {
  ALERT,
  DASHBOARD_MANAGERS_LOCAL_STORAGE_KEY,
  DASHBOARD_SERVICE_API_DOMAIN_URL,
  PROJECT_SERVICE_API_DOMAIN_URL,
  TOASTER_DEFAULTS,
} from '../../App/constants/appConstants'
import { useEnv } from '@praxis/component-runtime-env'
import { useAuth } from '@praxis/component-auth'
import { ProjectMilestoneCompleteRequest } from '../../../models/dashboardV2/ProjectMilestoneCompleteRequest.model'
import { ProjectMilestonesSummary } from '../../../models/dashboardV2/ProjectMilestoneSummary.model'
import BlueprintMilestoneCompleteRequest from '../../../models/dashboardV2/BlueprintMilestoneCompleteRequest.model'
import { Count } from '../../../models/dashboardV2/dto/Count.model'
import { useToaster } from '@enterprise-ui/canvas-ui-react'

enum DashboardErrors {
  BLUEPRINT_SUMMARY_LIST = 'Unable to get Blueprint Summary',
  BLUEPRINT_MILESTONE_SUMMARY_LIST = 'Unable to get Blueprint Milestone Summary',
  PROJECT_SUMMARY_LIST = 'Unable to get Project Summary',
  PROJECT_MILESTONE_SUMMARY_LIST = 'Unable to get Project Milestone Summary',
}

type ContextType = {
  isLoading: boolean
  managers: any[]
  setManagers: Function
  getManagersFromStorage: () => void

  dashboardErrors: Partial<
    Record<keyof typeof DashboardErrors, DashboardErrors>
  >

  blueprintSummaryList: any[]
  setBlueprintSummaryList: Function

  blueprintMilestoneSummaryList: any[]
  setBlueprintMilestoneSummaryList: Function

  projectSummaryList: ProjectSummary[]
  setProjectSummaryList: Function
  showKitAlert: boolean

  projectMilestoneSummaryList: ProjectMilestonesSummary[]
  setProjectMilestoneSummaryList: Function
  getColorCount: (list: any, color: string) => any
  showProjectMilestonAlert: boolean
  modifiedProjects: ProjectSummary[]
  setModifiedProjects: Function
  saveProjectMilestoneStatus: (
    modifiedMilestones: ProjectMilestoneCompleteRequest[],
  ) => void

  includeCompleteBlueprints: boolean
  setIncludeCompleteBlueprints: (isTrue: boolean) => void
  includeAllActiveBlueprints: boolean
  setIncludeAllActiveBlueprints: (isTrue: boolean) => void

  includeCompleteProjects: boolean
  setIncludeCompleteProjects: (isTure: boolean) => void
  includeAllActiveProjects: boolean
  setIncludeAllActiveProjects: (isTrue: boolean) => void

  includeCompleteBpMilestones: boolean
  setIncludeCompleteBpMilestones: (isTrue: boolean) => void

  includeCompleteProjectMilestones: boolean
  setIncludeCompleteProjectMilestones: (isTrue: boolean) => void
  saveBlueprintMilestoneStatus: (
    modifiedMilestones: BlueprintMilestoneCompleteRequest[],
  ) => void
  modifiedBlueprintMilestones: any[]
  setModifiedBlueprintMilestones: Function
  blueprintAggregateCounts: Count[]
  projectAggregateCounts: Count[]
  getBlueprintCounts: () => void
  getProjectCounts: () => void
  projectSummaryFacets: any
  setProjectSummaryFacets: (facets: any) => void
}

export const DashboardContext = createContext<ContextType | undefined>(
  undefined,
)

type Props = {
  children: React.ReactNode
}

export const DashboardContextProviderComponent = ({ children }: Props) => {
  const { session } = useAuth()
  const env = useEnv()
  const makeToast = useToaster()

  const [isLoading, setIsLoading] = useState(false)
  const [blueprintSummaryList, setBlueprintSummaryList] = useState([])
  const [blueprintMilestoneSummaryList, setBlueprintMilestoneSummaryList] =
    useState([])
  const [dashboardErrors] = useState({})
  const [projectSummaryList, setProjectSummaryList] = useState([])
  const [showKitAlert] = useState(false)
  const [projectMilestoneSummaryList, setProjectMilestoneSummaryList] =
    useState([])
  const [showProjectMilestonAlert] = useState(false)
  const [includeCompleteBlueprints, setIncludeCompleteBlueprints] =
    useState(false)
  const [includeAllActiveBlueprints, setIncludeAllActiveBlueprints] =
    useState(false)
  const [includeCompleteProjects, setIncludeCompleteProjects] = useState(false)
  const [includeAllActiveProjects, setIncludeAllActiveProjects] =
    useState(false)
  const [includeCompleteBpMilestones, setIncludeCompleteBpMilestones] =
    useState(false)
  const [
    includeCompleteProjectMilestones,
    setIncludeCompleteProjectMilestones,
  ] = useState(false)
  const [modifiedProjects, setModifiedProjects] = useState<ProjectSummary[]>([])
  const [modifiedBlueprintMilestones, setModifiedBlueprintMilestones] =
    useState<any[]>([])
  const [blueprintAggregateCounts, setBlueprintAggregateCounts] = useState<
    Count[]
  >([])
  const [projectAggregateCounts, setProjectAggregateCounts] = useState<Count[]>(
    [],
  )
  const [projectSummaryFacets, setProjectSummaryFacets] = useState({})

  const getManagersFromStorage = () => {
    const managerObjectFromStorage = localStorage.getItem(
      DASHBOARD_MANAGERS_LOCAL_STORAGE_KEY,
    )
    return managerObjectFromStorage ? JSON.parse(managerObjectFromStorage) : ''
  }

  const [managers, setManagers] = useState([
    {
      email: session && session.userInfo ? session.userInfo.email : '',
      isSessionUser: true,
    },
    ...getManagersFromStorage(),
  ])

  localStorage.setItem(
    DASHBOARD_MANAGERS_LOCAL_STORAGE_KEY,
    JSON.stringify(managers.filter((manager) => !manager.isSessionUser)),
  )

  const getColorCount = (list: any, color: string) => {
    return list.filter((listItem: any) => listItem.health === color).length
  }

  const saveProjectMilestoneStatus = async (
    modifiedMilestones: ProjectMilestoneCompleteRequest[],
  ) => {
    setIsLoading(true)
    try {
      await Axios.put(
        `${
          env.apiDomainUrl + PROJECT_SERVICE_API_DOMAIN_URL
        }/projects/milestones`,
        modifiedMilestones,
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'success',
        heading: 'Project Milestones Updated',
        message: 'Successfully updated Project Milestone Status',
      })
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Update Project Milestone Status',
        message: err.response.data.message,
      })
    }
    setIsLoading(false)
  }

  const saveBlueprintMilestoneStatus = async (
    modifiedMilestones: BlueprintMilestoneCompleteRequest[],
  ) => {
    setIsLoading(true)
    try {
      await Axios.put(
        `${
          env.apiDomainUrl + PROJECT_SERVICE_API_DOMAIN_URL
        }/blueprints/milestones`,
        {
          requests: modifiedMilestones,
        },
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'success',
        heading: 'Blueprint Milestones Updated',
        message: 'Successfully updated blueprint milestones statuses',
      })
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Update Blueprint Milestones',
        message: err.response.data.message,
      })
    }
    setIsLoading(false)
  }

  const getBlueprintCounts = useCallback(async () => {
    setIsLoading(true)
    try {
      const response = await Axios.post(
        `${
          env.apiDomainUrl + DASHBOARD_SERVICE_API_DOMAIN_URL
        }/blueprint_counts`,
        new DashboardRequest({
          user_names: managers.map((manager) => manager.email),
          include_complete: includeCompleteBlueprints,
          include_all: includeAllActiveBlueprints,
        }),
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      setBlueprintAggregateCounts(response.data.aggregate_counts)
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: ALERT.ERROR,
        heading: 'Get blueprint counts failed',
        message: err.response.data.message,
      })
    }
    setIsLoading(false)
  }, [
    env.apiDomainUrl,
    includeAllActiveBlueprints,
    includeCompleteBlueprints,
    managers,
    makeToast,
  ])

  const getProjectCounts = useCallback(async () => {
    setIsLoading(true)
    try {
      const response = await Axios.post(
        `${env.apiDomainUrl + DASHBOARD_SERVICE_API_DOMAIN_URL}/project_counts`,
        new DashboardRequest({
          user_names: managers.map((manager) => manager.email),
          include_complete: includeCompleteProjects,
          include_all: includeAllActiveProjects,
        }),
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      setProjectAggregateCounts(response.data.aggregate_counts)
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: ALERT.ERROR,
        heading: 'Get project counts failed',
        message: err.response.data.message,
      })
    }
    setIsLoading(false)
  }, [
    env.apiDomainUrl,
    includeAllActiveProjects,
    includeCompleteProjects,
    managers,
    makeToast,
  ])

  return (
    <DashboardContext.Provider
      value={{
        isLoading,
        managers,
        setManagers,
        getManagersFromStorage,

        blueprintSummaryList,
        setBlueprintSummaryList,

        blueprintMilestoneSummaryList,
        setBlueprintMilestoneSummaryList,

        projectSummaryList,
        setProjectSummaryList,
        showKitAlert,

        dashboardErrors,

        projectMilestoneSummaryList,
        setProjectMilestoneSummaryList,
        modifiedProjects,
        setModifiedProjects,
        saveProjectMilestoneStatus,
        showProjectMilestonAlert,
        getColorCount,

        includeCompleteBlueprints,
        setIncludeCompleteBlueprints,
        includeAllActiveBlueprints,
        setIncludeAllActiveBlueprints,

        includeCompleteProjects,
        setIncludeCompleteProjects,
        includeAllActiveProjects,
        setIncludeAllActiveProjects,

        includeCompleteBpMilestones,
        setIncludeCompleteBpMilestones,

        includeCompleteProjectMilestones,
        setIncludeCompleteProjectMilestones,
        saveBlueprintMilestoneStatus,
        modifiedBlueprintMilestones,
        setModifiedBlueprintMilestones,
        blueprintAggregateCounts,
        projectAggregateCounts,
        getBlueprintCounts,
        getProjectCounts,
        projectSummaryFacets,
        setProjectSummaryFacets,
      }}
    >
      {children}
    </DashboardContext.Provider>
  )
}

export const useDashboardContext = () => useContext(DashboardContext)

export const DashboardProvider = connect(
  null,
  null,
)(DashboardContextProviderComponent)
