import React, { createContext, useContext, useState } from 'react'
import { useDashboardContext } from 'components/DashboardV2/context/dashboardContext'
import { useEnv } from '@praxis/component-runtime-env'
import { IServerSideGetRowsParams } from 'ag-grid-community'
import { DashboardRequest } from '../../../models/dashboardV2/DashboardRequest.model'
import { isEmpty, set } from 'lodash'
import Axios from 'axios'
import {
  ALERT,
  DASHBOARD_SERVICE_API_DOMAIN_URL,
  HEADER_OBJECT,
  TOASTER_DEFAULTS,
} from 'components/App/constants/appConstants'
import { connect } from 'react-redux'
import { ProjectSummary } from '../../../models/dashboardV2/ProjectSummary.model'
import { getHealthFromLabel } from 'components/DashboardV2/helpers/dashboardHelper'
import { useToaster } from '@enterprise-ui/canvas-ui-react'

type ContextType = {
  projectDashboardSSRMServer: () => void
  currentProjectRequest: DashboardRequest | undefined
}

export const ProjectDashboardSSRMServerContext = createContext<
  ContextType | undefined
>(undefined)

type Props = {
  children: React.ReactNode
}

export const ProjectDashboardSSRMServerComponent = ({ children }: Props) => {
  const {
    managers,
    includeCompleteProjects,
    includeAllActiveProjects,
    setProjectSummaryFacets,
  } = useDashboardContext()!
  const env = useEnv()
  const makeToast = useToaster()
  const [currentProjectRequest, setCurrentProjectRequest] =
    useState<DashboardRequest>()

  const projectDashboardSSRMServer = () => {
    const buildSearchRequest = (params: IServerSideGetRowsParams) => {
      const { request } = params
      const sortFields =
        request.sortModel.length > 0
          ? Object.assign(
              {},
              ...request.sortModel.map((sortItem: any) => ({
                [sortItem.colId === 'health_info.label'
                  ? 'health'
                  : sortItem.colId]: sortItem.sort,
              })),
            )
          : undefined
      const filters = {}
      for (const [key, value] of Object.entries(request.filterModel)) {
        const { filter, values, dateFrom, dateTo } = value as any
        if (
          key === 'sap_project_id' ||
          key === 'name' ||
          key === 'blueprint_name'
        ) {
          set(filters, [key], [filter])
        } else if (key === 'health_info.label') {
          set(
            filters,
            ['health'],
            values.map((label: string) => getHealthFromLabel(label)),
          )
        } else if (
          key === 'set_date' ||
          key === 'current_milestone.due_date' ||
          key === 'in_store_date'
        ) {
          set(
            filters,
            [key],
            [dateFrom.substring(0, 10), dateTo.substring(0, 10)],
          )
        } else if (key === 'departments') {
          set(filters, ['departments.department_display_name'], values)
        } else {
          set(filters, [key], values)
        }
      }
      return new DashboardRequest({
        filters: isEmpty(filters) ? undefined : filters,
        sort_fields: sortFields,
        user_names: managers.map((manager: any) => manager.email),
        include_complete: includeCompleteProjects,
        include_all: includeAllActiveProjects,
      })
    }

    return {
      getData: async (params: IServerSideGetRowsParams) => {
        const dashboardRequest = buildSearchRequest(params)
        setCurrentProjectRequest(dashboardRequest)
        try {
          const pageNumber =
            params.request.endRow !== undefined
              ? Math.ceil(params.request.endRow / 50)
              : null
          const res = await Axios.post(
            `${
              env.apiDomainUrl + DASHBOARD_SERVICE_API_DOMAIN_URL
            }/projects/search?page=${pageNumber}`,
            dashboardRequest,
            HEADER_OBJECT,
          )
          setProjectSummaryFacets(res.data.facets)
          return {
            success: true,
            data: res.data.project_summaries.map(
              (datum: any) => new ProjectSummary(datum),
            ),
            rows: res.data.project_summaries.map(
              (datum: any) => new ProjectSummary(datum),
            ),
            headers: res.headers,
          }
        } catch (err: any) {
          makeToast({
            ...TOASTER_DEFAULTS,
            type: ALERT.ERROR,
            heading: 'Failed to get project summary',
            message: err.response.data.message,
          })
        }
      },
    }
  }

  return (
    <ProjectDashboardSSRMServerContext.Provider
      value={{ projectDashboardSSRMServer, currentProjectRequest }}
    >
      {children}
    </ProjectDashboardSSRMServerContext.Provider>
  )
}

export const ProjectDashboardSSRMServerProvider = connect(
  null,
  {},
)(ProjectDashboardSSRMServerComponent)

export const useProjectDashboardSSRMServerContext = () =>
  useContext(ProjectDashboardSSRMServerContext)
