import React from 'react'
import { Card, Grid } from '@enterprise-ui/canvas-ui-react'
import { useProjectDetailsContext } from '../../../context/projectDetailsContext'

const KitRecreationMessage = () => {
  const { currentProject } = useProjectDetailsContext()!

  const messageMap: any = {
    'Recreate Kits (Finalization)':
      'Signs or store list is updated after kits are finalized. Kit scenario must be re-run to accept new changes.',
    'Kits Modified (Finalization)':
      'Kit attributes have been updated. Re-finalization of kits is required.',
    'Recreate Kits':
      'Signs or store list is updated after kits are created. Kit scenario must be re-run to accept new changes.',
  }

  return (
    <Card padding="none" className="rbx-kit-message" elevation={0}>
      <div className="hc-pa-dense">
        <Grid.Container className="hc-pa-dense">
          <p>{messageMap[currentProject.status]}</p>
        </Grid.Container>
      </div>
    </Card>
  )
}

export default KitRecreationMessage
