import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
} from 'react'
import { Column } from 'ag-grid-community'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { getVendorOptions } from 'components/App/helpers/vendorHelpers'
import { Grid } from '@enterprise-ui/canvas-ui-react'
import SignResponse from '../../../../../../../models/signs/SignResponse.model'
import { useProjectSignListContext } from 'components/ProjectDetails/components/ProjectSignList/context/projectSignListContext'
import { DropdownOption } from '../../../../../../../models/app/DropdownOption.model'
import { ContractCore } from '../../../../../../../models/contracts/ContractCore.model'
import SignTemplateResponse from '../../../../../../../models/signTemplates/SignTemplateResponse.model'

export interface Props {
  data: SignResponse
  value: string
  column: Column
}

const AutocompleteCellEditor = forwardRef(
  ({ data, value, column }: Props, ref) => {
    const { activeContracts, signTemplateOptions, getSignTemplatesByType } =
      useProjectSignListContext()!
    const [editValue, setEditValue] = useState(value ? value : '')
    const [signSizeOptions, setSignSizeOptions] = useState<
      DropdownOption<string>[]
    >([])
    const [signDefinitionOptions, setSignDefinitionOptions] = useState<
      DropdownOption<string>[]
    >([])

    const handleOptions = (query: string) => {
      switch (column.getColId()) {
        case 'sign_template_info.template_name':
          return signTemplateOptions.map((template: SignTemplateResponse) => ({
            value: template.name,
            label: template.name,
            id: template.id,
          }))
        case 'sign_size':
          return signSizeOptions
        case 'sign_definition':
          return signDefinitionOptions
        case 'messaging.disclaimer':
          return [
            {
              id: 'Excludes clearance',
              value: 'Excludes clearance',
              label: 'Excludes clearance',
            },
          ]
        default:
          return getVendorOptions(query, editValue)
      }
    }

    const handleChange = (id: string, value: string) => setEditValue(value)

    useEffect(() => {
      if (column.getColId() === 'sign_template_info.template_name') {
        getSignTemplatesByType(data.sign_type)
      }
    }, [data.sign_type, column, getSignTemplatesByType])

    useEffect(() => {
      const filteredOptions: DropdownOption<string>[] = activeContracts
        .filter(
          (contract: ContractCore) => contract.signType === data.sign_type,
        )
        .map((contract: ContractCore, index: number) => {
          return new DropdownOption({
            id: index,
            value: contract.signSize,
            label: contract.signSize,
          })
        })
      filteredOptions.push(
        new DropdownOption({
          id: 'CUSTOM',
          value: 'CUSTOM',
          label: 'CUSTOM',
        }),
      )
      setSignSizeOptions(filteredOptions)
    }, [activeContracts, data.sign_type])

    useEffect(() => {
      const filteredOptions: DropdownOption<string>[] = activeContracts
        .filter(
          (contract: ContractCore) =>
            contract.signType === data.sign_type &&
            contract.signSize === data.sign_size,
        )
        .map((contract: ContractCore, index: number) => {
          return new DropdownOption({
            id: index,
            value: contract.signDefinition,
            label: contract.signDefinition,
          })
        })
      if (data.sign_size === 'CUSTOM') {
        filteredOptions.push(
          new DropdownOption<string>({
            id: 'CUSTOM',
            value: 'CUSTOM',
            label: 'CUSTOM',
          }),
        )
      }
      setSignDefinitionOptions(filteredOptions)
    }, [activeContracts, data.sign_size, data.sign_type])

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return editValue
        },
      }
    })

    return (
      <div className="hc-pa-dense">
        <Grid.Container className="sem_StandardForm error_messaging_enabled">
          <Grid.Item xs={12}>
            <Autocomplete
              label={column.getColDef().headerName}
              optionHeight={
                column.getColId() === 'sign_definition' ? 'expanded' : 'dense'
              }
              options={(query: string) => handleOptions(query)}
              value={{
                value: editValue,
                label: editValue,
              }}
              onUpdate={(id: string, value: any) => {
                if (value) handleChange(id, value.value)
              }}
            />
          </Grid.Item>
        </Grid.Container>
      </div>
    )
  },
)

export default AutocompleteCellEditor
