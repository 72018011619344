import { KitReceiveDates } from './KitReceiveDates.model'
import { KitComments } from './KitComments.model'

export class KitInfoRequest {
  id: string
  initial_receive_dates?: KitReceiveDates
  shipping_method?: string
  comments?: KitComments
  updated_by: string

  constructor(props: any = {}) {
    this.id = props.id
    this.initial_receive_dates = props.initial_receive_dates
    this.shipping_method = props.shipping_method
    this.comments = props.comments
    this.updated_by = props.updated_by
  }
}
