export enum TabName {
  DETAILS = 'DETAILS',
  MILESTONES = 'MILESTONES',
  PROJECTS = 'PROJECTS',
}

export const BLUEPRINT_PROJECT_TYPE = [
  {
    value: 'Brand Launch',
    label: 'Brand Launch',
  },
  {
    value: 'Brand Extension',
    label: 'Brand Extension',
  },
  {
    value: 'CoSpace',
    label: 'CoSpace',
  },
  {
    value: 'Cycle',
    label: 'Cycle',
  },
  {
    value: 'LTO',
    label: 'LTO',
  },
  {
    value: 'Operational',
    label: 'Operational',
  },
  {
    value: 'POC',
    label: 'POC',
  },
  {
    value: 'Pitch',
    label: 'Pitch',
  },
  {
    value: 'Revision',
    label: 'Revision',
  },
  {
    value: 'SPLN',
    label: 'SPLN',
  },
  {
    value: 'Seasonal',
    label: 'Seasonal',
  },
  {
    value: 'Storewide Initiative',
    label: 'Storewide Initiative',
  },
  {
    value: 'Test',
    label: 'Test',
  },
  {
    value: 'Transition',
    label: 'Transition',
  },
]

export const BLUEPRINT_VERSION_COMPLEXITY = [
  {
    value: 'Low (1-2 Unique Sign Packages)',
    label: 'Low (1-2 Unique Sign Packages)',
  },
  {
    value: 'Medium (3-9 Unique Sign Packages)',
    label: 'Medium (3-9 Unique Sign Packages)',
  },
  {
    value: 'High (10+ Unique Sign Packages)',
    label: 'High (10+ Unique Sign Packages)',
  },
]

export const BLUEPRINT_SIGN_VOLUME = [
  {
    value: '1-10 Unique Signs',
    label: '1-10 Unique Signs',
  },
  {
    value: '11-50 Unique Signs',
    label: '11-50 Unique Signs',
  },
  {
    value: '51-100 Unique Signs',
    label: '51-100 Unique Signs',
  },
  {
    value: '101-250 Unique Signs',
    label: '101-250 Unique Signs',
  },
  {
    value: '251-500 Unique Signs',
    label: '251-500 Unique Signs',
  },
  {
    value: '500+ Unique Signs',
    label: '500+ Unique Signs',
  },
]

export const BLUEPRINT_APPROVAL_LVL = [
  {
    value: 'Project Team',
    label: 'Project Team',
  },
  {
    value: 'Director',
    label: 'Director',
  },
  {
    value: 'VP',
    label: 'VP',
  },
  {
    value: 'SVP',
    label: 'SVP',
  },
  {
    value: 'CMO/CCO',
    label: 'CMO/CCO',
  },
]
