import { useFormik } from 'formik'
import { BlueprintDetail } from '../../../../../models/blueprints/BlueprintDetail.model'
import { BlueprintRequest } from '../../../../../models/blueprints/BlueprintRequest.model'

export const useBlueprintForm = (
  currentBlueprint: BlueprintDetail,
  updateBlueprint: Function,
  updatedBy: string,
) => {
  const validate = (values: BlueprintRequest) => {
    const errors: any = {}
    if (!values.type) {
      errors.type = 'Required'
    }
    if (!values.campaign || !values.campaign.id) {
      errors.campaign = { id: 'Required' }
    }
    if (!values.sign_volume_estimate) {
      errors.sign_volume_estimate = 'Required'
    }
    if (!values.version_complexity) {
      errors.version_complexity = 'Required'
    }
    if (!values.creative_approval_level) {
      errors.creative_approval_level = 'Required'
    }
    if (!values.set_date) {
      errors.set_date = 'Required'
    }
    if (!values.name) {
      errors.name = 'Required'
    } else if (values.name.length > 50) {
      errors.name = 'Must be less than 50 characters'
    }
    return errors
  }

  return useFormik({
    enableReinitialize: true,
    initialValues: new BlueprintRequest({
      ...currentBlueprint,
      is_tier_one: currentBlueprint.tier_one,
      is_incremental: currentBlueprint.incremental,
      updated_by: updatedBy,
    }),
    validate,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit(values) {
      updateBlueprint(values)
    },
  })
}
