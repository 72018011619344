import { ProductSpecification } from '../../../models/signs/ProductSpecification.model'

export const formattedSpecs = (spec: string = '') => {
  if (spec.includes('<B>') && spec.includes('</B>')) {
    const withFrontTagRemoved = spec.split('<B>')[1]
    const newSplit = withFrontTagRemoved.split('</B>')
    return new ProductSpecification({
      title: newSplit[0].trim(),
      body: newSplit[1].trim(),
    })
  } else {
    return new ProductSpecification()
  }
}

export const isDpciFormat = (value: string) =>
  value.match(/^(\d{3})-(\d{2})-(\d{4})/)
