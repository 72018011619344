import { Column } from '../../../../models/agGrid/AgGridColumn.model'

export const STANDARD_SIGN_ADMIN_VIEW = [
  new Column({
    type: ['selectedRow', 'fixed'],
  }),
  new Column({
    type: ['signMenu', 'fixed'],
  }),
  new Column({
    field: 'name',
    headerName: 'Standard Sign\u002A',
    width: 192,
    type: ['textEditor', 'requiredField'],
  }),
  new Column({
    field: 'type',
    headerName: 'Sign Type\u002A',
    width: 192,
    type: ['selectEditor', 'requiredField'],
  }),
  new Column({
    field: 'finished_width',
    headerName: 'Finished Width\u002A',
    width: 214,
    type: ['numberEditor', 'requiredField'],
  }),
  new Column({
    field: 'finished_length',
    headerName: 'Finished Length\u002A',
    width: 220,
    type: ['numberEditor', 'requiredField'],
  }),
  new Column({
    field: 'finished_depth',
    headerName: 'Finished Depth\u002A',
    width: 220,
    type: ['numberEditor', 'requiredField'],
  }),
  new Column({
    field: 'flat_width',
    headerName: 'Flat Width\u002A',
    width: 214,
    type: ['numberEditor', 'requiredField'],
  }),
  new Column({
    field: 'flat_length',
    headerName: 'Flat Length\u002A',
    width: 220,
    type: ['numberEditor', 'requiredField'],
  }),
  new Column({
    field: 'flat_depth',
    headerName: 'Flat Depth\u002A',
    width: 220,
    type: ['numberEditor', 'requiredField'],
  }),
  new Column({
    field: 'size_editable',
    headerName: 'Flat Dims Editable',
    width: 192,
    type: ['flatDimsEditable', 'toggleEditor'],
  }),
  new Column({
    field: 'count',
    headerName: 'Number of Signs',
    width: 220,
  }),
]
