import React from 'react'
// WARNING: Canvas v7 - Component "Container" has been deprecated. Please remove this import.
import { Layout, Grid, Divider } from '@enterprise-ui/canvas-ui-react'
import { ButtonRow, NonRetailFields, ProjectSignFields } from './components'

const ExportOptionsDrawer = () => {
  return (
    <Layout.Rail className="export_rail">
      <div className="hc-pa-dense">
        <Grid.Container spacing="dense">
          <ButtonRow />
          <Divider />
          <Grid.Item className="hc-pl-expanded" xs={12}>
            <ProjectSignFields />
          </Grid.Item>
          <Divider />
          <Grid.Item xs={12}>
            <NonRetailFields />
          </Grid.Item>
        </Grid.Container>
      </div>
    </Layout.Rail>
  )
}

export default ExportOptionsDrawer
