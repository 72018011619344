import React, { createContext, useState, useContext } from 'react'
import { connect } from 'react-redux'
import {
  GridOptions,
  GridReadyEvent,
  CellValueChangedEvent,
  GridApi,
  ColumnApi,
  FilterChangedEvent,
  SortChangedEvent,
  EditableCallbackParams,
  CellClassParams,
  RowNode,
  SelectionChangedEvent,
} from 'ag-grid-community'
import { SortState } from '../../../../models/agGrid/AgGridSortState.model'
import DimsEditableToggle from '../components/agGridTypes/DimsEditableToggle'
import RowDropdownMenu from '../components/agGridTypes/RowDropdownMenu'
import TextCellEditor from '../components/agGridTypes/TextCellEditor'
import SelectCellEditor from '../components/agGridTypes/SelectCellEditor'
import NumberCellEditor from '../components/agGridTypes/NumberCellEditor'
import ToggleCellEditor from '../components/agGridTypes/ToggleCellEditor'
import { useAppContext } from '../../../App/context/appContext'
import {
  BATCH_EDIT_ACTION,
  TOASTER_DEFAULTS,
} from '../../../App/constants/appConstants'
import { NEW_SIGN_TEMPLATE } from '../constants/standardSignConstants'
import { useStandardSignAdminContext } from './standardSignAdminContext'
import SignTemplateBatchRequest, {
  SignTemplateRequest,
} from '../../../../models/signTemplates/SignTemplateBatchRequest.model'
import SignTemplateResponse from '../../../../models/signTemplates/SignTemplateResponse.model'
import { v4 as uuidv4 } from 'uuid'
import { cloneDeep, clone } from 'lodash'
import { rowDragText } from '../helpers/signTemplateHelper'
import { useToaster } from '@enterprise-ui/canvas-ui-react'

type ContextType = {
  gridApi: GridApi | undefined
  rowData: SignTemplateResponse[]
  setRowData: Function
  gridOptions: GridOptions
  onGridReady: (event: GridReadyEvent) => void
  addNewSignTemplate: (rowIndex: number | null) => void
  onCellValueChange: (event: CellValueChangedEvent) => void
  onFilterChange: (event: FilterChangedEvent) => void
  onSortChanged: (event: SortChangedEvent) => void
  onSelectionChange: (event: SelectionChangedEvent) => void
  onFirstDataRender: () => void
  validateSignTemplatesAndSave: (nodes: RowNode[]) => void
  selectedRows: RowNode[]
  setSelectedRows: Function
}

export const AgGridStandardSignAdminContext = createContext<
  ContextType | undefined
>(undefined)

type Props = {
  children: React.ReactNode
}

export const AgGridStandardSignAdminProviderComponent = ({
  children,
}: Props) => {
  const {
    signTemplateSortState,
    setSignTemplateSortState,
    signTemplateFilter,
    setSignTemplateFilter,
  } = useAppContext()!
  const makeToast = useToaster()
  const {
    modifiedSignTemplates,
    setModifiedSignTemplates,
    modifiedSignTemplateIds,
    setModifiedSignTemplateIds,
    setSignTemplatesModified,
    saveModifiedSignTemplates,
  } = useStandardSignAdminContext()!
  const [gridApi, setGridApi] = useState<GridApi>()
  const [rowData, setRowData] = useState<SignTemplateResponse[]>([])
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>()
  const [selectedRows, setSelectedRows] = useState<RowNode[]>([])

  const standardSignColumnTypes = {
    fixed: {
      lockPinned: true,
      resizable: false,
      suppressSizeToFit: true,
      pinned: 'left',
    },
    selectedRow: {
      width: 70,
      maxWidth: 70,
      filter: false,
      sortable: false,
      headerCheckboxSelection: true,
      checkboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      rowDrag: true,
      rowDragText: rowDragText,
    },
    requiredField: {
      headerClass: 'required-field',
      cellClassRules: {
        'required-field': (params: CellClassParams) =>
          params.colDef.colId !== 'size_editable' &&
          (params.value === '' || params.value === undefined),
        'has-error': (params: CellClassParams) =>
          params.data.hasError
            ? params.value === '' || params.value === undefined
            : false,
      },
    },
    signMenu: {
      width: 36,
      maxWidth: 36,
      filter: false,
      sortable: false,
      cellRenderer: 'RowDropdownMenu',
      cellClass: 'actions-button-cell dropdown-menu',
    },
    flatDimsEditable: {
      cellRenderer: 'DimsEditableToggle',
    },
    textEditor: {
      editable: (params: EditableCallbackParams) => true,
      cellEditor: 'TextEditor',
      cellEditorPopup: true,
    },
    selectEditor: {
      editable: (params: EditableCallbackParams) => true,
      cellEditor: 'SelectEditor',
      cellEditorPopup: true,
    },
    numberEditor: {
      editable: (params: EditableCallbackParams) => true,
      cellEditor: 'NumberEditor',
      cellEditorPopup: true,
    },
    toggleEditor: {
      editable: (params: EditableCallbackParams) => true,
      cellEditor: 'ToggleEditor',
      cellEditorPopup: true,
    },
  }

  const standardSignGridComponents = {
    DimsEditableToggle: DimsEditableToggle,
    RowDropdownMenu: RowDropdownMenu,
    TextEditor: TextCellEditor,
    SelectEditor: SelectCellEditor,
    NumberEditor: NumberCellEditor,
    ToggleEditor: ToggleCellEditor,
  }

  const gridOptions = {
    animateRows: true,
    defaultColDef: {
      resizable: true,
      sortable: true,
      width: 148,
      menuTabs: ['filterMenuTab'],
      filterParams: {
        buttons: ['clear', 'reset'],
        newRowsAction: 'keep',
      },
    },
    columnTypes: standardSignColumnTypes,
    components: standardSignGridComponents,
  }

  const onGridReady = (event: GridReadyEvent) => {
    setGridApi(event.api)
    setGridColumnApi(event.columnApi)
  }

  const addNewSignTemplate = async (rowIndex: number | null) => {
    const editedSignTemplates = modifiedSignTemplates
    const editedSignTemplateIds = modifiedSignTemplateIds

    const newSignTemplateId = `${NEW_SIGN_TEMPLATE}:${uuidv4()}`
    editedSignTemplates.push(
      new SignTemplateBatchRequest({
        sign_template_dto: new SignTemplateRequest({
          id: newSignTemplateId,
          size_editable: false,
        }),
        action: BATCH_EDIT_ACTION.ADD,
      }),
    )
    editedSignTemplateIds.push(newSignTemplateId)

    gridApi?.applyTransaction({
      addIndex: rowIndex === null ? 0 : rowIndex + 1,
      add: [
        new SignTemplateResponse({
          id: newSignTemplateId,
          size_editable: false,
          modified: true,
        }),
      ],
    })
    setModifiedSignTemplates(editedSignTemplates)
    setModifiedSignTemplateIds(editedSignTemplateIds)
  }

  const onCellValueChange = (event: CellValueChangedEvent) => {
    if (event.newValue !== event.oldValue) {
      setSignTemplatesModified(true)
      event.data.modified = true
      event.data.hasError = false

      if (modifiedSignTemplateIds.includes(event.data.id)) {
        setModifiedSignTemplates(
          (previousState: SignTemplateBatchRequest[]) => {
            const clonedSignTemplates = cloneDeep(previousState)
            clonedSignTemplates.map(
              (signTemplate: SignTemplateBatchRequest, index: number) =>
                signTemplate.sign_template_dto?.id === event.data.id
                  ? (clonedSignTemplates[index] = new SignTemplateBatchRequest({
                      sign_template_dto: new SignTemplateRequest({
                        ...event.data,
                      }),
                      action:
                        event.data.id.split(':')[0] === NEW_SIGN_TEMPLATE
                          ? BATCH_EDIT_ACTION.ADD
                          : BATCH_EDIT_ACTION.UPDATE,
                    }))
                  : (clonedSignTemplates[index] = new SignTemplateBatchRequest(
                      signTemplate,
                    )),
            )
            return clonedSignTemplates
          },
        )
      } else {
        setModifiedSignTemplates(
          (previousState: SignTemplateBatchRequest[]) => {
            const clonedSignTemplates = cloneDeep(previousState)
            clonedSignTemplates.push(
              new SignTemplateBatchRequest({
                sign_template_dto: new SignTemplateRequest({
                  ...event.data,
                }),
                action:
                  event.data.id.split(':')[0] === NEW_SIGN_TEMPLATE
                    ? BATCH_EDIT_ACTION.ADD
                    : BATCH_EDIT_ACTION.UPDATE,
              }),
            )
            return clonedSignTemplates
          },
        )
        setModifiedSignTemplateIds((previousState: string[]) => {
          const clonedIds = clone(previousState)
          clonedIds.push(event.data.id)
          return clonedIds
        })
      }

      event.column.getColDef().cellClass = 'ag-cell-modified'
      event.api.refreshCells({
        force: true,
        columns: [event.column.getColId()],
        rowNodes: [event.node],
      })
    }
  }

  const onFilterChange = (event: FilterChangedEvent) => {
    setSignTemplateFilter(event.api.getFilterModel())
  }

  const onSortChanged = (event: SortChangedEvent) => {
    setSignTemplateSortState(
      event.columnApi
        .getColumnState()
        .filter((s) => s.sort != null)
        .map((s) => new SortState(s)),
    )
  }

  const onSelectionChange = (event: SelectionChangedEvent) => {
    if (event.api.getSelectedNodes().length > 0) {
      setSelectedRows(event.api.getSelectedNodes())
    } else {
      setSelectedRows([])
    }
  }

  const onFirstDataRender = () => {
    if (signTemplateFilter) {
      gridApi?.setFilterModel(signTemplateFilter)
    }
    if (signTemplateSortState) {
      gridColumnApi?.applyColumnState({
        state: signTemplateSortState,
        defaultState: { sort: null },
      })
    }
  }

  const validateSignTemplatesAndSave = (nodes: RowNode[]) => {
    let validatedRows: RowNode[] = []
    nodes.map((node: RowNode) => {
      const data: SignTemplateResponse = node.data
      for (const [field, value] of Object.entries(data)) {
        if (field === 'size_editable') {
          if (value === '' || value === undefined) {
            data.hasError = true
          }
        }
      }
      validatedRows.push(node)
      return validatedRows
    })
    const rowsWithErrors = validatedRows.filter(
      (row: RowNode) => row.data.hasError,
    )
    if (rowsWithErrors.length > 0) {
      gridApi?.applyTransaction({ update: rowsWithErrors })
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Missing Required Fields',
        message: 'Please fill out all required fields',
      })
    } else {
      saveModifiedSignTemplates()
    }
  }

  return (
    <AgGridStandardSignAdminContext.Provider
      value={{
        gridApi,
        rowData,
        setRowData,
        onGridReady,
        gridOptions,
        onCellValueChange,
        addNewSignTemplate,
        onFilterChange,
        onSortChanged,
        onSelectionChange,
        onFirstDataRender,
        validateSignTemplatesAndSave,
        selectedRows,
        setSelectedRows,
      }}
    >
      {children}
    </AgGridStandardSignAdminContext.Provider>
  )
}

export const AgGridStandardSignAdminProvider = connect(
  null,
  null,
)(AgGridStandardSignAdminProviderComponent)

export const useAgGridStandardSignAdminContext = () =>
  useContext(AgGridStandardSignAdminContext)
