import React, { createContext, useCallback, useContext } from 'react'
import { connect } from 'react-redux'
import Axios from 'axios'
import { get, isEmpty, set } from 'lodash'
import { IServerSideGetRowsParams } from 'ag-grid-community'
import { useEnv } from '@praxis/component-runtime-env'
import { useFilterContext } from '../../App/context/filterContext'
import {
  HEADER_OBJECT,
  SEARCH_RESPONSE_SIZE,
  SIGN_SERVICE_API_DOMAIN_URL,
  TOASTER_DEFAULTS,
} from '../../App/constants/appConstants'
import { SearchRequest } from '../../../models/app/SearchRequest.model'
import { SearchResult } from '../../../models/app/SearchResponse.model'
import {
  SignLibraryResponse,
  SignLibrarySearchFacets,
} from '../../../models/signLibrary'
import {
  formatDpcis,
  formatTextValues,
} from '../../SignLibrary/helpers/filterFormHelpers'
import { useToaster } from '@enterprise-ui/canvas-ui-react'
import { useProjectDetailsContext } from 'components/ProjectDetails/context/projectDetailsContext'

type ContextType = {
  signImportSSRMServer: () => void
}

export const SignImportSSRMServerContext = createContext<
  ContextType | undefined
>(undefined)

type Props = {
  children: React.ReactNode
}

export const SignImportSSRMServerComponent = ({ children }: Props) => {
  const { signImportSearchRequest, setSignImportSearchRequest } =
    useFilterContext()!
  const { DPCIList } = useProjectDetailsContext()!
  const env = useEnv()
  const makeToast = useToaster()

  const buildSearchReq = useCallback(
    (params: IServerSideGetRowsParams) => {
      const { api, request } = params
      const finalRowData =
        api.getServerSideStoreState()[0].info.finalSignData &&
        new SignLibraryResponse(
          api.getServerSideStoreState()[0].info.finalSignData,
        )
      const partialFilters = {}
      const includeFilters = {}
      const rangeFilters = {}
      const sortFields =
        request.sortModel.length > 0
          ? Object.assign(
              {},
              ...request.sortModel.map((sortItem: any) => ({
                [sortItem.colId]: sortItem.sort,
                id: 'asc',
              })),
            )
          : { id: 'asc' }
      const searchAfter =
        request.startRow === 0
          ? []
          : request.sortModel.length > 0
          ? [
              get(finalRowData, `${Object.keys(sortFields)[0]}`, ''),
              finalRowData.id,
            ]
          : [finalRowData.id]

      if (!isEmpty(request.filterModel)) {
        for (const [key, value] of Object.entries(request.filterModel)) {
          const { filterType, type, filter, values, dateFrom, dateTo } =
            value as any
          switch (filterType) {
            case 'text':
              type === 'dpciList'
                ? set(
                    partialFilters,
                    [key],
                    formatDpcis(filter.toString().replace(/ /g, '').split(',')),
                  )
                : key === 'project_sign_info_list.sap_project_id'
                ? set(includeFilters, [key], [filter])
                : set(
                    partialFilters,
                    [key],
                    formatTextValues(filter.toString().split(',')),
                  )
              break
            case 'number':
              set(includeFilters, [key], [filter?.toString()])
              break
            case 'date':
              set(rangeFilters, [key], {
                from: dateFrom.substring(0, 10),
                to: dateTo.substring(0, 10),
              })
              break
            case 'set':
              if (key === 'project_sign_info_list.sign_status') {
                values.length > 0 && set(includeFilters, [key], values)
              } else {
                set(
                  includeFilters,
                  key === 'non_retail_item_info.department'
                    ? [
                        'non_retail_item_info.department.department_display_name',
                      ]
                    : [key],
                  values,
                )
              }
              break
            default:
              break
          }
        }
      }
      return new SearchRequest<SignLibrarySearchFacets>({
        ...signImportSearchRequest,
        include_fields: [
          'project_sign_info_list.sign_status',
          'project_sign_info_list.sign_id',
        ],
        sort_fields: sortFields,
        search_after: searchAfter,
        partial_filters: isEmpty(partialFilters)
          ? signImportSearchRequest.partial_filters
          : partialFilters,
        include_filters: isEmpty(includeFilters)
          ? signImportSearchRequest.include_filters
          : includeFilters,
        exclude_filters: {
          'non_retail_item_info.dpci': DPCIList,
        },
        range_filters: isEmpty(rangeFilters)
          ? signImportSearchRequest.range_filters
          : rangeFilters,
      })
    },
    [DPCIList, signImportSearchRequest],
  )

  const signImportSSRMServer = useCallback(() => {
    return {
      getData: async (params: IServerSideGetRowsParams) => {
        const pidFilter = params.api
          .getFilterInstance('project_sign_info_list.sap_project_id')
          ?.getModel()?.filter
        const searchRequest = buildSearchReq(params)
        try {
          const res: any = await Axios.post(
            `${
              env.apiDomainUrl + SIGN_SERVICE_API_DOMAIN_URL
            }/sign_library/search?per_page=${SEARCH_RESPONSE_SIZE}`,
            searchRequest,
            HEADER_OBJECT,
          )
          setSignImportSearchRequest(searchRequest)
          return {
            success: true,
            data: res.data,
            rows: res.data.search.map(
              (searchResult: SearchResult<SignLibrarySearchFacets>) =>
                new SignLibraryResponse({
                  ...searchResult.result,
                  pidFilter: pidFilter ? pidFilter.toUpperCase() : undefined,
                }),
            ),
          }
        } catch (err: any) {
          makeToast({
            ...TOASTER_DEFAULTS,
            type: 'error',
            heading: 'Failed to Search Signs',
            message: err.response.data.message,
          })
        }
      },
    }
  }, [buildSearchReq, env.apiDomainUrl, setSignImportSearchRequest, makeToast])

  return (
    <SignImportSSRMServerContext.Provider value={{ signImportSSRMServer }}>
      {children}
    </SignImportSSRMServerContext.Provider>
  )
}

export const SignImportSSRMServerProvider = connect(
  null,
  null,
)(SignImportSSRMServerComponent)

export const useSignImportSSRMServerContext = () =>
  useContext(SignImportSSRMServerContext)
