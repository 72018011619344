import React, { Fragment, useEffect } from 'react'
import {
  Button,
  Chip,
  Form,
  Grid,
  Heading,
  Card,
  ExpandableSection,
  Divider,
  Input,
} from '@enterprise-ui/canvas-ui-react'
import ChildCampaignCard from './ChildCampaignCard'
import { useAdminCampaignsContext } from '../context/adminCampaignsContext'
import { useAppContext } from '../../../App/context/appContext'
import { CampaignTypeResponse } from '../../../../models/campaigns/CampaignTypeResponse.model'
import { ChildCampaignType } from '../../../../models/campaigns/ChildCampaignType.model'
import { DialogProps } from '../../../../models/app/DialogProps.model'

const CampaignGroupForm = () => {
  const { setPageHasChanges, setDialogProps } = useAppContext()!
  const {
    pyramidList,
    parentCampaignList,
    getParentCampaignList,
    deleteNewCampaign,
    availableChildCampaignList,
    getAvailableChildCampaignList,
    newCampaign,
    setNewCampaignGroup,
    addNewCampaign,
  } = useAdminCampaignsContext()!

  useEffect(() => {
    const pyramidIds = newCampaign.pyramids.map(
      (pyramid: any) => pyramid.group_id,
    )
    getAvailableChildCampaignList(pyramidIds)
  }, [getAvailableChildCampaignList, newCampaign.pyramids])

  useEffect(() => {
    getParentCampaignList()
  }, [getParentCampaignList])

  const isDuplicate = parentCampaignList.find(
    (parentCampaign: CampaignTypeResponse) =>
      parentCampaign.name === newCampaign.name,
  )

  const filteredChildCampaigns =
    newCampaign.child_types.length === 0
      ? availableChildCampaignList
      : availableChildCampaignList.filter(
          (availableCampaignTypes: CampaignTypeResponse) =>
            !newCampaign.child_types.find(
              (child: CampaignTypeResponse) =>
                availableCampaignTypes.id === child.id,
            ),
        )

  return (
    <Fragment>
      <Grid.Container className="sem-ParentCampaignForm">
        <Grid.Item align="right" xs>
          <Button
            type="secondary"
            className="saveCampaign-Button"
            onClick={() =>
              setDialogProps(
                new DialogProps({
                  headingText: 'Are you sure you want to remove this campaign?',
                  approveButtonText: 'Yes',
                  onApprove: () => deleteNewCampaign(),
                  refuseButtonText: 'Cancel',
                  onRefuse: () => setDialogProps(new DialogProps()),
                }),
              )
            }
          >
            Delete Campaign Group
          </Button>
          <Button
            type="primary"
            className="saveCampaign-Button"
            onClick={() => {
              addNewCampaign(newCampaign)
              setPageHasChanges(false)
            }}
            disabled={isDuplicate !== undefined}
          >
            Save New Campaign Group
          </Button>
        </Grid.Item>
        <Grid.Item xs={12}>
          <div className="hc-pa-dense">
            <Grid.Container>
              <Grid.Item xs={5} className={'simple_error_messaging_enabled'}>
                <Form.Field
                  id="name"
                  label="Campaign Group Title"
                  value={newCampaign.name}
                  onChange={(e: any) => {
                    setNewCampaignGroup({
                      ...newCampaign,
                      name: e.target.value,
                    })
                  }}
                  error={isDuplicate !== undefined}
                  errorText="Duplicate Campaign Group Title"
                />
              </Grid.Item>
              <Grid.Item xs={5}>
                <Card className={'sem-CampaignPyramidCard'} elevation={0}>
                  <ExpandableSection padding="dense">
                    <Heading className="sem-RosterTitle hc-pr-normal" size={6}>
                      Pyramids
                    </Heading>
                    <ExpandableSection.Content>
                      <Divider />
                      <div className="hc-pa-normal">
                        <Input.Checkboxes
                          id="pyramid"
                          options={pyramidList}
                          onUpdate={(e: any, value: any) => {
                            if (value.includes(0)) {
                              let withoutZero = pyramidList
                                .filter((pyramid: any) =>
                                  value.find(
                                    (id: number) => pyramid.value === id,
                                  ),
                                )
                                .map((pyramid: any) => ({
                                  group_id: pyramid.value,
                                  group_name: pyramid.label,
                                }))
                              const firstPyramid: any = {
                                group_id: 0,
                                group_name: 'NON RETAIL',
                              }
                              const selectedPyramids =
                                withoutZero.concat(firstPyramid)
                              setNewCampaignGroup({
                                ...newCampaign,
                                pyramids: selectedPyramids,
                              })
                              const pyramidIds = newCampaign.pyramids.map(
                                (pyramid: any) => pyramid.group_id,
                              )
                              getAvailableChildCampaignList(pyramidIds)
                              setPageHasChanges(true)
                            } else {
                              let selectedPyramids = pyramidList
                                .filter((pyramid: any) =>
                                  value.find(
                                    (id: number) => pyramid.value === id,
                                  ),
                                )
                                .map((pyramid: any) => ({
                                  group_id: pyramid.value,
                                  group_name: pyramid.label,
                                }))
                              setNewCampaignGroup({
                                ...newCampaign,
                                pyramids: selectedPyramids,
                              })
                              const pyramidIds = newCampaign.pyramids.map(
                                (pyramid: any) => pyramid.group_id,
                              )
                              getAvailableChildCampaignList(pyramidIds)
                              setPageHasChanges(true)
                            }
                          }}
                          value={newCampaign.pyramids.map(
                            (pyramid: any) => pyramid.group_id,
                          )}
                        />
                      </div>
                    </ExpandableSection.Content>
                  </ExpandableSection>
                </Card>
              </Grid.Item>
            </Grid.Container>
          </div>
        </Grid.Item>
        <div className="hc-pa-normal">
          <Heading size={4} className="hc-clr-grey02">
            Campaign Types in Group:
          </Heading>
          {newCampaign.child_types &&
            newCampaign.child_types.map((childCampaign: ChildCampaignType) => (
              <Chip
                className="sem-RosterChip"
                onRequestDelete={() => {
                  const childCampaigns = newCampaign.child_types.filter(
                    (child) => child.id !== childCampaign.id,
                  )
                  setNewCampaignGroup({
                    ...newCampaign,
                    child_types: childCampaigns,
                  })
                  setPageHasChanges(true)
                }}
                size="dense"
                key={childCampaign.id}
              >
                {childCampaign.name}
              </Chip>
            ))}
        </div>
        <Grid.Container align="flex-end">
          <Grid.Item xs>
            <div className="hc-pa-normal button-Container">
              <Heading size={4} className="hc-clr-gresy02">
                Available Campaign Types to Add:
              </Heading>
            </div>
          </Grid.Item>
          <Grid.Item></Grid.Item>
          <Grid.Item></Grid.Item>
        </Grid.Container>
        <div className="hc-pa-normal card-Container">
          {filteredChildCampaigns &&
            filteredChildCampaigns.map(
              (childCampaign: CampaignTypeResponse) => (
                <ChildCampaignCard
                  campaign={childCampaign}
                  key={childCampaign.id}
                />
              ),
            )}
        </div>
      </Grid.Container>
    </Fragment>
  )
}

export default CampaignGroupForm
