import React from 'react'
import { Layout } from '@enterprise-ui/canvas-ui-react'
import PageChangesWrapper from '../App/components/PageChangesWrapper'
import Dashboard from './components/Dashboard'
import { AgGridDashboardProvider } from './context/agGridDashboardContext'
import { DashboardProvider } from './context/dashboardContext'

const DashboardContainer = (props: any) => (
  <PageChangesWrapper>
    <DashboardProvider>
      <AgGridDashboardProvider>
        <Layout.Body className="hc-pa-expanded" includeRail>
          <Dashboard {...props} />
        </Layout.Body>
      </AgGridDashboardProvider>
    </DashboardProvider>
  </PageChangesWrapper>
)

export default DashboardContainer
