import React from 'react'
import {
  Grid,
  Heading,
  Form,
  Divider,
  Card,
  Spinner,
} from '@enterprise-ui/canvas-ui-react'
import {
  BLUEPRINT_APPROVAL_LVL,
  BLUEPRINT_PROJECT_TYPE,
  BLUEPRINT_SIGN_VOLUME,
  BLUEPRINT_VERSION_COMPLEXITY,
} from '../constants/blueprintDetailsConstants'
import { useBlueprintDetailsTabContext } from '../context/blueprintDetailsTabContext'
import { useUserContext } from 'components/App/context/userContext'
import { useBlueprintDetailsContainerContext } from '../../../context/blueprintDetailsContainerContext'
import { BlueprintRequest } from '../../../../../models/blueprints/BlueprintRequest.model'
import { useAppContext } from 'components/App/context/appContext'

export interface Props {
  values: BlueprintRequest
  errors: any
  setFieldValue: Function
}

export const BlueprintFormCard = ({ values, errors, setFieldValue }: Props) => {
  const { setPageHasChanges } = useAppContext()!
  const { currentBlueprint, signProjects } =
    useBlueprintDetailsContainerContext()!
  const {
    isBlueprintModified,
    setIsBlueprintModified,
    isCampaignListLoading,
    campaignList,
    handleCampaignChange,
  } = useBlueprintDetailsTabContext()!
  const { userPermissions } = useUserContext()!

  return (
    <Card
      className={`hc-mt-expanded sem_BlueprintFormCard ${
        isBlueprintModified ? 'modified' : ''
      }`}
    >
      <div className="hc-pa-dense">
        <Grid.Container className="hc-mb-normal sem_StandardForm error_messaging_enabled">
          <Grid.Item xs={12}>
            <Heading className="hc-clr-grey02" size={5}>
              Blueprint Details & Campaign Type
            </Heading>
          </Grid.Item>
          <Divider className="hc-pa-dense" />
          <Grid.Item xs={5} className="hc-pv-dense">
            <Form.Field
              disabled={
                !userPermissions.isBlueprintEditor ||
                currentBlueprint.status === 'Archive'
              }
              id="name"
              data-testid="name"
              label="Blueprint Name"
              value={values.name === 'Create New Blueprint' ? '' : values.name}
              error={errors.name !== undefined}
              errorText={errors.name}
              onChange={(e: any) => {
                setFieldValue('name', e.target.value)
                setIsBlueprintModified(true)
                setPageHasChanges(true)
              }}
              required
            />
          </Grid.Item>
          <Grid.Item xs={3} className="hc-pv-dense">
            <Form.Field
              type="date"
              disabled={
                !userPermissions.isBlueprintEditor ||
                currentBlueprint.status === 'Archive'
              }
              id="set_date"
              label="Initial Set Date*"
              value={values.set_date}
              error={errors.set_date !== undefined && values.set_date !== null}
              errorText={errors.set_date}
              onChange={(event: any) => {
                setFieldValue('set_date', event.target.value)
                if (currentBlueprint.set_date !== event.target.value) {
                  setIsBlueprintModified(true)
                  setPageHasChanges(true)
                }
              }}
              location="bottom-left"
            />
          </Grid.Item>
          <Grid.Item xs={4} className="hc-pv-dense">
            {isCampaignListLoading ? (
              <Grid.Container algin="center" justify="center">
                <Grid.Item>
                  <Spinner />
                </Grid.Item>
              </Grid.Container>
            ) : (
              <Form.Field
                disabled={
                  !userPermissions.isBlueprintEditor ||
                  signProjects.length > 0 ||
                  currentBlueprint.status === 'Archive'
                }
                type="select"
                id="campaign_type"
                data-testid="campaignType"
                label="Campaign Type"
                value={values.campaign ? values.campaign.id : ''}
                error={errors.campaign && errors.campaign.id !== undefined}
                errorText={errors.campaign ? errors.campaign.id : ''}
                onUpdate={(e: any, campaignId: string) => {
                  handleCampaignChange(campaignId, values)
                  setIsBlueprintModified(true)
                  setPageHasChanges(true)
                }}
                options={campaignList.map((campaign) => ({
                  value: campaign.id,
                  label: campaign.name,
                }))}
                required
              />
            )}
          </Grid.Item>
          <Grid.Item xs={3} className="hc-pv-dense">
            <Form.Field
              disabled={
                !userPermissions.isBlueprintEditor ||
                currentBlueprint.status === 'Archive'
              }
              type="select"
              id="type"
              data-testid="type"
              label="Blueprint Type"
              value={values.type}
              error={errors.type !== undefined}
              errorText={errors.type}
              onUpdate={(e: any, value: string) => {
                setFieldValue('type', value)
                setIsBlueprintModified(true)
                setPageHasChanges(true)
              }}
              options={BLUEPRINT_PROJECT_TYPE}
              required
            />
          </Grid.Item>
          <Grid.Item className="hc-pv-dense sem_FormChips hc-mt-normal">
            <Form.Field
              className="hc-mr-normal"
              disabled={
                !userPermissions.isBlueprintEditor ||
                currentBlueprint.status === 'Archive'
              }
              type="checkbox"
              id="is_tier_one"
              data-testid="tierOne"
              label="Tier 1 Project"
              checked={values.is_tier_one}
              onChange={(e: any) => {
                setFieldValue('is_tier_one', e.target.checked)
                setIsBlueprintModified(true)
                setPageHasChanges(true)
              }}
            />
            <Form.Field
              disabled={
                !userPermissions.isBlueprintEditor ||
                currentBlueprint.status === 'Archive'
              }
              type="checkbox"
              id="is_incremental"
              data-testid="incremental"
              label="Incremental Project"
              checked={values.is_incremental}
              onChange={(e: any) => {
                setFieldValue('is_incremental', e.target.checked)
                setIsBlueprintModified(true)
                setPageHasChanges(true)
              }}
            />
          </Grid.Item>
        </Grid.Container>
      </div>
      <div className="hc-pa-dense">
        <Grid.Container className="hc-mb-normal">
          <Grid.Item xs={12}>
            <Heading className="hc-clr-grey02" size={5}>
              SEM Operational Inputs
            </Heading>
          </Grid.Item>
          <Divider className="hc-pa-dense" />
          <Grid.Item xs={3} className="hc-pv-dense">
            <Form.Field
              disabled={
                !userPermissions.isBlueprintEditor ||
                currentBlueprint.status === 'Archive'
              }
              type="select"
              id="sign_volume_estimate"
              data-testid="signVolumeEstimate"
              label="Estimated Sign Volume"
              value={values.sign_volume_estimate}
              error={errors.sign_volume_estimate !== undefined}
              errorText={errors.sign_volume_estimate}
              onUpdate={(e: any, value: string) => {
                setFieldValue('sign_volume_estimate', value)
                setIsBlueprintModified(true)
                setPageHasChanges(true)
              }}
              options={BLUEPRINT_SIGN_VOLUME}
              required
            />
          </Grid.Item>
          <Grid.Item xs={5} className="hc-pv-dense">
            <Form.Field
              disabled={
                !userPermissions.isBlueprintEditor ||
                currentBlueprint.status === 'Archive'
              }
              type="select"
              id="version_complexity"
              data-testid="versionComplexity"
              label="Version Complexity"
              value={values.version_complexity}
              error={errors.version_complexity !== undefined}
              errorText={errors.version_complexity}
              onUpdate={(e: any, value: string) => {
                setFieldValue('version_complexity', value)
                setIsBlueprintModified(true)
                setPageHasChanges(true)
              }}
              options={BLUEPRINT_VERSION_COMPLEXITY}
              required
            />
          </Grid.Item>
          <Grid.Item xs={4} className="hc-pv-dense">
            <Form.Field
              disabled={
                !userPermissions.isBlueprintEditor ||
                currentBlueprint.status === 'Archive'
              }
              type="select"
              id="creative_approval_level"
              data-testid="bpApprovalLvl"
              label="Creative Approval Level (Highest)"
              value={values.creative_approval_level}
              error={errors.creative_approval_level !== undefined}
              errorText={errors.creative_approval_level}
              onUpdate={(e: any, value: string) => {
                setFieldValue('creative_approval_level', value)
                setIsBlueprintModified(true)
                setPageHasChanges(true)
              }}
              options={BLUEPRINT_APPROVAL_LVL}
              required
            />
          </Grid.Item>
        </Grid.Container>
      </div>
    </Card>
  )
}

export default BlueprintFormCard
