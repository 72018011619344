import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { Division } from '../../../../../../../models/merchandise/hierarchy/Division.model'
import { useBlueprintProjectListContext } from '../../../context/blueprintProjectListContext'
import { DropdownOption } from '../../../../../../../models/app/DropdownOption.model'
import { Grid } from '@enterprise-ui/canvas-ui-react'

export interface Props {
  value: Division[]
}

const DivisionsCellEditor = forwardRef(({ value }: Props, ref) => {
  const { divisionOptions } = useBlueprintProjectListContext()!
  const [editValue, setEditValue] = useState(value)

  const handleChange = (id: string, value: any) =>
    setEditValue(value.map((option: DropdownOption<Division>) => option.value))

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return editValue
      },
      isPopup() {
        return true
      },
    }
  })

  return (
    <div className="hc-pa-dense">
      <Grid.Container className="sem_StandardForm error_messaging_enabled">
        <Grid.Item xs={12}>
          <Autocomplete
            label="Project Division(s)"
            optionHeight="dense"
            multiselect
            value={editValue.map(
              (division: Division) =>
                new DropdownOption<Division>({
                  id: division.division_id,
                  value: division,
                  label: division.division_display_name,
                }),
            )}
            options={divisionOptions.map(
              (division: Division) =>
                new DropdownOption<Division>({
                  id: division.division_id,
                  value: division,
                  label: division.division_display_name,
                }),
            )}
            onUpdate={(id: string, value: any) => {
              if (value) handleChange(id, value)
            }}
          />
        </Grid.Item>
      </Grid.Container>
    </div>
  )
})

export default DivisionsCellEditor
