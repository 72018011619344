import React, { Fragment, useState } from 'react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { Button } from '@enterprise-ui/canvas-ui-react'
import { useProjectMilestoneTabContext } from '../context/projectMilestoneTabContext'
import { MilestoneTemplate } from '../../../../../models/milestones/MilestoneTemplate.model'

export const AddProjectMilestoneForm = () => {
  const {
    projectMilestone,
    milestonesInProjectPhase,
    addMilestones,
    closeMilestoneForm,
  } = useProjectMilestoneTabContext()!
  const [selectedMilestoneIds, setSelectedMilestoneIds] = useState<string[]>([])
  const [, setFilteredMilestoneIds] = useState<string[]>([])

  const milestoneOptions = milestonesInProjectPhase
    .filter(
      (milestone: MilestoneTemplate) =>
        !projectMilestone.find(({ name }) => milestone.name === name),
    )
    .map((milestone: MilestoneTemplate) => ({
      id: milestone.milestone_id,
      value: milestone.milestone_id,
      label: milestone.name,
    }))

  return (
    <Fragment>
      <Button
        fullWidth
        type="primary"
        className="addMilestone-Button"
        data-testid="addMilestones"
        onClick={() => {
          addMilestones(selectedMilestoneIds)
          setSelectedMilestoneIds([])
          setFilteredMilestoneIds([])
          closeMilestoneForm()
        }}
      >
        Click here to Add Milestones
      </Button>
      <Autocomplete
        id="add_milestone"
        label="Choose a Milestone(s) to add to this schedule"
        options={milestoneOptions}
        value={milestonesInProjectPhase
          .filter((milestoneTemplate: MilestoneTemplate) =>
            selectedMilestoneIds.includes(milestoneTemplate.milestone_id),
          )
          .map((milestone: MilestoneTemplate) => ({
            id: milestone.milestone_id,
            value: milestone.milestone_id,
            label: milestone.name,
          }))}
        optionHeight="dense"
        multiselect
        onUpdate={(event: any, value: any) => {
          let selectedIds = value.map((option: any) => option.id)
          setSelectedMilestoneIds(value.map((option: any) => option.id))
          if (value.length > 0) {
            setFilteredMilestoneIds(
              milestonesInProjectPhase
                .filter(
                  (milestone: MilestoneTemplate) =>
                    !selectedIds.includes(milestone.milestone_id),
                )
                .map((milestone: MilestoneTemplate) => milestone.milestone_id),
            )
          }
        }}
      />
    </Fragment>
  )
}

export default AddProjectMilestoneForm
