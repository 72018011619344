import React, { Fragment, useState } from 'react'
import {
  Button,
  Caption,
  Card,
  Chip,
  Divider,
  Form,
  Grid,
  GridItem,
  Heading,
  Spinner,
  Tooltip,
} from '@enterprise-ui/canvas-ui-react'
import { useProjectDistributionContext } from '../context/projectDistributionContext'
import { KitStatus } from '../../../../../models/kits/KitResponse.model'
import { useKitDetailsForm } from '../hooks/useKitDetailsForm'
import { SHIP_METHODS } from '../constants/KitInfoConstants'
import AgGridProjectKitSignsView from './AgGridProjectKitSignsView'
import EnterpriseIcon, { InfoIcon } from '@enterprise-ui/icons'
import ProjectKitStoresView from './ProjectKitStoresView'
import { useUserContext } from 'components/App/context/userContext'
import { DialogProps } from '../../../../../models/app/DialogProps.model'
import { useAppContext } from 'components/App/context/appContext'
import RubixChip from 'components/common/RubixChip'

const ProjectKitDetailsForm = () => {
  const { setDialogProps } = useAppContext()!
  const { kit, updateKit, isKitLoading, kitInfoResponse, signs } =
    useProjectDistributionContext()!

  const { userPermissions } = useUserContext()!
  const { handleSubmit, setFieldValue, values } = useKitDetailsForm(
    kit,
    kitInfoResponse.id,
    updateKit,
  )

  const handleChange = (id: string, value: any) => {
    setFieldValue(id, value)
  }

  const [showStores, setShowStores] = useState(false)

  if (isKitLoading) {
    return (
      <Grid.Container
        algin="center"
        justify="center"
        className="hc-pv-normal hc-pt-expanded"
      >
        <Grid.Item className="hc-pt-dense">
          <Spinner />
        </Grid.Item>
      </Grid.Container>
    )
  } else if (kit.kit_id === undefined) {
    return (
      <Fragment>
        <Grid.Container
          align="flex-end"
          spacing="dense"
          className="hc-pv-normal hc-pt-expanded"
        >
          <Grid.Item xs={true} className="hc-pt-dense">
            <Card className="sem-KitCard hc-pa-normal">
              <Grid.Container justify="space-between" align="center">
                <Grid.Item xs={12} className="hc-pa-normal">
                  <Heading size={6} className="hc-ta-center">
                    Select a Kit to view details
                  </Heading>
                </Grid.Item>
              </Grid.Container>
            </Card>
          </Grid.Item>
        </Grid.Container>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <Grid.Container
        align="flex-end"
        spacing="dense"
        className="hc-pv-normal hc-pt-expanded"
      >
        <Grid.Item xs={true} className="hc-pt-dense">
          <Card className="sem-KitCard hc-pa-normal">
            <Grid.Container justify="space-between" align="center">
              <Grid.Item xs={true} className="hc-pl-expanded">
                <Heading size={4}>{kit.kit_id}</Heading>
              </Grid.Item>
            </Grid.Container>
            <Divider className="hc-pa-dense" />
            <Grid.Container justify="space-between" align="center">
              <Grid.Item>
                <Caption uppercase above="Kit Id">
                  <Chip size="dense">{kit.kit_id}</Chip>
                </Caption>
              </Grid.Item>
              <Grid.Item>
                <Caption uppercase above="Kit Vendor">
                  <Chip size="dense">{kit.kit_vendor}</Chip>
                </Caption>
              </Grid.Item>
              <Grid.Item>
                <Caption
                  uppercase
                  above={
                    <Fragment>
                      Store Count
                      <Tooltip
                        location="top-left"
                        content="Click to Show Stores"
                      >
                        <Button
                          aria-label="Click to Show Stores"
                          type="ghost"
                          size="dense"
                          iconOnly
                          className="hc-pt-none"
                          onClick={() => {
                            setShowStores(true)
                          }}
                        >
                          <EnterpriseIcon
                            size="sm"
                            icon={InfoIcon}
                            color="grey"
                          />
                        </Button>
                      </Tooltip>
                    </Fragment>
                  }
                >
                  <Chip size="dense">{kit.stores?.length}</Chip>
                </Caption>
              </Grid.Item>
              <Grid.Item>
                <Caption uppercase above="Sign Count">
                  <Chip size="dense">{kit.signs?.length}</Chip>
                </Caption>
              </Grid.Item>
              <Grid.Item>
                <Caption
                  uppercase
                  above="Kit Finalized?"
                  className="hc-pt-normal"
                >
                  {values.status === KitStatus.FINALIZED && (
                    <RubixChip color="green">YES</RubixChip>
                  )}
                  {values.status !== KitStatus.FINALIZED && (
                    <Chip size="dense">NO</Chip>
                  )}
                </Caption>
              </Grid.Item>
            </Grid.Container>
            <Grid.Container>
              <Grid.Item xs={2}>
                <Grid.Container justify="space-between" align="center">
                  <Grid.Item xs={12}>
                    <Form.Field
                      className="hc-mt-none"
                      id="kit_quantity"
                      disabled={!userPermissions.isKitEditor}
                      type="number"
                      label="Kit Quantity"
                      placeholder="Enter your comments here..."
                      value={values.kit_quantity}
                      onChange={(e: any) => {
                        handleChange('kit_quantity', +e.target.value)
                      }}
                    />
                  </Grid.Item>
                  <Grid.Item xs={12}>
                    <Form.Field
                      className="hc-mt-none"
                      id="shipping_method"
                      disabled={!userPermissions.isKitEditor}
                      type="select"
                      label="Shipping Method"
                      options={SHIP_METHODS}
                      value={values.shipping_method}
                      onUpdate={(e: any, value: any) => {
                        handleChange('shipping_method', value)
                      }}
                    />
                  </Grid.Item>
                </Grid.Container>
              </Grid.Item>

              <Grid.Item xs={4}>
                <Grid.Container justify="space-between" align="center">
                  <Grid.Item xs={6}>
                    <Form.Field
                      className="hc-mt-none"
                      id="carton.length"
                      disabled={!userPermissions.isKitEditor}
                      type="number"
                      label="Carton Length (in.)"
                      value={values.carton?.length}
                      onChange={(e: any) => {
                        handleChange('carton.length', +e.target.value)
                      }}
                    />
                  </Grid.Item>
                  <Grid.Item xs={6}>
                    <Form.Field
                      className="hc-mt-none"
                      id="carton.width"
                      disabled={!userPermissions.isKitEditor}
                      type="number"
                      label="Carton Width  (in.)"
                      value={values.carton?.width}
                      onChange={(e: any) => {
                        handleChange('carton.width', +e.target.value)
                      }}
                    />
                  </Grid.Item>
                  <Grid.Item xs={6}>
                    <Form.Field
                      className="hc-mt-none"
                      id="carton.height"
                      disabled={!userPermissions.isKitEditor}
                      type="number"
                      label="Carton Height (in.)"
                      value={values.carton?.height}
                      onChange={(e: any) => {
                        handleChange('carton.height', +e.target.value)
                      }}
                    />
                  </Grid.Item>
                  <Grid.Item xs={6}>
                    <Form.Field
                      className="hc-mt-none"
                      id="carton.height"
                      disabled={!userPermissions.isKitEditor}
                      type="number"
                      label="Carton Weight (lbs.)"
                      value={values.carton?.weight}
                      onChange={(e: any) => {
                        handleChange('carton.weight', +e.target.value)
                      }}
                    />
                  </Grid.Item>
                </Grid.Container>
              </Grid.Item>
              <Grid.Item xs={3}>
                <Form.Field
                  className="hc-mt-none"
                  id="comments.kit_comment"
                  disabled={!userPermissions.isKitEditor}
                  type="textarea"
                  label="Kit Comments"
                  placeholder="Enter your comments here..."
                  value={values.comments?.kit_comment}
                  onChange={(e: any) => {
                    handleChange('comments.kit_comment', e.target.value)
                  }}
                />
              </Grid.Item>
              <Grid.Item xs={3}>
                <Form.Field
                  className="hc-mt-none"
                  id="comments.ship_comment"
                  disabled={!userPermissions.isKitEditor}
                  type="textarea"
                  label="Ship Comments"
                  placeholder="Enter your comments here..."
                  value={values.comments?.ship_comment}
                  onChange={(e: any) => {
                    handleChange('comments.ship_comment', e.target.value)
                  }}
                />
              </Grid.Item>
            </Grid.Container>
            <Grid.Container className="hc-pb-expanded">
              <Grid.Item>
                <Form.Field
                  id={`oversized_${kit?.kit_id}`}
                  disabled={!userPermissions.isKitEditor}
                  label="Over Sized"
                  type="checkbox"
                  defaultChecked={values.oversized}
                  onChange={(e: any) => {
                    const checked: boolean = e.target.checked
                    handleChange('oversized', checked)
                  }}
                />
              </Grid.Item>
              <Grid.Item>
                <Form.Field
                  id={`pre_assembled${kit?.kit_id}`}
                  disabled={!userPermissions.isKitEditor}
                  label="Pre-Assembled"
                  type="checkbox"
                  defaultChecked={values.pre_assembled}
                  onChange={(e: any) => {
                    const checked: boolean = e.target.checked
                    handleChange('pre_assembled', checked)
                  }}
                />
              </Grid.Item>

              <GridItem xs={true}></GridItem>
              {userPermissions.isKitEditor && (
                <Grid.Item xs={3}>
                  <Button
                    type="primary"
                    fullWidth
                    onClick={() => {
                      //handleSubmit()
                      if (
                        kitInfoResponse.kits_finalized &&
                        kit.status === KitStatus.FINALIZED
                      ) {
                        setDialogProps(
                          new DialogProps({
                            headingText:
                              'This action will undo kit finalization. Do you want to continue?',
                            approveButtonText: 'Yes',
                            onApprove: () => {
                              handleSubmit()
                              setDialogProps(new DialogProps())
                            },
                            refuseButtonText: 'Cancel',
                            onRefuse: () => setDialogProps(new DialogProps()),
                          }),
                        )
                      } else {
                        handleSubmit()
                      }
                    }}
                  >
                    Save Kit
                  </Button>
                </Grid.Item>
              )}
            </Grid.Container>
            {kit.signs && kit.signs.length > 0 && (
              <AgGridProjectKitSignsView
                kit={kit}
                signs={signs}
                values={values}
                setFieldValue={setFieldValue}
              />
            )}
          </Card>
        </Grid.Item>
      </Grid.Container>
      <ProjectKitStoresView
        stores={kit.stores}
        isShowStoresOpen={showStores}
        setShowStoresOpen={setShowStores}
      />
    </Fragment>
  )
}

export default ProjectKitDetailsForm
