import React from 'react'
import { Layout } from '@enterprise-ui/canvas-ui-react'
import PageChangesWrapper from '../App/components/PageChangesWrapper'
import ProjectSearch from './components/ProjectSearch'
import { ProjectFilterProvider } from './context/projectFilterContext'
import { ProjectListProvider } from './context/projectListContext'

const ProjectList = (props: any) => (
  <PageChangesWrapper>
    <ProjectListProvider>
      <ProjectFilterProvider>
        <Layout.Body className="hc-pa-expanded" includeRail>
          <ProjectSearch {...props} />
        </Layout.Body>
      </ProjectFilterProvider>
    </ProjectListProvider>
  </PageChangesWrapper>
)

export default ProjectList
