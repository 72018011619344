import React from 'react'
import { Button } from '@enterprise-ui/canvas-ui-react'
import { useNavigate } from 'react-router-dom'
import EnterpriseIcon, { ExternalIcon } from '@enterprise-ui/icons'

export interface Props {
  value: string
}

const IconLink = ({ value }: Props) => {
  const navigate = useNavigate()
  return (
    <Button type="ghost" iconOnly onClick={() => navigate(value)}>
      <EnterpriseIcon size="sm" icon={ExternalIcon} />
    </Button>
  )
}

export default IconLink
