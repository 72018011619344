import React, { Fragment } from 'react'
import { Layout } from '@enterprise-ui/canvas-ui-react'
import MainSectionHeader from '../../App/components/MainSectionHeader'
import NotificationsTable from './NotificationsTable'

const Notifications = () => {
  return (
    <Fragment>
      <Layout.SectionHeader className="hc-ph-expanded hc-pt-expanded hc-pb-none">
        <MainSectionHeader sectionTitle="Notifications Center" notifications />
      </Layout.SectionHeader>
      <Layout.Body
        className="hc-pl-expanded hc-pr-expanded hc-pt-none"
        includeRail
      >
        <NotificationsTable />
      </Layout.Body>
    </Fragment>
  )
}

export default Notifications
