export default class PrintingResponse {
  coating?: string
  die_cut?: string
  finishing?: string
  number_of_colors?: number
  sides_print: boolean
  planned_sides?: number
  pre_assembled: boolean
  file_approved: boolean
  print_vendor?: string
  print_vendor_id?: string
  substrate1?: string
  substrate2?: string
  is_created_add_on_expenses?: Boolean
  is_vendor_has_contract?: Boolean

  constructor(props: any = {}) {
    this.coating = props.coating
    this.die_cut = props.die_cut
    this.finishing = props.finishing
    this.number_of_colors = props.number_of_colors
    this.sides_print = props.sides_print || false
    this.planned_sides = props.planned_sides
    this.pre_assembled = props.pre_assembled || false
    this.file_approved = props.file_approved || false
    this.print_vendor = props.print_vendor || ''
    this.print_vendor_id = props.print_vendor_id || ''
    this.substrate1 = props.substrate1
    this.substrate2 = props.substrate2
    this.is_created_add_on_expenses = props.is_created_add_on_expenses
    this.is_vendor_has_contract = props.is_vendor_has_contract || false
  }
}
