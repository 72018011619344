import React, { createContext, useContext, useState } from 'react'
import { UserInformation } from '../../../models/app/UserInfo.model'
import { UserPermissions } from '../../../models/app/UserPermissions.model'
import { UserType } from '../constants/appConstants'

type ContextType = {
  userInfo: UserInformation
  setUserInfo: (info: UserInformation) => void
  /* === Old ContextType - keep until all user info components are refactored === */
  userPermissions: UserPermissions
  setUserPermissions: (permissions: UserPermissions) => void
  userName: string
  setUserName: (name: string) => void
  userId: string
  setUserId: (id: string) => void
  userEmail: string
  setUserEmail: (email: string) => void
  userCompany: string
  setUserCompany: (company: string) => void
  userType: UserType
  setUserType: (type: UserType) => void
}

export const UserContext = createContext<ContextType | undefined>(undefined)

type Props = { children: React.ReactNode }

export const UserProvider = ({ children }: Props) => {
  const [userInfo, setUserInfo] = useState<UserInformation>(
    new UserInformation(),
  )
  /* === Old State - keep until all user info components are refactored === */
  const [userPermissions, setUserPermissions] = useState(new UserPermissions())
  const [userName, setUserName] = useState('')
  const [userId, setUserId] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const [userCompany, setUserCompany] = useState('')
  const [userType, setUserType] = useState(UserType.INTERNAL)

  return (
    <UserContext.Provider
      value={{
        userInfo,
        setUserInfo,
        /* === Old Context.Provider value - keep until all user info components are refactored === */
        userPermissions,
        setUserPermissions,
        userName,
        setUserName,
        userId,
        setUserId,
        userEmail,
        setUserEmail,
        userCompany,
        setUserCompany,
        userType,
        setUserType,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export const useUserContext = () => useContext(UserContext)
