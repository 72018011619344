import React from 'react'
import { Link } from 'react-router-dom'
import {
  Layout,
  Grid,
  Breadcrumbs,
  Heading,
  Divider,
  Chip,
  Button,
  Anchor,
  Tooltip,
  Badge,
  Popover,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, {
  CautionIcon,
  CheckCircleIcon,
  ErrorIcon,
  GearIcon,
  InfoIcon,
  NotebookIcon,
  PencilIcon,
} from '@enterprise-ui/icons'
import { NOTES_BUTTON } from '../constants/appConstants'
import { useBlueprintNotesContext } from '../../BlueprintDetails/components/BlueprintNotes/context/blueprintNotesContext'
import { useProjectNotesContext } from '../../ProjectDetails/components/ProjectNotes/context/projectNotesContext'
import { useSignNotesContext } from '../../SignDetails/components/SignNotes/context/signNotesContext'
import { useProjectDetailsContext } from '../../ProjectDetails/context/projectDetailsContext'
import { useBlueprintDetailsContainerContext } from '../../BlueprintDetails/context/blueprintDetailsContainerContext'
import NotificationsSettings from '../../Notifications/components/NotificationsSettings'
import { useUserContext } from '../context/userContext'
import { UserType } from '../constants/appConstants'
import NotificationSettingsResponse from '../../../models/notifications/NotificationSettingsResponse.model'

export interface Props {
  sectionTitle: string
  sectionEditLink?: string
  subheading?: string
  crumbs?: any | null
  projectHealth?: string | null
  pagination?: any | null
  isModal?: boolean
  closeModal?: Function
  notesButton?: NOTES_BUTTON | null
  notifications?: boolean
  notificationSettings?: NotificationSettingsResponse
}

const MainSectionHeader = ({
  sectionTitle = 'Section Title',
  sectionEditLink = '',
  subheading = '',
  crumbs = null,
  projectHealth = null,
  pagination = null,
  isModal = false,
  closeModal,
  notesButton = null,
  notifications = false,
}: Props) => {
  const { userType } = useUserContext()!
  const blueprintNotesContext = useBlueprintNotesContext()!
  const projectNotesContext = useProjectNotesContext()!
  const signNotesContext = useSignNotesContext()!
  const projectDetailsContext = useProjectDetailsContext()!
  const blueprintDetailsContainerContext =
    useBlueprintDetailsContainerContext()!

  return (
    <Layout.SectionHeader>
      <Grid.Container>
        <Grid.Item xs={true} className="hc-ph-none hc-pt-none">
          <Breadcrumbs className="hc-ml-none">
            {crumbs?.map((crumb: any) => (
              <Breadcrumbs.Item key={crumb.name} href={crumb.to}>
                {crumb.name}
              </Breadcrumbs.Item>
            ))}
          </Breadcrumbs>
          <Grid.Container justify="space-between">
            <Grid.Item className="hc-pb-dense" xs={true}>
              <Heading size={2} className="hc-clr-contrast">
                {sectionTitle}
                <span className="sem-Subheading">{subheading}</span>
                {sectionEditLink !== undefined && sectionEditLink !== '' && (
                  <Tooltip
                    location="bottom"
                    content="Edit SAP Project Requests"
                  >
                    <Anchor
                      className="hc-mr-dense"
                      as={Link}
                      to={sectionEditLink}
                    >
                      <EnterpriseIcon icon={PencilIcon} />
                    </Anchor>
                  </Tooltip>
                )}
              </Heading>
            </Grid.Item>
            {projectHealth !== null ? (
              <Grid.Item>
                <Chip
                  className="sem_StatusChip"
                  dense
                  color={
                    projectHealth === 'On Track'
                      ? 'success'
                      : projectHealth === 'Past Due'
                      ? 'alert'
                      : projectHealth === 'At Risk'
                      ? 'error'
                      : 'default'
                  }
                >
                  <EnterpriseIcon
                    className="hc-mr-dense"
                    icon={
                      projectHealth === 'On Track'
                        ? CheckCircleIcon
                        : projectHealth === 'Past Due'
                        ? CautionIcon
                        : projectHealth === 'At Risk'
                        ? ErrorIcon
                        : InfoIcon
                    }
                  />
                  {projectHealth}
                </Chip>
              </Grid.Item>
            ) : null}
            {notifications !== false ? (
              <Grid.Item className="hc-pb-dense rbx-settings-popover">
                <Popover
                  content={<NotificationsSettings />}
                  type="clickOnly"
                  location="bottom-right"
                  width="xxl"
                >
                  <Button type="ghost" className="sem_IconBtn-dark" icon>
                    <EnterpriseIcon icon={GearIcon} size="md" />
                  </Button>
                </Popover>
              </Grid.Item>
            ) : null}
            {notesButton === NOTES_BUTTON.BLUEPRINT &&
            userType === UserType.INTERNAL ? (
              <Grid.Item className="hc-pb-dense">
                <Button
                  type="ghost"
                  className="hc-pl-min"
                  data-testid="toggleNotes"
                  onClick={() => {
                    blueprintNotesContext &&
                      blueprintNotesContext.setIsBlueprintNotesOpen(
                        (previousState: boolean) => !previousState,
                      )
                  }}
                >
                  <EnterpriseIcon
                    className="hc-pr-dense"
                    icon={NotebookIcon}
                    size="lg"
                  />
                  Blueprint Notes
                  {blueprintDetailsContainerContext &&
                  blueprintDetailsContainerContext.currentBlueprint.notes
                    .length > 0 ? (
                    <Badge
                      className="hc-pr-normal"
                      content={
                        blueprintDetailsContainerContext &&
                        blueprintDetailsContainerContext.currentBlueprint.notes.length.toString()
                      }
                      location="right"
                      aria-label={
                        blueprintDetailsContainerContext.currentBlueprint.notes
                          .length > 1
                          ? `${
                              blueprintDetailsContainerContext &&
                              blueprintDetailsContainerContext.currentBlueprint.notes.length.toString()
                            } Blueprint Notes`
                          : `${
                              blueprintDetailsContainerContext &&
                              blueprintDetailsContainerContext.currentBlueprint.notes.length.toString()
                            } Blueprint Note`
                      }
                    ></Badge>
                  ) : null}
                </Button>
              </Grid.Item>
            ) : notesButton === NOTES_BUTTON.PROJECT &&
              userType === UserType.INTERNAL ? (
              <Grid.Item className="hc-pb-dense">
                <Button
                  type="ghost"
                  data-testid="toggleNotes"
                  onClick={() =>
                    projectNotesContext &&
                    projectNotesContext.setIsProjectNotesOpen(
                      (previousState: boolean) => !previousState,
                    )
                  }
                >
                  <EnterpriseIcon
                    className="hc-pr-dense"
                    icon={NotebookIcon}
                    size="lg"
                  />
                  Project Notes
                  {projectDetailsContext &&
                  projectDetailsContext.currentProject.notes.length > 0 ? (
                    <Badge
                      className="hc-pr-normal"
                      content={
                        projectDetailsContext &&
                        projectDetailsContext.currentProject.notes.length.toString()
                      }
                      location="right"
                      aria-label={
                        projectDetailsContext.currentProject.notes.length > 1
                          ? `${
                              projectDetailsContext &&
                              projectDetailsContext.currentProject.notes.length.toString()
                            } Project Notes`
                          : `${
                              projectDetailsContext &&
                              projectDetailsContext.currentProject.notes.length.toString()
                            } Project Note`
                      }
                    ></Badge>
                  ) : null}
                </Button>
              </Grid.Item>
            ) : notesButton === NOTES_BUTTON.SIGN ? (
              <Grid.Item className="hc-pb-dense">
                <Button
                  type="ghost"
                  data-testid="toggleNotes"
                  onClick={() =>
                    signNotesContext &&
                    signNotesContext.setIsSignNotesOpen(
                      (previousState: boolean) => !previousState,
                    )
                  }
                >
                  <EnterpriseIcon
                    className="hc-pr-dense"
                    icon={NotebookIcon}
                    size="lg"
                  />
                  Sign Notes
                </Button>
              </Grid.Item>
            ) : null}
            {pagination !== null ? (
              <Grid.Item className="hc-pb-dense"></Grid.Item>
            ) : null}
            <Grid.Item className="hc-pt-none" xs={12}>
              <Divider />
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
      </Grid.Container>
    </Layout.SectionHeader>
  )
}

export default MainSectionHeader
