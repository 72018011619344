export class ProjectSearchFacets {
  [index: string]: any
  pyramid: string
  division: string
  department: string
  type: string
  tactic: string
  campaignType: string

  constructor(props: any = {}) {
    this.division = props.division || 'divisions.division_display_name'
    this.department = props.department || 'departments.department_display_name'
    this.pyramid = props.pyramid || 'divisions.group_display_name'
    this.type = props.type || 'project_type'
    this.tactic = props.tactic || 'tactic'
    this.campaignType = props.campaignType || 'campaign.name'
  }
}
