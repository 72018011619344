import React, { useMemo } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { SelectionChangedEvent } from 'ag-grid-community'
import { useAgGridAddOnContext } from 'components/ProjectDetails/components/AddOnExpenses/context/agGridAddOnContext'
import { useAddOnContext } from 'components/ProjectDetails/components/AddOnExpenses/context/addOnContext'
import { useUserContext } from 'components/App/context/userContext'

interface Props {
  colDefs: any[]
}

const AddOnExpensesTable = ({ colDefs }: Props) => {
  const { onGridReady, gridOptions, onCellValueChange, isAllExpenseSubmitted } =
    useAgGridAddOnContext()!
  const { addOnExpenses, isCreatingExpense, onSelectionChange } =
    useAddOnContext()!
  const { userPermissions } = useUserContext()!

  const context = useMemo(() => {
    return {
      isCreatingExpense: isCreatingExpense,
      userPermissions: userPermissions,
      isAllExpenseSubmitted: isAllExpenseSubmitted,
    }
  }, [isCreatingExpense, userPermissions, isAllExpenseSubmitted])

  return (
    <div className="ag-theme-rubix add-on-expenses-table">
      <AgGridReact
        rowData={addOnExpenses}
        onGridReady={onGridReady}
        rowHeight={44}
        columnDefs={colDefs}
        gridOptions={gridOptions}
        tooltipShowDelay={0}
        rowSelection="multiple"
        onSelectionChanged={(e: SelectionChangedEvent) => onSelectionChange(e)}
        enableFillHandle
        enableRangeSelection
        fillHandleDirection="y"
        onCellValueChanged={onCellValueChange}
        context={context}
        suppressRowClickSelection
      />
    </div>
  )
}

export default AddOnExpensesTable
