import React, { createContext, useContext, useState } from 'react'
import { useDashboardContext } from 'components/DashboardV2/context/dashboardContext'
import { useEnv } from '@praxis/component-runtime-env'
import { IServerSideGetRowsParams } from 'ag-grid-community'
import { DashboardRequest } from '../../../models/dashboardV2/DashboardRequest.model'
import { isEmpty, set } from 'lodash'
import Axios from 'axios'
import {
  ALERT,
  DASHBOARD_SERVICE_API_DOMAIN_URL,
  HEADER_OBJECT,
  TOASTER_DEFAULTS,
} from 'components/App/constants/appConstants'
import { connect } from 'react-redux'
import { BlueprintMilestoneSummary } from '../../../models/dashboardV2/BlueprintMilestoneSummary.model'
import { getHealthFromLabel } from 'components/DashboardV2/helpers/dashboardHelper'
import { useToaster } from '@enterprise-ui/canvas-ui-react'

type ContextType = {
  bpMilestoneDashboardSSRMServer: () => void
  currentBlueprintMilestoneRequest: DashboardRequest | undefined
}

export const BpMilestoneDashboardSSRMServerContext = createContext<
  ContextType | undefined
>(undefined)

type Props = {
  children: React.ReactNode
}

export const BpMilestoneDashboardSSRMServerComponent = ({
  children,
}: Props) => {
  const { includeCompleteBpMilestones, managers } = useDashboardContext()!
  const env = useEnv()
  const makeToast = useToaster()
  const [
    currentBlueprintMilestoneRequest,
    setCurrentBlueprintMilestoneRequest,
  ] = useState<DashboardRequest>()

  const bpMilestoneDashboardSSRMServer = () => {
    const buildSearchRequest = (params: IServerSideGetRowsParams) => {
      const { request } = params
      const sortFields =
        request.sortModel.length > 0
          ? Object.assign(
              {},
              ...request.sortModel.map((sortItem: any) => ({
                [sortItem.colId === 'health_info.label'
                  ? 'health'
                  : sortItem.colId]: sortItem.sort,
              })),
            )
          : undefined
      const filters = {}
      for (const [key, value] of Object.entries(request.filterModel)) {
        const { filter, values, dateFrom, dateTo } = value as any
        if (
          key === 'name' ||
          key === 'current_milestone.name' ||
          key === 'campaign_type' ||
          key === 'type'
        ) {
          set(filters, [key], [filter])
        } else if (key === 'health_info.label') {
          set(
            filters,
            ['health'],
            values.map((label: string) => getHealthFromLabel(label)),
          )
        } else if (key === 'set_date' || key === 'current_milestone.due_date') {
          set(
            filters,
            [key],
            [dateFrom.substring(0, 10), dateTo.substring(0, 10)],
          )
        } else {
          set(filters, [key], values)
        }
      }

      return new DashboardRequest({
        filters: isEmpty(filters) ? undefined : filters,
        sort_fields: sortFields,
        user_names: managers.map((manager: any) => manager.email),
        include_complete: includeCompleteBpMilestones,
        include_all: false,
      })
    }

    return {
      getData: async (params: IServerSideGetRowsParams) => {
        const dashboardRequest = buildSearchRequest(params)
        setCurrentBlueprintMilestoneRequest(dashboardRequest)
        try {
          const pageNumber =
            params.request.endRow !== undefined
              ? Math.ceil(params.request.endRow / 50)
              : null
          const res = await Axios.post(
            `${
              env.apiDomainUrl + DASHBOARD_SERVICE_API_DOMAIN_URL
            }/blueprint_milestones/search?page=${pageNumber}`,
            dashboardRequest,
            HEADER_OBJECT,
          )
          return {
            success: true,
            data: res.data,
            rows: res.data.search.map(
              (searchResult: any) =>
                new BlueprintMilestoneSummary(searchResult.result),
            ),
            headers: res.headers,
          }
        } catch (err: any) {
          makeToast({
            ...TOASTER_DEFAULTS,
            type: ALERT.ERROR,
            heading: 'Failed to get blueprint milestone summary',
            message: err.response.data.message,
          })
        }
      },
    }
  }

  return (
    <BpMilestoneDashboardSSRMServerContext.Provider
      value={{
        bpMilestoneDashboardSSRMServer,
        currentBlueprintMilestoneRequest,
      }}
    >
      {children}
    </BpMilestoneDashboardSSRMServerContext.Provider>
  )
}

export const BpMilestoneDashboardSSRMServerProvider = connect(
  null,
  {},
)(BpMilestoneDashboardSSRMServerComponent)

export const useBpMilestoneDashboardSSRMServerContext = () =>
  useContext(BpMilestoneDashboardSSRMServerContext)
