import { get } from 'lodash'

export default class MscGroupsFacets {
  store_groups?: DepartmentGroup[]
  sub_groups?: DepartmentSubGroup[]

  constructor(props: any = {}) {
    this.store_groups = get(props, 'store_groups', []).map(
      (group: any) => new DepartmentGroup(group),
    )
    this.sub_groups = get(props, 'sub_groups', []).map(
      (sub_group: any) => new DepartmentSubGroup(sub_group),
    )
  }
}

export class DepartmentGroup {
  group_id: string
  group_name: string

  constructor(props: any = {}) {
    this.group_id = props.group_id || ''
    this.group_name = props.group_name || ''
  }
}

export class DepartmentSubGroup {
  sub_group_id: string
  sub_group_name: string

  constructor(props: any = {}) {
    this.sub_group_id = props.sub_group_id || ''
    this.sub_group_name = props.sub_group_name || ''
  }
}
