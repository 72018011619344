import { Department } from '../../../models/signLibrary'

export const deptSelectionOptions = (
  deptFacets: Department[],
  currentDept: Department,
) => {
  return deptFacets
    .map((dept: Department) => dept.department_id)
    .includes(currentDept.department_id)
    ? deptFacets.map((dept: Department) => ({
        value: dept.department_id,
        label: dept.department_display_name,
      }))
    : [
        ...deptFacets.map((dept: Department) => ({
          value: dept.department_id,
          label: dept.department_display_name,
        })),
        {
          value: currentDept.department_id,
          label: currentDept.department_display_name,
        },
      ]
}
