import React, { Fragment } from 'react'
import { Grid, Form, Spinner } from '@enterprise-ui/canvas-ui-react'
import MessagingAttributes from './MessagingAttributes'
import {
  SIGN_MESSAGE_FACETS,
  SIGN_PREVIEW_TYPES,
} from '../../../constants/signMessagingFacets'
import { useSignPreviewContext } from '../../../context/signPreviewContext'
import { useSignDetailsContext } from '../../../context/signDetailsContext'
import { isDpciFormat } from '../../../helpers/signMessagingHelpers'
import { useSignFormsContext } from '../../../context/signFormsContext'
import { MessagingItemInfo } from '../../../../../models/signs/MessagingResponse.model'
import { useUserContext } from 'components/App/context/userContext'
import { isEditable } from 'components/App/helpers/signEditHelper'

export interface Props {
  handleChange: (id: string, value: any) => void
  itemIndex: number
  itemInfo: MessagingItemInfo
}

const MessagingForm = ({ handleChange, itemIndex, itemInfo }: Props) => {
  const { isInProject, currentSign, project } = useSignDetailsContext()!
  const { userPermissions, userCompany, userType } = useUserContext()!
  const { refreshProductInfo, isItemLoading } = useSignPreviewContext()!
  const { signPreviewFormik } = useSignFormsContext()!

  const dpciHintText =
    signPreviewFormik.values.sign_size === 'SEVEN_BY_ELEVEN' &&
    (signPreviewFormik.values.item_info[0].messaging_type !== 'Regular Price' ||
      signPreviewFormik.values.item_info[0].manual_price_override)
      ? 'DPCI# is needed for sign preview. (e.g. 123-45-6789)'
      : signPreviewFormik.values.sign_size === 'OTHER' ||
        signPreviewFormik.values.sign_size === ''
      ? '(e.g. 123-45-6789)'
      : 'Sign Finalization requires a DPCI#. (e.g. 123-45-6789)'

  const dpciHighlight =
    (signPreviewFormik.values.sign_size === 'SEVEN_BY_ELEVEN' &&
      (signPreviewFormik.values.item_info[0].messaging_type !==
        'Regular Price' ||
        signPreviewFormik.values.item_info[0].manual_price_override)) ||
    signPreviewFormik.values.sign_size === 'OTHER' ||
    signPreviewFormik.values.sign_size === ''
      ? false
      : true

  const isDisabled = (field: string) =>
    !isEditable(
      currentSign,
      field,
      userPermissions,
      userCompany,
      userType,
      isInProject,
      project.workflow.status,
      project.roster,
      project,
    ) || isItemLoading

  const handleDpciChange = (id: string, value: string, itemIndex: number) => {
    if (isDpciFormat(value)) {
      refreshProductInfo(value, itemIndex, itemInfo, handleChange)
    } else {
      handleChange(id, value)
    }
  }

  return (
    <Fragment>
      <Grid.Item xs={12}>
        <Grid.Container justify="center" align="center">
          {isItemLoading ? (
            <Grid.Item>
              <Spinner />
            </Grid.Item>
          ) : (
            <Fragment>
              <Grid.Item xs={true}>
                <Form.Field
                  id={`item_info[${itemIndex}].product_dpci`}
                  label="Product DPCI#"
                  value={
                    signPreviewFormik.values.item_info[itemIndex]
                      .product_dpci || ''
                  }
                  hintText={dpciHintText}
                  highlight={dpciHighlight}
                  disabled={isDisabled(`item_info[${itemIndex}].product_dpci`)}
                  onChange={(e: any) =>
                    handleDpciChange(e.target.id, e.target.value, itemIndex)
                  }
                />
              </Grid.Item>
              <Grid.Item xs={true}>
                <Form.Field
                  id="product_tcin"
                  label="Product TCIN#"
                  disabled
                  value={
                    signPreviewFormik.values.item_info[itemIndex].product_tcin
                  }
                />
              </Grid.Item>
              {signPreviewFormik.values.mpp ? (
                <Grid.Item className="hc-pt-none" xs={12}>
                  <Form.Field
                    id={`item_info[${itemIndex}].product_description`}
                    label="SubHeadline"
                    value={
                      signPreviewFormik.values.item_info[itemIndex]
                        .product_description
                    }
                    onChange={(e: any) => {
                      handleChange(e.target.id, e.target.value)
                    }}
                  />
                </Grid.Item>
              ) : null}
            </Fragment>
          )}
        </Grid.Container>
      </Grid.Item>
      {/* show SignMessageType dropdown for for all Value signs and all SPLN signs */}
      {(currentSign.sign_type === 'Value Sign' || currentSign.sales_plan) &&
      // unless it's MPP
      !signPreviewFormik.values.mpp &&
      // and unless it's Value FIVE_BY_THREE
      !(
        currentSign.sign_type === 'Value Sign' &&
        signPreviewFormik.values.sign_size === 'FIVE_BY_THREE'
      ) ? (
        <Fragment>
          <Grid.Item
            xs={
              signPreviewFormik.values.item_info[itemIndex].messaging_type ===
              'Regular Price'
                ? signPreviewFormik.values.item_info[itemIndex]
                    .manual_price_override
                  ? 4
                  : 8
                : 6
            }
          >
            <Form.Field
              type="select"
              id={`item_info[${itemIndex}].messaging_type`}
              label="Sign Messaging Type"
              disabled={isDisabled(`item_info[${itemIndex}].messaging_type`)}
              value={
                signPreviewFormik.values.item_info[itemIndex].messaging_type
              }
              options={SIGN_MESSAGE_FACETS(signPreviewFormik.values.sign_size)}
              onUpdate={(id: string, value: string) => handleChange(id, value)}
            />
          </Grid.Item>
          {signPreviewFormik.values.item_info[itemIndex].messaging_type ===
          'Starting At' ? (
            <Grid.Item xs={6}>
              <Form.Field
                id={`item_info[${itemIndex}].messaging_value.price`}
                label="Price Starting At"
                value={
                  signPreviewFormik.values.item_info[itemIndex].messaging_value
                    .price
                }
                hintText={
                  !signPreviewFormik.values.item_info[itemIndex].messaging_value
                    .price
                    ? 'Enter a price.'
                    : ''
                }
                highlight={
                  !signPreviewFormik.values.item_info[itemIndex].messaging_value
                    .price
                }
                disabled={isDisabled(
                  `item_info[${itemIndex}].messaging_value.price`,
                )}
                onChange={(e: any) => handleChange(e.target.id, e.target.value)}
              />
            </Grid.Item>
          ) : signPreviewFormik.values.item_info[itemIndex].messaging_type ===
            'Price Range' ? (
            <Fragment>
              <Grid.Item xs={3}>
                <Form.Field
                  id={`item_info[${itemIndex}].messaging_value.lowest_price`}
                  label="Lowest Price"
                  value={
                    signPreviewFormik.values.item_info[itemIndex]
                      .messaging_value.lowest_price || ''
                  }
                  hintText={
                    !signPreviewFormik.values.item_info[itemIndex]
                      .messaging_value.lowest_price
                      ? 'Enter the lowest price.'
                      : ''
                  }
                  highlight={
                    !signPreviewFormik.values.item_info[itemIndex]
                      .messaging_value.lowest_price
                  }
                  disabled={isDisabled(
                    `item_info[${itemIndex}].messaging_value.lowest_price`,
                  )}
                  onChange={(e: any) =>
                    handleChange(e.target.id, e.target.value)
                  }
                />
              </Grid.Item>
              <Grid.Item xs={3}>
                <Form.Field
                  id={`item_info[${itemIndex}].messaging_value.highest_price`}
                  label="Highest Price"
                  value={
                    signPreviewFormik.values.item_info[itemIndex]
                      .messaging_value.highest_price || ''
                  }
                  hintText={
                    !signPreviewFormik.values.item_info[itemIndex]
                      .messaging_value.highest_price
                      ? 'Enter the highest price.'
                      : ''
                  }
                  highlight={
                    !signPreviewFormik.values.item_info[itemIndex]
                      .messaging_value.highest_price
                  }
                  disabled={isDisabled(
                    `item_info[${itemIndex}].messaging_value.highest_price`,
                  )}
                  onChange={(e: any) =>
                    handleChange(e.target.id, e.target.value)
                  }
                />
              </Grid.Item>
            </Fragment>
          ) : signPreviewFormik.values.item_info[itemIndex].messaging_type ===
            'When You Buy' ? (
            <Fragment>
              <Grid.Item xs={3}>
                <Form.Field
                  id={`item_info[${itemIndex}].messaging_value.price`}
                  label="Price"
                  value={
                    signPreviewFormik.values.item_info[itemIndex]
                      .messaging_value.price || ''
                  }
                  hintText={
                    !signPreviewFormik.values.item_info[itemIndex]
                      .messaging_value.price
                      ? 'Enter a price.'
                      : ''
                  }
                  highlight={
                    !signPreviewFormik.values.item_info[itemIndex]
                      .messaging_value.price
                  }
                  disabled={isDisabled(
                    `item_info[${itemIndex}].messaging_value.price`,
                  )}
                  onChange={(e: any) =>
                    handleChange(e.target.id, e.target.value)
                  }
                />
              </Grid.Item>
              <Grid.Item xs={3}>
                <Form.Field
                  id={`item_info[${itemIndex}].messaging_value.quantity`}
                  label="When You Buy"
                  value={
                    signPreviewFormik.values.item_info[itemIndex]
                      .messaging_value.quantity || ''
                  }
                  hintText={
                    !signPreviewFormik.values.item_info[itemIndex]
                      .messaging_value.quantity
                      ? 'A quantity must be entered.'
                      : ''
                  }
                  highlight={
                    !signPreviewFormik.values.item_info[itemIndex]
                      .messaging_value.quantity
                  }
                  disabled={isDisabled(
                    `item_info[${itemIndex}].messaging_value.quantity`,
                  )}
                  onChange={(e: any) =>
                    handleChange(e.target.id, e.target.value)
                  }
                />
              </Grid.Item>
            </Fragment>
          ) : signPreviewFormik.values.item_info[itemIndex].messaging_type ===
            'And Under' ? (
            <Grid.Item xs={6}>
              <Form.Field
                id={`item_info[${itemIndex}].messaging_value.price`}
                label="And Under Price"
                value={
                  signPreviewFormik.values.item_info[itemIndex].messaging_value
                    .price || ''
                }
                hintText={
                  !signPreviewFormik.values.item_info[itemIndex].messaging_value
                    .price
                    ? 'Enter a price.'
                    : ''
                }
                highlight={
                  !signPreviewFormik.values.item_info[itemIndex].messaging_value
                    .price
                }
                disabled={isDisabled(
                  `item_info[${itemIndex}].messaging_value.price`,
                )}
                onChange={(e: any) => handleChange(e.target.id, e.target.value)}
              />
            </Grid.Item>
          ) : signPreviewFormik.values.item_info[itemIndex].messaging_type ===
              'Regular Price' &&
            signPreviewFormik.values.item_info[itemIndex]
              .manual_price_override ? (
            <Fragment>
              <Grid.Item xs={4}>
                <Form.Field
                  id={`item_info[${itemIndex}].messaging_value.price`}
                  label="Manual Price Override"
                  value={
                    signPreviewFormik.values.item_info[itemIndex]
                      .messaging_value.price || ''
                  }
                  hintText={
                    !signPreviewFormik.values.item_info[itemIndex]
                      .messaging_value.price
                      ? 'Enter a price.'
                      : ''
                  }
                  highlight={
                    !signPreviewFormik.values.item_info[itemIndex]
                      .messaging_value.price
                  }
                  disabled={isDisabled(
                    `item_info[${itemIndex}].messaging_value.price`,
                  )}
                  onChange={(e: any) =>
                    handleChange(e.target.id, e.target.value)
                  }
                />
              </Grid.Item>
              <Grid.Item xs={4}>
                <Form.Field
                  type="checkbox"
                  id={`item_info[${itemIndex}].manual_price_override`}
                  label="Manually Override Regular Price"
                  disabled={isDisabled(
                    `item_info[${itemIndex}].manual_price_override`,
                  )}
                  checked={
                    signPreviewFormik.values.item_info[itemIndex]
                      .manual_price_override
                  }
                  onChange={(e: any) =>
                    handleChange(e.target.id, e.target.checked)
                  }
                />
              </Grid.Item>
            </Fragment>
          ) : (
            <Grid.Item xs={4}>
              <Form.Field
                type="checkbox"
                id={`item_info[${itemIndex}].manual_price_override`}
                label="Manually Override Regular Price"
                checked={
                  signPreviewFormik.values.item_info[itemIndex]
                    .manual_price_override
                }
                onChange={(e: any) =>
                  handleChange(e.target.id, e.target.checked)
                }
              />
            </Grid.Item>
          )}
        </Fragment>
      ) : null}
      {currentSign.sign_type === SIGN_PREVIEW_TYPES.DISPLAY ? (
        <Grid.Item xs={12}>
          <MessagingAttributes
            attributes={
              signPreviewFormik.values.item_info[itemIndex].attributes
            }
            itemIndex={itemIndex}
            isInProject={isInProject}
            handleChange={handleChange}
            values={signPreviewFormik.values}
          />
        </Grid.Item>
      ) : null}
    </Fragment>
  )
}

export default MessagingForm
