import {
  Button,
  Grid,
  GridItem,
  Heading,
  Modal,
  Table,
} from '@enterprise-ui/canvas-ui-react'
import React, { useEffect } from 'react'
import EnterpriseIcon, { CancelIcon } from '@enterprise-ui/icons'
import { useProjectDistributionContext } from '../context/projectDistributionContext'
import { orderBy } from 'lodash'

export interface Props {
  stores?: string[]
  isShowStoresOpen: boolean
  setShowStoresOpen: Function
}

const ProjectKitStoresView = ({
  stores,
  isShowStoresOpen,
  setShowStoresOpen,
}: Props) => {
  const { locations, getLocations, filterLocations } =
    useProjectDistributionContext()!
  useEffect(() => {
    if (locations === undefined || locations.length === 0) {
      getLocations()
    }
  }, [locations, getLocations])

  return (
    <Modal
      onRefuse={() => setShowStoresOpen(false)}
      isVisible={isShowStoresOpen}
    >
      <div className="hc-pa-normal">
        <Grid.Container align="center" justify="space-between">
          <Grid.Item>
            <Heading
              className="hc-clr-grey01 hc-ml-normal hc-txt-capitalize"
              size={4}
            >
              Showing Kit Stores
            </Heading>
          </Grid.Item>
          <GridItem align="right">
            <p>Total {stores?.length}</p>
          </GridItem>
          <Grid.Item align="right">
            <Button
              aria-label="Close Stores View"
              iconOnly
              onClick={() => {
                setShowStoresOpen(false)
              }}
            >
              <EnterpriseIcon icon={CancelIcon} />
            </Button>
          </Grid.Item>
        </Grid.Container>
        <Grid.Container className="sem_ProjectKitStores">
          <Grid.Item xs={12}>
            <Table name="quantity per kit vendor">
              <Table.Head>
                <Table.Row>
                  <Table.Header xs={4} className="hc-pl-normal" key="Store Id">
                    Store Id
                  </Table.Header>
                  <Table.Header
                    xs={8}
                    className="hc-pl-normal"
                    key="Store Name"
                  >
                    Store Name
                  </Table.Header>
                </Table.Row>
              </Table.Head>

              <Table.Body>
                {locations &&
                  stores &&
                  orderBy(
                    filterLocations(locations, stores),
                    ['location_id'],
                    ['asc'],
                  ).map((store: any, index: number) => (
                    <Table.Row key={index}>
                      <Table.Data
                        xs={4}
                        className="hc-pl-normal"
                        key={store.location_id}
                      >
                        {store.location_id}
                      </Table.Data>
                      <Table.Data
                        xs={8}
                        className="hc-pl-normal"
                        key={store.location_name}
                      >
                        {store.location_name}
                      </Table.Data>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          </Grid.Item>
        </Grid.Container>
      </div>
    </Modal>
  )
}

export default ProjectKitStoresView
