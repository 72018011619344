import { IRowDragItem } from 'ag-grid-community'
import SignTemplateBatchRequest, {
  SignTemplateRequest,
} from '../../../../models/signTemplates/SignTemplateBatchRequest.model'
import { BATCH_EDIT_ACTION } from '../../../App/constants/appConstants'

export const rowDragText = (params: IRowDragItem, dragItemCount: number) =>
  dragItemCount > 1 ? `${dragItemCount} Templates` : params.rowNode?.data.name

export const removeIdFromNewTemplates = (
  modifiedTemplates: SignTemplateBatchRequest[],
) => {
  return modifiedTemplates.map((template: SignTemplateBatchRequest) =>
    template.action === BATCH_EDIT_ACTION.ADD
      ? new SignTemplateBatchRequest({
          ...template,
          sign_template_dto: new SignTemplateRequest({
            ...template.sign_template_dto,
            id: undefined,
          }),
        })
      : template,
  )
}
