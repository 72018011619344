import React, { useState } from 'react'
import { Grid, Form, Button } from '@enterprise-ui/canvas-ui-react'
import { useProjectNotesContext } from '../context/projectNotesContext'
import { useProjectDetailsContext } from '../../../context/projectDetailsContext'
import { useUserContext } from '../../../../App/context/userContext'
import { ProjectNote } from '../../../../../models/projects/ProjectNote.model'

const ProjectNotesForm = () => {
  const { currentProject } = useProjectDetailsContext()!
  const { userName } = useUserContext()!
  const {
    addProjectNote,
    isProjectNotesOpen,
    isProjectNotesFormOpen,
    setIsProjectNotesFormOpen,
  } = useProjectNotesContext()!

  const [newNote, setNewNote] = useState(
    new ProjectNote({
      author: userName,
      timestamp: new Date().toISOString(),
      content: '',
    }),
  )

  return (
    <Grid.Item
      className={`sem_NotesForm ${isProjectNotesOpen ? 'open' : ''} ${
        isProjectNotesFormOpen ? 'hc-pt-none isVisible' : 'hc-pa-none'
      }`}
      xs={12}
    >
      <Form onSubmit={() => addProjectNote(currentProject.project_id, newNote)}>
        <Grid.Container direction="row-reverse" spacing="dense">
          <Grid.Item xs={12}>
            <Form.Field
              className="hc-mt-none"
              id="new_bp_note"
              type="textarea"
              placeholder="Enter your new note here..."
              value={newNote.content}
              onChange={(e: any) =>
                setNewNote({
                  ...newNote,
                  content: e.target.value,
                })
              }
              size="dense"
            />
          </Grid.Item>
          <Grid.Item xs={5}>
            <Button
              fullWidth
              type="primary"
              size="dense"
              onClick={() => {
                addProjectNote(currentProject.project_id, newNote)
                setNewNote({
                  ...newNote,
                  content: '',
                })
                setIsProjectNotesFormOpen(false)
              }}
            >
              Add Note
            </Button>
          </Grid.Item>
          <Grid.Item xs={5}>
            <Button
              type="secondary"
              fullWidth
              size="dense"
              onClick={() => setIsProjectNotesFormOpen(false)}
            >
              Cancel
            </Button>
          </Grid.Item>
        </Grid.Container>
      </Form>
    </Grid.Item>
  )
}

export default ProjectNotesForm
