import React, { useEffect, useState } from 'react'
import { Button, Dropdown } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, {
  CancelIcon,
  CaretDownIcon,
  CheckIcon,
  ExportIcon,
  MaximizeIcon,
  MinimizeIcon,
  PlusIcon,
  RedoIcon,
  SaveIcon,
  UndoIcon,
} from '@enterprise-ui/icons'
import { useAgGridAddOnContext } from 'components/ProjectDetails/components/AddOnExpenses/context/agGridAddOnContext'
import { EXPORT_COLUMNS } from 'components/ProjectDetails/components/AddOnExpenses/constants/colDefs'
import { useProjectDetailsContext } from 'components/ProjectDetails/context/projectDetailsContext'
import { useAddOnContext } from 'components/ProjectDetails/components/AddOnExpenses/context/addOnContext'
import AddOnExpensesRequest from '../../../../../models/addOnExpenses/AddOnExpensesRequest.model'
import { useUserContext } from 'components/App/context/userContext'
import { RowNode } from 'ag-grid-community'
import { DialogProps } from '../../../../../models/app/DialogProps.model'
import { useAppContext } from 'components/App/context/appContext'

const ActionsDropdown = () => {
  const {
    gridApi,
    resetAllFilters,
    sizeColumnsToFit,
    autoSizeAllColumns,
    resetColumnState,
    addNewExpense,
    isAllExpenseSubmitted,
  } = useAgGridAddOnContext()!
  const {
    setModifiedExpenses,
    updateExpenses,
    modifiedExpenses,
    isAnySignPricingMissing,
    isPriceGatheringCompleted,
  } = useAddOnContext()!
  const { currentProject } = useProjectDetailsContext()!
  const { userEmail, userPermissions } = useUserContext()!
  const [isChangingStatus, setIsChangingStatus] = useState(false)
  const { setDialogProps } = useAppContext()!

  useEffect(() => {
    if (modifiedExpenses && isChangingStatus) {
      updateExpenses()
      setIsChangingStatus(false)
    }
  }, [isChangingStatus, modifiedExpenses, updateExpenses])

  const getAddOnExpensesRequestObject = (expense: RowNode, newStatus: string) =>
    new AddOnExpensesRequest({
      add_on_expenses_id: expense.data.id,
      print_vendor: expense.data.print_vendor,
      expenses_type: expense.data.expenses_type,
      cost: expense.data.cost,
      po_number: expense.data.po_number,
      vendor_notes: expense.data.vendor_notes,
      expenses_status: newStatus,
      updated_by: userEmail,
    })

  const changeExpensesStatusForSelectedEntries = (newStatus: string) => {
    const update_add_on_expenses_request_dto = gridApi
      ?.getSelectedNodes()
      .map((expense: RowNode) =>
        getAddOnExpensesRequestObject(expense, newStatus),
      )
    const expenses_status = newStatus
    setModifiedExpenses({ update_add_on_expenses_request_dto, expenses_status })
    setIsChangingStatus(true)
  }

  const changeExpensesStatusForAllEntries = (newStatus: string) => {
    const allRowData: any[] = []
    gridApi?.forEachNode((node: RowNode) => allRowData.push(node))
    const update_add_on_expenses_request_dto = allRowData.map(
      (expense: RowNode) => getAddOnExpensesRequestObject(expense, newStatus),
    )
    const expenses_status = newStatus
    setModifiedExpenses({ update_add_on_expenses_request_dto, expenses_status })
    setIsChangingStatus(true)
  }

  return (
    <Dropdown size="expanded" location="bottom-left">
      <Button type="ghost">
        Actions
        <EnterpriseIcon className="hc-ml-dense" icon={CaretDownIcon} />
      </Button>
      <Dropdown.Menu>
        <Dropdown.MenuItem
          className="sem_DropdownMenuItem--action"
          onClick={addNewExpense}
          disabled={
            !userPermissions.isSuperAdmin && isPriceGatheringCompleted()
          }
        >
          <EnterpriseIcon
            icon={PlusIcon}
            size="inline"
            className="hc-mr-normal"
          />
          Add New Add-On Expense
        </Dropdown.MenuItem>
        <Dropdown.MenuItem
          className="sem_DropdownMenuItem--action"
          onClick={() => changeExpensesStatusForAllEntries('Approved')}
          disabled={
            !userPermissions.isSuperAdmin &&
            (!userPermissions.isPrintProduction || isAllExpenseSubmitted)
          }
        >
          <EnterpriseIcon
            icon={CheckIcon}
            size="inline"
            className="hc-mr-normal"
          />
          Approve All Expenses
        </Dropdown.MenuItem>
        <Dropdown.MenuItem
          className="sem_DropdownMenuItem--action"
          onClick={() => changeExpensesStatusForSelectedEntries('Rejected')}
          disabled={
            (!userPermissions.isSuperAdmin &&
              !userPermissions.isPrintProduction) ||
            gridApi?.getSelectedNodes()?.length === 0 ||
            !gridApi
              ?.getSelectedNodes()
              ?.every(
                (row: RowNode) =>
                  row.data.expenses_status === 'Submitted' ||
                  row.data.expenses_status === 'Approved',
              )
          }
        >
          <EnterpriseIcon
            icon={CancelIcon}
            size="inline"
            className="hc-mr-normal"
          />
          Reject Selected Add-On Expenses
        </Dropdown.MenuItem>
        <Dropdown.MenuItem
          className="sem_DropdownMenuItem--action"
          onClick={() =>
            setDialogProps(
              new DialogProps({
                headingText: 'Are you sure you want to submit all expenses',
                bodyText:
                  'You will no longer be able to make changes or create new expenses.',
                approveButtonText: 'Yes',
                onApprove: isAnySignPricingMissing
                  ? () => {
                      setDialogProps(
                        new DialogProps({
                          headingText:
                            'Please add final unit price to all signs in order to submit',
                          approveButtonText: 'Ok',
                          onApprove: () => {
                            setDialogProps(new DialogProps())
                          },
                        }),
                      )
                    }
                  : () => {
                      setDialogProps(new DialogProps())
                      changeExpensesStatusForAllEntries('Submitted')
                    },
                refuseButtonText: 'Cancel',
                onRefuse: () => setDialogProps(new DialogProps()),
              }),
            )
          }
        >
          <EnterpriseIcon
            icon={SaveIcon}
            size="inline"
            className="hc-mr-normal"
          />
          Submit All Expenses
        </Dropdown.MenuItem>
        <Dropdown.MenuItem
          className="sem_DropdownMenuItem--action divider"
          onClick={() => {
            gridApi?.exportDataAsCsv({
              columnKeys: EXPORT_COLUMNS,
              fileName: `${currentProject.sap_project_id} ${currentProject.name} Add-On Expenses.csv`,
            })
          }}
        >
          <EnterpriseIcon
            icon={ExportIcon}
            size="inline"
            className="hc-mr-normal"
          />
          Export as CSV
        </Dropdown.MenuItem>
        <Dropdown.MenuItem onClick={() => resetAllFilters()}>
          <EnterpriseIcon
            icon={RedoIcon}
            size="inline"
            className="hc-mr-normal"
          />
          Reset All Column Filters
        </Dropdown.MenuItem>
        <Dropdown.MenuItem onClick={sizeColumnsToFit}>
          <EnterpriseIcon
            icon={MinimizeIcon}
            size="inline"
            className="hc-mr-normal"
          />
          Size Columns to Fit
        </Dropdown.MenuItem>
        <Dropdown.MenuItem onClick={autoSizeAllColumns}>
          <EnterpriseIcon
            icon={MaximizeIcon}
            size="inline"
            className="hc-mr-normal"
          />
          Auto Size All Columns
        </Dropdown.MenuItem>
        <Dropdown.MenuItem onClick={resetColumnState}>
          <EnterpriseIcon
            icon={UndoIcon}
            size="inline"
            className="hc-mr-normal"
          />
          Reset Column Sizes
        </Dropdown.MenuItem>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default ActionsDropdown
