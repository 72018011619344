import React from 'react'
import { createRoot } from 'react-dom/client'
import '@enterprise-ui/canvas-ui-css'
import '@enterprise-ui/canvas-ui-css-datepicker'
import '@enterprise-ui/canvas-ui-css-autocomplete'
import '@enterprise-ui/canvas-ui-css-gallery'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import './index.scss'
import { Provider } from 'react-redux'
import { createStore } from 'redux'
import { AuthProvider } from '@praxis/component-auth'
import { EnvProvider } from '@praxis/component-runtime-env'
import { BrowserRouter } from 'react-router-dom'
import { polDomainRegex } from 'components/App/constants/appConstants'
import App from './components/App/components/App'
import { UserProvider } from 'components/App/context/userContext'
import { AppProvider } from 'components/App/context/appContext'
import { FilterProvider } from 'components/App/context/filterContext'
import 'ag-grid-enterprise'
import { LicenseManager } from 'ag-grid-enterprise'
import { NotificationsProvider } from 'components/Notifications/context/notificationsContext'
import { NotificationSSRMServerProvider } from 'components/Notifications/context/notificationSSRMServerContext'
import commonConfig from './config/commonConfig'
import { Layout, ToastProvider } from '@enterprise-ui/canvas-ui-react'

LicenseManager.setLicenseKey(commonConfig.agGridLicenseKey)

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any
  }
}

const store = createStore((state) => state)

const getEnvByHost = (env: any): any => {
  if (window.location.hostname.match(polDomainRegex)) {
    return env.pol
  } else {
    return env.auth
  }
}

const domNode: Element = document.getElementById('root')!!
const root = createRoot(domNode)
root.render(
  <EnvProvider
    commonConfig={commonConfig}
    configPath={
      process.env.NODE_ENV === 'development'
        ? '/config.json'
        : '/app-environment'
    }
  >
    {({ env }) => {
      return (
        <AuthProvider {...getEnvByHost(env)}>
          <Provider store={store}>
            <UserProvider>
              <ToastProvider location="top">
                <AppProvider>
                  <FilterProvider>
                    <NotificationSSRMServerProvider>
                      <NotificationsProvider>
                        <BrowserRouter>
                          <Layout fullWidth darkMode={false}>
                            <App />
                          </Layout>
                        </BrowserRouter>
                      </NotificationsProvider>
                    </NotificationSSRMServerProvider>
                  </FilterProvider>
                </AppProvider>
              </ToastProvider>
            </UserProvider>
          </Provider>
        </AuthProvider>
      )
    }}
  </EnvProvider>,
)
