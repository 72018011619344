import Axios from 'axios'
import React, { createContext, useState, useContext, useCallback } from 'react'
import { connect } from 'react-redux'
import { DialogProps } from '../../../models/app/DialogProps.model'
import {
  SEARCH_RESPONSE_SIZE,
  PROJECT_SERVICE_API_DOMAIN_URL,
  TOASTER_DEFAULTS,
} from '../../App/constants/appConstants'
import { useEnv } from '@praxis/component-runtime-env'
import { SearchRequest } from '../../../models/app/SearchRequest.model'
import {
  SearchResponse,
  SearchResult,
} from '../../../models/app/SearchResponse.model'
import { BlueprintDetail } from '../../../models/blueprints/BlueprintDetail.model'
import { BlueprintRequest } from '../../../models/blueprints/BlueprintRequest.model'
import { BlueprintSearchFacets } from '../../../models/blueprints/BlueprintSearchFacets.model'
import { useAppContext } from '../../App/context/appContext'
import { useToaster } from '@enterprise-ui/canvas-ui-react'
type ContextType = {
  isLoadingMore: boolean
  setIsLoadingMore: (isLoadingMore: boolean) => void
  blueprintSearchResponse: SearchResponse<
    BlueprintSearchFacets,
    BlueprintDetail
  >
  setBlueprintSearchResponse: Function
  submitBlueprintSearch: (
    filters?: SearchRequest<BlueprintSearchFacets>,
  ) => void
  handleNewResults: (results: any) => void
  loadMoreBlueprints: (
    filters: SearchRequest<BlueprintSearchFacets>,
  ) => Promise<SearchResult<BlueprintDetail>[]>
  cancelBlueprint: (id: string) => void
}
export const BlueprintListContext = createContext<ContextType | undefined>(
  undefined,
)
type Props = {
  children: React.ReactNode
}
const BlueprintListProviderComponent = ({ children }: Props) => {
  const { setFullPageLoadingMessage, setDialogProps } = useAppContext()!
  const env = useEnv<any>()
  const makeToast = useToaster()
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [blueprintSearchResponse, setBlueprintSearchResponse] = useState(
    new SearchResponse<BlueprintSearchFacets, BlueprintDetail>({
      facets: new BlueprintSearchFacets(),
    }),
  )

  // pass filters as an argument, otherwise it would need to be in the dependency array and would cause extra API calls
  const submitBlueprintSearch = useCallback(
    async (filters?: SearchRequest<BlueprintSearchFacets>) => {
      setFullPageLoadingMessage('Loading Blueprints...')
      try {
        const res: any = await Axios.post(
          `${
            env.apiDomainUrl + PROJECT_SERVICE_API_DOMAIN_URL
          }/blueprints/search?per_page=${SEARCH_RESPONSE_SIZE}`,
          new SearchRequest({
            facets: (filters && filters.facets) || new BlueprintSearchFacets(),
            sort_fields: (filters && filters.sort_fields) || {
              set_date: 'desc',
            },
            ...filters,
          }),
          {
            headers: { 'Content-Type': 'application/json' },
          },
        )
        setBlueprintSearchResponse(new SearchResponse(res.data))
      } catch (err: any) {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'error',
          heading: 'Failed to Search Blueprint',
          message: err.response.data.message,
        })
      }
      setFullPageLoadingMessage('')
    },
    [setFullPageLoadingMessage, makeToast, env],
  )
  const handleNewResults = (
    newBlueprintSearchResults: SearchResult<BlueprintDetail>[],
  ) => {
    setBlueprintSearchResponse(
      new SearchResponse({
        ...blueprintSearchResponse,
        page: blueprintSearchResponse.page + 1,
        search: [
          ...blueprintSearchResponse.search,
          ...newBlueprintSearchResults,
        ],
      }),
    )
  }
  const loadMoreBlueprints = async (
    filters: SearchRequest<BlueprintSearchFacets>,
  ) => {
    try {
      const res = await Axios.post(
        `${
          env.apiDomainUrl + PROJECT_SERVICE_API_DOMAIN_URL
        }/blueprints/search?per_page=${SEARCH_RESPONSE_SIZE}&page=${
          blueprintSearchResponse.page + 1
        }`,
        new SearchRequest<BlueprintSearchFacets>(filters),
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      const response = new SearchResponse<
        BlueprintSearchFacets,
        BlueprintDetail
      >(res.data)
      return response.search
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Load More Blueprints',
        message: err.response.data.message,
      })
      return []
    }
  }
  const cancelBlueprint = async (blueprintId: string) => {
    setFullPageLoadingMessage('Cancelling Blueprint...')
    try {
      const res = await Axios.get(
        `${
          env.apiDomainUrl + PROJECT_SERVICE_API_DOMAIN_URL
        }/blueprints/${blueprintId}`,
      )
      await Axios.put(
        `${
          env.apiDomainUrl + PROJECT_SERVICE_API_DOMAIN_URL
        }/blueprints/cancel?blueprint_id=${blueprintId}`,
        {
          ...new BlueprintRequest(res.data),
          status: 'Cancel',
        },
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      setBlueprintSearchResponse(
        new SearchResponse({
          ...blueprintSearchResponse,
          search: blueprintSearchResponse.search.map(
            (searchResult: SearchResult<BlueprintDetail>) =>
              searchResult.result.blueprint_id === blueprintId
                ? new SearchResult<BlueprintDetail>({
                    result: new BlueprintDetail({
                      ...searchResult.result,
                      status: 'Cancel',
                    }),
                  })
                : searchResult,
          ),
        }),
      )
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'success',
        heading: 'Blueprint Cancelled',
        message: 'Successfully cancelled the blueprint',
      })
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Cancel Blueprint',
        message: err.response.data.message,
      })
    }
    setDialogProps(new DialogProps())
    setFullPageLoadingMessage('')
  }
  return (
    <BlueprintListContext.Provider
      value={{
        isLoadingMore,
        setIsLoadingMore,
        blueprintSearchResponse,
        setBlueprintSearchResponse,
        submitBlueprintSearch,
        handleNewResults,
        loadMoreBlueprints,
        cancelBlueprint,
      }}
    >
      {children}
    </BlueprintListContext.Provider>
  )
}
export const BlueprintListProvider = connect(
  null,
  null,
)(BlueprintListProviderComponent)
export const useBlueprintListContext = () => useContext(BlueprintListContext)
