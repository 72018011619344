export const parseInitials = (name: string) => {
  if (!name || name === '') {
    return ''
  }
  let nameSplit = name.split('.')
  if (nameSplit.length >= 3) {
    let firstInitial = nameSplit[0].charAt(0)
    let lastInitial = nameSplit[nameSplit.length - 2].charAt(0)
    return firstInitial + lastInitial
  } else if (nameSplit.length < 3) {
    return name.substring(0, 1)
  }
  return ''
}
