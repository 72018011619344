export class MilestoneTemplate {
  milestone_id: string
  name: string
  days_out: number
  risk_days_out?: number
  project_phase: string
  role: string
  completion_level: string
  information_only: boolean
  risk: boolean
  critical: boolean
  custom_milestone: boolean
  auto_check: boolean
  due_date?: string
  status?: string
  status_description?: string
  assigned_to?: string[]
  depends_on?: string
  disabled: boolean
  completed_date?: string
  created_by?: string
  created_date?: string
  updated_by?: string
  updated_date?: string

  constructor(props: any = {}) {
    this.milestone_id = props.milestone_id || ''
    this.name = props.name || ''
    this.days_out = props.days_out || 0
    this.risk_days_out = props.risk_days_out || 0
    this.project_phase = props.project_phase || ''
    this.role = props.role || ''
    this.completion_level = props.completion_level || ''
    this.information_only = props.information_only || false
    this.risk = props.risk || false
    this.critical = props.critical || false
    this.custom_milestone = props.custom_milestone || false
    this.auto_check = props.auto_check || false
    this.due_date = props.due_date || ''
    this.status = props.status
    this.status_description = props.status_description
    this.assigned_to = props.assigned_to || []
    this.depends_on = props.depends_on || ''
    this.disabled = props.disabled || false
    this.completed_date = props.completed_date || ''
    this.created_by = props.created_by || ''
    this.created_date = props.created_date || ''
    this.updated_by = props.updated_by || ''
    this.updated_date = props.updated_date || ''
  }
}
