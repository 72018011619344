import { UserPermissions } from '../../../../../models/app/UserPermissions.model'

export const isEditable = (
  data: any,
  isCreatingExpense?: boolean,
  userPermissions?: UserPermissions,
  expenseStatus?: string,
) => {
  if (expenseStatus === 'Completed') return false
  return (
    userPermissions?.isPrintVendor ||
    userPermissions?.isSuperAdmin ||
    userPermissions?.isTPSVendor ||
    userPermissions?.isSignEditor
  )
}
