import React, { useEffect, useState } from 'react'
import { Button, Dropdown } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, {
  CancelIcon,
  CheckIcon,
  OverflowMenuVerticalIcon,
  TrashIcon,
} from '@enterprise-ui/icons'
import moment from 'moment/moment'
import AddOnExpenses from '../../../../../../models/addOnExpenses/AddOnExpenses.model'
import { useAddOnContext } from 'components/ProjectDetails/components/AddOnExpenses/context/addOnContext'
import AddOnExpensesRequest from '../../../../../../models/addOnExpenses/AddOnExpensesRequest.model'
import { useUserContext } from 'components/App/context/userContext'

export interface Props {
  data: AddOnExpenses
}

const RowDropdownMenu = ({ data }: Props) => {
  const {
    deleteExpense,
    setModifiedExpenses,
    updateExpenses,
    modifiedExpenses,
    isPriceGatheringCompleted,
  } = useAddOnContext()!
  const { userEmail, userPermissions } = useUserContext()!
  const [isChangingStatus, setIsChangingStatus] = useState(false)

  useEffect(() => {
    if (modifiedExpenses && isChangingStatus) {
      updateExpenses()
      setIsChangingStatus(false)
    }
  }, [isChangingStatus, modifiedExpenses, updateExpenses])

  const changeExpenseStatus = (newStatus: string) => {
    const update_add_on_expenses_request_dto = [
      new AddOnExpensesRequest({
        add_on_expenses_id: data.id,
        print_vendor: data.print_vendor,
        expenses_type: data.expenses_type,
        cost: data.cost,
        po_number: data.po_number,
        vendor_notes: data.vendor_notes,
        expenses_status: newStatus,
        updated_by: userEmail,
      }),
    ]
    const expenses_status = newStatus
    setModifiedExpenses({ update_add_on_expenses_request_dto, expenses_status })
    setIsChangingStatus(true)
  }

  return (
    <Dropdown size="expanded" location="bottom-left">
      <Button
        aria-label="Row dropdown menu"
        type="ghost"
        className="sem_IconBtn-dark"
        data-testid="openAddOnDropdown"
        iconOnly
        size="dense"
      >
        <EnterpriseIcon icon={OverflowMenuVerticalIcon} size="md" />
      </Button>
      <Dropdown.Menu>
        <Dropdown.MenuItem as="div" className="sem_DropdownMenuItem--info">
          <span className="sem_StrongText hc-mr-dense">Last Modified:</span>
          {moment(data.updated_date).format('MM/DD/YYYY hh:mm A')}
        </Dropdown.MenuItem>
        <Dropdown.MenuItem
          as="div"
          className="sem_DropdownMenuItem--info divider"
        >
          <span className="sem_StrongText hc-mr-dense">Modified By:</span>
          {data.updated_by}
        </Dropdown.MenuItem>
        {userPermissions.isSuperAdmin ? (
          <Dropdown.MenuItem
            className="sem_DropdownMenuItem--action"
            onClick={() => changeExpenseStatus('Approved')}
            disabled={
              data.expenses_status !== 'Submitted' ||
              (!userPermissions.isSuperAdmin &&
                !userPermissions.isPrintProduction)
            }
          >
            <EnterpriseIcon
              icon={CheckIcon}
              size="inline"
              className="hc-mr-normal"
            />
            Approve Add-On Expense
          </Dropdown.MenuItem>
        ) : null}
        {userPermissions.isSuperAdmin ? (
          <Dropdown.MenuItem
            className="sem_DropdownMenuItem--action"
            onClick={() => changeExpenseStatus('Rejected')}
            disabled={
              (data.expenses_status !== 'Submitted' &&
                data.expenses_status !== 'Approved') ||
              (!userPermissions.isSuperAdmin &&
                !userPermissions.isPrintProduction)
            }
          >
            <EnterpriseIcon
              icon={CancelIcon}
              size="inline"
              className="hc-mr-normal"
            />
            Reject Add-On Expense
          </Dropdown.MenuItem>
        ) : null}
        {userPermissions.isSuperAdmin ||
        (!isPriceGatheringCompleted() &&
          (userPermissions.isPrintVendor || userPermissions.isTPSVendor)) ? (
          <Dropdown.MenuItem
            className="sem_DropdownMenuItem--action"
            onClick={() => deleteExpense(data.id!!)}
          >
            <EnterpriseIcon icon={TrashIcon} className="hc-mr-dense" />
            Delete Add-On Expense
          </Dropdown.MenuItem>
        ) : null}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default RowDropdownMenu
