import React, { createContext, useState, useContext } from 'react'
import { connect } from 'react-redux'
import {
  CellClassParams,
  CellValueChangedEvent,
  ColumnApi,
  EditableCallbackParams,
  FilterChangedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  RowNode,
  SelectionChangedEvent,
  SortChangedEvent,
  ValueGetterParams,
} from 'ag-grid-community'
import RowDropdownMenu from '../components/projectListTable/components/RowDropdownMenu'
import RowProjectDetailLink from '../components/projectListTable/components/RowProjectDetailLink'
import {
  cellIsEditable,
  dateComparator,
  dateFilterParams,
  departmentExportValueGetter,
  divisionExportValueGetter,
  projectSetDatesValueGetter,
  rowDragText,
  setDateValueGetter,
  dateFormatter,
} from '../helpers/projectListHelper'
import TableToggleComponent from '../../../../ProjectDetails/components/ProjectSignList/components/signListViews/components/TableToggleComponent'
import ProjectDepartmentsCell from '../components/projectListTable/components/ProjectDepartmentsCell'
import ProjectDivisionsCell from '../components/projectListTable/components/ProjectDivisionsCell'
import PogSelectionCell from '../components/projectListTable/components/PogSelectionCell'
import TextCellEditor from '../components/projectListTable/components/TextCellEditor'
import SapTableProject from '../../../../../models/projects/SapTableProject.model'
import { useUserContext } from 'components/App/context/userContext'
import SelectCellEditor from '../components/projectListTable/components/SelectCellEditor'
import DateCellEditor from '../components/projectListTable/components/DateCellEditor'
import FundCellRender from '../components/projectListTable/components/FundCellRender'
import ToggleCellEditor from '../../../../ProjectDetails/components/ProjectSignList/components/signListViews/components/ToggleCellEditor'
import NumberCellEditor from '../components/projectListTable/components/NumberCellEditor'
import DivisionsCellEditor from '../components/projectListTable/components/DivisionsCellEditor'
import DepartmentCellEditor from '../components/projectListTable/components/DepartmentsCellEditor'
import { useBlueprintProjectListContext } from './blueprintProjectListContext'
import SapTableProjectRequest, {
  SapProjectRequest,
} from '../../../../../models/projects/SapTableProjectRequest.model'
import {
  BATCH_EDIT_ACTION,
  TOASTER_DEFAULTS,
} from 'components/App/constants/appConstants'
import { NEW_SIGN_PROJECT } from '../../SignProjectTab/constants/signProjectConstants'
import { Division } from '../../../../../models/merchandise/hierarchy/Division.model'
import { Department } from '../../../../../models/merchandise/hierarchy/Department.model'
import PogSelectionEditor from '../components/projectListTable/components/PogSelectionEditor'
import {
  pogSelectionValues,
  PROJECT_STATUS,
} from '../constants/blueprintProjectListConstants'
import { useAppContext } from 'components/App/context/appContext'
import { SortState } from '../../../../../models/agGrid/AgGridSortState.model'
import CampaignCellEditor from '../components/projectListTable/components/CampaignCellEditor'
import CampaignTypeCell from '../components/projectListTable/components/CampaignTypeCell'
import PidCellRenderer from '../components/projectListTable/components/PidCellRenderer'
import { useBlueprintDetailsContainerContext } from '../../../context/blueprintDetailsContainerContext'
import { v4 as uuidv4 } from 'uuid'
import { ProjectFund } from '../../../../../models/projects/ProjectFundInfo.model'
import { BlueprintCampaign } from '../../../../../models/blueprints/BlueprintCampaign.model'
import { clone, cloneDeep } from 'lodash'
import moment from 'moment'
import { DialogProps } from 'models/app/DialogProps.model'
import { useToaster } from '@enterprise-ui/canvas-ui-react'

type ContextType = {
  gridApi: GridApi | undefined
  setGridApi: (value: GridApi) => void
  gridColumnApi: ColumnApi | undefined
  setGridColumnApi: (value: ColumnApi) => void
  onGridReady: (event: GridReadyEvent) => void
  gridOptions: GridOptions
  rowData: SapTableProject[]
  setRowData: Function
  onFirstDataRender: () => void
  addNewProject: (quantity: number, rowIndex: number | null) => void
  duplicateSelectedProject: (rowNode: RowNode, quantity: number) => void
  cancelSelectedProjects: (selectedRows: any[] | undefined) => void
  onSelectionChange: (event: SelectionChangedEvent) => void
  selectedRows: RowNode[]
  setSelectedRows: Function
  onCellValueChange: (event: CellValueChangedEvent) => void
  validateProjectsAndSave: (nodes: RowNode[]) => void
  onFilterChange: (event: FilterChangedEvent) => void
  onSortChanged: (event: SortChangedEvent) => void
  sizeColumnsToFit: () => void
  autoSizeAllColumns: () => void
  resetColumnState: () => void
  resetAllFilters: () => void
}

export const AgGridProjectListContext = createContext<ContextType | undefined>(
  undefined,
)

type Props = {
  children: React.ReactNode
}

export const AgGridProjectListProviderComponent = ({ children }: Props) => {
  const { userPermissions } = useUserContext()!
  const { setDialogProps } = useAppContext()!
  const {
    blueprintProjectListSavedFilter,
    setBlueprintProjectListSavedFilter,
    blueprintProjectListSortState,
    setBlueprintProjectListSortState,
  } = useAppContext()!
  const { currentBlueprint } = useBlueprintDetailsContainerContext()!
  const {
    blueprintCampaigns,
    projectFunds,
    loadCampaignIds,
    setProjectsModified,
    modifiedProjects,
    setModifiedProjects,
    modifiedProjectIds,
    setModifiedProjectIds,
    removedProjects,
    setRemovedProjects,
    saveModifiedProjects,
  } = useBlueprintProjectListContext()!
  const makeToast = useToaster()

  const [gridApi, setGridApi] = useState<GridApi>()
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>()
  const [rowData, setRowData] = useState<SapTableProject[]>([])
  const [selectedRows, setSelectedRows] = useState<RowNode[]>([])

  const onGridReady = (event: GridReadyEvent) => {
    setGridApi(event.api)
    setGridColumnApi(event.columnApi)
  }

  const gridOptions = {
    animateRows: true,
    defaultColDef: {
      resizable: true,
      sortable: true,
      width: 148,
      menuTabs: ['filterMenuTab'],
      filterParams: {
        buttons: ['clear', 'reset'],
        newRowsAction: 'keep',
      },
    },
    columnTypes: {
      fixed: {
        lockPinned: true,
        resizable: false,
        suppressSizeToFit: true,
        pinned: 'left',
      },
      popUpCell: {
        cellClass: 'actions-button-cell',
      },
      requiredField: {
        headerClass: 'required-field',
        cellClassRules: {
          'required-field': (params: CellClassParams) =>
            params.data.workflow?.status === PROJECT_STATUS.ARCHIVE ||
            params.data.workflow?.status === PROJECT_STATUS.CANCEL ||
            params.data.toBeCancelled
              ? false
              : params.colDef.colId === 'fund_id'
              ? projectFunds.filter(
                  (fund: ProjectFund) =>
                    fund.fund_id === params.value?.toString(),
                ).length === 0
              : params.colDef.colId === 'divisions' ||
                params.colDef.colId === 'departments'
              ? params.value?.length === 0
              : params.colDef.colId === 'campaign'
              ? params.data.campaign?.id === ''
              : params.value === '' || params.value === undefined,
          'has-error': (params: CellClassParams) =>
            params.data.hasError
              ? params.data.workflow?.status === PROJECT_STATUS.ARCHIVE ||
                params.data.workflow?.status === PROJECT_STATUS.CANCEL ||
                params.data.toBeCancelled
                ? false
                : params.colDef.colId === 'fund_id'
                ? projectFunds.filter(
                    (fund: ProjectFund) =>
                      fund.fund_id === params.value?.toString(),
                  ).length === 0
                : params.colDef.colId === 'divisions' ||
                  params.colDef.colId === 'departments'
                ? params.value?.length === 0
                : params.colDef.colId === 'campaign'
                ? params.data.campaign?.id === ''
                : params.value === '' || params.value === undefined
              : false,
        },
      },
      selectedRow: {
        width: 70,
        maxWidth: 70,
        filter: false,
        sortable: false,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        rowDrag: true,
        rowDragText: rowDragText,
      },
      signMenu: {
        width: 36,
        maxWidth: 36,
        filter: false,
        sortable: false,
        cellRenderer: 'RowDropdownMenu',
        cellClass: 'actions-button-cell dropdown-menu',
      },
      pidCell: {
        cellRenderer: 'PidCell',
      },
      projectDetailLink: {
        cellRenderer: 'ProjectDetailLink',
      },
      campaignType: {
        cellRenderer: 'CampaignType',
      },
      fundName: {
        cellRenderer: 'FundName',
      },
      setDate: {
        valueGetter: (params: ValueGetterParams) => setDateValueGetter(params),
        filterParams: dateFilterParams,
        comparator: dateComparator,
      },
      inStoreDate: {
        valueFormatter: dateFormatter('in_store_date'),
        filterParams: dateFilterParams,
        comparator: dateComparator,
      },
      nrscReceiveDate: {
        valueFormatter: dateFormatter('nrsc_receive_date'),
        filterParams: dateFilterParams,
        comparator: dateComparator,
      },
      toggle: {
        cellRenderer: 'ToggleCell',
      },
      pogSelection: {
        cellRenderer: 'PogSelection',
      },
      pogSetDatesExport: {
        valueGetter: (params: ValueGetterParams) =>
          projectSetDatesValueGetter(params),
      },
      divisionCell: {
        cellRenderer: 'DivisionCell',
      },
      divisionExportCell: {
        valueGetter: (params: ValueGetterParams) =>
          divisionExportValueGetter(params),
      },
      departmentCell: {
        cellRenderer: 'DepartmentCell',
      },
      departmentExportCell: {
        valueGetter: (params: ValueGetterParams) =>
          departmentExportValueGetter(params),
      },
      dateEditor: {
        editable: (params: EditableCallbackParams) =>
          cellIsEditable(
            params.data,
            params.column.getColId(),
            userPermissions,
          ),
        cellEditor: 'DateEditor',
        cellEditorPopup: true,
      },
      textEditor: {
        editable: (params: EditableCallbackParams) =>
          cellIsEditable(
            params.data,
            params.column.getColId(),
            userPermissions,
          ),
        cellEditor: 'TextEditor',
        cellEditorPopup: true,
      },
      selectEditor: {
        editable: (params: EditableCallbackParams) =>
          cellIsEditable(
            params.data,
            params.column.getColId(),
            userPermissions,
          ),
        cellEditor: 'SelectEditor',
        cellEditorPopup: true,
      },
      campaignEditor: {
        editable: (params: EditableCallbackParams) =>
          cellIsEditable(
            params.data,
            params.column.getColId(),
            userPermissions,
          ),
        cellEditor: 'CampaignEditor',
        cellEditorPopup: true,
      },
      numberEditor: {
        editable: (params: EditableCallbackParams) =>
          cellIsEditable(
            params.data,
            params.column.getColId(),
            userPermissions,
          ),
        cellEditor: 'NumberEditor',
        cellEditorPopup: true,
      },
      toggleEditor: {
        editable: (params: EditableCallbackParams) =>
          cellIsEditable(
            params.data,
            params.column.getColId(),
            userPermissions,
          ),
        cellEditor: 'ToggleEditor',
        cellEditorPopup: true,
      },
      pogSelectionEditor: {
        editable: (params: EditableCallbackParams) =>
          cellIsEditable(
            params.data,
            params.column.getColId(),
            userPermissions,
          ),
        cellEditor: 'PogSelectionEditor',
        cellEditorPopup: true,
      },
      divisionsEditor: {
        editable: (params: EditableCallbackParams) =>
          cellIsEditable(
            params.data,
            params.column.getColId(),
            userPermissions,
          ),
        cellEditor: 'DivisionsEditor',
        cellEditorPopup: true,
      },
      departmentEditor: {
        editable: (params: EditableCallbackParams) =>
          cellIsEditable(
            params.data,
            params.column.getColId(),
            userPermissions,
          ),
        cellEditor: 'DepartmentEditor',
        cellEditorPopup: true,
      },
      hiddenColumn: {
        hide: true,
      },
    },
    frameworkComponents: {
      RowDropdownMenu: RowDropdownMenu,
      PidCell: PidCellRenderer,
      ProjectDetailLink: RowProjectDetailLink,
      CampaignType: CampaignTypeCell,
      FundName: FundCellRender,
      ToggleCell: TableToggleComponent,
      PogSelection: PogSelectionCell,
      DivisionCell: ProjectDivisionsCell,
      DepartmentCell: ProjectDepartmentsCell,
      DateEditor: DateCellEditor,
      TextEditor: TextCellEditor,
      SelectEditor: SelectCellEditor,
      CampaignEditor: CampaignCellEditor,
      NumberEditor: NumberCellEditor,
      ToggleEditor: ToggleCellEditor,
      PogSelectionEditor: PogSelectionEditor,
      DivisionsEditor: DivisionsCellEditor,
      DepartmentEditor: DepartmentCellEditor,
    },
  }

  const onFirstDataRender = () => {
    if (blueprintProjectListSavedFilter) {
      gridApi?.setFilterModel(blueprintProjectListSavedFilter)
    }
    if (blueprintProjectListSortState) {
      gridColumnApi?.applyColumnState({
        state: blueprintProjectListSortState,
        defaultState: { sort: null },
      })
    }
  }

  const addNewProject = async (
    quantity: number = 1,
    rowIndex: number | null,
  ) => {
    setProjectsModified(true)
    const editedProjects = modifiedProjects
    const editedProjectIds = modifiedProjectIds
    let newCampaign: BlueprintCampaign | undefined = undefined
    let newCampaignId = undefined

    // always use the first campaign from blueprintCampaigns on new request creation
    if (blueprintCampaigns.length === 1) {
      newCampaign = blueprintCampaigns[0]
      newCampaignId = await loadCampaignIds(
        newCampaign.name,
        currentBlueprint.set_date,
      )
    }

    for (let i = 0; i < quantity; i++) {
      const newProjectId = `${NEW_SIGN_PROJECT}:${uuidv4()}`
      editedProjects.push(
        new SapTableProjectRequest({
          request: new SapProjectRequest({
            blueprint_id: currentBlueprint.blueprint_id,
            project_id: newProjectId,
            set_date: currentBlueprint.set_date,
            campaign: newCampaign,
            campaign_id: newCampaignId,
          }),
          action: BATCH_EDIT_ACTION.ADD,
        }),
      )
      editedProjectIds.push(newProjectId)

      gridApi?.applyTransaction({
        addIndex: rowIndex === null ? i : rowIndex + i + 1,
        add: [
          new SapTableProject({
            blueprint_id: currentBlueprint.blueprint_id,
            project_id: newProjectId,
            set_date: currentBlueprint.set_date,
            name: 'New SAP Project Request',
            campaign: newCampaign,
            campaign_id: newCampaignId,
            modified: true,
          }),
        ],
      })
    }
    setModifiedProjects(editedProjects)
    setModifiedProjectIds(editedProjectIds)
  }

  const duplicateSelectedProject = (rowNode: RowNode, quantity: number = 1) => {
    setProjectsModified(true)
    const editedProjects = modifiedProjects
    const editedProjectIds = modifiedProjectIds
    const { data, rowIndex } = rowNode

    for (let i = 0; i < quantity; i++) {
      const dupedProjectId = `${NEW_SIGN_PROJECT}:${uuidv4()}::duplicate`

      editedProjects.push(
        new SapTableProjectRequest({
          request: new SapProjectRequest({
            ...data,
            project_id: dupedProjectId,
            sap_project_id: undefined,
            short_name: '',
            in_store_date: null,
            nrsc_receive_date: null,
          }),
          action: BATCH_EDIT_ACTION.ADD,
        }),
      )
      editedProjectIds.push(dupedProjectId)

      gridApi?.applyTransaction({
        addIndex: rowIndex === null ? i : rowIndex + i + 1,
        add: [
          new SapTableProject({
            ...data,
            project_id: dupedProjectId,
            name: 'Duplicated SAP Project Request',
            short_name: '',
            sap_project_id: undefined,
            sap_project_execution_status: undefined,
            sap_project_execution_message: undefined,
            modified: true,
            hasError: false,
            in_store_date: null,
            nrsc_receive_date: null,
          }),
        ],
      })
    }
    setModifiedProjects(editedProjects)
    setModifiedProjectIds(editedProjectIds)
  }

  const cancelSelectedProjects = (selectedRows: RowNode[] | undefined) => {
    setProjectsModified(true)
    const projectsToBeCancelled = removedProjects
    let editedProjects: SapTableProjectRequest[] = modifiedProjects
    let editedProjectIds: string[] = modifiedProjectIds
    let rowsToBeCancelled: RowNode[] = []

    if (selectedRows && selectedRows.length > 0) {
      selectedRows?.forEach((row: RowNode, i: number) => {
        const data = row.data
        data.toBeCancelled = true
        data.modified = false
        data.hasError = false
        rowsToBeCancelled.push(data)
        // if project (row) is already modified, remove it from modified project lists
        if (editedProjectIds.includes(data.project_id)) {
          editedProjects = editedProjects.filter(
            (project: SapTableProjectRequest) =>
              project.request?.project_id !== data.project_id,
          )
          editedProjectIds = editedProjectIds.filter(
            (id: string) => id !== data.project_id,
          )
        }
        // if project (row) is not a new project, add it to removed projects list
        if (data.project_id.split(':')[0] !== NEW_SIGN_PROJECT) {
          projectsToBeCancelled.push(
            new SapTableProjectRequest({
              request: new SapProjectRequest(data),
              id: data.project_id,
              action: BATCH_EDIT_ACTION.DELETE,
            }),
          )
        }
      })
      setRemovedProjects(projectsToBeCancelled)
      setModifiedProjects(editedProjects)
      setModifiedProjectIds(editedProjectIds)
      gridApi?.applyTransaction({ update: rowsToBeCancelled })
      setSelectedRows([])
      gridApi?.deselectAll()
    }
  }

  const onSelectionChange = (event: SelectionChangedEvent) => {
    if (event.api.getSelectedNodes().length > 0) {
      setSelectedRows(event.api.getSelectedNodes())
    } else {
      setSelectedRows([])
    }
  }

  const onCellValueChange = async (event: CellValueChangedEvent) => {
    if (event.newValue !== event.oldValue) {
      if (event.column.getColId() === 'in_store_date') {
        const shouldNotContinue = await new Promise((resolve) => {
          setDialogProps(
            new DialogProps({
              headingText:
                'Changing the In-Store Date will change the NRSC Receive Date?',
              approveButtonText: 'Continue',
              onApprove: () => {
                resolve(false)
                setDialogProps(new DialogProps({}))
              },
              refuseButtonText: 'Cancel',
              onRefuse: () => {
                resolve(true)
                setDialogProps(new DialogProps({}))
              },
            }),
          )
        })

        if (shouldNotContinue) {
          return
        }
      }
      setProjectsModified(true)
      event.data.modified = true
      event.data.hasError = false

      const setCampaignId = async () => {
        const newCampaignId = await loadCampaignIds(
          event.node.data.campaign.name,
          event.node.data.set_date,
        )
        if (newCampaignId) {
          event.node.setDataValue('campaign_id', newCampaignId)
        } else {
          event.node.setDataValue('campaign_id', '')
        }
      }

      const setPogSelection = () => {
        return event.newValue.use_marketing_plan_date
          ? pogSelectionValues.MARKETING_PLAN_DATE
          : event.newValue.use_multiple_set_date
          ? pogSelectionValues.MULTIPLE_SET_DATES
          : pogSelectionValues.PROJECT_SET_DATE
      }

      if (event.column.getColId() === 'pog_set_date') {
        event.node.setDataValue('pog_selection', setPogSelection())
      }

      if (
        event.column.getColId() === 'campaign' ||
        event.column.getColId() === 'set_date'
      ) {
        setCampaignId()
      }

      if (
        event.column.getColId() === 'divisions' &&
        event.data.divisions.length === 0
      ) {
        event.node.setDataValue('departments', [])
      }

      if (modifiedProjectIds.includes(event.data.project_id)) {
        setModifiedProjects((previousState: SapTableProjectRequest[]) => {
          const clonedProjects = cloneDeep(previousState)
          clonedProjects.map(
            (project: SapTableProjectRequest, index: number) =>
              project.request?.project_id === event.data.project_id
                ? (clonedProjects[index] = new SapTableProjectRequest({
                    request: new SapProjectRequest({
                      ...event.data,
                      set_date: moment(event.data.set_date).format(
                        'YYYY-MM-DD',
                      ),
                      pog_selection: setPogSelection(),
                      division_ids: event.data.divisions.map(
                        (div: Division) => div.division_id,
                      ),
                      department_ids: event.data.departments.map(
                        (dept: Department) => dept.department_id,
                      ),
                    }),
                    id:
                      event.data.project_id.split(':')[0] === NEW_SIGN_PROJECT
                        ? undefined
                        : event.data.project_id,
                    action:
                      event.data.project_id.split(':')[0] === NEW_SIGN_PROJECT
                        ? BATCH_EDIT_ACTION.ADD
                        : BATCH_EDIT_ACTION.UPDATE,
                  }))
                : (clonedProjects[index] = new SapTableProjectRequest(project)),
          )
          return clonedProjects
        })
      } else {
        setModifiedProjects((previousState: SapTableProjectRequest[]) => {
          const clonedProjects = cloneDeep(previousState)
          clonedProjects.push(
            new SapTableProjectRequest({
              request: new SapProjectRequest({
                ...event.data,
                set_date: moment(event.data.set_date).format('YYYY-MM-DD'),
                pog_selection: setPogSelection(),
                division_ids: event.data.divisions.map(
                  (divison: Division) => divison.division_id,
                ),
                department_ids: event.data.departments.map(
                  (dept: Department) => dept.department_id,
                ),
              }),
              id: event.data.project_id,
              action:
                event.data.project_id.split(':')[0] === NEW_SIGN_PROJECT
                  ? BATCH_EDIT_ACTION.ADD
                  : BATCH_EDIT_ACTION.UPDATE,
            }),
          )
          return clonedProjects
        })
        setModifiedProjectIds((previousState: string[]) => {
          const clonedIds = clone(previousState)
          clonedIds.push(event.data.project_id)
          return clonedIds
        })
      }

      event.column.getColDef().cellClass = 'ag-cell-modified'
      event.api.refreshCells({
        force: true,
        columns: [event.column.getColId()],
        rowNodes: [event.node],
      })
    }
  }

  const validateProjectsAndSave = (nodes: RowNode[]) => {
    let validatedRows: RowNode[] = []
    nodes.map((node: RowNode) => {
      const data: SapTableProject = node.data
      for (const [field, value] of Object.entries(data)) {
        switch (field) {
          case 'short_name':
          case 'project_type':
          case 'initial_budget':
          case 'tactic':
          case 'fund_id':
          case 'campaign_id':
            if (value === '' || value === undefined) {
              data.hasError = true
            }
            break
          case 'divisions':
          case 'departments':
            if (value.length < 1) {
              data.hasError = true
            }
            break
          case 'campaign':
            if (value.id === '') {
              data.hasError = true
            }
            break
          default:
            break
        }
      }
      validatedRows.push(node)
      return validatedRows
    })
    const rowsWithErrors = validatedRows.filter(
      (row: RowNode) => row.data.hasError,
    )
    if (rowsWithErrors.length > 0) {
      gridApi?.applyTransaction({ update: rowsWithErrors })
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Missing Required Fields',
        message: 'Please fill out all required fields',
      })
    } else {
      saveModifiedProjects()
    }
  }

  const onFilterChange = (event: FilterChangedEvent) => {
    setBlueprintProjectListSavedFilter(event.api.getFilterModel())
  }

  const onSortChanged = (event: SortChangedEvent) => {
    setBlueprintProjectListSortState(
      event.columnApi
        .getColumnState()
        .filter((s) => s.sort != null)
        .map((s) => new SortState(s)),
    )
  }

  const sizeColumnsToFit = () => {
    gridApi && gridApi.sizeColumnsToFit()
  }

  const autoSizeAllColumns = () => {
    gridColumnApi && gridColumnApi.autoSizeAllColumns()
  }

  const resetColumnState = () => {
    gridColumnApi && gridColumnApi.resetColumnState()
  }

  const resetAllFilters = () => {
    gridApi && gridApi.setFilterModel({})
    gridColumnApi &&
      gridColumnApi.applyColumnState({ defaultState: { sort: null } })
  }

  return (
    <AgGridProjectListContext.Provider
      value={{
        gridApi,
        setGridApi,
        gridColumnApi,
        setGridColumnApi,
        onGridReady,
        gridOptions,
        rowData,
        setRowData,
        onFirstDataRender,
        addNewProject,
        duplicateSelectedProject,
        cancelSelectedProjects,
        onSelectionChange,
        setSelectedRows,
        selectedRows,
        onCellValueChange,
        validateProjectsAndSave,
        onFilterChange,
        onSortChanged,
        sizeColumnsToFit,
        autoSizeAllColumns,
        resetColumnState,
        resetAllFilters,
      }}
    >
      {children}
    </AgGridProjectListContext.Provider>
  )
}

export const useAgGridProjectListContext = () =>
  useContext(AgGridProjectListContext)

export const AgGridProjectListProvider = connect(
  null,
  null,
)(AgGridProjectListProviderComponent)
