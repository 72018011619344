import { Column } from '../../../../models/agGrid/AgGridColumn.model'

export const NOTIFICATIONS_VIEW = [
  new Column({
    type: ['selectedRow'],
  }),
  new Column({
    field: 'name',
    headerName: 'Header',
    width: 260,
    type: ['notificationHeader'],
    filter: 'agSetColumnFilter',
  }),
  new Column({
    field: 'status',
    headerName: 'Status',
    type: ['hiddenColumn'],
  }),
  new Column({
    field: 'type',
    headerName: 'Type',
    width: 200,
    type: ['defaultChip', 'notificationType'],
    filter: 'agSetColumnFilter',
  }),
  new Column({
    field: 'project.sap_project_id',
    headerName: 'PID',
    width: 200,
    type: ['textFilter'],
  }),
  new Column({
    field: 'project.project_name',
    headerName: 'Project Name',
    width: 300,
    type: ['textFilter'],
  }),
  new Column({
    field: 'description',
    headerName: 'Description',
    width: 700,
    type: ['descriptionPopover', 'textFilter'],
  }),
  new Column({
    field: 'create_timestamp',
    headerName: 'Created Date',
    width: 180,
    filter: 'agDateColumnFilter',
    type: ['formattedDate'],
  }),
  new Column({
    field: 'nav_link',
    headerName: 'Link',
    width: 100,
    type: ['iconLink'],
  }),
]
