import { useProjectDetailsContext } from '../../../context/projectDetailsContext'
import React, { Fragment, useEffect } from 'react'
import ProjectDistributionSummary from './ProjectDistributionSummary'
import KitRecreationMessage from './KitRecreationMessage'
import { useProjectDistributionContext } from '../context/projectDistributionContext'
import ProjectDistributionKits from './ProjectDistributionKits'
import { KitScenarioStatusNames } from '../../../../../models/kits/KitScenarioStatus.model'
import { useMountEffect } from 'components/App/hooks/useMountEffect'
import { useNavigate } from 'react-router-dom'

const ProjectDistributionTab = () => {
  const { currentProject, warningStatuses } = useProjectDetailsContext()!
  const {
    kitInfoResponse,
    getKitInfo,
    refreshSigns,
    signs,
    activeKit,
    doReloadPage,
  } = useProjectDistributionContext()!

  useMountEffect(() => {
    if (signs.length === 0) {
      refreshSigns(currentProject.project_id)
    }
  })

  const navigate = useNavigate()

  useEffect(() => {
    if (doReloadPage) {
      navigate(
        `/projects/${currentProject.project_id}?tab=KITTIING/DISTRIBUTION&kit=${activeKit}`,
      )
      window.location.reload()
    }
  }, [doReloadPage, currentProject, activeKit, navigate])

  useEffect(() => {
    if (kitInfoResponse.id === undefined) {
      getKitInfo(currentProject.project_id)
    }
  }, [kitInfoResponse, currentProject.project_id, getKitInfo])
  return (
    <Fragment>
      <div className="hc-pa-dense hc-pa-none">
        {warningStatuses.includes(currentProject.status) ? (
          <KitRecreationMessage />
        ) : null}
        {kitInfoResponse?.kit_summary &&
          kitInfoResponse.kit_scenario?.status.name !==
            KitScenarioStatusNames.RUNNING && (
            <ProjectDistributionSummary kittingInfo={kitInfoResponse} />
          )}
        <ProjectDistributionKits
          kittingInfo={kitInfoResponse}
          currentProject={currentProject}
        />
      </div>
    </Fragment>
  )
}

export default ProjectDistributionTab
