import React, { useState, useContext, createContext } from 'react'
import { connect } from 'react-redux'
import Axios from 'axios'
import { useAppContext } from '../../App/context/appContext'
import {
  SIGN_SERVICE_API_DOMAIN_URL,
  HEADER_OBJECT,
  TOASTER_DEFAULTS,
} from '../../App/constants/appConstants'
import { useEnv } from '@praxis/component-runtime-env'
import { cloneDeep, set, unset } from 'lodash'
import SignBulkEditFields from '../../../models/signs/SignBulkEditFields.model'
import { useUserContext } from '../../App/context/userContext'
import { useToaster } from '@enterprise-ui/canvas-ui-react'

type ContextType = {
  bulkEditFields: any
  isSignBulkNotesFormOpen: boolean
  setBulkEditFields: (fields: any) => void
  addBulkEditField: (field: string, value?: any) => void
  removeBulkEditField: (field: string) => void
  saveBulkSigns: (
    signIds: string[],
    projectId: string,
    reasonCode: string,
  ) => void
  setIsSignBulkNotesFormOpen: (isOpen: boolean) => void
  selectedFields: string[]
  setSelectedFields: Function
}

export const SignBulkEditContext = createContext<ContextType | undefined>(
  undefined,
)

type Props = {
  children: React.ReactNode
}

const SignBulkEditProviderComponent = ({ children }: Props) => {
  const { setFullPageLoadingMessage } = useAppContext()!
  const { userId } = useUserContext()!
  const env = useEnv()
  const makeToast = useToaster()
  const [bulkEditFields, setBulkEditFields] = useState({})
  const [isSignBulkNotesFormOpen, setIsSignBulkNotesFormOpen] = useState(false)
  const [selectedFields, setSelectedFields] = useState([])

  const addBulkEditField = (field: string, value?: any) => {
    const clonedFields = cloneDeep(bulkEditFields)
    set(clonedFields, field, value)
    setBulkEditFields(clonedFields)
  }

  const removeBulkEditField = (field: string) => {
    const clonedFields: any = cloneDeep(bulkEditFields)
    unset(clonedFields, field)
    if (field === 'distribution.location_filter_info') {
      unset(clonedFields, 'distribution.quantity_per_store')
    }
    if (field === 'distribution.kit_groups') {
      unset(clonedFields, 'distribution.kit_group_quantities')
    }

    setBulkEditFields(clonedFields)
  }

  const saveBulkSigns = async (
    signIds: string[],
    projectId: string,
    reasonCode?: string,
  ) => {
    setFullPageLoadingMessage('Saving Signs...')
    try {
      let fields = new SignBulkEditFields(bulkEditFields)
      await Axios.put(
        `${env.apiDomainUrl + SIGN_SERVICE_API_DOMAIN_URL}/signs/bulk_edit`,
        {
          sign_ids: signIds,
          project_id: projectId,
          fields: fields,
          reason_code: reasonCode ? reasonCode : undefined,
          updated_by: userId,
        },
        HEADER_OBJECT,
      )
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'success',
        heading: 'Bulk Signs Updated',
        message: 'Successfully updated multiple signs',
      })
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Update Signs',
        message: err.response.data.message,
      })
    }
    setFullPageLoadingMessage('')
    setBulkEditFields({})
  }

  return (
    <SignBulkEditContext.Provider
      value={{
        bulkEditFields,
        isSignBulkNotesFormOpen,
        setBulkEditFields,
        addBulkEditField,
        removeBulkEditField,
        saveBulkSigns,
        setIsSignBulkNotesFormOpen,
        selectedFields,
        setSelectedFields,
      }}
    >
      {children}
    </SignBulkEditContext.Provider>
  )
}

export const SignBulkEditProvider = connect(
  null,
  null,
)(SignBulkEditProviderComponent)

export const useSignBulkEditContext = () => useContext(SignBulkEditContext)
