import React, { Fragment } from 'react'
import {
  Button,
  Grid,
  Popover,
  Divider,
  Card,
  Modal,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { PlusIcon } from '@enterprise-ui/icons'
import { useMilestoneContext } from 'components/App/context/milestoneContext'
import AddMilestoneForm from './AddScheduleMilestoneForm'
import { MilestoneTemplate } from '../../../../../models/milestones/MilestoneTemplate.model'
import AdminScheduleMilestoneCard from './AdminScheduleMilestoneCard'
import EditScheduleTemplate from './EditScheduleTemplate'
import AdminScheduleActionsDropdown from './AdminScheduleActionsDropdown'
import { useAdminMilestoneContext } from '../../context/adminMilestoneContext'
import AdminSchedulingTemplateModal from './AdminScheduleTemplateModal'
import { upperFirst } from 'lodash'
import RubixChip from 'components/common/RubixChip'

const AdminScheduleTab = () => {
  const {
    scheduleTemplateByScheduleTemplateId,
    updateScheduleTemplateByScheduleTemplateId,
  } = useMilestoneContext()!
  const {
    closeMilestoneForm,
    closeTemplateForm,
    isScheduleTemplateModalOpen,
    setIsScheduleTemplateOpen,
    isEditTemplateName,
  } = useAdminMilestoneContext()!

  return (
    <Grid.Container className="sem-MilestonesTab">
      <Grid.Item xs={true}>
        <Grid.Container justify={'flex-start'}>
          <Grid.Item xs={12}>
            {/* Milestone Card */}
            <Card className="sem_ProjectDetailsCard" elevation={0}>
              <div className="hc-pa-normal hc-pv-none">
                <Grid.Container
                  className="sem_Overview"
                  align="center"
                  justify="space-between"
                >
                  <Grid.Item className="hc-pv-none">
                    <span className="sem_OverviewTitle">
                      Project Schedule Type:{' '}
                    </span>
                    <RubixChip data-testid="statusChip">
                      {scheduleTemplateByScheduleTemplateId.schedule_type
                        ? upperFirst(
                            scheduleTemplateByScheduleTemplateId.schedule_type,
                          )
                        : 'N/A'}
                    </RubixChip>
                  </Grid.Item>
                  <Grid.Item className="hc-pv-none">
                    <span className="sem_OverviewTitle">
                      Schedule Template:{' '}
                    </span>
                    <RubixChip data-testid="statusChip">
                      {scheduleTemplateByScheduleTemplateId.template_name
                        ? upperFirst(
                            scheduleTemplateByScheduleTemplateId.template_name,
                          )
                        : 'N/A'}
                    </RubixChip>
                  </Grid.Item>
                  <Grid.Item className="hc-pv-none">
                    <span className="sem_OverviewTitle">Master Template: </span>{' '}
                    <RubixChip
                      data-testid="statusChip"
                      color={
                        scheduleTemplateByScheduleTemplateId.master_template
                          ? 'green'
                          : 'yellow'
                      }
                    >
                      {scheduleTemplateByScheduleTemplateId.master_template
                        ? 'True'
                        : 'False'}
                    </RubixChip>
                  </Grid.Item>
                </Grid.Container>
              </div>
            </Card>
          </Grid.Item>
          <Grid.Item>
            <Popover
              type="clickOnly"
              location="bottom-left"
              size="expanded"
              className="sem-AddMilestonePopover"
              content={<EditScheduleTemplate />}
              onRequestClose={closeTemplateForm}
            >
              <Button
                fullWidth
                type="primary"
                className="hc-mt-normal hc-ta-justify"
              >
                Edit Template
              </Button>
            </Popover>
          </Grid.Item>
          <Grid.Item>
            <Button
              type="ghost"
              fullWidth
              className="hc-mt-normal hc-ta-justify"
              onClick={() => setIsScheduleTemplateOpen(true)}
            >
              <EnterpriseIcon className="hc-mr-normal" icon={PlusIcon} />
              Add Template
            </Button>
          </Grid.Item>
          {!scheduleTemplateByScheduleTemplateId.template_id ? null : (
            <Grid.Item>
              <Popover
                type="clickOnly"
                location="bottom-left"
                size="expanded"
                className="sem-AddMilestonePopover"
                content={<AddMilestoneForm />}
                onRequestClose={closeMilestoneForm}
              >
                <Button
                  type="ghost"
                  fullWidth
                  className="hc-mt-normal hc-ta-justify"
                >
                  <EnterpriseIcon className="hc-mr-normal" icon={PlusIcon} />
                  Add Milestone
                </Button>
              </Popover>
            </Grid.Item>
          )}
          {!scheduleTemplateByScheduleTemplateId.template_id ? null : (
            <Grid.Item className="scheduleTemplateDropdown">
              <AdminScheduleActionsDropdown
                scheduleTemplateByScheduleTemplateId={
                  scheduleTemplateByScheduleTemplateId
                }
              />
            </Grid.Item>
          )}
          <Modal
            headingText={
              isEditTemplateName
                ? 'Edit Schedule Template Name'
                : 'New Schedule Template Name'
            }
            onRefuse={() => {
              setIsScheduleTemplateOpen(!isScheduleTemplateModalOpen)
            }}
            isVisible={isScheduleTemplateModalOpen}
          >
            <AdminSchedulingTemplateModal />
          </Modal>
          {!scheduleTemplateByScheduleTemplateId.template_id ? null : (
            <Grid.Item xs={2}>
              <Button
                className="hc-mt-normal"
                fullWidth
                type="primary"
                data-testid="saveMilestones"
                onClick={() => {
                  updateScheduleTemplateByScheduleTemplateId(
                    scheduleTemplateByScheduleTemplateId,
                  )
                }}
              >
                Save Changes
              </Button>
            </Grid.Item>
          )}
          <Grid.Item xs={12}>
            {scheduleTemplateByScheduleTemplateId.template_id ? (
              <Fragment>
                <Grid.Container className="hc-bg-grey06 rounded" spacing="none">
                  <Grid.Item className="phase-GridItem" xs={3}>
                    <div className="hc-pa-dense phase-Container">
                      <p className="title-name hc-mb-none">
                        Strategy &amp; Planning
                      </p>
                      <Divider className="phase-Divider" width={3} />
                      {scheduleTemplateByScheduleTemplateId.milestones
                        .filter(
                          (milestone: MilestoneTemplate) =>
                            milestone.project_phase === 'Strategy & Planning',
                        )
                        .sort((a: any, b: any) => b['days_out'] - a['days_out'])
                        .map((filteredMilestone) => (
                          <AdminScheduleMilestoneCard
                            milestone={filteredMilestone}
                            key={filteredMilestone.milestone_id}
                          />
                        ))}
                    </div>
                  </Grid.Item>

                  <Grid.Item className="phase-GridItem" xs={3}>
                    <div className="hc-pa-dense phase-Container">
                      <p className="title-name hc-mb-none">
                        Creative Development
                      </p>
                      <Divider className="phase-Divider" width={3} />
                      {scheduleTemplateByScheduleTemplateId.milestones
                        .filter(
                          (milestone: MilestoneTemplate) =>
                            milestone.project_phase === 'Creative Development',
                        )
                        .sort((a: any, b: any) => b['days_out'] - a['days_out'])
                        .map((filteredMilestone) => (
                          <AdminScheduleMilestoneCard
                            milestone={filteredMilestone}
                            key={filteredMilestone.milestone_id}
                          />
                        ))}
                    </div>
                  </Grid.Item>

                  <Grid.Item className="phase-GridItem" xs={3}>
                    <div className="hc-pa-dense phase-Container">
                      <p className="title-name hc-mb-none">
                        Sign List Finalization
                      </p>
                      <Divider className="phase-Divider" width={3} />
                      {scheduleTemplateByScheduleTemplateId.milestones
                        .filter(
                          (milestone: MilestoneTemplate) =>
                            milestone.project_phase ===
                            'Sign List Finalization',
                        )
                        .sort((a: any, b: any) => b['days_out'] - a['days_out'])
                        .map((filteredMilestone) => (
                          <AdminScheduleMilestoneCard
                            milestone={filteredMilestone}
                            key={filteredMilestone.milestone_id}
                          />
                        ))}
                    </div>
                  </Grid.Item>

                  <Grid.Item className="phase-GridItem" xs={3}>
                    <div className="hc-pa-dense phase-Container">
                      <p className="title-name hc-mb-none">
                        Production to Market
                      </p>
                      <Divider className="phase-Divider" width={3} />
                      {scheduleTemplateByScheduleTemplateId.milestones
                        .filter(
                          (milestone: MilestoneTemplate) =>
                            milestone.project_phase === 'Production to Market',
                        )
                        .sort((a: any, b: any) => b['days_out'] - a['days_out'])
                        .map((filteredMilestone) => (
                          <AdminScheduleMilestoneCard
                            milestone={filteredMilestone}
                            key={filteredMilestone.milestone_id}
                          />
                        ))}
                    </div>
                  </Grid.Item>
                </Grid.Container>
              </Fragment>
            ) : (
              <p className="hc-clr-grey02 hc-mb-normal">
                Please select a Master Template or Add a Master Template.
              </p>
            )}
          </Grid.Item>
        </Grid.Container>
      </Grid.Item>
    </Grid.Container>
  )
}

export default AdminScheduleTab
