import React, { useEffect, useState } from 'react'
import {
  Grid,
  ExpandableSection,
  Divider,
  Button,
  Form,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { PencilIcon, TrashIcon } from '@enterprise-ui/icons'
import Truncate from 'react-truncate'
import { DateFormatter } from '@enterprise-ui/canvas-ui-react-date'
import { BlueprintNote } from '../../../../../models/blueprints/BlueprintNote.model'
import { useBlueprintNotesContext } from '../context/blueprintNotesContext'
import { useUserContext } from 'components/App/context/userContext'
export interface Props {
  blueprintId: string
  note: BlueprintNote
}

const BlueprintNotesItem = ({ blueprintId, note }: Props) => {
  const { deleteBlueprintNote, updateBlueprintNote } =
    useBlueprintNotesContext()!
  const [isExpanded, setIsExpanded] = useState(false)
  const { author, timestamp, content } = note
  const { userName } = useUserContext()!
  const [editNote, setEditNote] = useState(false)
  const [showEditControls, setShowEditControls] = useState(true)
  const [noteContent, setNoteContent] = useState(note.content)
  const [editCanceled, setEditCanceled] = useState(false)
  const [noteChanged, setNoteChanged] = useState(false)

  const updateNote = (newNoteContent: string) => {
    if (!editCanceled) {
      note.content = newNoteContent
      note.author = userName
      note.timestamp = new Date().toISOString()
      updateBlueprintNote(blueprintId, note)
    }
  }
  useEffect(() => {
    setNoteContent(note.content)
  }, [note.content])

  return (
    <Grid.Item className="sem_Notes open" xs={12}>
      <ExpandableSection
        onExpand={() => setIsExpanded(!isExpanded)}
        expanded={isExpanded}
        padding="none"
      >
        <Grid.Container justify="space-between">
          <Grid.Item xs={6}>
            {isExpanded ? (
              <pre className="sem_NotesContent hc-pl-none">
                {content.split('\n')[0]}
              </pre>
            ) : (
              <Truncate width={420}>{content.split('\n')[0]}</Truncate>
            )}
          </Grid.Item>
          <Grid.Item>
            <p className="sem_NotesByline">
              <DateFormatter format="LLL" date={timestamp} /> by {author}
            </p>
          </Grid.Item>
        </Grid.Container>
        <ExpandableSection.Content>
          <div className="hc-pa-dense hc-pr-none hc-pt-none">
            <Grid.Container className="sem_StandardForm error_messaging_enabled">
              <Grid.Item xs={true}>
                <pre className="sem_NotesContent">
                  {content.split('\n').length > 1
                    ? content.substring(content.indexOf('\n') + 1)
                    : ''}
                </pre>
              </Grid.Item>
              {!showEditControls && !editCanceled ? (
                <>
                  <Grid.Item xs={12}>
                    <Form.Field
                      size="dense"
                      className="hc-mt-none"
                      id="edit_note"
                      type="textarea"
                      label="Edit Note"
                      value={noteContent}
                      hintText={
                        noteChanged && noteContent.length < 1
                          ? 'Please enter the updated note.'
                          : ''
                      }
                      onChange={(e: { target: { value: any } }) => {
                        setNoteChanged(true)
                        setNoteContent(e.target.value)
                      }}
                    />
                  </Grid.Item>
                </>
              ) : null}
            </Grid.Container>
            {showEditControls || editCanceled ? (
              <Grid.Container direction="row-reverse">
                <Grid.Item>
                  <Button
                    type="ghost"
                    size="dense"
                    onClick={() => {
                      deleteBlueprintNote(blueprintId, note.id)
                      setIsExpanded(!isExpanded)
                    }}
                  >
                    {' '}
                    <EnterpriseIcon
                      className="hc-pr-dense"
                      size="inline"
                      icon={TrashIcon}
                    />
                    Delete
                  </Button>
                  <Button
                    type="ghost"
                    size="dense"
                    onClick={() => {
                      setEditNote(!editNote)
                      setShowEditControls(false)
                      setEditCanceled(false)
                    }}
                  >
                    {' '}
                    <EnterpriseIcon
                      className="hc-pr-dense"
                      size="inline"
                      icon={PencilIcon}
                    />
                    Edit
                  </Button>
                </Grid.Item>{' '}
              </Grid.Container>
            ) : (
              <Grid.Container direction="row-reverse">
                <Grid.Item>
                  <Button
                    disabled={!noteChanged || noteContent.length < 1}
                    size="dense"
                    type="primary"
                    onClick={() => {
                      setShowEditControls(true)
                      updateNote(noteContent)
                      setIsExpanded(!isExpanded)
                      setNoteContent(note.content)
                    }}
                  >
                    Update
                  </Button>
                </Grid.Item>

                <Grid.Item>
                  <Button
                    type="secondary"
                    size="dense"
                    onClick={() => {
                      setEditCanceled(true)
                    }}
                  >
                    Cancel
                  </Button>
                </Grid.Item>
              </Grid.Container>
            )}
          </div>
        </ExpandableSection.Content>
      </ExpandableSection>
      <Divider className="hc-mt-dense" />
    </Grid.Item>
  )
}

export default BlueprintNotesItem
