import React from 'react'
import {
  Badge,
  Button,
  Grid,
  List,
  Popover,
  Table,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { CaretDownIcon, InfoIcon } from '@enterprise-ui/icons'
import SignResponse from '../../../../../../../models/signs/SignResponse.model'
import { useAppContext } from 'components/App/context/appContext'
import {
  getSignStatusCount,
  viewFilterRules,
} from '../../../helpers/signListHelper'

interface Props {
  signList: SignResponse[]
}

const SignCountDropdown = ({ signList }: Props) => {
  const { projectSignListView } = useAppContext()!

  const dropdownContent = (
    <Grid.Container>
      <Grid.Item className="hc-pv-dense" xs={12}>
        <Table className="hc-clr-grey01">
          <Table.Head>
            <Table.Row>
              <Table.Data xs={8}>SIGN STATUS</Table.Data>
              <Table.Data xs={4} className="hc-ta-right">
                SIGN COUNT
              </Table.Data>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            <Table.Row>
              <Table.Data xs={8}>New</Table.Data>
              <Table.Data xs={4} className="hc-ta-right">
                {signList.length > 0 ? getSignStatusCount(signList, 'New') : 0}
              </Table.Data>
            </Table.Row>
            <Table.Row>
              <Table.Data xs={8}>Reissue</Table.Data>
              <Table.Data xs={4} className="hc-ta-right">
                {signList.length > 0
                  ? getSignStatusCount(signList, 'Reissue')
                  : 0}
              </Table.Data>
            </Table.Row>
            <Table.Row>
              <Table.Data xs={8}>Carry Forward</Table.Data>
              <Table.Data xs={4} className="hc-ta-right">
                {signList.length > 0
                  ? getSignStatusCount(signList, 'Carry Forward')
                  : 0}
              </Table.Data>
            </Table.Row>
            <Table.Row>
              <Table.Data xs={8}>Remove</Table.Data>
              <Table.Data xs={4} className="hc-ta-right">
                {signList.length > 0
                  ? getSignStatusCount(signList, 'Remove')
                  : 0}
              </Table.Data>
            </Table.Row>
          </Table.Body>
        </Table>
        <List>
          <List.Item className="hc-pa-none">
            <p className="sem_ProductHighlights--ListItem">
              <EnterpriseIcon
                size="inline"
                className="hc-mr-dense"
                icon={InfoIcon}
              />
              {viewFilterRules(projectSignListView)}
            </p>
          </List.Item>
        </List>
      </Grid.Item>
    </Grid.Container>
  )
  return (
    <Grid.Item className="hc-ph-dense hc-bp-dense">
      <Popover
        content={dropdownContent}
        size="expanded"
        location="bottom-right"
        type="clickOnly"
      >
        <Button type="ghost">
          <EnterpriseIcon className="hc-mr-dense" icon={CaretDownIcon} />
          Sign Count:{' '}
          <Badge
            aria-label="sign_count"
            className="hc-ml-dense"
            location="inline"
            content={signList.length.toString()}
          />
        </Button>
      </Popover>
    </Grid.Item>
  )
}

export default SignCountDropdown
