import React from 'react'
import { Layout } from '@enterprise-ui/canvas-ui-react'
import PageChangesWrapper from '../App/components/PageChangesWrapper'
import BlueprintSearch from './components/BlueprintSearch'
import { BlueprintFilterProvider } from './context/blueprintFilterContext'
import { BlueprintListProvider } from './context/blueprintListContext'

const BlueprintList = (props: any) => (
  <PageChangesWrapper>
    <BlueprintListProvider>
      <BlueprintFilterProvider>
        <Layout.Body className="hc-pa-expanded" includeRail>
          <BlueprintSearch {...props} />
        </Layout.Body>
      </BlueprintFilterProvider>
    </BlueprintListProvider>
  </PageChangesWrapper>
)

export default BlueprintList
