import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { connect } from 'react-redux'
import { useEnv } from '@praxis/component-runtime-env'
import Axios from 'axios'
import {
  CellClassParams,
  CellValueChangedEvent,
  ColDef,
  Column,
  ColumnApi,
  EditableCallbackParams,
  GetDetailRowDataParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  IServerSideGetRowsParams,
  RowHeightParams,
  RowNode,
  ValueGetterParams,
} from 'ag-grid-community'
import moment from 'moment'
import { useSignLibrarySSRMServerContext } from './signLibrarySSRMServerContext'
import {
  dateValueGetter,
  detailDateComparator,
  detailDateFilterParams,
  isEditable,
  signLibraryGridComponents,
} from '../helpers/signLibraryHelpers'
import { useFilterContext } from '../../App/context/filterContext'
import {
  ADMIN_SERVICE_API_DOMAIN_URL,
  BATCH_EDIT_ACTION,
  HEADER_OBJECT,
  SIGN_SERVICE_API_DOMAIN_URL,
  TOASTER_DEFAULTS,
} from '../../App/constants/appConstants'
import {
  Department,
  EditedSignElement,
  EditingFacets,
  ProjectSignInfo,
  SignElementEditorRequest,
  SignElementFields,
  SignLibrarySearchFacets,
  ReinstateNRIRequest,
  SignLibraryFilterFacets,
} from '../../../models/signLibrary'
import { DETAIL_PROJECT_LIST } from '../views/AgGridSignLibraryView'
import { useUserContext } from '../../App/context/userContext'
import { useAppContext } from '../../App/context/appContext'
import { clone, cloneDeep, isEmpty, set } from 'lodash'
import FilterFacets from '../../../models/signLibrary/FilterFacets.model'
import { useToaster } from '@enterprise-ui/canvas-ui-react'
import { ContractCore } from '../../../models/contracts/ContractCore.model'
import SignTemplateResponse from '../../../models/signTemplates/SignTemplateResponse.model'

type ContextType = {
  filterFacets: SignLibraryFilterFacets
  loadFacets: () => void
  facetsAreLoaded: boolean
  readyToLoadGrid: boolean
  setReadyToLoadGrid: (value: boolean) => void
  gridApi: GridApi | undefined
  setGridApi: Function
  gridColumnApi: ColumnApi | undefined
  setGridColumnApi: Function
  gridOptions: GridOptions
  onGridReady: (event: GridReadyEvent) => void
  refreshDatasource: () => void
  totalResults: number | undefined
  setTotalResults: (total: number | undefined) => void
  facetResults: SignLibrarySearchFacets
  setFacetResults: (results: SignLibrarySearchFacets) => void
  editingFacets: EditingFacets
  filterList: any[]
  resetAllFilters: () => void
  signsModified: boolean
  setSignsModified: (signsModified: boolean) => void
  modifiedSigns: EditedSignElement[]
  setModifiedSigns: Function
  modifiedSignIds: string[]
  setModifiedSignIds: Function
  modifiedFields: string[]
  setModifiedFields: Function
  saveModifiedSigns: () => void
  reinstatedSigns: ReinstateNRIRequest[]
  reinstateSign: (node: RowNode) => void
  onCellValueChange: (event: CellValueChangedEvent) => void
  getSignTemplatesByType: (type: string) => void
  signTemplateOptions: SignTemplateResponse[]
  activeContracts: ContractCore[]
}

export const AgGridSignLibraryContext = createContext<ContextType | undefined>(
  undefined,
)

type Props = {
  children: React.ReactNode
}

export const AgGridSignLibraryProviderComponent = ({ children }: Props) => {
  const { setFullPageLoadingMessage, getActiveContracts, signLibraryView } =
    useAppContext()!
  const { userName, userPermissions, userType } = useUserContext()!
  const { signLibraryFilterModel, setSignLibraryFilterModel } =
    useFilterContext()!
  const { signLibrarySSRMServer } = useSignLibrarySSRMServerContext()!
  const env = useEnv()
  const makeToast = useToaster()

  const [filterFacets, setFilterFacets] = useState<SignLibraryFilterFacets>(
    new FilterFacets(),
  )
  const [facetsAreLoaded, setFacetsAreLoaded] = useState<boolean>(false)
  const [readyToLoadGrid, setReadyToLoadGrid] = useState<boolean>(false)
  const [gridApi, setGridApi] = useState<GridApi>()
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>()
  const [totalResults, setTotalResults] = useState<number | undefined>(
    undefined,
  )
  const [facetResults, setFacetResults] = useState<SignLibrarySearchFacets>(
    new SignLibrarySearchFacets(),
  )
  const [filterList, setFilterList] = useState<any[]>([])
  const [editingFacets, setEditingFacets] = useState<EditingFacets>(
    new EditingFacets(),
  )

  const [signsModified, setSignsModified] = useState(false)
  const [modifiedSigns, setModifiedSigns] = useState<EditedSignElement[]>([])
  const [modifiedSignIds, setModifiedSignIds] = useState<string[]>([])
  const [modifiedFields, setModifiedFields] = useState<string[]>([])
  const [reinstatedSigns, setReinstatedSigns] = useState<ReinstateNRIRequest[]>(
    [],
  )
  const [signTemplateOptions, setSignTemplateOptions] = useState<
    SignTemplateResponse[]
  >([])
  const [activeContracts, setActiveContracts] = useState<ContractCore[]>([])

  useEffect(() => {
    getActiveContracts().then((data: ContractCore[]) =>
      setActiveContracts(data),
    )
  }, [getActiveContracts])

  const isEditableField = useCallback(
    (params: EditableCallbackParams) =>
      isEditable(
        params.api.getServerSideStoreState()[0].info.userInfo,
        params.data.non_retail_item_info.sign_item_status,
        params.column.getColId(),
        params.data.non_retail_item_info.sign_size,
        modifiedFields.includes(
          'non_retail_item_info.sign_template_info.template_id',
        )
          ? signTemplateOptions.find(
              (template: SignTemplateResponse) =>
                template.name ===
                params.data.non_retail_item_info.sign_template_info
                  .template_name,
            )!.size_editable
          : params.data.non_retail_item_info.sign_template_info.size_editable,
      ),
    [signTemplateOptions, modifiedFields],
  )

  const signLibraryColumnTypes: { [key: string]: ColDef } = {
    fixed: {
      lockPinned: true,
      resizable: false,
      suppressSizeToFit: true,
      pinned: 'left',
    },
    selectedRow: {
      width: 50,
      maxWidth: 50,
      filter: false,
      sortable: false,
      suppressMenu: true,
      checkboxSelection: true,
    },
    signMenu: {
      width: 36,
      maxWidth: 36,
      filter: false,
      sortable: false,
      suppressMenu: true,
      cellRenderer: 'TableDropdownMenu',
      cellClass: 'actions-button-cell dropdown-menu',
    },
    requiredField: {
      headerClass: 'required-field',
      cellClassRules: {
        'required-field': (params: CellClassParams) =>
          params.data.non_retail_item_info.sign_template_info.template_name ===
            'CUSTOM' && params.colDef.field!.includes('dimensions'),
      },
    },
    dpci: {
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: {
        innerRenderer: 'Dpci',
      },
      filterParams: {
        filterOptions: [
          {
            displayKey: 'dpciList',
            displayName: 'DPCI List (comma separated)',
            predicate: () => {},
          },
        ],
        suppressAndOrCondition: true,
      },
    },
    signType: {
      filterParams: {
        values: filterFacets.sign_type,
      },
    },
    mostRecentSignStatus: {
      cellRenderer: 'SignStatus',
    },
    signItemStatus: {
      cellRenderer: 'SignStatus',
      filterParams: {
        values: filterFacets.sign_item_status,
      },
    },
    signTemplate: {
      filterParams: {
        values: filterFacets.sign_template,
      },
    },
    signSize: {
      filterParams: {
        values: filterFacets.sign_size,
      },
    },
    signDefinition: {
      filterParams: {
        values: filterFacets.sign_definition,
      },
    },
    signItemStatusImport: {
      cellRenderer: 'SignStatus',
      sortable: false,
      suppressMenu: true,
    },
    department: {
      cellRenderer: 'Department',
      filterParams: {
        values: filterFacets.department,
      },
    },
    departmentEditor: {
      editable: (params: EditableCallbackParams) =>
        isEditable(
          params.api.getServerSideStoreState()[0].info.userInfo,
          params.data.non_retail_item_info.sign_item_status,
          params.column.getColId(),
        ),
      cellEditor: 'DepartmentEditor',
      cellEditorPopup: true,
    },
    productVendor: {
      filterParams: {
        values: filterFacets.product_vendor,
      },
    },
    separator: {
      filterParams: {
        values: filterFacets.separator,
      },
    },
    date: {
      valueGetter: (params: ValueGetterParams) => dateValueGetter(params),
      filterParams: {
        filterOptions: ['inRange'],
        suppressAndOrCondition: true,
      },
    },
    detailDate: {
      valueGetter: (params: ValueGetterParams) => dateValueGetter(params),
      filterParams: detailDateFilterParams,
      comparator: detailDateComparator,
    },
    toggle: {
      cellRenderer: 'ToggleCell',
      filter: false,
      sortable: false,
      suppressMenu: true,
    },
    textFilter: {
      filterParams: {
        filterOptions: ['contains'],
        suppressAndOrCondition: true,
      },
    },
    numberFilter: {
      filterParams: {
        filterOptions: ['equals'],
        suppressAndOrCondition: true,
      },
    },
    hiddenColumn: {
      hide: true,
    },
    nonFilterable: {
      sortable: false,
      suppressMenu: true,
      headerClass: 'ag-header-cell-non-filterable',
    },
    signStatus: {
      filterParams: {
        values: filterFacets.sign_status,
      },
      cellRenderer: 'SignStatus',
    },
    pyramid: {
      filterParams: {
        values: filterFacets.pyramid,
      },
      hide: true,
    },
    division: {
      filterParams: {
        values: filterFacets.division,
      },
      hide: true,
    },
    projectType: {
      filterParams: {
        values: filterFacets.department,
      },
      hide: true,
    },
    campaignType: {
      filterParams: {
        values: filterFacets.campaign,
      },
      hide: true,
    },
    printVendor: {
      filterParams: {
        values: filterFacets.printing_vendor,
      },
      hide: true,
    },
    kittingVendor: {
      filterParams: {
        values: filterFacets.kitting_vendor,
      },
      hide: true,
    },
    setQuantity: {
      cellRenderer: 'SetQuantity',
      filter: false,
      sortable: false,
      suppressMenu: true,
    },
    setQtyEditor: {
      editable: (params: EditableCallbackParams) =>
        isEditable(
          params.api.getServerSideStoreState()[0].info.userInfo,
          params.data.non_retail_item_info.sign_item_status,
          params.column.getColId(),
        ) && params.data.non_retail_item_info.unit_of_measure === 'ST',
      cellEditor: 'NumberEditor',
      cellEditorPopup: true,
    },
    textEditor: {
      editable: (params: EditableCallbackParams) =>
        isEditable(
          params.api.getServerSideStoreState()[0].info.userInfo,
          params.data.non_retail_item_info.sign_item_status,
          params.column.getColId(),
        ),
      cellEditor: 'TextEditor',
      cellEditorPopup: true,
    },
    selectEditor: {
      editable: (params: EditableCallbackParams) =>
        isEditable(
          params.api.getServerSideStoreState()[0].info.userInfo,
          params.data.non_retail_item_info.sign_item_status,
          params.column.getColId(),
        ),
      cellEditor: 'SelectEditor',
      cellEditorPopup: true,
    },
    dateEditor: {
      editable: (params: EditableCallbackParams) =>
        isEditable(
          params.api.getServerSideStoreState()[0].info.userInfo,
          params.data.non_retail_item_info.sign_item_status,
          params.column.getColId(),
        ),
      cellEditor: 'DateEditor',
      cellEditorPopup: true,
    },
    numberEditor: {
      editable: (params: EditableCallbackParams) => isEditableField(params),
      cellEditor: 'NumberEditor',
      cellEditorPopup: true,
    },
    toggleEditor: {
      editable: (params: EditableCallbackParams) =>
        isEditable(
          params.api.getServerSideStoreState()[0].info.userInfo,
          params.data.non_retail_item_info.sign_item_status,
          params.column.getColId(),
        ),
      cellEditor: 'ToggleEditor',
    },
    autocompletesubstrateEditor: {
      editable: (params: EditableCallbackParams) =>
        isEditable(
          params.api.getServerSideStoreState()[0].info.userInfo,
          params.data.non_retail_item_info.sign_item_status,
          params.column.getColId(),
        ),
      cellEditor: 'AutocompletesubstrateCellEditor',
      cellEditorPopup: true,
    },
    autocompleteEditor: {
      editable: (params: EditableCallbackParams) =>
        isEditable(
          params.api.getServerSideStoreState()[0].info.userInfo,
          params.data.non_retail_item_info.sign_item_status,
          params.column.getColId(),
        ),
      cellEditor: 'AutocompleteEditor',
      cellEditorPopup: true,
    },
  }

  const onGridReady = (event: GridReadyEvent) => {
    setGridApi(event.api)
    setGridColumnApi(event.columnApi)
    event.api.setFilterModel(signLibraryFilterModel)
    event.api.setServerSideDatasource(
      signLibraryDatasource(signLibrarySSRMServer()),
    )
  }

  const gridOptions = {
    animateRows: true,
    masterDetail: true,
    getRowHeight: (params: RowHeightParams) => {
      return params.node.detail
        ? params.data.project_sign_info_list.length > 9
          ? 568
          : 328
        : undefined
    },
    defaultColDef: {
      resizable: true,
      sortable: true,
      width: 148,
      menuTabs: ['filterMenuTab'],
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true,
      },
    },
    columnTypes: signLibraryColumnTypes,
    frameworkComponents: signLibraryGridComponents,
    detailCellRendererParams: {
      detailGridOptions: {
        defaultColDef: {
          resizable: true,
          sortable: true,
          width: 148,
          menuTabs: ['filterMenuTab'],
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['reset', 'apply'],
            closeOnApply: true,
          },
        },
        columnTypes: signLibraryColumnTypes,
        columnDefs: DETAIL_PROJECT_LIST,
        frameworkComponents: signLibraryGridComponents,
      },
      getDetailRowData: async (params: GetDetailRowDataParams) => {
        const res = await Axios.get(
          `${env.apiDomainUrl + SIGN_SERVICE_API_DOMAIN_URL}/sign_library/${
            params.data.id
          }`,
        )
        params.successCallback(
          res.data.project_sign_info_list.map(
            (project: any) => new ProjectSignInfo(project),
          ),
        )
      },
    },
  }

  const buildFilterList = useCallback(
    (params: IServerSideGetRowsParams) => {
      const { api, request } = params
      const tempFilterList = []
      const tempFilterModel = {}

      if (isEmpty(request.filterModel)) {
        setFilterList([])
      } else {
        for (const [field, value] of Object.entries(request.filterModel)) {
          const { filterType, filter, values, dateFrom, dateTo } = value as any
          if (filterType === 'set' && values.length < 1) {
            break
          } else {
            tempFilterList.push({
              field,
              label: api.getColumnDef(field)?.headerName,
              value:
                filterType === 'set'
                  ? values.join(', ')
                  : filterType === 'date'
                  ? `${moment(dateFrom).format('MM/DD/YYYY')} - ${moment(
                      dateTo,
                    ).format('MM/DD/YYYY')}`
                  : `"${filter}"`,
            })
          }
          set(tempFilterModel, [field], value)
        }
        setFilterList(tempFilterList)
      }
      setSignLibraryFilterModel(request.filterModel)
    },
    [setSignLibraryFilterModel],
  )

  const signLibraryDatasource = (server: any) => {
    return {
      getRows: async (params: IServerSideGetRowsParams) => {
        buildFilterList(params)
        const res = await server.getData(params)
        const lastRowIndex = res.data.search.length - 1
        setFacetResults(new SignLibrarySearchFacets(res.data.facets))
        setTotalResults(res.data.total_results)
        if (res?.success) {
          params.success({
            rowData: res.rows,
            rowCount: res.data.total_results,
            storeInfo: {
              facets: res.data.facets,
              finalSignData:
                lastRowIndex > -1
                  ? res.data.search[lastRowIndex].result
                  : undefined,
              userInfo: { userPermissions, userType },
            },
          })
        }
      },
    }
  }

  const refreshDatasource = () => {
    gridApi!.refreshServerSideStore({ purge: true })
    gridColumnApi?.getAllColumns()?.map(
      (column: Column) =>
        (column.getColDef().cellClassRules = {
          'ag-grid-modified': (params: CellClassParams) => false,
        }),
    )
  }

  const loadFacets = useCallback(async () => {
    try {
      const facetsRes: any = await Axios.post(
        `${env.apiDomainUrl + SIGN_SERVICE_API_DOMAIN_URL}/sign_library/facets`,
        new SignLibrarySearchFacets(),
        HEADER_OBJECT,
      )
      const nriFacetsRes: any = await Axios.get(
        `${env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL}/facets/signs`,
      )
      const deptFacetsRes: any = await Axios.get(
        `${
          env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
        }/merchandise_hierarchy/departments`,
      )
      setFilterFacets(new FilterFacets(facetsRes.data))
      setEditingFacets(
        new EditingFacets({
          ...nriFacetsRes.data,
          departments: deptFacetsRes.data
            .filter(
              (dept: any) =>
                dept.department_name !== 'OPEN' && new Department(dept),
            )
            .sort((a: Department, b: Department) =>
              a.department_id > b.department_id ? 1 : -1,
            ),
        }),
      )
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Build Filtering Facets',
        message: err.response.data.message,
      })
    }
    setFacetsAreLoaded(true)
  }, [env.apiDomainUrl, makeToast])

  const changeDimensionsOld = (
    node: RowNode,
    newTemplate?: SignTemplateResponse,
  ) => {
    node.setDataValue(
      'non_retail_item_info.item_dimensions.width',
      newTemplate ? newTemplate!.finished_width : '',
    )
    node.setDataValue(
      'non_retail_item_info.item_dimensions.height',
      newTemplate ? newTemplate!.finished_length : '',
    )
    node.setDataValue(
      'non_retail_item_info.item_dimensions.depth',
      newTemplate ? newTemplate!.finished_depth : '',
    )
    node.setDataValue(
      'non_retail_item_info.nominal_dimensions.width',
      newTemplate ? newTemplate!.flat_width : '',
    )
    node.setDataValue(
      'non_retail_item_info.nominal_dimensions.height',
      newTemplate ? newTemplate!.flat_length : '',
    )
    node.setDataValue(
      'non_retail_item_info.nominal_dimensions.depth',
      newTemplate ? newTemplate!.flat_depth : '',
    )
  }

  const changeDimensions = (node: RowNode, newContract?: ContractCore) => {
    node.setDataValue(
      'non_retail_item_info.item_dimensions.width',
      newContract ? newContract!.finishedWidth : '',
    )
    node.setDataValue(
      'non_retail_item_info.item_dimensions.height',
      newContract ? newContract!.finishedHeight : '',
    )
    node.setDataValue(
      'non_retail_item_info.item_dimensions.depth',
      newContract ? newContract!.finishedDepth : '',
    )
    node.setDataValue(
      'non_retail_item_info.nominal_dimensions.width',
      newContract ? newContract!.flatWidth : '',
    )
    node.setDataValue(
      'non_retail_item_info.nominal_dimensions.height',
      newContract ? newContract!.flatHeight : '',
    )
    node.setDataValue(
      'non_retail_item_info.nominal_dimensions.depth',
      newContract ? newContract!.flatDepth : '',
    )
    if (signLibraryView.label === 'Printing Info') {
      node.setDataValue(
        'non_retail_item_info.printing_info.planned_sides',
        newContract ? newContract!.sides : '',
      )
      node.setDataValue(
        'non_retail_item_info.printing_info.substrate1',
        newContract ? newContract!.substrate1 : '',
      )
      node.setDataValue(
        'non_retail_item_info.printing_info.substrate2',
        newContract ? newContract!.substrate2 : '',
      )
      node.setDataValue(
        'non_retail_item_info.printing_info.finishing',
        newContract ? newContract!.finishing : '',
      )
      node.setDataValue(
        'non_retail_item_info.printing_info.die_cut',
        newContract ? newContract!.dieCut : '',
      )
    }
  }

  const handleTypeChange = (node: RowNode, newValue: string) => {
    if (env.beskarFeature) {
      node.setDataValue('non_retail_item_info.sign_size', '')
      node.setDataValue('non_retail_item_info.sign_definition', '')
      changeDimensions(node, undefined)
    } else {
      node.setDataValue(
        'non_retail_item_info.sign_template_info.template_name',
        '',
      )
      changeDimensionsOld(node, undefined)
    }
    return newValue
  }

  const handleSignSizeChange = (node: RowNode, newValue: string) => {
    if (newValue !== 'CUSTOM') {
      node.setDataValue('non_retail_item_info.sign_definition', '')
    } else {
      node.setDataValue('non_retail_item_info.sign_definition', 'CUSTOM')
    }
    changeDimensions(node, undefined)
    return newValue
  }

  const handleSignDefinitionChange = (
    node: RowNode,
    newValue: string,
    fields?: SignElementFields,
  ) => {
    const newContract = activeContracts.find(
      (contract: ContractCore) => contract.signDefinition === newValue,
    )
    changeDimensions(node, newContract)
    return {
      ...fields,
      sign_definition: newValue,
      sign_template_info: {
        template_id: newValue === 'CUSTOM' ? 'custom' : newContract?.sourceId,
        source_id: newValue === 'CUSTOM' ? 'custom' : newContract?.sourceId,
        dieline_name: newContract?.dielineName,
      },
      printing_info: {
        planned_sides: newContract?.sides,
        substrate1: newContract?.substrate1,
        substrate2: newContract?.substrate2,
        die_cut: newContract?.dieCut,
        finishing: newContract?.finishing,
      },
      item_dimensions: {
        width: newContract?.finishedWidth,
        height: newContract?.finishedHeight,
        depth: newContract?.finishedDepth,
      },
      nominal_dimensions: {
        width: newContract?.flatWidth,
        height: newContract?.flatHeight,
        depth: newContract?.flatDepth,
      },
    }
  }

  const handleTemplateChange = (
    node: RowNode,
    newValue: string,
    fields?: SignElementFields,
  ) => {
    const newTemplate = signTemplateOptions.find(
      (template: SignTemplateResponse) => template.name === newValue,
    )
    changeDimensionsOld(node, newTemplate)
    return {
      ...fields,
      sign_template_info: {
        template_id: newTemplate?.id,
        template_name: newValue,
        size_editable: newTemplate?.size_editable,
        standard_sign: newTemplate?.id !== 'custom' && newTemplate?.id !== 'NA',
      },
    }
  }

  const onCellValueChange = (event: CellValueChangedEvent) => {
    if (event.newValue !== event.oldValue) {
      setSignsModified(true)
      event.data.modified = true
      const fieldPath = event.column
        .getColId()
        .replace(/^(non_retail_item_info\.)/, '')
      if (modifiedSignIds.includes(event.data.id)) {
        setModifiedSigns((previousState: EditedSignElement[]) => {
          const clonedSigns = cloneDeep(previousState)
          clonedSigns.map((sign: EditedSignElement, index: number) =>
            sign.non_retail_item_id === event.data.id
              ? (clonedSigns[index] = new EditedSignElement({
                  action: BATCH_EDIT_ACTION.UPDATE,
                  fields: new SignElementFields(
                    fieldPath === 'sign_template_info.template_name'
                      ? handleTemplateChange(
                          event.node,
                          event.value,
                          sign.fields,
                        )
                      : fieldPath === 'sign_definition'
                      ? handleSignDefinitionChange(
                          event.node,
                          event.value,
                          sign.fields,
                        )
                      : set(
                          sign.fields,
                          fieldPath,
                          fieldPath === 'end_date'
                            ? event.value === ''
                              ? null
                              : moment(event.value).format('YYYY-MM-DD')
                            : fieldPath === 'set_quantity'
                            ? parseInt(event.value)
                            : fieldPath
                                .split('.')
                                .includes('nominal_dimensions') ||
                              fieldPath.split('.').includes('item_dimensions')
                            ? event.value.toString()
                            : fieldPath === 'sign_type'
                            ? handleTypeChange(event.node, event.value)
                            : fieldPath === 'sign_size'
                            ? handleSignSizeChange(event.node, event.value)
                            : event.value,
                        ),
                  ),
                  non_retail_item_id: event.data.id,
                }))
              : new EditedSignElement(sign),
          )
          return clonedSigns
        })
      } else {
        setModifiedSigns((previousState: EditedSignElement[]) => {
          const clonedProjects = cloneDeep(previousState)
          clonedProjects.push(
            new EditedSignElement({
              action: BATCH_EDIT_ACTION.UPDATE,
              fields: new SignElementFields(
                fieldPath === 'sign_template_info.template_name'
                  ? handleTemplateChange(event.node, event.value, undefined)
                  : fieldPath === 'sign_definition'
                  ? handleSignDefinitionChange(
                      event.node,
                      event.value,
                      undefined,
                    )
                  : set(
                      {},
                      fieldPath,
                      fieldPath === 'end_date'
                        ? event.value === ''
                          ? null
                          : moment(event.value).format('YYYY-MM-DD')
                        : fieldPath === 'set_quantity'
                        ? parseInt(event.value)
                        : fieldPath.split('.').includes('nominal_dimensions')
                        ? event.value.toString()
                        : fieldPath.split('.').includes('item_dimensions')
                        ? event.value.toString()
                        : fieldPath === 'sign_type'
                        ? handleTypeChange(event.node, event.value)
                        : fieldPath === 'sign_size'
                        ? handleSignSizeChange(event.node, event.value)
                        : event.value,
                    ),
              ),
              non_retail_item_id: event.data.id,
            }),
          )
          return clonedProjects
        })
        setModifiedSignIds((previousState: string[]) => {
          const clonedIds = clone(previousState)
          clonedIds.push(event.data.id)
          return clonedIds
        })
      }

      setModifiedFields((previousState: string[]) => {
        const clonedFields = clone(previousState)
        !clonedFields.includes(event.column.getColId()) &&
          clonedFields.push(event.column.getColId())
        return clonedFields
      })
      event.column.getColDef().cellClassRules = {
        'ag-cell-modified': (params: CellClassParams) => {
          return params.value === event.newValue
        },
      }
      event.api.refreshCells({
        force: true,
        columns: [event.column.getColId()],
        rowNodes: [event.node],
      })
    }
  }

  const saveModifiedSigns = async () => {
    setFullPageLoadingMessage('Saving Sign Library...')
    try {
      const res = await Axios.put(
        `${env.apiDomainUrl + SIGN_SERVICE_API_DOMAIN_URL}/sign_elements/batch`,
        new SignElementEditorRequest({
          sign_elements: modifiedSigns,
          updated_by: userName,
        }),
        HEADER_OBJECT,
      )
      const rejectedSignMessages: string[] = res.data.sign_elements
        .filter((signRes: any) => signRes.edit_status.status === 'REJECTED')
        .map((signRes: any) => signRes.edit_status.error_message)
      setModifiedSigns([])
      setModifiedSignIds([])
      setModifiedFields([])
      setSignsModified(false)
      refreshDatasource()
      if (rejectedSignMessages.length > 0) {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'alert',
          heading: 'Some Sign(s) Could Not be Saved',
          message: rejectedSignMessages.join('\n'),
        })
      } else {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'success',
          heading: 'Sign Library Updated',
          message: 'Successfully updated sign elements',
        })
      }
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Update Sign Library',
        message: err.response.data.message,
      })
    }
    setFullPageLoadingMessage('')
  }

  const reinstateSign = (node: RowNode) => {
    setSignsModified(true)
    setReinstatedSigns((previousState: ReinstateNRIRequest[]) => {
      const clonedSigns = cloneDeep(previousState)
      clonedSigns.push(
        new ReinstateNRIRequest({
          non_retail_item_id: node.data.id,
          end_date: undefined, // Set End Date to undifined as placeholder until user add new Date
          obsolete: false,
        }),
      )
      return clonedSigns
    })
    node.setDataValue('non_retail_item_info.reinstated', true)
    node.setDataValue('non_retail_item_info.end_date', undefined)
  }

  const resetAllFilters = () => {
    setSignLibraryFilterModel({})
    gridApi && gridApi.setFilterModel({})
    gridColumnApi &&
      gridColumnApi.applyColumnState({ defaultState: { sort: null } })
  }

  const getSignTemplatesByType = useCallback(
    async (type: string) => {
      try {
        const res = await Axios.get(
          `${
            env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
          }/templates/sign_templates/type?sign_type=${type}`,
        )
        setSignTemplateOptions(res.data)
      } catch (err: any) {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'error',
          heading: 'Failed to Get Sign Templates',
          message: err.response.data.message,
        })
      }
    },
    [env.apiDomainUrl, makeToast],
  )

  return (
    <AgGridSignLibraryContext.Provider
      value={{
        filterFacets,
        loadFacets,
        facetsAreLoaded,
        readyToLoadGrid,
        setReadyToLoadGrid,
        gridApi,
        setGridApi,
        gridColumnApi,
        setGridColumnApi,
        refreshDatasource,
        gridOptions,
        onGridReady,
        totalResults,
        setTotalResults,
        facetResults,
        setFacetResults,
        editingFacets,
        filterList,
        resetAllFilters,
        signsModified,
        setSignsModified,
        modifiedSigns,
        setModifiedSigns,
        modifiedSignIds,
        setModifiedSignIds,
        modifiedFields,
        setModifiedFields,
        onCellValueChange,
        saveModifiedSigns,
        reinstateSign,
        reinstatedSigns,
        activeContracts,
        signTemplateOptions,
        getSignTemplatesByType,
      }}
    >
      {children}
    </AgGridSignLibraryContext.Provider>
  )
}

export const AgGridSignLibraryProvider = connect(
  null,
  null,
)(AgGridSignLibraryProviderComponent)

export const useAgGridSignLibraryContext = () =>
  useContext(AgGridSignLibraryContext)
