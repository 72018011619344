import React, { createContext, useContext } from 'react'
import { SearchRequest } from '../../../models/app/SearchRequest.model'
import { useBlueprintListContext } from './blueprintListContext'
import { useFilterContext } from '../../App/context/filterContext'

type ContextType = {
  setIncludeFilters: (filter: string, value: string[]) => void
  setExcludeFilters: (filter: string, value: string[]) => void
  setPartialFilters: (filter: string, value: string[]) => void
  setDateFilters: (id: string, date: string) => void
  submitSort: (searchPath: string, direction: string) => void
}

export const BlueprintFilterContext = createContext<ContextType | undefined>(
  undefined,
)

type Props = {
  children: React.ReactNode
}

export const BlueprintFilterProvider = ({ children }: Props) => {
  const { submitBlueprintSearch } = useBlueprintListContext()!
  const { blueprintFilters, setBlueprintFilters } = useFilterContext()!

  const setIncludeFilters = (filter: string, value: string[]) => {
    setBlueprintFilters(
      new SearchRequest({
        ...blueprintFilters,
        include_filters: {
          ...blueprintFilters.include_filters,
          [filter]: value,
        },
      }),
    )
  }

  const setPartialFilters = (filter: string, value: string[]) =>
    setBlueprintFilters(
      new SearchRequest({
        ...blueprintFilters,
        partial_filters: {
          ...blueprintFilters.partial_filters,
          [filter]: value,
        },
      }),
    )

  const setDateFilters = (id: string, date: string) => {
    let rangeFilters
    switch (id) {
      case 'setDateFrom': {
        rangeFilters = {
          set_date: {
            ...blueprintFilters.range_filters?.set_date,
            from: date,
          },
        }
        break
      }
      case 'setDateTo': {
        rangeFilters = {
          set_date: {
            ...blueprintFilters.range_filters?.set_date,
            to: date,
          },
        }
        break
      }
    }

    setBlueprintFilters(
      new SearchRequest({
        ...blueprintFilters,
        range_filters: rangeFilters,
      }),
    )
  }

  const setExcludeFilters = (filter: string, value: string[]) => {
    setBlueprintFilters(
      new SearchRequest({
        ...blueprintFilters,
        exclude_filters: {
          ...blueprintFilters.exclude_filters,
          [filter]: value,
        },
      }),
    )
  }

  const submitSort = async (searchPath: string, direction: string) => {
    const newFilters = {
      ...blueprintFilters,
      sort_fields: { [searchPath]: direction },
    }
    setBlueprintFilters(newFilters)
    submitBlueprintSearch(newFilters)
  }

  return (
    <BlueprintFilterContext.Provider
      value={{
        setIncludeFilters,
        setExcludeFilters,
        setPartialFilters,
        setDateFilters,
        submitSort,
      }}
    >
      {children}
    </BlueprintFilterContext.Provider>
  )
}

export const useBlueprintFilterContext = () =>
  useContext(BlueprintFilterContext)
