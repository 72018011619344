import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import {
  Button,
  Chip,
  Divider,
  Form,
  Grid,
} from '@enterprise-ui/canvas-ui-react'
import {
  pogSelectionValues,
  POG_SELECTION_OPTIONS,
} from '../../../constants/blueprintProjectListConstants'
import SapTableProject, {
  PogSetDate,
} from '../../../../../../../models/projects/SapTableProject.model'
import EnterpriseIcon, { PlusIcon } from '@enterprise-ui/icons'
import moment from 'moment'
import { RowNode } from 'ag-grid-community'

export interface Props {
  value: PogSetDate
  data: SapTableProject
  node: RowNode
}

const PogSelectionEditor = forwardRef(({ value, data, node }: Props, ref) => {
  const [editValue, setEditValue] = useState(value)
  const [pogSelection, setPogSelection] = useState<string>(data.pog_selection)
  const [currentPogDate, setCurrentPogDate] = useState(
    moment(data.set_date).format('YYYY-MM-DD') || '',
  )
  const [pogSetDates, setPogSetDates] = useState(editValue.set_dates)
  const refInput = useRef<HTMLInputElement>(null)

  useEffect(() => {
    setTimeout(() => refInput?.current?.focus())
  }, [])

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return editValue
      },
      isPopup() {
        return true
      },
    }
  })

  const handlePogSelectionChange = (selection: string) => {
    node.setDataValue('pog_selection', selection)
    if (selection === pogSelectionValues.PROJECT_SET_DATE) {
      setEditValue({
        use_marketing_plan_date: false,
        use_multiple_set_date: false,
        set_dates: [],
      })
      setPogSetDates([])
    }
    if (selection === pogSelectionValues.MARKETING_PLAN_DATE) {
      setEditValue({
        use_marketing_plan_date: true,
        use_multiple_set_date: false,
        set_dates: [],
      })
      setPogSetDates([])
    }
    if (selection === pogSelectionValues.MULTIPLE_SET_DATES) {
      setEditValue({
        use_marketing_plan_date: false,
        use_multiple_set_date: true,
        set_dates: pogSetDates,
      })
    }
    setPogSelection(selection)
  }

  const handlePogSetDateAddition = () => {
    if (!pogSetDates.find((date) => date === currentPogDate)) {
      setEditValue({
        ...editValue,
        set_dates: pogSetDates.concat([currentPogDate]),
      })
      setPogSetDates(pogSetDates.concat([currentPogDate]))
    }
  }

  const handlePogSetDateRemoval = (removedDate: string) => {
    const updatedPogSetDates = pogSetDates.filter(
      (date) => date !== removedDate,
    )
    setEditValue({
      ...editValue,
      set_dates: updatedPogSetDates,
    })
    setPogSetDates(updatedPogSetDates)
  }

  return (
    <div className="hc-pa-dense">
      <Grid.Container align="center" justify="space-between">
        <Grid.Item className="hc-pb-none" xs={12}>
          <Form.Field
            id="pog_selection"
            ref={refInput}
            label="POG Selection Options"
            type="select"
            value={pogSelection}
            options={POG_SELECTION_OPTIONS}
            onUpdate={(id: string, value: string) =>
              handlePogSelectionChange(value)
            }
            size="dense"
          />
          <Divider className="hc-mt-normal" />
        </Grid.Item>
        <Grid.Item className="hc-pb-none" xs={true}>
          <Form.Field
            id="pog_dates"
            type="date"
            label="Select Date(s)"
            value={currentPogDate}
            onChange={(e: any) => {
              setCurrentPogDate(e.target.value)
            }}
            disabled={
              data.pog_selection !== pogSelectionValues.MULTIPLE_SET_DATES
            }
            size="dense"
          />
        </Grid.Item>
        <Grid.Item className="hc-pl-none hc-pb-none">
          <Button
            type="ghost"
            iconOnly
            size="dense"
            disabled={
              data.pog_selection !== pogSelectionValues.MULTIPLE_SET_DATES
            }
            onClick={() => handlePogSetDateAddition()}
          >
            <EnterpriseIcon icon={PlusIcon} />
          </Button>
        </Grid.Item>
        <Grid.Item className="hc-pv-dense" xs={12}>
          {pogSetDates.map((setDate: string, index: number) => (
            <Chip
              size="dense"
              key={index}
              removable
              onRequestDelete={() => handlePogSetDateRemoval(setDate)}
            >
              {moment(setDate).format('MM/DD/YYYY')}
            </Chip>
          ))}
        </Grid.Item>
      </Grid.Container>
    </div>
  )
})

export default PogSelectionEditor
