import React, { useState, useEffect } from 'react'
import { Modal, Grid, Button } from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { useProjectSignListContext } from '../../context/projectSignListContext'
import { useAgGridSignListContext } from '../../context/agGridSignListContext'
import MoveSignsTable from './MoveSignsTable'
import { fetchPidList } from '../../helpers/projectListHelpers'
import { useAppContext } from 'components/App/context/appContext'
import { useProjectDetailsContext } from '../../../../context/projectDetailsContext'
import { DialogProps } from '../../../../../../models/app/DialogProps.model'
import SignResponse from '../../../../../../models/signs/SignResponse.model'

const MoveSignsModal = () => {
  const { moveModalOpen, setMoveModalOpen, moveSigns } =
    useProjectSignListContext()!
  const { selectedRows, setSelectedRows } = useAgGridSignListContext()!
  const { currentProject } = useProjectDetailsContext()!
  const { setDialogProps } = useAppContext()!
  const [pid, setPid] = useState('')
  const [pidList, setPidList] = useState<any>([])

  useEffect(() => {
    const getPidList = async () => {
      const list = await fetchPidList()
      setPidList(list)
    }
    getPidList()
  }, [])

  return (
    <Modal isVisible={moveModalOpen} onRefuse={() => setMoveModalOpen(false)}>
      <div className="hc-pa-dense">
        <Grid.Container>
          <Grid.Item xs={12}>
            <MoveSignsTable />
          </Grid.Item>
          <Grid.Item xs={6}>
            <Autocomplete
              id="pid"
              label="Choose a PID"
              hintText="Only active projects are available"
              options={pidList}
              value={{ id: pid, label: pid, value: pid }}
              onUpdate={(id: string, value: any) => {
                if (value !== null) {
                  setPid(value.value)
                }
              }}
            />
          </Grid.Item>
          <Grid.Item xs={6}>
            <Grid.Container justify="flex-end">
              <Grid.Item xs={7}>
                <div className="hc-pa-dense">
                  <Button onClick={() => setMoveModalOpen(false)}>
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => {
                      setDialogProps({
                        headingText:
                          'Are you sure you want to move these signs?',
                        approveButtonText: 'Yes',
                        onApprove: () => {
                          moveSigns(
                            selectedRows.map(
                              (row: SignResponse) => row.sign_id,
                            ),
                            currentProject.project_id,
                            pid,
                          )
                          setDialogProps(new DialogProps())
                          setMoveModalOpen(false)
                          setPid('')
                        },
                        refuseButtonText: 'No',
                        onRefuse: () => {
                          setDialogProps(new DialogProps())
                          setPid('')
                        },
                      })
                      setSelectedRows([])
                    }}
                    disabled={
                      pid === '' || pid === currentProject.sap_project_id
                    }
                  >
                    Move
                  </Button>
                </div>
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
        </Grid.Container>
      </div>
    </Modal>
  )
}

export default MoveSignsModal
