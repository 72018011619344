import React from 'react'
import { Button, Dropdown, Grid } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, {
  CaretDownIcon,
  PencilIcon,
  SaveIcon,
  TrashIcon,
} from '@enterprise-ui/icons'
import { ScheduleTemplate } from '../../../../../models/milestones/ScheduleTemplate'
import { useAdminMilestoneContext } from '../../context/adminMilestoneContext'
import { useAppContext } from 'components/App/context/appContext'
import { DialogProps } from '../../../../../models/app/DialogProps.model'

export interface Props {
  scheduleTemplateByScheduleTemplateId: ScheduleTemplate
}
const AdminScheduleActionsDropdown = ({
  scheduleTemplateByScheduleTemplateId,
}: Props) => {
  const {
    setIsScheduleTemplateOpen,
    removeCustomScheduleTemplate,
    setIsEditTemplateName,
  } = useAdminMilestoneContext()!
  const { setDialogProps } = useAppContext()!

  return (
    <Grid.Item className="hc-pv-dense">
      <Dropdown size="dense" location="bottom-left">
        <Button type="ghost">
          Actions
          <EnterpriseIcon className="hc-ml-dense" icon={CaretDownIcon} />
        </Button>
        <Dropdown.Menu>
          <Dropdown.MenuItem
            onClick={() => {
              setIsScheduleTemplateOpen(true)
              setIsEditTemplateName(true)
            }}
          >
            <EnterpriseIcon className="hc-mr-dense" icon={PencilIcon} />
            Edit Template Name
          </Dropdown.MenuItem>
          <Dropdown.MenuItem
            onClick={() => {
              setIsScheduleTemplateOpen(true)
              setIsEditTemplateName(false)
            }}
          >
            <EnterpriseIcon className="hc-mr-dense" icon={SaveIcon} />
            Save As New Template
          </Dropdown.MenuItem>
          <Dropdown.MenuItem
            onClick={() =>
              setDialogProps(
                new DialogProps({
                  headingText: 'Are you sure you want to remove this template?',
                  approveButtonText: 'Yes',
                  onApprove: () =>
                    scheduleTemplateByScheduleTemplateId.template_id &&
                    removeCustomScheduleTemplate(
                      scheduleTemplateByScheduleTemplateId.template_id,
                    ),
                  refuseButtonText: 'Cancel',
                  onRefuse: () => setDialogProps(new DialogProps()),
                }),
              )
            }
          >
            <EnterpriseIcon className="hc-mr-dense" icon={TrashIcon} />
            Remove Current Template
          </Dropdown.MenuItem>
        </Dropdown.Menu>
      </Dropdown>
    </Grid.Item>
  )
}

export default AdminScheduleActionsDropdown
