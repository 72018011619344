import React, { useState } from 'react'
import {
  Avatar,
  Button,
  Card,
  Grid,
  Form,
  Tooltip,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { MinusIcon, TrashIcon } from '@enterprise-ui/icons'
import { MilestoneTemplate } from '../../../../../models/milestones/MilestoneTemplate.model'
import { useAdminMilestoneContext } from '../../context/adminMilestoneContext'
import { useMilestoneContext } from 'components/App/context/milestoneContext'
import { useAppContext } from 'components/App/context/appContext'
import { DialogProps } from '../../../../../models/app/DialogProps.model'

export interface Props {
  milestone: MilestoneTemplate
}

const AdminScheduleMilestoneCard = ({ milestone }: Props) => {
  const { setDialogProps } = useAppContext()!
  const { removeMilestone, updateMilestone } = useAdminMilestoneContext()!
  const {
    modifiedMilestoneIds,
    addToModifiedMilestoneIds,
    scheduleTemplateByScheduleTemplateId,
    isDependency,
  } = useMilestoneContext()!
  const [editMode, setEditMode] = useState(false)

  const milestoneTemplateFacets =
    scheduleTemplateByScheduleTemplateId.milestones.map(
      (template: MilestoneTemplate) => {
        return { value: template.name, label: template.name }
      },
    )

  const daysNotLessThan = () => {
    const dependency = scheduleTemplateByScheduleTemplateId.milestones.find(
      (ms: MilestoneTemplate) => {
        return ms.name === milestone.depends_on
      },
    )
    if (dependency !== undefined) {
      return milestone.days_out > dependency.days_out
    } else {
      return false
    }
  }

  return (
    <Card
      className={`sem-MilestoneTemplateCard ${
        modifiedMilestoneIds.has(milestone.milestone_id) ? 'modified' : ''
      }`}
      onClickCapture={() => setEditMode(true)}
    >
      {editMode ? (
        <div className="hc-pa-dense hc-mt-normal toolbar-Container">
          <Grid.Container justify="flex-end" spacing="none">
            {/*Collapse Button*/}
            <Grid.Item xs>
              <Tooltip location="bottom-right" content="Collapse">
                <Button
                  aria-label="Collapse"
                  iconOnly
                  type="ghost"
                  size="dense"
                  className="sem-MilestoneBtnRemove"
                  onClick={() => {
                    setEditMode(false)
                  }}
                >
                  <EnterpriseIcon color="gray" icon={MinusIcon} />
                </Button>
              </Tooltip>
            </Grid.Item>

            <Grid.Item>
              <Tooltip location="bottom" content="Delete">
                <Button
                  aria-label="Delete Milestone"
                  iconOnly
                  type="destructive"
                  size="dense"
                  className="sem-MilestoneBtnRemove"
                  onClick={() => {
                    if (
                      isDependency(
                        milestone.name,
                        scheduleTemplateByScheduleTemplateId.milestones,
                      )
                    ) {
                      setDialogProps({
                        headingText:
                          'Other milestones are dependent on this milestone.  Please remove it as a dependency from all relevant milestones, then try again.',
                        approveButtonText: 'Ok',
                        onApprove: () => setDialogProps(new DialogProps()),
                        onRefuse: () => setDialogProps(new DialogProps()),
                      })
                    } else {
                      setDialogProps(
                        new DialogProps({
                          headingText:
                            'Are you sure you want to remove this milestone?',
                          approveButtonText: 'Yes',
                          onApprove: () =>
                            removeMilestone(milestone.milestone_id),
                          refuseButtonText: 'Cancel',
                          onRefuse: () => setDialogProps(new DialogProps()),
                        }),
                      )
                    }
                  }}
                >
                  <EnterpriseIcon
                    size="inline"
                    color="white"
                    icon={TrashIcon}
                  />
                </Button>
              </Tooltip>
            </Grid.Item>
          </Grid.Container>

          <div className="hc-pa-dense">
            <Grid.Container spacing="dense">
              <Grid.Item xs>
                <Grid.Container spacing="none" align="flex-end">
                  <Grid.Item className="hc-clr-grey02" xs={12}>
                    <p className="title">{milestone.name}</p>
                  </Grid.Item>
                  <Grid.Item className="hc-clr-grey02" xs={12}>
                    <p className="hc-clr-grey02">{milestone.role}</p>
                  </Grid.Item>
                </Grid.Container>
              </Grid.Item>
              <Grid.Item>
                <Grid.Container align="flex-end" spacing="dense">
                  <Grid.Item className="hc-pl-none" xs>
                    <Avatar aria-label="risk_days_out" color="indigo">
                      <span aria-hidden="true">{milestone.days_out}</span>
                    </Avatar>
                  </Grid.Item>
                  {milestone.risk && (
                    <Grid.Item className="hc-pl-none hc-pr-none" xs>
                      <Avatar aria-label="risk_days_out" color="red">
                        <span aria-hidden="true">
                          {milestone.risk_days_out}
                        </span>
                      </Avatar>
                    </Grid.Item>
                  )}
                </Grid.Container>
              </Grid.Item>
              <Grid.Container spacing="none" justify="space-around">
                <Grid.Item xs={4} className="hc-mt-dense">
                  <Form.Field
                    id="days_out"
                    type="number"
                    className="simple_error_messaging_enabled"
                    name="points"
                    label="Days Out"
                    value={milestone.days_out}
                    error={daysNotLessThan()}
                    errorText="Days out must be less than dependency milestone"
                    onChange={(e: any) => {
                      updateMilestone({
                        ...milestone,
                        days_out: e.target.value,
                      })
                      addToModifiedMilestoneIds(milestone.milestone_id)
                    }}
                  />
                </Grid.Item>
                {milestone.risk && (
                  <Grid.Item xs={4} className="hc-mt-dense">
                    <Form.Field
                      id="risk_days_out"
                      type="number"
                      name="points"
                      label="Risk Days Out"
                      value={milestone.risk_days_out}
                      onChange={(e: any) => {
                        updateMilestone({
                          ...milestone,
                          risk_days_out: e.target.value,
                        })
                        addToModifiedMilestoneIds(milestone.milestone_id)
                      }}
                    />
                  </Grid.Item>
                )}
                <Grid.Item className="hc-clr-grey02" xs={12}>
                  <Form.Field
                    type="select"
                    id="dependsOn"
                    label="Depends On"
                    value={milestone.depends_on}
                    options={milestoneTemplateFacets}
                    onUpdate={(e: any, dependsOn: string) => {
                      updateMilestone({
                        ...milestone,
                        depends_on: dependsOn,
                      })
                      addToModifiedMilestoneIds(milestone.milestone_id)
                    }}
                  />
                </Grid.Item>
              </Grid.Container>
            </Grid.Container>
          </div>
        </div>
      ) : (
        <div className="hc-pa-dense hc-mt-dense hc-mb-none">
          <Grid.Container align="flex-end" spacing="dense">
            <Grid.Item xs>
              <Grid.Container spacing="none">
                <Grid.Item className="hc-clr-grey02" xs={12}>
                  <p className="title">{milestone.name}</p>
                </Grid.Item>
                <Grid.Item className="hc-clr-grey02" xs={12}>
                  {milestone.role}
                </Grid.Item>
                <Grid.Item className="hc-clr-grey02" xs={12}>
                  <p className="hc-clr-grey02">{milestone.depends_on}</p>
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
            <Grid.Item className="hc-pl-none" align="right">
              <Tooltip location="bottom" content="Days Out">
                <Avatar aria-label="risk_days_out" color="indigo">
                  <span aria-hidden="true">{milestone.days_out}</span>
                </Avatar>
              </Tooltip>
            </Grid.Item>
            {milestone.risk && (
              <Grid.Item className="hc-pl-none hc-pr-none" align="right">
                <Tooltip location="bottom" content="Risk Days Out">
                  <Avatar aria-label="risk_days_out" color="red">
                    <span aria-hidden="true">{milestone.risk_days_out}</span>
                  </Avatar>
                </Tooltip>
              </Grid.Item>
            )}
          </Grid.Container>
        </div>
      )}
    </Card>
  )
}

export default AdminScheduleMilestoneCard
