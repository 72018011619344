import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { Column } from 'ag-grid-community'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { Grid } from '@enterprise-ui/canvas-ui-react'
import { useProjectSignListContext } from '../../../context/projectSignListContext'
import SignResponse from '../../../../../../../models/signs/SignResponse.model'

export interface Props {
  value: string
  column: Column
  data: SignResponse
}

const AutocompletesubstrateCellEditor = forwardRef(
  ({ value, column, data }: Props, ref) => {
    const { signFacets } = useProjectSignListContext()!
    const [editValue, setEditValue] = useState(value)

    const substrateOptions = signFacets.sign_substrate

    const availableSubstrateOptions = () => {
      return column.getColId().split('.')[1] === 'substrate1'
        ? substrateOptions.filter(
            (option: any) => option.value !== data.printing.substrate2,
          )
        : substrateOptions.filter(
            (option: any) => option.value !== data.printing.substrate1,
          )
    }

    const handleChange = (id: string, value: string) => setEditValue(value)

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return editValue
        },
      }
    })

    return (
      <div className="hc-pa-dense">
        <Grid.Container className="sem_StandardForm error_messaging_enabled">
          <Grid.Item xs={12}>
            <Autocomplete
              label={column.getColDef().headerName}
              optionHeight="dense"
              options={() => availableSubstrateOptions()}
              value={{
                value: editValue ? editValue : '',
                label: editValue ? editValue : '',
              }}
              onUpdate={(id: string, value: any) => {
                if (value) {
                  handleChange(id, value.value)
                } else {
                  handleChange(id, '')
                }
              }}
              allowCustomInputValue
            />
          </Grid.Item>
        </Grid.Container>
      </div>
    )
  },
)

export default AutocompletesubstrateCellEditor
