export const NEW_SIGN_PROJECT = 'NEW_SIGN_PROJECT'
export const NAME_MAX_LENGTH = 22

export enum SapProjectExecutionStatus {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  PENDING = 'PENDING',
  CRITICAL = 'CRITICAL',
}

export const SIGN_PROJECT_TYPE_OPTIONS = [
  {
    value: 'REV',
    label: 'REV',
  },
  {
    value: 'SPLN',
    label: 'SPLN',
  },
  {
    value: 'TRN',
    label: 'TRN',
  },
]

export const SIGN_PROJECT_TACTIC_OPTIONS = [
  {
    value: 'Z001 Concept',
    label: 'Z001 Concept',
  },
  {
    value: 'Z032 Sponsorships',
    label: 'Z032 Sponsorships',
  },
  {
    value: 'Z078 Experience',
    label: 'Z078 Experience',
  },
  {
    value: 'Concept',
    label: 'Concept (Deprecated)',
    disabled: true,
  },
  {
    value: 'Shoot',
    label: 'Shoot (Deprecated)',
    disabled: true,
  },
  {
    value: 'Print',
    label: 'Print (Deprecated)',
    disabled: true,
  },
  {
    value: 'Style Guide',
    label: 'Style Guide (Deprecated)',
    disabled: true,
  },
]
