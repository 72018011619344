import Axios from 'axios'
import React, { createContext, useState, useContext } from 'react'
import { connect } from 'react-redux'
import { DialogProps } from '../../../../models/app/DialogProps.model'
import { MilestoneTemplate } from '../../../../models/milestones/MilestoneTemplate.model'
import { ScheduleTemplate } from '../../../../models/milestones/ScheduleTemplate'
import { TemplateResponse } from '../../../../models/milestones/TemplateResponse.model'
import {
  ADMIN_SERVICE_API_DOMAIN_URL,
  TOASTER_DEFAULTS,
} from '../../../App/constants/appConstants'
import { useEnv } from '@praxis/component-runtime-env'
import { useAppContext } from '../../../App/context/appContext'
import { useMilestoneContext } from '../../../App/context/milestoneContext'
import { useToaster } from '@enterprise-ui/canvas-ui-react'

type ContextType = {
  addMilestones: (milestoneIdsToAdd: string[]) => void
  removeMilestone: (milestoneIdsToRemove: string) => void
  updateMilestone: (milestoneIdToUpdate: MilestoneTemplate) => void

  isScheduleTemplateModalOpen: boolean
  setIsScheduleTemplateOpen: Function
  removeCustomScheduleTemplate: (templateId: string) => void

  isEditTemplateName: boolean
  setIsEditTemplateName: Function

  milestoneMenu: string
  setMilestoneMenu: Function
  isOpen: boolean
  setIsOpen: Function
  closeMilestoneForm: Function

  templateMenu: string
  setTemplateMenu: Function
  closeTemplateForm: Function

  addNewScheduleTemplate: (newScheduleTemplate: ScheduleTemplate) => void
  addNewMilestoneTemplate: (milestoneToAdd: MilestoneTemplate) => void
  saveMilestoneTemplate: (milestoneToSave: MilestoneTemplate) => void
  removeMilestoneTemplate: (milestoneId: string) => void
  milestoneWarningIsVisible: boolean
  setMilestoneWarningIsVisible: (isVisible: boolean) => void
}

export const AdminMilestoneContext = createContext<ContextType | undefined>(
  undefined,
)

type Props = {
  children: React.ReactNode
}

export const AdminMilestoneProviderComponent = ({ children }: Props) => {
  const { setFullPageLoadingMessage, setDialogProps } = useAppContext()!
  const {
    milestoneTemplates,
    setAllScheduleTemplates,
    setMilestoneTemplates,
    setScheduleTemplateByScheduleTemplateId,
    setScheduleTemplatesByScheduleType,
    setModifiedMilestoneIds,
  } = useMilestoneContext()!
  const env = useEnv()
  const makeToast = useToaster()
  const [isScheduleTemplateModalOpen, setIsScheduleTemplateOpen] =
    useState(false)
  const [isEditTemplateName, setIsEditTemplateName] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [milestoneMenu, setMilestoneMenu] = useState('')
  const [templateMenu, setTemplateMenu] = useState('')
  const [milestoneWarningIsVisible, setMilestoneWarningIsVisible] =
    useState(false)

  const addMilestones = (milestoneIdsToAdd: string[]) => {
    setScheduleTemplateByScheduleTemplateId(
      (previousState: ScheduleTemplate) =>
        new ScheduleTemplate({
          ...previousState,
          milestones: [
            ...previousState.milestones,
            ...milestoneIdsToAdd.map((id: string) =>
              milestoneTemplates.find(
                (milestone: MilestoneTemplate) => id === milestone.milestone_id,
              ),
            ),
          ],
        }),
    )
  }

  const removeMilestone = (milestoneIdToRemove: string) => {
    setScheduleTemplateByScheduleTemplateId(
      (previousState: ScheduleTemplate) =>
        new ScheduleTemplate({
          ...previousState,
          milestones: [
            ...previousState.milestones.filter(
              (milestone: MilestoneTemplate) =>
                milestone.milestone_id !== milestoneIdToRemove,
            ),
          ],
        }),
    )
    setDialogProps(new DialogProps())
  }

  const updateMilestone = (milestoneToUpdate: MilestoneTemplate) => {
    setScheduleTemplateByScheduleTemplateId(
      (previousState: ScheduleTemplate) =>
        new ScheduleTemplate({
          ...previousState,
          milestones: previousState.milestones.map(
            (milestone: MilestoneTemplate) =>
              milestone.milestone_id === milestoneToUpdate.milestone_id
                ? milestoneToUpdate
                : milestone,
          ),
        }),
    )
  }

  const removeCustomScheduleTemplate = async (templateId: string) => {
    setFullPageLoadingMessage('Removing Template...')

    try {
      await Axios.delete(
        `${
          env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
        }/templates/schedule_templates/${templateId}`,
      )
      setScheduleTemplateByScheduleTemplateId(new ScheduleTemplate())
      setAllScheduleTemplates((previousState: ScheduleTemplate[]) =>
        previousState.filter(
          (scheduleTemplate: ScheduleTemplate) =>
            scheduleTemplate.template_id !== templateId,
        ),
      )
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Delete Template',
        message: err.response.data.message,
      })
    }
    setDialogProps(new DialogProps())
    setFullPageLoadingMessage('')
  }

  const closeMilestoneForm = () => {
    setMilestoneMenu('')
    setIsOpen(false)
  }

  const closeTemplateForm = () => {
    setTemplateMenu('')
    setIsOpen(false)
  }

  const addNewScheduleTemplate = async (
    newScheduleTemplate: ScheduleTemplate,
  ) => {
    try {
      const res = await Axios.post(
        `${
          env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
        }/templates/schedule_templates`,
        newScheduleTemplate,
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      const newTemplate = new TemplateResponse(res.data)
      setScheduleTemplatesByScheduleType(
        (previousState: TemplateResponse[]) => [...previousState, newTemplate],
      )
      const scheduleTemplateToSave = new ScheduleTemplate(res.data)
      setScheduleTemplateByScheduleTemplateId(scheduleTemplateToSave)
      setAllScheduleTemplates((previousState: ScheduleTemplate[]) => [
        ...previousState,
        newScheduleTemplate,
      ])
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'success',
        heading: 'New Schedule Template Saved',
        message: 'Successfully saved new schedule template',
      })
      setModifiedMilestoneIds(new Set())
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Save New Template',
        message: err.response.data.message,
      })
    }
  }

  const addNewMilestoneTemplate = async (milestoneToAdd: MilestoneTemplate) => {
    try {
      await Axios.post(
        `${
          env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
        }/templates/milestone_templates`,
        milestoneToAdd,
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      setMilestoneTemplates((previousState: MilestoneTemplate[]) => [
        ...previousState,
        milestoneToAdd,
      ])
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'success',
        heading: 'New Milestone Saved',
        message: 'Successfully saved new milestone',
      })
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Create New Milestone',
        message: err.response.data.message,
      })
    }
  }

  const saveMilestoneTemplate = async (milestoneToSave: MilestoneTemplate) => {
    try {
      await Axios.put(
        `${
          env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
        }/templates/milestone_templates/${milestoneToSave.milestone_id}`,
        milestoneToSave,
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      setMilestoneTemplates(
        milestoneTemplates.map((milestone: MilestoneTemplate) =>
          milestone.milestone_id === milestoneToSave.milestone_id
            ? new MilestoneTemplate({ ...milestoneToSave })
            : milestone,
        ),
      )
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'success',
        heading: 'Milestone Saved',
        message: 'Successfully saved milestone changes',
      })
      setModifiedMilestoneIds(new Set())
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Save Milestone',
        message: err.response.data.message,
      })
    }
  }

  const removeMilestoneTemplate = async (milestoneId: string) => {
    try {
      await Axios.delete(
        `${
          env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
        }/templates/milestone_templates/${milestoneId}`,
      )
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'success',
        heading: 'Milestone Removed',
        message: 'Successfully removed milestone',
      })
      setMilestoneTemplates((previousState: MilestoneTemplate[]) =>
        previousState.filter(
          (milestone: MilestoneTemplate) =>
            milestone.milestone_id !== milestoneId,
        ),
      )
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Remove Template',
        message: err.response.data.message,
      })
    }
    setDialogProps(new DialogProps())
  }

  return (
    <AdminMilestoneContext.Provider
      value={{
        addMilestones,
        removeMilestone,
        updateMilestone,

        isScheduleTemplateModalOpen,
        setIsScheduleTemplateOpen,
        removeCustomScheduleTemplate,

        isEditTemplateName,
        setIsEditTemplateName,

        milestoneMenu,
        setMilestoneMenu,
        isOpen,
        setIsOpen,
        closeMilestoneForm,

        templateMenu,
        setTemplateMenu,
        closeTemplateForm,

        addNewMilestoneTemplate,
        addNewScheduleTemplate,
        saveMilestoneTemplate,
        removeMilestoneTemplate,

        milestoneWarningIsVisible,
        setMilestoneWarningIsVisible,
      }}
    >
      {children}
    </AdminMilestoneContext.Provider>
  )
}

export const useAdminMilestoneContext = () => useContext(AdminMilestoneContext)

export const AdminMilestoneProvider = connect(
  null,
  null,
)(AdminMilestoneProviderComponent)
