import {
  Button,
  Divider,
  Form,
  Grid,
  Heading,
  Modal,
} from '@enterprise-ui/canvas-ui-react'
import React, { useState } from 'react'
import EnterpriseIcon, { CancelIcon } from '@enterprise-ui/icons'
import { useProjectSignListContext } from '../../context/projectSignListContext'
import { useAgGridSignListContext } from '../../context/agGridSignListContext'
import { useProjectDetailsContext } from 'components/ProjectDetails/context/projectDetailsContext'

const SignGroupModal = () => {
  const { projectGroupList, setProjectGroupList } = useProjectDetailsContext()!
  const { isSignGroupOpen, setIsSignGroupOpen } = useProjectSignListContext()!
  const { gridApi, handleAddSignToGroup } = useAgGridSignListContext()!

  const [selectGroupName, setSelectGroupName] = useState('')
  const [createdGroupName, setCreatedGroupName] = useState('')

  const handleSelectGroupName = (groupName: string) => {
    setSelectGroupName(groupName)
    setCreatedGroupName('')
  }

  const handleCreatedGroupName = (groupName: string) => {
    setCreatedGroupName(groupName)
    setSelectGroupName('')
  }

  const handleSave = () => {
    const updatedGroup =
      selectGroupName !== ''
        ? selectGroupName
        : createdGroupName !== ''
        ? createdGroupName
        : 'No Assigned Group'
    if (createdGroupName !== '') {
      setProjectGroupList([...projectGroupList, createdGroupName])
    }
    handleAddSignToGroup(updatedGroup)
    handleClose()
  }

  const handleClose = () => {
    gridApi?.clearFocusedCell()
    gridApi?.deselectAll()
    setSelectGroupName('')
    setCreatedGroupName('')
    setIsSignGroupOpen(false)
  }

  return (
    // WARNING: Canvas v7 - Modal "closeButton" prop has been deprecated.
    <Modal
      className="sign-group-modal"
      isVisible={isSignGroupOpen}
      onRefuse={() => handleClose()}
    >
      <Grid.Container justify="space-between">
        <Grid.Item xs={true}>
          <Heading className="hc-clr-grey02" size={4}>
            Add Sign to Group
          </Heading>
        </Grid.Item>
        <Grid.Item className="sem_Modal__close">
          <Button
            aria-label="Cancel sign group"
            iconOnly
            size="dense"
            className="sem_IconBtn"
            onClick={() => handleClose()}
          >
            <EnterpriseIcon icon={CancelIcon} />
          </Button>
        </Grid.Item>
        <Divider className="hc-mb-normal hc-ph-none" />
      </Grid.Container>
      <Grid.Container>
        <Grid.Item className="hc-pv-expanded" xs={6}>
          <Form.Field
            id="selectGroup"
            type="select"
            label="Select from existing Sign Groups:"
            value={selectGroupName}
            options={
              projectGroupList.length > 0
                ? [
                    { value: '', label: '--- Select a Sign Group ---' },
                    ...projectGroupList.map((group: string) => ({
                      value: group,
                      label: group,
                    })),
                  ]
                : [
                    {
                      value: undefined,
                      label: '--- Create a new Sign Group ---',
                      disabled: true,
                    },
                  ]
            }
            onUpdate={(e: any, value: string) => handleSelectGroupName(value)}
          />
        </Grid.Item>
        <Grid.Item className="hc-pv-expanded" xs={6}>
          <Form.Field
            id="createGroup"
            label="Create a new Sign Group"
            value={createdGroupName}
            onChange={(e: any) => handleCreatedGroupName(e.target.value)}
          />
        </Grid.Item>
        <Divider className="hc-mb-normal hc-ph-none" />
      </Grid.Container>
      <Grid.Container justify="flex-end">
        <Grid.Item className="hc-pr-none">
          <Button
            type="primary"
            disabled={!selectGroupName && !createdGroupName}
            onClick={() => handleSave()}
          >
            {createdGroupName ? 'Create new Sign Group' : 'Add Sign to Group'}
          </Button>
        </Grid.Item>
        <Grid.Item>
          <Button onClick={() => handleClose()}>Cancel</Button>
        </Grid.Item>
      </Grid.Container>
    </Modal>
  )
}

export default SignGroupModal
