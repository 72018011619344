import React, { useEffect, useState } from 'react'
import {
  Card,
  Grid,
  Avatar,
  Tooltip,
  ExpandableSection,
  Divider,
  Chip,
  Form,
  Button,
  useToaster,
} from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import EnterpriseIcon, { CancelIcon, PlusIcon } from '@enterprise-ui/icons'
import { parseInitials } from '../../App/helpers/nameInteractions'
import {
  InternalUser,
  RosterUser,
} from '../../../models/roster/RosterUser.model'
import { RosterElement } from '../../../models/roster/RosterElement.model'
import { RosterUserType } from '../constants/rosterConstants'
import { BusinessPartner } from '../../../models/app/BusinessPartner.model'
import Axios from 'axios'
import {
  ADMIN_SERVICE_API_DOMAIN_URL,
  TOASTER_DEFAULTS,
} from '../../App/constants/appConstants'
import { useEnv } from '@praxis/component-runtime-env'
import { LdapResponse } from '../../../models/roster/LdapResponse.model'
import { useAppContext } from '../../App/context/appContext'
import { DialogProps } from '../../../models/app/DialogProps.model'

export interface Props {
  rosterElement: RosterElement
  deleteFromRoster: (user: RosterUser, rowTitle: string) => void
  addToRoster: (user: RosterUser, rowTitle: string) => void
  isRowChanged: boolean
  resetTrigger: string
  hasEditPermission: boolean
}

const RosterCard = ({
  rosterElement,
  deleteFromRoster,
  addToRoster,
  isRowChanged,
  resetTrigger,
  hasEditPermission,
}: Props) => {
  const { setDialogProps } = useAppContext()!
  const env = useEnv()
  const makeToast = useToaster()
  const [expanded, setExpanded] = useState(false)
  const [addNameOpen, setAddNameOpen] = useState(false)
  const [emailInput, setEmailInput] = useState('')
  const [addedBusinessPartnersByName, setAddedBusinessPartnersByName] =
    useState<string[]>([])

  // any time the campaign type or roster list is changed, reset everything
  useEffect(() => {
    setExpanded(false)
    setAddNameOpen(false)
    setEmailInput('')
    setAddedBusinessPartnersByName([])
  }, [resetTrigger])

  useEffect(() => {
    setAddedBusinessPartnersByName(
      rosterElement.users
        .filter((user: RosterUser) => user instanceof BusinessPartner)
        .map((user: RosterUser) => (user as BusinessPartner).name),
    )
  }, [rosterElement.users])

  const isDuplicateEmail =
    emailInput !== '' &&
    rosterElement.users.find(
      (user: RosterUser) =>
        user instanceof InternalUser &&
        user.email.toLowerCase() === emailInput.toLowerCase(),
    ) !== undefined

  const handleAddUser = (user: RosterUser, rowTitle: string) => {
    setAddNameOpen(false)
    addToRoster(user, rowTitle)
  }

  // get LDAP info when user pressed 'enter'
  const handleKeyDown = async (e: any) => {
    if (e.key === 'Enter' && !isDuplicateEmail) {
      try {
        var instance = Axios.create()
        delete instance.defaults.headers.common['id_token']
        const res = await instance.get(`${env.ldapApiUrl}/lan_ids`, {
          params: {
            email: emailInput,
            key: env.apiKey,
          },
        })
        const ldapResponse = new LdapResponse(res.data)
        handleAddUser(
          new InternalUser({
            id: ldapResponse.sam_account_name,
            email: ldapResponse.mail,
            type: RosterUserType.INTERNAL,
          }),
          rosterElement.title,
        )
      } catch (err: any) {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'error',
          heading: 'Failed to Get Roster Info',
          message: err.response.data.message,
        })
      }
      setEmailInput('')
    }
  }

  const getBusinessPartnerOptions = async (query: string) => {
    try {
      const res = await Axios.get(
        `${
          env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
        }/business_partners/search?query=${query}`,
      )
      return res.data
        .map((bp: any) => new BusinessPartner(bp))
        .filter(
          (businessPartner: BusinessPartner) =>
            !addedBusinessPartnersByName.includes(businessPartner.name),
        )
        .map((businessPartner: BusinessPartner) => {
          return {
            id: businessPartner.name,
            value: businessPartner,
            label: businessPartner.name,
          }
        })
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Get Business Partners',
        message: err.response.data.message,
      })
    }
  }

  const CollapsedUserChip = ({ user }: { user: RosterUser }) => {
    const userName =
      user instanceof InternalUser ? user.email : (user as BusinessPartner).name

    return userName && userName.length > 0 && !expanded ? (
      <Tooltip location="bottom-right" content={userName}>
        <Avatar
          aria-label="roster_avatar"
          className="sem-RosterAvatar hc-mr-dense hc-mb-dense"
          size="dense"
        >
          {user instanceof InternalUser
            ? parseInitials(user.email)
            : (user as BusinessPartner).name.substring(0, 2)}
        </Avatar>
      </Tooltip>
    ) : null
  }

  const ExpandedUserChip = ({ user }: { user: RosterUser }) => {
    const userName =
      user instanceof InternalUser ? user.email : (user as BusinessPartner).name

    return userName && userName.length > 0 ? (
      <Chip
        className="sem-RosterChip"
        onRequestDelete={
          hasEditPermission
            ? () =>
                setDialogProps(
                  new DialogProps({
                    headingText: 'Are you sure you want to remove this user?',
                    approveButtonText: 'Yes',
                    onApprove: () =>
                      deleteFromRoster(user, rosterElement.title),
                    refuseButtonText: 'Cancel',
                    onRefuse: () => setDialogProps(new DialogProps()),
                  }),
                )
            : undefined
        }
        size="dense"
      >
        {userName}
      </Chip>
    ) : null
  }

  return (
    <Card
      className={
        isRowChanged ? 'sem-MilestoneCard modified' : 'sem-MilestoneCard'
      }
      elevation={0}
    >
      <ExpandableSection
        padding="dense"
        onExpand={() => setExpanded(!expanded)}
        expanded={expanded}
      >
        <Grid.Container
          spacing="expanded"
          justify="space-between"
          className="sem-RosterContainer"
        >
          <Grid.Item className="sem-RosterTitle hc-pr-normal" xs={5}>
            {rosterElement.title}
          </Grid.Item>
          <Grid.Item xs={true}>
            {rosterElement.users.map((user: RosterUser) => (
              <CollapsedUserChip key={user.id} user={user} />
            ))}
          </Grid.Item>
        </Grid.Container>
        <ExpandableSection.Content>
          <Divider />
          <div className="hc-pa-dense">
            <Grid.Container>
              <Grid.Item xs={12}>
                {rosterElement.users.map((user: RosterUser) => (
                  <ExpandedUserChip key={user.id} user={user} />
                ))}
                {!addNameOpen && hasEditPermission && (
                  <Button
                    aria-label="Add user to roster"
                    type="ghost"
                    size="dense"
                    iconOnly
                    onClick={() => setAddNameOpen(true)}
                  >
                    <EnterpriseIcon size="inline" icon={PlusIcon} />
                  </Button>
                )}
              </Grid.Item>

              {addNameOpen ? (
                rosterElement.type === RosterUserType.INTERNAL ? (
                  <Grid.Item className="sem-RosterForm" xs={12}>
                    <Grid.Container spacing="dense">
                      <Grid.Item xs={8}>
                        <Form.Field
                          id="roster_email"
                          onChange={(e: any) => {
                            setEmailInput(e.target.value)
                          }}
                          onKeyDown={handleKeyDown}
                          value={emailInput}
                          hintText="Provide a valid email address and hit ENTER."
                          error={isDuplicateEmail}
                          errorText="Duplicate email"
                        />
                      </Grid.Item>
                      <Grid.Item xs={true}>
                        <Button iconOnly onClick={() => setAddNameOpen(false)}>
                          <EnterpriseIcon icon={CancelIcon} />
                        </Button>
                      </Grid.Item>
                    </Grid.Container>
                  </Grid.Item>
                ) : (
                  <Grid.Item className="sem-RosterForm" xs={12}>
                    <Grid.Container spacing="dense">
                      <Grid.Item xs={8}>
                        <Autocomplete
                          options={getBusinessPartnerOptions}
                          filter={Autocomplete.filters.none}
                          value={null}
                          optionHeight="dense"
                          onUpdate={(
                            e: any,
                            value: { value: BusinessPartner },
                          ) => {
                            if (value) {
                              handleAddUser(value.value, rosterElement.title)
                              setAddedBusinessPartnersByName([
                                ...addedBusinessPartnersByName,
                                value.value.name,
                              ])
                            }
                          }}
                        />
                      </Grid.Item>
                    </Grid.Container>
                  </Grid.Item>
                )
              ) : null}
            </Grid.Container>
          </div>
        </ExpandableSection.Content>
      </ExpandableSection>
    </Card>
  )
}

export default RosterCard
