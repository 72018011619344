import React from 'react'
import { Chip } from '@enterprise-ui/canvas-ui-react'

export interface Props {
  value: string
}

const DefaultChip = ({ value }: Props) => {
  return <Chip size="dense">{value}</Chip>
}

export default DefaultChip
