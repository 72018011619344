import React from 'react'
import { Dropdown, Button, Grid } from '@enterprise-ui/canvas-ui-react'
import { useFilterContext } from '../../App/context/filterContext'
import { GridApi } from 'ag-grid-community'
import EnterpriseIcon, { CaretDownIcon } from '@enterprise-ui/icons'

export interface Props {
  gridApi?: GridApi
}

const NotificationsViewDropdown = ({ gridApi }: Props) => {
  const { currentNotificationsView, setCurrentNotificationsView } =
    useFilterContext()!

  const setNotificationFilterModel = (view: string) => {
    setCurrentNotificationsView(view)
    gridApi?.setFilterModel({
      status: {
        filterType: 'text',
        type: 'contains',
        filter: view,
      },
    })
  }

  return (
    <Grid.Item className="hc-ph-dense hc-pb-dense" xs={true}>
      <Dropdown location="bottom-left">
        <Button type="ghost">
          View: {currentNotificationsView}
          <EnterpriseIcon className="hc-ml-dense" icon={CaretDownIcon} />
        </Button>
        <Dropdown.Menu>
          <Dropdown.MenuItem onClick={() => setNotificationFilterModel('ALL')}>
            All
          </Dropdown.MenuItem>
          <Dropdown.MenuItem
            onClick={() => setNotificationFilterModel('UNREAD')}
          >
            Unread
          </Dropdown.MenuItem>
          <Dropdown.MenuItem onClick={() => setNotificationFilterModel('READ')}>
            Read
          </Dropdown.MenuItem>
        </Dropdown.Menu>
      </Dropdown>
    </Grid.Item>
  )
}

export default NotificationsViewDropdown
