export class Department {
  department_id: number
  department_name: string
  department_display_name: string
  department_type_code: string
  division_id: number
  division_name: string
  division_display_name: string
  sub_group_id: number
  sub_group_name: string
  group_id: number
  group_name: string
  group_display_name: string

  constructor(props: any = {}) {
    this.department_id = props.department_id || 0
    this.department_name = props.department_name || ''
    this.department_display_name = props.department_display_name || ''
    this.department_type_code = props.department_type_code || ''
    this.division_id = props.division_id || 0
    this.division_name = props.division_name || ''
    this.division_display_name = props.division_display_name || ''
    this.sub_group_id = props.sub_group_id || 0
    this.sub_group_name = props.sub_group_name || ''
    this.group_id = props.group_id || 0
    this.group_name = props.group_name || ''
    this.group_display_name = props.group_display_name || ''
  }
}
