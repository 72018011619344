import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Anchor, Grid, Popover } from '@enterprise-ui/canvas-ui-react'
import { ProjectSummary } from '../../../../models/dashboardV2/ProjectSummary.model'
import { Column, ColumnEvent } from 'ag-grid-community'

export interface Props {
  value: string
  data: ProjectSummary
  column: Column
}

const ProjectNameLink = ({ data, value, column }: Props) => {
  const [colWidth, setColWidth] = useState(column.getActualWidth())
  useEffect(() => {
    column.addEventListener('widthChanged', (event: ColumnEvent) => {
      setColWidth(column.getActualWidth())
    })
  }, [column])

  const popoverContent = (
    <Grid.Container spacing="dense">
      <Grid.Item className="hc-pa-dense" xs={12}>
        <Anchor
          className="sem_DashboardLink"
          as={Link}
          to={`/projects/${data.project_id}`}
        >
          {value}
        </Anchor>
      </Grid.Item>
    </Grid.Container>
  )

  return (
    <Popover content={popoverContent} size="expanded" location="bottom-left">
      <Grid.Container className="ag-cell-value" style={{ width: colWidth }}>
        <Grid.Item className="ag-cell-value" xs={12}>
          <Anchor
            className="sem_DashboardLink"
            as={Link}
            to={`/projects/${data.project_id}`}
          >
            {value}
          </Anchor>
        </Grid.Item>
      </Grid.Container>
    </Popover>
  )
}

export default ProjectNameLink
