import { useFormik } from 'formik'
import { KitInfoRequest } from '../../../../../models/kits/KitInfoRequest.model'

export const useKitInfoForm = (
  kitInfoRequest: KitInfoRequest,
  updatedKitInfoRequest: (req: KitInfoRequest) => void,
) => {
  const currentKitInfo = new KitInfoRequest(kitInfoRequest)

  return useFormik({
    enableReinitialize: true,
    initialValues: currentKitInfo,
    onSubmit(values) {
      const kit_info = new KitInfoRequest({ ...values })
      updatedKitInfoRequest(kit_info)
    },
  })
}
