import { get } from 'lodash'
import { MessagingItemInfo } from './MessagingResponse.model'

export default class MessagingRequest {
  sign_size: string
  item_info: MessagingItemInfo[]
  updated_by: string
  headline?: string
  mpp: boolean
  topper_brand?: string
  disclaimer?: string
  inclusions?: string
  exclusions?: string
  logo_url?: string
  reason_code?: string

  constructor(props: any = {}) {
    this.sign_size = props.sign_size || 'OTHER'
    this.item_info = get(props, 'item_info', []).map(
      (item: any) => new MessagingItemInfo(item),
    )
    this.updated_by = props.updated_by || ''
    this.headline = props.headline
    this.mpp = props.mpp || false
    this.topper_brand = props.topper_brand || null
    this.disclaimer = props.disclaimer
    this.inclusions = props.inclusions
    this.exclusions = props.exclusions
    this.logo_url = props.logo_url
    this.reason_code = props.reason_code
  }
}
