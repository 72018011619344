import React, { createContext, useState, useContext, useCallback } from 'react'
import Axios from 'axios'
import { connect } from 'react-redux'
import { useBlueprintDetailsContainerContext } from '../../../context/blueprintDetailsContainerContext'
import {
  ADMIN_SERVICE_API_DOMAIN_URL,
  HEADER_OBJECT,
  PROJECT_SERVICE_API_DOMAIN_URL,
  TOASTER_DEFAULTS,
} from 'components/App/constants/appConstants'
import { useEnv } from '@praxis/component-runtime-env'
import {
  ProjectFund,
  ProjectFundInfo,
} from '../../../../../models/projects/ProjectFundInfo.model'
import { isDateInRange } from 'components/App/helpers/dateHelpers'
import moment from 'moment'
import { BlueprintCampaign } from '../../../../../models/blueprints/BlueprintCampaign.model'
import { Division } from '../../../../../models/merchandise/hierarchy/Division.model'
import { Pyramid } from '../../../../../models/merchandise/hierarchy/Pyramid.model'
import { Department } from '../../../../../models/merchandise/hierarchy/Department.model'
import SapTableProjectRequest from '../../../../../models/projects/SapTableProjectRequest.model'
import SapTableProject from '../../../../../models/projects/SapTableProject.model'
import SapProjectFieldsModified from '../../../../../models/projects/SapProjectFieldsModified.model'
import { RowNode } from 'ag-grid-community'
import { useToaster } from '@enterprise-ui/canvas-ui-react'
import appConfig from '../../../../../config/appConfig'
import {
  PROJECT_TACTIC_OPTIONS,
  PROJECT_TYPE_OPTIONS,
} from 'components/BlueprintDetails/components/BlueprintProjectList/constants/blueprintProjectListConstants'

type ContextType = {
  projectList: SapTableProject[]
  setProjectList: (projectList: SapTableProject[]) => void
  loadProjectList: () => void
  isLoadingProjectList: boolean
  setIsLoadingProjectList: (isLoading: boolean) => void
  projectFunds: ProjectFund[]
  setProjectFunds: (projectFunds: ProjectFund[]) => void
  loadProjectFunds: () => void
  blueprintCampaigns: BlueprintCampaign[]
  setBlueprintCampaigns: (campaigns: BlueprintCampaign[]) => void
  loadCampaigns: (setDate: string, campaignName: string) => void
  isLoadingCampaigns: boolean
  setIsLoadingCampaigns: (loading: boolean) => void
  loadCampaignIds: (type: string, setDate: string) => any
  isLoadingCampaignIds: boolean
  setIsLoadingCampaignIds: (loading: boolean) => void
  divisionOptions: Division[]
  setDivisionOptions: (divisions: Division[]) => void
  loadDivisionOptions: (pyramids: Pyramid[]) => void
  departmentOptions: Department[]
  setDepartmentOptions: (departments: Department[]) => void
  loadDepartmentOptions: (divisions: Division[]) => void
  addToDepartmentOptions: (division: Division) => void
  isProjectListFullScreen: boolean
  setIsProjectListFullScreen: (value: boolean) => void
  newProjectModal: { type: string; node: RowNode | null } | undefined
  setNewProjectModal: (
    value: { type: string; node: RowNode | null } | undefined,
  ) => void
  projectsModified: boolean
  setProjectsModified: (value: boolean) => void
  modifiedProjects: SapTableProjectRequest[]
  setModifiedProjects: Function
  modifiedProjectIds: string[]
  setModifiedProjectIds: Function
  removedProjects: SapTableProjectRequest[]
  setRemovedProjects: Function
  saveModifiedProjects: () => void
  projectModifiedFields: SapProjectFieldsModified[]
  setProjectModifiedFields: (projects: SapProjectFieldsModified[]) => void
  setSelectOptions: (
    field: string,
    setDate?: string,
  ) => Promise<Object[] | undefined>
}

export const BlueprintProjectListContext = createContext<
  ContextType | undefined
>(undefined)

type Props = {
  children: React.ReactNode
}

const BlueprintProjectListProviderComponent = ({ children }: Props) => {
  const { currentBlueprint } = useBlueprintDetailsContainerContext()!
  const env = useEnv()
  const makeToast = useToaster()
  const [projectList, setProjectList] = useState<SapTableProject[]>([])
  const [isLoadingProjectList, setIsLoadingProjectList] = useState(false)
  const [projectFunds, setProjectFunds] = useState<ProjectFund[]>([])
  const [blueprintCampaigns, setBlueprintCampaigns] = useState<
    BlueprintCampaign[]
  >([])
  const [isLoadingCampaignIds, setIsLoadingCampaignIds] = useState(false)
  const [isLoadingCampaigns, setIsLoadingCampaigns] = useState(false)
  const [divisionOptions, setDivisionOptions] = useState<Division[]>([])
  const [departmentOptions, setDepartmentOptions] = useState<Department[]>([])
  const [isProjectListFullScreen, setIsProjectListFullScreen] = useState(false)
  const [newProjectModal, setNewProjectModal] = useState<
    { type: string; node: RowNode | null } | undefined
  >(undefined)
  const [projectsModified, setProjectsModified] = useState(false)
  const [modifiedProjects, setModifiedProjects] = useState<
    SapTableProjectRequest[]
  >([])
  const [modifiedProjectIds, setModifiedProjectIds] = useState<string[]>([])
  const [removedProjects, setRemovedProjects] = useState<
    SapTableProjectRequest[]
  >([])
  const [projectModifiedFields, setProjectModifiedFields] = useState<
    SapProjectFieldsModified[]
  >([])

  const getProjectFunds = async (setDate: string) => {
    try {
      const config = await appConfig()
      const res = await Axios.get(
        `${
          config.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
        }/funds?set_date=${moment(setDate).format('yyyy-MM-DD')}`,
      )

      let projectOptions: Object[] = []

      res.data[0].funds.map((fund: ProjectFund) =>
        projectOptions.push({ value: fund.fund_id, label: fund.fund_name }),
      )

      return projectOptions
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Get Funds',
        message: err.response.data.message,
      })
      return []
    }
  }

  const setSelectOptions = async (field: string, setDate?: string) => {
    switch (field) {
      case 'project_type':
        return PROJECT_TYPE_OPTIONS
      case 'tactic':
        return PROJECT_TACTIC_OPTIONS
      case 'fund_id':
        return getProjectFunds(setDate!)
      default:
        break
    }
  }

  const loadProjectList = useCallback(async () => {
    setIsLoadingProjectList(true)
    try {
      if (currentBlueprint.blueprint_id !== '' && projectFunds.length > 0) {
        const res = await Axios.get(
          `${
            env.apiDomainUrl + PROJECT_SERVICE_API_DOMAIN_URL
          }/projects?blueprint_id=${currentBlueprint.blueprint_id}`,
        )
        setProjectList(
          res.data.map((project: any) => new SapTableProject(project)),
        )
      }
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Get Project List',
        message: err.response.data.message,
      })
    }
    setIsLoadingProjectList(false)
  }, [currentBlueprint, projectFunds, makeToast, env])

  const loadProjectFunds = async () => {
    try {
      const res = await Axios.get(
        `${env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL}/funds/all`,
      )
      const fiscalYear = res.data.filter((info: ProjectFundInfo) =>
        isDateInRange(
          currentBlueprint.set_date,
          info.fiscal_start_date,
          info.fiscal_end_date,
        ),
      )[0].year
      let projectOptions: ProjectFund[] = []
      res.data
        .filter(
          (info: ProjectFundInfo) =>
            info.year >= fiscalYear - 1 || info.year <= fiscalYear + 1,
        )
        .map((info: ProjectFundInfo) =>
          info.funds.map((fund: ProjectFund) =>
            projectOptions.push(new ProjectFund(fund)),
          ),
        )
      setProjectFunds(projectOptions)
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Get Project Funds',
        message: err.response.data.message,
      })
    }
  }

  const loadCampaignIds = async (type: string, setDate: any) => {
    setIsLoadingCampaignIds(true)
    try {
      const encoded_type = encodeURIComponent(type)
      const res = await Axios.get(
        `${
          env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
        }/campaigns/campaign_ids?type=${encoded_type}&set_date=${moment(
          setDate,
        ).format('yyyy-MM-DD')}`,
      )
      return res.data && res.data.length > 0
        ? res.data[0].campaign_id
        : undefined
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Get Campaign ID',
        message: err.response.data.message,
      })
    }
    setIsLoadingCampaignIds(false)
  }

  const loadCampaigns = async (setDate: string, campaignName: string) => {
    setIsLoadingCampaigns(true)
    try {
      const encoded_name =
        campaignName === undefined ? '' : encodeURIComponent(campaignName)
      const nameParam =
        campaignName === undefined ? '' : `&name=${encoded_name}`
      const res = await Axios.get(
        `${
          env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
        }/campaign_types/names?set_date=${moment(setDate).format(
          'yyyy-MM-DD',
        )}${nameParam}`,
      )
      setBlueprintCampaigns(
        res.data.map((campaign: any) => new BlueprintCampaign(campaign)),
      )
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Load Campaign Types',
        message: err.response.data.message,
      })
    }
    setIsLoadingCampaigns(false)
  }

  const loadDivisionOptions = useCallback(
    async (pyramids: Pyramid[]) => {
      const pyramidIds = pyramids.map((pyramid: Pyramid) => pyramid.group_id)
      try {
        let promises: any[] = []
        pyramidIds
          .filter((pyramidId: number) => pyramidId !== -1)
          .map(async (pyramidId: number) => {
            promises.push(
              Axios.get(
                `${
                  env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
                }/merchandise_hierarchy/divisions?group_id=${pyramidId}`,
              ),
            )
          })
        const allResults = await Promise.all(promises)

        let divisions: Division[] = []
        allResults.map((res: any) => {
          return res.data.map((division: any) =>
            divisions.push(new Division(division)),
          )
        })
        setDivisionOptions(divisions)
      } catch (err: any) {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'error',
          heading: 'Failed to Get Division List',
          message: err.response.data.message,
        })
      }
    },
    [makeToast, env.apiDomainUrl],
  )

  const loadDepartmentOptions = useCallback(
    async (divisions: Division[]) => {
      const divisionIds = divisions.map(
        (division: Division) => division.division_id,
      )
      try {
        let promises: any[] = []
        divisionIds
          .filter((divisionId: number) => divisionId !== 0)
          .map(async (divisionId: number) => {
            promises.push(
              Axios.get(
                `${
                  env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
                }/merchandise_hierarchy/departments?division_id=${divisionId}`,
              ),
            )
          })
        const allResults = await Promise.all(promises)

        let departments: Department[] = []
        allResults.map((res: any) => {
          return res.data.map((department: any) =>
            departments.push(new Department(department)),
          )
        })
        setDepartmentOptions(departments)
      } catch (err: any) {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'error',
          heading: 'Failed to Get Department List',
          message: err.response.data.message,
        })
      }
    },
    [makeToast, env.apiDomainUrl],
  )

  const addToDepartmentOptions = useCallback(
    async (division: Division) => {
      try {
        const res = await Axios.get(
          `${
            env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
          }/merchandise_hierarchy/departments?division_id=${
            division.division_id
          }`,
        )

        const newDepartments = res.data.map(
          (department: any) => new Department(department),
        )
        setDepartmentOptions((previousState: Department[]) => [
          ...previousState,
          ...newDepartments,
        ])
      } catch (err: any) {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'error',
          heading: 'Failed to Get Department List',
          message: err.response.data.message,
        })
      }
    },
    [makeToast, env.apiDomainUrl],
  )

  const saveModifiedProjects = async () => {
    setIsLoadingProjectList(true)
    try {
      const res = await Axios.post(
        `${env.apiDomainUrl + PROJECT_SERVICE_API_DOMAIN_URL}/projects/batch`,
        modifiedProjects.concat(removedProjects),
        {
          ...HEADER_OBJECT,
          params: { blueprint_id: currentBlueprint.blueprint_id },
        },
      )
      setProjectList(
        res.data.map((project: any) => new SapTableProject(project)),
      )
      setModifiedProjects([])
      setModifiedProjectIds([])
      setRemovedProjects([])
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'success',
        heading: 'SAP Project Requests Updated',
        message: 'Successfully updated SAP Project requests',
      })
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Update Signs',
        message: err.response.data.message,
      })
    }
    setIsLoadingProjectList(false)
  }

  return (
    <BlueprintProjectListContext.Provider
      value={{
        projectList,
        setProjectList,
        loadProjectList,
        isLoadingProjectList,
        setIsLoadingProjectList,
        projectFunds,
        setProjectFunds,
        loadProjectFunds,
        blueprintCampaigns,
        setBlueprintCampaigns,
        loadCampaigns,
        isLoadingCampaigns,
        setIsLoadingCampaigns,
        loadCampaignIds,
        isLoadingCampaignIds,
        setIsLoadingCampaignIds,
        divisionOptions,
        setDivisionOptions,
        loadDivisionOptions,
        departmentOptions,
        setDepartmentOptions,
        loadDepartmentOptions,
        addToDepartmentOptions,
        isProjectListFullScreen,
        setIsProjectListFullScreen,
        newProjectModal,
        setNewProjectModal,
        projectsModified,
        setProjectsModified,
        modifiedProjects,
        setModifiedProjects,
        modifiedProjectIds,
        setModifiedProjectIds,
        removedProjects,
        setRemovedProjects,
        saveModifiedProjects,
        projectModifiedFields,
        setProjectModifiedFields,
        setSelectOptions,
      }}
    >
      {children}
    </BlueprintProjectListContext.Provider>
  )
}

export const useBlueprintProjectListContext = () =>
  useContext(BlueprintProjectListContext)

export const BlueprintProjectListProvider = connect(
  null,
  null,
)(BlueprintProjectListProviderComponent)
