import React, { useState } from 'react'
import {
  Grid,
  Heading,
  Divider,
  Button,
  Form,
} from '@enterprise-ui/canvas-ui-react'
import { useBlueprintMilestoneTabContext } from '../../context/blueprintMilestoneTabContext'
import { useUserContext } from 'components/App/context/userContext'
import { ScheduleTemplate } from '../../../../../../models/milestones/ScheduleTemplate'
import { useMilestoneContext } from 'components/App/context/milestoneContext'

export const SchedulingTemplateModal = () => {
  const {
    isScheduleTemplateModalOpen,
    setIsScheduleTemplateOpen,
    saveCustomScheduleTemplate,
    blueprintMilestone,
  } = useBlueprintMilestoneTabContext()!
  const { scheduleTemplateByScheduleTemplateId } = useMilestoneContext()!
  const [templateName, setTemplateName] = useState('')
  const { userName } = useUserContext()!
  const customeScheduleTemplate = new ScheduleTemplate({
    template_id: null,
    template_name: templateName,
    schedule_type: scheduleTemplateByScheduleTemplateId.schedule_type,
    master_template: false,
    milestones: blueprintMilestone.milestones,
    created_by: userName,
    created_date: new Date().toISOString(),
    updated_by: userName,
    updated_date: new Date().toISOString(),
  })

  return (
    <div className="hc-pa-normal">
      <Grid.Container>
        <Grid.Item xs={12}>
          <Heading className="hc-clr-grey02 hc-mb-normal" size={4}>
            New Scheduling Template Name
          </Heading>
          <Divider />
        </Grid.Item>
        <Grid.Item xs={12}>
          <Form.Field
            id="template_name"
            data-testid="templateName"
            label="Scheduling Template Name"
            value={templateName}
            required
            onChange={(e: any) => setTemplateName(e.target.value)}
          />
        </Grid.Item>
      </Grid.Container>
      <Grid.Container direction="row-reverse" spacing="dense">
        <Grid.Item>
          <Button
            type="primary"
            size="dense"
            fullWidth
            data-testid="saveScheduleTemplate"
            onClick={() => {
              saveCustomScheduleTemplate(customeScheduleTemplate)
              setIsScheduleTemplateOpen(!isScheduleTemplateModalOpen)
              setTemplateName('')
            }}
          >
            Save Scheduling Template
          </Button>
        </Grid.Item>
        <Grid.Item>
          <Button
            type="secondary"
            size="dense"
            fullWidth
            data-testid="toggleTemplate"
            onClick={() => {
              setIsScheduleTemplateOpen(!isScheduleTemplateModalOpen)
              setTemplateName('')
            }}
          >
            Cancel
          </Button>
        </Grid.Item>
      </Grid.Container>
    </div>
  )
}

export default SchedulingTemplateModal
