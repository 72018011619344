export default class BlueprintMilestoneCompleteRequest {
  blueprint_id: string
  milestone_id: string
  completed: boolean
  updated_by: string

  constructor(props: any = {}) {
    this.blueprint_id = props.blueprint_id
    this.milestone_id = props.milestone_id
    this.completed = props.completed
    this.updated_by = props.updated_by
  }
}
