import React from 'react'
import { Grid, Spinner } from '@enterprise-ui/canvas-ui-react'
import DashboardActions from '../DashboardActionsDropdown'
import DashboardViews from '../DashboardViewsButtonDropdown'
import { useAgGridDashboardContext } from '../../context/agGridDashboardContext'
import { useDashboardContext } from '../../context/dashboardContext'
import { TabName } from '../../constants/dashboardConstants'
import { useAppContext } from '../../../App/context/appContext'
import DashboardEditButtons from '../DashboardEditButtons'
import ProjectMilestonesGrid from './ProjectMilestonesGrid'
import ProjectSummaryGrid from './ProjectSummaryGrid'

const ProjectDashboard = () => {
  const { dashboardActiveTab } = useAppContext()!
  const { isLoading, modifiedProjects } = useDashboardContext()!
  const { gridApi } = useAgGridDashboardContext()!

  return (
    <div className="hc-pa-dense hc-pv-none">
      <Grid.Container justify="space-between">
        <DashboardActions />
        <DashboardViews />
        {modifiedProjects.length > 0 ? (
          <DashboardEditButtons gridApi={gridApi} />
        ) : null}
        {isLoading ? (
          <Grid.Container
            className="sign-projects-loading-container"
            direction="column"
            justify="center"
            align="center"
          >
            <Grid.Item>
              {dashboardActiveTab === TabName.PROJECT_SUMMARY
                ? 'Loading Project Summary...'
                : dashboardActiveTab === TabName.MY_PROJECT_MILESTONES
                ? 'Loading My Project Milestones...'
                : 'Loading...'}
            </Grid.Item>
            <Grid.Item>
              <Spinner />
            </Grid.Item>
          </Grid.Container>
        ) : dashboardActiveTab === TabName.PROJECT_SUMMARY ? (
          <ProjectSummaryGrid />
        ) : (
          <ProjectMilestonesGrid />
        )}
      </Grid.Container>
    </div>
  )
}

export default ProjectDashboard
