import React, { Fragment } from 'react'
import { Grid, Heading, Table } from '@enterprise-ui/canvas-ui-react'
import BlueprintListTableItem from './BlueprintListTableItem'
import { BlueprintDetail } from '../../../models/blueprints/BlueprintDetail.model'
import { useBlueprintListContext } from '../context/blueprintListContext'
import { useBlueprintFilterContext } from '../context/blueprintFilterContext'
import { SearchResult } from '../../../models/app/SearchResponse.model'
import SortableTableHeader from '../../App/components/SortableTableHeader'

const BlueprintListTable = () => {
  const { blueprintSearchResponse } = useBlueprintListContext()!
  const { submitSort } = useBlueprintFilterContext()!

  return (
    <Fragment>
      <Grid.Item xs={12}>
        <Table className="sem-Table" name="Blueprint List">
          <Table.Head>
            <Table.Row justify="space-between">
              <Table.Header
                className="sem_Table--IconHeader hc-mr-normal"
                key="iconHeader"
              />
              <SortableTableHeader
                searchPath="set_date"
                text="Set Date"
                xs={1}
                sortFields={blueprintSearchResponse.sort_fields}
                submitSort={submitSort}
                key="set_date"
              />

              <SortableTableHeader
                searchPath="name"
                text="Name"
                xs={3}
                sortFields={blueprintSearchResponse.sort_fields}
                submitSort={submitSort}
                key="name"
              />

              <Table.Header xs={1} key="status">
                Status
              </Table.Header>

              <SortableTableHeader
                searchPath="campaign.name"
                text="Campaign"
                xs={2}
                sortFields={blueprintSearchResponse.sort_fields}
                submitSort={submitSort}
                key="campaign"
              />

              <SortableTableHeader
                searchPath="type"
                text="Type"
                xs={true}
                sortFields={blueprintSearchResponse.sort_fields}
                submitSort={submitSort}
                key="type"
              />

              <SortableTableHeader
                searchPath="campaign.pyramids.group_name"
                text="Pyramids"
                xs={true}
                sortFields={blueprintSearchResponse.sort_fields}
                submitSort={submitSort}
                key="pyramids"
              />
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {blueprintSearchResponse.search.length > 0 ? (
              blueprintSearchResponse.search.map(
                (
                  searchResult: SearchResult<BlueprintDetail>,
                  index: number,
                ) => (
                  <BlueprintListTableItem
                    blueprint={searchResult.result}
                    key={index}
                  />
                ),
              )
            ) : (
              <Table.Row
                className="sem_TableCardItem disabled"
                justify="space-around"
                key="noResultsRow"
              >
                <Table.Data xs={4} className="hc-pv-dense" key="no_results">
                  <Heading size={6} className="hc-ta-center">
                    No Results to Display
                  </Heading>
                </Table.Data>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Grid.Item>
    </Fragment>
  )
}

export default BlueprintListTable
