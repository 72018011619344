import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { Grid, Form } from '@enterprise-ui/canvas-ui-react'

export interface Props {
  value: number
}

const NumberEditor = forwardRef(({ value }: Props, ref) => {
  const refInput = useRef<HTMLInputElement>(null)
  const [editValue, setEditValue] = useState(value)

  useEffect(() => {
    setTimeout(() => refInput?.current?.focus())
  }, [])

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return editValue
      },
      isPopup() {
        return true
      },
    }
  })

  const handleChange = (id: string, value: string) =>
    setEditValue(parseFloat(value))

  return (
    <div className="hc-pa-dense">
      <Grid.Container className="sem_StandardForm error_messaging_enabled">
        <Grid.Item className="hc-pb-normal" xs={12}>
          <Form.Field
            id="number_editor_addon"
            label="Cost"
            ref={refInput}
            type="number"
            value={editValue}
            onChange={(e: any) => handleChange(e.target.id, e.target.value)}
            size="dense"
          />
        </Grid.Item>
      </Grid.Container>
    </div>
  )
})

export default NumberEditor
