import React, {
  forwardRef,
  useState,
  useRef,
  useEffect,
  useImperativeHandle,
} from 'react'
import { Column } from 'ag-grid-community'
import { Grid, Input } from '@enterprise-ui/canvas-ui-react'

export interface Props {
  value: boolean
  column: Column
}

const ToggleCellEditor = forwardRef(({ value, column }: Props, ref) => {
  const [editValue, setEditValue] = useState(value)
  const refInput = useRef<HTMLInputElement>(null)

  useEffect(() => {
    setTimeout(() => refInput?.current?.focus())
  }, [])

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return editValue
      },
    }
  })

  return (
    <div className="hc-pa-dense">
      <Grid.Container className="sem_StandardForm error_messaging_enabled toggle_editor">
        <Grid.Item>
          <Input.Toggle
            id="toggle_cell_editor"
            ref={refInput}
            label="Flat Dims Editable"
            checked={editValue}
            onChange={(e: any) => setEditValue(e.target.checked)}
          />
          <p className="C-FormField_FieldInfo" />
        </Grid.Item>
      </Grid.Container>
    </div>
  )
})

export default ToggleCellEditor
