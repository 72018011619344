import React, { Fragment } from 'react'
import { Button, Dropdown, Grid } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, {
  CancelIcon,
  CaretDownIcon,
  DownloadIcon,
  MaximizeIcon,
  MinimizeIcon,
  PlusIcon,
  RedoIcon,
  UndoIcon,
} from '@enterprise-ui/icons'
import { useBlueprintProjectListContext } from '../../../context/blueprintProjectListContext'
import { useAgGridProjectListContext } from '../../../context/agGridProjectListContext'
import { useBlueprintDetailsContainerContext } from '../../../../../context/blueprintDetailsContainerContext'
import { useAppContext } from 'components/App/context/appContext'
import { BLUEPRINT_PROJECT_LIST_VIEW } from 'components/App/constants/appConstants'
import { exportColumns } from '../../../views/AgGridProjectListViews'
import { useUserContext } from 'components/App/context/userContext'

const ActionsDropdown = () => {
  const { currentBlueprint } = useBlueprintDetailsContainerContext()!
  const {
    isProjectListFullScreen,
    setIsProjectListFullScreen,
    setNewProjectModal,
  } = useBlueprintProjectListContext()!
  const { userPermissions } = useUserContext()!
  const {
    gridApi,
    cancelSelectedProjects,
    sizeColumnsToFit,
    autoSizeAllColumns,
    resetColumnState,
    resetAllFilters,
  } = useAgGridProjectListContext()!
  const { blueprintProjectListView } = useAppContext()!

  return (
    <Grid.Item className="hc-ph-dense hc-pb-dense">
      <Dropdown size="expanded" location="bottom-left">
        <Button type="ghost">
          Actions
          <EnterpriseIcon className="hc-ml-dense" icon={CaretDownIcon} />
        </Button>
        <Dropdown.Menu>
          <Dropdown.MenuItem
            className="sem_DropdownMenuItem--action divider"
            onClick={() => setIsProjectListFullScreen(!isProjectListFullScreen)}
          >
            <EnterpriseIcon
              size="inline"
              className="hc-mr-normal"
              icon={isProjectListFullScreen ? MinimizeIcon : MaximizeIcon}
            />
            {isProjectListFullScreen
              ? 'Close Full Screen View'
              : 'Open Full Screen View'}
          </Dropdown.MenuItem>
          {userPermissions.isProjectAdmin &&
            blueprintProjectListView === BLUEPRINT_PROJECT_LIST_VIEW.ACTIVE && (
              <Fragment>
                <Dropdown.MenuItem
                  className="sem_DropdownMenuItem--action"
                  onClick={() =>
                    setNewProjectModal({ type: 'add', node: null })
                  }
                >
                  <EnterpriseIcon
                    size="inline"
                    className="hc-mr-normal"
                    icon={PlusIcon}
                  />
                  Add New SAP Project Requests
                </Dropdown.MenuItem>
                <Dropdown.MenuItem
                  className="sem_DropdownMenuItem--action divider"
                  onClick={() =>
                    cancelSelectedProjects(gridApi?.getSelectedNodes())
                  }
                >
                  <EnterpriseIcon
                    size="inline"
                    className="hc-mr-normal"
                    icon={CancelIcon}
                  />
                  Cancel Selected Project/SAP ID Requests
                </Dropdown.MenuItem>
              </Fragment>
            )}
          <Dropdown.MenuItem
            className="sem_DropdownMenuItem--action divider"
            onClick={() =>
              gridApi?.exportDataAsCsv({
                columnKeys: exportColumns,
                fileName: `${currentBlueprint.name} SAP Project Requests.csv`,
              })
            }
          >
            <EnterpriseIcon
              size="inline"
              className="hc-mr-normal"
              icon={DownloadIcon}
            />
            Export as CSV
          </Dropdown.MenuItem>
          {/* === Ag-Grid action items === */}
          <Dropdown.MenuItem onClick={() => resetAllFilters()}>
            <EnterpriseIcon
              size="inline"
              className="hc-mr-normal"
              icon={UndoIcon}
            />
            Reset All Column Filters
          </Dropdown.MenuItem>
          <Dropdown.MenuItem onClick={sizeColumnsToFit}>
            <EnterpriseIcon
              size="inline"
              className="hc-mr-normal"
              icon={MinimizeIcon}
            />
            Size Columns to Fit
          </Dropdown.MenuItem>
          <Dropdown.MenuItem onClick={autoSizeAllColumns}>
            <EnterpriseIcon
              size="inline"
              className="hc-mr-normal"
              icon={MaximizeIcon}
            />
            Auto Size All Columns
          </Dropdown.MenuItem>
          <Dropdown.MenuItem onClick={resetColumnState}>
            <EnterpriseIcon
              size="inline"
              className="hc-mr-normal"
              icon={RedoIcon}
            />
            Reset Column Sizes
          </Dropdown.MenuItem>
        </Dropdown.Menu>
      </Dropdown>
    </Grid.Item>
  )
}

export default ActionsDropdown
