import React from 'react'
import { Popover } from '@enterprise-ui/canvas-ui-react'
import NotificationResponse from '../../../../../models/notifications/NotificationResponse.model'
import '../../styles/notificationStyles.scss'

export interface Props {
  value: string
  data: NotificationResponse
}

const DescriptionPopover = ({ value, data }: Props) => {
  const content = (
    <div className="notification-description">
      <p className="notification-paragraph">{value}</p>
    </div>
  )
  return (
    <Popover content={content} location="none" size="expanded">
      <p>{data.header_text}</p>
    </Popover>
  )
}

export default DescriptionPopover
