import React from 'react'
import { MilestoneProvider } from '../../../App/context/milestoneContext'
import ProjectMilestoneTab from './components/ProjectMilestoneTab'
import { ProjectMilestoneTabProvider } from './context/projectMilestoneTabContext'

const ProjectMilestoneTabWrapper = (props: any) => (
  <MilestoneProvider>
    <ProjectMilestoneTabProvider>
      <ProjectMilestoneTab {...props} />
    </ProjectMilestoneTabProvider>
  </MilestoneProvider>
)

export default ProjectMilestoneTabWrapper
