import { get } from 'lodash'

export default class ReinstateSignElementRequest {
  obsolete_non_retail_items: ReinstateNRIRequest[]
  updated_by: string

  constructor(props: any = {}) {
    this.obsolete_non_retail_items = get(props, 'obsolete_non_retail_items', [
      {},
    ]).map((item: any) => new ReinstateNRIRequest(item))
    this.updated_by = props.updated_by || 'System'
  }
}

export class ReinstateNRIRequest {
  non_retail_item_id: string
  end_date?: Date | undefined // Undefined value used as placeholder until user add new End Date | Date format: 'YYYY-MM-DD'
  obsolete: boolean // default: 'false'

  constructor(props: any = {}) {
    this.non_retail_item_id = props.non_retail_item_id || ''
    this.end_date = props.end_date
    this.obsolete = props.obsolete || false
  }
}
