export const getHealthChipColor = (health: string) => {
  switch (health) {
    case 'green':
      return 'green'
    case 'yellow':
      return 'yellow'
    case 'red':
      return 'red'
    case 'grey':
      return 'grey'
    default:
      return 'indigo'
  }
}

export const getStatusChipColor = (status: string) => {
  switch (status) {
    case 'Planning':
      return 'yellow'
    case 'Pending':
      return 'yellow'
    case 'Ready':
      return 'green'
    case 'Signing':
      return 'green'
    case 'Finalize':
      return 'green'
    case 'Archive':
      return 'grey'
    case 'Reject':
      return 'red'
    default:
      return 'indigo'
  }
}

export const getMilestoneStatusChipColor = (
  informationOnly: boolean,
  status?: string,
  statusDescription?: string,
) => {
  if (informationOnly) {
    return 'grey'
  } else if (status === 'Completed') {
    return 'green'
  } else if (status === 'Past Due' && statusDescription === 'Due <1 Week Ago') {
    return 'yellow'
  } else if (status === 'Past Due' && statusDescription !== 'Due <1 Week Ago') {
    return 'red'
  } else if (status === 'Upcoming') {
    return 'indigo'
  } else if (status === 'Past Risk') {
    return 'red'
  } else {
    return 'grey'
  }
}
