import React from 'react'
import { Input } from '@enterprise-ui/canvas-ui-react'

export interface Props {
  value: boolean
}

const CompleteToggle = ({ value }: Props) => (
  <Input.Toggle
    aria-label="Milestone Complete"
    id="complete_toggle"
    className="hc-ma-none hc-pv-dense"
    checked={value}
    disabled
  />
)

export default CompleteToggle
