import Axios from 'axios'
import React, { createContext, useState, useContext, useCallback } from 'react'
import { connect } from 'react-redux'
import {
  PROJECT_SERVICE_API_DOMAIN_URL,
  TOASTER_DEFAULTS,
} from 'components/App/constants/appConstants'
import { useEnv } from '@praxis/component-runtime-env'
import { BlueprintDetail } from '../../../../../models/blueprints/BlueprintDetail.model'
import { BlueprintNote } from '../../../../../models/blueprints/BlueprintNote.model'
import { useAppContext } from 'components/App/context/appContext'
import { useBlueprintDetailsContainerContext } from '../../../context/blueprintDetailsContainerContext'
import { useToaster } from '@enterprise-ui/canvas-ui-react'

type ContextType = {
  isBlueprintNotesOpen: boolean
  setIsBlueprintNotesOpen: Function
  isBlueprintNotesFormOpen: boolean
  setIsBlueprintNotesFormOpen: (isOpen: boolean) => void
  addBlueprintNote: (id: string, note: BlueprintNote) => void
  deleteBlueprintNote: (id: string, noteId: string) => void
  updateBlueprintNote: (id: string, note: BlueprintNote) => void
  getBlueprintNotes: (id: string) => void
}

export const BlueprintNotesContext = createContext<ContextType | undefined>(
  undefined,
)

type Props = {
  children: React.ReactNode
}

export const BlueprintNotesProviderComponent = ({ children }: Props) => {
  const { setFullPageLoadingMessage } = useAppContext()!
  const { setCurrentBlueprint } = useBlueprintDetailsContainerContext()!
  const env = useEnv<any>()
  const makeToast = useToaster()

  const [isBlueprintNotesOpen, setIsBlueprintNotesOpen] = useState(false)
  const [isBlueprintNotesFormOpen, setIsBlueprintNotesFormOpen] =
    useState(false)

  const getBlueprintNotes = useCallback(
    async (id: string) => {
      try {
        const res = await Axios.get(
          `${
            env.apiDomainUrl + PROJECT_SERVICE_API_DOMAIN_URL
          }/blueprints/${id}/notes`,
        )
        const sortedNotes = res.data.sort((a: any, b: any) =>
          a.timestamp < b.timestamp ? 1 : -1,
        )
        setCurrentBlueprint(
          (previousState: BlueprintDetail) =>
            new BlueprintDetail({
              ...previousState,
              notes: sortedNotes,
            }),
        )
      } catch (err: any) {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'error',
          heading: 'Failed to Get Blueprint Notes',
          message: err.response.data.message,
        })
      }
    },
    [setCurrentBlueprint, env, makeToast],
  )

  const addBlueprintNote = async (id: string, note: BlueprintNote) => {
    try {
      setFullPageLoadingMessage('Saving note...')
      await Axios.put(
        `${
          env.apiDomainUrl + PROJECT_SERVICE_API_DOMAIN_URL
        }/blueprints/${id}/notes`,
        note,
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      await getBlueprintNotes(id)
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'success',
        heading: 'Note Added',
        message: 'Successfully added blueprint note',
      })
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to add Note',
        message: err.response.data.message,
      })
    }
    setFullPageLoadingMessage('')
  }

  const updateBlueprintNote = async (id: string, note: BlueprintNote) => {
    try {
      setFullPageLoadingMessage('Saving note...')
      await Axios.put(
        `${
          env.apiDomainUrl + PROJECT_SERVICE_API_DOMAIN_URL
        }/blueprints/${id}/notes`,
        note,
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      await getBlueprintNotes(id)
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'success',
        heading: 'Note Updated',
        message: 'Successfully updated blueprint note',
      })
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to update Note',
        message: err.response.data.message,
      })
    }
    setFullPageLoadingMessage('')
  }

  const deleteBlueprintNote = async (id: string, noteId: string) => {
    try {
      setFullPageLoadingMessage('Deleting note...')
      await Axios.delete(
        `${
          env.apiDomainUrl + PROJECT_SERVICE_API_DOMAIN_URL
        }/blueprints/${id}/notes/${noteId}`,
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      await getBlueprintNotes(id)
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'success',
        heading: 'Note Deleted',
        message: 'Successfully deleted blueprint note',
      })
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to delete Note',
        message: err.response.data.message,
      })
    }
    setFullPageLoadingMessage('')
  }

  return (
    <BlueprintNotesContext.Provider
      value={{
        isBlueprintNotesOpen,
        setIsBlueprintNotesOpen,
        isBlueprintNotesFormOpen,
        setIsBlueprintNotesFormOpen,
        addBlueprintNote,
        updateBlueprintNote,
        deleteBlueprintNote,
        getBlueprintNotes,
      }}
    >
      {children}
    </BlueprintNotesContext.Provider>
  )
}

export const useBlueprintNotesContext = () => useContext(BlueprintNotesContext)

export const BlueprintNotesProvider = connect(
  null,
  null,
)(BlueprintNotesProviderComponent)
