import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { connect } from 'react-redux'
import { useEnv } from '@praxis/component-runtime-env'
import Axios from 'axios'
import {
  ColumnApi,
  FirstDataRenderedEvent,
  GetDetailRowData,
  GetDetailRowDataParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  IDetailCellRendererParams,
  IServerSideGetRowsParams,
  RowHeightParams,
  RowNode,
  SelectionChangedEvent,
} from 'ag-grid-community'
import moment from 'moment'
import { isEmpty, set } from 'lodash'
import { useUserContext } from '../../App/context/userContext'
import { useFilterContext } from '../../App/context/filterContext'
import { useSignImportSSRMServerContext } from './signImportSSRMServerContext'
import {
  signImportColumnTypes,
  signImportGridComponents,
} from '../helpers/signImportHelpers'
import {
  ADMIN_SERVICE_API_DOMAIN_URL,
  HEADER_OBJECT,
  SEARCH_RESPONSE_SIZE,
  SIGN_SERVICE_API_DOMAIN_URL,
  SIGN_STATUS,
  TOASTER_DEFAULTS,
} from '../../App/constants/appConstants'
import { SearchRequest } from '../../../models/app/SearchRequest.model'
import {
  Department,
  EditingFacets,
  ProjectSignInfo,
  SignLibrarySearchFacets,
  SelectedSignsIds,
} from '../../../models/signLibrary'
import { DETAIL_PROJECT_LIST } from '../../SignLibrary/views/AgGridSignLibraryView'
import {
  defaultImportFilterModel,
  defaultImportSortState,
  detailGridDefaultFilterModel,
} from '../helpers/signImportHelpers'
import { useToaster } from '@enterprise-ui/canvas-ui-react'

type ContextType = {
  gridApi: GridApi | undefined
  setGridApi: Function
  gridColumnApi: ColumnApi | undefined
  setGridColumnApi: Function
  gridOptions: GridOptions
  onGridReady: (event: GridReadyEvent) => void
  readyToLoadGrid: boolean
  setReadyToLoadGrid: (value: boolean) => void
  totalResults: number | undefined
  setTotalResults: (total: number | undefined) => void
  facetResults: SignLibrarySearchFacets
  setFacetResults: (results: SignLibrarySearchFacets) => void
  refreshFiltersFromPyramid: (facetValue: string[]) => void
  refreshFiltersFromDivision: (facetValue: string[]) => void
  refreshDeptEditFacets: (divisionId?: string) => void
  filterList: any[]
  setFilterList: (filters: any[]) => void
  buildFilterList: (filters: any) => void
  selectedSignIds: SelectedSignsIds
  setSelectedSignIds: (selectedSigns: SelectedSignsIds) => void
  importIsDisabled: boolean
  deselectAll: () => void
  sizeColumnsToFit: () => void
  autoSizeAllColumns: () => void
  resetColumnState: () => void
  resetAllFilters: () => void
}

export const AgGridSignImportContext = createContext<ContextType | undefined>(
  undefined,
)

type Props = {
  children: React.ReactNode
}

export const AgGridSignImportProviderComponent = ({ children }: Props) => {
  const { userPermissions, userType } = useUserContext()!
  const {
    signImportSearchRequest,
    signImportFilterModel,
    setSignImportFilterModel,
  } = useFilterContext()!
  const { signImportSSRMServer } = useSignImportSSRMServerContext()!
  const env = useEnv()
  const makeToast = useToaster()

  const [gridApi, setGridApi] = useState<GridApi>()
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>()
  const [readyToLoadGrid, setReadyToLoadGrid] = useState(false)
  const [totalResults, setTotalResults] = useState<number | undefined>(
    undefined,
  )
  const [facetResults, setFacetResults] = useState<SignLibrarySearchFacets>(
    new SignLibrarySearchFacets(),
  )
  const [filterList, setFilterList] = useState<any[]>([])
  const [editingFacets, setEditingFacets] = useState<EditingFacets>(
    new EditingFacets(),
  )
  const [selectedSignIds, setSelectedSignIds] = useState<SelectedSignsIds>(
    new SelectedSignsIds(),
  )
  const [importIsDisabled, setImportIsDisabled] = useState(true)

  const onGridReady = (event: GridReadyEvent) => {
    setGridApi(event.api)
    setGridColumnApi(event.columnApi)
    event.api.setFilterModel({
      'non_retail_item_info.sign_item_status': {
        filterType: 'set',
        values: ['Active'],
      },
      'project_sign_info_list.sign_status': {
        filterType: 'set',
        values: ['New', 'Carry Forward', 'Reissue'],
      },
    })
    event.api.setServerSideDatasource(
      signLibraryDatasource(signImportSSRMServer()),
    )
  }

  const buildFilterList = (params: IServerSideGetRowsParams) => {
    const { api, request } = params
    const tempFilterList = []

    if (isEmpty(request.filterModel)) {
      setFilterList([])
    } else {
      for (const [field, value] of Object.entries(request.filterModel)) {
        const { filterType, filter, values, dateFrom, dateTo } = value as any
        if (field === 'project_sign_info_list.sign_status') {
          values.length > 0 &&
            tempFilterList.push({
              field: api.getColumnDef(field)?.headerName,
              value: values.join(', '),
            })
        } else {
          tempFilterList.push({
            field: api.getColumnDef(field)?.headerName,
            value:
              filterType === 'set'
                ? values.join(', ')
                : filterType === 'date'
                ? `${moment(dateFrom).format('MM/DD/YYYY')} - ${moment(
                    dateTo,
                  ).format('MM/DD/YYYY')}`
                : `"${filter}"`,
          })
        }
      }
      setFilterList(tempFilterList)
    }

    setSignImportFilterModel(request.filterModel)
  }

  const signLibraryDatasource = (server: any) => {
    return {
      getRows: async (params: IServerSideGetRowsParams) => {
        buildFilterList(params)
        const res = await server.getData(params)
        const lastRowIndex = res.data.search.length - 1
        setFacetResults(new SignLibrarySearchFacets(res.data.facets))
        setTotalResults(res.data.total_results)
        if (res?.success) {
          params.success({
            rowData: res.rows,
            rowCount: res.data.total_results,
            storeInfo: {
              facets: res.data.facets,
              finalSignData:
                lastRowIndex > -1
                  ? res.data.search[lastRowIndex].result
                  : undefined,
              userInfo: { userPermissions, userType },
            },
          })
        }
      },
    }
  }

  const getDetailRowData: GetDetailRowData = useCallback(
    async (params: GetDetailRowDataParams) => {
      const res = await Axios.get(
        `${env.apiDomainUrl + SIGN_SERVICE_API_DOMAIN_URL}/sign_library/${
          params.data.id
        }`,
      )
      const rowData = res.data.project_sign_info_list.filter(
        (project: any) => project.sap_project_id,
      )
      const filteredProjectSignList = rowData.filter(
        (project: ProjectSignInfo) =>
          project.sign_status !== SIGN_STATUS.REMOVE,
      )
      const mostRecentSign = new ProjectSignInfo(
        filteredProjectSignList.sort(
          (a: ProjectSignInfo, b: ProjectSignInfo) => b.set_date > a.set_date,
        )[0],
      )
      params.successCallback(
        rowData.map((project: ProjectSignInfo) =>
          project.project_id === mostRecentSign.project_id
            ? new ProjectSignInfo({
                ...project,
                most_recent_sign: true,
                non_retail_item_id: res.data.non_retail_item_info.id,
              })
            : new ProjectSignInfo({
                ...project,
                non_retail_item_id: res.data.non_retail_item_info.id,
              }),
        ),
      )
    },
    [env.apiDomainUrl],
  )

  const detailCellRendererParams = useMemo<any>(() => {
    return {
      getDetailRowData: getDetailRowData,
      detailGridOptions: {
        animateRows: true,
        rowSelection: 'single',
        suppressRowClickSelection: true,
        defaultColDef: {
          resizable: true,
          sortable: true,
          width: 148,
          menuTabs: ['filterMenuTab'],
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['reset', 'apply'],
            closeOnApply: true,
          },
        },
        columnTypes: signImportColumnTypes,
        columnDefs: DETAIL_PROJECT_LIST,
        frameworkComponents: signImportGridComponents,
        onFirstDataRendered: (event: FirstDataRenderedEvent) => {
          event.api.setFilterModel(detailGridDefaultFilterModel)
          event.columnApi.applyColumnState(defaultImportSortState)
        },
      },
    } as IDetailCellRendererParams
  }, [getDetailRowData])

  const onMasterSelectionChange = (event: SelectionChangedEvent) => {
    const editedSignIds = new SelectedSignsIds()
    event.api
      .getSelectedNodes()
      .map((node: RowNode, index: number) =>
        node.data.selected_project_sign_info.sign_id
          ? set(
              editedSignIds,
              `sign_ids[${index}]`,
              node.data.selected_project_sign_info.sign_id,
            )
          : node.data.non_retail_item_info.id &&
            set(
              editedSignIds,
              `non_retail_item_ids[${index}]`,
              node.data.non_retail_item_info.id,
            ),
      )
    setSelectedSignIds({
      sign_ids: editedSignIds.sign_ids.filter((id: any) => id !== null),
      non_retail_item_ids: editedSignIds.non_retail_item_ids.filter(
        (id: any) => id !== null,
      ),
    })
    setImportIsDisabled(event.api.getSelectedNodes().length === 0)
  }

  const gridOptions = {
    animateRows: true,
    masterDetail: true,
    isRowMaster: (dataItem: any) => dataItem.project_sign_info_list.length > 0,
    getRowHeight: (params: RowHeightParams) => {
      return params.node.detail
        ? params.data.project_sign_info_list.length > 9
          ? 472
          : params.data.project_sign_info_list.length * 48 + 88
        : undefined
    },
    rowSelection: 'multiple',
    onSelectionChanged: onMasterSelectionChange,
    defaultColDef: {
      resizable: true,
      sortable: true,
      width: 148,
      menuTabs: ['filterMenuTab'],
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true,
      },
    },
    columnTypes: signImportColumnTypes,
    frameworkComponents: signImportGridComponents,
    detailCellRendererParams: detailCellRendererParams,
  }

  const refreshDeptEditFacets = async (divisionId?: string) => {
    try {
      const res: any = await Axios.get(
        `${
          env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
        }/merchandise_hierarchy/departments`,
        {
          params: { division_id: divisionId },
        },
      )
      setEditingFacets(
        new EditingFacets({
          ...editingFacets,
          departments: res.data.map((dept: any) => new Department(dept)),
        }),
      )
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Get Department Facets',
        message: err.response.data.message,
      })
    }
  }

  const refreshFiltersFromPyramid = async (facetValue: string[]) => {
    try {
      setSignImportFilterModel({
        ...signImportFilterModel,
        [signImportSearchRequest.facets.pyramid]: {
          filterType: 'set',
          values: facetValue,
        },
      })

      const res = await Axios.post(
        `${
          env.apiDomainUrl + SIGN_SERVICE_API_DOMAIN_URL
        }/sign_library/search?per_page=${SEARCH_RESPONSE_SIZE}`,
        new SearchRequest<SignLibrarySearchFacets>({
          facets: signImportSearchRequest.facets,
          include_filters: {
            [signImportSearchRequest.facets.pyramid]: facetValue,
          },
        }),
        HEADER_OBJECT,
      )
      setFacetResults(
        new SignLibrarySearchFacets({
          ...facetResults,
          division: res.data.facets.division,
          department: res.data.facets.department,
        }),
      )
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Refresh from Pyramid',
        message: err.response.data.message,
      })
    }
  }

  const refreshFiltersFromDivision = async (facetValue: string[]) => {
    try {
      setSignImportFilterModel({
        ...signImportFilterModel,
        [signImportSearchRequest.facets.division]: {
          filterType: 'set',
          values: facetValue,
        },
      })

      const res = await Axios.post(
        `${
          env.apiDomainUrl + SIGN_SERVICE_API_DOMAIN_URL
        }/sign_library/search?per_page=${SEARCH_RESPONSE_SIZE}`,
        new SearchRequest<SignLibrarySearchFacets>({
          facets: signImportSearchRequest.facets,
          include_filters: {
            [signImportSearchRequest.facets.division]: facetValue,
          },
        }),
        HEADER_OBJECT,
      )
      setFacetResults(
        new SignLibrarySearchFacets({
          ...facetResults,
          department: res.data.facets.department,
        }),
      )
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Refresh from Division',
        message: err.response.data.message,
      })
    }
  }

  const deselectAll = () => gridApi && gridApi.deselectAll()

  const sizeColumnsToFit = () => {
    gridApi && gridApi.sizeColumnsToFit()
  }

  const autoSizeAllColumns = () => {
    gridColumnApi && gridColumnApi.autoSizeAllColumns()
  }

  const resetColumnState = () => {
    gridColumnApi && gridColumnApi.resetColumnState()
  }

  const resetAllFilters = () => {
    gridApi && gridApi.setFilterModel(defaultImportFilterModel)
    gridColumnApi &&
      gridColumnApi.applyColumnState({ defaultState: { sort: null } })
  }

  return (
    <AgGridSignImportContext.Provider
      value={{
        gridApi,
        setGridApi,
        gridColumnApi,
        setGridColumnApi,
        gridOptions,
        onGridReady,
        readyToLoadGrid,
        setReadyToLoadGrid,
        totalResults,
        setTotalResults,
        facetResults,
        setFacetResults,
        refreshFiltersFromPyramid,
        refreshFiltersFromDivision,
        refreshDeptEditFacets,
        filterList,
        setFilterList,
        buildFilterList,
        selectedSignIds,
        setSelectedSignIds,
        importIsDisabled,
        deselectAll,
        resetAllFilters,
        sizeColumnsToFit,
        autoSizeAllColumns,
        resetColumnState,
      }}
    >
      {children}
    </AgGridSignImportContext.Provider>
  )
}

export const AgGridSignImportProvider = connect(
  null,
  null,
)(AgGridSignImportProviderComponent)

export const useAgGridSignImportContext = () =>
  useContext(AgGridSignImportContext)
