import React from 'react'
import { Grid, List, Popover } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { CaretDownIcon } from '@enterprise-ui/icons'
import { Division } from '../../../../../../../models/merchandise/hierarchy/Division.model'

export interface Props {
  value: Division[]
}

const ProjectDivisionsCell = ({ value = [new Division()] }: Props) => {
  const popoverContent = (
    <Grid.Container>
      <Grid.Item className="hc-pv-dense" xs={12}>
        <List>
          {value.map((division: Division, i: number) => (
            <List.Item
              className="sem_ProductHighlights--ListItem"
              key={i}
              divider={i !== value.length - 1}
            >
              {division.division_display_name}
            </List.Item>
          ))}
        </List>
      </Grid.Item>
    </Grid.Container>
  )

  return value.length > 1 ? (
    <Popover
      content={popoverContent}
      size="normal"
      location="bottom"
      onClick={(event: any) => event.stopPropagation()}
    >
      <div className="ag-cell-value">
        Show Divisions List
        <EnterpriseIcon
          className="hc-ml-dense"
          size="sm"
          icon={CaretDownIcon}
        />
      </div>
    </Popover>
  ) : (
    <div className="ag-cell-value">
      {value.length > 0 ? value[0].division_display_name : ''}
    </div>
  )
}

export default ProjectDivisionsCell
