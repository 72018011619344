export class DashboardRequest {
  user_names: string[]
  company_name: string
  include_complete: boolean
  include_all: boolean

  constructor(props: any = {}) {
    this.user_names = props.user_names || undefined
    this.company_name = props.company_name || undefined
    this.include_complete = props.include_complete || false
    this.include_all = props.include_all || false
  }
}
