import React, { Fragment } from 'react'
import {
  Button,
  Form,
  Grid,
  GridContainer,
  Modal,
} from '@enterprise-ui/canvas-ui-react'
import { useAuth } from '@praxis/component-auth'
import { KitInfoResponse } from '../../../../../models/kits/KitInfoResponse.model'
import { KitInfoRequest } from '../../../../../models/kits/KitInfoRequest.model'
import { get } from 'lodash'
import { useProjectDistributionContext } from '../context/projectDistributionContext'
import { useKitInfoForm } from '../hooks/useKitInfoForm'
import { SHIP_METHODS } from '../constants/KitInfoConstants'
import { useUserContext } from 'components/App/context/userContext'

export interface Props {
  kittingInfo: KitInfoResponse
  isKitInfoFormOpen: boolean
  setKitInfoFormOpen: Function
}

const ProjectKitInfoForm = ({
  kittingInfo,
  isKitInfoFormOpen,
  setKitInfoFormOpen,
}: Props) => {
  const { userPermissions } = useUserContext()!
  const { session } = useAuth()
  const username = get(session, 'userInfo.fullName', '')
  const { updateKitInfo } = useProjectDistributionContext()!
  const kittingInfoRequest = new KitInfoRequest({
    ...kittingInfo,
    updated_by: username,
  })

  const { handleSubmit, setFieldValue, values } = useKitInfoForm(
    kittingInfoRequest,
    updateKitInfo,
  )

  const handleChange = (id: string, value: string) => {
    setFieldValue(id, value)
  }
  return (
    <Fragment>
      <Modal
        onRefuse={() => setKitInfoFormOpen(false)}
        isVisible={isKitInfoFormOpen}
        headingText="Kitting Info"
      >
        <div className="hc-pa-normal">
          <Form id="sem_projectKitInfoForm">
            <GridContainer>
              <Grid.Item xs={4}>
                <Form.Field
                  type="date"
                  id="initial_receive_dates.instore_date"
                  label="In-Store Date"
                  value={values.initial_receive_dates?.instore_date}
                  onChange={(event: any) => {
                    setFieldValue(
                      'initial_receive_dates.instore_date',
                      event.target.value,
                    )
                  }}
                  location="bottom-left"
                />
              </Grid.Item>
              <Grid.Item xs={4}>
                <Form.Field
                  type="date"
                  id="initial_receive_dates.nrsc_receive_date"
                  label="NRSC Receive Date"
                  value={values.initial_receive_dates?.nrsc_receive_date}
                  onChange={(event: any) => {
                    setFieldValue(
                      'initial_receive_dates.nrsc_receive_date',
                      event.target.value,
                    )
                  }}
                  location="bottom-left"
                />
              </Grid.Item>
              <Grid.Item xs={4}>
                <Form.Field
                  type="date"
                  id="initial_receive_dates.archway_receive_date"
                  label="Archway Receive Date"
                  value={values.initial_receive_dates?.archway_receive_date}
                  onChange={(event: any) => {
                    setFieldValue(
                      'initial_receive_dates.archway_receive_date',
                      event.target.value,
                    )
                  }}
                  location="bottom-left"
                />
              </Grid.Item>
              <Grid.Item xs={4}>
                <Form.Field
                  className="hc-mt-none"
                  id="shipping_method"
                  disabled={!userPermissions.isKitEditor}
                  type="select"
                  label="Shipping Method"
                  options={SHIP_METHODS}
                  value={values.shipping_method}
                  onUpdate={(e: any, value: any) => {
                    handleChange('shipping_method', value)
                  }}
                />
              </Grid.Item>
              <Grid.Item xs={12}>
                <Form.Field
                  className="hc-mt-none"
                  id="comments.kit_comment"
                  type="textarea"
                  label="Project Comments"
                  placeholder="Enter your comments here..."
                  value={values.comments?.kit_comment}
                  onChange={(e: any) => {
                    handleChange(e.target.id, e.target.value)
                  }}
                />
              </Grid.Item>
            </GridContainer>
            <GridContainer>
              <Grid.Item xs={6}>
                <Button
                  fullWidth
                  type="secondary"
                  onClick={() => {
                    setKitInfoFormOpen(false)
                  }}
                >
                  CANCEL
                </Button>
              </Grid.Item>
              <Grid.Item xs={6}>
                <Button
                  fullWidth
                  type="primary"
                  onClick={() => {
                    handleSubmit()
                    setKitInfoFormOpen(false)
                  }}
                >
                  SAVE
                </Button>
              </Grid.Item>
            </GridContainer>
          </Form>
        </div>
      </Modal>
    </Fragment>
  )
}

export default ProjectKitInfoForm
