export default class SignImportRequest {
  sign_ids: string[]
  non_retail_item_ids: string[]
  project_id: string
  shell: boolean
  reason_code?: string
  updated_by: string

  constructor(props: any = {}) {
    this.sign_ids = props.sign_ids
    this.non_retail_item_ids = props.non_retail_item_ids
    this.project_id = props.project_id || ''
    this.shell = props.shell || false
    this.reason_code = props.reason_code
    this.updated_by = props.updated_by || 'System'
  }
}

export class SelectedSignsIds {
  sign_ids: string[]
  non_retail_item_ids: string[]

  constructor(props: any = {}) {
    this.sign_ids = props.sign_id || []
    this.non_retail_item_ids = props.non_retail_item_id || []
  }
}
