import { get } from 'lodash'
import { Department } from '../merchandise/hierarchy/Department.model'

export default class SharedMscFacets {
  locations: StoreLocation[]
  departments: Department[]

  constructor(props: any = {}) {
    this.locations = get(props, 'locations', [{}]).map(
      (location: any) => new StoreLocation(location),
    )
    this.departments = get(props, 'departments', [{}]).map(
      (dept: any) => new Department(dept),
    )
  }
}

export class StoreLocation {
  location_id: number
  location_name: string

  constructor(props: any = {}) {
    this.location_id = props.location_id || 0
    this.location_name = props.location_name || ''
  }
}
