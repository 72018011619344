import React, { useState } from 'react'
import {
  Card,
  Grid,
  Avatar,
  Tooltip,
  ExpandableSection,
  Divider,
  Chip,
  Form,
  Button,
  Input,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { CancelIcon, PlusIcon } from '@enterprise-ui/icons'
import axios from 'axios'
import { useUserContext } from '../../../App/context/userContext'
import { useEnv } from '@praxis/component-runtime-env'
import { parseInitials } from '../../../App/helpers/nameInteractions'
import { useAppContext } from '../../../App/context/appContext'
import { DialogProps } from '../../../../models/app/DialogProps.model'

export interface Props {
  users: any
  handleDelete: Function
  handleAdd: Function
}

const DashboardRosterCard = ({ users, handleDelete, handleAdd }: Props) => {
  const { userType } = useUserContext()!
  const { setDialogProps } = useAppContext()!
  const env = useEnv()
  const [expanded, setExpanded] = useState(false)
  const [addNameOpen, setAddNameOpen] = useState(false)
  const [emailInput, setEmailInput] = useState('')
  const [inputError, setInputError] = useState(false)

  const getLDAPInfoForDashboard = async (email: string) => {
    setInputError(false)
    try {
      var instance = axios.create()
      delete instance.defaults.headers.common['id_token']

      const res: any = await instance.get(`${env.ldapApiUrl}/lan_ids`, {
        params: {
          email: email,
          key: env.apiKey,
        },
      })
      handleAdd(res.data.mail)
    } catch {
      setInputError(true)
    }
  }

  const onOpen = () => {
    expanded ? setExpanded(false) : setExpanded(true)
  }

  const openAddName = () => {
    setAddNameOpen(true)
  }

  const closeAddName = () => {
    setAddNameOpen(false)
  }

  const handleChange = (e: any) => {
    setEmailInput(e.target.value)
  }

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      getLDAPInfoForDashboard(emailInput)
      setEmailInput('')
    }
  }

  const deleteName = (email: string) => {
    setDialogProps(
      new DialogProps({
        headingText: 'Are you sure you want to remove this user?',
        approveButtonText: 'Yes',
        onApprove: () => handleDelete(email),
        refuseButtonText: 'Cancel',
        onRefuse: () => setDialogProps(new DialogProps()),
      }),
    )
  }

  return (
    <Card elevation={0}>
      <ExpandableSection padding="dense" onExpand={onOpen} expanded={expanded}>
        <Grid.Container spacing="expanded" justify="space-between">
          <Grid.Item className="sem-RosterTitle hc-pr-normal">
            {users[0].email}
          </Grid.Item>
          <Grid.Item className="hc-pl-none">
            {users.map((user: any) => {
              return user.email.length > 0 && !expanded ? (
                <Tooltip
                  location="bottom-right"
                  content={user.email}
                  key={user.email}
                >
                  <Avatar
                    aria-label="email"
                    className="hc-mr-dense"
                    size="dense"
                  >
                    {parseInitials(user.email)}
                  </Avatar>
                </Tooltip>
              ) : null
            })}
          </Grid.Item>
        </Grid.Container>
        <ExpandableSection.Content>
          <Divider />
          <div className="hc-pa-dense">
            <Grid.Container>
              <Grid.Item>
                <Grid.Container direction="column" spacing="dense">
                  {users.map((user: any, index: number) => {
                    return user.email.length > 0 && index !== 0 ? (
                      <Grid.Item key={index}>
                        <Chip
                          className="sem-RosterChip"
                          onRequestDelete={
                            user.isSessionUser
                              ? undefined
                              : () => {
                                  deleteName(user.email)
                                }
                          }
                          size="dense"
                          key={user.email}
                        >
                          {user.email}
                        </Chip>
                      </Grid.Item>
                    ) : null
                  })}
                  {addNameOpen ? (
                    <Grid.Item className="sem-RosterForm">
                      <Grid.Container spacing="dense">
                        <Grid.Item>
                          <Form.Field
                            id="email_input"
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            value={emailInput}
                          />
                          <Input.Info
                            className="sem-AddRosterName"
                            error={inputError}
                          >
                            Please provide a valid Target email address
                          </Input.Info>
                        </Grid.Item>
                        <Grid.Item>
                          <Button type="ghost" iconOnly onClick={closeAddName}>
                            <EnterpriseIcon icon={CancelIcon} />
                          </Button>
                        </Grid.Item>
                      </Grid.Container>
                    </Grid.Item>
                  ) : null}
                </Grid.Container>
              </Grid.Item>
              <Grid.Item xs={true}>
                {!addNameOpen && userType !== 'EXTERNAL' ? (
                  <Tooltip
                    location="bottom-left"
                    content="Add Users to Dashboard View"
                  >
                    <Button
                      type="ghost"
                      size="dense"
                      iconOnly
                      onClick={openAddName}
                    >
                      <EnterpriseIcon icon={PlusIcon} />
                    </Button>
                  </Tooltip>
                ) : null}
              </Grid.Item>
            </Grid.Container>
          </div>
        </ExpandableSection.Content>
      </ExpandableSection>
    </Card>
  )
}

export default DashboardRosterCard
