import React, { Fragment } from 'react'
import { Route, Routes } from 'react-router-dom'
import { ProtectedElement } from '@praxis/component-auth'
import AppBar from './AppBar'
import DashboardV2 from '../../DashboardV2'
import BlueprintDetails from '../../BlueprintDetails'
import ProjectList from '../../ProjectList'
import ProjectDetails from '../../ProjectDetails'
import { PERMISSION_SETS, UserType } from '../constants/appConstants'
import BlueprintList from '../../BlueprintList'
import SignDetails from '../../SignDetails'
import { useUserContext } from '../context/userContext'
import AdminCampaigns from '../../Admin/AdminCampaigns'
import AdminMilestones from '../../Admin/AdminMilestone'
import AdminRoles from '../../Admin/AdminRoles/AdminRoles'
import AdminUpload from '../../Admin/AdminUpload'
import Notifications from '../../Notifications/components/Notifications'
import SignLibrary from '../../SignLibrary'
import StandardSignAdmin from '../../Admin/StandardSign'
import { useEnv } from '@praxis/component-runtime-env'
import Dashboard from 'components/Dashboard'

export const MainLayout = () => {
  const { userType } = useUserContext()!
  const env = useEnv()
  return (
    <Fragment>
      <AppBar />
      <Routes>
        {env.dashboardV2Feature && userType === UserType.INTERNAL ? (
          <Route
            path="/"
            element={
              <ProtectedElement>
                <DashboardV2 />
              </ProtectedElement>
            }
          />
        ) : (
          <Route
            path="/"
            element={
              <ProtectedElement>
                <Dashboard />
              </ProtectedElement>
            }
          />
        )}
        {userType === UserType.INTERNAL ? (
          <Route
            path="/blueprints"
            element={
              <ProtectedElement>
                <BlueprintList />
              </ProtectedElement>
            }
          />
        ) : null}
        <Route
          path="/blueprints/:id"
          element={
            <ProtectedElement>
              <BlueprintDetails />
            </ProtectedElement>
          }
        />
        <Route
          path="/blueprints/:id/:type"
          element={
            <ProtectedElement>
              <BlueprintDetails />
            </ProtectedElement>
          }
        />
        {userType === UserType.INTERNAL ? (
          <Route
            path="/projects"
            element={
              <ProtectedElement>
                <ProjectList />
              </ProtectedElement>
            }
          />
        ) : null}
        <Route
          path="/projects/:id"
          element={
            <ProtectedElement>
              <ProjectDetails />
            </ProtectedElement>
          }
        />
        {userType === UserType.INTERNAL ? (
          <Route
            path="/signs"
            element={
              <ProtectedElement>
                <SignLibrary />
              </ProtectedElement>
            }
          />
        ) : null}
        <Route
          path="/signs/:sign_id"
          element={
            <ProtectedElement>
              <SignDetails />
            </ProtectedElement>
          }
        />
        <Route
          path="/projects/:project_id/signs/:sign_id"
          element={
            <ProtectedElement>
              <SignDetails />
            </ProtectedElement>
          }
        />
        <Route
          path="/projects/:project_id/signs/:sign_id/:type"
          element={
            <ProtectedElement>
              <SignDetails />
            </ProtectedElement>
          }
        />
        <Route
          path="/notifications"
          element={
            <ProtectedElement>
              <Notifications />
            </ProtectedElement>
          }
        />
        {userType === UserType.INTERNAL ? (
          <Route
            path="/admin-milestones"
            element={
              <ProtectedElement allowed={PERMISSION_SETS.superAdmins}>
                <AdminMilestones />
              </ProtectedElement>
            }
          />
        ) : null}
        {userType === UserType.INTERNAL ? (
          <Route
            path="/admin-campaign-types"
            element={
              <ProtectedElement allowed={PERMISSION_SETS.superAdmins}>
                <AdminCampaigns />
              </ProtectedElement>
            }
          />
        ) : null}
        {userType === UserType.INTERNAL ? (
          <Route
            path="/admin-campaign-upload"
            element={
              <ProtectedElement allowed={PERMISSION_SETS.superAdmins}>
                <AdminUpload />
              </ProtectedElement>
            }
          />
        ) : null}
        {userType === UserType.INTERNAL ? (
          <Route
            path="/admin-roles"
            element={
              <ProtectedElement allowed={PERMISSION_SETS.superAdmins}>
                <AdminRoles />
              </ProtectedElement>
            }
          />
        ) : null}
        {userType === UserType.INTERNAL ? (
          <Route
            path="/standard-sign-templates"
            element={
              <ProtectedElement allowed={PERMISSION_SETS.superAdmins}>
                <StandardSignAdmin />
              </ProtectedElement>
            }
          />
        ) : null}
      </Routes>
    </Fragment>
  )
}

export default MainLayout
