import React, { Fragment } from 'react'
import {
  Grid,
  Button,
  Heading,
  Table,
  Spinner,
  Modal,
} from '@enterprise-ui/canvas-ui-react'
import { LocationQuantity } from '../../../../models/signs/DistributionResponse.model'

export interface Props {
  locations: any
  isVisible: boolean
  toggleVisible: Function
  isLoadingLocations: Boolean
}

const StoreCountModal = ({
  locations,
  isVisible,
  toggleVisible,
  isLoadingLocations,
}: Props) => {
  return (
    <Modal
      isVisible={isVisible}
      headingText="Store Location List"
      onRefuse={() => toggleVisible(!isVisible)}
    >
      <div className="hc-pa-normal">
        {isLoadingLocations ? (
          <Grid.Container justify="center">
            <Grid.Item>
              <Spinner />
            </Grid.Item>
          </Grid.Container>
        ) : (
          <Fragment>
            <Grid.Container className="hc-mt-normal hc-mb-none">
              <Grid.Item xs={12}>
                <Table className="sem-Table" name="POG List Table Head">
                  <Table.Head>
                    <Table.Row justify="space-between">
                      <Table.Header className="hc-ml-dense hc-ta-center" xs={6}>
                        Store ID
                      </Table.Header>
                      <Table.Header className="hc-ml-dense hc-ta-center" xs={6}>
                        Quantity
                      </Table.Header>
                    </Table.Row>
                  </Table.Head>
                </Table>
              </Grid.Item>
            </Grid.Container>
            <Grid.Container className="sem_ListModal-Table">
              <Grid.Item xs={12}>
                <Table className="sem-Table" name="POG List Table Body">
                  <Table.Body>
                    {locations.map(
                      (location: LocationQuantity, index: number) => (
                        <Table.Row
                          className="sem_TableCardItem"
                          noWrap
                          justify="space-between"
                          align="center"
                          key={location.location_id + index}
                        >
                          <Table.Data
                            className="hc-ml-dense hc-ta-center"
                            xs={6}
                          >
                            {location.location_id}
                          </Table.Data>
                          <Table.Data
                            className="hc-ml-dense hc-ta-center"
                            xs={6}
                          >
                            {location.quantity}
                          </Table.Data>
                        </Table.Row>
                      ),
                    )}
                  </Table.Body>
                </Table>
              </Grid.Item>
            </Grid.Container>
          </Fragment>
        )}
        <Grid.Container
          className="hc-mt-normal"
          align="center"
          direction="row-reverse"
        >
          <Grid.Item>
            <Button type="secondary" onClick={() => toggleVisible(false)}>
              Close
            </Button>
          </Grid.Item>
          {!isLoadingLocations && (
            <Grid.Item xs={true}>
              <Heading className="hc-clr-grey02" size={6}>
                {`Total Store Count: ${locations.length}`}
              </Heading>
            </Grid.Item>
          )}
        </Grid.Container>
      </div>
    </Modal>
  )
}

export default StoreCountModal
