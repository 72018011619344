import React, { Fragment } from 'react'
import { Divider, Grid, Heading, List } from '@enterprise-ui/canvas-ui-react'
import { ProductInfoResponse } from '../../../../../models/signs/ProductInfoResponse.model'

export interface Props {
  productInfo: ProductInfoResponse
}

const ProductHighlights = ({ productInfo }: Props) => {
  const highlights = productInfo.product_description.soft_bullet_description
    ? productInfo.product_description.soft_bullet_description.split('<br>')
    : []

  return (
    <Fragment>
      <Heading className="hc-clr-grey02" size={6}>
        Product Highlights:
      </Heading>
      <Divider className="hc-pa-dense" />
      <div className="hc-pa-dense">
        <Grid.Container>
          <Grid.Item className="hc-pv-none" xs={12}>
            <List size="dense">
              {highlights.length > 0 ? (
                highlights.map((highlight: string, index: number) => (
                  <List.Item
                    className="sem_ProductHighlights--ListItem"
                    key={index}
                    divider
                  >
                    {highlight.replace('&bull;', '')}
                  </List.Item>
                ))
              ) : (
                <List.Item className="sem_ProductHighlights--ListItem">
                  No Highlights to show at this time
                </List.Item>
              )}
            </List>
          </Grid.Item>
        </Grid.Container>
      </div>
    </Fragment>
  )
}

export default ProductHighlights
