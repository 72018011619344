import React from 'react'
import { DescriptionListProps } from './types'

export default function DescriptionList<T extends Record<string, any>>({
  config,
  data,
}: DescriptionListProps<T>) {
  return (
    <dl>
      {config.map((item, index) => {
        return (
          <div key={index} className="sem-description-list">
            <dt>{item.label}</dt>
            <dd>{data[item.key]}</dd>
          </div>
        )
      })}
    </dl>
  )
}
