import React from 'react'
import { ProjectSummary } from '../../../../models/dashboardV2/ProjectSummary.model'
import { getHealthChipColor } from '../../../App/helpers/userColor'
import RubixChip from 'components/common/RubixChip'

export interface Props {
  value: string
  data: ProjectSummary
}

const DueDateChip = ({ data, value }: Props) => {
  return value ? (
    <RubixChip color={getHealthChipColor(data.milestone_health)}>
      {value}
    </RubixChip>
  ) : null
}

export default DueDateChip
