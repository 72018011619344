import { RosterUserType } from '../../components/Roster/constants/rosterConstants'
import { RosterUser } from '../roster/RosterUser.model'

export class BusinessPartner implements RosterUser {
  id: string
  name: string
  roles: string[]
  type: RosterUserType

  constructor(props: any = {}) {
    this.id = props.id || ''
    this.name = props.name || ''
    this.roles = props.roles || []
    this.type = RosterUserType.BUSINESS_PARTNER
  }
}
