export class PogSelectionRequest {
  pog_ids: string[]
  excluded: boolean
  updated_by: string

  constructor(props: any = {}) {
    this.pog_ids = props.pog_ids || []
    this.excluded = props.excluded || false
    this.updated_by = props.updated_by || ''
  }
}
