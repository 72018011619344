import React from 'react'
import { Button, Dropdown, Grid } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, {
  CaretDownIcon,
  DownloadIcon,
  MaximizeIcon,
  MinimizeIcon,
  RedoIcon,
  UndoIcon,
} from '@enterprise-ui/icons'
import { useAppContext } from '../../App/context/appContext'
import { useAgGridDashboardContext } from '../context/agGridDashboardContext'

const DashboardActions = () => {
  const { dashboardActiveTab } = useAppContext()!
  const { resetAllFilters, exportTab, gridApi, gridColumnApi } =
    useAgGridDashboardContext()!

  return (
    <Grid.Item className="hc-ph-dense hc-pb-dense">
      <Dropdown size="normal" location="bottom-left">
        <Button type="ghost">
          Actions
          <EnterpriseIcon className="hc-ml-dense" icon={CaretDownIcon} />
        </Button>
        <Dropdown.Menu>
          <Dropdown.MenuItem
            className="sem_DropdownMenuItem--action divider"
            onClick={() => exportTab(dashboardActiveTab)}
          >
            <EnterpriseIcon
              size="inline"
              className="hc-mr-normal"
              icon={DownloadIcon}
            />
            Export as CSV
          </Dropdown.MenuItem>
          <Dropdown.MenuItem onClick={() => resetAllFilters()}>
            <EnterpriseIcon
              size="inline"
              className="hc-mr-normal"
              icon={RedoIcon}
            />
            Reset All Filters and Column Sorting
          </Dropdown.MenuItem>
          <Dropdown.MenuItem onClick={() => gridApi?.sizeColumnsToFit()}>
            <EnterpriseIcon
              size="inline"
              className="hc-mr-normal"
              icon={MinimizeIcon}
            />
            Size Columns to Fit
          </Dropdown.MenuItem>
          <Dropdown.MenuItem
            onClick={() =>
              gridColumnApi?.autoSizeColumns(
                gridColumnApi
                  .getAllColumns()!
                  .map((column: any) => column.colId),
              )
            }
          >
            <EnterpriseIcon
              size="inline"
              className="hc-mr-normal"
              icon={MaximizeIcon}
            />
            Auto Size All Columns
          </Dropdown.MenuItem>
          <Dropdown.MenuItem onClick={() => gridColumnApi?.resetColumnState()}>
            <EnterpriseIcon
              size="inline"
              className="hc-mr-normal"
              icon={UndoIcon}
            />
            Reset Column View
          </Dropdown.MenuItem>
        </Dropdown.Menu>
      </Dropdown>
    </Grid.Item>
  )
}

export default DashboardActions
