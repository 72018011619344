import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import {
  CellClassParams,
  Column,
  ColumnApi,
  EditableCallbackParams,
  FilterChangedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  IServerSideGetRowsParams,
  SortChangedEvent,
  ValueGetterParams,
} from 'ag-grid-community'
import BlueprintNameLink from '../components/AgGridView/BlueprintNameLink'
import HealthChip from '../components/AgGridView/HealthChip'
import MilestoneOwnerAvatar from '../components/AgGridView/MilestoneOwnerAvatar'
import DueDateChip from '../components/AgGridView/DueDateChip'
import BudgetText from '../components/AgGridView/BudgetText'
import TableToggleComponent from '../../ProjectDetails/components/ProjectSignList/components/signListViews/components/TableToggleComponent'
import ProjectNameLink from '../components/AgGridView/ProjectNameLink'
import { useAppContext } from '../../App/context/appContext'
import { SortState } from '../../../models/agGrid/AgGridSortState.model'
import { TabName } from '../constants/dashboardConstants'
import CompleteToggle from '../components/AgGridView/CompleteToggle'
import CompleteToggleEditor from '../components/AgGridView/CompleteToggleEditor'
import {
  dateComparator,
  dateFilterParams,
  dateValueGetter,
  getLabelFromHealth,
} from '../helpers/dashboardHelper'
import ProjectMilestoneHealthChip from '../components/AgGridView/ProjectMilestoneHealthChip'
import ProjectMilestoneDueDateChip from '../components/AgGridView/ProjectMilestoneDueDateChip'
import { useUserContext } from '../../App/context/userContext'
import DepartmentsChips from '../components/AgGridView/DepartmentsChips'
import { useBlueprintDashboardSSRMServerContext } from 'components/DashboardV2/context/blueprintDashboardSSRMServerContext'
import { useBpMilestoneDashboardSSRMServerContext } from 'components/DashboardV2/context/bpMilestoneDashboardSSRMServerContext'
import { useProjectDashboardSSRMServerContext } from 'components/DashboardV2/context/projectDashboardSSRMServerContext'
import { useProjectMilestoneDashboardSSRMServerContext } from 'components/DashboardV2/context/projectMilestoneDashboardSSRMServerContext'
import Axios from 'axios'
import { useEnv } from '@praxis/component-runtime-env'
import {
  ALERT,
  DASHBOARD_SERVICE_API_DOMAIN_URL,
  HEADER_OBJECT,
  TOASTER_DEFAULTS,
} from 'components/App/constants/appConstants'
import FileDownload from 'js-file-download'
import { useToaster } from '@enterprise-ui/canvas-ui-react'
import moment from 'moment'
import { useDashboardContext } from 'components/DashboardV2/context/dashboardContext'

type ContextType = {
  blueprintGridApi: GridApi | undefined
  bpMilestoneGridApi: GridApi | undefined
  bpMilestoneColumnApi: ColumnApi | undefined
  projectGridApi: GridApi | undefined
  projectColumnApi: ColumnApi | undefined
  projectMilestoneGridApi: GridApi | undefined
  projectMilestoneColumnApi: ColumnApi | undefined
  onBlueprintGridReady: (event: GridReadyEvent) => void
  onBpMilestoneGridReady: (event: GridReadyEvent) => void
  onProjectGridReady: (event: GridReadyEvent) => void
  onProjectMilestoneGridReady: (event: GridReadyEvent) => void
  gridOptions: GridOptions
  onFirstDataRender: () => void
  onFilterChange: (event: FilterChangedEvent) => void
  onSortChanged: (event: SortChangedEvent) => void
  resetAllFilters: () => void
  sizeColumnsToFit: () => void
  autoSizeColumns: () => void
  resetColumnView: () => void
  exportSummary: () => void
  refreshDatasource: () => void
}

export const AgGridDashboardContext = createContext<ContextType | undefined>(
  undefined,
)

type Props = { children: React.ReactNode }

export const AgGridDashboardProviderComponent = ({ children }: Props) => {
  const {
    dashboardActiveTab,

    blueprintMilestoneSavedFilter,
    setBlueprintMilestoneSavedFilter,
    blueprintMilestoneSortState,
    setBlueprintMilestoneSortState,

    projectMilestoneSavedFilter,
    setProjectMilestoneSavedFilter,
    projectMilestoneSortState,
    setProjectMilestoneSortState,

    blueprintSummarySavedFilter,
    setBlueprintSummarySavedFilter,
    blueprintSummarySortState,
    setBlueprintSummarySortState,

    projectSummarySavedFilter,
    setProjectSummarySavedFilter,
    projectSummarySortState,
    setProjectSummarySortState,
    setFullPageLoadingMessage,
  } = useAppContext()!

  const [blueprintGridApi, setBlueprintGridApi] = useState<GridApi>()
  const [blueprintColumnApi, setBlueprintColumnApi] = useState<ColumnApi>()
  const [bpMilestoneGridApi, setBpMilestoneGridApi] = useState<GridApi>()
  const [bpMilestoneColumnApi, setBpMilestoneColumnApi] = useState<ColumnApi>()
  const [projectGridApi, setProjectGridApi] = useState<GridApi>()
  const [projectColumnApi, setProjectColumnApi] = useState<ColumnApi>()
  const [projectMilestoneGridApi, setProjectMilestoneGridApi] =
    useState<GridApi>()
  const [projectMilestoneColumnApi, setProjectMilestoneColumnApi] =
    useState<ColumnApi>()
  const { userPermissions, userType } = useUserContext()!
  const { blueprintDashboardSSRMServer, currentBlueprintRequest } =
    useBlueprintDashboardSSRMServerContext()!
  const { bpMilestoneDashboardSSRMServer, currentBlueprintMilestoneRequest } =
    useBpMilestoneDashboardSSRMServerContext()!
  const { projectDashboardSSRMServer, currentProjectRequest } =
    useProjectDashboardSSRMServerContext()!
  const {
    projectMilestoneDashboardSSRMServer,
    currentProjectMilestoneRequest,
  } = useProjectMilestoneDashboardSSRMServerContext()!
  const { projectSummaryFacets } = useDashboardContext()!
  const env = useEnv()
  const makeToast = useToaster()

  const onBlueprintGridReady = (params: any) => {
    setBlueprintGridApi(params.api)
    setBlueprintColumnApi(params.columnApi)
    params.api.setServerSideDatasource(
      blueprintDashboardDatasource(blueprintDashboardSSRMServer()),
    )
  }

  const onBpMilestoneGridReady = (params: any) => {
    setBpMilestoneGridApi(params.api)
    setBpMilestoneColumnApi(params.columnApi)
    params.api.setServerSideDatasource(
      bpMilestoneDashboardDatasource(bpMilestoneDashboardSSRMServer()),
    )
  }

  const onProjectGridReady = (params: any) => {
    setProjectGridApi(params.api)
    setProjectColumnApi(params.columnApi)
    params.api.setServerSideDatasource(
      projectDashboardDatasource(projectDashboardSSRMServer()),
    )
  }

  const onProjectMilestoneGridReady = (params: any) => {
    setProjectMilestoneGridApi(params.api)
    setProjectMilestoneColumnApi(params.columnApi)
    params.api.setServerSideDatasource(
      projectMilestoneDashboardDatasource(
        projectMilestoneDashboardSSRMServer(),
      ),
    )
  }

  useEffect(() => {
    switch (dashboardActiveTab) {
      case TabName.MY_BLUEPRINT_MILESTONES:
        bpMilestoneGridApi?.setFilterModel(blueprintMilestoneSavedFilter)
        break
      case TabName.MY_PROJECT_MILESTONES:
        projectMilestoneGridApi?.setFilterModel(projectMilestoneSavedFilter)
        break
      case TabName.BLUEPRINT_SUMMARY:
        blueprintGridApi?.setFilterModel(blueprintSummarySavedFilter)
        break
      case TabName.PROJECT_SUMMARY:
        projectGridApi?.setFilterModel(projectSummarySavedFilter)
        break
    }
  }, [
    blueprintGridApi,
    blueprintMilestoneSavedFilter,
    blueprintSummarySavedFilter,
    bpMilestoneGridApi,
    dashboardActiveTab,
    projectGridApi,
    projectMilestoneGridApi,
    projectMilestoneSavedFilter,
    projectSummarySavedFilter,
  ])

  useEffect(() => {
    switch (dashboardActiveTab) {
      case TabName.MY_BLUEPRINT_MILESTONES:
        bpMilestoneColumnApi?.applyColumnState({
          state: blueprintMilestoneSortState,
          defaultState: { sort: null },
        })
        break
      case TabName.MY_PROJECT_MILESTONES:
        projectMilestoneColumnApi?.applyColumnState({
          state: projectMilestoneSortState
            ? projectMilestoneSortState
            : [
                {
                  colId: 'due_date',
                  sort: 'asc',
                  sortIndex: 0,
                },
              ],
          defaultState: { sort: null },
        })
        break
      case TabName.BLUEPRINT_SUMMARY:
        blueprintColumnApi?.applyColumnState({
          state: blueprintSummarySortState,
          defaultState: { sort: null },
        })
        break
      case TabName.PROJECT_SUMMARY:
        projectColumnApi?.applyColumnState({
          state: projectSummarySortState
            ? projectSummarySortState
            : [
                {
                  colId: 'set_date',
                  sort: 'asc',
                  sortIndex: 0,
                },
              ],
          defaultState: { sort: null },
        })
    }
  }, [
    dashboardActiveTab,
    blueprintColumnApi,
    blueprintMilestoneSortState,
    blueprintSummarySortState,
    bpMilestoneColumnApi,
    projectColumnApi,
    projectMilestoneColumnApi,
    projectMilestoneSortState,
    projectSummarySortState,
  ])

  const onFirstDataRender = () => {
    switch (dashboardActiveTab) {
      case TabName.MY_BLUEPRINT_MILESTONES:
        if (blueprintMilestoneSavedFilter) {
          bpMilestoneGridApi?.setFilterModel(blueprintMilestoneSavedFilter)
        }
        if (blueprintMilestoneSortState) {
          bpMilestoneColumnApi?.applyColumnState({
            state: blueprintMilestoneSortState,
            defaultState: { sort: null },
          })
        }
        break
      case TabName.MY_PROJECT_MILESTONES:
        if (projectMilestoneSavedFilter) {
          projectMilestoneGridApi?.setFilterModel(projectMilestoneSavedFilter)
        }
        projectMilestoneColumnApi?.applyColumnState(
          projectMilestoneSortState
            ? {
                state: projectMilestoneSortState,
                defaultState: { sort: null },
              }
            : {
                state: [
                  {
                    colId: 'due_date',
                    sort: 'asc',
                    sortIndex: 0,
                  },
                ],
                defaultState: { sort: null },
              },
        )
        break
      case TabName.BLUEPRINT_SUMMARY:
        if (blueprintSummarySavedFilter) {
          blueprintGridApi?.setFilterModel(blueprintSummarySavedFilter)
        }
        if (blueprintSummarySortState) {
          blueprintColumnApi?.applyColumnState({
            state: blueprintSummarySortState,
            defaultState: { sort: null },
          })
        }
        break
      case TabName.PROJECT_SUMMARY:
        if (projectSummarySavedFilter) {
          projectGridApi?.setFilterModel(projectSummarySavedFilter)
        }
        projectColumnApi?.applyColumnState(
          projectSummarySortState
            ? {
                state: projectSummarySortState,
                defaultState: { sort: null },
              }
            : {
                state: [
                  {
                    colId: 'set_date',
                    sort: 'asc',
                    sortIndex: 0,
                  },
                ],
                defaultState: { sort: null },
              },
        )
        break
      default:
        break
    }
  }

  const onFilterChange = (event: FilterChangedEvent) => {
    switch (dashboardActiveTab) {
      case TabName.MY_BLUEPRINT_MILESTONES:
        setBlueprintMilestoneSavedFilter(event.api.getFilterModel())
        break
      case TabName.MY_PROJECT_MILESTONES:
        setProjectMilestoneSavedFilter(event.api.getFilterModel())
        break
      case TabName.BLUEPRINT_SUMMARY:
        setBlueprintSummarySavedFilter(event.api.getFilterModel())
        break
      case TabName.PROJECT_SUMMARY:
        setProjectSummarySavedFilter(event.api.getFilterModel())
        break
      default:
        break
    }
  }

  const onSortChanged = (event: SortChangedEvent) => {
    switch (dashboardActiveTab) {
      case TabName.MY_BLUEPRINT_MILESTONES:
        setBlueprintMilestoneSortState(
          event.columnApi
            .getColumnState()
            .filter((s: Record<string, any>) => s.sort != null)
            .map((s: Record<string, any>) => new SortState(s)),
        )
        break
      case TabName.MY_PROJECT_MILESTONES:
        setProjectMilestoneSortState(
          event.columnApi
            .getColumnState()
            .filter((s: Record<string, any>) => s.sort != null)
            .map((s: Record<string, any>) => new SortState(s)),
        )
        break
      case TabName.BLUEPRINT_SUMMARY:
        setBlueprintSummarySortState(
          event.columnApi
            .getColumnState()
            .filter((s: Record<string, any>) => s.sort != null)
            .map((s: Record<string, any>) => new SortState(s)),
        )
        break
      case TabName.PROJECT_SUMMARY:
        setProjectSummarySortState(
          event.columnApi
            .getColumnState()
            .filter((s) => s.sort != null)
            .map((s) => new SortState(s)),
        )
        break
      default:
        break
    }
  }

  const resetAllFilters = () => {
    switch (dashboardActiveTab) {
      case TabName.BLUEPRINT_SUMMARY:
        blueprintGridApi?.setFilterModel({})
        blueprintColumnApi?.applyColumnState({
          defaultState: { sort: null },
        })
        break
      case TabName.PROJECT_SUMMARY:
        projectGridApi?.setFilterModel({})
        projectColumnApi?.applyColumnState({
          state: [
            {
              colId: 'set_date',
              sort: 'asc',
              sortIndex: 0,
            },
          ],
          defaultState: { sort: null },
        })
        break
      case TabName.MY_BLUEPRINT_MILESTONES:
        bpMilestoneGridApi?.setFilterModel({})
        bpMilestoneColumnApi?.applyColumnState({
          defaultState: { sort: null },
        })
        break
      case TabName.MY_PROJECT_MILESTONES:
        projectMilestoneGridApi?.setFilterModel({})
        projectMilestoneColumnApi?.applyColumnState({
          defaultState: { sort: null },
        })
    }
  }

  const sizeColumnsToFit = () => {
    switch (dashboardActiveTab) {
      case TabName.BLUEPRINT_SUMMARY:
        blueprintGridApi?.sizeColumnsToFit()
        break
      case TabName.PROJECT_SUMMARY:
        projectGridApi?.sizeColumnsToFit()
        break
      case TabName.MY_BLUEPRINT_MILESTONES:
        bpMilestoneGridApi?.sizeColumnsToFit()
        break
      case TabName.MY_PROJECT_MILESTONES:
        projectMilestoneGridApi?.sizeColumnsToFit()
        break
    }
  }

  const autoSizeColumns = () => {
    switch (dashboardActiveTab) {
      case TabName.BLUEPRINT_SUMMARY:
        blueprintColumnApi?.autoSizeColumns(
          blueprintColumnApi
            .getAllColumns()!
            .map((column: any) => column.colId),
        )
        break
      case TabName.PROJECT_SUMMARY:
        projectColumnApi?.autoSizeColumns(
          projectColumnApi.getAllColumns()!.map((column: any) => column.colId),
        )
        break
      case TabName.MY_BLUEPRINT_MILESTONES:
        bpMilestoneColumnApi?.autoSizeColumns(
          bpMilestoneColumnApi
            .getAllColumns()!
            .map((column: any) => column.colId),
        )
        break
      case TabName.MY_PROJECT_MILESTONES:
        projectMilestoneColumnApi?.autoSizeColumns(
          projectMilestoneColumnApi
            .getAllColumns()!
            .map((column: any) => column.colId),
        )
        break
    }
  }

  const resetColumnView = () => {
    switch (dashboardActiveTab) {
      case TabName.BLUEPRINT_SUMMARY:
        blueprintColumnApi?.resetColumnState()
        break
      case TabName.PROJECT_SUMMARY:
        projectColumnApi?.resetColumnState()
        break
      case TabName.MY_BLUEPRINT_MILESTONES:
        bpMilestoneColumnApi?.resetColumnState()
        break
      case TabName.MY_PROJECT_MILESTONES:
        projectMilestoneColumnApi?.resetColumnState()
        break
    }
  }

  const gridOptions = {
    animateRows: true,
    defaultColDef: {
      resizable: true,
      sortable: true,
      filter: true,
      width: 148,
      menuTabs: ['filterMenuTab'],
      filterParams: {
        buttons: ['clear', 'reset'],
        newRowsAction: 'keep',
      },
    },
    columnTypes: {
      fixed: {
        lockPinned: true,
        resizable: false,
        suppressSizeToFit: true,
        pinned: 'left',
      },
      blueprintNameLink: {
        cellRenderer: 'BlueprintNameLink',
      },
      projectNameLink: {
        cellRenderer: 'ProjectNameLink',
        cellClass: 'actions-button-cell',
      },
      setDateChip: {
        valueGetter: (params: ValueGetterParams) => dateValueGetter(params),
        unSortIcon: dashboardActiveTab === TabName.PROJECT_SUMMARY,
        filterParams: dateFilterParams,
        comparator: dateComparator,
      },
      healthChip: {
        cellRenderer: 'HealthChip',
      },
      projectMilestoneHealthChip: {
        cellRenderer: 'ProjectMilestoneHealthChip',
        cellClass: 'actions-button-cell',
        sortable: false,
      },
      completeToggle: {
        cellRenderer: 'CompleteToggle',
        sortable: false,
      },
      completeToggleEditor: {
        editable: (params: EditableCallbackParams) => {
          if (dashboardActiveTab === TabName.MY_BLUEPRINT_MILESTONES) {
            return (
              !params.data.current_milestone.auto_check ||
              userPermissions.isBlueprintAdmin
            )
          } else if (dashboardActiveTab === TabName.MY_PROJECT_MILESTONES) {
            return (
              !params.data.my_milestone.auto_check ||
              userPermissions.isProjectAdmin
            )
          } else {
            return false
          }
        },
        cellEditor: 'CompleteToggleEditor',
        cellEditorPopup: true,
      },
      milestoneOwnerAvatar: {
        cellRenderer: 'MilestoneOwnerAvatar',
        cellClass: 'actions-button-cell',
        suppressMenu: true,
        sortable: false,
      },
      dueDateChip: {
        cellRenderer: 'DueDateChip',
        valueGetter: (params: ValueGetterParams) => dateValueGetter(params),
        filterParams: dateFilterParams,
        comparator: dateComparator,
      },
      departmentsChips: {
        cellRenderer: 'DepartmentsChips',
        cellClass: 'actions-button-cell',
      },
      projectMilestoneDueDateChip: {
        cellRenderer: 'ProjectMilestoneDueDateChip',
        cellClass: 'actions-button-cell',
        sortIcon: dashboardActiveTab === TabName.MY_PROJECT_MILESTONES,
        valueGetter: (params: ValueGetterParams) => dateValueGetter(params),
        filterParams: dateFilterParams,
        comparator: dateComparator,
      },
      // budgetText: {
      //   cellRenderer: 'BudgetText',
      // },
      tierOne: {
        cellRenderer: 'TableToggleComponent',
        cellClass: 'not-editable',
      },
      healthHeader: {
        filterParams: {
          values: useCallback(
            (params: any) => {
              if (projectSummaryFacets.health) {
                params.success(
                  Object.keys(projectSummaryFacets.health).map(
                    (health: string) => getLabelFromHealth(health),
                  ),
                )
              } else {
                params.success([])
              }
            },
            [projectSummaryFacets],
          ),
          refreshValuesOnOpen: true,
        },
      },
      projectPhaseHeader: {
        filterParams: {
          values: [
            'Strategy & Planning',
            'Creative Development',
            'Sign List Finalization',
            'Production to Market',
          ],
          refreshValuesOnOpen: true,
        },
      },
      booleanHeader: {
        filterParams: {
          values: [true, false],
          refreshValuesOnOpen: true,
        },
      },
      currentMilestoneHeader: {
        filterParams: {
          values: useCallback(
            (params: any) => {
              if (projectSummaryFacets.currentMilestone) {
                params.success(
                  Object.keys(projectSummaryFacets.currentMilestone),
                )
              } else {
                params.success([])
              }
            },
            [projectSummaryFacets],
          ),
          refreshValuesOnOpen: true,
        },
      },
      departmentHeader: {
        filterParams: {
          values: useCallback(
            (params: any) => {
              if (projectSummaryFacets.departments) {
                params.success(Object.keys(projectSummaryFacets.departments))
              } else {
                params.success([])
              }
            },
            [projectSummaryFacets],
          ),
          refreshValuesOnOpen: true,
        },
      },
      campaignHeader: {
        filterParams: {
          values: useCallback(
            (params: any) => {
              if (projectSummaryFacets.campaign) {
                params.success(Object.keys(projectSummaryFacets.campaign))
              } else {
                params.success([])
              }
            },
            [projectSummaryFacets],
          ),
          refreshValuesOnOpen: true,
        },
      },
    },
    frameworkComponents: {
      BlueprintNameLink,
      ProjectNameLink,
      HealthChip,
      ProjectMilestoneHealthChip,
      MilestoneOwnerAvatar,
      DepartmentsChips,
      DueDateChip,
      ProjectMilestoneDueDateChip,
      BudgetText,
      TableToggleComponent,
      CompleteToggle,
      CompleteToggleEditor,
    },
  }

  const blueprintDashboardDatasource = (server: any) => {
    return {
      getRows: async (params: IServerSideGetRowsParams) => {
        const res = await server.getData(params)
        const lastRowIndex = res.data.length - 1
        if (res?.success) {
          params.success({
            rowData: res.rows,
            rowCount: +res.headers['total-count'],
            storeInfo: {
              finalBlueprintData:
                lastRowIndex > -1 ? res.data[lastRowIndex] : undefined,
              userInfo: { userPermissions, userType },
            },
          })
        }
      },
    }
  }

  const projectDashboardDatasource = (server: any) => {
    return {
      getRows: async (params: IServerSideGetRowsParams) => {
        const res = await server.getData(params)
        const lastRowIndex = res.data.length - 1
        if (res?.success) {
          params.success({
            rowData: res.rows,
            rowCount: +res.headers['total-count'],
            storeInfo: {
              finalProjectData:
                lastRowIndex > -1 ? res.data[lastRowIndex] : undefined,
              userInfo: { userPermissions, userType },
            },
          })
        }
      },
    }
  }

  const bpMilestoneDashboardDatasource = (server: any) => {
    return {
      getRows: async (params: IServerSideGetRowsParams) => {
        const res = await server.getData(params)
        const lastRowIndex = res.data.length - 1
        if (res?.success) {
          params.success({
            rowData: res.rows,
            rowCount: res.data.total_results,
            storeInfo: {
              finalBpMilestoneData:
                lastRowIndex > -1 ? res.data[lastRowIndex] : undefined,
              userInfo: { userPermissions: userType },
            },
          })
        }
      },
    }
  }

  const projectMilestoneDashboardDatasource = (server: any) => {
    return {
      getRows: async (params: IServerSideGetRowsParams) => {
        const res = await server.getData(params)
        const lastRowIndex = res.data.length - 1
        if (res?.success) {
          params.success({
            rowData: res.rows,
            rowCount: res.data.total_results,
            storeInfo: {
              finalProjectMilestoneData:
                lastRowIndex > -1 ? res.data[lastRowIndex] : undefined,
              userInfo: { userPermissions: userType },
            },
          })
        }
      },
    }
  }

  const exportBlueprintSummary = useCallback(async () => {
    setFullPageLoadingMessage('Exporting Blueprint Summary...')
    Axios({
      url: `${
        env.apiDomainUrl + DASHBOARD_SERVICE_API_DOMAIN_URL
      }/blueprints/export`,
      method: 'POST',
      data: currentBlueprintRequest,
      ...HEADER_OBJECT,
      responseType: 'blob',
    })
      .then((res) =>
        FileDownload(
          res.data,
          `export_blueprint_summary_${moment().format('MM_DD_YYYY_HHmm')}.xlsx`,
        ),
      )
      .then(() => setFullPageLoadingMessage(''))
      .catch((err) => {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: ALERT.ERROR,
          heading: 'Export Failed',
          message: err.response.data.message,
        })
        setFullPageLoadingMessage('')
      })
  }, [
    currentBlueprintRequest,
    env.apiDomainUrl,
    makeToast,
    setFullPageLoadingMessage,
  ])

  const exportProjectSummary = useCallback(async () => {
    setFullPageLoadingMessage('Exporting Project Summary...')
    Axios({
      url: `${
        env.apiDomainUrl + DASHBOARD_SERVICE_API_DOMAIN_URL
      }/projects/export`,
      method: 'POST',
      data: currentProjectRequest,
      ...HEADER_OBJECT,
      responseType: 'blob',
    })
      .then((res) =>
        FileDownload(
          res.data,
          `export_project_summary_${moment().format('MM_DD_YYYY_HHmm')}.xlsx`,
        ),
      )
      .then(() => setFullPageLoadingMessage(''))
      .catch((err) => {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: ALERT.ERROR,
          heading: 'Export Failed',
          message: err.response.data.message,
        })
        setFullPageLoadingMessage('')
      })
  }, [
    currentProjectRequest,
    env.apiDomainUrl,
    makeToast,
    setFullPageLoadingMessage,
  ])

  const exportBlueprintMilestoneSummary = useCallback(async () => {
    setFullPageLoadingMessage('Exporting Blueprint Milestone Summary...')
    Axios({
      url: `${
        env.apiDomainUrl + DASHBOARD_SERVICE_API_DOMAIN_URL
      }/blueprint_milestones/export`,
      method: 'POST',
      data: currentBlueprintMilestoneRequest,
      ...HEADER_OBJECT,
      responseType: 'blob',
    })
      .then((res) =>
        FileDownload(
          res.data,
          `export_blueprint_milestone_summary_${moment().format(
            'MM_DD_YYYY_HHmm',
          )}.xlsx`,
        ),
      )
      .then(() => setFullPageLoadingMessage(''))
      .catch((err) => {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: ALERT.ERROR,
          heading: 'Export Failed',
          message: err.response.data.message,
        })
        setFullPageLoadingMessage('')
      })
  }, [
    currentBlueprintMilestoneRequest,
    env.apiDomainUrl,
    makeToast,
    setFullPageLoadingMessage,
  ])

  const exportProjectMilestoneSummary = useCallback(async () => {
    setFullPageLoadingMessage('Exporting Project Milestone Summary...')
    Axios({
      url: `${
        env.apiDomainUrl + DASHBOARD_SERVICE_API_DOMAIN_URL
      }/project_milestones/export`,
      method: 'POST',
      data: currentProjectMilestoneRequest,
      ...HEADER_OBJECT,
      responseType: 'blob',
    })
      .then((res) =>
        FileDownload(
          res.data,
          `export_project_milestone_summary_${moment().format(
            'MM_DD_YYYY_HHmm',
          )}.xlsx`,
        ),
      )
      .then(() => setFullPageLoadingMessage(''))
      .catch((err) => {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: ALERT.ERROR,
          heading: 'Export Failed',
          message: err.response.data.message,
        })
        setFullPageLoadingMessage('')
      })
  }, [
    currentProjectMilestoneRequest,
    env.apiDomainUrl,
    makeToast,
    setFullPageLoadingMessage,
  ])

  const exportSummary = useCallback(async () => {
    switch (dashboardActiveTab) {
      case TabName.BLUEPRINT_SUMMARY:
        await exportBlueprintSummary()
        break
      case TabName.PROJECT_SUMMARY:
        await exportProjectSummary()
        break
      case TabName.MY_BLUEPRINT_MILESTONES:
        await exportBlueprintMilestoneSummary()
        break
      case TabName.MY_PROJECT_MILESTONES:
        await exportProjectMilestoneSummary()
        break
    }
  }, [
    dashboardActiveTab,
    exportBlueprintSummary,
    exportProjectSummary,
    exportBlueprintMilestoneSummary,
    exportProjectMilestoneSummary,
  ])

  const refreshDatasource = () => {
    switch (dashboardActiveTab) {
      case TabName.MY_BLUEPRINT_MILESTONES:
        bpMilestoneGridApi!.refreshServerSideStore({ purge: true })
        bpMilestoneColumnApi?.getAllColumns()?.map(
          (column: Column) =>
            (column.getColDef().cellClassRules = {
              'ag-grid-modified': (params: CellClassParams) => false,
            }),
        )
        break
      case TabName.MY_PROJECT_MILESTONES:
        projectMilestoneGridApi!.refreshServerSideStore({ purge: true })
        projectMilestoneColumnApi?.getAllColumns()?.map(
          (column: Column) =>
            (column.getColDef().cellClassRules = {
              'ag-grid-modified': (params: CellClassParams) => false,
            }),
        )
        break
      case TabName.BLUEPRINT_SUMMARY:
        blueprintGridApi!.refreshServerSideStore({ purge: true })
        blueprintColumnApi?.getAllColumns()?.map(
          (column: Column) =>
            (column.getColDef().cellClassRules = {
              'ag-grid-modified': (params: CellClassParams) => false,
            }),
        )
        break
      case TabName.PROJECT_SUMMARY:
        projectGridApi!.refreshServerSideStore({ purge: true })
        projectColumnApi?.getAllColumns()?.map(
          (column: Column) =>
            (column.getColDef().cellClassRules = {
              'ag-grid-modified': (params: CellClassParams) => false,
            }),
        )
        break
    }
  }

  return (
    <AgGridDashboardContext.Provider
      value={{
        blueprintGridApi,
        bpMilestoneGridApi,
        bpMilestoneColumnApi,
        projectGridApi,
        projectColumnApi,
        projectMilestoneGridApi,
        projectMilestoneColumnApi,
        onBlueprintGridReady,
        onBpMilestoneGridReady,
        onProjectGridReady,
        onProjectMilestoneGridReady,
        gridOptions,
        onFirstDataRender,
        onFilterChange,
        onSortChanged,
        resetAllFilters,
        sizeColumnsToFit,
        autoSizeColumns,
        resetColumnView,
        exportSummary,
        refreshDatasource,
      }}
    >
      {children}
    </AgGridDashboardContext.Provider>
  )
}

export const useAgGridDashboardContext = () =>
  useContext(AgGridDashboardContext)

export const AgGridDashboardProvider = AgGridDashboardProviderComponent
