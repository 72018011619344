export default class PricingResponse {
  estimated_quantity?: number
  estimated_unit_price?: number
  final_unit_price?: number
  po_number?: string

  constructor(props: any = {}) {
    this.estimated_quantity = props.estimated_quantity
    this.estimated_unit_price = props.estimated_unit_price
    this.final_unit_price = props.final_unit_price
    this.po_number = props.po_number
  }
}
