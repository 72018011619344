import React from 'react'
import { BlueprintCampaign } from '../../../../../../../models/signs/SignResponse.model'

export interface Props {
  value: BlueprintCampaign
}

const CampaignTypeCell = ({ value }: Props) => {
  return <div className="ag-cell-value">{value?.name}</div>
}

export default CampaignTypeCell
