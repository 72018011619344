export class Column {
  field?: string
  headerName?: string
  width?: number
  type?: string[]
  colId?: string
  cellClass?: string
  filter?: string
  sort?: string

  constructor(props: any = {}) {
    this.field = props.field
    this.headerName = props.headerName
    this.width = props.width
    this.type = props.type
    this.colId = props.colId
    this.cellClass = props.cellClass
    this.filter = props.filter
    this.sort = props.sort
  }
}
