import React, { Fragment } from 'react'
import { Grid, Form, Button, Tooltip } from '@enterprise-ui/canvas-ui-react'
import MessagingResponse from '../../../../../models/signs/MessagingResponse.model'
import EnterpriseIcon, { MinusIcon, PlusIcon } from '@enterprise-ui/icons'

export interface Props {
  attributes: any
  itemIndex: number
  isInProject: boolean
  handleChange: Function
  values: MessagingResponse
}

const MessagingAttributes = ({
  attributes = [''],
  itemIndex = 0,
  isInProject,
  handleChange,
  values,
}: Props) => {
  return (
    <Fragment>
      {attributes.map((attribute: string, index: number) => {
        const isLastRow = index + 1 === attributes.length
        return (
          <Grid.Container align="center" key={index}>
            <Grid.Item xs={true} className="hc-pr-none">
              <Form.Field
                id="attributes"
                type="textarea"
                size="dense"
                label={`Attribute ${index + 1}`}
                value={attribute}
                disabled={!isInProject}
                onChange={(e: any) => {
                  handleChange(
                    `item_info[${itemIndex}].attributes[${index}]`,
                    e.target.value,
                  )
                }}
              />
            </Grid.Item>
            <Grid.Item className="hc-pl-dense">
              <Tooltip
                location="bottom"
                content={
                  isLastRow ? 'Add another Attribute' : 'Remove Attribute'
                }
              >
                <Button
                  iconOnly
                  alert={!isLastRow}
                  onClick={() => {
                    handleChange(
                      `item_info[${itemIndex}].attributes`,
                      isLastRow
                        ? [...attributes, '']
                        : attributes.filter(
                            (attr: string, i: number) => i !== index,
                          ),
                    )
                  }}
                  disabled={
                    !isInProject ||
                    (values.sign_size !== 'OTHER' &&
                      isLastRow &&
                      attributes.length >= 4)
                  }
                >
                  <EnterpriseIcon icon={isLastRow ? PlusIcon : MinusIcon} />
                </Button>
              </Tooltip>
            </Grid.Item>
          </Grid.Container>
        )
      })}
    </Fragment>
  )
}

export default MessagingAttributes
