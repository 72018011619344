import Axios from 'axios'
import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect,
} from 'react'
import { connect } from 'react-redux'
import {
  PROJECT_SERVICE_API_DOMAIN_URL,
  SIGN_SERVICE_API_DOMAIN_URL,
  ADMIN_SERVICE_API_DOMAIN_URL,
  HEADER_OBJECT,
  DASHBOARD_SERVICE_API_DOMAIN_URL,
  TOASTER_DEFAULTS,
} from '../../App/constants/appConstants'
import { useEnv } from '@praxis/component-runtime-env'
import { ProjectSummary } from '../../../models/dashboardV2/ProjectSummary.model'
import { Division } from '../../../models/merchandise/hierarchy/Division.model'
import { ProjectDetail } from '../../../models/projects/ProjectDetail.model'
import { SignFacetsResponse } from '../../../models/signs/SignFacetsResponse.model'
import SignRequest from '../../../models/signs/SignRequest.model'
import SignResponse, { PogInfo } from '../../../models/signs/SignResponse.model'
import { useAppContext } from '../../App/context/appContext'
import { useUserContext } from '../../App/context/userContext'
import {
  MSC_ROW,
  NEW_SIGN,
  SIGN_DETAILS_SECTION,
} from '../constants/signDetailsConstants'
import { LocationQuantity } from '../../../models/signs/DistributionResponse.model'
import { PogSelectionRequest } from '../../../models/signs/PogSelectionRequest.modet'
import { isEditReasonRequired } from '../../App/helpers/signEditHelper'
import { userIsCategoryManager } from '../../App/helpers/userPermissionsHelper'
import { Department } from '../../../models/signLibrary'
import SignTemplateResponse from '../../../models/signTemplates/SignTemplateResponse.model'
import TableEditorRequest from '../../../models/signs/TableEditorRequest.model'
import { useToaster } from '@enterprise-ui/canvas-ui-react'
import { ContractCore } from '../../../models/contracts/ContractCore.model'

type ContextType = {
  setCurrentSign: Function
  currentSign: SignResponse
  refreshNewSignDetails: (projectId: string) => void
  refreshSignDetails: (
    id: string,
    loadingMessage: string,
    reissue?: boolean,
  ) => void
  signFacets: SignFacetsResponse
  refreshSignFacets: () => void
  setIsInProject: (isInProject: boolean) => void
  isInProject: boolean
  setOpenSidebar: (openSidebar: string) => void
  openSidebar: string
  createSign: (sign: SignRequest) => void
  updateSign: (sign: SignRequest, sign_id: string) => void
  updateSignSection: (
    sectionModel: any,
    sectionSlug: SIGN_DETAILS_SECTION,
    sign_id: string,
  ) => void
  project: ProjectDetail
  setProject: (project: ProjectDetail) => void
  expandedSection: string
  setExpandedSection: (section: string) => void
  projectSummary: ProjectSummary
  setProjectSummary: (summary: ProjectSummary) => void
  getProjectSummary: () => void
  modifiedSections: SIGN_DETAILS_SECTION[]
  addToModifiedSections: (section: SIGN_DETAILS_SECTION) => void
  removeFromModifiedSections: (section: SIGN_DETAILS_SECTION) => void
  deleteSign: (signsIds: string[], reasonCode?: string) => void
  finalizeSign: () => void
  selectedIncludeMscRow: MSC_ROW
  setSelectedIncludeMscRow: (row: MSC_ROW) => void
  selectedExcludeMscRow: MSC_ROW
  setSelectedExcludeMscRow: (row: MSC_ROW) => void
  pogs: PogInfo[]
  setPogs: (input: PogInfo[]) => void
  getPogs: () => void
  includePogs: (
    signId: string,
    pogSelectionRequest: PogSelectionRequest,
  ) => void
  excludePogs: (
    signId: string,
    pogSelectionRequest: PogSelectionRequest,
  ) => void
  isPogModalLoading: Boolean
  setIsPogModalLoading: (isLoading: boolean) => void
  pogLocations: LocationQuantity[]
  isLoadingLocations: Boolean
  setIsLoadingLocations: (isLoading: boolean) => void
  setPogLocations: (input: LocationQuantity[]) => void
  getPogLocations: () => void
  requiredFieldsChanged: boolean
  setRequiredFieldsChanged: (fieldsChanged: boolean) => void
  reasonCode: string
  setReasonCode: Function
  editReasonRequired: boolean
  setEditReasonRequired: Function
  actionType: string
  setActionType: Function
  signIds: string[]
  setSignIds: Function
  showReasonCodeModal: boolean
  setShowReasonCodeModal: Function
  signTemplates: SignTemplateResponse[]
  getSignTemplatesByType: (type: string) => void
  activeContracts: ContractCore[]
}

export const SignDetailsContext = createContext<ContextType | undefined>(
  undefined,
)

type Props = {
  children: React.ReactNode
}

const SignDetailsProviderComponent = ({ children }: Props) => {
  const { setFullPageLoadingMessage, setPageHasChanges, getActiveContracts } =
    useAppContext()!
  const { userInfo } = useUserContext()!
  const env = useEnv()
  const makeToast = useToaster()

  const [currentSign, setCurrentSign] = useState(new SignResponse())
  const [isInProject, setIsInProject] = useState(false)
  const [project, setProject] = useState(new ProjectDetail())
  const [openSidebar, setOpenSidebar] = useState('')
  const [signFacets, setSignFacets] = useState(new SignFacetsResponse())
  const [expandedSection, setExpandedSection] = useState('')
  const [projectSummary, setProjectSummary] = useState(new ProjectSummary())
  const [reasonCode, setReasonCode] = useState<string>('')
  const [showReasonCodeModal, setShowReasonCodeModal] = useState<boolean>(false)
  const [actionType, setActionType] = useState<string>('')
  const [signIds, setSignIds] = useState<string[]>([])
  const [editReasonRequired, setEditReasonRequired] = useState<boolean>(false)
  const [modifiedSections, setModifiedSections] = useState<
    SIGN_DETAILS_SECTION[]
  >([])
  const [selectedIncludeMscRow, setSelectedIncludeMscRow] = useState(
    MSC_ROW.NONE,
  )
  const [selectedExcludeMscRow, setSelectedExcludeMscRow] = useState(
    MSC_ROW.NONE,
  )
  const [requiredFieldsChanged, setRequiredFieldsChanged] = useState(false)

  const [pogs, setPogs] = useState<PogInfo[]>([])
  const [isPogModalLoading, setIsPogModalLoading] = useState(false)
  const [pogLocations, setPogLocations] = useState<LocationQuantity[]>([])
  const [isLoadingLocations, setIsLoadingLocations] = useState(false)
  const [signTemplates, setSignTemplates] = useState<SignTemplateResponse[]>([])
  const [activeContracts, setActiveContracts] = useState<ContractCore[]>([])

  useEffect(() => {
    getActiveContracts().then((data: ContractCore[]) =>
      setActiveContracts(data),
    )
  }, [getActiveContracts])

  const refreshNewSignDetails = useCallback(
    async (projectId: string) => {
      setFullPageLoadingMessage('Loading...')
      try {
        const res = await Axios.get(
          `${
            env.apiDomainUrl + PROJECT_SERVICE_API_DOMAIN_URL
          }/projects/${projectId}`,
        )
        const projectDetail = new ProjectDetail(res.data)
        setCurrentSign(
          new SignResponse({
            blueprint_id: res.data.blueprint_id,
            blueprint_name: res.data.blueprint_name,
            project_id: projectId,
            campaign: res.data.campaign,
            campaign_id: res.data.campaign_id,
            sign_status: 'New',
            non_retail_item_info: {
              sign_item_status: 'In Development',
              product_vendor: userInfo.permissions.isProductVendorOrCatMan
                ? userIsCategoryManager(userInfo.company, projectDetail.roster)
                  ? undefined
                  : userInfo.company
                : undefined,
            },
            sign_id: NEW_SIGN,
            on_pog_sign: !res.data.non_adjacency_project,
            sales_plan: projectDetail.project_type === 'SPLN',
          }),
        )
        setProject(projectDetail)
        setEditReasonRequired(isEditReasonRequired(projectDetail))
        setExpandedSection(SIGN_DETAILS_SECTION.INFO)
      } catch (err: any) {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'error',
          heading: 'Failed to Get Project',
          message: err.response.data.message,
        })
      }
      setFullPageLoadingMessage('')
    },
    [setFullPageLoadingMessage, env.apiDomainUrl, userInfo, makeToast],
  )

  const refreshSignDetails = useCallback(
    async (signId: string, loadingMessage: string, reissue?: boolean) => {
      setFullPageLoadingMessage(loadingMessage)
      try {
        const signRes = await Axios.get(
          `${env.apiDomainUrl + SIGN_SERVICE_API_DOMAIN_URL}/signs/${signId}`,
        )

        var newProject: ProjectDetail | undefined = undefined
        if (signRes.data.project_id) {
          const projectRes = await Axios.get(
            `${env.apiDomainUrl + PROJECT_SERVICE_API_DOMAIN_URL}/projects/${
              signRes.data.project_id
            }`,
          )
          newProject = new ProjectDetail(projectRes.data)
          setProject(newProject)
          setEditReasonRequired(isEditReasonRequired(newProject))
        }

        const newSign = new SignResponse({
          ...new SignResponse(signRes.data),
          sales_plan:
            signRes.data.sales_plan ||
            (newProject && newProject.project_type === 'SPLN'),
        })
        setCurrentSign(newSign)

        const includeLocationInfo =
          newSign.distribution.location_filter_info.include_location_info
        setSelectedIncludeMscRow(
          includeLocationInfo.locations.length > 0
            ? MSC_ROW.SPECIFIC_STORES
            : includeLocationInfo.department_location_groups.filter(
                (locationGroup) => locationGroup.department_id,
              ).length > 0
            ? MSC_ROW.DEPARTMENT
            : includeLocationInfo.all_locations
            ? MSC_ROW.ALL_STORES
            : MSC_ROW.NONE,
        )

        const excludeLocationInfo =
          newSign.distribution.location_filter_info.exclude_location_info
        setSelectedExcludeMscRow(
          excludeLocationInfo.locations.length > 0
            ? MSC_ROW.SPECIFIC_STORES
            : excludeLocationInfo.department_location_groups.filter(
                (locationGroup) => locationGroup.department_id,
              ).length > 0
            ? MSC_ROW.DEPARTMENT
            : MSC_ROW.NONE,
        )
      } catch (err: any) {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'error',
          heading: 'Failed to Get Sign Details',
          message: err.response.data.message,
        })
      }
      setFullPageLoadingMessage('')
    },
    [makeToast, setFullPageLoadingMessage, env],
  )

  const createSign = async (sign: SignRequest) => {
    setFullPageLoadingMessage('Saving New Sign...')
    try {
      var updatedSign = sign
      if (reasonCode !== undefined && reasonCode !== '') {
        updatedSign = new SignRequest({
          ...sign,
          reason_code: reasonCode ? reasonCode : undefined,
        })
      }
      const res: any = await Axios.post(
        `${env.apiDomainUrl + SIGN_SERVICE_API_DOMAIN_URL}/signs`,
        updatedSign,
        HEADER_OBJECT,
      )
      const newSign = new SignResponse(res.data)
      setPageHasChanges(false)
      setCurrentSign(newSign)
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'success',
        heading: 'Sign Created',
        message: 'Successfully created new sign',
      })

      window.location.href = `/projects/${currentSign.project_id}/signs/${newSign.sign_id}`
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Create New Sign',
        message: err.response.data.message,
      })
    }
    setFullPageLoadingMessage('')
  }

  const updateSign = async (sign: SignRequest, sign_id: string) => {
    setFullPageLoadingMessage('Saving Sign...')
    try {
      const res: any = await Axios.put(
        `${env.apiDomainUrl + SIGN_SERVICE_API_DOMAIN_URL}/signs/${sign_id}`,
        {
          ...sign,
          reason_code: reasonCode ? reasonCode : undefined,
        },
        HEADER_OBJECT,
      )
      setCurrentSign(new SignResponse(res.data))

      // we need to check modifiedSections length before updating its state, otherwise it will not update in time to check its new length
      modifiedSections.length === 1 && setPageHasChanges(false)
      removeFromModifiedSections(SIGN_DETAILS_SECTION.INFO)

      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'success',
        heading: 'Sign Updated',
        message: 'Successfully updated the sign',
      })
      setRequiredFieldsChanged(false)
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Update Sign',
        message: err.response.data.message,
      })
    }
    setFullPageLoadingMessage('')
  }

  const updateSignSection = async (
    sectionModel: any,
    sectionSlug: SIGN_DETAILS_SECTION,
    sign_id: string,
  ) => {
    setFullPageLoadingMessage('Saving Sign...')
    try {
      const res: any = await Axios.put(
        `${
          env.apiDomainUrl + SIGN_SERVICE_API_DOMAIN_URL
        }/signs/${sign_id}/${sectionSlug}`,
        { ...sectionModel, reason_code: reasonCode ? reasonCode : undefined },
        HEADER_OBJECT,
      )

      // we need to check modifiedSections length before updating its state, otherwise it will not update in time to check its new length
      modifiedSections.length === 1 && setPageHasChanges(false)
      removeFromModifiedSections(sectionSlug)

      setCurrentSign(new SignResponse(res.data))
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'success',
        heading: 'Sign Updated',
        message: 'Successfully updated the sign',
      })
      setRequiredFieldsChanged(false)
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Update Sign',
        message: err.response.data.message,
      })
    }
    setFullPageLoadingMessage('')
  }

  const refreshSignFacets = useCallback(async () => {
    try {
      const facetRes: any = await Axios.get(
        `${env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL}/facets/signs`,
      )
      if (project.project_id) {
        const departmentRes = await Axios.get(
          `${
            env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
          }/merchandise_hierarchy/departments`,
          {
            params: {
              division_id: project.divisions
                .map((division: Division) => division.division_id)
                .toString(),
              include_defaults: true,
            },
          },
        )
        setSignFacets(
          new SignFacetsResponse({
            ...facetRes.data,
            departments: departmentRes.data.sort(
              (a: Department, b: Department) =>
                a.department_id > b.department_id ? 1 : -1,
            ),
          }),
        )
      } else {
        setSignFacets(new SignFacetsResponse({ ...facetRes.data }))
      }
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Get Sign Facets',
        message: err.response.data.message,
      })
    }
  }, [makeToast, project.divisions, project.project_id, env.apiDomainUrl])

  const excludePogs = async (
    signId: string,
    pogSelectionRequest: PogSelectionRequest,
  ) => {
    setIsPogModalLoading(true)
    try {
      const response = await Axios.put(
        `${
          env.apiDomainUrl + SIGN_SERVICE_API_DOMAIN_URL
        }/signs/pog_selections/${signId}`,
        pogSelectionRequest,
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      response.data &&
        setPogs(
          response.data.map((result: PogInfo) => {
            return new PogInfo(result)
          }),
        )
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'success',
        heading: 'Pogs Excluded',
        message: 'Successfully excluded Pogs',
      })
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Exclude Pogs',
        message: err.response.data.message,
      })
    }
    setIsPogModalLoading(false)
  }

  const includePogs = async (
    signId: string,
    pogSelectionRequest: PogSelectionRequest,
  ) => {
    setIsPogModalLoading(true)
    try {
      const response = await Axios.put(
        `${
          env.apiDomainUrl + SIGN_SERVICE_API_DOMAIN_URL
        }/signs/pog_selections/${signId}`,
        pogSelectionRequest,
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      response.data &&
        setPogs(
          response.data.map((result: PogInfo) => {
            return new PogInfo(result)
          }),
        )
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'success',
        heading: 'Pogs Included',
        message: 'Successfully included Pogs',
      })
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Include Pogs',
        message: err.response.data.message,
      })
    }
    setIsPogModalLoading(false)
  }

  const getPogs = useCallback(async () => {
    setIsPogModalLoading(true)
    try {
      const response = await Axios.get(
        `${env.apiDomainUrl + SIGN_SERVICE_API_DOMAIN_URL}/sign_pogs/${
          currentSign.sign_id
        }`,
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      response.data &&
        setPogs(
          response.data.map((result: PogInfo) => {
            return new PogInfo(result)
          }),
        )
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Get Sign on POGs',
        message: err.response.data.message,
      })
    }
    setIsPogModalLoading(false)
  }, [currentSign.sign_id, makeToast, env.apiDomainUrl])

  const getPogLocations = useCallback(async () => {
    setIsLoadingLocations(true)
    try {
      const response = await Axios.get(
        `${env.apiDomainUrl + SIGN_SERVICE_API_DOMAIN_URL}/sign_locations/${
          currentSign.sign_id
        }`,
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      response.data &&
        setPogLocations(
          response.data.map((result: LocationQuantity) => {
            return new LocationQuantity(result)
          }),
        )
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Get Sign Locations',
        message: err.response.data.message,
      })
    }
    setIsLoadingLocations(false)
  }, [currentSign.sign_id, makeToast, env.apiDomainUrl])

  const getProjectSummary = useCallback(async () => {
    if (project) {
      try {
        const response = await Axios.get(
          `${env.apiDomainUrl + DASHBOARD_SERVICE_API_DOMAIN_URL}/projects/${
            project.project_id
          }`,
          {
            headers: { 'Content-Type': 'application/json' },
          },
        )
        response.data && setProjectSummary(new ProjectSummary(response.data))
      } catch (err: any) {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'error',
          heading: 'Failed to Get Project Summary',
          message: err.response.data.message,
        })
      }
    }
  }, [project, makeToast, env.apiDomainUrl])

  const addToModifiedSections = (section: SIGN_DETAILS_SECTION) => {
    setModifiedSections((previousState: SIGN_DETAILS_SECTION[]) => {
      if (!previousState.includes(section)) {
        return [...previousState, section]
      } else {
        return previousState
      }
    })
  }

  const removeFromModifiedSections = (section: SIGN_DETAILS_SECTION) => {
    setModifiedSections((previousState: SIGN_DETAILS_SECTION[]) => {
      if (previousState.includes(section)) {
        return previousState.filter(
          (currentSection) => currentSection !== section,
        )
      } else return previousState
    })
  }

  const deleteSign = async (signIds: string[], reasonCode?: string) => {
    try {
      setFullPageLoadingMessage('Deleting Sign...')
      const res = await Axios.put(
        `${env.apiDomainUrl + SIGN_SERVICE_API_DOMAIN_URL}/signs/batch`,
        new TableEditorRequest({
          project_id: project.project_id,
          reason_code: reasonCode ? reasonCode : undefined,
          signs: signIds.map((id: string) => ({
            action: 'DELETE',
            sign_id: id,
          })),
          updated_by: userInfo.name,
        }),
      )
      const rejected_sign_messages: string[] = res.data.signs
        .filter((signRes: any) => signRes.edit_status.status === 'REJECTED')
        .map((signRes: any) => signRes.edit_status.error_message)

      if (rejected_sign_messages.length > 0) {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'alert',
          heading: 'Sign could not be deleted',
          message: rejected_sign_messages.join('\n'),
        })
      } else {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'success',
          heading: 'Sign Deleted',
          message: `Successfully deleted ${
            res.data.signs.length > 1
              ? `${res.data.signs.length} Signs`
              : '1 Sign'
          } from this Project`,
        })
      }
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Restore Sign',
        message: err.response.data.message,
      })
    }
    setFullPageLoadingMessage('')
  }

  const finalizeSign = async () => {
    setFullPageLoadingMessage('Finalizing Sign...')
    try {
      const res = await Axios.put(
        `${env.apiDomainUrl + SIGN_SERVICE_API_DOMAIN_URL}/signs/${
          currentSign.sign_id
        }/finalize`,
        {
          project_id: project.project_id,
          updated_by: userInfo.name,
        },
      )

      setCurrentSign(new SignResponse(res.data))
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Finalize Sign',
        message: err.response.data.message,
      })
    }
    setFullPageLoadingMessage('')
  }

  const getSignTemplatesByType = useCallback(
    async (type: string) => {
      try {
        const res = await Axios.get(
          `${
            env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
          }/templates/sign_templates/type?sign_type=${encodeURIComponent(
            type,
          )}`,
        )
        setSignTemplates(res.data)
      } catch (err: any) {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'error',
          heading: 'Failed to Get Sign Templates',
          message: err.response.data.message,
        })
      }
    },
    [env.apiDomainUrl, makeToast],
  )

  return (
    <SignDetailsContext.Provider
      value={{
        currentSign,
        setCurrentSign,
        refreshNewSignDetails,
        refreshSignDetails,
        signFacets,
        refreshSignFacets,
        createSign,
        updateSign,
        updateSignSection,
        isInProject,
        setIsInProject,
        openSidebar,
        setOpenSidebar,
        project,
        setProject,
        expandedSection,
        setExpandedSection,
        projectSummary,
        setProjectSummary,
        getProjectSummary,
        modifiedSections,
        addToModifiedSections,
        removeFromModifiedSections,
        deleteSign,
        finalizeSign,
        selectedIncludeMscRow,
        setSelectedIncludeMscRow,
        selectedExcludeMscRow,
        setSelectedExcludeMscRow,
        pogs,
        setPogs,
        getPogs,
        excludePogs,
        includePogs,
        isPogModalLoading,
        setIsPogModalLoading,
        pogLocations,
        isLoadingLocations,
        setIsLoadingLocations,
        setPogLocations,
        getPogLocations,
        requiredFieldsChanged,
        setRequiredFieldsChanged,
        reasonCode,
        setReasonCode,
        editReasonRequired,
        setEditReasonRequired,
        showReasonCodeModal,
        setShowReasonCodeModal,
        actionType,
        setActionType,
        signIds,
        setSignIds,
        signTemplates,
        getSignTemplatesByType,
        activeContracts,
      }}
    >
      {children}
    </SignDetailsContext.Provider>
  )
}

export const SignDetailsProvider = connect(
  null,
  null,
)(SignDetailsProviderComponent)

export const useSignDetailsContext = () => useContext(SignDetailsContext)
