import { get } from 'lodash'
import { RosterUserType } from '../../components/Roster/constants/rosterConstants'
import { BusinessPartner } from '../app/BusinessPartner.model'
import { InternalUser, RosterUser } from './RosterUser.model'

export class RosterElement {
  type: RosterUserType
  title: string
  users: RosterUser[]

  constructor(props: any = {}) {
    this.type = props.type || ''
    this.title = props.title || ''
    this.users = get(props, 'users', []).map((user: any) =>
      this.type === RosterUserType.INTERNAL
        ? new InternalUser(user)
        : new BusinessPartner(user),
    )
  }
}
