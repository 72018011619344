import { useFormik } from 'formik'
import { CampaignDetail } from '../../../../models/campaigns/CampaignDetail.model'

export const useNewAdminCampaignForm = (
  newCampaignDetail: CampaignDetail,
  createNewCampaign: Function,
  closeCampaignForm: Function,
) => {
  const validate = (values: CampaignDetail) => {
    const errors: any = {}
    if (!values.campaign_id) {
      errors.project_phase = 'Required'
    } else if (!values.campaign_type) {
      errors.role = 'Required'
    }
    return errors
  }

  return useFormik({
    enableReinitialize: false,
    initialValues: newCampaignDetail,
    validate,
    validateOnChange: false,
    onSubmit(values) {
      createNewCampaign(
        new CampaignDetail({
          ...newCampaignDetail,
          campaign_id: values.campaign_id,
          campaign_type: values.campaign_type,
          active: true,
          fiscal_year_start_date: values.fiscal_year_start_date,
          fiscal_year_end_date: values.fiscal_year_end_date,
          campaign_plan_start_date: values.campaign_plan_start_date,
          campaign_plan_end_date: values.campaign_plan_end_date,
        }),
      )
      closeCampaignForm()
    },
  })
}
