import React from 'react'
import PageChangesWrapper from '../App/components/PageChangesWrapper'
import SignLibraryContainer from './components'
import { AgGridSignLibraryProvider } from './context/agGridSignLibraryContext'
import { ExportOptionsProvider } from './context/exportOptionsContext'
import { SignLibrarySSRMServerProvider } from './context/signLibrarySSRMServerContext'

const SignLibrary = (props: any) => (
  <PageChangesWrapper>
    <SignLibrarySSRMServerProvider>
      <AgGridSignLibraryProvider>
        <ExportOptionsProvider>
          <SignLibraryContainer {...props} />
        </ExportOptionsProvider>
      </AgGridSignLibraryProvider>
    </SignLibrarySSRMServerProvider>
  </PageChangesWrapper>
)

export default SignLibrary
