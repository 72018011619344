import React, { Fragment } from 'react'
import { useNavigate } from 'react-router'
import moment from 'moment'
import { Button, Dropdown } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, {
  CheckboxIcon,
  FilesIcon,
  OverflowMenuVerticalIcon,
  TrashIcon,
  VisibleShowIcon,
} from '@enterprise-ui/icons'
import SignResponse from '../../../../../../../models/signs/SignResponse.model'
import { useProjectSignListContext } from '../../../context/projectSignListContext'
import { useProjectDetailsContext } from '../../../../../context/projectDetailsContext'
import { useUserContext } from 'components/App/context/userContext'
import { DialogProps } from '../../../../../../../models/app/DialogProps.model'
import { useAppContext } from 'components/App/context/appContext'
import { JobStatusNames } from '../../../../../../../models/job/JobStatus.model'
import { RowNode } from 'ag-grid-community'
import {
  disableSignAction,
  getSignEditorCredentials,
} from 'components/App/helpers/signEditHelper'

export interface Props {
  data: SignResponse
  node: RowNode
}

const TableDropdownMenu = ({ data = new SignResponse(), node }: Props) => {
  const { userName, userInfo } = useUserContext()!
  const { setDialogProps } = useAppContext()!
  const { currentProject, editReasonRequired } = useProjectDetailsContext()!
  const {
    finalizeSign,
    setSignForDuplicateModal,
    setShowReasonCodeModal,
    setSignToDuplicate,
    setActionType,
    deleteSigns,
    signDuplicateResponse,
    setSignIds,
  } = useProjectSignListContext()!
  const navigate = useNavigate()
  const handleViewSignClick = (sign_id: string, project_id: any) => {
    navigate(`/projects/${project_id}/signs/${sign_id}`)
  }
  const isDisabledAction = (action: string) =>
    disableSignAction(action, currentProject, [data], userInfo)
  const userCanUseAction = getSignEditorCredentials(
    userInfo,
    currentProject.roster,
    data,
  )
  const userCanFinalize =
    userInfo.permissions.isSignAdmin || userInfo.permissions.isKitAdmin
  const duplicateRunning =
    signDuplicateResponse?.status?.name === JobStatusNames.RUNNING

  return (
    <Dropdown size="expanded" location="bottom-left">
      <Button
        aria-label="Row dropdown menu"
        type="ghost"
        className="sem_IconBtn-dark"
        data-testid="openSignDropdown"
        iconOnly
        size="dense"
      >
        <EnterpriseIcon icon={OverflowMenuVerticalIcon} size="md" />
      </Button>
      <Dropdown.Menu>
        <Dropdown.MenuItem as="div" className="sem_DropdownMenuItem--info">
          <span className="sem_StrongText hc-mr-dense">Last Modified:</span>
          {moment(data.updated_date).format('MM/DD/YYYY hh:mm A')}
        </Dropdown.MenuItem>
        <Dropdown.MenuItem
          as="div"
          className="sem_DropdownMenuItem--info divider"
        >
          <span className="sem_StrongText hc-mr-dense">Modified By:</span>
          {data.updated_by}
        </Dropdown.MenuItem>
        <Dropdown.MenuItem
          data-testid="openProjectSign"
          onClick={() => handleViewSignClick(data.sign_id, data.project_id)}
        >
          <EnterpriseIcon className="hc-mr-dense" icon={VisibleShowIcon} />
          View Sign Details
        </Dropdown.MenuItem>
        {userCanUseAction || userCanFinalize ? (
          <Fragment>
            <Dropdown.MenuItem
              data-testid="openDuplicateSign"
              disabled={isDisabledAction('duplicate') && !duplicateRunning}
              onClick={() => {
                if (editReasonRequired) {
                  setSignToDuplicate(data)
                  setActionType('Duplicate')
                  setShowReasonCodeModal(true)
                } else {
                  setSignForDuplicateModal(data)
                }
              }}
            >
              <EnterpriseIcon className="hc-mr-dense" icon={FilesIcon} />
              Duplicate Signs
            </Dropdown.MenuItem>
            <Dropdown.MenuItem
              disabled={isDisabledAction('deleteSigns')}
              onClick={() => {
                if (editReasonRequired) {
                  setShowReasonCodeModal(true)
                  setActionType('DeleteSigns')
                  setSignIds([data.sign_id])
                } else {
                  setDialogProps(
                    new DialogProps({
                      headingText: 'Are you sure you want to delete this sign?',
                      approveButtonText: 'Yes',
                      refuseButtonText: 'No',
                      onApprove: () => {
                        deleteSigns([data.sign_id])
                        setDialogProps(new DialogProps())
                      },
                      onRefuse: () => {
                        setDialogProps(new DialogProps())
                      },
                    }),
                  )
                }
              }}
            >
              <EnterpriseIcon className="hc-mr-dense" icon={TrashIcon} />
              Delete Sign from Project
            </Dropdown.MenuItem>
          </Fragment>
        ) : null}
        {userCanFinalize ? (
          <Dropdown.MenuItem
            onClick={() =>
              finalizeSign(data.sign_id, data.project_id, userName)
            }
            disabled={isDisabledAction('finalizeSign')}
          >
            <EnterpriseIcon className="hc-mr-dense" icon={CheckboxIcon} />
            Finalize Sign
          </Dropdown.MenuItem>
        ) : null}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default TableDropdownMenu
