import React, { useState, useEffect, Fragment } from 'react'
import { Grid, Divider, Tabs, Card } from '@enterprise-ui/canvas-ui-react'
import BlueprintDetailsTab from './BlueprintDetailTab'
import BlueprintOverviewCard from './BlueprintDetailTab/components/BlueprintOverviewCard'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { TabName } from './BlueprintDetailTab/constants/blueprintDetailsConstants'
import { NOTES_BUTTON } from '../../App/constants/appConstants'
import BlueprintNotes from './BlueprintNotes'
import { useBlueprintDetailsContainerContext } from '../context/blueprintDetailsContainerContext'
import BlueprintMilestoneTab from './BlueprintMilestoneTab'
import { useAppContext } from '../../App/context/appContext'
import MainSectionHeader from '../../App/components/MainSectionHeader'
import BlueprintProjectListTab from './BlueprintProjectList'

const BlueprintDetailsContainer = () => {
  const { id, type } = useParams()
  const { setPageHasChanges /*pageHasChanges*/ } = useAppContext()!
  const { currentBlueprint, refreshCurrentBlueprint, setCurrentProjectId } =
    useBlueprintDetailsContainerContext()!

  const navigate = useNavigate()
  const location = useLocation()
  const [activeTab, setActiveTab] = useState('')

  // get current blueprint only on first page load
  useEffect(() => {
    if (id !== undefined) {
      refreshCurrentBlueprint(id, type)
    }
  }, [refreshCurrentBlueprint, id, type])

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    setActiveTab(params.get('tab') || TabName.DETAILS)
    setCurrentProjectId(params.get('project_id') || '')
  }, [setPageHasChanges, location, setCurrentProjectId])

  const handleTabChange = (e: any, tab: any) => {
    const params = new URLSearchParams(location.search)
    params.set('tab', tab.name)
    navigate(`${location.pathname}?${params.toString()}`)
  }

  return (
    <Fragment>
      <MainSectionHeader
        sectionTitle={
          window.location.pathname.includes('duplicate')
            ? currentBlueprint.name + ' DUPLICATE'
            : currentBlueprint.name || 'New Blueprint'
        }
        crumbs={[
          { name: 'Blueprint Library', to: '/blueprints' },
          { name: 'Blueprint Details' },
        ]}
        notesButton={NOTES_BUTTON.BLUEPRINT}
      />

      <BlueprintNotes />
      <Card className="hc-pt-normal hc-ph-dense hc-pb-dense">
        <div className="hc-pa-dense">
          <Grid.Container>
            <BlueprintOverviewCard currentBlueprint={currentBlueprint} />
            <Grid.Item className="hc-pb-dense" xs={12}>
              <Tabs
                className="hc-mb-none"
                onTabSelect={handleTabChange}
                activeTab={activeTab}
              >
                <Tabs.Item name={TabName.DETAILS}>Blueprint Details</Tabs.Item>
                <Tabs.Item
                  className="sem_BlueprintTab"
                  disabled={currentBlueprint.blueprint_id === ''}
                  name={TabName.MILESTONES}
                >
                  Timing Plan
                </Tabs.Item>
                <Tabs.Item
                  className="sem_BlueprintTab"
                  disabled={
                    currentBlueprint.blueprint_id === '' ||
                    currentBlueprint.milestone?.template_name === ''
                  }
                  name={TabName.PROJECTS}
                >
                  SAP Project Requests
                </Tabs.Item>
              </Tabs>
              <Divider />
            </Grid.Item>
            <Grid.Item xs={12} className="hc-pt-none">
              {activeTab === TabName.DETAILS ? (
                <BlueprintDetailsTab />
              ) : activeTab === TabName.MILESTONES ? (
                <BlueprintMilestoneTab />
              ) : activeTab === TabName.PROJECTS ? (
                // <SignProjectsTab />
                <BlueprintProjectListTab />
              ) : null}
            </Grid.Item>
          </Grid.Container>
        </div>
        {/* show warning if user is navigating to a different page within the app with unsaved changes */}
        {/*<Prompt*/}
        {/*  when={pageHasChanges}*/}
        {/*  message="You have unsaved changes. Are you sure you want to leave?"*/}
        {/*/>*/}
      </Card>
    </Fragment>
  )
}

export default BlueprintDetailsContainer
