import React, { Fragment, useEffect } from 'react'
import CampaignTypeForm from './CampaignTypeForm'
import CampaignGroupForm from './CampaignGroupForm'
import NewCampaignGroupForm from './NewCampaignGroupForm'
import { useAdminCampaignsContext } from '../context/adminCampaignsContext'

const CampaignDetails = () => {
  const {
    newCampaign,
    currentCampaign,
    getParentCampaign,
    getChildCampaign,
    getRosterTemplates,
  } = useAdminCampaignsContext()!

  useEffect(() => {
    getRosterTemplates()
  }, [getRosterTemplates])

  useEffect(() => {
    if (currentCampaign.parent) {
      getParentCampaign(currentCampaign.id)
    } else {
      if (currentCampaign.id !== '') {
        getChildCampaign(currentCampaign.id)
      }
    }
  }, [getParentCampaign, currentCampaign, getChildCampaign])

  return (
    <Fragment>
      {newCampaign.name !== '' ? (
        <NewCampaignGroupForm />
      ) : currentCampaign.parent ? (
        <CampaignGroupForm />
      ) : (
        <CampaignTypeForm />
      )}
    </Fragment>
  )
}

export default CampaignDetails
