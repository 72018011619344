import React from 'react'
import { Layout } from '@enterprise-ui/canvas-ui-react'
import PageChangesWrapper from '../App/components/PageChangesWrapper'
import BlueprintDetailsContainer from './components/BlueprintDetailsContainer'
import { BlueprintNotesProvider } from './components/BlueprintNotes/context/blueprintNotesContext'
import { BlueprintDetailsContainerProvider } from './context/blueprintDetailsContainerContext'

const BlueprintDetails = (props: any) => (
  <PageChangesWrapper>
    <BlueprintDetailsContainerProvider>
      {/* BlueprintNotesProvider needs to wrap BlueprintDetailsContainer so the button works */}
      <BlueprintNotesProvider>
        <Layout.Body className="hc-pa-expanded" includeRail>
          <BlueprintDetailsContainer {...props} />
        </Layout.Body>
      </BlueprintNotesProvider>
    </BlueprintDetailsContainerProvider>
  </PageChangesWrapper>
)

export default BlueprintDetails
