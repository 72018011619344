import React, { Fragment } from 'react'
import { useStandardSignAdminContext } from '../../context/standardSignAdminContext'
import { useAgGridStandardSignAdminContext } from '../../context/agGridStandardSignAdminContext'
import { RowNode } from 'ag-grid-community'
import { Grid, Button } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { CancelIcon, SaveIcon } from '@enterprise-ui/icons'

const TableEditorButtons = () => {
  const {
    getSignTemplates,
    signTemplatesModified,
    setSignTemplatesModified,
    setModifiedSignTemplates,
    setModifiedSignTemplateIds,
  } = useStandardSignAdminContext()!
  const { gridApi, validateSignTemplatesAndSave } =
    useAgGridStandardSignAdminContext()!

  const handleSave = () => {
    const modifiedRowNodes: RowNode[] = []
    gridApi?.forEachNode((node: RowNode) => modifiedRowNodes.push(node))
    validateSignTemplatesAndSave(
      modifiedRowNodes.filter((node) => node.data.modified),
    )
    setSignTemplatesModified(false)
  }

  const handleCancel = () => {
    getSignTemplates()
    setModifiedSignTemplates([])
    setModifiedSignTemplateIds([])
    setSignTemplatesModified(false)
  }

  return (
    <Fragment>
      <Grid.Item className="hc-ph-dense hc-pb-dense">
        <Button
          type="ghost"
          disabled={!signTemplatesModified}
          onClick={() => handleCancel()}
        >
          <EnterpriseIcon className="hc-mr-dense" icon={CancelIcon} />
          Cancel Changes
        </Button>
      </Grid.Item>
      <Grid.Item className="hc-ph-dense hc-pb-dense">
        <Button
          type="ghost"
          disabled={!signTemplatesModified}
          onClick={() => handleSave()}
        >
          <EnterpriseIcon className="hc-mr-dense" icon={SaveIcon} />
          Save Changes
        </Button>
      </Grid.Item>
    </Fragment>
  )
}

export default TableEditorButtons
