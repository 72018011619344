import React, { useEffect, Fragment } from 'react'
import { Button, Divider, Grid, Popover } from '@enterprise-ui/canvas-ui-react'
import AddProjectMilestoneForm from './AddProjectMilestoneForm'
import EnterpriseIcon, { PlusIcon } from '@enterprise-ui/icons'
import AddProjectMilestoneMenu from './AddProjectMilestoneMenu'
import CreateProjectMilestoneForm from './CreateProjectMilestoneForm'
import ProjectMilestoneCard from './ProjectMilestoneCard'
import { useUserContext } from 'components/App/context/userContext'
import { useProjectDetailsContext } from '../../../context/projectDetailsContext'
import { useProjectMilestoneTabContext } from '../context/projectMilestoneTabContext'
import { MilestoneTemplate } from '../../../../../models/milestones/MilestoneTemplate.model'
import {
  InternalUser,
  RosterUser,
} from '../../../../../models/roster/RosterUser.model'
import { BusinessPartner } from '../../../../../models/app/BusinessPartner.model'
import { RosterElement } from '../../../../../models/roster/RosterElement.model'
import AddScheduleTemplate from './AddScheduleTemplate'

export const ProjectMilestoneTab = () => {
  const { currentProject } = useProjectDetailsContext()!
  const { userPermissions, userName, userEmail, userCompany } =
    useUserContext()!
  const {
    projectMilestone,
    getProjectMilestones,
    saveProjectMilestones,
    addMilestone,
    setAddMilestone,
    createMilestone,
    setCreateMilestone,
    setIsOpen,
    closeMilestoneForm,
    milestonesInProjectPhase,
    getMilestonesInProjectPhase,
  } = useProjectMilestoneTabContext()!

  const enableSave = () => {
    return currentProject.milestones.some((milestone: MilestoneTemplate) => {
      const roleMatch = currentProject.roster.find(
        (roles: RosterElement) => roles.title === milestone.role,
      )
      return roleMatch?.users.some((user: RosterUser) =>
        user.type === 'INTERNAL'
          ? (user as InternalUser).email === userEmail
          : (user as BusinessPartner).name === userCompany,
      )
    })
  }

  useEffect(() => {
    getProjectMilestones()
  }, [getProjectMilestones])

  useEffect(() => {
    getMilestonesInProjectPhase()
  }, [getMilestonesInProjectPhase])

  return (
    <Grid.Container className="sem-MilestonesTab">
      <Grid.Item xs={true}>
        <Grid.Container justify="flex-start">
          {userPermissions.isProjectAdmin &&
            currentProject.workflow.status !== 'Archive' &&
            currentProject.workflow.status !== 'Cancel' && (
              <Grid.Item>
                <Popover
                  type="clickOnly"
                  location="bottom-left"
                  size="expanded"
                  className="sem-AddMilestonePopover"
                  content={<AddScheduleTemplate />}
                  onRequestClose={() => setIsOpen(false)}
                >
                  <Button
                    type="ghost"
                    fullWidth
                    className="hc-mt-normal hc-ta-justify"
                  >
                    <EnterpriseIcon className="hc-mr-normal" icon={PlusIcon} />
                    Add Template
                  </Button>
                </Popover>
              </Grid.Item>
            )}
          {/* Add Milestone Menu */}
          {userPermissions.isProjectAdmin &&
            currentProject.workflow.status !== 'Archive' &&
            currentProject.workflow.status !== 'Cancel' && (
              <Grid.Item>
                <Popover
                  type="clickOnly"
                  location="bottom-left"
                  size="expanded"
                  content={
                    addMilestone ? (
                      <AddProjectMilestoneForm />
                    ) : createMilestone ? (
                      <CreateProjectMilestoneForm />
                    ) : (
                      <AddProjectMilestoneMenu
                        milestonesInProjectPhase={milestonesInProjectPhase}
                        setAddMilestone={setAddMilestone}
                        setCreateMilestone={setCreateMilestone}
                      />
                    )
                  }
                  onRequestClose={closeMilestoneForm}
                >
                  <Button
                    type="ghost"
                    fullWidth
                    className="hc-mt-normal hc-ta-justify"
                  >
                    <EnterpriseIcon className="hc-mr-normal" icon={PlusIcon} />
                    Add Milestone
                  </Button>
                </Popover>
              </Grid.Item>
            )}

          {/* Save Button */}
          {(userPermissions.isProjectEditor || enableSave()) &&
            currentProject.workflow.status !== 'Archive' &&
            currentProject.workflow.status !== 'Cancel' && (
              <Grid.Item xs={2}>
                <Button
                  className="hc-mt-normal"
                  fullWidth
                  type="primary"
                  data-testid="saveMilestones"
                  disabled={projectMilestone.length === 0}
                  onClick={() => {
                    saveProjectMilestones(
                      currentProject.project_id,
                      projectMilestone,
                      userName,
                    )
                  }}
                >
                  Save Changes
                </Button>
              </Grid.Item>
            )}

          <Grid.Item xs={12}>
            {projectMilestone.length > 0 ? (
              <Fragment>
                <Grid.Container className="hc-bg-grey06 rounded" spacing="none">
                  <Grid.Item className="phase-GridItem" xs>
                    <div className="hc-pa-dense phase-Container">
                      <p className="title-name hc-mb-none">
                        Sign List Finalization
                      </p>
                      <Divider className="phase-Divider" width={3} />
                      {projectMilestone
                        .filter(
                          (milestone: MilestoneTemplate) =>
                            milestone.project_phase ===
                            'Sign List Finalization',
                        )
                        .sort((a: any, b: any) => b['days_out'] - a['days_out'])
                        .map((filteredMilestone) => (
                          <div className="hc-pt-expanded">
                            <ProjectMilestoneCard
                              milestone={filteredMilestone}
                              key={filteredMilestone.milestone_id}
                            />
                          </div>
                        ))}
                    </div>
                  </Grid.Item>
                  <Grid.Item className="phase-GridItem" xs>
                    <div className="hc-pa-dense phase-Container">
                      <p className="title-name hc-mb-none">
                        Production to Market
                      </p>
                      <Divider className="phase-Divider" width={3} />
                      {projectMilestone
                        .filter(
                          (milestone: MilestoneTemplate) =>
                            milestone.project_phase === 'Production to Market',
                        )
                        .sort((a: any, b: any) => b['days_out'] - a['days_out'])
                        .map((filteredMilestone) => (
                          <div className="hc-pt-expanded">
                            <ProjectMilestoneCard
                              milestone={filteredMilestone}
                              key={filteredMilestone.milestone_id}
                            />
                          </div>
                        ))}
                    </div>
                  </Grid.Item>
                </Grid.Container>
              </Fragment>
            ) : (
              <p className="hc-clr-grey03 hc-mb-normal">
                No milestones in the current project.
              </p>
            )}
          </Grid.Item>
        </Grid.Container>
      </Grid.Item>
    </Grid.Container>
  )
}

export default ProjectMilestoneTab
