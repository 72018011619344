import React from 'react'
import { parseInitials } from '../../../App/helpers/nameInteractions'
import { Column } from 'ag-grid-community'
import AvatarList, {
  AvatarPopover,
  ResizableColWidthContainer,
} from './AvatarList'

export interface Props {
  value: string[]
  column: Column
}

const MilestoneOwnerAvatar = ({ value = [], column }: Props) => {
  const renderItem = (val: string) => <span>{parseInitials(val)}</span>

  return (
    <AvatarPopover value={value} renderItem={renderItem}>
      <ResizableColWidthContainer column={column}>
        <AvatarList value={value}>{renderItem}</AvatarList>
      </ResizableColWidthContainer>
    </AvatarPopover>
  )
}

export default MilestoneOwnerAvatar
