import React, { Fragment, useEffect } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import {
  Grid,
  Card,
  Anchor,
  Tooltip,
  Button,
  Caption,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { CheckIcon, TrashIcon } from '@enterprise-ui/icons'
import { useSignDetailsContext } from '../context/signDetailsContext'
import { getHealthChipColor } from '../../App/helpers/userColor'
import { useUserContext } from '../../App/context/userContext'
import { NEW_SIGN } from '../constants/signDetailsConstants'
import FinalizeLevelCell from '../../ProjectDetails/components/ProjectSignList/components/signListViews/components/FinalizeLevelCell'
import { useAppContext } from '../../App/context/appContext'
import { DialogProps } from '../../../models/app/DialogProps.model'
import {
  disableSignAction,
  getProjectStatus,
  getSignEditorCredentials,
} from '../../App/helpers/signEditHelper'
import RubixChip from 'components/common/RubixChip'

export const SignOverviewCard = () => {
  const {
    project,
    projectSummary,
    getProjectSummary,
    currentSign,
    editReasonRequired,
    isInProject,
    deleteSign,
    finalizeSign,
    requiredFieldsChanged,
    setSignIds,
    setActionType,
    setShowReasonCodeModal,
  } = useSignDetailsContext()!
  const { userPermissions, userInfo } = useUserContext()!
  const { setDialogProps } = useAppContext()!
  useEffect(() => {
    project.project_id && getProjectSummary()
  }, [getProjectSummary, project.project_id])

  const userCanFinalizeOrRemove = () =>
    getSignEditorCredentials(userInfo, project.roster, currentSign) ||
    userInfo.permissions.isKitAdmin

  const isDisabledAction = (action: string) =>
    disableSignAction(
      action,
      project,
      [currentSign],
      userInfo,
      requiredFieldsChanged,
    )

  return (
    <Grid.Item xs={12}>
      <Card className="sem_OverviewCard" elevation={0}>
        <div className="hc-pa-normal hc-pv-normal">
          <Grid.Container
            className="sem_Overview"
            align="center"
            justify="space-between"
          >
            <Grid.Item className="hc-pt-none">
              <Caption
                uppercase
                above="Project Set Date "
                className="hc-pt-normal"
              >
                {project?.set_date && moment(project.set_date).isValid()
                  ? moment(project.set_date).format('MM/DD/YYYY')
                  : ''}
              </Caption>
            </Grid.Item>
            <Grid.Item className="hc-pt-none">
              <Caption uppercase above="Project Name " className="hc-pt-normal">
                <Anchor
                  className="sem_StrongText hc-mr-dense"
                  as={Link}
                  to={`/projects/${project?.project_id}?tab=SIGN+LIST`}
                >
                  {project?.short_name}
                </Anchor>
              </Caption>
            </Grid.Item>
            <Grid.Item className="hc-pt-none">
              <Caption
                uppercase
                above="SAP Project Id# "
                className="hc-pt-normal"
              >
                {project?.sap_project_id ? project?.sap_project_id : 'None'}
              </Caption>
            </Grid.Item>
            {projectSummary.current_milestone ? (
              <Fragment>
                <Grid.Item className="hc-pt-none">
                  <Caption
                    uppercase
                    above="Project Phase"
                    className="hc-pt-normal"
                  >
                    <RubixChip
                      data-testid="statusChip"
                      color={getHealthChipColor(projectSummary.health)}
                    >
                      {projectSummary.current_milestone.project_phase || 'None'}
                    </RubixChip>
                  </Caption>
                </Grid.Item>
                <Grid.Item className="hc-pt-none">
                  <Caption
                    uppercase
                    above="Project Milestone"
                    className="hc-pt-normal"
                  >
                    <RubixChip
                      data-testid="statusChip"
                      color={getHealthChipColor(projectSummary.health)}
                    >
                      {projectSummary.current_milestone.name || 'None'}
                    </RubixChip>
                  </Caption>
                </Grid.Item>
                <Grid.Item className="hc-pt-none">
                  <Caption
                    uppercase
                    above="Project Status"
                    className="hc-pt-normal"
                  >
                    <RubixChip
                      data-testid="statusChip"
                      color={project.workflow.color}
                    >
                      {getProjectStatus(project)}
                    </RubixChip>
                  </Caption>
                </Grid.Item>
              </Fragment>
            ) : null}
            <Grid.Item className="hc-pt-none">
              <Caption
                uppercase
                above="Sign Finalization Level"
                className="hc-pt-normal"
              >
                <FinalizeLevelCell data={currentSign} onDetailPage />
              </Caption>
            </Grid.Item>
            {currentSign.reason_code ? (
              <Grid.Item className="hc-pt-none">
                <Caption uppercase above="Reason Code" className="hc-pt-normal">
                  {currentSign.reason_code}
                </Caption>
              </Grid.Item>
            ) : null}
            {userCanFinalizeOrRemove() ? (
              <Grid.Item className="hc-pv-dense">
                {currentSign.sign_id !== NEW_SIGN && isInProject ? (
                  <Fragment>
                    <Tooltip location="bottom" content="Remove Sign">
                      <Button
                        aria-label="Delete sign"
                        type="ghost"
                        iconOnly
                        disabled={isDisabledAction('deleteSign')}
                        className="hc-mr-dense"
                        onClick={() => {
                          if (editReasonRequired) {
                            setSignIds([currentSign.sign_id])
                            setActionType('DeleteSigns')
                            setShowReasonCodeModal(true)
                          } else {
                            setDialogProps(
                              new DialogProps({
                                headingText:
                                  'Are you sure you want to delete this sign?',
                                approveButtonText: 'Yes',
                                refuseButtonText: 'No',
                                onApprove: () => {
                                  deleteSign([currentSign.sign_id])
                                  setDialogProps(new DialogProps())
                                },
                                onRefuse: () => {
                                  setDialogProps(new DialogProps())
                                },
                              }),
                            )
                          }
                        }}
                      >
                        <EnterpriseIcon size="sm" icon={TrashIcon} />
                      </Button>
                    </Tooltip>
                  </Fragment>
                ) : null}
                {(userPermissions.isSignAdmin || userPermissions.isKitAdmin) &&
                currentSign.sign_id !== NEW_SIGN &&
                isInProject ? (
                  <Tooltip location="bottom" content="Finalize Sign">
                    <Button
                      aria-label="Finalize sign"
                      type="ghost"
                      iconOnly
                      disabled={isDisabledAction('finalizeSign')}
                      onClick={() => finalizeSign()}
                    >
                      <EnterpriseIcon size="sm" icon={CheckIcon} />
                    </Button>
                  </Tooltip>
                ) : null}
              </Grid.Item>
            ) : null}
          </Grid.Container>
        </div>
      </Card>
    </Grid.Item>
  )
}

export default SignOverviewCard
