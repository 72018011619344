import React, { Fragment, useEffect } from 'react'
import { Button, Grid, Modal, Tooltip } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { PlusIcon } from '@enterprise-ui/icons'
import ProjectNotesItem from './ProjectNotesItem'
import ProjectNotesForm from './ProjectNotesForm'
import { useProjectDetailsContext } from '../../../context/projectDetailsContext'
import { useProjectNotesContext } from '../context/projectNotesContext'
import { ProjectNote } from '../../../../../models/projects/ProjectNote.model'

const ProjectNotes = () => {
  const { currentProject } = useProjectDetailsContext()!
  const {
    getProjectNotes,
    isProjectNotesOpen,
    setIsProjectNotesOpen,
    isProjectNotesFormOpen,
    setIsProjectNotesFormOpen,
  } = useProjectNotesContext()!

  useEffect(() => {
    currentProject.project_id && getProjectNotes(currentProject.project_id)
  }, [getProjectNotes, currentProject.project_id])

  return (
    <Fragment>
      <Modal
        headingText="Project Notes"
        isVisible={isProjectNotesOpen}
        onRefuse={() => {
          setIsProjectNotesOpen(false)
          setIsProjectNotesFormOpen(false)
        }}
      >
        <Grid.Container className="sem_NotesTab">
          <Grid.Item xs={12}>
            <Grid.Container
              className="sem_NotesContainer"
              align="center"
              justify="space-between"
            >
              <Grid.Item>
                {currentProject.workflow.status !== 'Archive' &&
                  currentProject.workflow.status !== 'Cancel' && (
                    <Tooltip location="bottom" content="Add a new note.">
                      <Button
                        aria-label="Add new project note"
                        type="ghost"
                        iconOnly
                        className={`sem_NotesBtnAdd ${
                          isProjectNotesOpen ? 'open' : ''
                        }`}
                        onClick={() =>
                          setIsProjectNotesFormOpen(!isProjectNotesFormOpen)
                        }
                      >
                        <EnterpriseIcon icon={PlusIcon} />
                      </Button>
                    </Tooltip>
                  )}
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
          {isProjectNotesFormOpen && (
            <Grid.Item xs={12}>
              <ProjectNotesForm />
            </Grid.Item>
          )}
          {currentProject.notes && (
            <Grid.Item className="sem_NotesItem" xs>
              {currentProject.notes.map((note: ProjectNote, index: number) => (
                <ProjectNotesItem
                  projectId={currentProject.project_id}
                  note={note}
                  key={index}
                />
              ))}
            </Grid.Item>
          )}
        </Grid.Container>
      </Modal>
    </Fragment>
  )
}

export default ProjectNotes
