import { Column } from 'ag-grid-community'
import { truncate } from 'utils/string'
import React, { useState } from 'react'
import AvatarList, {
  AvatarPopover,
  ResizableColWidthContainer,
} from './AvatarList'

export interface Props {
  value: string[]
  column: Column
}

const DepartmentsChips = ({ value = [], column }: Props) => {
  const [width, setWidth] = useState(column.getActualWidth() - 20)
  const renderItem = (val: string) => <span>{truncate(val, 6)}</span>

  return (
    <AvatarPopover value={value} renderItem={renderItem} chip>
      <ResizableColWidthContainer column={column} onSizeChange={setWidth}>
        <AvatarList value={value} chip style={{ width }}>
          {renderItem}
        </AvatarList>
      </ResizableColWidthContainer>
    </AvatarPopover>
  )
}

export default DepartmentsChips
