import React, { Fragment, useEffect, useState } from 'react'
import {
  Grid,
  ExpandableSection,
  Heading,
  Divider,
  Form,
  Button,
  Tooltip,
  Input,
} from '@enterprise-ui/canvas-ui-react'
import moment from 'moment'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { useSignDetailsContext } from '../context/signDetailsContext'
import {
  NEW_SIGN,
  SIGN_DETAILS_SECTION,
  ISM_STRATEGIES,
  SIGN_STATUS_OPTION,
} from '../constants/signDetailsConstants'
import { POST_KIT_FINALIZATION } from '../../App/constants/appConstants'
import { DropdownOption } from '../../../models/app/DropdownOption.model'
import { Department } from '../../../models/merchandise/hierarchy/Department.model'
import { getVendorOptions } from '../../App/helpers/vendorHelpers'
import { SignFacet } from '../../../models/signs/SignFacetsResponse.model'
import { get } from 'lodash'
import { useUserContext } from '../../App/context/userContext'
import { useSignFormsContext } from '../context/signFormsContext'
import { useAppContext } from '../../App/context/appContext'
import {
  getSignItemStatusChipColor,
  getSignStatusChipColor,
} from '../../App/helpers/statusCalculation'
import PogModal from './modals/PogModal'
import {
  fieldIsEditable,
  isSaveButtonShown,
} from '../../App/helpers/signEditHelper'
import { deptSelectionOptions } from '../helpers/signInfoHelpers'
import { DialogProps } from '../../../models/app/DialogProps.model'
import EnterpriseIcon, {
  OverflowMenuHorizontalIcon,
} from '@enterprise-ui/icons'
import RubixChip from 'components/common/RubixChip'
import { ContractCore } from '../../../models/contracts/ContractCore.model'
import SignTemplateResponse from '../../../models/signTemplates/SignTemplateResponse.model'
import { useEnv } from '@praxis/component-runtime-env'
import { SignTemplateInfo } from '../../../models/signs/SignResponse.model'

const SignInfoSection = () => {
  const env = useEnv()
  const {
    setPageHasChanges,
    setDialogProps,
    getPogCategoryList,
    pogCategoryOptions,
  } = useAppContext()!
  const { userInfo } = useUserContext()!
  const {
    isInProject,
    currentSign,
    signFacets,
    refreshSignFacets,
    expandedSection,
    setExpandedSection,
    setOpenSidebar,
    modifiedSections,
    addToModifiedSections,
    removeFromModifiedSections,
    setRequiredFieldsChanged,
    pogs,
    getPogs,
    isPogModalLoading,
    setShowReasonCodeModal,
    setActionType,
    editReasonRequired,
    project,
    signTemplates,
    getSignTemplatesByType,
  } = useSignDetailsContext()!
  const { signInfoFormik } = useSignFormsContext()!
  const [isPogModalOpen, setIsPogModalOpen] = useState(false)
  const [onPogUpdated, setOnPogUpdated] = useState(false)
  const [signTemplateOptions, setSignTemplateOptions] = useState<
    DropdownOption<string>[]
  >([])
  const [signSizeOptions, setSignSizeOptions] = useState<
    DropdownOption<string>[]
  >([])
  const [signDefinitionOptions, setSignDefinitionOptions] = useState<
    DropdownOption<string>[]
  >([])
  const { includePogs, excludePogs, activeContracts } = useSignDetailsContext()!

  useEffect(() => {
    refreshSignFacets()
  }, [refreshSignFacets])

  useEffect(() => {
    if (signInfoFormik.values.sign_type) {
      getSignTemplatesByType(signInfoFormik.values.sign_type)
    }
  }, [signInfoFormik.values.sign_type, getSignTemplatesByType])

  useEffect(() => {
    getPogCategoryList()
  }, [getPogCategoryList])

  useEffect(() => {
    const templateOptions: DropdownOption<string>[] = signTemplates.map(
      (signTemplate: SignTemplateResponse) =>
        new DropdownOption({
          value: signTemplate.id,
          label: signTemplate.name,
          id: signTemplate.id,
        }),
    )
    if (signInfoFormik.values.sign_template_info.template_id === 'NA') {
      templateOptions.push(
        new DropdownOption({
          value: 'NA',
          label: 'NA',
          id: 'na',
        }),
      )
    }
    setSignTemplateOptions(templateOptions)
  }, [
    signInfoFormik.values.sign_type,
    signTemplates,
    signInfoFormik.values.sign_template_info.template_id,
  ])

  useEffect(() => {
    if (signInfoFormik.values.sign_type !== null) {
      const filteredOptions: DropdownOption<string>[] = activeContracts
        .filter(
          (contract: ContractCore) =>
            contract.signType === signInfoFormik.values.sign_type,
        )
        .map((contract: ContractCore, index: number) => {
          return new DropdownOption({
            id: index,
            value: contract.signSize,
            label: contract.signSize,
          })
        })
      filteredOptions.push(
        new DropdownOption({
          id: 'CUSTOM',
          value: 'CUSTOM',
          label: 'CUSTOM',
        }),
      )
      setSignSizeOptions(filteredOptions)
    }
  }, [activeContracts, signInfoFormik.values.sign_type])

  useEffect(() => {
    if (signInfoFormik.values.sign_size !== null) {
      const filteredOptions: DropdownOption<string>[] = activeContracts
        .filter(
          (contract: ContractCore) =>
            contract.signSize === signInfoFormik.values.sign_size &&
            contract.signType === signInfoFormik.values.sign_type,
        )
        .map((contract: ContractCore, index: number) => {
          return new DropdownOption({
            id: index,
            value: contract.signDefinition,
            label: contract.signDefinition,
          })
        })
      if (signInfoFormik.values.sign_size === 'CUSTOM') {
        filteredOptions.push(
          new DropdownOption<string>({
            id: 'CUSTOM',
            value: 'CUSTOM',
            label: 'CUSTOM',
          }),
        )
      }
      filteredOptions.push(
        new DropdownOption<string>({
          id: 'Initiate',
          value: 'Initiate',
          label: 'Initiate',
        }),
      )
      setSignDefinitionOptions(filteredOptions)
    }
  }, [
    activeContracts,
    signInfoFormik.values.sign_type,
    signInfoFormik.values.sign_size,
  ])

  const binarySelect = [
    new DropdownOption({ value: false, label: 'No' }),
    new DropdownOption({ value: true, label: 'Yes' }),
  ]

  const isDisabled = (field: string) =>
    !fieldIsEditable(
      signInfoFormik.values,
      field,
      userInfo,
      env.beskarFeature,
      project,
    )

  const dimensionFieldHintText = (value: number | undefined) => {
    if (currentSign.sign_id !== NEW_SIGN) {
      if (!value) {
        return 'Required for sign finalization.'
      } else if (value <= 0) {
        return 'Must be greater than 0.'
      }
    }
    return ''
  }

  const dimensionFieldHighlight = (value: number | undefined) => {
    if (currentSign.sign_id !== NEW_SIGN) {
      if (!value || value <= 0) {
        return true
      }
    }
    return false
  }

  const handleSignTypes = (id: string, singFacets: any) => {
    const excludedTypes = [
      'Operational',
      'Shipper/Bin',
      'Tag',
      'Endcap Header',
      'Inline Header',
      'Fin',
    ]

    const filteredSignTypes = signFacets.sign_types.filter(
      (signType: any) => !excludedTypes.includes(signType.value),
    )
    return filteredSignTypes
  }

  const handleChange = (id: string, value: any) => {
    if (id === 'spt_sign_on_pog' || id === 'on_pog_sign') {
      setOnPogUpdated(true)
    } else {
      setOnPogUpdated(false)
    }
    if (id === 'sign_size') {
      if (value === 'CUSTOM') {
        signInfoFormik.setFieldValue('sign_definition', 'CUSTOM')
        setContractFields('CUSTOM')
      } else {
        signInfoFormik.setFieldValue('sign_definition', '')
      }
    }
    addToModifiedSections(SIGN_DETAILS_SECTION.INFO)
    signInfoFormik.setFieldValue(id, value)
    setPageHasChanges(true)
  }

  const handleCancel = () => {
    if (currentSign.sign_id === NEW_SIGN) {
      console.log('TEST ===> Cancel New Sign Creation...')
    } else {
      removeFromModifiedSections(SIGN_DETAILS_SECTION.INFO)
      setRequiredFieldsChanged(false)
      signInfoFormik.setValues(signInfoFormik.initialValues)
      setPageHasChanges(false)
    }
  }

  const handleSave = () => {
    if (
      editReasonRequired &&
      !(
        onPogUpdated &&
        !POST_KIT_FINALIZATION.includes(currentSign.workflow.status)
      )
    ) {
      setActionType('Info Edit')
      setShowReasonCodeModal(true)
    } else {
      signInfoFormik.handleSubmit()
    }
  }

  const setTemplateDimensions = (templateId?: string) => {
    const currentTemplate = signTemplates.find(
      (template: SignTemplateResponse) => template.id === templateId,
    )
    signInfoFormik.setFieldValue(
      'non_retail_item_info.item_dimensions.height',
      templateId ? currentTemplate!.finished_length : '',
    )
    signInfoFormik.setFieldValue(
      'non_retail_item_info.item_dimensions.width',
      templateId ? currentTemplate!.finished_width : '',
    )
    signInfoFormik.setFieldValue(
      'non_retail_item_info.item_dimensions.depth',
      templateId ? currentTemplate!.finished_depth : '',
    )
    signInfoFormik.setFieldValue(
      'non_retail_item_info.nominal_dimensions.height',
      templateId ? currentTemplate!.flat_length : '',
    )
    signInfoFormik.setFieldValue(
      'non_retail_item_info.nominal_dimensions.width',
      templateId ? currentTemplate!.flat_width : '',
    )
    signInfoFormik.setFieldValue(
      'non_retail_item_info.nominal_dimensions.depth',
      templateId ? currentTemplate!.flat_depth : '',
    )
  }

  const setContractFields = (signDefinition?: string) => {
    if (signDefinition === 'Initiate') {
      signInfoFormik.setFieldValue(
        'non_retail_item_info.item_dimensions.height',
        1,
      )
      signInfoFormik.setFieldValue(
        'non_retail_item_info.item_dimensions.width',
        1,
      )
      signInfoFormik.setFieldValue(
        'non_retail_item_info.item_dimensions.depth',
        1,
      )
      signInfoFormik.setFieldValue(
        'non_retail_item_info.nominal_dimensions.height',
        1,
      )
      signInfoFormik.setFieldValue(
        'non_retail_item_info.nominal_dimensions.width',
        1,
      )
      signInfoFormik.setFieldValue(
        'non_retail_item_info.nominal_dimensions.depth',
        1,
      )
      signInfoFormik.setFieldValue('sign_template_info.source_id', 'NA')
      signInfoFormik.setFieldValue('sign_template_info.dieline_name', null)
      signInfoFormik.setFieldValue('printing.planned_sides', null)
      signInfoFormik.setFieldValue('printing.substrate1', null)
      signInfoFormik.setFieldValue('printing.substrate2', null)
      signInfoFormik.setFieldValue('printing.finishing', null)
      signInfoFormik.setFieldValue('printing.die_cut', null)
      signInfoFormik.setFieldValue('sign_template_info.template_id', 'NA')
    } else if (signDefinition === 'CUSTOM') {
      signInfoFormik.setFieldValue('sign_template_info.template_id', 'custom')
      signInfoFormik.setFieldValue(
        'non_retail_item_info.item_dimensions.height',
        '',
      )
      signInfoFormik.setFieldValue(
        'non_retail_item_info.item_dimensions.width',
        '',
      )
      signInfoFormik.setFieldValue(
        'non_retail_item_info.item_dimensions.depth',
        '',
      )
      signInfoFormik.setFieldValue(
        'non_retail_item_info.nominal_dimensions.height',
        '',
      )
      signInfoFormik.setFieldValue(
        'non_retail_item_info.nominal_dimensions.width',
        '',
      )
      signInfoFormik.setFieldValue(
        'non_retail_item_info.nominal_dimensions.depth',
        '',
      )
      signInfoFormik.setFieldValue('sign_template_info.source_id', 'custom')
      signInfoFormik.setFieldValue('sign_template_info.dieline_name', 'custom')
      signInfoFormik.setFieldValue('printing.planned_sides', null)
      signInfoFormik.setFieldValue('printing.substrate1', null)
      signInfoFormik.setFieldValue('printing.substrate2', null)
      signInfoFormik.setFieldValue('printing.finishing', null)
      signInfoFormik.setFieldValue('printing.die_cut', null)
    } else {
      const currentContract = activeContracts.find(
        (contract: ContractCore) =>
          contract.signDefinition === signDefinition &&
          contract.signType === signInfoFormik.values.sign_type &&
          contract.signSize === signInfoFormik.values.sign_size,
      )
      signInfoFormik.setFieldValue(
        'sign_template_info.template_id',
        signDefinition ? currentContract!.sourceId : '',
      )
      signInfoFormik.setFieldValue(
        'non_retail_item_info.item_dimensions.height',
        signDefinition ? currentContract!.finishedHeight : '',
      )
      signInfoFormik.setFieldValue(
        'non_retail_item_info.item_dimensions.width',
        signDefinition ? currentContract!.finishedWidth : '',
      )
      signInfoFormik.setFieldValue(
        'non_retail_item_info.item_dimensions.depth',
        signDefinition ? currentContract!.finishedDepth : '',
      )
      signInfoFormik.setFieldValue(
        'non_retail_item_info.nominal_dimensions.height',
        signDefinition ? currentContract!.flatHeight : '',
      )
      signInfoFormik.setFieldValue(
        'non_retail_item_info.nominal_dimensions.width',
        signDefinition ? currentContract!.flatWidth : '',
      )
      signInfoFormik.setFieldValue(
        'non_retail_item_info.nominal_dimensions.depth',
        signDefinition ? currentContract!.flatDepth : '',
      )
      signInfoFormik.setFieldValue(
        'printing.planned_sides',
        signDefinition ? currentContract!.sides : '',
      )
      signInfoFormik.setFieldValue(
        'printing.substrate1',
        signDefinition ? currentContract!.substrate1 : '',
      )
      signInfoFormik.setFieldValue(
        'printing.substrate2',
        signDefinition ? currentContract!.substrate2 : '',
      )
      signInfoFormik.setFieldValue(
        'printing.finishing',
        signDefinition ? currentContract!.finishing : '',
      )
      signInfoFormik.setFieldValue(
        'printing.die_cut',
        signDefinition ? currentContract!.dieCut : '',
      )
      signInfoFormik.setFieldValue(
        'sign_template_info.source_id',
        signDefinition ? currentContract!.sourceId : '',
      )
      signInfoFormik.setFieldValue(
        'sign_template_info.dieline_name',
        signDefinition ? currentContract!.dielineName : '',
      )
    }
  }

  const handleTypeUpdateOld = (id: string, value: string) => {
    if (
      signInfoFormik.values.sign_template_info.template_id !== null &&
      signInfoFormik.values.sign_template_info.template_id !== '' &&
      signInfoFormik.values.sign_template_info.template_id !== 'NA'
    ) {
      setDialogProps({
        headingText: 'Please re-select a sign template.',
        approveButtonText: 'Ok',
        onApprove: () => {
          handleChange(id, value)
          signInfoFormik.setFieldValue('sign_template_info.template_id', null)
          setTemplateDimensions(undefined)
          setOpenSidebar('')
          setRequiredFieldsChanged(true)
          setDialogProps(new DialogProps())
        },
        onRefuse: () => {
          handleChange(id, value)
          signInfoFormik.setFieldValue('sign_template_info.template_id', null)
          setTemplateDimensions(undefined)
          setOpenSidebar('')
          setRequiredFieldsChanged(true)
          setDialogProps(new DialogProps())
        },
      })
    } else {
      handleChange(id, value)
      setOpenSidebar('')
      setRequiredFieldsChanged(true)
    }
  }

  const handleTypeUpdate = (id: string, value: string) => {
    if (
      signInfoFormik.values.sign_size !== null &&
      signInfoFormik.values.sign_size !== '' &&
      signInfoFormik.values.sign_size !== 'NA'
    ) {
      setDialogProps({
        headingText: 'Please re-select a sign size and sign definition.',
        approveButtonText: 'Ok',
        onApprove: () => {
          handleChange(id, value)
          setSignSizeOptions([])
          setSignDefinitionOptions([])
          signInfoFormik.setFieldValue('sign_size', '')
          signInfoFormik.setFieldValue('sign_definition', '')
          setContractFields(undefined)
          setOpenSidebar('')
          setRequiredFieldsChanged(true)
          setDialogProps(new DialogProps())
        },
        onRefuse: () => {
          handleChange(id, value)
          setSignSizeOptions([])
          setSignDefinitionOptions([])
          signInfoFormik.setFieldValue('sign_size', '')
          signInfoFormik.setFieldValue('sign_definition', '')
          setContractFields(undefined)
          setOpenSidebar('')
          setRequiredFieldsChanged(true)
          setDialogProps(new DialogProps())
        },
      })
    } else {
      handleChange(id, value)
      setOpenSidebar('')
      setRequiredFieldsChanged(true)
    }
  }

  return (
    <Fragment>
      <Grid.Item className="hc-pv-none hc-ph-normal" xs={12}>
        <ExpandableSection
          expanded={expandedSection === SIGN_DETAILS_SECTION.INFO}
          padding="none"
          className={`sem_BlueprintFormCard ${
            modifiedSections.includes(SIGN_DETAILS_SECTION.INFO)
              ? 'modified'
              : ''
          }`}
          onExpand={() =>
            setExpandedSection(
              expandedSection === SIGN_DETAILS_SECTION.INFO
                ? ''
                : SIGN_DETAILS_SECTION.INFO,
            )
          }
        >
          <Grid.Container justify="space-between" align="center">
            <Grid.Item xs={12}>
              <Heading className="hc-clr-grey01 hc-pl-normal" size={5}>
                Sign Information
              </Heading>
            </Grid.Item>
          </Grid.Container>
          <ExpandableSection.Content>
            <Divider className="hc-pa-dense" />
            <div className="hc-pa-dense">
              <Grid.Container
                align="center"
                className={`sem_StandardForm hc-mb-normal${
                  isInProject ? ' error_messaging_enabled' : ''
                }`}
              >
                <Grid.Item xs={3}>
                  <Form.Field
                    type="select"
                    id="sign_type"
                    label="Sign Type"
                    value={signInfoFormik.values.sign_type}
                    error={signInfoFormik.errors.sign_type !== undefined}
                    errorText={signInfoFormik.errors.sign_type}
                    highlight={!signInfoFormik.values.sign_type}
                    disabled={isDisabled('sign_type')}
                    required
                    options={handleSignTypes(currentSign.sign_id, signFacets)}
                    onUpdate={(id: string, value: string) =>
                      env.beskarFeature
                        ? handleTypeUpdate(id, value)
                        : handleTypeUpdateOld(id, value)
                    }
                  />
                </Grid.Item>
                <Grid.Item xs={3}>
                  {env.beskarFeature ? (
                    <Autocomplete
                      id="sign_size"
                      label="Sign Size"
                      value={{
                        value: signInfoFormik.values.sign_size,
                        label: signInfoFormik.values.sign_size,
                      }}
                      error={signInfoFormik.errors.sign_size !== undefined}
                      errorText={signInfoFormik.errors.sign_size}
                      highlight={!signInfoFormik.values.sign_size}
                      disabled={
                        isDisabled('sign_size') ||
                        !signInfoFormik.values.sign_type
                      }
                      required
                      options={signSizeOptions}
                      onUpdate={(id: string, value: any) => {
                        if (value) {
                          handleChange(id, value.value)
                          setRequiredFieldsChanged(true)
                        }
                      }}
                    />
                  ) : (
                    <Autocomplete
                      id="sign_template_info.template_id"
                      label="Standard Sign"
                      value={{
                        value:
                          signInfoFormik.values.sign_template_info.template_id,
                        label:
                          signInfoFormik.values.sign_template_info
                            .template_name,
                      }}
                      error={
                        signInfoFormik.errors.sign_template_info !==
                          undefined &&
                        signInfoFormik.errors.sign_template_info!
                          .template_id !== undefined
                      }
                      errorText={
                        signInfoFormik.errors.sign_template_info !== undefined
                          ? signInfoFormik.errors.sign_template_info.template_id
                          : ''
                      }
                      highlight={
                        !signInfoFormik.values.sign_template_info.template_id
                      }
                      disabled={
                        isDisabled('sign_template_info.template_id') ||
                        !signInfoFormik.values.sign_type
                      }
                      required
                      options={signTemplateOptions}
                      onUpdate={(id: string, value: any) => {
                        if (value) {
                          const template = signTemplates.find(
                            (temp: any) => temp.id === value.value,
                          )
                          handleChange(
                            'sign_template_info',
                            new SignTemplateInfo({
                              template_id: template!.id,
                              template_name: template!.name,
                              size_editable: template!.size_editable,
                            }),
                          )
                          setTemplateDimensions(value.value)
                          setRequiredFieldsChanged(true)
                        }
                      }}
                    />
                  )}
                </Grid.Item>
                {env.beskarFeature ? (
                  <Grid.Item xs={3}>
                    <Autocomplete
                      id="sign_definition"
                      disabled={
                        isDisabled('sign_definition') ||
                        !signInfoFormik.values.sign_size
                      }
                      hintText={
                        signInfoFormik.values.sign_definition === null
                          ? 'Required for DPCI creation'
                          : ''
                      }
                      label="Sign Definition"
                      highlight={!signInfoFormik.values.sign_definition}
                      onUpdate={(id: string, value: any) => {
                        if (value) {
                          handleChange(id, value.value)
                          setContractFields(value.value)
                          setRequiredFieldsChanged(true)
                        }
                      }}
                      options={signDefinitionOptions}
                      required
                      value={{
                        value: signInfoFormik.values.sign_definition,
                        label: signInfoFormik.values.sign_definition,
                      }}
                    />
                  </Grid.Item>
                ) : null}
                <Grid.Item xs={3}>
                  <Form.Field
                    id="description"
                    label="Sign Description"
                    value={signInfoFormik.values.description}
                    error={signInfoFormik.errors.description !== undefined}
                    errorText={signInfoFormik.errors.description}
                    highlight={!signInfoFormik.values.description}
                    disabled={isDisabled('sign_description')}
                    required
                    onChange={(e: any) => {
                      handleChange(e.target.id, e.target.value)
                      setRequiredFieldsChanged(true)
                    }}
                  />
                </Grid.Item>
                <Grid.Item xs={3}>
                  <Form.Field
                    id="brand"
                    label="Brand/Category"
                    value={signInfoFormik.values.brand || ''}
                    onChange={(e: any) =>
                      handleChange(e.target.id, e.target.value)
                    }
                    disabled={isDisabled('brand')}
                  />
                </Grid.Item>
                <Grid.Item className="hc-mt-expanded" xs={6}>
                  <Grid.Container align="center">
                    <Grid.Item xs={8}>
                      {signInfoFormik.values.sign_status === 'New' ? (
                        <Fragment>
                          <Input.Label className="sign-info-status">
                            Sign Status
                          </Input.Label>
                          <RubixChip
                            color={getSignStatusChipColor(
                              signInfoFormik.values.sign_status,
                            )}
                          >
                            {currentSign.sign_status}
                          </RubixChip>
                        </Fragment>
                      ) : (
                        <Fragment>
                          <Form.Field
                            type="select"
                            id="sign_status"
                            label="Sign Status"
                            value={signInfoFormik.values.sign_status}
                            error={
                              signInfoFormik.errors.sign_status !== undefined
                            }
                            errorText={signInfoFormik.errors.sign_status}
                            highlight={!signInfoFormik.values.sign_status}
                            disabled={isDisabled('sign_status')}
                            options={SIGN_STATUS_OPTION}
                            onUpdate={(id: string, value: string) => {
                              handleChange(id, value)
                              setOpenSidebar('')
                              setRequiredFieldsChanged(true)
                            }}
                          />
                        </Fragment>
                      )}
                    </Grid.Item>
                    <Grid.Item xs={4} className="hc-pt-none">
                      <Form.Field
                        id="on_pog_sign"
                        type="checkbox"
                        label="On POG Sign"
                        checked={signInfoFormik.values.on_pog_sign}
                        onChange={(e: any) => {
                          handleChange(e.target.id, e.target.checked)
                        }}
                        disabled={isDisabled('on_pog_sign')}
                      />
                    </Grid.Item>
                  </Grid.Container>
                </Grid.Item>
                <Grid.Item className="hc-mt-expanded" xs={2}>
                  <Input.Label className="sign-info-status">
                    Sign Item Status
                  </Input.Label>
                  <RubixChip
                    color={getSignItemStatusChipColor(
                      signInfoFormik.values.non_retail_item_info
                        .sign_item_status,
                    )}
                  >
                    {get(
                      currentSign,
                      'non_retail_item_info.sign_item_status',
                      '',
                    )}
                  </RubixChip>
                </Grid.Item>
                <Grid.Item xs={2}>
                  <Tooltip
                    location="right"
                    content="End Date is only editable for signs with status 'Remove'"
                  >
                    {' '}
                    <Form.Field
                      type="date"
                      id="non_retail_item_info.end_date"
                      placeholder={''}
                      label="End Date"
                      value={
                        signInfoFormik.values.non_retail_item_info.end_date
                          ? moment(
                              signInfoFormik.values.non_retail_item_info
                                .end_date,
                            ).format('YYYY-MM-DD')
                          : ''
                      }
                      onChange={(e: any) =>
                        handleChange(e.target.id, e.target.value)
                      }
                      disabled={isDisabled('end_date')}
                    />
                  </Tooltip>
                </Grid.Item>
                <Grid.Item xs={2}>
                  <Form.Field
                    type="date"
                    id="set_date"
                    label="Original Set Date"
                    value={moment(currentSign.original_set_date).format(
                      'MM/DD/YYYY',
                    )}
                    onChange={() => {}}
                    disabled
                    location="bottom-left"
                  />
                </Grid.Item>
                <Grid.Item xs={2}>
                  <Form.Field
                    id="dpci"
                    label="Sign DPCI #"
                    value={signInfoFormik.values.non_retail_item_info.dpci}
                    hintText={
                      currentSign.sign_id !== NEW_SIGN &&
                      !signInfoFormik.values.non_retail_item_info.dpci
                        ? 'Must be assigned for sign finalization.'
                        : ''
                    }
                    highlight={
                      currentSign.sign_id !== NEW_SIGN &&
                      !signInfoFormik.values.non_retail_item_info.dpci
                    }
                    onChange={() => {}}
                    disabled
                  />
                </Grid.Item>
                <Grid.Item xs={2}>
                  <Form.Field
                    id="signUpc"
                    label="Sign UPC"
                    value={signInfoFormik.values.non_retail_item_info.upc}
                    onChange={() => {}}
                    disabled
                  />
                </Grid.Item>
                <Grid.Item xs={env.beskarFeature ? 4 : 3}>
                  <Form.Field
                    type="select"
                    id="sign_creator"
                    label="Creation/Production Owner"
                    value={signInfoFormik.values.sign_creator}
                    hintText={
                      currentSign.sign_id !== NEW_SIGN &&
                      !signInfoFormik.values.sign_creator
                        ? 'Please select Creation/Production Owner for sign finalization.'
                        : ''
                    }
                    highlight={
                      currentSign.sign_id !== NEW_SIGN &&
                      !signInfoFormik.values.sign_creator
                    }
                    onUpdate={(e: any, value: string) => {
                      handleChange('sign_creator', value)
                      setRequiredFieldsChanged(true)
                    }}
                    options={signFacets.sign_creator.map(
                      (signCreator: SignFacet) =>
                        new DropdownOption<string>({
                          id: signCreator.value,
                          value: signCreator.value,
                          label: signCreator.label,
                        }),
                    )}
                    required
                    disabled={isDisabled('sign_creator')}
                  />
                </Grid.Item>
                <Grid.Item xs={env.beskarFeature ? 4 : 5}>
                  <Autocomplete
                    id="non_retail_item_info.product_vendor"
                    label="Product Vendor"
                    options={(query: string) =>
                      getVendorOptions(
                        query,
                        signInfoFormik.values.non_retail_item_info
                          .product_vendor,
                      )
                    }
                    value={{
                      value:
                        signInfoFormik.values.non_retail_item_info
                          .product_vendor,
                      label:
                        signInfoFormik.values.non_retail_item_info
                          .product_vendor,
                    }}
                    onUpdate={(id: string, value: any) => {
                      if (
                        !value &&
                        signInfoFormik.values.non_retail_item_info
                          .product_vendor !== ''
                      ) {
                        handleChange(id, '')
                      } else if (
                        value &&
                        value.value !==
                          signInfoFormik.values.non_retail_item_info
                            .product_vendor
                      ) {
                        handleChange(id, value.value)
                      }
                    }}
                    disabled={isDisabled('product_vendor')}
                  />
                </Grid.Item>
                <Grid.Item xs={env.beskarFeature ? 3 : 4}>
                  <Form.Field
                    type="select"
                    id="department"
                    label="Department"
                    value={signInfoFormik.values.department.department_id}
                    hintText={
                      currentSign.sign_id !== NEW_SIGN &&
                      !signInfoFormik.values.department.department_id
                        ? 'Please select a Department for sign finalization.'
                        : ''
                    }
                    highlight={
                      currentSign.sign_id !== NEW_SIGN &&
                      !signInfoFormik.values.department.department_id
                    }
                    options={deptSelectionOptions(
                      signFacets.departments,
                      currentSign.department,
                    )}
                    required
                    onUpdate={(id: string, value: number) => {
                      const selected = signFacets.departments.filter(
                        (department: Department) =>
                          department.department_id === value,
                      )
                      handleChange(id, selected[0])
                      setRequiredFieldsChanged(true)
                    }}
                    disabled={isDisabled('department')}
                  />
                </Grid.Item>
                <Grid.Item xs={env.beskarFeature ? 3 : 4}>
                  <Form.Field
                    type="select"
                    id="unit_of_measure"
                    label="Unit of Measure"
                    value={signInfoFormik.values.unit_of_measure}
                    hintText={
                      currentSign.sign_id !== NEW_SIGN &&
                      !signInfoFormik.values.unit_of_measure
                        ? 'Select a unit of measure for sign finalization.'
                        : ''
                    }
                    highlight={
                      currentSign.sign_id !== NEW_SIGN &&
                      !signInfoFormik.values.unit_of_measure
                    }
                    options={[
                      { value: 'EA', label: 'Each' },
                      { value: 'ST', label: 'Set' },
                      { value: 'KT', label: 'Kit' },
                    ]}
                    onUpdate={(id: string, value: string) => {
                      handleChange(id, value)
                      setRequiredFieldsChanged(true)
                    }}
                    required
                    disabled={isDisabled('unit_of_measure')}
                  />
                </Grid.Item>
                <Grid.Item xs={env.beskarFeature ? 2 : 4}>
                  <Form.Field
                    type="number"
                    id="set_quantity"
                    label="Quantity per Set"
                    error={signInfoFormik.errors.set_quantity !== undefined}
                    errorText={signInfoFormik.errors.set_quantity}
                    value={
                      signInfoFormik.values.unit_of_measure === 'ST'
                        ? signInfoFormik.values.set_quantity || ''
                        : ''
                    }
                    hintText={
                      currentSign.sign_id !== NEW_SIGN &&
                      signInfoFormik.values.unit_of_measure === 'ST' &&
                      !signInfoFormik.values.set_quantity
                        ? 'A Set Quantity of greater than 0 is required before the sign can be finalized.'
                        : ''
                    }
                    highlight={
                      currentSign.sign_id !== NEW_SIGN &&
                      signInfoFormik.values.unit_of_measure === 'ST' &&
                      !signInfoFormik.values.set_quantity
                    }
                    onChange={(e: any) => {
                      handleChange(e.target.id, parseInt(e.target.value))
                      setRequiredFieldsChanged(true)
                    }}
                    required={signInfoFormik.values.unit_of_measure === 'ST'}
                    disabled={isDisabled('set_quantity')}
                  />
                </Grid.Item>
                <Grid.Item xs={2}>
                  <Tooltip location="right" content="Left to Right">
                    <Form.Field
                      type="number"
                      id="non_retail_item_info.item_dimensions.width"
                      label="Finished Width"
                      disabled={isDisabled('finished_width')}
                      value={
                        signInfoFormik.values.sign_size === '1x1'
                          ? 1
                          : signInfoFormik.values.non_retail_item_info
                              .item_dimensions.width
                      }
                      hintText={dimensionFieldHintText(
                        signInfoFormik.values.non_retail_item_info
                          .item_dimensions.width,
                      )}
                      highlight={dimensionFieldHighlight(
                        signInfoFormik.values.non_retail_item_info
                          .item_dimensions.width,
                      )}
                      onChange={(e: any) => {
                        handleChange(e.target.id, e.target.value)
                        setRequiredFieldsChanged(true)
                      }}
                      required
                    />
                  </Tooltip>
                </Grid.Item>
                <Grid.Item xs={2}>
                  <Tooltip location="right" content="Top to Bottom">
                    <Form.Field
                      type="number"
                      id="non_retail_item_info.item_dimensions.height"
                      label="Finished Height"
                      disabled={isDisabled('finished_height')}
                      value={
                        signInfoFormik.values.sign_size === '1x1'
                          ? 1
                          : signInfoFormik.values.non_retail_item_info
                              .item_dimensions.height
                      }
                      hintText={dimensionFieldHintText(
                        signInfoFormik.values.non_retail_item_info
                          .item_dimensions.height,
                      )}
                      highlight={dimensionFieldHighlight(
                        signInfoFormik.values.non_retail_item_info
                          .item_dimensions.height,
                      )}
                      onChange={(e: any) => {
                        handleChange(e.target.id, e.target.value)
                        setRequiredFieldsChanged(true)
                      }}
                      required
                    />
                  </Tooltip>
                </Grid.Item>
                <Grid.Item xs={2}>
                  <Form.Field
                    type="number"
                    id="non_retail_item_info.item_dimensions.depth"
                    label="Finished Depth"
                    disabled={isDisabled('finished_depth')}
                    value={
                      signInfoFormik.values.sign_size === '1x1'
                        ? 1
                        : signInfoFormik.values.non_retail_item_info
                            .item_dimensions.depth
                    }
                    hintText={dimensionFieldHintText(
                      signInfoFormik.values.non_retail_item_info.item_dimensions
                        .depth,
                    )}
                    highlight={dimensionFieldHighlight(
                      signInfoFormik.values.non_retail_item_info.item_dimensions
                        .depth,
                    )}
                    onChange={(e: any) => {
                      handleChange(e.target.id, e.target.value)
                      setRequiredFieldsChanged(true)
                    }}
                    required
                  />
                </Grid.Item>
                <Grid.Item xs={2}>
                  <Tooltip location="right" content="Left to Right">
                    <Form.Field
                      type="number"
                      id="non_retail_item_info.nominal_dimensions.width"
                      label="Flat Width"
                      disabled={isDisabled('flat_width')}
                      value={
                        signInfoFormik.values.sign_size === '1x1'
                          ? 1
                          : signInfoFormik.values.non_retail_item_info
                              .nominal_dimensions.width
                      }
                      hintText={dimensionFieldHintText(
                        signInfoFormik.values.non_retail_item_info
                          .nominal_dimensions.width,
                      )}
                      highlight={dimensionFieldHighlight(
                        signInfoFormik.values.non_retail_item_info
                          .nominal_dimensions.width,
                      )}
                      onChange={(e: any) => {
                        handleChange(e.target.id, e.target.value)
                        setRequiredFieldsChanged(true)
                      }}
                      required
                    />
                  </Tooltip>
                </Grid.Item>
                <Grid.Item xs={2}>
                  <Tooltip location="right" content="Top to Bottom">
                    <Form.Field
                      type="number"
                      id="non_retail_item_info.nominal_dimensions.height"
                      label="Flat Height"
                      disabled={isDisabled('flat_height')}
                      value={
                        signInfoFormik.values.sign_size === '1x1'
                          ? 1
                          : signInfoFormik.values.non_retail_item_info
                              .nominal_dimensions.height
                      }
                      hintText={dimensionFieldHintText(
                        signInfoFormik.values.non_retail_item_info
                          .nominal_dimensions.height,
                      )}
                      highlight={dimensionFieldHighlight(
                        signInfoFormik.values.non_retail_item_info
                          .nominal_dimensions.height,
                      )}
                      onChange={(e: any) => {
                        handleChange(e.target.id, e.target.value)
                        setRequiredFieldsChanged(true)
                      }}
                      required
                    />
                  </Tooltip>
                </Grid.Item>
                <Grid.Item xs={2}>
                  <Form.Field
                    type="number"
                    id="non_retail_item_info.nominal_dimensions.depth"
                    label="Flat Depth"
                    disabled={isDisabled('flat_depth')}
                    value={
                      signInfoFormik.values.sign_size === '1x1'
                        ? 1
                        : signInfoFormik.values.non_retail_item_info
                            .nominal_dimensions.depth
                    }
                    hintText={dimensionFieldHintText(
                      signInfoFormik.values.non_retail_item_info
                        .nominal_dimensions.depth,
                    )}
                    highlight={dimensionFieldHighlight(
                      signInfoFormik.values.non_retail_item_info
                        .nominal_dimensions.depth,
                    )}
                    onChange={(e: any) => {
                      handleChange(e.target.id, e.target.value)
                      setRequiredFieldsChanged(true)
                    }}
                    required
                  />
                </Grid.Item>
                <Grid.Item xs={4}>
                  <Autocomplete
                    id="separator"
                    label="Separator"
                    options={(query: string) =>
                      getVendorOptions(query, signInfoFormik.values.separator)
                    }
                    value={{
                      value: signInfoFormik.values.separator,
                      label: signInfoFormik.values.separator,
                    }}
                    hintText={
                      currentSign.sign_id !== NEW_SIGN &&
                      !signInfoFormik.values.separator &&
                      signInfoFormik.values.sign_type !== 'Hardware' &&
                      signInfoFormik.values.sign_creator !==
                        'Vendor Designed/Target Produced' &&
                      signInfoFormik.values.sign_creator !==
                        'Vendor Designed/Vendor Produced'
                        ? "A Separator must be selected for sign finalization.  Choose 'Not Applicable' if Separator is not required."
                        : ''
                    }
                    highlight={
                      currentSign.sign_id !== NEW_SIGN &&
                      !signInfoFormik.values.separator &&
                      signInfoFormik.values.sign_type !== 'Hardware' &&
                      signInfoFormik.values.sign_creator !==
                        'Vendor Designed/Target Produced' &&
                      signInfoFormik.values.sign_creator !==
                        'Vendor Designed/Vendor Produced'
                    }
                    disabled={isDisabled('separator')}
                    required
                    onUpdate={(id: string, value: any) => {
                      if (
                        value &&
                        value.value !== signInfoFormik.values.separator
                      ) {
                        handleChange(id, value.value)
                      }
                    }}
                  />
                </Grid.Item>
                <Grid.Item xs={2}>
                  <Form.Field
                    type="select"
                    id="photography_needed"
                    label="Photography Needed"
                    disabled={isDisabled('photography_needed')}
                    value={signInfoFormik.values.photography_needed}
                    options={binarySelect}
                    onUpdate={(id: string, value: string) => {
                      handleChange(id, value)
                    }}
                  />
                </Grid.Item>
                <Grid.Item xs={2}>
                  <Form.Field
                    id="third_party_shipping"
                    type="checkbox"
                    label="Third Party Shipping"
                    checked={signInfoFormik.values.third_party_shipping}
                    onChange={(e: any) => {
                      handleChange(e.target.id, e.target.checked)
                    }}
                    disabled={isDisabled('third_party_shipping')}
                  />
                </Grid.Item>
                {currentSign.on_pog_sign ? (
                  <Fragment>
                    <Grid.Item xs={4}>
                      <Grid.Container align="center">
                        <Grid.Item className="hc-pr-none">
                          <Form.Field
                            id="spt_sign_on_pog"
                            type="checkbox"
                            label="SPT Sign on all POGs/POGs completed"
                            checked={signInfoFormik.values.spt_sign_on_pog}
                            onChange={(e: any) => {
                              handleChange(e.target.id, e.target.checked)
                            }}
                            disabled={isDisabled('spt_sign_on_pog')}
                          />
                        </Grid.Item>
                        <Grid.Item className="hc-pl-normal">
                          <Tooltip location="bottom" content="Show all POGs">
                            <Button
                              type="primary"
                              size="dense"
                              onClick={() => {
                                getPogs()
                                setIsPogModalOpen(true)
                              }}
                            >
                              <EnterpriseIcon
                                icon={OverflowMenuHorizontalIcon}
                              />
                            </Button>
                            <PogModal
                              pogDetails={pogs}
                              isVisible={isPogModalOpen}
                              toggleVisible={setIsPogModalOpen}
                              isPogModalLoading={isPogModalLoading}
                              currentSignId={currentSign.sign_id}
                              currentSignDPCI={
                                currentSign.non_retail_item_info.dpci
                              }
                              includePogs={includePogs}
                              excludePogs={excludePogs}
                            />
                          </Tooltip>
                        </Grid.Item>
                      </Grid.Container>
                    </Grid.Item>
                    {currentSign.sales_plan && (
                      <Fragment>
                        <Grid.Item xs={3}>
                          <Form.Field
                            id="priority"
                            label="Priority"
                            disabled={isDisabled('priority')}
                            value={signInfoFormik.values.priority}
                            onChange={(e: any) =>
                              handleChange(e.target.id, e.target.value)
                            }
                          />
                        </Grid.Item>
                        <Grid.Item xs={3}>
                          <Form.Field
                            id="endcap_type"
                            label="Endcap Type"
                            disabled={isDisabled('endcap_type')}
                            value={signInfoFormik.values.endcap_type}
                            onChange={(e: any) =>
                              handleChange(e.target.id, e.target.value)
                            }
                          />
                        </Grid.Item>
                        <Grid.Item xs={3}>
                          <Form.Field
                            id="program_name"
                            label="Program Name"
                            disabled={isDisabled('program_name')}
                            value={signInfoFormik.values.program_name}
                            onChange={(e: any) =>
                              handleChange(e.target.id, e.target.value)
                            }
                          />
                        </Grid.Item>
                        <Grid.Item xs={3}>
                          <Form.Field
                            type="select"
                            id="ism_strategy"
                            label="ISM Strategy"
                            disabled={isDisabled('ism_strategy')}
                            value={signInfoFormik.values.ism_strategy}
                            onUpdate={(id: string, value: string) =>
                              handleChange(id, value)
                            }
                            options={ISM_STRATEGIES}
                          />
                        </Grid.Item>
                      </Fragment>
                    )}
                  </Fragment>
                ) : null}
                <Grid.Item xs={4}>
                  <Autocomplete
                    id="pog_categories"
                    disabled={isDisabled('pog_categories')}
                    label="POG Categories"
                    multiselect
                    options={pogCategoryOptions}
                    value={signInfoFormik.values.pog_categories?.map(
                      (cat: any) => {
                        return {
                          id: cat.pog_category_id,
                          value: cat.pog_category_id,
                          label: cat.pog_category_name,
                        }
                      },
                    )}
                    onUpdate={(id: string, value: any) => {
                      if (
                        value.length > 0 &&
                        value !== signInfoFormik.values.pog_categories
                      ) {
                        handleChange(
                          id,
                          value.map((newCat: any) => {
                            return {
                              pog_category_id: newCat.value,
                              pog_category_name: newCat.label,
                            }
                          }),
                        )
                      }
                    }}
                  />
                </Grid.Item>
                <Grid.Item xs={4}>
                  <Form.Field
                    id="tie_to_product_dpci"
                    label="Tie to Product DPCI"
                    value={signInfoFormik.values.tie_to_product_dpci}
                    onChange={(e: any) =>
                      handleChange(e.target.id, e.target.value)
                    }
                  />
                </Grid.Item>
                <Grid.Item xs={4}>
                  <Form.Field
                    id="tie_to_hardware_dpci"
                    label="Tie to Hardware DPCI"
                    value={signInfoFormik.values.tie_to_hardware_dpci}
                    onChange={(e: any) =>
                      handleChange(e.target.id, e.target.value)
                    }
                  />
                </Grid.Item>
                <Grid.Item xs={4}>
                  <Form.Field
                    type="select"
                    id="placement_on_shelf"
                    label="SPT Placement on Shelf"
                    value={signInfoFormik.values.placement_on_shelf}
                    onUpdate={(id: string, value: string) =>
                      handleChange(id, value)
                    }
                    options={signFacets.placement_on_shelf.map(
                      (facet: SignFacet) => {
                        return {
                          value: facet.label,
                          label: facet.label,
                        }
                      },
                    )}
                  />
                </Grid.Item>
                <Grid.Item xs={4}>
                  <Form.Field
                    id="quantity_per_placement"
                    label="Quantity Per Placement"
                    value={signInfoFormik.values.quantity_per_placement}
                    onChange={(e: any) =>
                      handleChange(e.target.id, e.target.value)
                    }
                  />
                </Grid.Item>
                <Grid.Item xs={4}>
                  <Form.Field
                    type="select"
                    id="pog_unit_of_measure"
                    label="POG Unit of Measure"
                    value={signInfoFormik.values.pog_unit_of_measure}
                    onUpdate={(id: string, value: string) =>
                      handleChange(id, value)
                    }
                    options={signFacets.pog_unit_of_measure.map(
                      (facet: SignFacet) => {
                        return {
                          value: facet.label,
                          label: facet.label,
                        }
                      },
                    )}
                  />
                </Grid.Item>
                <Grid.Item xs={4}>
                  <Form.Field
                    id="pog_exclusions"
                    label="POG Exclusions"
                    value={signInfoFormik.values.pog_exclusions}
                    onChange={(e: any) =>
                      handleChange(e.target.id, e.target.value)
                    }
                  />
                </Grid.Item>
                {isSaveButtonShown(currentSign, isInProject) ? (
                  <Divider className="hc-pa-dense" />
                ) : null}
              </Grid.Container>
              {isSaveButtonShown(currentSign, isInProject) ? (
                <Grid.Container direction="row-reverse">
                  <Grid.Item className="hc-pb-dense hc-pt-normal">
                    <Button
                      type="primary"
                      size="dense"
                      disabled={
                        !modifiedSections.includes(SIGN_DETAILS_SECTION.INFO)
                      }
                      onClick={() => handleSave()}
                    >
                      {currentSign.sign_id === NEW_SIGN
                        ? 'Create New Sign'
                        : 'Save Changes'}
                    </Button>
                  </Grid.Item>
                  <Grid.Item>
                    <Button
                      type="secondary"
                      size="dense"
                      disabled={
                        currentSign.sign_id !== NEW_SIGN &&
                        !modifiedSections.includes(SIGN_DETAILS_SECTION.INFO)
                      }
                      onClick={() => handleCancel()}
                    >
                      {currentSign.sign_id === NEW_SIGN
                        ? 'Cancel'
                        : 'Cancel Changes'}
                    </Button>
                  </Grid.Item>
                </Grid.Container>
              ) : null}
            </div>
          </ExpandableSection.Content>
        </ExpandableSection>
      </Grid.Item>
      <Grid.Item className="hc-mv-dense" xs={12}>
        <Divider />
      </Grid.Item>
    </Fragment>
  )
}

export default SignInfoSection
