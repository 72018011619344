import React from 'react'
import { Layout } from '@enterprise-ui/canvas-ui-react'
import PageChangesWrapper from '../../App/components/PageChangesWrapper'
import AdminCampaigns from './components/AdminCampaigns'
import { AdminCampaignsProvider } from './context/adminCampaignsContext'

const AdminCampaignsContainer = (props: any) => (
  <PageChangesWrapper>
    <AdminCampaignsProvider>
      <Layout.Body className="hc-pa-expanded" includeRail>
        <AdminCampaigns {...props} />
      </Layout.Body>
    </AdminCampaignsProvider>
  </PageChangesWrapper>
)

export default AdminCampaignsContainer
