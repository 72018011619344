import { useEffect } from 'react'
import { useAppContext } from '../context/appContext'

export interface Props {
  children: any
}

const PageChangesWrapper = ({ children }: Props) => {
  const { setPageHasChanges } = useAppContext()!

  // whenever a new page loads, reset the changes flag
  useEffect(() => {
    setPageHasChanges(false)
  }, [setPageHasChanges])

  return children
}

export default PageChangesWrapper
