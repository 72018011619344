import React, { Fragment } from 'react'
import { Divider, Grid, Heading, List } from '@enterprise-ui/canvas-ui-react'
import { formattedSpecs } from '../../../helpers/signMessagingHelpers'
import { ProductSpecification } from '../../../../../models/signs/ProductSpecification.model'
import { ProductInfoResponse } from '../../../../../models/signs/ProductInfoResponse.model'

export interface Props {
  productInfo: ProductInfoResponse
}

const ProductSpecifications = ({ productInfo }: Props) => {
  const specifications =
    productInfo.product_description.bullet_descriptions.map(
      (bullet: string) => new ProductSpecification(formattedSpecs(bullet)),
    )

  return (
    <Fragment>
      <Heading className="hc-clr-grey02" size={6}>
        Product Specifications:
      </Heading>
      <Divider className="hc-pa-dense" />
      <div className="hc-pa-dense">
        <Grid.Container>
          <Grid.Item className="hc-pt-none" xs={12}>
            <List size="dense">
              {specifications.length > 0 ? (
                specifications.map((spec: any, index: number) => (
                  <List.Item
                    className="sem_ProductHighlights--ListItem"
                    key={index}
                    divider
                  >
                    <span className="sem_SpecTitle">{spec.title}</span>
                    {spec.body}
                  </List.Item>
                ))
              ) : (
                <List.Item className="sem_ProductHighlights--ListItem">
                  No Specifications to show at this time
                </List.Item>
              )}
            </List>
          </Grid.Item>
        </Grid.Container>
      </div>
    </Fragment>
  )
}

export default ProductSpecifications
