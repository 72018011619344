import React, { useEffect, useState } from 'react'
import { Grid, Heading, Button } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { NotebookIcon, SaveIcon } from '@enterprise-ui/icons'
import RosterCard from './RosterCard'
import { RosterElement } from '../../../models/roster/RosterElement.model'
import { RosterUser } from '../../../models/roster/RosterUser.model'
import { BlueprintDetail } from '../../../models/blueprints/BlueprintDetail.model'
import { useAppContext } from '../../App/context/appContext'
import { DialogProps } from '../../../models/app/DialogProps.model'
import { ProjectDetail } from '../../../models/projects/ProjectDetail.model'

export interface Props {
  currentBlueprint?: BlueprintDetail
  currentProject?: ProjectDetail
  roster?: RosterElement[]
  deleteFromRoster: (user: RosterUser, title: string) => void
  saveRoster: () => void
  hasEditPermission: boolean
  addToRoster: (user: RosterUser, role: string) => void
  resetTrigger: string
}

const EditableRoster = ({
  currentBlueprint,
  currentProject,
  roster,
  deleteFromRoster,
  saveRoster,
  hasEditPermission,
  addToRoster,
  resetTrigger,
}: Props) => {
  const [rowsChangedByTitle, setRowsChangedByTitle] = useState<string[]>([])
  const { setDialogProps } = useAppContext()!
  // if the campaign type is changed, then the rows changed should be reset
  useEffect(() => {
    setRowsChangedByTitle([])
  }, [resetTrigger])

  const handleDeleteUser = (user: RosterUser, title: string) => {
    setRowsChangedByTitle([...rowsChangedByTitle, title])
    deleteFromRoster(user, title)
    setDialogProps(new DialogProps())
  }

  const handleAddUser = (user: RosterUser, role: string) => {
    setRowsChangedByTitle([...rowsChangedByTitle, role])
    addToRoster(user, role)
  }

  return (
    <Grid.Container>
      <Grid.Item xs={12}>
        <Grid.Container spacing="dense" justify="space-between">
          <Grid.Item size="true" className="hc-ml-dense">
            <div className="sem-BpRosterBtn open">
              <Button type="ghost">
                <EnterpriseIcon icon={NotebookIcon} size="lg" />
                <Heading
                  className="hc-clr-grey02 hc-ml-normal hc-txt-capitalize"
                  size={5}
                >
                  Roster
                </Heading>
              </Button>
            </div>
          </Grid.Item>
          {hasEditPermission &&
            currentBlueprint?.status !== 'Archive' &&
            currentProject?.workflow.status !== 'Archive' &&
            currentProject?.workflow.status !== 'Cancel' && (
              <Grid.Item className="hc-mr-dense">
                <div>
                  <Button
                    aria-label="Save Roster"
                    type="ghost"
                    iconOnly
                    className="sem-BpRosterBtnSave open"
                    disabled={currentBlueprint?.blueprint_id === ''}
                    onClick={() => {
                      saveRoster()
                      setRowsChangedByTitle([])
                    }}
                  >
                    <EnterpriseIcon icon={SaveIcon} size="lg" />
                  </Button>
                </div>
              </Grid.Item>
            )}
        </Grid.Container>
      </Grid.Item>
      <Grid.Item className="sem-BpRoster open" xs={12}>
        {roster && roster.length > 0 ? (
          roster.map((rosterElement: RosterElement) => {
            return (
              <RosterCard
                rosterElement={rosterElement}
                key={rosterElement.title}
                deleteFromRoster={handleDeleteUser}
                addToRoster={handleAddUser}
                isRowChanged={rowsChangedByTitle.includes(rosterElement.title)}
                resetTrigger={resetTrigger}
                hasEditPermission={
                  hasEditPermission &&
                  currentBlueprint?.status !== 'Archive' &&
                  currentProject?.workflow.status !== 'Archive' &&
                  currentProject?.workflow.status !== 'Cancel'
                }
              />
            )
          })
        ) : (
          <div className="sem-RosterPlaceholder">
            Please select a valid campaign
          </div>
        )}
      </Grid.Item>
    </Grid.Container>
  )
}

export default EditableRoster
