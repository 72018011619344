import { useFormik } from 'formik'
import { KitScenarioRequest } from '../../../../../models/kits/KitScenarioRequest.model'

export const useRunKitScenarioForm = (
  kitScenarioRequest: KitScenarioRequest,
  runKitScenario: (req: KitScenarioRequest) => void,
) => {
  const currentKitScenario = new KitScenarioRequest(kitScenarioRequest)

  return useFormik({
    enableReinitialize: true,
    initialValues: currentKitScenario,
    onSubmit(values) {
      const kitScenario = new KitScenarioRequest({ ...values })
      runKitScenario(kitScenario)
    },
  })
}
