import React, { Fragment, useEffect } from 'react'
import { Card, Grid } from '@enterprise-ui/canvas-ui-react'
import BlueprintListTable from './BlueprintListTable'
import BlueprintFilterCard from './BlueprintFilterCard'
import { useBlueprintListContext } from '../context/blueprintListContext'
import InfiniteScroll from '../../App/components/InfiniteScroll'
import { useAppContext } from '../../App/context/appContext'
import { useFilterContext } from '../../App/context/filterContext'
import MainSectionHeader from '../../App/components/MainSectionHeader'

export interface Props {
  isModal?: boolean
  closeModal?: Function
}

const BlueprintSearch = ({ isModal = false, closeModal }: Props) => {
  const {
    submitBlueprintSearch,
    blueprintSearchResponse,
    isLoadingMore,
    setIsLoadingMore,
    handleNewResults,
    loadMoreBlueprints,
  } = useBlueprintListContext()!

  const { blueprintFilters } = useFilterContext()!
  const { fullPageLoadingMessage } = useAppContext()!

  useEffect(() => {
    submitBlueprintSearch(blueprintFilters)
    // Need this so that useEffect only runs on page load
    // eslint-disable-next-line
  }, [submitBlueprintSearch])

  return (
    <Fragment>
      <MainSectionHeader
        sectionTitle="Blueprint Library"
        isModal={isModal}
        closeModal={closeModal}
      />
      <Card className="hc-pa-dense">
        <Grid.Container>
          <Grid.Item xs={12} className="hc-mb-expanded">
            <BlueprintFilterCard />
          </Grid.Item>
        </Grid.Container>
        <Grid.Container>
          <Grid.Item xs={12}>
            <BlueprintListTable />
          </Grid.Item>
        </Grid.Container>
      </Card>

      <InfiniteScroll
        isAlreadyLoading={isLoadingMore || fullPageLoadingMessage !== ''}
        shouldShowSpinner={isLoadingMore}
        setIsLoadingMore={setIsLoadingMore}
        currentPageNumber={blueprintSearchResponse.page}
        totalNumberOfElements={blueprintSearchResponse.total_results}
        handleNewResults={handleNewResults}
        loadMore={() => loadMoreBlueprints(blueprintFilters)}
      />
    </Fragment>
  )
}

export default BlueprintSearch
