import React from 'react'
import { Modal } from '@enterprise-ui/canvas-ui-react'
import { useProjectSignListContext } from '../../context/projectSignListContext'
import SignLibraryImport from '../../../../../SignLibraryImport'

const SignLibraryImportModal = () => {
  const { isImportSignsFullScreen } = useProjectSignListContext()!

  return (
    <Modal className="sign-import-modal" isVisible={isImportSignsFullScreen}>
      <SignLibraryImport />
    </Modal>
  )
}

export default SignLibraryImportModal
