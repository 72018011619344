import React from 'react'
import { Dropdown, Button, Grid } from '@enterprise-ui/canvas-ui-react'
import { useNotificationsContext } from '../context/notificationsContext'
import NotificationResponse from '../../../models/notifications/NotificationResponse.model'
import EnterpriseIcon, { CaretDownIcon } from '@enterprise-ui/icons'

const NotificationsActions = () => {
  const {
    selectedRows,
    updateNotificationsToRead,
    updateAllNotificationsToRead,
  } = useNotificationsContext()!
  return (
    <Grid.Item className="hc-ph-dense hc-pb-dense">
      <Dropdown size="normal" location="bottom-left">
        <Button type="ghost">
          Actions
          <EnterpriseIcon className="hc-ml-dense" icon={CaretDownIcon} />
        </Button>
        <Dropdown.Menu>
          <Dropdown.MenuItem
            onClick={() =>
              updateNotificationsToRead(
                selectedRows.map((row: NotificationResponse) => row.id),
              )
            }
          >
            Mark as Read
          </Dropdown.MenuItem>
          <Dropdown.MenuItem onClick={updateAllNotificationsToRead}>
            Mark All as Read
          </Dropdown.MenuItem>
        </Dropdown.Menu>
      </Dropdown>
    </Grid.Item>
  )
}

export default NotificationsActions
