import React from 'react'
import PageChangesWrapper from '../../../App/components/PageChangesWrapper'
import { SignProjectProvider } from '../SignProjectTab/context/signProjectContext'
import { AgGridProjectListProvider } from './context/agGridProjectListContext'
import { BlueprintProjectListProvider } from './context/blueprintProjectListContext'
import BlueprintProjectListTab from './components/BlueprintProjectListTab'

const BluePrintProjectList = (props: any) => (
  <PageChangesWrapper>
    <SignProjectProvider>
      <BlueprintProjectListProvider>
        <AgGridProjectListProvider>
          <BlueprintProjectListTab {...props} />
        </AgGridProjectListProvider>
      </BlueprintProjectListProvider>
    </SignProjectProvider>
  </PageChangesWrapper>
)

export default BluePrintProjectList
