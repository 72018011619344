import React, { createContext, useContext } from 'react'
import { FormikProps } from 'formik'
import DistributionResponse from '../../../models/signs/DistributionResponse.model'
import PricingResponse from '../../../models/signs/PricingResponse.model'
import PrintingResponse from '../../../models/signs/PrintingResponse.model'
import SignRequest from '../../../models/signs/SignRequest.model'
import SignResponse from '../../../models/signs/SignResponse.model'
import MessagingResponse from '../../../models/signs/MessagingResponse.model'
import { useSignDistributionForm } from '../hooks/useSignDistributionForm'
import { useSignInfoForm } from '../hooks/useSignInfoForm'
import { useSignPrintForm } from '../hooks/useSignPrintForm'
import { useSignDetailsContext } from './signDetailsContext'
import { useSignPricingForm } from '../hooks/useSignPricingForm'
import { useSignPreviewForm } from '../hooks/useSignPreviewForm'
import { useSignPreviewContext } from './signPreviewContext'
import { useSignMessagingForm } from '../hooks/useSignMessagingForm'
import { NEW_SIGN } from '../constants/signDetailsConstants'
import { useUserContext } from '../../App/context/userContext'
import { useEnv } from '@praxis/component-runtime-env'

type ContextType = {
  signInfoFormik: FormikProps<SignResponse>
  signPreviewFormik: FormikProps<MessagingResponse>
  signMessagingFormik: FormikProps<MessagingResponse>
  signPrintFormik: FormikProps<PrintingResponse>
  signDistributionFormik: FormikProps<DistributionResponse>
  signPricingFormik: FormikProps<PricingResponse>
}

export const SignFormContext = createContext<ContextType | undefined>(undefined)

type Props = {
  children: React.ReactNode
}

const SignFormsProviderComponent = ({ children }: Props) => {
  const env = useEnv()
  const {
    currentSign,
    updateSignSection,
    createSign,
    updateSign,
    selectedIncludeMscRow,
  } = useSignDetailsContext()!
  const { userInfo } = useUserContext()!
  const { refreshSignPreview } = useSignPreviewContext()!

  const signInfoFormik = useSignInfoForm(
    currentSign,
    (signReq: SignRequest) =>
      currentSign.sign_id === NEW_SIGN
        ? createSign(signReq)
        : updateSign(signReq, currentSign.sign_id),
    env.beskarFeature,
  )
  // use 2 forms for Messaging section to encompass both the preview-generating and the sign-updating functionalities with one set of fields
  const signPreviewFormik = useSignPreviewForm(currentSign, refreshSignPreview)
  const signMessagingFormik = useSignMessagingForm(
    currentSign,
    signPreviewFormik.values,
    updateSignSection,
  )
  const signPrintFormik = useSignPrintForm(
    currentSign,
    userInfo.permissions,
    userInfo.company,
    updateSignSection,
  )
  const signDistributionFormik = useSignDistributionForm(
    currentSign,
    userInfo.permissions,
    userInfo.company,
    updateSignSection,
    selectedIncludeMscRow,
  )
  const signPricingFormik = useSignPricingForm(currentSign, updateSignSection)

  return (
    <SignFormContext.Provider
      value={{
        signInfoFormik,
        signPreviewFormik,
        signMessagingFormik,
        signPrintFormik,
        signDistributionFormik,
        signPricingFormik,
      }}
    >
      {children}
    </SignFormContext.Provider>
  )
}
export const SignFormsProvider = SignFormsProviderComponent
export const useSignFormsContext = () => useContext(SignFormContext)
