import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { Form, Grid } from '@enterprise-ui/canvas-ui-react'
import { useBlueprintProjectListContext } from '../../../context/blueprintProjectListContext'
import { Column } from 'ag-grid-community'
import SapTableProject from '../../../../../../../models/projects/SapTableProject.model'
import SapTableProjectRequest from '../../../../../../../models/projects/SapTableProjectRequest.model'
import SapProjectFieldsModified from '../../../../../../../models/projects/SapProjectFieldsModified.model'
import { BATCH_EDIT_ACTION } from 'components/App/constants/appConstants'

export interface Props {
  value: number | string
  column: Column
  data: SapTableProject
}

const NumberCellEditor = forwardRef(({ value, column, data }: Props, ref) => {
  const { projectModifiedFields, setProjectModifiedFields, modifiedProjects } =
    useBlueprintProjectListContext()!
  const [editValue, setEditValue] = useState(value)
  const refInput = useRef<HTMLInputElement>(null)

  const handleChange = (id: string, value: string) => {
    if (
      projectModifiedFields.find(
        (project: SapProjectFieldsModified) =>
          project.projectId === data.project_id,
      )
    ) {
      setProjectModifiedFields(
        projectModifiedFields.map((project: SapProjectFieldsModified) => {
          return project.projectId === data.project_id
            ? new SapProjectFieldsModified({
                projectId: project.projectId,
                fieldsChanged: project.fieldsChanged.add(column.getColId()),
              })
            : project
        }),
      )
    } else {
      setProjectModifiedFields([
        ...projectModifiedFields,
        new SapProjectFieldsModified({
          projectId: data.project_id,
          fieldsChanged: new Set([column.getColId()]),
        }),
      ])
    }
    setEditValue(value)
  }

  useEffect(() => {
    setTimeout(() => refInput?.current?.focus())
  }, [])

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return editValue
      },
      isPopup() {
        return true
      },
    }
  })

  const isFundIdModified = () => {
    const modifiedProject = projectModifiedFields.find(
      (project: SapProjectFieldsModified) =>
        project.projectId === data.project_id,
    )
    return (
      modifiedProject !== undefined &&
      modifiedProject.fieldsChanged.has('fund_id')
    )
  }

  const isUpdate = () => {
    const modifiedProject = modifiedProjects.find(
      (project: SapTableProjectRequest) =>
        project?.request?.project_id === data.project_id,
    )
    return modifiedProject?.action === BATCH_EDIT_ACTION.UPDATE
  }

  const isDisabled = () => {
    if (
      column.getColId() === 'initial_budget' &&
      isFundIdModified() &&
      isUpdate()
    ) {
      return true
    } else {
      return false
    }
  }

  return (
    <div className="hc-pa-dense">
      <Grid.Container className="sem_StandardForm error_messaging_enabled">
        <Grid.Item className="hc-pb-normal" xs={12}>
          <Form.Field
            id="number_cell_editor"
            label="Budget*"
            ref={refInput}
            type="number"
            value={editValue}
            disabled={isDisabled()}
            error={isDisabled()}
            errorText={
              isDisabled()
                ? 'Cannot edit budget at the same time as fund ID'
                : ''
            }
            onChange={(e: any) => handleChange(e.target.id, e.target.value)}
            size="dense"
          />
        </Grid.Item>
      </Grid.Container>
    </div>
  )
})

export default NumberCellEditor
