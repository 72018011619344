export default class SignTemplateResponse {
  id?: string
  name: string
  type: string
  finished_width: string
  finished_length: string
  finished_depth: string
  flat_width: string
  flat_length: string
  flat_depth: string
  size_editable: boolean
  count?: number
  last_modified_by?: string
  last_updated_date?: string
  hasError?: boolean
  modified?: boolean

  constructor(props: any = {}) {
    this.id = props.id
    this.name = props.name
    this.type = props.type
    this.finished_width = props.finished_width
    this.finished_length = props.finished_length
    this.finished_depth = props.finished_depth
    this.flat_width = props.flat_width
    this.flat_length = props.flat_length
    this.flat_depth = props.flat_depth
    this.size_editable = props.size_editable
    this.count = props.count
    this.last_modified_by = props.last_modified_by
    this.last_updated_date = props.last_updated_date
    this.hasError = props.hasError || false
    this.modified = props.modified || false
  }
}
