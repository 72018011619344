import { Grid, Modal, Spinner } from '@enterprise-ui/canvas-ui-react'
import React from 'react'

export interface Props {
  isVisible: boolean
  message: string
}

const LoadingModal = ({ message, isVisible }: Props) => {
  return (
    <Modal className="sem_LoadingModal" isVisible={isVisible}>
      <Grid.Container align="center" justify="center" direction="column">
        <Grid.Item>{message}</Grid.Item>
        <Grid.Item>
          <Spinner />
        </Grid.Item>
      </Grid.Container>
    </Modal>
  )
}

export default LoadingModal
