import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useAuth } from '@praxis/component-auth'
import { useEnv } from '@praxis/component-runtime-env'
import { HelveticaForTarget } from '@enterprise-ui/component-font'
import MainLayout from './MainLayout'
import axios from 'axios'
import { get } from 'lodash'
import { TOASTER_DEFAULTS, UserType } from '../constants/appConstants'
import '../styles/app.scss'
import { useUserContext } from '../context/userContext'
import { getUserPermissions } from '../helpers/userPermissionsHelper'
import LoadingModal from './LoadingModal'
import { useAppContext } from '../context/appContext'
import { Dialog, useToaster } from '@enterprise-ui/canvas-ui-react'
import Cookies from 'js-cookie'
import { UserInformation } from '../../../models/app/UserInfo.model'

export const App = () => {
  const { session } = useAuth()
  const env = useEnv<any>()
  const makeToast = useToaster()
  const {
    setUserInfo,
    setUserPermissions,
    setUserName,
    setUserId,
    setUserEmail,
    setUserCompany,
    setUserType,
  } = useUserContext()!
  const { fullPageLoadingMessage, dialogProps, pageHasChanges } =
    useAppContext()!

  const [isUserLoaded, setIsUserLoaded] = useState(false)
  useEffect(() => {
    setUserInfo(
      new UserInformation({
        permissions: getUserPermissions(get(session, 'userInfo.memberOf', [])),
        name: get(session, 'userInfo.fullName', ''),
        id: get(session, 'userInfo.lanId', ''),
        email: get(session, 'userInfo.email', ''),
        company: get(session, 'userInfo.company', ''),
        type: get(session, 'userInfo.email', '')
          .toLowerCase()
          .endsWith('@target.com')
          ? UserType.INTERNAL
          : UserType.EXTERNAL,
      }),
    )
    setUserPermissions(
      getUserPermissions(get(session, 'userInfo.memberOf', [])),
    )
    setUserName(get(session, 'userInfo.fullName', ''))
    setUserId(get(session, 'userInfo.lanId', ''))
    setUserEmail(get(session, 'userInfo.email', ''))
    setUserCompany(get(session, 'identity.company', ''))
    setUserType(
      get(session, 'userInfo.email', '').toLowerCase().endsWith('@target.com')
        ? UserType.INTERNAL
        : UserType.EXTERNAL,
    )
    setIsUserLoaded(true)
  }, [
    session,
    setUserPermissions,
    setUserName,
    setUserId,
    setUserEmail,
    setUserCompany,
    setUserType,
    setUserInfo,
  ])

  // set up listener to alert the user if they are reloading or changing the URL with unsaved changes
  const alertUser = useCallback(
    (e: any) => {
      if (pageHasChanges) {
        e.preventDefault()
        e.returnValue = ''
      }
    },
    [pageHasChanges],
  )
  useEffect(() => {
    window.addEventListener('beforeunload', alertUser)
    return () => {
      window.removeEventListener('beforeunload', alertUser)
    }
  }, [alertUser])

  axios.interceptors.request.use(function (config) {
    if (isSSOSessionActive()) {
      if (config.headers) {
        if (localStorage.getItem('access_token')) {
          config.headers.Authorization = `${localStorage.getItem(
            'access_token',
          )}`
        }
        if (localStorage.getItem('id_token')) {
          config.headers.id_token = `${localStorage.getItem('id_token')}`
        }
      }
    } else {
      throw new axios.Cancel('SSO Session expired.')
    }

    if (config.headers) {
      config.headers['X-Api-Key'] = `${env.apiKey}`
      config.headers['X-Requested-With'] = 'XMLHttpRequest'
    }
    return config
  })

  const isSSOSessionActive = () => {
    if (getDomainName() === 'partnersonline.com') {
      console.log('Checking SSO only for the POL route')
      //Check SSO only for the POL route
      const tokenUserName = getUserIdFromAccessToken() //Get user name from access token
      const ssoSessionCookie = Cookies.get('SSOSESSIONINFO') //Get SSO Cookie details
      if (ssoSessionCookie && ssoSessionCookie.length > 0) {
        const ssoSessionCookieJson = JSON.parse(atob(ssoSessionCookie))
        console.log(
          'tokenUserName = ',
          tokenUserName,
          ' ssoSessionCookieJson.login_status = ',
          ssoSessionCookieJson.login_status,
          'ssoSessionCookieJson.user = ',
          ssoSessionCookieJson.user,
          'ssoSessionCookieJson.user.toUpperCase() = ',
          ssoSessionCookieJson.user.toUpperCase(),
          'tokenUserName.toUpperCase()',
          tokenUserName.toUpperCase(),
        )
        if (
          tokenUserName &&
          ssoSessionCookieJson.login_status &&
          ssoSessionCookieJson.user &&
          ssoSessionCookieJson.user.toUpperCase() ===
            tokenUserName.toUpperCase()
        ) {
          console.log('Valid case. User is active and user name matches')
          //Valid case. User is active and user name matches
          return true
        }
      }
      console.log('Invalid case. Session expired, clear tokens and loggoff')
      //Invalid case. Session expired, clear tokens and loggoff
      localStorage.removeItem('access_token') //Remove token from LS
      localStorage.removeItem('id_token')
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Session Expired or Company Changed!',
        message:
          'POL session logged out or company changed. Please refresh and continue',
      })
      setTimeout(() => {
        window.location.href = '/' //Redirect to home page to login again
      }, 3000)
      return false
    } else {
      //No need to check SSO for internal users.
      return true
    }
  }

  const getUserIdFromAccessToken = () => {
    const accessToken = localStorage.getItem('access_token')
    if (accessToken) {
      const userDetails = accessToken.split('.')[1]
      if (userDetails) {
        const userDetailsJSON = JSON.parse(atob(userDetails))
        return userDetailsJSON.username
      }
    }
    return null
  }

  const getDomainName = (): string => {
    const hostName = window && window.location && window.location.hostname
    return (
      hostName &&
      hostName.substring(
        hostName.lastIndexOf('.', hostName.lastIndexOf('.') - 1) + 1,
      )
    )
  }

  return isUserLoaded ? (
    <Fragment>
      <HelveticaForTarget variants={['n4', 'n5', 'n7', 'i4']} />
      <MainLayout />
      <LoadingModal
        message={fullPageLoadingMessage}
        isVisible={fullPageLoadingMessage !== ''}
      />
      <Dialog
        {...dialogProps}
        isVisible={dialogProps.headingText !== undefined}
      />
    </Fragment>
  ) : null
}
export default App
