import React from 'react'
import PageChangesWrapper from '../App/components/PageChangesWrapper'
import { SignImportSSRMServerProvider } from './context/signImportSSRMServerContext'
import { AgGridSignImportProvider } from './context/agGridSignImportContext'
import SignImportContainer from './components'

const SignLibraryImport = (props: any) => (
  <PageChangesWrapper>
    <SignImportSSRMServerProvider>
      <AgGridSignImportProvider>
        <SignImportContainer {...props} />
      </AgGridSignImportProvider>
    </SignImportSSRMServerProvider>
  </PageChangesWrapper>
)

export default SignLibraryImport
