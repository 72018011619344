import Axios from 'axios'
import React, { createContext, useState, useContext, useCallback } from 'react'
import { connect } from 'react-redux'
import {
  BlueprintSummary,
  BlueprintSummarySearchResult,
} from '../../../models/dashboard/BlueprintSummary.model'
import { DashboardRequest } from '../../../models/dashboard/DashboardRequest.model'
import { ProjectSummary } from '../../../models/dashboard/ProjectSummary.model'
import {
  DASHBOARD_MANAGERS_LOCAL_STORAGE_KEY,
  DASHBOARD_SERVICE_API_DOMAIN_URL,
  PROJECT_SERVICE_API_DOMAIN_URL,
  TOASTER_DEFAULTS,
} from '../../App/constants/appConstants'
import { useEnv } from '@praxis/component-runtime-env'
import { useAuth } from '@praxis/component-auth'
import { ProjectMilestoneCompleteRequest } from '../../../models/dashboard/ProjectMilestoneCompleteRequest.model'
import { ProjectMilestonesSummary } from '../../../models/dashboard/ProjectMilestoneSummary.model'
import BlueprintMilestoneCompleteRequest from '../../../models/dashboard/BlueprintMilestoneCompleteRequest.model'
import { useToaster } from '@enterprise-ui/canvas-ui-react'

enum DashboardErrors {
  BLUEPRINT_SUMMARY_LIST = 'Unable to get Blueprint Summary',
  BLUEPRINT_MILESTONE_SUMMARY_LIST = 'Unable to get Blueprint Milestone Summary',
  PROJECT_SUMMARY_LIST = 'Unable to get Project Summary',
  PROJECT_MILESTONE_SUMMARY_LIST = 'Unable to get Project Milestone Summary',
}

type ContextType = {
  isLoading: boolean
  managers: any[]
  setManagers: Function
  getManagersFromStorage: () => void

  dashboardErrors: Partial<
    Record<keyof typeof DashboardErrors, DashboardErrors>
  >

  blueprintSummaryList: any[]
  setBlueprintSummaryList: Function
  getBlueprintSummaryList: (
    includeComplete: boolean,
    includeAllActive: boolean,
  ) => void

  blueprintMilestoneSummaryList: any[]
  setBlueprintMilestoneSummaryList: Function
  getBlueprintMilestoneSummaryList: (includeComplete: boolean) => void

  projectSummaryList: ProjectSummary[]
  setProjectSummaryList: Function
  getProjectSummaryList: (
    includeComplete: boolean,
    includeAllActive: boolean,
  ) => void
  showKitAlert: boolean

  projectMilestoneSummaryList: ProjectMilestonesSummary[]
  setProjectMilestoneSummaryList: Function
  getProjectMilestoneSummaryList: (includeComplete: boolean) => void
  getColorCount: (list: any, color: string) => any
  showProjectMilestonAlert: boolean
  modifiedProjects: ProjectSummary[]
  setModifiedProjects: Function
  saveProjectMilestoneStatus: (
    modifiedMilestones: ProjectMilestoneCompleteRequest[],
  ) => void

  includeCompleteBlueprints: boolean
  setIncludeCompleteBlueprints: (isTrue: boolean) => void
  includeAllActiveBlueprints: boolean
  setIncludeAllActiveBlueprints: (isTrue: boolean) => void

  includeCompleteProjects: boolean
  setIncludeCompleteProjects: (isTure: boolean) => void
  includeAllActiveProjects: boolean
  setIncludeAllActiveProjects: (isTrue: boolean) => void

  includeCompleteBpMilestones: boolean
  setIncludeCompleteBpMilestones: (isTrue: boolean) => void

  includeCompleteProjectMilestones: boolean
  setIncludeCompleteProjectMilestones: (isTrue: boolean) => void
  saveBlueprintMilestoneStatus: (
    modifiedMilestones: BlueprintMilestoneCompleteRequest[],
  ) => void
  modifiedBlueprintMilestones: any[]
  setModifiedBlueprintMilestones: Function
}

export const DashboardContext = createContext<ContextType | undefined>(
  undefined,
)

type Props = {
  children: React.ReactNode
}

export const DashboardContextProviderComponent = ({ children }: Props) => {
  const { session } = useAuth()
  const env = useEnv()
  const makeToast = useToaster()

  const [isLoading, setIsLoading] = useState(false)
  const [blueprintSummaryList, setBlueprintSummaryList] = useState([])
  const [blueprintMilestoneSummaryList, setBlueprintMilestoneSummaryList] =
    useState([])
  const [dashboardErrors, setDashboardErrors] = useState({})
  const [projectSummaryList, setProjectSummaryList] = useState([])
  const [showKitAlert, setShowKitAlert] = useState(false)
  const [projectMilestoneSummaryList, setProjectMilestoneSummaryList] =
    useState([])
  const [showProjectMilestonAlert] = useState(false)
  const [includeCompleteBlueprints, setIncludeCompleteBlueprints] =
    useState(false)
  const [includeAllActiveBlueprints, setIncludeAllActiveBlueprints] =
    useState(false)
  const [includeCompleteProjects, setIncludeCompleteProjects] = useState(false)
  const [includeAllActiveProjects, setIncludeAllActiveProjects] =
    useState(false)
  const [includeCompleteBpMilestones, setIncludeCompleteBpMilestones] =
    useState(false)
  const [
    includeCompleteProjectMilestones,
    setIncludeCompleteProjectMilestones,
  ] = useState(false)
  const [modifiedProjects, setModifiedProjects] = useState<ProjectSummary[]>([])
  const [modifiedBlueprintMilestones, setModifiedBlueprintMilestones] =
    useState<any[]>([])

  const getManagersFromStorage = () => {
    const managerObjectFromStorage = localStorage.getItem(
      DASHBOARD_MANAGERS_LOCAL_STORAGE_KEY,
    )
    return managerObjectFromStorage ? JSON.parse(managerObjectFromStorage) : ''
  }

  const [managers, setManagers] = useState([
    {
      email: session && session.userInfo ? session.userInfo.email : '',
      isSessionUser: true,
    },
    ...getManagersFromStorage(),
  ])

  localStorage.setItem(
    DASHBOARD_MANAGERS_LOCAL_STORAGE_KEY,
    JSON.stringify(managers.filter((manager) => !manager.isSessionUser)),
  )

  const getBlueprintSummaryList = useCallback(
    async (includeComplete: boolean, includeAllActive: boolean) => {
      setIsLoading(true)
      try {
        const response = await Axios.post(
          `${env.apiDomainUrl + DASHBOARD_SERVICE_API_DOMAIN_URL}/blueprints`,
          new DashboardRequest({
            user_names: managers.map((manager) => manager.email),
            include_complete: includeComplete,
            include_all: includeAllActive,
          }),
          {
            headers: { 'Content-Type': 'application/json' },
          },
        )
        response.data &&
          setBlueprintSummaryList(
            response.data.map((result: BlueprintSummarySearchResult) => {
              return new BlueprintSummary(result)
            }),
          )
      } catch (err: any) {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'error',
          heading: DashboardErrors.BLUEPRINT_SUMMARY_LIST,
          message: err.response.data.message,
        })
        setDashboardErrors((state) => ({
          ...state,
          BLUEPRINT_SUMMARY_LIST: DashboardErrors.BLUEPRINT_SUMMARY_LIST,
        }))
      }
      setIsLoading(false)
    },
    [managers, env.apiDomainUrl, makeToast],
  )

  const getBlueprintMilestoneSummaryList = useCallback(
    async (includeComplete: boolean) => {
      setIsLoading(true)
      try {
        const response = await Axios.post(
          `${
            env.apiDomainUrl + DASHBOARD_SERVICE_API_DOMAIN_URL
          }/blueprints/milestones`,
          {
            user_names: managers.map((manager) => manager.email),
            include_complete: includeComplete,
          },
          {
            headers: { 'Content-Type': 'application/json' },
          },
        )
        response.data &&
          setBlueprintMilestoneSummaryList(
            response.data.map((result: BlueprintSummarySearchResult) => {
              return new BlueprintSummary(result)
            }),
          )
      } catch (err: any) {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'error',
          heading: DashboardErrors.BLUEPRINT_MILESTONE_SUMMARY_LIST,
          message: err.response.data.message,
        })

        setDashboardErrors((state) => ({
          ...state,
          BLUEPRINT_MILESTONE_SUMMARY_LIST:
            DashboardErrors.BLUEPRINT_MILESTONE_SUMMARY_LIST,
        }))
      }
      setIsLoading(false)
    },
    [makeToast, managers, env.apiDomainUrl],
  )

  const getProjectSummaryList = useCallback(
    async (includeComplete: boolean, includeAllActive: boolean) => {
      setIsLoading(true)
      try {
        const response = await Axios.post(
          `${env.apiDomainUrl + DASHBOARD_SERVICE_API_DOMAIN_URL}/projects`,
          new DashboardRequest({
            user_names: managers.map((manager) => manager.email),
            include_complete: includeComplete,
            include_all: includeAllActive,
          }),
          {
            headers: { 'Content-Type': 'application/json' },
          },
        )
        response.data &&
          setProjectSummaryList(
            response.data.map((result: ProjectSummary) => {
              return new ProjectSummary(result)
            }),
          )
        if (
          response.data.some((result: ProjectSummary) => result.recreate_kits)
        ) {
          setShowKitAlert(true)
        } else {
          setShowKitAlert(false)
        }
      } catch (err: any) {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'error',
          heading: DashboardErrors.PROJECT_SUMMARY_LIST,
          message: err.response.data.message,
        })
        setDashboardErrors((state) => ({
          ...state,
          PROJECT_SUMMARY_LIST: DashboardErrors.PROJECT_SUMMARY_LIST,
        }))
      }
      setIsLoading(false)
    },
    [makeToast, managers, env.apiDomainUrl],
  )

  const getProjectMilestoneSummaryList = useCallback(
    async (includeComplete: boolean = false) => {
      setIsLoading(true)
      try {
        const user_names = managers.map((manager) => manager.email)
        const res = await Axios.post(
          `${
            env.apiDomainUrl + DASHBOARD_SERVICE_API_DOMAIN_URL
          }/projects/milestones`,
          {
            user_names,
            include_complete: includeComplete,
          },
          {
            headers: { 'Content-Type': 'application/json' },
          },
        )
        res.data && includeComplete
          ? setProjectMilestoneSummaryList(
              res.data
                .filter((project: any) =>
                  user_names.some((name) =>
                    project.my_milestone.assigned_to.includes(name),
                  ),
                )
                .map((project: any) => new ProjectMilestonesSummary(project)),
            )
          : setProjectMilestoneSummaryList(
              res.data
                .filter((project: any) =>
                  user_names.some((name) =>
                    project.my_milestone.assigned_to.includes(name),
                  ),
                )
                .filter(
                  (project: any) => project.my_milestone.status !== 'Completed',
                )
                .map((project: any) => new ProjectMilestonesSummary(project)),
            )
      } catch (err: any) {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'error',
          heading: DashboardErrors.PROJECT_MILESTONE_SUMMARY_LIST,
          message: err.response.data.message,
        })
        setDashboardErrors((state) => ({
          ...state,
          PROJECT_MILESTONE_SUMMARY_LIST:
            DashboardErrors.PROJECT_MILESTONE_SUMMARY_LIST,
        }))
      }
      setIsLoading(false)
    },
    [makeToast, managers, env.apiDomainUrl],
  )

  const getColorCount = (list: any, color: string) => {
    return list.filter((listItem: any) => listItem.health === color).length
  }

  const saveProjectMilestoneStatus = async (
    modifiedMilestones: ProjectMilestoneCompleteRequest[],
  ) => {
    setIsLoading(true)
    try {
      await Axios.put(
        `${
          env.apiDomainUrl + PROJECT_SERVICE_API_DOMAIN_URL
        }/projects/milestones`,
        modifiedMilestones,
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'success',
        heading: 'Project Milestones Updated',
        message: 'Successfully updated Project Milestone Status',
      })
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Update Project Milestone Status',
        message: err.response.data.message,
      })
    }
    setIsLoading(false)
  }

  const saveBlueprintMilestoneStatus = async (
    modifiedMilestones: BlueprintMilestoneCompleteRequest[],
  ) => {
    setIsLoading(true)
    try {
      await Axios.put(
        `${
          env.apiDomainUrl + PROJECT_SERVICE_API_DOMAIN_URL
        }/blueprints/milestones`,
        {
          requests: modifiedMilestones,
        },
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'success',
        heading: 'Blueprint Milestones Updated',
        message: 'Successfully updated blueprint milestones statuses',
      })
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Update Blueprint Milestones',
        message: err.response.data.message,
      })
    }
    setIsLoading(false)
  }

  return (
    <DashboardContext.Provider
      value={{
        isLoading,
        managers,
        setManagers,
        getManagersFromStorage,

        blueprintSummaryList,
        setBlueprintSummaryList,
        getBlueprintSummaryList,

        blueprintMilestoneSummaryList,
        setBlueprintMilestoneSummaryList,
        getBlueprintMilestoneSummaryList,

        projectSummaryList,
        setProjectSummaryList,
        getProjectSummaryList,
        showKitAlert,

        dashboardErrors,

        projectMilestoneSummaryList,
        setProjectMilestoneSummaryList,
        getProjectMilestoneSummaryList,
        modifiedProjects,
        setModifiedProjects,
        saveProjectMilestoneStatus,
        showProjectMilestonAlert,
        getColorCount,

        includeCompleteBlueprints,
        setIncludeCompleteBlueprints,
        includeAllActiveBlueprints,
        setIncludeAllActiveBlueprints,

        includeCompleteProjects,
        setIncludeCompleteProjects,
        includeAllActiveProjects,
        setIncludeAllActiveProjects,

        includeCompleteBpMilestones,
        setIncludeCompleteBpMilestones,

        includeCompleteProjectMilestones,
        setIncludeCompleteProjectMilestones,
        saveBlueprintMilestoneStatus,
        modifiedBlueprintMilestones,
        setModifiedBlueprintMilestones,
      }}
    >
      {children}
    </DashboardContext.Provider>
  )
}

export const useDashboardContext = () => useContext(DashboardContext)

export const DashboardProvider = connect(
  null,
  null,
)(DashboardContextProviderComponent)
