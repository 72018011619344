import { PogSetDate } from './ProjectDetail.model'

export class ProjectRequest {
  blueprint_id: string
  short_name: string
  status: string
  campaign_id: string
  creative_assets: boolean
  non_adjacency_project: boolean
  set_date: Date
  in_store_date?: Date
  pog_set_date: PogSetDate
  division_ids: [number]
  department_ids: [number]
  project_type: string
  tactic: string
  fund_id: string
  initial_budget: number
  campaign: []
  merchant_contact: string
  updated_by: string

  constructor(props: any = {}) {
    this.blueprint_id = props.blueprint_id || ''
    this.short_name = props.short_name || ''
    this.status = props.status || ''
    this.campaign_id = props.campaign_id || ''
    this.creative_assets = props.creative_assets || false
    this.non_adjacency_project = props.non_adjacency_project || false
    this.set_date = props.set_date || new Date()
    this.in_store_date = props.in_store_date
    this.pog_set_date = props.pog_set_date || new PogSetDate()
    this.division_ids = props.division_ids || []
    this.department_ids = props.department_ids || []
    this.project_type = props.project_type || ''
    this.tactic = props.tactic || ''
    this.fund_id = props.fund_id || ''
    this.initial_budget = props.initial_budget || 0
    this.campaign = props.campaign || []
    this.merchant_contact = props.merchant_contact || ''
    this.updated_by = props.updated_by || ''
  }
}
