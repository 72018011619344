import React from 'react'
import { Link } from 'react-router-dom'
import { Anchor } from '@enterprise-ui/canvas-ui-react'
import { ProjectSignInfo } from '../../../../../models/signLibrary'

export interface Props {
  value: string
  data: ProjectSignInfo
}

const ProjectNameCell = ({ value, data }: Props) => (
  <div className="ag-cell-value">
    <Anchor as={Link} to={`/projects/${data.project_id}?tab=SIGN+LIST`}>
      {value}
    </Anchor>
  </div>
)

export default ProjectNameCell
