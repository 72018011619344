import React from 'react'
import { Grid, Spinner } from '@enterprise-ui/canvas-ui-react'

const LoadingSigns = () => {
  return (
    <Grid.Container
      className="sign-projects-loading-container"
      direction="column"
      justify="center"
      align="center"
    >
      <Grid.Item>Loading Signs...</Grid.Item>
      <Grid.Item>
        <Spinner />
      </Grid.Item>
    </Grid.Container>
  )
}

export default LoadingSigns
