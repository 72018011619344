import { Column, RowNode } from 'ag-grid-community'
import { Grid, Form } from '@enterprise-ui/canvas-ui-react'
import moment from 'moment'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { NRI_SIGN_ITEM_STATUS } from 'components/App/constants/appConstants'

export interface Props {
  value: any
  column: Column
  node: RowNode
}

const getLatestDate = (data: any) => {
  return data.project_sign_info_list.reduce(
    (acc: any, item: Record<string, string>) => {
      if (new Date(item.set_date).getTime() > acc) {
        return new Date(item.set_date).getTime()
      }
      return acc
    },
    0,
  )
}

const DateCellEditor = forwardRef(({ value, column, node }: Props, ref) => {
  const [editValue, setEditValue] = useState(value)
  const [dateError, setDateError] = useState(false)

  const earliestPossibleEndDate = moment(getLatestDate(node.data)).format(
    'YYYY-MM-DD',
  )

  const refInput = useRef<HTMLInputElement>(null)

  const handleChange = (newDate: string) => {
    if (moment(newDate).isBefore(earliestPossibleEndDate)) {
      setDateError(true)
      newDate = ''
    }
    setEditValue(newDate === '' ? null : newDate)
    if (column.getColId() === 'non_retail_item_info.end_date') {
      node.data.non_retail_item_info.sign_item_status ===
        NRI_SIGN_ITEM_STATUS.OBSOLETE &&
        node.setDataValue('non_retail_item_info.reinstated', true)
    }
  }

  useEffect(() => {
    setTimeout(() => refInput?.current?.focus())
  }, [])

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return editValue && moment(editValue).format('YYYY-MM-DD')
      },
      isPopup() {
        return true
      },
    }
  })

  return (
    <div className="hc-pa-dense">
      <Grid.Container className="sem_StandardForm error_messaging_enabled hc-pb-dense">
        <Grid.Item xs={14}>
          <Form.Field
            id="date_cell"
            type="date"
            label={column.getColDef().headerName}
            ref={refInput}
            value={editValue && moment(editValue).format('YYYY-MM-DD')}
            onChange={(e: any) => handleChange(e.target.value)}
            min={earliestPossibleEndDate}
            hintText={`End Date must be blank or after ${earliestPossibleEndDate}`}
            error={dateError}
            errorText={`End Date must be after ${earliestPossibleEndDate}`}
            size="dense"
          />
        </Grid.Item>
      </Grid.Container>
    </div>
  )
})

export default DateCellEditor
