import { Pyramid } from '../merchandise/hierarchy/Pyramid.model'
import { get } from 'lodash'
import { RosterElement } from '../roster/RosterElement.model'
import { ChildCampaignType } from './ChildCampaignType.model'

export class CampaignTypeRequest {
  name: string
  pyramids: Pyramid[]
  roster: RosterElement[]
  parent: boolean
  non_adjacency: boolean
  child_types: ChildCampaignType[]
  cascade_roster: boolean
  updated_by: string

  constructor(props: any = {}) {
    this.name = props.name || ''
    this.pyramids =
      props.pyramids !== null
        ? get(props, 'pyramids', []).map((pyramid: any) => new Pyramid(pyramid))
        : []
    this.roster = get(props, 'roster', []).map(
      (roster: any) => new RosterElement(roster),
    )
    this.parent = props.parent || false
    this.non_adjacency = props.non_adjacency || false
    this.child_types = get(props, 'child_types', []).map(
      (child_type: any) => new ChildCampaignType(child_type),
    )
    this.cascade_roster = props.cascade_roster || false
    this.updated_by = props.updated_by || ''
  }
}
