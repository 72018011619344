import React, { useEffect, Fragment } from 'react'
import {
  Grid,
  Button,
  Popover,
  Divider,
  Modal,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { PlusIcon } from '@enterprise-ui/icons'
import AddBlueprintMilestoneForm from './BlueprintMilestone/AddBlueprintMilestoneForm'
import { useBlueprintMilestoneTabContext } from '../context/blueprintMilestoneTabContext'
import { useBlueprintDetailsContainerContext } from '../../../context/blueprintDetailsContainerContext'
import { useUserContext } from 'components/App/context/userContext'
import { useMilestoneContext } from 'components/App/context/milestoneContext'
import CreateBlueprintMilestoneForm from './BlueprintMilestone/CreateBlueprintMilestoneForm'
import { MilestoneTemplate } from '../../../../../models/milestones/MilestoneTemplate.model'
import BlueprintMilestoneCard from './BlueprintMilestone/BlueprintMilestoneCard'
import AddScheduleTemplateMenu from './ScheduleTemplate/AddScheduleTemplateMenu'
import { AddCustomScheduleTemplate } from './ScheduleTemplate/AddCustomScheduleTemplate'
import AddBlueprintMilestoneMenu from './BlueprintMilestone/AddBlueprintMilestoneMenu'
import AddMasterScheduleTemplate from './ScheduleTemplate/AddMasterScheduleTemplate'
import ScheduleTemplateActionsDropdown from './ScheduleTemplate/ScheduleTemplateActionsDropdown'
import SchedulingTemplateModal from './ScheduleTemplate/SchedulingTemplateModal'
import {
  MilestoneMenuName,
  TemplateMenuName,
} from '../constants/blueprintMilestoneConstants'
import { RosterElement } from '../../../../../models/roster/RosterElement.model'
import {
  InternalUser,
  RosterUser,
} from '../../../../../models/roster/RosterUser.model'
import { BusinessPartner } from '../../../../../models/app/BusinessPartner.model'
import RubixChip from 'components/common/RubixChip'

export const BlueprintMilestoneTab = () => {
  const { currentBlueprint } = useBlueprintDetailsContainerContext()!
  const { userPermissions, userEmail, userCompany } = useUserContext()!
  const { scheduleTemplateByScheduleTemplateId, getMilestoneFacets } =
    useMilestoneContext()!
  const {
    getBlueprintMilestones,
    saveBlueprintMilestones,
    milestoneMenu,
    closeMilestoneForm,
    isScheduleTemplateModalOpen,
    setIsScheduleTemplateOpen,
    templateMenu,
    closeTemplateForm,
  } = useBlueprintMilestoneTabContext()!

  const enableSave = () => {
    return currentBlueprint.milestone?.milestones.some(
      (milestone: MilestoneTemplate) => {
        const roleMatch = currentBlueprint.roster.find(
          (roles: RosterElement) => roles.title === milestone.role,
        )
        return roleMatch?.users.some((user: RosterUser) =>
          user.type === 'INTERNAL'
            ? (user as InternalUser).email === userEmail
            : (user as BusinessPartner).name === userCompany,
        )
      },
    )
  }

  useEffect(() => {
    getBlueprintMilestones()
  }, [getBlueprintMilestones])

  useEffect(() => {
    getMilestoneFacets()
  }, [getMilestoneFacets])

  return (
    <Grid.Container className="sem-MilestonesTab">
      <Grid.Item xs={true}>
        <Grid.Container justify="flex-start">
          <Grid.Item xs={12}>
            <div className="hc-pa-normal hc-pv-none">
              <Grid.Container
                className="hc-mt-dense"
                align="center"
                justify="space-between"
              >
                <Grid.Item className="hc-pv-none">
                  <span className="sem_OverviewTitle">
                    Project Schedule Type:{' '}
                  </span>
                  <RubixChip data-testid="statusChip">
                    {scheduleTemplateByScheduleTemplateId.schedule_type
                      ? scheduleTemplateByScheduleTemplateId.schedule_type
                      : 'N/A'}
                  </RubixChip>
                </Grid.Item>
                <Grid.Item className="hc-pv-none">
                  <span className="sem_OverviewTitle">Schedule Template: </span>
                  <RubixChip data-testid="statusChip">
                    {scheduleTemplateByScheduleTemplateId.template_name
                      ? scheduleTemplateByScheduleTemplateId.template_name
                      : 'N/A'}
                  </RubixChip>
                </Grid.Item>
                <Grid.Item className="hc-pv-none">
                  <span className="sem_OverviewTitle">Master Template: </span>{' '}
                  <RubixChip
                    color={
                      scheduleTemplateByScheduleTemplateId.master_template
                        ? 'green'
                        : 'yellow'
                    }
                  >
                    {scheduleTemplateByScheduleTemplateId.master_template
                      ? 'True'
                      : 'False'}
                  </RubixChip>
                </Grid.Item>
              </Grid.Container>
            </div>
          </Grid.Item>

          {/* Add Schedule Templates Menu */}
          {userPermissions.isBlueprintAdmin &&
            currentBlueprint.status !== 'Archive' && (
              <Grid.Item>
                <Popover
                  type="clickOnly"
                  location="bottom-left"
                  size="expanded"
                  className="sem-AddMilestonePopover"
                  content={
                    templateMenu === TemplateMenuName.MASTER_TEMPLATE ? (
                      <AddMasterScheduleTemplate />
                    ) : templateMenu === TemplateMenuName.CUSTOM_TEMPLATE ? (
                      <AddCustomScheduleTemplate />
                    ) : (
                      <AddScheduleTemplateMenu />
                    )
                  }
                  onRequestClose={closeTemplateForm}
                >
                  <Button
                    type="ghost"
                    fullWidth
                    className="hc-mt-normal hc-ta-justify"
                  >
                    <EnterpriseIcon className="hc-mr-normal" icon={PlusIcon} />
                    Add Template
                  </Button>
                </Popover>
              </Grid.Item>
            )}

          {!scheduleTemplateByScheduleTemplateId.template_id ? null : (
            <Fragment>
              {/* Add Milestone Menu */}
              {userPermissions.isBlueprintAdmin &&
                currentBlueprint.status !== 'Archive' && (
                  <Grid.Item>
                    <Popover
                      type="clickOnly"
                      location="bottom-left"
                      size="expanded"
                      className="sem-AddMilestonePopover"
                      content={
                        milestoneMenu ===
                        MilestoneMenuName.ADD_NEW_MILESTONE ? (
                          <AddBlueprintMilestoneForm />
                        ) : milestoneMenu ===
                          MilestoneMenuName.CREATE_NEW_MILESTONE ? (
                          <CreateBlueprintMilestoneForm />
                        ) : (
                          <AddBlueprintMilestoneMenu />
                        )
                      }
                      onRequestClose={closeMilestoneForm}
                    >
                      <Button
                        type="ghost"
                        fullWidth
                        className="hc-mt-normal hc-ta-justify"
                      >
                        <EnterpriseIcon
                          className="hc-mr-normal"
                          icon={PlusIcon}
                        />
                        Add Milestone
                      </Button>
                    </Popover>
                  </Grid.Item>
                )}
              {/* Actions Dropdown */}
              {currentBlueprint.status !== 'Archive' && (
                <Grid.Item className="scheduleTemplateDropdown">
                  <ScheduleTemplateActionsDropdown
                    scheduleTemplateByScheduleTemplateId={
                      scheduleTemplateByScheduleTemplateId
                    }
                  />
                </Grid.Item>
              )}
              <Modal
                onRefuse={() => {
                  setIsScheduleTemplateOpen(!isScheduleTemplateModalOpen)
                }}
                isVisible={isScheduleTemplateModalOpen}
              >
                <SchedulingTemplateModal />
              </Modal>
              {/* Save Button */}
              {(userPermissions.isBlueprintEditor || enableSave()) &&
                currentBlueprint.status !== 'Archive' && (
                  <Grid.Item xs={2}>
                    <Button
                      className="hc-mt-normal"
                      fullWidth
                      type="primary"
                      data-testid="saveMilestones"
                      onClick={() => {
                        saveBlueprintMilestones(
                          currentBlueprint.blueprint_id,
                          scheduleTemplateByScheduleTemplateId,
                        )
                      }}
                    >
                      Save Changes
                    </Button>
                  </Grid.Item>
                )}
            </Fragment>
          )}
          <Grid.Item xs={12}>
            {scheduleTemplateByScheduleTemplateId.milestones.length > 0 ? (
              <Fragment>
                <Grid.Container className="hc-bg-grey06 rounded" spacing="none">
                  <Grid.Item className="phase-GridItem" xs={3}>
                    <div className="hc-pa-dense phase-Container">
                      <p className="title-name hc-mb-none">
                        Strategy &amp; Planning
                      </p>
                      <Divider width={3} />
                      <div className="hc-mt-expanded">
                        {scheduleTemplateByScheduleTemplateId.milestones
                          .filter(
                            (milestone: MilestoneTemplate) =>
                              milestone.project_phase === 'Strategy & Planning',
                          )
                          .sort(
                            (a: any, b: any) => b['days_out'] - a['days_out'],
                          )
                          .map((filteredMilestone) => (
                            <div className="hc-mb-md">
                              <BlueprintMilestoneCard
                                milestone={filteredMilestone}
                                disabled={false}
                              />
                            </div>
                          ))}
                      </div>
                    </div>
                  </Grid.Item>

                  <Grid.Item className="phase-GridItem" xs={3}>
                    <div className="hc-pa-dense phase-Container">
                      <p className="title-name hc-mb-none">
                        Creative Development
                      </p>
                      <Divider width={3} />
                      <div className="hc-mt-expanded">
                        {scheduleTemplateByScheduleTemplateId.milestones
                          .filter(
                            (milestone: MilestoneTemplate) =>
                              milestone.project_phase ===
                              'Creative Development',
                          )
                          .sort(
                            (a: any, b: any) => b['days_out'] - a['days_out'],
                          )
                          .map((filteredMilestone) => (
                            <div className="hc-mb-md">
                              <BlueprintMilestoneCard
                                milestone={filteredMilestone}
                                disabled={false}
                              />
                            </div>
                          ))}
                      </div>
                    </div>
                  </Grid.Item>

                  <Grid.Item className="phase-GridItem" xs={3}>
                    <div className="hc-pa-dense phase-Container">
                      <p className="title-name hc-mb-none">
                        Sign List Finalization
                      </p>
                      <Divider width={3} />
                      <div className="hc-mt-expanded">
                        {scheduleTemplateByScheduleTemplateId.milestones
                          .filter(
                            (milestone: MilestoneTemplate) =>
                              milestone.project_phase ===
                              'Sign List Finalization',
                          )
                          .sort(
                            (a: any, b: any) => b['days_out'] - a['days_out'],
                          )
                          .map((filteredMilestone) => (
                            <div className="hc-mb-md">
                              <BlueprintMilestoneCard
                                milestone={filteredMilestone}
                                disabled={true}
                              />
                            </div>
                          ))}
                      </div>
                    </div>
                  </Grid.Item>

                  <Grid.Item className="phase-GridItem" xs={3}>
                    <div className="hc-pa-dense phase-Container">
                      <p className="title-name hc-mb-none">
                        Production to Market
                      </p>
                      <Divider width={3} />
                      <div className="hc-mt-expanded">
                        {scheduleTemplateByScheduleTemplateId.milestones
                          .filter(
                            (milestone: MilestoneTemplate) =>
                              milestone.project_phase ===
                              'Production to Market',
                          )
                          .sort(
                            (a: any, b: any) => b['days_out'] - a['days_out'],
                          )
                          .map((filteredMilestone) => (
                            <div className="hc-mb-md">
                              <BlueprintMilestoneCard
                                milestone={filteredMilestone}
                                disabled={true}
                              />
                            </div>
                          ))}
                      </div>
                    </div>
                  </Grid.Item>
                </Grid.Container>
              </Fragment>
            ) : (
              <p className="hc-clr-grey03 hc-mb-normal">
                Please select a Master Template or Custom Template to load to
                this page.
              </p>
            )}
          </Grid.Item>
        </Grid.Container>
      </Grid.Item>
    </Grid.Container>
  )
}

export default BlueprintMilestoneTab
