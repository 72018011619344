import React, { useState, Fragment, useEffect } from 'react'
import { Grid, Divider, Tabs, Card } from '@enterprise-ui/canvas-ui-react'
import MainSectionHeader from '../../../App/components/MainSectionHeader'
import AdminScheduleTab from './AdminSchedule/AdminScheduleTab'
import AdminMilestoneListTab from './AdminMilestone/AdminMilestoneListTab'
import { TabName } from '../constants/adminMilestoneConstants'
import { useNavigate, useLocation } from 'react-router-dom'
import { useAppContext } from '../../../App/context/appContext'

const AdminMilestone = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [activeTab, setActiveTab] = useState('')
  const { setPageHasChanges /*pageHasChanges*/ } = useAppContext()!

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    setActiveTab(params.get('tab') || TabName.SCHEDULES)
  }, [setPageHasChanges, location])

  const handleTabChange = (e: any, tab: any) => {
    const params = new URLSearchParams(location.search)
    params.set('tab', tab.name)
    navigate(`${location.pathname}?${params.toString()}`)
  }

  return (
    <Fragment>
      <MainSectionHeader sectionTitle="Admin Milestones" />
      <Card className="hc-pa-normal">
        <Grid.Container className="hc-mb-dense" justify="space-between">
          <Grid.Item className="hc-mb-dense" xs={12}>
            <Tabs
              className="hc-mb-none"
              onTabSelect={handleTabChange}
              activeTab={activeTab}
            >
              <Tabs.Item name={TabName.SCHEDULES}>Schedule Template</Tabs.Item>
              <Tabs.Item name={TabName.MILESTONES}>Milestones List</Tabs.Item>
              <Divider />
            </Tabs>
          </Grid.Item>
        </Grid.Container>
        <div className="hc-pa-dense">
          <Grid.Container className="hc-pb-expanded">
            {activeTab === TabName.SCHEDULES && <AdminScheduleTab />}
            {activeTab === TabName.MILESTONES && <AdminMilestoneListTab />}
          </Grid.Container>
        </div>
      </Card>
    </Fragment>
  )
}

export default AdminMilestone
