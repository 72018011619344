import React, { useEffect, useState } from 'react'
import { Divider, Grid } from '@enterprise-ui/canvas-ui-react'
import { Count } from '../../../../models/dashboardV2/dto/Count.model'

export type Props = {
  name: string
  aggregateCounts: Count[]
}

const ProjectManagerSection = ({ name, aggregateCounts }: Props) => {
  const [totalCount, setTotalCount] = useState(0)

  useEffect(() => {
    setTotalCount(
      aggregateCounts.reduce(
        (acc: number, currentValue: any) => acc + currentValue.count,
        0,
      ),
    )
  }, [aggregateCounts])

  return (
    <Grid.Container>
      <Grid.Item className="sem_DashboardProjectManagerSectionName">
        {name}:
      </Grid.Item>
      <Grid.Item className="sem_DashboardProjectManagerCount sem_DashboardProjectManagerCount--white">
        {totalCount}
      </Grid.Item>
      {totalCount > 0 ? (
        <Divider
          className="sem_DashboardProjectManagerDivider"
          direction="vertical"
          width={2}
        />
      ) : null}
      {aggregateCounts
        .filter((count: any) => count.count > 0)
        .map((count: any) => (
          <Grid.Item
            className={`sem_DashboardProjectManagerCount sem_DashboardProjectManagerCount--${count.color}`}
            key={count.count}
          >
            {count.count}
          </Grid.Item>
        ))}
    </Grid.Container>
  )
}

export default ProjectManagerSection
