import React, { Fragment } from 'react'
import { Grid, Heading, Button, Divider } from '@enterprise-ui/canvas-ui-react'
import { useSignDetailsContext } from '../context/signDetailsContext'
import { SIGN_DETAILS_SIDEBAR } from '../constants/signDetailsConstants'
import { useSignPreviewContext } from '../context/signPreviewContext'
import { SIGN_PREVIEW_TYPES } from '../constants/signMessagingFacets'
import { useSignFormsContext } from '../context/signFormsContext'
import EnterpriseIcon, { ImagesIcon } from '@enterprise-ui/icons'

const SignDetailsSidebar = () => {
  const { openSidebar, setOpenSidebar, currentSign } = useSignDetailsContext()!
  const { refreshSignPreview, previewSrcUrl } = useSignPreviewContext()!
  const { signPreviewFormik } = useSignFormsContext()!

  return (
    <div className="sem_SidebarBtnContainer">
      <Grid.Container>
        <Grid.Item>
          <Button
            type="ghost"
            className={`sem_SidebarBtn ${
              openSidebar === SIGN_DETAILS_SIDEBAR.GALLERY ? 'hidden' : ''
            }`}
            onClick={() => setOpenSidebar(SIGN_DETAILS_SIDEBAR.GALLERY)}
          >
            <EnterpriseIcon icon={ImagesIcon} size="lg" />
            <Heading
              className="hc-clr-grey02 hc-ml-normal hc-txt-uppercase"
              size={5}
            >
              Sign Gallery
            </Heading>
          </Button>
          {(currentSign.sign_type === SIGN_PREVIEW_TYPES.DISPLAY ||
            currentSign.sign_type === SIGN_PREVIEW_TYPES.VALUE) &&
          signPreviewFormik.values.sign_size !== 'OTHER' &&
          signPreviewFormik.values.sign_size !== '' ? (
            <Fragment>
              {openSidebar !== SIGN_DETAILS_SIDEBAR.GALLERY ? (
                <Divider className="hc-ph-normal" direction="vertical" />
              ) : null}
              <Button
                type="ghost"
                className={`sem_SidebarBtn ${
                  openSidebar === SIGN_DETAILS_SIDEBAR.PREVIEW ? 'hidden' : ''
                }`}
                onClick={() => {
                  !previewSrcUrl && refreshSignPreview(signPreviewFormik.values)
                  setOpenSidebar(SIGN_DETAILS_SIDEBAR.PREVIEW)
                }}
              >
                <EnterpriseIcon icon={ImagesIcon} size="lg" />
                <Heading
                  className="hc-clr-grey02 hc-ml-normal hc-txt-uppercase"
                  size={5}
                >
                  Sign Preview
                </Heading>
              </Button>
            </Fragment>
          ) : null}
          {openSidebar === SIGN_DETAILS_SIDEBAR.GALLERY ? (
            <Divider className="hc-ph-normal" direction="vertical" />
          ) : null}
        </Grid.Item>
      </Grid.Container>
    </div>
  )
}

export default SignDetailsSidebar
