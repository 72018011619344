import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { Form, Grid } from '@enterprise-ui/canvas-ui-react'
import { Column } from 'ag-grid-community'

export interface Props {
  value: string
  column: Column
}

const TextAreaCellEditor = forwardRef(({ value, column }: Props, ref) => {
  const [editValue, setEditValue] = useState(value)
  const refInput = useRef<HTMLInputElement>(null)

  const handleChange = (value: string) => setEditValue(value)

  useEffect(() => {
    setTimeout(() => refInput?.current?.focus())
  }, [])

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return editValue
      },
    }
  })

  return (
    <div className="hc-pa-dense">
      <Grid.Container className="sem_StandardForm error_messaging_enabled wide-form">
        <Grid.Item xs={12}>
          <Form.Field
            id="text_cell"
            type="textarea"
            ref={refInput}
            label={column.getColDef().headerName}
            value={editValue}
            onChange={(e: any) => handleChange(e.target.value)}
            size="dense"
          />
        </Grid.Item>
      </Grid.Container>
    </div>
  )
})

export default TextAreaCellEditor
