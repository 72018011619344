import React, { Fragment, useEffect, useState } from 'react'
import { Button, Form, Grid, Modal } from '@enterprise-ui/canvas-ui-react'
import { useSignDetailsContext } from '../../../context/signDetailsContext'
import { useSignNotesContext } from '../context/signNotesContext'
import { NoteType } from '../../../../../models/signs/SignNotes.model'
import { useUserContext } from 'components/App/context/userContext'
import '../../../styles/signDetailsStyles.scss'
import EnterpriseIcon, { CancelIcon, SaveIcon } from '@enterprise-ui/icons'

const SignNotes = () => {
  const { currentSign } = useSignDetailsContext()!
  const [snpNoteContent, setSnpNoteContent] = useState(String)
  const [separatorNoteContent, setSeparatorNoteContent] = useState(String)
  const [plannerNoteContent, setPlannerNoteContent] = useState(String)
  const [ippNoteContent, setIPPNoteContent] = useState(String)
  const [sptContent, setSptContent] = useState(String)
  const { userName } = useUserContext()!
  const [openSNPNotes, setOpenSNPNotes] = useState(false)
  const [openSeparatorNotes, setOpenSeparatorNotes] = useState(false)
  const [openPlannerNotes, setOpenPlannerNotes] = useState(false)
  const [openIPPNotes, setOpenIPPNotes] = useState(false)
  const [openSptNotes, setOpenSptNotes] = useState(false)

  let snpNote: NoteType = {
    author: '',
    timestamp: new Date(),
    content: '',
  }
  let separatorNote: NoteType = {
    author: '',
    timestamp: new Date(),
    content: '',
  }
  let plannerNote: NoteType = {
    author: '',
    timestamp: new Date(),
    content: '',
  }
  let ippNote: NoteType = {
    author: '',
    timestamp: new Date(),
    content: '',
  }
  let sptNote: NoteType = {
    author: '',
    timestamp: new Date(),
    content: '',
  }

  const {
    getSignNotes,
    isSignNotesOpen,
    setIsSignNotesOpen,
    // isSignNotesFormOpen,
    setIsSignNotesFormOpen,
    editSignNote,
    addSignNote,
  } = useSignNotesContext()!

  useEffect(() => {
    currentSign.sign_id && getSignNotes(currentSign.sign_id)
  }, [getSignNotes, currentSign.sign_id])

  const updateNote = (
    currentNote: any,
    newNoteContent: string,
    noteType: string,
  ) => {
    if (currentNote !== undefined) {
      currentNote.content = newNoteContent
      editSignNote(currentNote, currentSign.sign_id, noteType)
    } else {
      currentNote = {
        author: userName,
        content: newNoteContent,
        timestamp: new Date(),
      }
      addSignNote(currentSign.sign_id, currentNote, noteType)
    }
  }

  if (snpNote === undefined) {
    if (currentSign.sign_notes.snp_note !== undefined) {
      snpNote = currentSign.sign_notes.snp_note
    }
  }
  if (separatorNote === undefined) {
    if (currentSign.sign_notes.separator_note !== undefined) {
      separatorNote = currentSign.sign_notes.separator_note
    }
  }
  if (plannerNote === undefined) {
    if (currentSign.sign_notes.planner_note !== undefined) {
      plannerNote = currentSign.sign_notes.planner_note
    }
  }
  if (ippNote === undefined) {
    if (currentSign.sign_notes.ipp_note !== undefined) {
      ippNote = currentSign.sign_notes.ipp_note
    }
  }
  if (sptNote === undefined) {
    if (currentSign.sign_notes.spt_note !== undefined) {
      sptNote = currentSign.sign_notes.spt_note
    }
  }

  snpNote.content = snpNoteContent
  separatorNote.content = separatorNoteContent
  plannerNote.content = plannerNoteContent
  ippNote.content = ippNoteContent
  sptNote.content = sptContent

  useEffect(() => {
    if (currentSign.sign_notes.snp_note) {
      setSnpNoteContent(currentSign.sign_notes.snp_note.content)
    } else {
      setSnpNoteContent('')
    }
  }, [currentSign.sign_notes])

  useEffect(() => {
    if (currentSign.sign_notes.separator_note) {
      setSeparatorNoteContent(currentSign.sign_notes.separator_note.content)
    } else {
      setSeparatorNoteContent('')
    }
  }, [currentSign.sign_notes])

  useEffect(() => {
    if (currentSign.sign_notes.planner_note) {
      setPlannerNoteContent(currentSign.sign_notes.planner_note.content)
    } else {
      setPlannerNoteContent('')
    }
  }, [currentSign.sign_notes])

  useEffect(() => {
    if (currentSign.sign_notes.ipp_note) {
      setIPPNoteContent(currentSign.sign_notes.ipp_note.content)
    } else {
      setIPPNoteContent('')
    }
  }, [currentSign.sign_notes])

  useEffect(() => {
    if (currentSign.sign_notes.spt_note) {
      setSptContent(currentSign.sign_notes.spt_note.content)
    } else {
      setSptContent('')
    }
  }, [currentSign.sign_notes])

  return (
    <div className="sem_SignNotesContainer">
      <Fragment>
        <Modal
          headingText="Sign Notes"
          isVisible={isSignNotesOpen}
          onRefuse={() => {
            setIsSignNotesOpen(false)
            setIsSignNotesFormOpen(false)
          }}
        >
          <Grid.Container
            className="hc-pl-normal sem_StandardForm error_messaging_enabled"
            align="center"
            direction="row-reverse"
          >
            <Grid.Item xs={12} className="hc-pb-none">
              <Form.Field
                id="distribution_notes"
                className="hc-pr-normal hc-pt-normal hc-pb-none hc-pl-none"
                type="textarea"
                grow={true}
                label="Placement Notes"
                hintText={
                  currentSign.sign_notes.snp_note
                    ? `By ${currentSign.sign_notes.snp_note.author.toString()} on ${
                        currentSign.sign_notes.snp_note.timestamp
                      }`
                    : ''
                }
                placeholder="Add a Placement note here..."
                value={snpNoteContent}
                onChange={(e: { target: { value: any } }) => {
                  setSnpNoteContent(e.target.value)
                }}
                onClick={() => {
                  setOpenSNPNotes(true)
                  setOpenSeparatorNotes(false)
                  setOpenPlannerNotes(false)
                  setOpenIPPNotes(false)
                  setOpenSptNotes(false)
                }}
              />
            </Grid.Item>
            {openSNPNotes && (
              <>
                <Grid.Item
                  className="hc-pr-expanded hc-pt-none hc-pb-expanded"
                  xs={4}
                >
                  <Button
                    fullWidth
                    type="primary"
                    onClick={() => {
                      setOpenSNPNotes(false)
                      updateNote(
                        currentSign.sign_notes.snp_note,
                        snpNoteContent,
                        'snp_note',
                      )
                    }}
                  >
                    <EnterpriseIcon
                      className="hc-pr-dense"
                      size="xl"
                      icon={SaveIcon}
                    />
                    SAVE
                  </Button>
                </Grid.Item>
                <Grid.Item
                  className="hc-pl-expanded  hc-pt-none  hc-pb-expanded"
                  xs={4}
                >
                  <Button
                    type="secondary"
                    fullWidth
                    onClick={() => {
                      setOpenSNPNotes(false)
                    }}
                  >
                    <EnterpriseIcon
                      className="hc-pr-dense"
                      size="md"
                      icon={CancelIcon}
                    />
                    CANCEL
                  </Button>
                </Grid.Item>
              </>
            )}
          </Grid.Container>
          {/* SEPARATOR NOTE */}
          <Grid.Container
            className="hc-pl-normal sem_StandardForm error_messaging_enabled"
            align="center"
            direction="row-reverse"
          >
            <Grid.Item xs={12} className="hc-pb-none">
              <Form.Field
                id="separator_notes"
                className="hc-pr-normal hc-pt-normal hc-pb-none hc-pl-none"
                type="textarea"
                grow={true}
                label="Separator Notes"
                hintText={
                  currentSign.sign_notes.separator_note
                    ? `By ${currentSign.sign_notes.separator_note.author.toString()} on ${
                        currentSign.sign_notes.separator_note.timestamp
                      }`
                    : ''
                }
                placeholder="Add a Separator note here..."
                value={separatorNoteContent}
                onChange={(e: { target: { value: any } }) => {
                  setSeparatorNoteContent(e.target.value)
                }}
                onClick={() => {
                  setOpenSeparatorNotes(true)
                  setOpenSNPNotes(false)
                  setOpenPlannerNotes(false)
                  setOpenIPPNotes(false)
                  setOpenSptNotes(false)
                }}
              />
            </Grid.Item>
            {openSeparatorNotes && (
              <>
                <Grid.Item
                  className="hc-pr-expanded hc-pt-none hc-pb-expanded"
                  xs={4}
                >
                  <Button
                    fullWidth
                    type="primary"
                    onClick={() => {
                      setOpenSeparatorNotes(false)
                      updateNote(
                        currentSign.sign_notes.separator_note,
                        separatorNoteContent,
                        'separator_note',
                      )
                    }}
                  >
                    <EnterpriseIcon
                      className="hc-pr-dense"
                      size="md"
                      icon={SaveIcon}
                    />
                    SAVE
                  </Button>
                </Grid.Item>
                <Grid.Item
                  className="hc-pl-expanded  hc-pt-none hc-pb-expanded"
                  xs={4}
                >
                  <Button
                    type="secondary"
                    fullWidth
                    onClick={() => {
                      setOpenSeparatorNotes(false)
                    }}
                  >
                    <EnterpriseIcon
                      className="hc-pr-dense"
                      size="md"
                      icon={CancelIcon}
                    />
                    CANCEL
                  </Button>
                </Grid.Item>
              </>
            )}
          </Grid.Container>

          {/* PLANNER NOTE  */}
          <Grid.Container
            className="hc-pl-normal sem_StandardForm error_messaging_enabled"
            align="center"
            direction="row-reverse"
          >
            <Grid.Item xs={12} className="hc-pb-none">
              <Form.Field
                id="planner_notes"
                className="hc-pr-normal hc-pt-normal hc-pb-none hc-pl-none"
                type="textarea"
                grow={true}
                label="Planner Notes"
                hintText={
                  currentSign.sign_notes.planner_note
                    ? `By ${currentSign.sign_notes.planner_note.author.toString()} on ${
                        currentSign.sign_notes.planner_note.timestamp
                      }`
                    : ''
                }
                placeholder="Add a Planner note here..."
                value={plannerNoteContent}
                onChange={(e: { target: { value: any } }) => {
                  setPlannerNoteContent(e.target.value)
                }}
                onClick={() => {
                  setOpenPlannerNotes(true)
                  setOpenSNPNotes(false)
                  setOpenSeparatorNotes(false)
                  setOpenIPPNotes(false)
                  setOpenSptNotes(false)
                }}
              />
            </Grid.Item>
            {openPlannerNotes && (
              <>
                <Grid.Item
                  className="hc-pr-expanded hc-pt-none hc-pb-expanded"
                  xs={4}
                >
                  <Button
                    fullWidth
                    type="primary"
                    onClick={() => {
                      setOpenPlannerNotes(false)
                      updateNote(
                        currentSign.sign_notes.planner_note,
                        plannerNoteContent,
                        'planner_note',
                      )
                    }}
                  >
                    <EnterpriseIcon
                      className="hc-pr-dense"
                      size="md"
                      icon={SaveIcon}
                    />
                    SAVE
                  </Button>
                </Grid.Item>
                <Grid.Item
                  className="hc-pl-expanded  hc-pt-none hc-pb-expanded"
                  xs={4}
                >
                  <Button
                    type="secondary"
                    fullWidth
                    onClick={() => {
                      setOpenPlannerNotes(false)
                    }}
                  >
                    <EnterpriseIcon
                      className="hc-pr-dense"
                      size="md"
                      icon={CancelIcon}
                    />
                    CANCEL
                  </Button>
                </Grid.Item>
              </>
            )}
          </Grid.Container>

          {/* IPP NOTE  */}
          <Grid.Container
            className="hc-pl-normal sem_StandardForm error_messaging_enabled"
            align="center"
            direction="row-reverse"
          >
            <Grid.Item xs={12} className="hc-pb-none">
              <Form.Field
                id="ipp_note"
                className="hc-pr-normal hc-pt-normal hc-pb-none hc-pl-none "
                type="textarea"
                grow={true}
                label="IPP Note"
                hintText={
                  currentSign.sign_notes.ipp_note
                    ? `By ${currentSign.sign_notes.ipp_note.author.toString()} on ${
                        currentSign.sign_notes.ipp_note.timestamp
                      }`
                    : ''
                }
                placeholder="Add an IPP note here..."
                value={ippNoteContent}
                onChange={(e: { target: { value: any } }) => {
                  setIPPNoteContent(e.target.value)
                }}
                onClick={() => {
                  setOpenPlannerNotes(false)
                  setOpenSNPNotes(false)
                  setOpenSeparatorNotes(false)
                  setOpenIPPNotes(true)
                  setOpenSptNotes(false)
                }}
              />
            </Grid.Item>
            {openIPPNotes && (
              <>
                <Grid.Item
                  className="hc-pr-expanded hc-pt-none hc-pb-expanded"
                  xs={4}
                >
                  <Button
                    fullWidth
                    type="primary"
                    onClick={() => {
                      setOpenIPPNotes(false)
                      updateNote(
                        currentSign.sign_notes.ipp_note,
                        ippNoteContent,
                        'ipp_note',
                      )
                    }}
                  >
                    <EnterpriseIcon
                      className="hc-pr-dense"
                      size="md"
                      icon={SaveIcon}
                    />
                    SAVE
                  </Button>
                </Grid.Item>
                <Grid.Item
                  className="hc-pl-expanded  hc-pt-none hc-pb-expanded"
                  xs={4}
                >
                  <Button
                    type="secondary"
                    fullWidth
                    onClick={() => {
                      setOpenIPPNotes(false)
                    }}
                  >
                    <EnterpriseIcon
                      className="hc-pr-dense"
                      size="md"
                      icon={CancelIcon}
                    />
                    CANCEL
                  </Button>
                </Grid.Item>
              </>
            )}
          </Grid.Container>
          <Grid.Container
            className="hc-pl-normal sem_StandardForm error_messaging_enabled"
            align="center"
            direction="row-reverse"
          >
            <Grid.Item xs={12} className="hc-pb-none">
              <Form.Field
                id="ipp_note"
                className="hc-pr-normal hc-pt-normal hc-pb-none hc-pl-none"
                type="textarea"
                grow={true}
                label="SPT Note"
                hintText={
                  currentSign.sign_notes.spt_note
                    ? `By ${currentSign.sign_notes.spt_note.author.toString()} on ${
                        currentSign.sign_notes.spt_note.timestamp
                      }`
                    : ''
                }
                placeholder="Add an SPT note here..."
                value={sptContent}
                onChange={(e: { target: { value: any } }) => {
                  setSptContent(e.target.value)
                }}
                onClick={() => {
                  setOpenSNPNotes(false)
                  setOpenPlannerNotes(false)
                  setOpenSeparatorNotes(false)
                  setOpenIPPNotes(false)
                  setOpenSptNotes(true)
                }}
              />
            </Grid.Item>
            {openSptNotes && (
              <>
                <Grid.Item
                  className="hc-pr-expanded hc-pt-none hc-pb-expanded"
                  xs={4}
                >
                  <Button
                    fullWidth
                    type="primary"
                    onClick={() => {
                      setOpenSptNotes(false)
                      updateNote(
                        currentSign.sign_notes.spt_note,
                        sptContent,
                        'spt_note',
                      )
                    }}
                  >
                    <EnterpriseIcon
                      className="hc-pr-dense"
                      size="md"
                      icon={SaveIcon}
                    />
                    SAVE
                  </Button>
                </Grid.Item>
                <Grid.Item
                  className="hc-pl-expanded  hc-pt-none hc-pb-expanded"
                  xs={4}
                >
                  <Button
                    type="secondary"
                    fullWidth
                    onClick={() => {
                      setOpenSptNotes(false)
                    }}
                  >
                    <EnterpriseIcon
                      className="hc-pr-dense"
                      size="md"
                      icon={CancelIcon}
                    />
                    CANCEL
                  </Button>
                </Grid.Item>
              </>
            )}
            <Grid.Item></Grid.Item>
          </Grid.Container>
        </Modal>
      </Fragment>
    </div>
  )
}

export default SignNotes
