import moment from 'moment'
import { MilestoneTemplate } from '../milestones/MilestoneTemplate.model'

export class BlueprintSummary {
  blueprint_id: string
  name: string
  set_date: string
  due_date: string
  health: string
  health_info: HealthInfo
  milestone_health: string
  milestone_health_info: HealthInfo
  current_milestone?: MilestoneTemplate
  campaign_type: string
  type: string
  tier_one_project: boolean
  incremental_project: boolean

  constructor(props: any = {}) {
    this.blueprint_id = props.blueprint_id || ''
    this.name = props.name || ''
    this.set_date = props.set_date || ''
    this.due_date =
      props.current_milestone &&
      parseInt(
        moment(props.set_date)
          .subtract(props.current_milestone.days_out, 'days')
          .format('x'),
      ).toString()
    this.health = props.health || ''
    this.health_info = new HealthInfo(props.health)
    this.milestone_health = props.milestone_health || ''
    this.milestone_health_info = new HealthInfo(props.milestone_health)
    this.current_milestone = props.current_milestone || new MilestoneTemplate()
    this.campaign_type = props.campaign_type || ''
    this.type = props.type || ''
    this.tier_one_project = props.tier_one_project
    this.incremental_project = props.incremental_project
  }
}

export class BlueprintSummarySearchResult {
  result: BlueprintSummary

  constructor(props: any = {}) {
    this.result = new BlueprintSummary(props.result)
  }
}

export class HealthInfo {
  color: string
  label: string

  constructor(health: string = '') {
    this.color = health
    this.label =
      health === 'green'
        ? 'On Track'
        : health === 'yellow'
        ? 'Past Due'
        : health === 'red'
        ? 'At Risk'
        : health === 'blue'
        ? 'Initialized'
        : 'N/A'
  }
}
