import React from 'react'
import SignTemplateResponse from '../../../../../models/signTemplates/SignTemplateResponse.model'
import { Button, Dropdown } from '@enterprise-ui/canvas-ui-react'
import { RowNode } from 'ag-grid-community'
import { useStandardSignAdminContext } from '../../context/standardSignAdminContext'
import moment from 'moment'
import EnterpriseIcon, {
  ExportIcon,
  OverflowMenuVerticalIcon,
  TrashIcon,
} from '@enterprise-ui/icons'

export interface Props {
  data: SignTemplateResponse
  node: RowNode
}

const RowDropdownMenu = ({ data, node }: Props) => {
  const {
    deleteSignTemplates,
    setCurrentSignType,
    setIsMoveSignsModalOpen,
    setCurrentStandardSign,
  } = useStandardSignAdminContext()!

  const handleMoveSigns = () => {
    setCurrentSignType(data.type)
    setCurrentStandardSign(data.name)
    setIsMoveSignsModalOpen(true)
  }

  return (
    <Dropdown location="bottom-left">
      <Button
        aria-label="Table Dropdown Menu"
        type="ghost"
        className="sem_IconBtn-dark"
        iconOnly
        size="dense"
      >
        <EnterpriseIcon icon={OverflowMenuVerticalIcon} size="sm" />
      </Button>
      <Dropdown.Menu>
        <Dropdown.MenuItem as="div" className="sem_DropdownMenuItem--info">
          <span className="sem_StrongText hc-mr-dense">Last Modified:</span>
          {data.last_updated_date
            ? moment(data.last_updated_date).format('MM/DD/YYYY hh:mm A')
            : null}
        </Dropdown.MenuItem>
        <Dropdown.MenuItem
          as="div"
          className="sem_DropdownMenuItem--info divider"
        >
          <span className="sem_StrongText hc-mr-dense">Modified By:</span>
          {data.last_modified_by}
        </Dropdown.MenuItem>
        <Dropdown.MenuItem
          className="sem_DropdownMenuItem--action"
          onClick={() => deleteSignTemplates([node])}
        >
          <EnterpriseIcon className="hc-mr-dense" icon={TrashIcon} />
          Delete Standard Sign
        </Dropdown.MenuItem>
        <Dropdown.MenuItem
          className="sem_DropdownMenuItem--action"
          onClick={handleMoveSigns}
          disabled={data.count === undefined || data.count < 1}
        >
          <EnterpriseIcon className="hc-mr-dense" icon={ExportIcon} />
          Move Standard Signs
        </Dropdown.MenuItem>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default RowDropdownMenu
