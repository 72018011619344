import React from 'react'
import { Link } from 'react-router-dom'
import { Anchor } from '@enterprise-ui/canvas-ui-react'
import SapTableProject from '../../../../../../../models/projects/SapTableProject.model'
import { NEW_SIGN_PROJECT } from '../../../../SignProjectTab/constants/signProjectConstants'

export interface Props {
  value: string
  data: SapTableProject
}

const RowProjectDetailLink = ({ data, value }: Props) => {
  return (
    <div className="ag-cell-value">
      {data.project_id?.split(':')[0] === NEW_SIGN_PROJECT ? (
        <span className="hc-clr-grey04">{value}</span>
      ) : (
        <Anchor as={Link} to={`/projects/${data?.project_id}`}>
          {value}
        </Anchor>
      )}
    </div>
  )
}

export default RowProjectDetailLink
