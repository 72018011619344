import React from 'react'
import { Button } from '@enterprise-ui/canvas-ui-react'
import { useAddOnContext } from 'components/ProjectDetails/components/AddOnExpenses/context/addOnContext'
import EnterpriseIcon, { CancelIcon } from '@enterprise-ui/icons'

const CancelButton = () => {
  const {
    expensesModified,
    getAddOnExpenses,
    setModifiedExpenses,
    setModifiedExpensesIds,
    setExpensesModified,
    setIsCreatingExpense,
  } = useAddOnContext()!

  const handleCancel = () => {
    getAddOnExpenses()
    setModifiedExpenses({
      update_add_on_expenses_request_dto: [],
      expenses_status: '',
    })
    setModifiedExpensesIds([])
    setExpensesModified(false)
    setIsCreatingExpense(false)
  }

  return (
    <Button
      type="ghost"
      disabled={!expensesModified}
      onClick={() => handleCancel()}
      className="hc-mr-dense"
    >
      <EnterpriseIcon icon={CancelIcon} className="hc-mr-dense" />
      Cancel Changes
    </Button>
  )
}

export default CancelButton
