export default class AddOnExpenses {
  id?: string
  print_vendor?: string
  expenses_type: string
  cost: number
  po_number: string
  vendor_notes: string
  expenses_status?: string
  hasError: boolean
  modified: boolean
  updated_by: string
  updated_date: string

  constructor(props: any = {}) {
    this.id = props.id
    this.print_vendor = props.print_vendor
    this.expenses_type = props.expenses_type || ''
    this.cost = props.cost
    this.po_number = props.po_number || ''
    this.vendor_notes = props.vendor_notes || ''
    this.expenses_status = props.expenses_status
    this.hasError = props.hasError || false
    this.modified = props.modified || false
    this.updated_by = props.updated_by
    this.updated_date = props.updated_date
  }
}
