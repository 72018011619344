import { useFormik } from 'formik'
import PricingRequest from '../../../models/signs/PricingRequest.model'
import PricingResponse from '../../../models/signs/PricingResponse.model'
import SignResponse from '../../../models/signs/SignResponse.model'
import { SIGN_DETAILS_SECTION } from '../constants/signDetailsConstants'

export const useSignPricingForm = (
  currentSign: SignResponse,
  updateSignSection: (
    req: PricingRequest,
    slug: SIGN_DETAILS_SECTION,
    sign_id: string,
  ) => void,
) => {
  return useFormik({
    enableReinitialize: true,
    initialValues: new PricingResponse(currentSign.pricing),
    onSubmit(values) {
      updateSignSection(
        new PricingRequest({ ...values, updated_by: currentSign.updated_by }),
        SIGN_DETAILS_SECTION.PRICING,
        currentSign.sign_id,
      )
    },
  })
}
