import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { DialogProps } from '../../../models/app/DialogProps.model'
import {
  STANDARD_VIEW,
  STANDARD_VIEW_OLD,
} from '../../ProjectDetails/components/ProjectSignList/views/AgGridSignListViews'
import { AgGridSignListView } from '../../../models/agGrid/AgGridSignListView.model'
import { TabName } from 'components/DashboardV2/constants/dashboardConstants'
import { useUserContext } from './userContext'
import {
  ADMIN_SERVICE_API_DOMAIN_URL,
  BLUEPRINT_PROJECT_LIST_VIEW,
  TOASTER_DEFAULTS,
} from '../constants/appConstants'
import {
  SIGN_LIBRARY_STANDARD_VIEW,
  SIGN_LIBRARY_STANDARD_VIEW_OLD,
} from '../../SignLibrary/views/AgGridSignLibraryView'
import { ColumnState } from 'ag-grid-community'
import axios, { AxiosError } from 'axios'
import { useEnv } from '@praxis/component-runtime-env'
import { DropdownOption } from '../../../models/app/DropdownOption.model'
import Axios from 'axios'
import { useToaster } from '@enterprise-ui/canvas-ui-react'
import { ContractCore } from '../../../models/contracts/ContractCore.model'

type ContextType = {
  fullPageLoadingMessage: string
  setFullPageLoadingMessage: (message: string) => void
  dialogProps: DialogProps
  setDialogProps: (dialogProps: DialogProps) => void
  pageHasChanges: boolean
  setPageHasChanges: (hasChanges: boolean) => void
  /* === Sign Library state  === */
  signLibraryView: AgGridSignListView
  setSignLibraryView: Function
  signLibrarySavedFilter: any
  setSignLibrarySavedFilter: Function
  signLibrarySortState: any
  setSignLibrarySortState: Function
  /* === Project Sign List state ===*/
  projectSignListView: AgGridSignListView
  setProjectSignListView: Function
  projectSignListSavedFilter: any
  setProjectSignListSavedFilter: Function
  projectSignListSortState: ColumnState[] | undefined
  setProjectSignListSortState: Function
  /* === Dashboard: Active Tab state ===*/
  dashboardActiveTab: TabName
  setDashboardActiveTab: (tab: TabName) => void
  /* === Dashboard: My Blueprint Milestone state ===*/
  blueprintMilestoneSavedFilter: any
  setBlueprintMilestoneSavedFilter: Function
  blueprintMilestoneSortState: ColumnState[] | undefined
  setBlueprintMilestoneSortState: Function
  /* === Dashboard: My Project Milestone state ===*/
  projectMilestoneSavedFilter: any
  setProjectMilestoneSavedFilter: Function
  projectMilestoneSortState: ColumnState[] | undefined
  setProjectMilestoneSortState: Function
  /* === Dashboard: Blueprint Summary state ===*/
  blueprintSummarySavedFilter: any
  setBlueprintSummarySavedFilter: Function
  blueprintSummarySortState: ColumnState[] | undefined
  setBlueprintSummarySortState: Function
  /* === Dashboard: Project Summary state ===*/
  projectSummarySavedFilter: any
  setProjectSummarySavedFilter: Function
  projectSummarySortState: ColumnState[] | undefined
  setProjectSummarySortState: Function
  /* === SAP Project List state ===*/
  blueprintProjectListView: BLUEPRINT_PROJECT_LIST_VIEW
  setBlueprintProjectListView: (view: BLUEPRINT_PROJECT_LIST_VIEW) => void
  blueprintProjectListSavedFilter: any
  setBlueprintProjectListSavedFilter: Function
  blueprintProjectListSortState: ColumnState[] | undefined
  setBlueprintProjectListSortState: Function
  /* === Notifications List state === */
  notificationsSortState: ColumnState[] | undefined
  setNotificationsSortState: Function
  notificationsFilter: any
  setNotificationsFilter: Function
  notificationCount: number
  setNotificationCount: (count: number) => void
  getNotificationCount: () => void
  /* === Sign Template Sort State === */
  signTemplateSortState: ColumnState[] | undefined
  setSignTemplateSortState: Function
  signTemplateFilter: any
  setSignTemplateFilter: Function
  getPogCategoryList: () => void
  pogCategoryOptions: DropdownOption<any>[]
  getActiveContracts: () => Promise<ContractCore[]>
}

export const AppContext = createContext<ContextType | undefined>(undefined)

type Props = { children: React.ReactNode }

export const AppProvider = ({ children }: Props) => {
  const { userPermissions } = useUserContext()!
  const env = useEnv()
  const makeToast = useToaster()

  const [fullPageLoadingMessage, setFullPageLoadingMessage] = useState('')
  const [dialogProps, setDialogProps] = useState(new DialogProps())
  const [pageHasChanges, setPageHasChanges] = useState(false)

  /* === Sign Library state  === */
  const [signLibraryView, setSignLibraryView] = useState(
    env.beskarFeature
      ? SIGN_LIBRARY_STANDARD_VIEW
      : SIGN_LIBRARY_STANDARD_VIEW_OLD,
  )
  const [signLibrarySavedFilter, setSignLibrarySavedFilter] = useState({})
  const [signLibrarySortState, setSignLibrarySortState] = useState()

  /* === Project Sign List state ===*/
  const [projectSignListView, setProjectSignListView] = useState(
    env.beskarFeature ? STANDARD_VIEW : STANDARD_VIEW_OLD,
  )
  const [projectSignListSavedFilter, setProjectSignListSavedFilter] = useState(
    {},
  )
  const [projectSignListSortState, setProjectSignListSortState] = useState()

  const [dashboardActiveTab, setDashboardActiveTab] = useState(
    TabName.MY_BLUEPRINT_MILESTONES,
  )
  /* === Dashboard: My Blueprint Milestone state ===*/
  const [blueprintMilestoneSavedFilter, setBlueprintMilestoneSavedFilter] =
    useState({})
  const [blueprintMilestoneSortState, setBlueprintMilestoneSortState] =
    useState()
  /* === Dashboard: My Project Milestone state ===*/
  const [projectMilestoneSavedFilter, setProjectMilestoneSavedFilter] =
    useState({})
  const [projectMilestoneSortState, setProjectMilestoneSortState] = useState()
  /* === Dashboard: Blueprint Summary state ===*/
  const [blueprintSummarySavedFilter, setBlueprintSummarySavedFilter] =
    useState({})
  const [blueprintSummarySortState, setBlueprintSummarySortState] = useState()
  /* === Dashboard: Project Summary state ===*/
  const [projectSummarySavedFilter, setProjectSummarySavedFilter] = useState({})
  const [projectSummarySortState, setProjectSummarySortState] = useState()
  /* === SAP Project List state ===*/
  const [blueprintProjectListView, setBlueprintProjectListView] = useState(
    BLUEPRINT_PROJECT_LIST_VIEW.ACTIVE,
  )
  const [blueprintProjectListSavedFilter, setBlueprintProjectListSavedFilter] =
    useState({})
  const [blueprintProjectListSortState, setBlueprintProjectListSortState] =
    useState()

  const [notificationsSortState, setNotificationsSortState] = useState()
  const [notificationsFilter, setNotificationsFilter] = useState({})
  const [notificationCount, setNotificationCount] = useState(0)

  const [signTemplateSortState, setSignTemplateSortState] = useState()
  const [signTemplateFilter, setSignTemplateFilter] = useState({})
  const [pogCategoryOptions, setPogCategoryOptions] = useState<
    DropdownOption<any>[]
  >([])

  useEffect(() => {
    setDashboardActiveTab(
      userPermissions.isPrintVendor ||
        userPermissions.isTPSVendor ||
        userPermissions.isKitVendor ||
        userPermissions.isProductVendorOrCatMan
        ? TabName.PROJECT_SUMMARY
        : TabName.MY_BLUEPRINT_MILESTONES,
    )
  }, [userPermissions, setDashboardActiveTab])

  const getNotificationCount = async () => {
    try {
      const res = await axios.get(
        `${env.apiDomainUrl}/notification_services/notifications/counts`,
      )
      setNotificationCount(res.data['UNREAD'])
    } catch (err) {
      console.log((err as AxiosError).message)
    }
  }

  const getPogCategoryList = useCallback(async () => {
    try {
      const res = await Axios.get(
        `${env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL}/pog_category`,
      )
      const options = res.data.pog_category_responses.map(
        (category: any) =>
          new DropdownOption({
            id: category.category_id,
            value: category.category_id,
            label: category.category_name,
          }),
      )
      setPogCategoryOptions(options)
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Get POG Categories',
        message: err.response.data.message,
      })
    }
  }, [env.apiDomainUrl, makeToast])

  const getActiveContracts = useCallback(async () => {
    try {
      const res = await Axios.get(
        `${env.beskarApiUrl}/contracts/v1/contract_definitions`,
      )
      return res.data
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to get active contracts',
        message: err.response.data.message,
      })
    }
  }, [env.beskarApiUrl, makeToast])

  return (
    <AppContext.Provider
      value={{
        fullPageLoadingMessage,
        setFullPageLoadingMessage,
        dialogProps,
        setDialogProps,
        pageHasChanges,
        setPageHasChanges,

        signLibraryView,
        setSignLibraryView,
        signLibrarySavedFilter,
        setSignLibrarySavedFilter,
        signLibrarySortState,
        setSignLibrarySortState,

        projectSignListView,
        setProjectSignListView,
        projectSignListSavedFilter,
        setProjectSignListSavedFilter,
        projectSignListSortState,
        setProjectSignListSortState,

        dashboardActiveTab,
        setDashboardActiveTab,

        blueprintMilestoneSavedFilter,
        setBlueprintMilestoneSavedFilter,
        blueprintMilestoneSortState,
        setBlueprintMilestoneSortState,

        projectMilestoneSavedFilter,
        setProjectMilestoneSavedFilter,
        projectMilestoneSortState,
        setProjectMilestoneSortState,

        blueprintSummarySavedFilter,
        setBlueprintSummarySavedFilter,
        blueprintSummarySortState,
        setBlueprintSummarySortState,

        projectSummarySavedFilter,
        setProjectSummarySavedFilter,
        projectSummarySortState,
        setProjectSummarySortState,

        blueprintProjectListView,
        setBlueprintProjectListView,
        blueprintProjectListSavedFilter,
        setBlueprintProjectListSavedFilter,
        blueprintProjectListSortState,
        setBlueprintProjectListSortState,

        notificationsSortState,
        setNotificationsSortState,
        notificationsFilter,
        setNotificationsFilter,
        notificationCount,
        setNotificationCount,
        getNotificationCount,
        signTemplateSortState,
        setSignTemplateSortState,
        signTemplateFilter,
        setSignTemplateFilter,
        pogCategoryOptions,
        getPogCategoryList,
        getActiveContracts,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export const useAppContext = () => useContext(AppContext)
