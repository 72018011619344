import React from 'react'
import { Grid, Modal, Spinner } from '@enterprise-ui/canvas-ui-react'
import { useProjectSignListContext } from '../../context/projectSignListContext'

const FinalizingSignsModal = () => {
  const { isFinalizingSign, isFinalizingAllSigns } =
    useProjectSignListContext()!
  return (
    <Modal
      className="sem_LoadingModal"
      isVisible={isFinalizingSign || isFinalizingAllSigns}
    >
      <Grid.Container
        className="sign-projects-loading-container"
        direction="column"
        justify="center"
        align="center"
      >
        <Grid.Item>
          {isFinalizingSign
            ? 'Finalizing Signs...'
            : isFinalizingAllSigns
            ? 'Finalizing All Signs...'
            : ''}
        </Grid.Item>
        <Grid.Item>
          <Spinner />
        </Grid.Item>
      </Grid.Container>
    </Modal>
  )
}

export default FinalizingSignsModal
