export class SignNotes {
  snp_note?: NoteType
  separator_note?: NoteType
  planner_note?: NoteType
  ipp_note?: NoteType
  spt_note?: NoteType

  constructor(props: any = {}) {
    this.snp_note = props.snp_note && new NoteType(props.snp_note)
    this.separator_note =
      props.separator_note && new NoteType(props.separator_note)
    this.planner_note = props.planner_note && new NoteType(props.planner_note)
    this.ipp_note = props.ipp_note && new NoteType(props.ipp_note)
    this.spt_note = props.spt_note && new NoteType(props.spt_note)
  }
}

export class NoteType {
  author: string
  timestamp: Date
  content: string

  constructor(props: any = {}) {
    this.author = props.author || ''
    this.timestamp = props.timestamp || new Date()
    this.content = props.content || ''
  }
}
