import React from 'react'
import {
  Grid,
  Form,
  Modal,
  Button,
  Heading,
} from '@enterprise-ui/canvas-ui-react'
import { useProjectSignListContext } from '../../context/projectSignListContext'
import { useDuplicateSignForm } from '../../hooks/useDuplicateSignForm'
import EnterpriseIcon, { CancelIcon } from '@enterprise-ui/icons'
import { useProjectDetailsContext } from '../../../../context/projectDetailsContext'
import SignDuplicateRequest from '../../../../../../models/signs/SignDuplicateRequest.model'

const DuplicateSignModal = () => {
  const { currentProject } = useProjectDetailsContext()!
  const { signForDuplicateModal, setSignForDuplicateModal, duplicateSign } =
    useProjectSignListContext()!

  const { values, errors, setFieldValue, handleSubmit } = useDuplicateSignForm(
    (values: any) => {
      duplicateSign(
        new SignDuplicateRequest({
          project_id: currentProject.project_id,
          sign_id: signForDuplicateModal!.sign_id,
          times: values.numberOfDuplicates,
        }),
        setFieldValue,
      )
    },
  )

  const closeModal = () => {
    setSignForDuplicateModal()
    setFieldValue('numberOfDuplicates', 1)
  }

  return (
    // WARNING: Canvas v7 - Modal "closeButton" prop has been deprecated.
    <Modal
      className="sem_DuplicateSignModal"
      isVisible={signForDuplicateModal !== undefined}
      onRefuse={closeModal}
    >
      <Grid.Container
        justify="space-between"
        className="hc-mb-normal error_messaging_enabled sem_StandardForm"
      >
        <Grid.Item xs={true}>
          <Heading size={4}>
            How many duplicates would you like to make?
          </Heading>
        </Grid.Item>

        <Grid.Item className="sem_Modal__close">
          <Button
            iconOnly
            size="dense"
            className="sem_IconBtn"
            aria-label="Close Modal"
            onClick={() => closeModal()}
          >
            <EnterpriseIcon icon={CancelIcon} />
          </Button>
        </Grid.Item>
        <Form.Field
          id="duplicates"
          label="Duplicates"
          type="number"
          error={errors.numberOfDuplicates !== undefined}
          errorText={errors.numberOfDuplicates}
          hintText="Max 10"
          value={values.numberOfDuplicates}
          min="1"
          max="10"
          onChange={(e: any) =>
            setFieldValue('numberOfDuplicates', e.target.value)
          }
        />
      </Grid.Container>
      <Grid.Container justify="flex-end">
        <Grid.Item className="hc-ml-expanded">
          <Button
            className="hc-mr-expanded"
            type="primary"
            onClick={() => handleSubmit()}
          >
            Submit
          </Button>
          <Button onClick={closeModal}>Cancel</Button>
        </Grid.Item>
      </Grid.Container>
    </Modal>
  )
}

export default DuplicateSignModal
