export class SortState {
  colId: string
  sort: string | null
  sortIndex: number

  constructor(props: any = {}) {
    this.colId = props.colId || ''
    this.sort = props.sort || null
    this.sortIndex = props.sortIndex || 0
  }
}
