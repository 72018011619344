import { CampaignType } from '../campaigns/CampaignType.model'
import { BlueprintMilestone } from '../milestones/BlueprintMilestone.model'
import { RosterElement } from '../roster/RosterElement.model'
import { get } from 'lodash'
import { BlueprintNote } from './BlueprintNote.model'

export class BlueprintDetail {
  blueprint_id: string
  name: string
  status: string
  project_phase: string
  health: string
  set_date: string
  type: string
  planned_budget: number
  tier_one: boolean
  incremental: boolean
  campaign: CampaignType
  sign_volume_estimate: string
  version_complexity: string
  creative_approval_level: string
  milestone?: BlueprintMilestone
  roster: RosterElement[]
  notes: BlueprintNote[]
  projectIds?: string[]
  is_migrated: boolean
  created_by: string
  created_date: string
  updated_by: string
  updated_date: string

  constructor(props: any = {}) {
    this.blueprint_id = props.blueprint_id || ''
    this.name = props.name || ''
    this.status = props.status || 'Initiate'
    this.project_phase = props.project_phase || ''
    this.health = props.health || ''
    this.set_date = props.set_date || ''
    this.type = props.type || ''
    this.planned_budget = props.planned_budget || 0
    this.tier_one = props.tier_one || false
    this.incremental = props.incremental || false
    this.campaign = props.campaign || new CampaignType()
    this.sign_volume_estimate = props.sign_volume_estimate || ''
    this.version_complexity = props.version_complexity || ''
    this.creative_approval_level = props.creative_approval_level || ''
    this.milestone = props.milestone || new BlueprintMilestone()
    this.roster = get(props, 'roster', []).map(
      (rosterElement: any) => new RosterElement(rosterElement),
    )
    this.notes = get(props, 'notes', []).map(
      (note: any) => new BlueprintNote(note),
    )
    this.is_migrated = props.is_migrated || false
    this.created_by = props.created_by || ''
    this.created_date = props.created_date || ''
    this.updated_by = props.updated_by || ''
    this.updated_date = props.updated_date || ''
  }
}
