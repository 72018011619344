import { Column } from '../../../../../models/agGrid/AgGridColumn.model'
import TableChip from '../../ProjectSignList/components/signListViews/components/TableChip'
import NumberCellEditor from '../../ProjectSignList/components/signListViews/components/NumberCellEditor'
import TableToggleComponent from '../../ProjectSignList/components/signListViews/components/TableToggleComponent'
import SignDetailLink from '../../ProjectSignList/components/signListViews/components/TableSignDetailLink'

export const gridOptions = {
  defaultColDef: {
    resizable: true,
    suppressColumnVirtualisation: true,
    sortable: true,
    filter: true,
    width: 148,
  },
  columnTypes: {
    fixed: {
      lockPinned: true,
      resizable: true,
      suppressSizeToFit: false,
      pinned: 'left',
    },
    tableChip: {
      cellRenderer: 'TableChip',
    },
    toggle: {
      cellRenderer: 'TableToggle',
    },
    signDetailLink: {
      cellRenderer: 'SignDetailLink',
    },
    numberEditor: {
      editable: true,
      cellEditor: 'NumberCellEditor',
      cellEditorPopup: true,
    },
  },
  components: {
    TableChip: TableChip,
    TableToggle: TableToggleComponent,
    NumberCellEditor: NumberCellEditor,
    SignDetailLink: SignDetailLink,
  },
}

export const columns = [
  new Column({
    field: 'dpci',
    headerName: 'DPCI #',
    width: 160,
    type: ['fixed'],
  }),
  new Column({
    field: 'sign_description',
    headerName: 'Sign Description',
    width: 160,
    type: ['fixed', 'signDetailLink'],
  }),
  new Column({
    field: 'sign_status',
    headerName: 'Sign Status',
    width: 154,
    type: ['tableChip'],
  }),
  new Column({
    field: 'total_stores',
    headerName: 'Total Stores',
  }),
  new Column({
    field: 'quantity_per_kit',
    headerName: 'Quantity Per Kit',
    type: ['numberEditor'],
  }),
  new Column({
    field: 'nrsc_quantity',
    headerName: 'NRSC Quantity',
  }),
  new Column({
    field: 'kit_by_itself',
    headerName: 'kit By Itself',
    width: 150,
    type: ['toggle'],
  }),
  new Column({
    field: 'print_vendor',
    headerName: 'Print Vendor',
    width: 224,
  }),
  new Column({
    field: 'flat_width',
    headerName: 'Flat Width',
  }),
  new Column({
    field: 'flat_height',
    headerName: 'Flat Height',
  }),
  new Column({
    field: 'flat_depth',
    headerName: 'Flat Depth',
  }),
]
