import React from 'react'
import PageChangesWrapper from '../../App/components/PageChangesWrapper'
import StandardSignAdminContainer from './components'
import { AgGridStandardSignAdminProvider } from './context/agGridStandardSignAdminContext'
import { StandardSignAdminProvider } from './context/standardSignAdminContext'

const StandardSignAdmin = (props: any) => (
  <PageChangesWrapper>
    <StandardSignAdminProvider>
      <AgGridStandardSignAdminProvider>
        <StandardSignAdminContainer {...props} />
      </AgGridStandardSignAdminProvider>
    </StandardSignAdminProvider>
  </PageChangesWrapper>
)

export default StandardSignAdmin
