import React from 'react'
import { ImageSelectorProps } from './types'
import { useImageSelector } from './useImageSelector'

export default function ImageSelector<T extends Record<string, any>>({
  images,
  onSelected = (index: number) => {},
  selectedIndex = 0,
}: ImageSelectorProps<T>) {
  const { carouselImages, hovering, setHovering } = useImageSelector(
    selectedIndex,
    images,
  )

  return (
    <div className="image-selector">
      {carouselImages.map((index, idx) => {
        const image = images[index]
        return (
          <div
            className={`image-selector__image ${
              index === selectedIndex ? 'image-selector__image--selected' : ''
            }`}
            key={idx}
            onClick={() => onSelected(index)}
            onMouseOver={() => setHovering(index)}
          >
            <img src={image?.image} alt="selector-thumbnail" />
            {hovering === index && (
              <div
                onMouseOver={() => setHovering(index)}
                onMouseOut={() => setHovering(-1)}
                className="image-selector__overlay"
              >
                <span>{image?.overlayMessage}</span>
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}
