import React from 'react'
import { Grid, List, Popover } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { CaretDownIcon } from '@enterprise-ui/icons'
import { pogSelectionValues } from '../../../constants/blueprintProjectListConstants'
import moment from 'moment'
import { PogSetDate } from '../../../../../../../models/projects/SapTableProject.model'

export interface Props {
  data: any
  value: PogSetDate
}

const PogSelectionCell = ({ data = {}, value }: Props) => {
  const popoverContent = (
    <Grid.Container>
      <Grid.Item className="hc-pv-dense" xs={12}>
        <List>
          {value?.set_dates.map((setDate: string, i: number) => (
            <List.Item
              className="sem_ProductHighlights--ListItem"
              key={i}
              divider={i !== value.set_dates.length - 1}
            >
              {moment(setDate).format('MM/DD/YYYY')}
            </List.Item>
          ))}
        </List>
      </Grid.Item>
    </Grid.Container>
  )
  return data.pog_selection === pogSelectionValues.MULTIPLE_SET_DATES ? (
    <Popover
      content={popoverContent}
      size="dense"
      location="bottom"
      onClick={(event: any) => event.stopPropagation()}
    >
      <div className="ag-cell-value">
        Use Multiple Set Dates
        <EnterpriseIcon
          className="hc-ml-dense"
          size="sm"
          icon={CaretDownIcon}
        />
      </div>
    </Popover>
  ) : (
    <div className="ag-cell-value">{data.pog_selection}</div>
  )
}

export default PogSelectionCell
