import React from 'react'
import { Tooltip } from '@enterprise-ui/canvas-ui-react'
import { ProjectMilestonesSummary } from '../../../../models/dashboard/ProjectMilestoneSummary.model'
import RubixChip from 'components/common/RubixChip'

export interface Props {
  value: string
  data: ProjectMilestonesSummary
}

const ProjectMilestoneDueDateChip = ({ data, value }: Props) => {
  return value ? (
    <Tooltip
      location="bottom"
      content={data.my_milestone_health_info.description}
    >
      <RubixChip color={data.my_milestone_health_info.color}>{value}</RubixChip>
    </Tooltip>
  ) : null
}

export default ProjectMilestoneDueDateChip
