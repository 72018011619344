export default class MoveSignRequest {
  source_project_id: string
  target_project_id: string
  sign_ids: string[]
  updated_by: string

  constructor(props: any = {}) {
    this.source_project_id = props.source_project_id
    this.target_project_id = props.target_project_id
    this.sign_ids = props.sign_ids
    this.updated_by = props.updated_by
  }
}
