import { BusinessPartner } from '../../../models/app/BusinessPartner.model'
import { RosterElement } from '../../../models/roster/RosterElement.model'
import { RosterUser } from '../../../models/roster/RosterUser.model'

export const isCategoryManager = (
  projectRoster: RosterElement[] | undefined,
  userCompany: string,
) =>
  projectRoster
    ? projectRoster
        .find((type: RosterElement) => type.title === 'Category Manager')
        ?.users.some(
          (user: RosterUser) =>
            user instanceof BusinessPartner && user.name === userCompany,
        )
    : false
