import React from 'react'
import EnterpriseIcon, {
  CautionIcon,
  CheckIcon,
  InfoIcon,
  TimerIcon,
} from '@enterprise-ui/icons'
import { ProjectSummary } from '../../../../models/dashboardV2/ProjectSummary.model'
import { BlueprintSummary } from '../../../../models/dashboardV2/BlueprintSummary.model'
import { BlueprintMilestoneSummary } from '../../../../models/dashboardV2/BlueprintMilestoneSummary.model'
import RubixChip from 'components/common/RubixChip'

export interface Props {
  data: ProjectSummary | BlueprintSummary | BlueprintMilestoneSummary
}

const HealthChip = ({ data }: Props) => {
  const statusIcon =
    data.health === 'green'
      ? CheckIcon
      : data.health === 'yellow'
      ? TimerIcon
      : data.health === 'red'
      ? CautionIcon
      : InfoIcon
  const label =
    data.health === 'green'
      ? 'On Track'
      : data.health === 'yellow'
      ? 'Past Due'
      : data.health === 'red'
      ? 'At Risk'
      : data.health === 'blue'
      ? 'Initialized'
      : 'N/A'
  return (
    <RubixChip color={data.health}>
      <EnterpriseIcon size="inline" className="hc-mr-dense" icon={statusIcon} />
      {label}
    </RubixChip>
  )
}

export default HealthChip
