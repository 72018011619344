import React from 'react'
import { Department } from '../../../../../../../models/merchandise/hierarchy/Department.model'

export interface Props {
  value: Department
}

const TableNotesComponent = ({ value }: Props) => (
  <p className="ag-cell-value">{value.department_display_name}</p>
)

export default TableNotesComponent
