export default class SignLibraryFacets {
  [index: string]: any
  campaign: any
  department: any
  division: any
  kitting_vendor: any
  printing_vendor: any
  pyramid: any
  separator: any
  sign_item_status: any
  sign_type: any
  sign_status: any
  sign_template: any
  sign_size: any
  sign_definition: any
  product_vendor: any
  project_type: any

  constructor(props: any = {}) {
    this.campaign = props.campaign || 'project_sign_info_list.campaign.name'
    this.department =
      props.department ||
      'non_retail_item_info.department.department_display_name'
    this.division =
      props.division || 'non_retail_item_info.department.division_display_name'
    this.kitting_vendor =
      props.kitting_vendor || 'project_sign_info_list.kitting_vendor'
    this.printing_vendor =
      props.printing_vendor || 'non_retail_item_info.printing_info.print_vendor'
    this.pyramid =
      props.pyramid || 'non_retail_item_info.department.group_display_name'
    this.separator = props.separator || 'non_retail_item_info.separator'
    this.sign_item_status =
      props.sign_item_status || 'non_retail_item_info.sign_item_status'
    this.sign_type = props.sign_type || 'non_retail_item_info.sign_type'
    this.sign_status = props.sign_status || 'project_sign_info_list.sign_status'
    this.product_vendor =
      props.product_vendor || 'non_retail_item_info.product_vendor'
    this.project_type =
      props.project_type || 'project_sign_info_list.project_type'
    this.sign_template =
      props.sign_template ||
      'non_retail_item_info.sign_template_info.template_name'
    this.sign_size = props.sign_size || 'non_retail_item_info.sign_size'
    this.sign_definition =
      props.sign_definition || 'non_retail_item_info.sign_definition'
  }
}
