export class SearchResponse<T, U> {
  total_pages: number
  total_results: number
  page: number
  per_page: number
  sort_fields: any
  search: SearchResult<U>[]
  facets: T

  constructor(props: any = {}) {
    this.total_pages = props.total_pages || 0
    this.total_results = props.total_results || 0
    this.page = props.page || 0
    this.per_page = props.per_page || 0
    this.sort_fields = props.sort_fields || {}
    this.search = props.search || []
    this.facets = props.facets || {}
  }
}

export class SearchResult<U> {
  result: U

  constructor(props: any = {}) {
    this.result = props.result
  }
}
