import React from 'react'
import Truncate from 'react-truncate'
import { Divider, Grid, Popover } from '@enterprise-ui/canvas-ui-react'
import { DateFormatter } from '@enterprise-ui/canvas-ui-react-date'
import { Column } from 'ag-grid-community'
import { get } from 'lodash'
import SignResponse from '../../../../../../../models/signs/SignResponse.model'

export interface Props {
  value: string
  data: SignResponse
  column: Column
}

const TableNotesComponent = ({ value, data, column }: Props) => {
  const popoverContent = (
    <Grid.Container>
      <Grid.Item className="hc-pv-dense" xs={12}>
        <pre className="sem_NotesPopover">{value}</pre>
      </Grid.Item>
      <Divider className="hc-ph-dense" />
      <Grid.Item className="hc-pt-none hc-pb-dense" xs={12}>
        <p className="sem_NotesByline hc-ta-right">
          <DateFormatter
            format="LLL"
            date={get(data, `${column.getColId()}.timestamp`)}
          />
          {` by ${get(data, `${column.getColId()}.author`)}`}
        </p>
      </Grid.Item>
    </Grid.Container>
  )

  return value ? (
    <Popover
      onClick={() => {}}
      content={popoverContent}
      location="bottom"
      size="expanded"
    >
      <Truncate width={180}>{value || ''}</Truncate>
    </Popover>
  ) : null
}

export default TableNotesComponent
