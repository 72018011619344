import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { Form, Grid } from '@enterprise-ui/canvas-ui-react'
import { Column } from 'ag-grid-community'
import SapTableProject from '../../../../../../../models/projects/SapTableProject.model'
import SapTableProjectRequest from '../../../../../../../models/projects/SapTableProjectRequest.model'
import SapProjectFieldsModified from '../../../../../../../models/projects/SapProjectFieldsModified.model'
import { useBlueprintProjectListContext } from '../../../context/blueprintProjectListContext'
import { BATCH_EDIT_ACTION } from 'components/App/constants/appConstants'

export interface Props {
  value: string | number
  data: SapTableProject
  column: Column
}

const SelectCellEditor = forwardRef(({ value, data, column }: Props, ref) => {
  const {
    projectModifiedFields,
    setProjectModifiedFields,
    modifiedProjects,
    setSelectOptions,
  } = useBlueprintProjectListContext()!
  const [editValue, setEditValue] = useState(value)
  const refInput = useRef<HTMLInputElement>(null)
  const [options, setOptions] = useState<Object[] | undefined>(undefined)

  const labelMap: any = {
    fund_id: 'Fund ID\u002A',
    project_type: 'Project Type\u002A',
    tactic: 'Tactic\u002A',
  }

  useEffect(() => {
    setTimeout(() => refInput?.current?.focus())
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      const selectOptions: Object[] | undefined = await setSelectOptions(
        column.getColId(),
        column.getColId() === 'fund_id' ? data.set_date : undefined,
      )
      setOptions(selectOptions)
    }
    fetchData()
  }, [column, data.set_date, setSelectOptions])

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return editValue
      },
      isPopup() {
        return true
      },
    }
  })

  const handleChange = (id: string, value: string) => {
    if (
      projectModifiedFields.find(
        (project: SapProjectFieldsModified) =>
          project.projectId === data.project_id,
      )
    ) {
      setProjectModifiedFields(
        projectModifiedFields.map((project: SapProjectFieldsModified) => {
          return project.projectId === data.project_id
            ? new SapProjectFieldsModified({
                projectId: project.projectId,
                fieldsChanged: project.fieldsChanged.add(column.getColId()),
              })
            : project
        }),
      )
    } else {
      setProjectModifiedFields([
        ...projectModifiedFields,
        new SapProjectFieldsModified({
          projectId: data.project_id,
          fieldsChanged: new Set([column.getColId()]),
        }),
      ])
    }
    setEditValue(value)
  }

  const isBudgetModified = () => {
    const modifiedProject = projectModifiedFields.find(
      (project: SapProjectFieldsModified) =>
        project.projectId === data.project_id,
    )
    return (
      modifiedProject !== undefined &&
      modifiedProject.fieldsChanged.has('initial_budget')
    )
  }

  const isUpdate = () => {
    const modifiedProject = modifiedProjects.find(
      (project: SapTableProjectRequest) =>
        project?.request?.project_id === data.project_id,
    )
    return modifiedProject?.action === BATCH_EDIT_ACTION.UPDATE
  }

  const isDisabled = () => {
    if (column.getColId() === 'fund_id' && isBudgetModified() && isUpdate()) {
      return true
    } else {
      return false
    }
  }

  return (
    <div className="hc-pa-dense">
      <Grid.Container className="sem_StandardForm error_messaging_enabled">
        <Grid.Item className="hc-pb-normal" xs={12}>
          <Form.Field
            id="select_cell_editor"
            label={labelMap[column.getColId()]}
            ref={refInput}
            type="select"
            value={editValue}
            disabled={isDisabled()}
            error={isDisabled()}
            errorText={
              isDisabled()
                ? 'Cannot edit fund ID at the same time as budget'
                : ''
            }
            options={options}
            onUpdate={(id: string, value: string) => handleChange(id, value)}
            size="dense"
          />
        </Grid.Item>
      </Grid.Container>
    </div>
  )
})

export default SelectCellEditor
