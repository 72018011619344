import React from 'react'
import { Chip, Grid } from '@enterprise-ui/canvas-ui-react'
import { useAgGridSignLibraryContext } from '../../context/agGridSignLibraryContext'

const FilterChips = () => {
  const { filterList } = useAgGridSignLibraryContext()!

  return (
    <Grid.Item className="hc-ph-normal hc-pt-dense hc-pb-none" xs={true}>
      <Grid.Container className="hc-ma-none" align="center">
        {filterList.map((filter: any, index: number) => {
          return (
            <Chip
              className="sem_SignLibraryFilterChip"
              size="dense"
              key={index}
            >
              <p className="sem_FilterChip--filter">
                {filter.label}:
                <span className="sem_FilterChip--value">{filter.value}</span>
              </p>
            </Chip>
          )
        })}
      </Grid.Container>
    </Grid.Item>
  )
}

export default FilterChips
