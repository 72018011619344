export class CampaignDetail {
  id: string
  campaign_id: string
  year: string
  campaign_type: string
  fiscal_year_start_date: string
  fiscal_year_end_date: string
  campaign_plan_start_date: string
  campaign_plan_end_date: string
  active: boolean
  created_by: string
  created_date: Date
  updated_by: string
  updated_date: Date

  constructor(props: any = {}) {
    this.id = props.id || ''
    this.campaign_id = props.campaign_id || ''
    this.year = props.year || ''
    this.campaign_type = props.campaign_type || ''
    this.fiscal_year_start_date = props.fiscal_year_start_date || new Date()
    this.fiscal_year_end_date = props.fiscal_year_end_date || new Date()
    this.campaign_plan_start_date = props.campaign_plan_start_date || new Date()
    this.campaign_plan_end_date = props.campaign_plan_end_date || new Date()
    this.active = props.active || false
    this.created_by = props.created_by || ''
    this.created_date = props.created_date || new Date()
    this.updated_by = props.updated_by || ''
    this.updated_date = props.updated_date || new Date()
  }
}
