import React, { createContext, useCallback, useContext, useState } from 'react'
import { connect } from 'react-redux'
import Axios from 'axios'
import {
  KIT_SERVICE_API_DOMAIN_URL,
  TOASTER_DEFAULTS,
} from 'components/App/constants/appConstants'
import { useEnv } from '@praxis/component-runtime-env'
import { KitInfoResponse } from '../../../../../models/kits/KitInfoResponse.model'
import { KitScenarioRequest } from '../../../../../models/kits/KitScenarioRequest.model'
import { useAppContext } from 'components/App/context/appContext'
import {
  KitScenarioConfig,
  KitScenario,
} from '../../../../../models/kits/KitScenario.model'
import { useToaster } from '@enterprise-ui/canvas-ui-react'

type ContextType = {
  kittingVendors: KitScenarioConfig[]
  setKittingVendors: Function
  getKittingVendors: Function
  runKitScenario: (req: KitScenarioRequest) => void
  kitInfoResponse: KitInfoResponse
  setKitInfoResponse: Function
}

export const RunKitScenarioContext = createContext<ContextType | undefined>(
  undefined,
)

type Props = {
  children: React.ReactNode
}

const RunKitScenarioProviderComponent = ({ children }: Props) => {
  const { setFullPageLoadingMessage } = useAppContext()!
  const env = useEnv()
  const makeToast = useToaster()

  const [kittingVendors, setKittingVendors] = useState<KitScenarioConfig[]>([])

  const [kitInfoResponse, setKitInfoResponse] = useState<KitInfoResponse>(
    new KitInfoResponse(),
  )

  const getKittingVendors = useCallback(
    async (id: string) => {
      try {
        const res = await Axios.get(
          `${
            env.apiDomainUrl + KIT_SERVICE_API_DOMAIN_URL
          }/kit_scenarios/${id}/kitting_vendors`,
        )
        const kittingVendors: string[] = res.data
        setKittingVendors(
          kittingVendors.map(
            (kitVendor: string) =>
              new KitScenarioConfig({ kit_vendor: kitVendor }),
          ),
        )
      } catch (err: any) {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'error',
          heading: 'Failed to Get Kitting Vendors',
          message: err.response.data.message,
        })
      }
    },
    [makeToast, env.apiDomainUrl],
  )

  const runKitScenario = async (kitScenarioRequest: KitScenarioRequest) => {
    setFullPageLoadingMessage('Submitting Kit Scenario...')
    const newKitScenarioRequest = new KitScenarioRequest({
      ...kitScenarioRequest,
      kit_scenario: new KitScenario({
        ...kitScenarioRequest.kit_scenario,
        sign_roundup: kitScenarioRequest.kit_scenario.sign_roundup?.filter(
          (value) =>
            value !== undefined &&
            value.quantity !== undefined &&
            value.quantity > 0,
        ),
        max_kits: kitScenarioRequest.kit_scenario.max_kits?.filter(
          (value) =>
            value !== undefined &&
            value.quantity !== undefined &&
            value.quantity > 0,
        ),
      }),
    })
    try {
      const res = await Axios.post(
        `${env.apiDomainUrl + KIT_SERVICE_API_DOMAIN_URL}/kit_scenarios`,
        newKitScenarioRequest,
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      setKitInfoResponse(res.data)
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Execute Kit Scenario',
        message: err.response.data.message,
      })
    }
    setFullPageLoadingMessage('')
  }

  return (
    <RunKitScenarioContext.Provider
      value={{
        kittingVendors,
        setKittingVendors,
        getKittingVendors,
        runKitScenario,
        kitInfoResponse,
        setKitInfoResponse,
      }}
    >
      {children}
    </RunKitScenarioContext.Provider>
  )
}

export const RunKitScenarioProvider = connect(
  null,
  null,
)(RunKitScenarioProviderComponent)

export const useRunKitScenarioContext = () => useContext(RunKitScenarioContext)
