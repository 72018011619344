import React from 'react'
import {
  Button,
  Card,
  Chip,
  Grid,
  Tooltip,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { PlusIcon } from '@enterprise-ui/icons'
import { CampaignTypeResponse } from '../../../../models/campaigns/CampaignTypeResponse.model'
import { Pyramid } from '../../../../models/merchandise/hierarchy/Pyramid.model'
import { useAdminCampaignsContext } from '../context/adminCampaignsContext'
import { ParentCampaignType } from '../../../../models/campaigns/ParentCampaignType.model'
import { useAppContext } from '../../../App/context/appContext'

export interface Props {
  campaign: CampaignTypeResponse
}

const ChildCampaignCard = ({ campaign }: Props) => {
  const { setPageHasChanges } = useAppContext()!
  const { newCampaign, setParentCampaign, setNewCampaignGroup } =
    useAdminCampaignsContext()!

  return (
    <Card className="availableCampaign-Card">
      <div className="hc-pa-dense">
        <Grid.Container align="center">
          <Grid.Item>
            <Tooltip location="bottom" content="Add To Campaign">
              <Button
                aria-label="Add to Campaign"
                iconOnly
                type="ghost"
                size="dense"
                data-testid="addToCampaignButton"
                className="sem-MilestoneBtnRemove"
                onClick={() => {
                  if (newCampaign.name === '') {
                    setParentCampaign(
                      (previousState: ParentCampaignType) =>
                        new ParentCampaignType({
                          ...previousState,
                          child_types: [...previousState.child_types, campaign],
                        }),
                    )
                  } else {
                    setNewCampaignGroup(
                      (previousState: ParentCampaignType) =>
                        new ParentCampaignType({
                          ...previousState,
                          child_types: [...previousState.child_types, campaign],
                        }),
                    )
                  }
                  setPageHasChanges(true)
                }}
              >
                <EnterpriseIcon color="gray" icon={PlusIcon} />
              </Button>
            </Tooltip>
          </Grid.Item>
          <Grid.Item className="hc-clr-grey02" xs={4}>
            {campaign.name}
          </Grid.Item>
          <Grid.Item className="hc-clr-grey02" xs={7}>
            {campaign.pyramids.map((pyramid: Pyramid) => (
              <Chip
                key={pyramid.group_id}
                className="sem-RosterChip"
                size="dense"
              >
                {pyramid.group_name}
              </Chip>
            ))}
          </Grid.Item>
        </Grid.Container>
      </div>
    </Card>
  )
}

export default ChildCampaignCard
