import React, {
  forwardRef,
  useState,
  useRef,
  useImperativeHandle,
  useEffect,
} from 'react'
import { Column } from 'ag-grid-community'
import SignTemplateResponse from '../../../../../models/signTemplates/SignTemplateResponse.model'
import { Grid, Form } from '@enterprise-ui/canvas-ui-react'
import { useStandardSignAdminContext } from '../../context/standardSignAdminContext'

export interface Props {
  value: string | number
  data: SignTemplateResponse
  column: Column
}

const SelectCellEditor = forwardRef(({ value, data, column }: Props, ref) => {
  const [editValue, setEditValue] = useState(value)
  const refInput = useRef<HTMLInputElement>(null)
  const { getSignTypes, signTypes } = useStandardSignAdminContext()!

  useEffect(() => {
    setTimeout(() => refInput?.current?.focus())
  }, [])

  useEffect(() => {
    getSignTypes()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return editValue
      },
      isPopup() {
        return true
      },
    }
  })

  return (
    <div className="hc-pa-dense">
      <Grid.Container className="sem_StandardForm error_messaging_enabled">
        <Grid.Item className="hc-pb-normal" xs={12}>
          <Form.Field
            id="sign_type_editor"
            label="Sign Type"
            ref={refInput}
            type="select"
            value={editValue}
            options={signTypes}
            onUpdate={(id: string, value: string) => setEditValue(value)}
            size="dense"
          />
        </Grid.Item>
      </Grid.Container>
    </div>
  )
})

export default SelectCellEditor
