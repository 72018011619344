import {
  Button,
  Modal,
  Pagination,
  Spinner,
} from '@enterprise-ui/canvas-ui-react'
import React, { useEffect } from 'react'
import { useProjectSignListContext } from '../../context/projectSignListContext'
import EnterpriseIcon, { PrinterIcon } from '@enterprise-ui/icons'
import {
  SignResponseWithImage,
  useExportPDFState,
} from '../../hooks/useExportPDFState'
import { dlConfig } from '../../hooks/useExportPDFState'
import ImageSelector from 'components/common/ImageSelector/ImageSelector'
import DescriptionList from 'components/common/DescriptionList/DescriptionList'

const ExportImagesModal = () => {
  const { showExportPdfModal, setShowExportPdfModal, currentProject } =
    useProjectSignListContext()!

  const { sap_project_id, short_name, set_date } = currentProject || {}

  const {
    images,
    setImageIndex,
    selectedIndex,
    currentImage,
    exportImages,
    loading,
    setLoading,
  } = useExportPDFState(sap_project_id, set_date, short_name)

  useEffect(() => {
    if (showExportPdfModal) {
      setLoading(false)
      setImageIndex(0)()
    }
  }, [showExportPdfModal]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    // WARNING: Canvas v7 - Modal "closeButton" prop has been deprecated.
    <Modal
      className="sem_bulkEditModal"
      headingText="Export Images to PDF"
      isVisible={showExportPdfModal}
      onRefuse={() => setShowExportPdfModal(false)}
    >
      {images.length ? (
        <div className="export-images-modal">
          <div className="content-container">
            <DescriptionList config={dlConfig} data={images[selectedIndex]} />
            <figure>
              <img src={currentImage} alt="pdf-export" />
            </figure>
          </div>
          {images.length && (
            <div className="bottom-row">
              <Button onClick={exportImages}>
                <EnterpriseIcon icon={PrinterIcon} />
                <span style={{ marginLeft: '10px' }}>Print PDF</span>
              </Button>
              <div className="carousel-controller">
                <ImageSelector
                  onSelected={(val: number) => setImageIndex(val)()}
                  selectedIndex={selectedIndex}
                  images={images.map((image: SignResponseWithImage) => ({
                    image: image.image,
                    overlayMessage: image.description,
                  }))}
                />
                <Pagination
                  onRequestNext={setImageIndex(true)}
                  onRequestPrev={setImageIndex(false)}
                  totalPages={images.length}
                  currentPage={selectedIndex + 1}
                />
              </div>
            </div>
          )}
          {loading && (
            <div className="overlay absolute">
              <Spinner />
              <span>This could take a while...</span>
            </div>
          )}
        </div>
      ) : (
        'No images found'
      )}
    </Modal>
  )
}

export default ExportImagesModal
