import React, { Fragment, useEffect, useState } from 'react'
import {
  Heading,
  Chip,
  GridContainer,
  GridItem,
  Divider,
  Grid,
  Button,
  Dropdown,
  Card,
  Tooltip,
  Popover,
  Table,
  Spinner,
  Modal,
  Input,
} from '@enterprise-ui/canvas-ui-react'
import { useAuth } from '@praxis/component-auth'
import moment from 'moment'
import { KitInfoResponse } from '../../../../../models/kits/KitInfoResponse.model'
import ProjectKitList from './ProjectKitList'
import ProjectKitDetailsForm from './ProjectKitDetailsForm'
import EnterpriseIcon, {
  CaretDownIcon,
  CautionIcon,
  DownloadIcon,
  FilesIcon,
  InfoIcon,
  PlayIcon,
  WorkIcon,
} from '@enterprise-ui/icons'
import { useProjectDistributionContext } from '../context/projectDistributionContext'
import { ProjectDetail } from '../../../../../models/projects/ProjectDetail.model'
import { get } from 'lodash'
import { DialogProps } from '../../../../../models/app/DialogProps.model'
import { useAppContext } from 'components/App/context/appContext'
import ProjectKitInfoView from './ProjectKitInfoView'
import { KitScenarioStatusNames } from '../../../../../models/kits/KitScenarioStatus.model'
import {
  BASELINE,
  OTHERS,
} from '../../RunKitScenario/constants/KitScenarioOptions'
import {
  PROJECT_READY_TO_KIT,
  PROJECT_WORKFLOW_LOCKED,
  ALLOW_EXPORT_PSF,
  UserType,
} from 'components/App/constants/appConstants'
import RunKitScenarioForm from '../../RunKitScenario/components/RunKitScenarioForm'
import { KitScenarioConfig } from '../../../../../models/kits/KitScenario.model'
import { useUserContext } from 'components/App/context/userContext'
import {
  KitResponse,
  KitStatus,
} from '../../../../../models/kits/KitResponse.model'
import MergeKits from './MergeKits'

export interface Props {
  kittingInfo: KitInfoResponse
  currentProject: ProjectDetail
}

const ProjectDistributionKits = ({ kittingInfo, currentProject }: Props) => {
  const { session } = useAuth()
  const username = get(session, 'userInfo.fullName', '')
  const { setDialogProps } = useAppContext()!
  const [isRunKitScenarioOpen, setIsRunKitScenarioOpen] = useState(false)
  const [showShipMethodWarning, setShowShipMethodWarning] = useState(false)
  const {
    exportKittingInfo,
    finalizeAll,
    kittingVendors,
    getKittingVendors,
    getKitInfo,
    setIsMergeKitsOpen,
  } = useProjectDistributionContext()!
  const { userInfo } = useUserContext()!

  const getFileName = () => {
    if (kittingInfo.kits_finalized) {
      return `${currentProject.sap_project_id}_${currentProject.name}_PSF.xlsx`
    } else {
      return `${currentProject.sap_project_id}_${currentProject.name}_PSF_Draft.xlsx`
    }
  }

  useEffect(() => {
    if (kittingVendors === undefined && kittingInfo.kits !== undefined) {
      getKittingVendors(kittingInfo.kits)
    }
  }, [kittingVendors, kittingInfo.kits, getKittingVendors])

  useEffect(() => {
    const interval = setInterval(refreshKitInfo, 30 * 1000)
    kittingInfo.kit_scenario?.status.name !== KitScenarioStatusNames.RUNNING &&
      clearInterval(interval)

    return () => {
      clearInterval(interval)
    }
  })

  const refreshKitInfo = () => getKitInfo(currentProject.project_id)

  const kitScenarioType = () =>
    kittingInfo.kit_scenario.no_adjustment ? BASELINE : OTHERS

  const getQuantity = (
    kitVendor: any,
    kitScenarioConfigs?: KitScenarioConfig[],
  ) =>
    kitScenarioConfigs?.filter(
      (value) => value.kit_vendor === kitVendor.value,
    )[0]?.quantity

  const shipMethodWarningFlag = () => {
    const ship_methods = ['Store Mail', 'DTS', '3rd Party']
    const withinTwentyEightDays =
      moment(kittingInfo.initial_receive_dates?.instore_date).diff(
        moment(),
        'days',
      ) <= 28
    return (
      withinTwentyEightDays &&
      !ship_methods.some(
        (ship_method: string) => kittingInfo.shipping_method === ship_method,
      )
    )
  }

  const userCanRunKits =
    userInfo.permissions.isKitEditor && userInfo.type === UserType.INTERNAL
  const readyForKitting = PROJECT_READY_TO_KIT.includes(
    currentProject.workflow.status,
  )
  const allowExportPSF = ALLOW_EXPORT_PSF.includes(
    currentProject.workflow.status,
  )

  const userCanViewPricingPSF =
    userInfo.type === UserType.INTERNAL && !userInfo.permissions.isKitVendor
  const kitScenarioStatus = kittingInfo.kit_scenario?.status.name
  const userCanMergeKits =
    userInfo.permissions.isKitEditor || userInfo.permissions.isKitAdmin
  const kitsCanBeMerged = kittingInfo.kits && kittingInfo.kits.length > 1
  const userCanFinalizeKits =
    kitScenarioStatus === KitScenarioStatusNames.SUCCESS &&
    userInfo.permissions.isKitEditor
  const kitsCanBeFinalized =
    !(
      kittingInfo.kits_finalized &&
      kittingInfo.kits.every(
        (kit: KitResponse) => kit.status === KitStatus.FINALIZED,
      )
    ) || !PROJECT_WORKFLOW_LOCKED

  return (
    <Fragment>
      <Card
        padding="none"
        className="sem_ProjectKitCard hc-pt-expanded hc-pb-none"
      >
        <Grid.Container
          className="hc-pa-dense hc-pt-none hc-pb-none"
          justify="space-between"
          align="center"
        >
          <Grid.Item xs={true} className="hc-pt-none hc-pb-none">
            <Heading size={4}>Kits</Heading>
          </Grid.Item>
          {kittingInfo.changes && kittingInfo.changes?.length > 0 ? (
            <GridItem>
              <Popover
                type="clickOnly"
                location="bottom"
                size="expanded"
                content={
                  <Fragment>
                    <GridContainer>
                      <GridItem xs={8}>
                        <Input.Label>Kit Changes:</Input.Label>
                      </GridItem>
                    </GridContainer>
                    {kittingInfo.changes.map((changeLog: string, index) => (
                      <GridItem key={index}>{changeLog}</GridItem>
                    ))}
                  </Fragment>
                }
              >
                <Tooltip location="bottom-left" content="View Kit Changes">
                  <Button>
                    <EnterpriseIcon
                      className="hc-mr-dense"
                      icon={CautionIcon}
                    />
                    <span>Kit Changes</span>
                  </Button>
                </Tooltip>
              </Popover>
            </GridItem>
          ) : null}
          {kittingInfo?.kit_scenario ? (
            <GridItem className="hc-pt-none hc-pb-none">
              <Popover
                type="clickOnly"
                location="bottom"
                size={
                  kittingInfo.kit_scenario.no_adjustment ? 'normal' : 'expanded'
                }
                content={
                  <Fragment>
                    <GridContainer>
                      <GridItem xs={12}>
                        <Input.Label>Kit Scenario:</Input.Label>
                        <Chip
                          id="kit_scenario_chip"
                          size="dense"
                          className="hc-txt-capitalize"
                        >
                          {kitScenarioType()}
                        </Chip>
                      </GridItem>
                      <GridItem xs={12} className="hc-pt-none">
                        {!kittingInfo.kit_scenario.no_adjustment ? (
                          <Input.Label>Values:</Input.Label>
                        ) : null}
                        {!kittingInfo.kit_scenario.no_adjustment ? (
                          <Fragment>
                            <Table
                              name="other scenarios"
                              className="sem_KitScenarioTable"
                            >
                              <Table.Head>
                                <Table.Row>
                                  <Table.Header xs={4} className="hc-pl-normal">
                                    Kitting Vendor
                                  </Table.Header>
                                  <Table.Header xs={4} className="hc-pl-normal">
                                    Roundup Sign Quantity Difference
                                  </Table.Header>
                                  <Table.Header xs={4} className="hc-pl-normal">
                                    Max. Number of Kits
                                  </Table.Header>
                                </Table.Row>
                              </Table.Head>
                              <Table.Body>
                                {kittingVendors &&
                                  kittingVendors
                                    .filter((value) => value.label !== 'All')
                                    .map((kitVendor, index) => (
                                      <Table.Row key={index}>
                                        <Table.Data
                                          xs={4}
                                          className="hc-pl-normal"
                                        >
                                          {kitVendor.label}
                                        </Table.Data>
                                        <Table.Data
                                          xs={4}
                                          className="hc-pl-normal"
                                        >
                                          {getQuantity(
                                            kitVendor,
                                            kittingInfo.kit_scenario
                                              .sign_roundup,
                                          ) || ''}
                                        </Table.Data>
                                        <Table.Data
                                          xs={4}
                                          className="hc-pl-normal"
                                        >
                                          {getQuantity(
                                            kitVendor,
                                            kittingInfo.kit_scenario.max_kits,
                                          ) || ''}
                                        </Table.Data>
                                      </Table.Row>
                                    ))}
                              </Table.Body>
                            </Table>
                          </Fragment>
                        ) : null}
                      </GridItem>
                    </GridContainer>
                  </Fragment>
                }
              >
                <Tooltip
                  location="bottom-left"
                  content="View Kit Scenario Info"
                >
                  <Button type="ghost">
                    <EnterpriseIcon className="hc-mr-dense" icon={InfoIcon} />
                    <span>Kit Scenario Info</span>
                  </Button>
                </Tooltip>
              </Popover>
            </GridItem>
          ) : null}
          {allowExportPSF ? (
            <GridItem className="hc-pt-none hc-pb-none">
              <Dropdown size="dense" location="bottom-right">
                <Button type="ghost">
                  <span>Actions</span>
                  <EnterpriseIcon
                    className="hc-ml-dense"
                    icon={CaretDownIcon}
                  />
                </Button>
                <Dropdown.Menu>
                  {readyForKitting && userCanRunKits ? (
                    <Dropdown.MenuItem
                      className="sem_DropdownMenuItem--action divider"
                      disabled={!readyForKitting}
                      onClick={() => {
                        setIsRunKitScenarioOpen(true)
                      }}
                    >
                      <EnterpriseIcon
                        size="inline"
                        className="hc-mr-normal"
                        icon={PlayIcon}
                      />
                      Run Kit Scenario
                    </Dropdown.MenuItem>
                  ) : null}
                  {kitScenarioStatus === KitScenarioStatusNames.SUCCESS ? (
                    <Fragment>
                      <Dropdown.MenuItem
                        data-testid="exportPSF"
                        onClick={() => {
                          exportKittingInfo(kittingInfo.id, getFileName())
                        }}
                      >
                        <EnterpriseIcon
                          size="inline"
                          className="hc-mr-normal"
                          icon={DownloadIcon}
                        />
                        Export PSF
                      </Dropdown.MenuItem>
                      {userCanViewPricingPSF ? (
                        <Dropdown.MenuItem
                          className={`sem_DropdownMenuItem--action ${
                            userCanViewPricingPSF && 'divider'
                          }`}
                          onClick={() => {
                            exportKittingInfo(
                              kittingInfo.id,
                              getFileName(),
                              true,
                            )
                          }}
                        >
                          <EnterpriseIcon
                            size="inline"
                            className="hc-mr-normal"
                            icon={DownloadIcon}
                          />
                          Export Pricing PSF
                        </Dropdown.MenuItem>
                      ) : null}
                    </Fragment>
                  ) : null}
                  {readyForKitting && userCanMergeKits ? (
                    <Dropdown.MenuItem
                      onClick={() => setIsMergeKitsOpen(true)}
                      data-testid="mergeKits"
                      disabled={!kitsCanBeMerged}
                    >
                      <EnterpriseIcon
                        size="inline"
                        className="hc-mr-normal"
                        icon={FilesIcon}
                      />
                      Merge Kits
                    </Dropdown.MenuItem>
                  ) : null}
                  {readyForKitting && userCanFinalizeKits ? (
                    <Dropdown.MenuItem
                      disabled={!kitsCanBeFinalized}
                      onClick={() => {
                        if (shipMethodWarningFlag()) {
                          setShowShipMethodWarning(true)
                        } else {
                          setDialogProps(
                            new DialogProps({
                              headingText: `Are you sure you want finalize all kits?`,
                              approveButtonText: 'Yes',
                              onApprove: () => {
                                finalizeAll(kittingInfo.id, false, username)
                                setDialogProps(new DialogProps())
                              },
                              refuseButtonText: 'Cancel',
                              onRefuse: () => setDialogProps(new DialogProps()),
                            }),
                          )
                        }
                      }}
                    >
                      <EnterpriseIcon
                        size="inline"
                        className="hc-mr-normal"
                        icon={WorkIcon}
                      />
                      Finalize All Kits
                    </Dropdown.MenuItem>
                  ) : null}
                </Dropdown.Menu>
              </Dropdown>
            </GridItem>
          ) : null}
        </Grid.Container>
        {(kittingInfo.id === undefined ||
          kittingInfo.kit_scenario?.status.name ===
            KitScenarioStatusNames.RUNNING ||
          kittingInfo.kit_scenario?.status.name ===
            KitScenarioStatusNames.ERROR) && (
          <GridContainer>
            <Grid.Item xs={12}>
              <Card
                padding="none"
                className="sem_ProjectKitSummaryCard"
                align="center"
              >
                <Heading size={6}>
                  {kittingInfo.id === undefined && (
                    <span>
                      No Kits to show. Run Kit Scenario to generate kits
                    </span>
                  )}
                  {kittingInfo.kit_scenario?.status.name ===
                    KitScenarioStatusNames.RUNNING && (
                    <Fragment>
                      <span>
                        The page will automatically reload after kits
                        generation.
                      </span>
                      <br />
                      <Spinner />
                    </Fragment>
                  )}
                  {kittingInfo.kit_scenario?.status.name ===
                    KitScenarioStatusNames.ERROR && (
                    <span className="hc-clr-error">
                      Kit scenario execution failed with error,{' '}
                      {kittingInfo.kit_scenario!!.status.description}. Please
                      rerun the kit scenario
                    </span>
                  )}
                </Heading>
              </Card>
            </Grid.Item>
          </GridContainer>
        )}

        {kittingInfo?.kits &&
          kittingInfo.kit_scenario?.status.name ===
            KitScenarioStatusNames.SUCCESS && (
            <Fragment>
              <Divider className="hc-pt-normal" direction="horizontal" />
              <Grid.Container className="sem_ProjectKitInfo hc-pb-none">
                <Grid.Item xs={3} className="hc-pb-none">
                  <ProjectKitList
                    kittingInfo={kittingInfo}
                    currentProject={currentProject}
                  />
                </Grid.Item>
                <Grid.Item xs={9} className="hc-pb-none">
                  <ProjectKitInfoView kittingInfo={kittingInfo} />
                  <Divider className="hc-pt-normal" direction="horizontal" />
                  <ProjectKitDetailsForm />
                </Grid.Item>
              </Grid.Container>
            </Fragment>
          )}
      </Card>
      <MergeKits />
      <RunKitScenarioForm
        show_warning={true}
        isRunKitScenarioOpen={isRunKitScenarioOpen}
        setRunKitScenarioOpen={setIsRunKitScenarioOpen}
      />
      <Modal
        onRefuse={() => setShowShipMethodWarning(false)}
        isVisible={showShipMethodWarning}
        className="sem_KitScenarioModal"
      >
        <div className="hc-pa-normal">
          <GridContainer align="center">
            <Grid.Item xs={12}>
              {!userInfo.permissions.isKitAdmin && (
                <span className="hc-clr-grey01">
                  In store date is less then 28 days. Shipping method can only
                  be 'Store Mail', 'DTS', or '3rd Party'. The Current chosen
                  shipping method is '{kittingInfo.shipping_method}'. If you
                  still want to use '{kittingInfo.shipping_method}' as shipping
                  method, only Kit Admins can finalize the kits.
                </span>
              )}
              {userInfo.permissions.isKitAdmin && (
                <span className="hc-clr-grey01">
                  You have less then 28 days for set date. Shipping method can
                  only be 'Store Mail' or 'DTS' . The Current chosen shipping
                  method is '{kittingInfo.shipping_method}' , Do you still want
                  to proceed with '{kittingInfo.shipping_method}' as the
                  shipping method ?
                </span>
              )}
            </Grid.Item>
          </GridContainer>
          {userInfo.permissions.isKitAdmin && (
            <GridContainer align="right">
              <GridItem xs={true}></GridItem>
              <GridItem>
                <Button
                  fullWidth
                  type="secondary"
                  onClick={() => {
                    setShowShipMethodWarning(false)
                  }}
                >
                  No
                </Button>
              </GridItem>
              <GridItem align="right">
                <Button
                  fullWidth
                  type="primary"
                  onClick={() => {
                    finalizeAll(kittingInfo.id, false, username)
                    setShowShipMethodWarning(false)
                  }}
                >
                  Yes
                </Button>
              </GridItem>
            </GridContainer>
          )}
        </div>
      </Modal>
    </Fragment>
  )
}

export default ProjectDistributionKits
