import React from 'react'
import moment from 'moment'

export interface Props {
  value: string
}

const FormattedDate = ({ value }: Props) => {
  return <p>{moment(value).format('M/D/YY h:mm A')}</p>
}

export default FormattedDate
