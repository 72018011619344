import React, { Fragment } from 'react'
import { Heading, Input, Button, Grid } from '@enterprise-ui/canvas-ui-react'
import { useNotificationsContext } from '../context/notificationsContext'

const NotificationsSettings = () => {
  const { notificationSettings, setPreference, updateSettings } =
    useNotificationsContext()!
  return (
    <Fragment>
      <Heading size={4}>Notification Settings</Heading>
      {notificationSettings?.preferences !== undefined &&
        notificationSettings?.preferences.map((type: any) => (
          <div
            className="notification-settings-row"
            key={type.notification_type}
          >
            <Input.Toggle
              id={`notification_emails_toggle_${type.notification_type}`}
              label={
                'Receive ' +
                type.notification_type.toLowerCase() +
                ' notification emails'
              }
              checked={type.email_opted}
              onChange={(event: any) =>
                setPreference(type.notification_type, event.target.checked)
              }
            />
          </div>
        ))}
      <Grid.Container direction="row-reverse">
        <Grid.Item>
          <Button type="primary" justify="right" onClick={updateSettings}>
            Save
          </Button>
        </Grid.Item>
      </Grid.Container>
    </Fragment>
  )
}

export default NotificationsSettings
