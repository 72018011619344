import React from 'react'
import { Layout } from '@enterprise-ui/canvas-ui-react'
import PageChangesWrapper from '../../App/components/PageChangesWrapper'
import AdminUpload from './components/AdminUpload'
import { MilestoneProvider } from '../../App/context/milestoneContext'
import { AdminUploadProvider } from './context/adminUploadContext'

const AdminCampaignUpload = (props: any) => (
  <PageChangesWrapper>
    <MilestoneProvider>
      <AdminUploadProvider>
        <Layout.Body className="hc-pa-expanded" includeRail>
          <AdminUpload {...props} />
        </Layout.Body>
      </AdminUploadProvider>
    </MilestoneProvider>
  </PageChangesWrapper>
)

export default AdminCampaignUpload
