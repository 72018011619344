import { Column } from './AgGridColumn.model'

export const BLUEPRINT_VIEW_COLUMNS = [
  new Column({
    field: 'name',
    headerName: 'Blueprint Name',
    width: 200,
    type: ['blueprintNameLink'],
  }),
  new Column({
    colId: 'set_date',
    headerName: 'Set Date',
    width: 130,
    type: ['setDateChip'],
    filter: 'agDateColumnFilter',
  }),
  new Column({
    field: 'health_info.label',
    headerName: 'Health',
    width: 160,
    type: ['healthChip', 'healthHeader'],
    filter: 'agSetColumnFilter',
  }),
  new Column({
    field: 'current_milestone.project_phase',
    headerName: 'Project Phase',
    width: 200,
    type: ['projectPhaseHeader'],
    filter: 'agSetColumnFilter',
  }),
  new Column({
    field: 'current_milestone.name',
    headerName: 'Current Milestone',
    width: 200,
  }),
  new Column({
    field: 'current_milestone.assigned_to',
    headerName: 'Milestone Owner',
    width: 200,
    type: ['milestoneOwnerAvatar'],
  }),
  new Column({
    field: 'due_date',
    colId: 'current_milestone.due_date',
    headerName: 'Milestone Due Date',
    width: 200,
    type: ['dueDateChip'],
    filter: 'agDateColumnFilter',
  }),
  new Column({
    field: 'campaign.name',
    headerName: 'Campaign',
  }),
  new Column({
    field: 'type',
    headerName: 'Blueprint Type',
    width: 200,
  }),
  new Column({
    field: 'tier_one',
    headerName: 'Tier One Project',
    width: 200,
    type: ['tierOne', 'booleanHeader'],
    filter: 'agSetColumnFilter',
  }),
]

export const BLUEPRINT_MILESTONE_VIEW_COLUMNS = [
  new Column({
    field: 'name',
    headerName: 'Blueprint Name',
    width: 200,
    type: ['blueprintNameLink'],
  }),
  new Column({
    colId: 'set_date',
    headerName: 'Set Date',
    width: 130,
    type: ['setDateChip'],
    filter: 'agDateColumnFilter',
  }),
  new Column({
    field: 'health_info.label',
    headerName: 'Health',
    width: 160,
    type: ['healthChip', 'healthHeader'],
    filter: 'agSetColumnFilter',
  }),
  new Column({
    field: 'current_milestone.project_phase',
    headerName: 'Project Phase',
    width: 200,
    type: ['projectPhaseHeader'],
    filter: 'agSetColumnFilter',
  }),
  new Column({
    field: 'current_milestone.name',
    headerName: 'Current Milestone',
    width: 200,
  }),
  new Column({
    field: 'current_milestone.assigned_to',
    headerName: 'Milestone Owner',
    width: 200,
    type: ['milestoneOwnerAvatar'],
  }),
  new Column({
    field: 'completed',
    headerName: 'Complete',
    width: 200,
    type: ['completeToggle', 'completeToggleEditor', 'booleanHeader'],
    filter: 'agSetColumnFilter',
  }),
  new Column({
    field: 'due_date',
    colId: 'current_milestone.due_date',
    headerName: 'Milestone Due Date',
    width: 200,
    type: ['dueDateChip'],
    filter: 'agDateColumnFilter',
  }),
  new Column({
    field: 'campaign_type',
    headerName: 'Campaign',
  }),
  new Column({
    field: 'type',
    headerName: 'Blueprint Type',
    width: 200,
  }),
  new Column({
    field: 'tier_one_project',
    headerName: 'Tier One Project',
    width: 200,
    type: ['tierOne', 'booleanHeader'],
    filter: 'agSetColumnFilter',
  }),
]

export const PROJECT_MILESTONES_VIEW = [
  new Column({
    field: 'sap_project_id',
    headerName: 'SAP CRM ID',
    width: 162,
    type: ['fixed'],
  }),
  new Column({
    field: 'project_name',
    headerName: 'Project Name',
    type: ['projectNameLink', 'fixed'],
  }),
  new Column({
    field: 'blueprint_name',
    headerName: 'Blueprint Name',
    width: 200,
  }),
  new Column({
    field: 'set_date',
    colId: 'set_date',
    headerName: 'Set Date',
    width: 148,
    type: ['setDateChip'],
    filter: 'agDateColumnFilter',
  }),
  new Column({
    field: 'my_milestone.name',
    headerName: 'My Milestone',
    width: 238,
  }),
  new Column({
    field: 'complete',
    headerName: 'Complete',
    width: 160,
    type: ['completeToggle', 'completeToggleEditor', 'booleanHeader'],
    filter: 'agSetColumnFilter',
  }),
  new Column({
    field: 'my_due_date',
    colId: 'my_due_date',
    headerName: 'My Due Date',
    width: 200,
    type: ['projectMilestoneDueDateChip'],
    filter: 'agDateColumnFilter',
  }),
  new Column({
    field: 'my_milestone_health_info.label',
    headerName: 'My  Milestone Health',
    width: 200,
    type: ['projectMilestoneHealthChip', 'healthHeader'],
    filter: 'agSetColumnFilter',
  }),
  new Column({
    field: 'my_milestone.assigned_to',
    headerName: 'Milestone Owner',
    width: 200,
    type: ['milestoneOwnerAvatar'],
    cellClass: 'actions-button-cell',
    filter: 'agSetColumnFilter',
  }),
  new Column({
    field: 'dependency_milestone.name',
    headerName: 'Dependency Milestone',
    width: 238,
  }),
  new Column({
    field: 'dependency_due_date',
    colId: 'dependency_due_date',
    headerName: 'Dependency Due Date',
    width: 210,
    type: ['dueDateChip'],
    filter: 'agDateColumnFilter',
  }),
  new Column({
    field: 'dependency_owner',
    headerName: 'Dependency Owner',
    width: 200,
    type: ['milestoneOwnerAvatar'],
    cellClass: 'actions-button-cell',
    filter: 'agSetColumnFilter',
  }),
  new Column({
    field: 'dependency_complete',
    headerName: 'Dependency Completed',
    width: 210,
    type: ['booleanHeader'],
    filter: 'agSetColumnFilter',
  }),
  new Column({
    field: 'campaign',
    headerName: 'Campaign',
    width: 160,
  }),
]

export const PROJECT_VIEW_COLUMNS = [
  new Column({
    field: 'sap_project_id',
    headerName: 'SAP CRM ID',
    width: 162,
  }),
  new Column({
    field: 'name',
    headerName: 'Project Name',
    type: ['projectNameLink'],
  }),
  new Column({
    field: 'blueprint_name',
    headerName: 'Blueprint Name',
    width: 200,
  }),
  new Column({
    colId: 'set_date',
    headerName: 'Set Date',
    width: 148,
    type: ['setDateChip'],
    filter: 'agDateColumnFilter',
  }),
  new Column({
    colId: 'in_store_date',
    headerName: 'In-Store Date',
    width: 200,
    type: ['setDateChip'],
    filter: 'agDateColumnFilter',
  }),
  new Column({
    field: 'health_info.label',
    headerName: 'Health',
    width: 160,
    type: ['healthChip', 'healthHeader'],
    filter: 'agSetColumnFilter',
  }),
  new Column({
    field: 'current_milestone.name',
    headerName: 'Current Milestone',
    width: 200,
    filter: 'agSetColumnFilter',
    type: ['currentMilestoneHeader'],
  }),
  new Column({
    field: 'current_milestone.assigned_to',
    headerName: 'Milestone Owner',
    width: 200,
    type: ['milestoneOwnerAvatar'],
    cellClass: 'actions-button-cell',
  }),
  new Column({
    field: 'due_date',
    colId: 'current_milestone.due_date',
    headerName: 'Milestone Due Date',
    width: 200,
    type: ['dueDateChip'],
    filter: 'agDateColumnFilter',
  }),
  new Column({
    field: 'departments',
    headerName: 'Departments',
    width: 200,
    type: ['departmentsChips', 'departmentHeader'],
    filter: 'agSetColumnFilter',
  }),
  new Column({
    field: 'campaign.name',
    headerName: 'Campaign',
    type: ['campaignHeader'],
    filter: 'agSetColumnHeader',
  }),
  new Column({
    field: 'tier_one_project',
    headerName: 'Tier One Project',
    width: 200,
    type: ['tierOne', 'booleanHeader'],
    filter: 'agSetColumnFilter',
  }),
]
