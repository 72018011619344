import React from 'react'
import { Grid, Button, Dropdown } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { CaretDownIcon } from '@enterprise-ui/icons'
import { useUserContext } from 'components/App/context/userContext'
import { useAppContext } from 'components/App/context/appContext'
import { useProjectDetailsContext } from '../../../../../context/projectDetailsContext'
import {
  STANDARD_VIEW,
  ESTIMATION_VIEW,
  PRINT_VIEW,
  IPP_DEMAND_VIEW,
  FINALIZATION_VIEW,
  SPT_VIEW,
  SPT_SPLN_VIEW,
  IRL_VIEW,
  SPLN_VIEW,
  DISPLAY_SIGN_VIEW,
  VC_VIEW,
  STANDARD_VIEW_OLD,
  FINALIZATION_VIEW_OLD,
  PRINT_VIEW_OLD,
  ESTIMATION_VIEW_OLD,
  IRL_VIEW_OLD,
  SEPARATOR_AGENCY_VIEW_COMBINED,
} from '../../../views/AgGridSignListViews'
import { useProjectSignListContext } from '../../../context/projectSignListContext'
import { DialogProps } from '../../../../../../../models/app/DialogProps.model'
import { AgGridSignListView } from '../../../../../../../models/agGrid/AgGridSignListView.model'
import { useEnv } from '@praxis/component-runtime-env'
import { isCreativeAgent } from 'components/App/components/isCreativeAgent'

const ChangeViewButton = () => {
  const env = useEnv()
  const { userPermissions, userCompany } = useUserContext()!
  const { projectSignListView, setProjectSignListView, setDialogProps } =
    useAppContext()!
  const { currentProject } = useProjectDetailsContext()!
  const { signsModified } = useProjectSignListContext()!

  const handleViewChange = (view: AgGridSignListView) => {
    if (signsModified) {
      setDialogProps({
        headingText:
          'Changing view while editing may result in lost or erroneous data.',
        approveButtonText: 'Ok',
        onApprove: () => {
          setProjectSignListView(view)
          setDialogProps(new DialogProps())
        },
        onRefuse: () => {
          setDialogProps(new DialogProps())
        },
      })
    } else {
      setProjectSignListView(view)
    }
  }

  return (
    <Grid.Item className="hc-ph-dense hc-pb-dense">
      <Grid.Container>
        <Grid.Item>
          <Dropdown location="bottom-left" size="dense">
            <Button
              type="ghost"
              data-testid="openChangeView"
              disabled={
                userPermissions.isPrintVendor ||
                userPermissions.isTPSVendor ||
                userPermissions.isKitVendor ||
                userPermissions.isAgencySeparator ||
                isCreativeAgent(currentProject.roster, userCompany)
              }
            >
              View: {projectSignListView.label}
              <EnterpriseIcon className="hc-ml-dense" icon={CaretDownIcon} />
            </Button>
            <Dropdown.Menu>
              <Dropdown.MenuItem
                data-testid="openStandardView"
                onClick={() =>
                  handleViewChange(
                    env.beskarFeature ? STANDARD_VIEW : STANDARD_VIEW_OLD,
                  )
                }
              >
                Standard View
              </Dropdown.MenuItem>
              <Dropdown.MenuItem
                data-testid="openFinalizationView"
                onClick={() =>
                  handleViewChange(
                    env.beskarFeature
                      ? FINALIZATION_VIEW
                      : FINALIZATION_VIEW_OLD,
                  )
                }
              >
                Finalization View
              </Dropdown.MenuItem>
              <Dropdown.MenuItem
                data-testid="openEstimationView"
                onClick={() =>
                  handleViewChange(
                    env.beskarFeature ? ESTIMATION_VIEW : ESTIMATION_VIEW_OLD,
                  )
                }
              >
                Estimation View
              </Dropdown.MenuItem>
              <Dropdown.MenuItem
                data-testid="openSptView"
                onClick={() => {
                  currentProject.project_type === 'SPLN'
                    ? handleViewChange(SPT_SPLN_VIEW)
                    : handleViewChange(SPT_VIEW)
                }}
              >
                SPT View
              </Dropdown.MenuItem>
              <Dropdown.MenuItem
                data-testid="openVcView"
                onClick={() => handleViewChange(VC_VIEW)}
              >
                VC View
              </Dropdown.MenuItem>
              <Dropdown.MenuItem
                data-testid="openPrintView"
                onClick={() =>
                  env.beskarFeature
                    ? handleViewChange(PRINT_VIEW)
                    : handleViewChange(PRINT_VIEW_OLD)
                }
              >
                Print View
              </Dropdown.MenuItem>
              <Dropdown.MenuItem
                data-testid="openIppView"
                onClick={() => handleViewChange(IPP_DEMAND_VIEW)}
              >
                IPP Demand View
              </Dropdown.MenuItem>
              <Dropdown.MenuItem
                data-testid="openIrlView"
                onClick={() =>
                  handleViewChange(env.beskarFeature ? IRL_VIEW : IRL_VIEW_OLD)
                }
              >
                IRL View
              </Dropdown.MenuItem>
              <Dropdown.MenuItem
                data-testid="openSplnView"
                onClick={() => handleViewChange(SPLN_VIEW)}
              >
                SPLN View
              </Dropdown.MenuItem>
              <Dropdown.MenuItem
                data-testid="openDisplaySignView"
                onClick={() => handleViewChange(DISPLAY_SIGN_VIEW)}
              >
                Display Sign View
              </Dropdown.MenuItem>
              <Dropdown.MenuItem
                data-testid="openAgencySeparatorView"
                onClick={() => handleViewChange(SEPARATOR_AGENCY_VIEW_COMBINED)}
              >
                Agency/Separator View
              </Dropdown.MenuItem>
            </Dropdown.Menu>
          </Dropdown>
        </Grid.Item>
      </Grid.Container>
    </Grid.Item>
  )
}

export default ChangeViewButton
