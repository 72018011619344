export class LdapResponse {
  department: string
  display_name: string
  mail: string
  organization: string
  sam_account_name: string

  constructor(props: any = {}) {
    this.department = props.department || ''
    this.display_name = props.display_name || ''
    this.mail = props.mail || ''
    this.organization = props.organization || ''
    this.sam_account_name = props.sam_account_name || ''
  }
}
