import { get } from 'lodash'
import moment from 'moment'

export class PreviewRequest {
  /* === sign information === */
  sign_id: string
  sign_type: string
  sign_dpci: string
  sign_tcin: string
  sign_barcode_number: string
  set_date: Date
  locations: string[]
  /* === messaging and product information === */
  mpp: boolean
  sign_size: string
  headline: string
  topper_brand?: string
  disclaimer?: string
  inclusions?: string
  exclusions?: string
  logo_url?: string
  item_list: ItemInfo[]

  constructor(props: any = {}) {
    /* === sign properties === */
    this.sign_id = props.sign_id || ''
    this.sign_type = props.sign_type || ''
    this.sign_dpci = props.sign_dpci || ''
    this.sign_tcin = props.sign_tcin || ''
    this.sign_barcode_number = props.sign_barcode_number || ''
    this.set_date = props.set_date || parseInt(moment(new Date()).format('x'))
    this.locations = props.locations || ['3']
    /* === messaging and product properties === */
    this.mpp = props.mpp || false
    this.sign_size = props.sign_size || 'OTHER'
    this.headline = props.headline || ''
    this.topper_brand = props.topper_brand
    this.disclaimer = props.disclaimer
    this.inclusions = props.inclusions
    this.exclusions = props.exclusions
    this.logo_url = props.logo_url
    this.item_list = get(props, 'item_list', [{}]).map(
      (item: any) => new ItemInfo(item),
    )
  }
}

export class ItemInfo {
  product_dpci: string
  product_tcin: string
  product_description: string
  attributes?: string[]
  messaging_type?: string
  messaging_value?: MessagingValue
  manual_price_override: boolean

  constructor(props: any = {}) {
    this.product_dpci = props.product_dpci || ''
    this.product_tcin = props.product_tcin || ''
    this.product_description = props.product_description || ''
    this.attributes = props.attributes || ['']
    this.messaging_type = props.messaging_type
    this.messaging_value = props.messaging_value
    this.manual_price_override = props.manual_price_override || false
  }
}

export class MessagingValue {
  price: number
  quantity?: number

  constructor(props: any = {}) {
    this.price = props.price || 0
    this.quantity = props.quantity
  }
}
