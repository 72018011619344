export enum PROJECT_STATUS {
  PLANNING = 'Planning',
  PENDING = 'Pending',
  READY = 'Ready',
  SIGNING = 'Signing',
  FINALIZE = 'Finalize',
  ARCHIVE = 'Archive',
  REJECT = 'Reject',
  CANCEL = 'Cancel',
}

export const PROJECT_TYPE_OPTIONS = [
  {
    value: 'REV',
    label: 'REV',
  },
  {
    value: 'SPLN',
    label: 'SPLN',
  },
  {
    value: 'TRN',
    label: 'TRN',
  },
]

export const PROJECT_TACTIC_OPTIONS = [
  {
    value: 'Z001 Concept',
    label: 'Z001 Concept',
  },
  {
    value: 'Z032 Sponsorships',
    label: 'Z032 Sponsorships',
  },
  {
    value: 'Z078 Experience',
    label: 'Z078 Experience',
  },
  {
    value: 'Concept',
    label: 'Concept (Deprecated)',
    disabled: true,
  },
  {
    value: 'Shoot',
    label: 'Shoot (Deprecated)',
    disabled: true,
  },
  {
    value: 'Print',
    label: 'Print (Deprecated)',
    disabled: true,
  },
  {
    value: 'Style Guide',
    label: 'Style Guide (Deprecated)',
    disabled: true,
  },
]

export enum pogSelectionValues {
  PROJECT_SET_DATE = 'Use Project Set Date',
  MARKETING_PLAN_DATE = 'Use Marketing Plan Date',
  MULTIPLE_SET_DATES = 'Use Multiple Set Dates',
}

export const POG_SELECTION_OPTIONS = [
  {
    value: pogSelectionValues.PROJECT_SET_DATE,
    label: 'Use Project Set Date',
  },
  {
    value: pogSelectionValues.MARKETING_PLAN_DATE,
    label: 'Use Marketing Plan Date',
  },
  {
    value: pogSelectionValues.MULTIPLE_SET_DATES,
    label: 'Use Multiple Set Dates',
  },
]
