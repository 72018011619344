import React, { Fragment } from 'react'
import {
  Button,
  Form,
  Grid,
  Heading,
  Input,
  Spinner,
} from '@enterprise-ui/canvas-ui-react'
import CampaignCard from './CampaignCard'
import { CampaignTypeOptions } from '../constants/adminCampaignConstants'
import { useAdminCampaignsContext } from '../context/adminCampaignsContext'
import { useUserContext } from '../../../App/context/userContext'
import { CampaignTypeResponse } from '../../../../models/campaigns/CampaignTypeResponse.model'
import { ParentCampaignType } from '../../../../models/campaigns/ParentCampaignType.model'

export const CampaignList = () => {
  const {
    allCampaignList,
    setAllCampaignList,
    getAllCampaignList,
    parentCampaignList,
    setParentCampaignList,
    getParentCampaignList,
    childCampaignList,
    setChildCampaignList,
    getChildCampaignList,
    pyramidList,
    pyramidFilter,
    setPyramidFilter,
    campaignType,
    setCampaignType,
    isLoadingCampaignList,
    newCampaign,
    setNewCampaignGroup,
  } = useAdminCampaignsContext()!
  const { userName } = useUserContext()!

  const newCampaignGroup = new ParentCampaignType({
    name: 'New Campaign Group',
    pyramids: [
      {
        group_id: 0,
        group_name: 'NON RETAIL',
      },
    ],
    parent: true,
    child_types: [],
    non_adjacency: false,
    created_by: userName,
    created_date: new Date().toISOString(),
    updated_by: userName,
    updated_date: new Date().toISOString(),
  })

  const clearPyramidFilter = () => {
    setPyramidFilter([])
    if (campaignType === CampaignTypeOptions.ALL) {
      getAllCampaignList()
    } else if (campaignType === CampaignTypeOptions.GROUP) {
      getParentCampaignList()
    } else if (campaignType === CampaignTypeOptions.TYPE) {
      getChildCampaignList()
    }
  }

  const addNewCampaign = () => {
    setNewCampaignGroup({
      ...newCampaignGroup,
    })
  }

  return (
    <Fragment>
      <Button
        type="primary"
        fullWidth
        onClick={addNewCampaign}
        disabled={newCampaign.name !== ''}
      >
        Add New Campaign Group
      </Button>
      <Heading size={4} className="filter-Heading hc-clr-grey01">
        Filter By:
      </Heading>
      <Grid.Container>
        <Grid.Item xs={12}>
          <Grid.Container align="center" spacing="dense">
            <Grid.Item xs={9}>
              <Form.Field
                type="select"
                id="pyramid_filter"
                label="Pyramid"
                value={pyramidFilter}
                options={pyramidList}
                onUpdate={(e: any, pyramidIds: Array<number>) => {
                  setPyramidFilter(pyramidIds)
                  if (campaignType === CampaignTypeOptions.ALL) {
                    getAllCampaignList(pyramidIds)
                  } else if (campaignType === CampaignTypeOptions.GROUP) {
                    getParentCampaignList(pyramidIds)
                  } else if (campaignType === CampaignTypeOptions.TYPE) {
                    getChildCampaignList(pyramidIds)
                  }
                }}
              />
            </Grid.Item>
            <Grid.Item xs={true}>
              <Button type="primary" onClick={clearPyramidFilter}>
                Clear
              </Button>
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
      </Grid.Container>
      <Grid.Container>
        <Grid.Item xs>
          <Input.Radio
            id="campaign_filter"
            options={[
              { value: CampaignTypeOptions.ALL, label: 'All Campaigns' },
              { value: CampaignTypeOptions.GROUP, label: 'Campaign Groups' },
              { value: CampaignTypeOptions.TYPE, label: 'Campaign Types' },
            ]}
            value={campaignType}
            onUpdate={(id: any, value: string) => {
              setCampaignType(value)
              if (value === CampaignTypeOptions.ALL) {
                getAllCampaignList()
                setParentCampaignList([])
                setChildCampaignList([])
              } else if (value === CampaignTypeOptions.GROUP) {
                getParentCampaignList(pyramidFilter)
                setAllCampaignList([])
                setChildCampaignList([])
              } else if (value === CampaignTypeOptions.TYPE) {
                getChildCampaignList(pyramidFilter)
                setAllCampaignList([])
                setParentCampaignList([])
              }
            }}
          />
        </Grid.Item>
      </Grid.Container>
      <Grid.Container className="sem-CampaignList">
        <Grid.Item align="right" xs></Grid.Item>
      </Grid.Container>
      {isLoadingCampaignList ? (
        <Grid.Container
          className="sign-projects-loading-container"
          direction="column"
          justify="center"
          align="center"
        >
          <Grid.Item>Loading Campaigns...</Grid.Item>
          <Grid.Item>
            <Spinner />
          </Grid.Item>
        </Grid.Container>
      ) : (
        <Grid.Container className="hc-pb-expanded sem-CampaignList">
          <Grid.Item xs={true} className="scroller">
            {newCampaign.name !== '' && <CampaignCard campaign={newCampaign} />}
            {allCampaignList.length !== 0
              ? allCampaignList.map((campaign: CampaignTypeResponse) => (
                  <CampaignCard campaign={campaign} key={campaign.id} />
                ))
              : parentCampaignList.length !== 0
              ? parentCampaignList.map((campaign: CampaignTypeResponse) => (
                  <CampaignCard campaign={campaign} key={campaign.id} />
                ))
              : childCampaignList.length !== 0
              ? childCampaignList.map((campaign: CampaignTypeResponse) => (
                  <CampaignCard campaign={campaign} key={campaign.id} />
                ))
              : null}
          </Grid.Item>
        </Grid.Container>
      )}
    </Fragment>
  )
}

export default CampaignList
