import {
  SUPER_ADMIN,
  BLUEPRINT_ADMIN,
  BLUEPRINT_EDITOR,
  KIT_ADMIN,
  KIT_EDITOR,
  PROJECT_ADMIN,
  PROJECT_EDITOR,
  SIGN_ADMIN,
  SIGN_EDITOR,
  EXTERNAL_AGENCY_SEPARATOR,
  EXTERNAL_PRODUCT_VENDOR_CAT_MAN,
  EXTERNAL_PRINT_VENDOR,
  EXTERNAL_KIT_VENDOR,
  EXTERNAL_ASSETHUB_VENDOR,
  EXTERNAL_SAP_VENDOR,
  PRINT_PRODUCTION,
  TPS_PRINT_VENDOR,
} from '../constants/appConstants'
import { UserPermissions } from '../../../models/app/UserPermissions.model'
import { RosterElement } from '../../../models/roster/RosterElement.model'
import { RosterUser } from '../../../models/roster/RosterUser.model'
import { BusinessPartner } from '../../../models/app/BusinessPartner.model'

export const getUserPermissions = (memberOf: string[]): UserPermissions => {
  return new UserPermissions({
    isBlueprintAdmin:
      memberOf.includes(SUPER_ADMIN) || memberOf.includes(BLUEPRINT_ADMIN),

    isBlueprintEditor:
      memberOf.includes(SUPER_ADMIN) ||
      memberOf.includes(BLUEPRINT_ADMIN) ||
      memberOf.includes(BLUEPRINT_EDITOR),

    isProjectAdmin:
      memberOf.includes(SUPER_ADMIN) || memberOf.includes(PROJECT_ADMIN),

    isProjectEditor:
      memberOf.includes(SUPER_ADMIN) ||
      memberOf.includes(PROJECT_ADMIN) ||
      memberOf.includes(PROJECT_EDITOR),

    isSignAdmin:
      memberOf.includes(SUPER_ADMIN) || memberOf.includes(SIGN_ADMIN),

    isSignEditor:
      memberOf.includes(SUPER_ADMIN) ||
      memberOf.includes(SIGN_ADMIN) ||
      memberOf.includes(SIGN_EDITOR) ||
      memberOf.includes(EXTERNAL_PRODUCT_VENDOR_CAT_MAN) ||
      memberOf.includes(EXTERNAL_PRINT_VENDOR) ||
      memberOf.includes(EXTERNAL_KIT_VENDOR) ||
      memberOf.includes(EXTERNAL_AGENCY_SEPARATOR),

    isKitAdmin: memberOf.includes(SUPER_ADMIN) || memberOf.includes(KIT_ADMIN),

    isKitEditor:
      memberOf.includes(SUPER_ADMIN) ||
      memberOf.includes(KIT_EDITOR) ||
      memberOf.includes(KIT_ADMIN),

    isSuperAdmin: memberOf.includes(SUPER_ADMIN),

    isAgencySeparator: memberOf.includes(EXTERNAL_AGENCY_SEPARATOR),

    isProductVendorOrCatMan: memberOf.includes(EXTERNAL_PRODUCT_VENDOR_CAT_MAN),

    isPrintVendor: memberOf.includes(EXTERNAL_PRINT_VENDOR),

    isKitVendor: memberOf.includes(EXTERNAL_KIT_VENDOR),

    isAssethubVendor: memberOf.includes(EXTERNAL_ASSETHUB_VENDOR),

    isSAPVendor: memberOf.includes(EXTERNAL_SAP_VENDOR),

    isPrintProduction: memberOf.includes(PRINT_PRODUCTION),

    isTPSVendor: memberOf.includes(TPS_PRINT_VENDOR),
  })
}

export const userIsCategoryManager = (
  company: string,
  roster?: RosterElement[],
) =>
  roster
    ? roster
        .find((element: RosterElement) => element.title === 'Category Manager')
        ?.users.some(
          (user: RosterUser) =>
            user instanceof BusinessPartner && user.name === company,
        )
    : false

export const userIsCreativeAgent = (
  company: string,
  roster?: RosterElement[],
) =>
  roster
    ? roster
        .find((element: RosterElement) => element.title === 'Creative Agency')
        ?.users.some(
          (user: RosterUser) =>
            user instanceof BusinessPartner && user.name === company,
        )
    : false
