export class TemplateResponse {
  template_id: string
  schedule_type: string
  template_name: string
  master_template: boolean

  constructor(props: any = {}) {
    this.template_id = props.template_id || ''
    this.schedule_type = props.schedule_type || ''
    this.template_name = props.template_name || ''
    this.master_template = props.master_template || false
  }
}
