import React, { createContext, useCallback, useContext, useState } from 'react'
import { connect } from 'react-redux'
import Axios from 'axios'
import {
  ADMIN_SERVICE_API_DOMAIN_URL,
  KIT_SERVICE_API_DOMAIN_URL,
  SIGN_SERVICE_API_DOMAIN_URL,
  TOASTER_DEFAULTS,
} from 'components/App/constants/appConstants'
import { useEnv } from '@praxis/component-runtime-env'
import { KitInfoResponse } from '../../../../../models/kits/KitInfoResponse.model'
import { useAppContext } from 'components/App/context/appContext'
import axios from 'axios'
import FileDownload from 'js-file-download'
import { KitFinalizeRequestDto } from '../../../../../models/kits/KitFinalizeRequest.model'
import { KitInfoRequest } from '../../../../../models/kits/KitInfoRequest.model'
import {
  KitResponse,
  KitStatus,
} from '../../../../../models/kits/KitResponse.model'
import { KitRequest } from '../../../../../models/kits/KitRequest.model'
import SignResponse from '../../../../../models/signs/SignResponse.model'
import { useToaster } from '@enterprise-ui/canvas-ui-react'

type ContextType = {
  setKittingVendors: Function
  kitInfoResponse: KitInfoResponse
  setKitInfoResponse: Function
  getKitInfo: Function
  exportKittingInfo: Function
  finalizeAll: Function
  updateKitInfo: (kitInfoRequest: KitInfoRequest) => void
  kittingVendors: any[]
  getKittingVendors: (kits: KitResponse[]) => void
  filteredKits: KitResponse[]
  setFilteredKits: Function
  getFilteredKits: Function
  kit: KitResponse
  setKit: Function
  getKit: (kit_id: string, kitting_info_id: string) => void
  updateKit: (kitInfoId: string, req: KitRequest) => void
  isKitLoading: boolean
  setIsKitLoading: Function
  locations: any
  getLocations: Function
  filterLocations: Function
  projectId: string
  finalizeCheckBoxOptions: any[]
  finalizedFilterValues: string[]
  setFinalizedFilterValues: Function
  activeKit: string
  setActiveKit: Function
  signs: SignResponse[]
  refreshSigns: Function
  doReloadPage: boolean
  isMergeKitsOpen: boolean
  setIsMergeKitsOpen: (isOpen: boolean) => void
  mergeKits: (kitIds: string[]) => void
  downloadMergePreview: (kitIds: string[], fileName: string) => void
}

export const ProjectDistributionContext = createContext<
  ContextType | undefined
>(undefined)

type Props = {
  children: React.ReactNode
}

const ProjectDistributionProviderComponent = ({ children }: Props) => {
  const { setFullPageLoadingMessage } = useAppContext()!
  const env = useEnv()
  const makeToast = useToaster()

  const [kittingVendors, setKittingVendors] = useState<any[]>([])

  const [kitInfoResponse, setKitInfoResponse] = useState<KitInfoResponse>(
    new KitInfoResponse(),
  )

  const [filteredKits, setFilteredKits] = useState<KitResponse[]>([])

  const [kit, setKit] = useState<KitResponse>(new KitResponse())

  const [isKitLoading, setIsKitLoading] = useState(false)

  const [projectId, setProjectId] = useState('')

  const [activeKit, setActiveKit] = useState('')

  const [isMergeKitsOpen, setIsMergeKitsOpen] = useState(false)

  const finalizeCheckBoxOptions = [
    { value: KitStatus.FINALIZED, label: 'Show Finalized Kits' },
    { value: KitStatus.CREATED, label: 'Show Not Finalized Kits' },
  ]

  const [finalizedFilterValues, setFinalizedFilterValues] = useState([
    KitStatus.FINALIZED,
    KitStatus.CREATED,
  ])

  const [doReloadPage, setDoReloadPage] = useState<boolean>(false)

  const [locations, setLocations] = useState<any[]>([])

  const [signs, setSigns] = useState<SignResponse[]>([])
  const getLocations = async () => {
    try {
      if (locations === undefined || locations.length === 0) {
        const res = await Axios.get(
          `${env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL}/locations/all`,
        ).then()
        setLocations(res.data)
      }
      return locations
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Get Locations',
        message: err.response.data.message,
      })
      return []
    }
  }

  const filterLocations = (
    locations: any,
    locationsTofilter: string[],
  ): any[] => {
    return locations.filter((location: any) => {
      return locationsTofilter.includes(location.location_id.toString())
    })
  }

  const getKitInfo = useCallback(
    async (project_id: string) => {
      setFullPageLoadingMessage('Loading Kitting Info...')
      setProjectId(project_id)
      try {
        const res = await Axios.get(
          `${
            env.apiDomainUrl + KIT_SERVICE_API_DOMAIN_URL
          }/kits/kit_infos?project_id=${project_id}`,
          {
            headers: { 'Content-Type': 'application/json' },
          },
        )
        setKitInfoResponse(res.data)
      } catch (err: any) {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'error',
          heading: 'Failed to Get Kitting Info',
          message: err.response.data.message,
        })
      }
      setFullPageLoadingMessage('')
    },
    [
      makeToast,
      setKitInfoResponse,
      setFullPageLoadingMessage,
      env.apiDomainUrl,
    ],
  )

  const getKit = useCallback(
    async (kit_id: string, kitting_info_id: string) => {
      setIsKitLoading(true)
      try {
        const res = await Axios.get(
          `${
            env.apiDomainUrl + KIT_SERVICE_API_DOMAIN_URL
          }/kits?kit_id=${kit_id}&kittingInfoId=${kitting_info_id}`,
          {
            headers: { 'Content-Type': 'application/json' },
          },
        )
        setKit(res.data)
      } catch (err: any) {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'error',
          heading: 'Failed to Get Kit',
          message: err.response.data.message,
        })
      }
      setIsKitLoading(false)
    },
    [makeToast, env.apiDomainUrl],
  )

  const updateKit = async (kitInfoId: string, kitRequest: KitRequest) => {
    const current_status = kitRequest.status
    const newKitRequest = new KitRequest({
      ...kitRequest,
      sign_quantities: kitRequest.sign_quantities?.filter(
        (value) => value !== undefined && value.sign_quantity !== undefined,
      ),
    })
    try {
      setIsKitLoading(true)
      const res = await Axios.put(
        `${
          env.apiDomainUrl + KIT_SERVICE_API_DOMAIN_URL
        }/kits?kittingInfoId=${kitInfoId}`,
        newKitRequest,
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      setKit(res.data)
      setActiveKit(res.data.id)
      getKitInfo(projectId)
      setFilteredKits(
        filteredKits.map((kit) => {
          if (kit.kit_id === res.data.kit_id) {
            kit.status = res.data.status
            return kit
          }
          return kit
        }),
      )
      if (current_status !== res.data.status) {
        setDoReloadPage(true)
      }
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'success',
        heading: 'Kit Updated',
        message: 'Successfully updated kit',
      })
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Update Kit',
        message: err.response.data.message,
      })
    }
    setIsKitLoading(false)
  }

  const exportKittingInfo = useCallback(
    async (id: string, filename: string, pricing: boolean) => {
      let exportPsfUrl = `${
        env.apiDomainUrl + KIT_SERVICE_API_DOMAIN_URL
      }/kits/export?kitting_info_id=${id}`
      if (pricing) {
        exportPsfUrl = `${exportPsfUrl}&pricing=true`
      }
      setFullPageLoadingMessage('Exporting PSF...')
      try {
        axios({
          url: exportPsfUrl,
          method: 'GET',
          responseType: 'blob', // Important
        }).then((response) => {
          setFullPageLoadingMessage('')
          FileDownload(response.data, filename)
        })
      } catch (err: any) {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'error',
          heading: 'Failed to Export PSF',
          message: err.response.data.message,
        })
      }
      setFullPageLoadingMessage('')
    },
    [makeToast, setFullPageLoadingMessage, env.apiDomainUrl],
  )

  const finalizeAll = useCallback(
    async (id: string, dts: boolean, updated_by: string) => {
      setFullPageLoadingMessage('Finalizing Kits...')
      try {
        await Axios.put(
          `${
            env.apiDomainUrl + KIT_SERVICE_API_DOMAIN_URL
          }/kits/finalize_all?kitting_info_id=${id}&dts=${dts}`,
          new KitFinalizeRequestDto({ updated_by: updated_by }),
          {
            headers: { 'Content-Type': 'application/json' },
          },
        )
        setDoReloadPage(true)
      } catch (err: any) {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'error',
          heading: 'Failed to Finalize Kits',
          message: err.response.data.message,
        })
      }
      setFullPageLoadingMessage('')
    },
    [makeToast, setFullPageLoadingMessage, setDoReloadPage, env.apiDomainUrl],
  )

  const updateKitInfo = async (kitInfoRequest: KitInfoRequest) => {
    setFullPageLoadingMessage('Updating Kitting Info...')
    try {
      const res = await Axios.put(
        `${env.apiDomainUrl + KIT_SERVICE_API_DOMAIN_URL}/kits/kit_infos/${
          kitInfoRequest.id
        }`,
        kitInfoRequest,
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      setKitInfoResponse(res.data)
      setDoReloadPage(true)
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'success',
        heading: 'Kit Updated',
        message: 'Successfully updated kitting info',
      })
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Update Kitting Info',
        message: err.response.data.message,
      })
    }
    setFullPageLoadingMessage('')
  }

  const getKittingVendors = (kits?: KitResponse[]) => {
    let defaultKittingVendor: any[] = [{ value: 'All', label: 'All' }]
    const uniqueKittingVendors: any[] =
      kits
        ?.map((kit: KitResponse) => kit.kit_vendor)
        .filter((v: string, i: number, a: string[]) => a.indexOf(v) === i)
        .sort() || []
    setKittingVendors([
      ...defaultKittingVendor,
      ...uniqueKittingVendors.map((x: string) => ({ value: x, label: x })),
    ])
  }

  const getFilteredKits = (
    kittingVendor: string,
    filterFinalizedKits: string[],
    kits: KitResponse[],
  ) => {
    if (
      (kittingVendor === 'All' || kittingVendor === undefined) &&
      filterFinalizedKits.length !== finalizeCheckBoxOptions.length
    ) {
      setFilteredKits(
        kits.filter((kit: KitResponse) =>
          filterFinalizedKits.some((value: string) => value === kit.status),
        ),
      )
    } else if (kittingVendor === 'All' || kittingVendor === undefined) {
      setFilteredKits(kits)
    } else {
      setFilteredKits(
        kits.filter(
          (kit: KitResponse) =>
            kit.kit_vendor === kittingVendor &&
            filterFinalizedKits.some((value: string) => value === kit.status),
        ),
      )
    }
    setActiveKit('')
  }

  const refreshSigns = useCallback(
    async (id: string) => {
      setFullPageLoadingMessage('Loading Kit Signs...')
      try {
        const res = await Axios.get(
          `${
            env.apiDomainUrl + SIGN_SERVICE_API_DOMAIN_URL
          }/signs?project_id=${id}`,
        )
        const sortedSigns = res.data.sort((a: any, b: any) =>
          a.created_date < b.created_date ? 1 : -1,
        )
        setSigns(sortedSigns.map((sign: any) => new SignResponse(sign)))
      } catch (err: any) {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'error',
          heading: 'Failed to Get Project Signs',
          message: err.response.data.message,
        })
      }
      setFullPageLoadingMessage('')
    },
    [makeToast, setFullPageLoadingMessage, env.apiDomainUrl],
  )

  const mergeKits = async (kitIds: string[]) => {
    setFullPageLoadingMessage('Merging kits...')
    try {
      await Axios.put(
        `${env.apiDomainUrl + KIT_SERVICE_API_DOMAIN_URL}/kits/merge_kits`,
        {
          id: kitInfoResponse.id,
          kit_ids: kitIds,
        },
      )
      setDoReloadPage(true)
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Kits Merged',
        message: 'Successfully merged kits together',
      })
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Kits Merge Failed',
        message: err.response.data.message,
      })
    }
    setFullPageLoadingMessage('')
  }

  const downloadMergePreview = async (kitIds: string[], fileName: string) => {
    setFullPageLoadingMessage('Downloading merge preview...')
    try {
      const res = await axios.post(
        `${env.apiDomainUrl + KIT_SERVICE_API_DOMAIN_URL}/kits/preview_kits`,
        {
          id: kitInfoResponse.id,
          kit_ids: kitIds,
        },
        {
          responseType: 'blob',
        },
      )
      FileDownload(res.data, fileName)
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Preview Download Failed',
        message: err.response.data.message,
      })
    }
    setFullPageLoadingMessage('')
  }

  return (
    <ProjectDistributionContext.Provider
      value={{
        kittingVendors,
        setKittingVendors,
        getKittingVendors,
        kitInfoResponse,
        setKitInfoResponse,
        getKitInfo,
        exportKittingInfo,
        finalizeAll,
        updateKitInfo,
        filteredKits,
        setFilteredKits,
        getFilteredKits,
        kit,
        setKit,
        getKit,
        updateKit,
        isKitLoading,
        setIsKitLoading,
        locations,
        getLocations,
        filterLocations,
        projectId,
        finalizeCheckBoxOptions,
        finalizedFilterValues,
        setFinalizedFilterValues,
        activeKit,
        setActiveKit,
        signs,
        refreshSigns,
        doReloadPage,
        isMergeKitsOpen,
        setIsMergeKitsOpen,
        mergeKits,
        downloadMergePreview,
      }}
    >
      {children}
    </ProjectDistributionContext.Provider>
  )
}

export const ProjectDistributionProvider = connect(
  null,
  null,
)(ProjectDistributionProviderComponent)

export const useProjectDistributionContext = () =>
  useContext(ProjectDistributionContext)
