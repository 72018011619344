import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { Department } from '../../../../../../../models/merchandise/hierarchy/Department.model'
import { useBlueprintProjectListContext } from '../../../context/blueprintProjectListContext'
import { DropdownOption } from '../../../../../../../models/app/DropdownOption.model'
import SapTableProject from '../../../../../../../models/projects/SapTableProject.model'
import { Grid } from '@enterprise-ui/canvas-ui-react'

export interface Props {
  value: Department[]
  data: SapTableProject
}

const DepartmentsCellEditor = forwardRef(({ value, data }: Props, ref) => {
  const { loadDepartmentOptions, departmentOptions } =
    useBlueprintProjectListContext()!
  const [editValue, setEditValue] = useState(value)

  useEffect(() => {
    loadDepartmentOptions(data.divisions)
  }, [data.divisions, loadDepartmentOptions])

  const handleChange = (id: string, value: any) =>
    setEditValue(
      value.map((option: DropdownOption<Department>) => option.value),
    )

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return editValue
      },
      isPopup() {
        return true
      },
    }
  })

  return (
    <div className="hc-pa-dense">
      <Grid.Container>
        <Grid.Item xs={12}>
          <Autocomplete
            label="Project Department(s)"
            multiselect
            value={editValue.map(
              (dept: Department) =>
                new DropdownOption<Department>({
                  id: dept.department_id,
                  value: dept,
                  label: dept.department_display_name,
                }),
            )}
            options={departmentOptions.map(
              (dept: Department) =>
                new DropdownOption<Department>({
                  id: dept.department_id,
                  value: dept,
                  label: dept.department_display_name,
                }),
            )}
            onUpdate={(id: string, value: any) => {
              if (value) handleChange(id, value)
            }}
          />
        </Grid.Item>
      </Grid.Container>
    </div>
  )
})

export default DepartmentsCellEditor
