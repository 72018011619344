import React from 'react'
import { SignDetailsProvider } from './context/signDetailsContext'
import { SignFormsProvider } from './context/signFormsContext'
import { SignBulkEditProvider } from '../SignBulkEdit/context/signBulkEditContext'
import { SignPreviewProvider } from './context/signPreviewContext'
import { SignNotesProvider } from './components/SignNotes/context/signNotesContext'
import SignDetailsContainer from './components/SignDetailsContainer'
import PageChangesWrapper from '../App/components/PageChangesWrapper'
import { Layout } from '@enterprise-ui/canvas-ui-react'
import { ProjectDetailsProvider } from '../ProjectDetails/context/projectDetailsContext'

const SignDetails = (props: any) => (
  <PageChangesWrapper>
    <ProjectDetailsProvider>
      <SignDetailsProvider>
        <SignPreviewProvider>
          <SignFormsProvider>
            <SignBulkEditProvider>
              <SignNotesProvider>
                <Layout.Body className="hc-pa-expanded" includeRail>
                  <SignDetailsContainer {...props} />
                </Layout.Body>
              </SignNotesProvider>
            </SignBulkEditProvider>
          </SignFormsProvider>
        </SignPreviewProvider>
      </SignDetailsProvider>
    </ProjectDetailsProvider>
  </PageChangesWrapper>
)

export default SignDetails
