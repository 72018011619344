import { get } from 'lodash'
import { Department } from '.'

export default class EditingFacets {
  departments: Department[]
  sign_languages: SignFacetOption[]
  sign_sizes: SignFacetOption[]
  sign_types: SignFacetOption[]
  unit_of_measure: SignFacetOption[]
  sign_substrate: SignFacetOption[]

  constructor(props: any = {}) {
    this.departments = get(props, 'departments', [{}]).map(
      (dept: any) => new Department(dept),
    )
    this.sign_languages = get(props, 'sign_languages', [{}]).map(
      (sign_language: any) => new SignFacetOption(sign_language),
    )
    this.sign_sizes = get(props, 'sign_sizes', [{}]).map(
      (sign_size: any) => new SignFacetOption(sign_size),
    )
    this.sign_types = get(props, 'sign_types', [{}]).map(
      (sign_type: any) => new SignFacetOption(sign_type),
    )
    this.unit_of_measure = get(props, 'unit_of_measure', [{}]).map(
      (unit: any) => new SignFacetOption(unit),
    )
    this.sign_substrate = get(props, 'sign_substrate', [{}]).map(
      (sign_substrate: any) => new SignFacetOption(sign_substrate),
    )
  }
}

export class SignFacetOption {
  value?: string
  label: string

  constructor(props: any = {}) {
    this.value = props.value
    this.label = props.label || ''
  }
}
