import React from 'react'
import { Grid } from '@enterprise-ui/canvas-ui-react'
import { AgGridReact, AgGridColumn } from 'ag-grid-react'
import { useAppContext } from '../../../App/context/appContext'
import { useDashboardContext } from '../../context/dashboardContext'
import { useAgGridDashboardContext } from '../../context/agGridDashboardContext'
import { Column } from '../../../../models/agGrid/AgGridColumn.model'
import { PROJECT_VIEW_COLUMNS } from '../../../../models/agGrid/AgGridDashboardView'
import { ErrorCard } from 'components/ErrorCard'

const ProjectSummaryGrid = () => {
  const { setPageHasChanges } = useAppContext()!
  const {
    projectSummaryList,
    modifiedProjects,
    setModifiedProjects,
    dashboardErrors,
  } = useDashboardContext()!
  const {
    onGridReady,
    gridOptions,
    onFirstDataRender,
    onFilterChange,
    onSortChanged,
  } = useAgGridDashboardContext()!

  const rowClassRules = {
    'ag-row-highlight': function (params: any) {
      return (
        params.data.recreate_kits || params.data.dependency_complete === 'Yes'
      )
    },
  }

  const handleCellValueChange = (e: any) => {
    setModifiedProjects([...modifiedProjects, e.data])
    setPageHasChanges(true)
    return (e.data.modified = true)
  }

  return (
    <Grid.Item xs={12}>
      <div className="ag-theme-rubix dashboard-table">
        {dashboardErrors.PROJECT_SUMMARY_LIST ? (
          <ErrorCard
            text={`${dashboardErrors.PROJECT_SUMMARY_LIST}. Please try again.`}
          />
        ) : (
          <AgGridReact
            rowData={projectSummaryList}
            gridOptions={gridOptions}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRender}
            applyColumnDefOrder={true}
            suppressCellSelection={true}
            suppressRowClickSelection={true}
            rowClassRules={rowClassRules}
            onFilterChanged={(e) => onFilterChange(e)}
            onSortChanged={(e) => onSortChanged(e)}
            onCellValueChanged={(e: any) => handleCellValueChange(e)}
          >
            {PROJECT_VIEW_COLUMNS.map((column: Column, index: number) => (
              <AgGridColumn
                field={column.field}
                colId={column.colId}
                headerName={column.headerName}
                width={column.width || 200}
                type={column.type}
                filter={column.filter || true}
                sort={column.sort}
                key={index}
              />
            ))}
          </AgGridReact>
        )}
      </div>
    </Grid.Item>
  )
}

export default ProjectSummaryGrid
