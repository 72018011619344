import React, { Fragment } from 'react'
import { Tooltip } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon from '@enterprise-ui/icons'
import SapTableProject from '../../../../../../../models/projects/SapTableProject.model'
import {
  sapStatusIconColor,
  sapStatusIcon,
  sapStatusMessage,
} from '../../../helpers/projectListHelper'

export interface Props {
  value: string
  data: SapTableProject
}

const PidCellRenderer = ({ value, data }: Props) => {
  return (
    <Fragment>
      <Tooltip
        content={sapStatusMessage(
          data.sap_project_execution_status,
          data.sap_project_execution_message,
        )}
        location="right"
      >
        <EnterpriseIcon
          className="hc-mr-dense"
          size="inline"
          icon={sapStatusIcon(data.sap_project_execution_status)}
          color={sapStatusIconColor(data.sap_project_execution_status)}
        />
      </Tooltip>
      {value}
    </Fragment>
  )
}

export default PidCellRenderer
