import React, { useEffect } from 'react'
import { Grid } from '@enterprise-ui/canvas-ui-react'
import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import { useAgGridProjectListContext } from '../../context/agGridProjectListContext'
import { Column } from '../../../../../../models/agGrid/AgGridColumn.model'
import {
  filterProjectListByView,
  rowClassRules,
} from '../../helpers/projectListHelper'
import { BLUEPRINT_PROJECT_LIST } from '../../views/AgGridProjectListViews'
import ActionsDropdown from './components/ActionsDropdown'
import { useBlueprintProjectListContext } from '../../context/blueprintProjectListContext'
import {
  CellValueChangedEvent,
  FilterChangedEvent,
  SelectionChangedEvent,
  SortChangedEvent,
} from 'ag-grid-community'
import TableEditorButtons from './components/TableEditorButtons'
import { useBlueprintDetailsContainerContext } from '../../../../context/blueprintDetailsContainerContext'
import ChangeViewButton from './components/ChangeViewButton'
import { useAppContext } from 'components/App/context/appContext'

const AgGridProjectListTable = () => {
  const { blueprintProjectListView } = useAppContext()!
  const { currentBlueprint } = useBlueprintDetailsContainerContext()!
  const { projectList, loadCampaigns, isProjectListFullScreen } =
    useBlueprintProjectListContext()!
  const {
    rowData,
    setRowData,
    onGridReady,
    gridOptions,
    onFirstDataRender,
    onSelectionChange,
    onCellValueChange,
    onFilterChange,
    onSortChanged,
  } = useAgGridProjectListContext()!

  useEffect(() => {
    setRowData(filterProjectListByView(blueprintProjectListView, projectList))
  }, [blueprintProjectListView, projectList, setRowData])

  useEffect(() => {
    currentBlueprint.campaign.name !== '' &&
      loadCampaigns(currentBlueprint.set_date, currentBlueprint.campaign.name)
    // eslint-disable-next-line
  }, [currentBlueprint])

  return (
    <Grid.Container className="hc-pa-normal" justify="flex-end">
      <ActionsDropdown />
      <ChangeViewButton />
      <TableEditorButtons />
      <Grid.Item xs={12} className="hc-pt-dense">
        <div
          className={`ag-theme-rubix blueprint-project-list-table ${
            isProjectListFullScreen && 'full-screen'
          }`}
        >
          <AgGridReact
            gridOptions={gridOptions}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRender}
            rowData={rowData}
            rowDragManaged
            rowDragMultiRow
            applyColumnDefOrder
            suppressRowClickSelection
            rowSelection="multiple"
            enableRangeSelection
            enableFillHandle
            fillHandleDirection="y"
            rowClassRules={rowClassRules}
            onFilterChanged={(e: FilterChangedEvent) => onFilterChange(e)}
            onSortChanged={(e: SortChangedEvent) => onSortChanged(e)}
            onSelectionChanged={(e: SelectionChangedEvent) =>
              onSelectionChange(e)
            }
            onCellValueChanged={(e: CellValueChangedEvent) =>
              onCellValueChange(e)
            }
          >
            {BLUEPRINT_PROJECT_LIST.map((column: Column, index: number) => (
              <AgGridColumn
                headerName={column.headerName}
                field={column.field}
                width={column.width || 148}
                type={column.type}
                filter={column.filter || 'agTextColumnFilter'}
                colId={column.colId}
                key={index}
              />
            ))}
          </AgGridReact>
        </div>
      </Grid.Item>
    </Grid.Container>
  )
}

export default AgGridProjectListTable
