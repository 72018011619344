import React, { Fragment, useState } from 'react'
import {
  Button,
  Divider,
  Form,
  Grid,
  Heading,
  Tooltip,
  Spinner,
  Input,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, {
  OverflowMenuHorizontalIcon,
} from '@enterprise-ui/icons'
import { useSignDetailsContext } from '../../../context/signDetailsContext'
import { useMscLocationsContext } from '../../../context/mscLocationsContext'
import { useSignBulkEditContext } from 'components/SignBulkEdit/context/signBulkEditContext'
import LocationsModal from '../../modals/LocationsModal'
import DepartmentGroupRow from './DepartmentGroupRow'
import {
  MSC_ROW,
  SIGN_DETAILS_SECTION,
} from '../../../constants/signDetailsConstants'
import { useAppContext } from 'components/App/context/appContext'
import { DialogProps } from '../../../../../models/app/DialogProps.model'
import { useSignFormsContext } from '../../../context/signFormsContext'
import { isEditable } from 'components/App/helpers/signEditHelper'
import { useUserContext } from 'components/App/context/userContext'
import SignResponse from '../../../../../models/signs/SignResponse.model'

export interface Props {
  isBulkEdit?: boolean
  onPogSign: boolean
  handleChange: (id: string, value: any) => void
  errors: any
  resetSelections: (category: string) => void
  selectedSigns?: SignResponse[]
}

const IncludeLocations = ({
  isBulkEdit = false,
  onPogSign = false,
  handleChange,
  errors,
  resetSelections,
  selectedSigns,
}: Props) => {
  const { setDialogProps } = useAppContext()!
  const {
    isInProject,
    currentSign,
    addToModifiedSections,
    selectedIncludeMscRow,
    setSelectedIncludeMscRow,
    project,
  } = useSignDetailsContext()!

  const { addBulkEditField, selectedFields } = useSignBulkEditContext()!
  const { signDistributionFormik } = useSignFormsContext()!
  const { userPermissions, userCompany, userType } = useUserContext()!

  // desctructoring for `department_location_groups` because it is so deeply nested
  const { department_location_groups, locations } =
    signDistributionFormik.values.location_filter_info.include_location_info

  const { isLoading } = useMscLocationsContext()!
  const [locationModalCategory, setLocationModalCategory] = useState('')

  const handleRadioSelect = (value: MSC_ROW) => {
    if (value === MSC_ROW.CLEAR) {
      addBulkEditField(
        'distribution.location_filter_info[include_location_info]',
        {},
      )
    }
    resetSelections('include')
    setSelectedIncludeMscRow(value)
    addToModifiedSections(SIGN_DETAILS_SECTION.DISTRIBUTION)
    if (value === MSC_ROW.ALL_STORES) {
      handleChange(
        'location_filter_info.include_location_info.all_locations',
        true,
      )
      if (isBulkEdit) {
        addBulkEditField(
          'distribution.location_filter_info.include_location_info.all_locations',
          true,
        )
      }
    }
  }

  const isDisabled = (field: string) => {
    if (isBulkEdit) {
      if (selectedSigns && selectedSigns.length > 0) {
        for (var i = 0; i < selectedSigns.length; i++) {
          if (
            !isEditable(
              selectedSigns[i],
              field,
              userPermissions,
              userCompany,
              userType,
              isInProject,
              project.workflow.status,
              undefined,
              project,
            )
          ) {
            return true
          }
        }
        return false
      }
      return true
    } else {
      return !isEditable(
        currentSign,
        field,
        userPermissions,
        userCompany,
        userType,
        isInProject,
        project.workflow.status,
        undefined,
        project,
      )
    }
  }

  const checkFormIsEmpty = () => {
    let {
      all_locations: allLocations,
      locations: localLocations,
      department_location_groups: localDeptLocGroups,
    } = signDistributionFormik.values.location_filter_info.include_location_info
    return (
      !allLocations &&
      localLocations.length === 0 &&
      localDeptLocGroups[0].department_id.length === 0
    )
  }

  const getOptions = () => {
    if (onPogSign) {
      return [
        { value: MSC_ROW.CLEAR, label: 'Clear Inclusions' },
        {
          value: MSC_ROW.SPECIFIC_STORES,
          label: 'Specific Store(s):',
        },
        { value: MSC_ROW.DEPARTMENT, label: 'Department MSC' },
      ]
    } else {
      return [
        { value: MSC_ROW.CLEAR, label: 'Clear Inclusions' },
        {
          value: MSC_ROW.SPECIFIC_STORES,
          label: 'Specific Store(s):',
        },
        { value: MSC_ROW.ALL_STORES, label: 'All Stores' },
        { value: MSC_ROW.DEPARTMENT, label: 'Department MSC' },
      ]
    }
  }
  return (
    <Fragment>
      <Grid.Item xs={12} className="hc-pb-none">
        <Heading className="hc-clr-grey01" size={6}>
          Include Stores Selection
        </Heading>
      </Grid.Item>
      <Grid.Item
        xs={12}
        className="hc-pt-dense"
        style={{ position: 'relative' }}
      >
        <Grid.Container justify="center">
          <Grid.Item
            className={`hc-pr-none hc-pb-none ${isLoading ? 'sem_Fade' : ''}`}
          >
            <Input.Radio
              className="sem_MscFacetsRadio"
              id="include_msc"
              value={selectedIncludeMscRow}
              options={getOptions()}
              disabled={
                isBulkEdit
                  ? !selectedFields.includes(
                      'distribution.location_filter_info',
                    )
                  : isDisabled('include_msc')
              }
              onUpdate={(id: string, value: MSC_ROW) => {
                if (!checkFormIsEmpty()) {
                  setDialogProps(
                    new DialogProps({
                      headingText:
                        'This action will clear any selected inclusions.',
                      approveButtonText: 'OK',
                      refuseButtonText: 'Cancel',
                      onApprove: () => {
                        handleRadioSelect(value)
                        setDialogProps(new DialogProps())
                      },
                      onRefuse: () => {
                        setDialogProps(new DialogProps())
                      },
                    }),
                  )
                } else {
                  handleRadioSelect(value)
                }
              }}
            />
          </Grid.Item>
          <Grid.Item
            xs={true}
            className={`hc-pr-dense ${isLoading ? 'sem_Fade' : ''}`}
          >
            <Grid.Container
              style={{ marginTop: '40px', marginBottom: '-16px' }}
            >
              <Grid.Item
                xs={onPogSign ? 10 : 5}
                className="hc-pr-none hc-mt-dense"
              >
                <Form.Field
                  className="rbx-dense-form"
                  id="includeSpecStores"
                  value={locations}
                  size="dense"
                  disabled
                />
              </Grid.Item>
              <Grid.Item xs={1} className="hc-pl-dense hc-mt-dense">
                <Tooltip location="bottom" content="Store Locations">
                  <Button
                    type="primary"
                    size="dense"
                    id="select_stores"
                    onClick={() => setLocationModalCategory('include')}
                    disabled={
                      selectedIncludeMscRow !== MSC_ROW.SPECIFIC_STORES ||
                      isDisabled('select_stores')
                    }
                  >
                    <EnterpriseIcon icon={OverflowMenuHorizontalIcon} />
                  </Button>
                  {locationModalCategory && (
                    <LocationsModal
                      modalCategory={locationModalCategory}
                      toggleVisible={setLocationModalCategory}
                      handleFormChange={handleChange}
                    />
                  )}
                </Tooltip>
              </Grid.Item>
              {!onPogSign && (
                <Grid.Item xs={6} className="hc-pt-none hc-pr-expanded">
                  <Form.Field
                    className="rbx-dense-form"
                    id="quantity_per_store"
                    label="Quantity per Store"
                    type="number"
                    value={
                      selectedIncludeMscRow === MSC_ROW.SPECIFIC_STORES
                        ? signDistributionFormik.values.quantity_per_store || ''
                        : ''
                    }
                    size="dense"
                    disabled={
                      selectedIncludeMscRow !== MSC_ROW.SPECIFIC_STORES ||
                      isDisabled('quantity_per_store')
                    }
                    onChange={(e: any) => {
                      handleChange(e.target.id, parseInt(e.target.value))
                      if (isBulkEdit) {
                        addBulkEditField(
                          'distribution.quantity_per_store',
                          parseInt(e.target.value),
                        )
                      }
                    }}
                    error={
                      selectedIncludeMscRow === MSC_ROW.SPECIFIC_STORES &&
                      errors.quantity_per_store !== undefined &&
                      !onPogSign
                    }
                    errorText={errors.quantity_per_store}
                  />
                </Grid.Item>
              )}
              {!onPogSign && (
                <Grid.Item
                  xs={6}
                  className="hc-pt-none hc-pr-expanded"
                  style={{ marginTop: '-5px' }}
                >
                  <Form.Field
                    className="rbx-dense-form"
                    id="quantity_per_store"
                    label="Quantity per Store"
                    type="number"
                    value={
                      selectedIncludeMscRow === MSC_ROW.ALL_STORES
                        ? signDistributionFormik.values.quantity_per_store || ''
                        : ''
                    }
                    size="dense"
                    disabled={
                      selectedIncludeMscRow !== MSC_ROW.ALL_STORES ||
                      isDisabled('quantity_per_store')
                    }
                    onChange={(e: any) => {
                      handleChange(e.target.id, parseInt(e.target.value))
                      if (isBulkEdit) {
                        addBulkEditField(
                          'distribution.quantity_per_store',
                          parseInt(e.target.value),
                        )
                      }
                    }}
                    error={
                      selectedIncludeMscRow === MSC_ROW.ALL_STORES &&
                      errors.quantity_per_store !== undefined &&
                      !onPogSign
                    }
                    errorText={errors.quantity_per_store}
                  />
                </Grid.Item>
              )}
            </Grid.Container>
          </Grid.Item>
          {department_location_groups.map((group: any, index: number) => (
            <DepartmentGroupRow
              departmentGroup={group}
              category="include"
              index={index}
              active={
                selectedIncludeMscRow === MSC_ROW.DEPARTMENT &&
                !isDisabled('selected_msc')
              }
              key={index}
              handleChange={handleChange}
              errors={errors}
              isBulkEdit
            />
          ))}
          {isLoading ? (
            <Grid.Item style={{ position: 'absolute', maxHeight: '72px' }}>
              <Spinner />
            </Grid.Item>
          ) : null}
        </Grid.Container>
      </Grid.Item>
      <Grid.Item xs={12}>
        <Divider className="hc-pv-none" />
      </Grid.Item>
    </Fragment>
  )
}

export default IncludeLocations
