import React from 'react'
import { Layout } from '@enterprise-ui/canvas-ui-react'
import PageChangesWrapper from '../App/components/PageChangesWrapper'
import Dashboard from './components/Dashboard'
import { AgGridDashboardProvider } from './context/agGridDashboardContext'
import { DashboardProvider } from './context/dashboardContext'
import { BlueprintDashboardSSRMServerProvider } from 'components/DashboardV2/context/blueprintDashboardSSRMServerContext'
import { BpMilestoneDashboardSSRMServerProvider } from 'components/DashboardV2/context/bpMilestoneDashboardSSRMServerContext'
import { ProjectDashboardSSRMServerProvider } from 'components/DashboardV2/context/projectDashboardSSRMServerContext'
import { ProjectMilestoneDashboardSSRMServerProvider } from 'components/DashboardV2/context/projectMilestoneDashboardSSRMServerContext'

const DashboardContainer = (props: any) => (
  <PageChangesWrapper>
    <DashboardProvider>
      <BlueprintDashboardSSRMServerProvider>
        <BpMilestoneDashboardSSRMServerProvider>
          <ProjectDashboardSSRMServerProvider>
            <ProjectMilestoneDashboardSSRMServerProvider>
              <AgGridDashboardProvider>
                <Layout.Body className="hc-pa-expanded" includeRail>
                  <Dashboard {...props} />
                </Layout.Body>
              </AgGridDashboardProvider>
            </ProjectMilestoneDashboardSSRMServerProvider>
          </ProjectDashboardSSRMServerProvider>
        </BpMilestoneDashboardSSRMServerProvider>
      </BlueprintDashboardSSRMServerProvider>
    </DashboardProvider>
  </PageChangesWrapper>
)

export default DashboardContainer
