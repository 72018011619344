import { useFormik } from 'formik'
import { SIGN_DETAILS_SECTION } from '../constants/signDetailsConstants'
import MessagingRequest from '../../../models/signs/MessagingRequest.model'
import MessagingResponse from '../../../models/signs/MessagingResponse.model'
import SignResponse from '../../../models/signs/SignResponse.model'

export const useSignMessagingForm = (
  currentSign: SignResponse,
  messagingResponse: MessagingResponse,
  updateSignSection: (
    sectionModel: any,
    sectionSlug: SIGN_DETAILS_SECTION,
    sign_id: string,
  ) => void,
) => {
  return useFormik({
    enableReinitialize: true,
    initialValues: messagingResponse,
    onSubmit(values) {
      updateSignSection(
        new MessagingRequest({ ...values, updated_by: currentSign.updated_by }),
        SIGN_DETAILS_SECTION.MESSAGING,
        currentSign.sign_id,
      )
    },
  })
}
