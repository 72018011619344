import Axios from 'axios'
import appConfig from '../../../config/appConfig'
import { ADMIN_SERVICE_API_DOMAIN_URL } from '../constants/appConstants'
import { BusinessPartner } from '../../../models/app/BusinessPartner.model'
import { DropdownOption } from '../../../models/app/DropdownOption.model'

export const fetchVendorList = async (query: string) => {
  const config = await appConfig()
  const res: any = await Axios.get(
    `${
      config.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
    }/business_partners/search?query=${query}`,
  )
  return res.data.map((vendor: any) => new BusinessPartner(vendor))
}

export const getVendorOptions = async (query: string, vendorName: string) => {
  if (query === '') {
    return [
      {
        value: '',
        label: 'Begin typing to search Vendor List. Min 3 characters required',
      },
    ]
  } else if (query === vendorName) {
    return [
      {
        value: vendorName,
        label: vendorName,
      },
    ]
  } else {
    const vendorList = await fetchVendorList(query)
    return vendorList.map(
      (vendor: BusinessPartner) =>
        new DropdownOption({
          id: vendor.id,
          value: vendor.name,
          label: vendor.name,
        }),
    )
  }
}
