import { useFormik } from 'formik'
import { set } from 'lodash'
import { UserPermissions } from '../../../models/app/UserPermissions.model'
import DistributionRequest from '../../../models/signs/DistributionRequest.model'
import DistributionResponse from '../../../models/signs/DistributionResponse.model'
import SignResponse from '../../../models/signs/SignResponse.model'
import {
  MSC_ROW,
  SIGN_DETAILS_SECTION,
} from '../constants/signDetailsConstants'

export const useSignDistributionForm = (
  currentSign: SignResponse,
  userPermissions: UserPermissions,
  userCompany: string,
  updateSignSection: (
    req: DistributionRequest,
    slug: SIGN_DETAILS_SECTION,
    sign_id: string,
  ) => void,
  selectedIncludeMscRow: MSC_ROW,
) => {
  const validate = (values: any) => {
    const errors: any = {}

    if (values.archway_quantity === '') {
      if (currentSign.distribution.archway_quantity !== undefined) {
        errors.archway_quantity = 'Must be a whole number'
      }
    }

    if (values.max_quantity_per_kit === '0') {
      console.log('here')
      errors.max_quantity_per_kit = 'Must be greater than zero'
    }

    if (
      (selectedIncludeMscRow === MSC_ROW.SPECIFIC_STORES ||
        selectedIncludeMscRow === MSC_ROW.ALL_STORES) &&
      !currentSign.on_pog_sign
    ) {
      if (
        values.quantity_per_store !== undefined &&
        values.quantity_per_store <= 0
      ) {
        errors.quantity_per_store = 'Must be greater than 0'
      }
      if (
        values.quantity_per_store !== undefined &&
        !Number.isInteger(values.quantity_per_store)
      ) {
        errors.quantity_per_store = 'Must be a whole number'
      }
    }

    if (
      selectedIncludeMscRow === MSC_ROW.DEPARTMENT &&
      !currentSign.on_pog_sign
    ) {
      values.location_filter_info.include_location_info.department_location_groups.forEach(
        (group: any, index: any) => {
          if (group.quantity <= 0) {
            set(
              errors,
              `location_filter_info.include_location_info.department_location_groups[${index}].quantity`,
              'Quantity required',
            )
          }
          if (
            group.quantity !== undefined &&
            !Number.isInteger(group.quantity)
          ) {
            set(
              errors,
              `location_filter_info.include_location_info.department_location_groups[${index}].quantity`,
              'Must be a whole number',
            )
          }
        },
      )
    }
    return errors
  }

  return useFormik({
    enableReinitialize: true,
    initialValues: new DistributionResponse({
      ...currentSign.distribution,
      kitting_vendor: userPermissions.isKitVendor
        ? userCompany
        : currentSign.distribution.kitting_vendor,
    }),
    validate,
    onSubmit(values) {
      updateSignSection(
        new DistributionRequest({
          ...values,
          updated_by: currentSign.updated_by,
        }),
        SIGN_DETAILS_SECTION.DISTRIBUTION,
        currentSign.sign_id,
      )
    },
  })
}
