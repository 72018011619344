import React, { forwardRef, useImperativeHandle, useState } from 'react'
import SignResponse from '../../../../../../../models/signs/SignResponse.model'
import { Grid } from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { DropdownOption } from '../../../../../../../models/app/DropdownOption.model'
import { useAppContext } from 'components/App/context/appContext'

export interface Props {
  value: any[]
  data: SignResponse
}

const PogCategoriesEditor = forwardRef(({ value }: Props, ref) => {
  const [editValue, setEditValue] = useState(value ? value : [])
  const { pogCategoryOptions } = useAppContext()!

  const handleChange = (id: string, value: any) => {
    setEditValue(value.map((option: DropdownOption<any>) => option.value))
  }

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return editValue
      },
      isPopup() {
        return true
      },
    }
  })

  return (
    <div className="hc-pa-dense">
      <Grid.Container>
        <Grid.Item xs={12}>
          <Autocomplete
            label="POG Categories"
            multiselect
            value={editValue.map(
              (cat: any) =>
                new DropdownOption<any>({
                  id: cat.pog_category_id,
                  value: cat,
                  label: cat.pog_category_name,
                }),
            )}
            options={pogCategoryOptions.map(
              (opt: DropdownOption<any>) =>
                new DropdownOption<any>({
                  id: opt.id,
                  value: {
                    pog_category_id: opt.value,
                    pog_category_name: opt.label,
                  },
                  label: opt.label,
                }),
            )}
            onUpdate={(id: string, value: any) => {
              if (value && value.length > 0) handleChange(id, value)
            }}
          />
        </Grid.Item>
      </Grid.Container>
    </div>
  )
})

export default PogCategoriesEditor
