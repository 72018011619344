import React from 'react'
import PageChangesWrapper from '../../../App/components/PageChangesWrapper'
import ProjectSignListTab from './components/ProjectSignListTab'
import { AgGridSignListProvider } from './context/agGridSignListContext'
import { ProjectSignListProvider } from './context/projectSignListContext'

const ProjectSignListContainer = (props: any) => (
  <PageChangesWrapper>
    <ProjectSignListProvider>
      <AgGridSignListProvider>
        <ProjectSignListTab {...props} />
      </AgGridSignListProvider>
    </ProjectSignListProvider>
  </PageChangesWrapper>
)

export default ProjectSignListContainer
