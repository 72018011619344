import React from 'react'
import PageChangesWrapper from '../../../App/components/PageChangesWrapper'
import BlueprintDetailsTab from './components/BlueprintDetailsTab'
import { BlueprintDetailsTabProvider } from './context/blueprintDetailsTabContext'

const BlueprintDetailTab = (props: any) => (
  <PageChangesWrapper>
    <BlueprintDetailsTabProvider>
      <BlueprintDetailsTab {...props} />
    </BlueprintDetailsTabProvider>
  </PageChangesWrapper>
)

export default BlueprintDetailTab
