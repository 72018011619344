import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { Form, Grid } from '@enterprise-ui/canvas-ui-react'
import { Column } from 'ag-grid-community'
import { get } from 'lodash'
import { useProjectSignListContext } from '../../../context/projectSignListContext'
import { ISM_STRATEGIES } from 'components/SignDetails/constants/signDetailsConstants'
import { SIGN_SIZE_FACETS } from 'components/SignDetails/constants/signMessagingFacets'
import SignResponse from '../../../../../../../models/signs/SignResponse.model'

export interface Props {
  data: SignResponse
  value: any
  column: Column
}

const SelectCellEditor = forwardRef(({ data, value, column }: Props, ref) => {
  const { signFacets } = useProjectSignListContext()!
  const [editValue, setEditValue] = useState(value)
  const refInput = useRef<HTMLInputElement>(null)

  const handleOptions = () => {
    switch (column.getColId()) {
      case 'unit_of_measure':
        return [
          { value: 'EA', label: 'EA' },
          { value: 'ST', label: 'ST' },
        ]
      case 'printing.planned_sides':
        return [
          { value: 1, label: '1' },
          { value: 2, label: '2' },
        ]
      case 'printing.number_of_colors':
        return [
          { value: 1, label: '1' },
          { value: 2, label: '2' },
          { value: 3, label: '3' },
          { value: 4, label: '4' },
        ]
      case 'ism_strategy':
        return ISM_STRATEGIES
      case 'messaging.sign_size':
        return Object.values(SIGN_SIZE_FACETS)
          .filter((facet: any) => facet.validSignTypes.includes(data.sign_type))
          .map((facet: any) => ({
            value: facet.value,
            label: facet.label,
          }))
      case 'sign_types': {
        const excludedTypes = [
          'Operational',
          'Shipper/Bin',
          'Tag',
          'Endcap Header',
          'Inline Header',
          'Fin',
        ]
        const filteredSignTypes = get(signFacets, 'sign_types', []).filter(
          (type) => !excludedTypes.includes(type.label),
        )
        return filteredSignTypes
      }
      default:
        return get(signFacets, column.getColId())
    }
  }

  useEffect(() => {
    setTimeout(() => refInput?.current?.focus())
  }, [])

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return editValue
      },
    }
  })

  return (
    <div className="hc-pa-dense">
      <Grid.Container className="sem_StandardForm error_messaging_enabled">
        <Grid.Item xs={12}>
          <Form.Field
            id="select_cell_editor"
            type="select"
            label={column.getColDef().headerName}
            ref={refInput}
            value={editValue}
            options={handleOptions()}
            onUpdate={(id: string, value: string) => setEditValue(value)}
            size="dense"
          />
        </Grid.Item>
      </Grid.Container>
    </div>
  )
})

export default SelectCellEditor
