import React from 'react'
import { Grid, Popover, Table, Avatar } from '@enterprise-ui/canvas-ui-react'
import SignResponse from '../../../../../../../models/signs/SignResponse.model'
import { DepartmentLocationGroup } from '../../../../../../../models/signs/DistributionResponse.model'
import EnterpriseIcon, { InfoIcon } from '@enterprise-ui/icons'

export interface Props {
  data: SignResponse
}

const QuantityCell = ({ data }: Props) => {
  const content = data.distribution?.location_filter_info?.include_location_info
    ?.department_location_groups?.length > 0 && (
    <Grid.Container>
      <Grid.Item xs={12}>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Data xs={3}>Dept ID</Table.Data>
              <Table.Data xs={3}>Group ID</Table.Data>
              <Table.Data xs={3}>Subgroup IDs</Table.Data>
              <Table.Data xs={3}>Quantity</Table.Data>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {data.distribution.location_filter_info.include_location_info.department_location_groups.map(
              (group: DepartmentLocationGroup) => {
                return (
                  <Table.Row>
                    <Table.Data xs={3}>{group.department_id}</Table.Data>
                    <Table.Data xs={3}>{group.group_id}</Table.Data>
                    <Table.Data xs={3}>
                      {group.sub_group_ids.join(',')}
                    </Table.Data>
                    <Table.Data xs={3}>{group.quantity}</Table.Data>
                  </Table.Row>
                )
              },
            )}
          </Table.Body>
        </Table>
      </Grid.Item>
    </Grid.Container>
  )

  if (
    data?.distribution.location_filter_info?.include_location_info
      ?.department_location_groups?.length > 0 &&
    data?.distribution?.location_filter_info?.include_location_info
      ?.department_location_groups[0]?.department_id !== ''
  ) {
    return (
      <Popover content={content} size="expanded" location="bottom-right">
        <Grid.Container>
          <Grid.Item xs={6}>
            <p>Dept MSC</p>
          </Grid.Item>
          <Grid.Item xs={6}>
            <Avatar aria-label="dept_msc" size="dense">
              <EnterpriseIcon icon={InfoIcon} size="sm" />
            </Avatar>
          </Grid.Item>
        </Grid.Container>
      </Popover>
    )
  } else if (!data?.on_pog_sign) {
    return <p>{data.distribution.quantity_per_store}</p>
  } else {
    return null
  }
}

export default QuantityCell
