import { KitReceiveDates } from './KitReceiveDates.model'
import { KitComments } from './KitComments.model'
import { KitSummary } from './KitSummary.model'
import { KitResponse } from './KitResponse.model'
import { KitScenario } from './KitScenario.model'

export class KitInfoResponse {
  id: string
  kit_summary?: KitSummary
  initial_receive_dates?: KitReceiveDates
  kits: KitResponse[]
  kits_finalized: boolean
  kit_scenario: KitScenario
  shipping_method?: string
  comments?: KitComments
  changes?: string[]
  created_by: string
  created_date: string
  updated_by: string
  updated_date: string

  constructor(props: any = {}) {
    this.id = props.id
    this.kit_summary = props.kit_summary
    this.initial_receive_dates = props.initial_receive_dates
    this.kits = props.kits
    this.kits_finalized = props.kits_finalized
    this.kit_scenario = props.kit_scenario
    this.shipping_method = props.shipping_method
    this.comments = props.comments
    this.changes = props.changes
    this.created_by = props.created_by
    this.created_date = props.created_date
    this.updated_by = props.updated_by
    this.updated_date = props.updated_date
  }
}
