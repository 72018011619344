import React, { useEffect, Fragment } from 'react'
import { Button, Grid, Divider, Popover } from '@enterprise-ui/canvas-ui-react'
import CreateAdminMilestoneForm from './CreateAdminMilestoneForm'
import { useMilestoneContext } from 'components/App/context/milestoneContext'
import { useAdminMilestoneContext } from '../../context/adminMilestoneContext'
import EnterpriseIcon, { PlusIcon } from '@enterprise-ui/icons'
import { MilestoneTemplate } from '../../../../../models/milestones/MilestoneTemplate.model'
import AdminMilestoneCard from './AdminMilestoneCard'

const AdminMilestoneListTab = () => {
  const { milestoneTemplates, getMilestoneTemplates } = useMilestoneContext()!
  const { closeMilestoneForm } = useAdminMilestoneContext()!

  useEffect(() => {
    getMilestoneTemplates()
  }, [getMilestoneTemplates])

  return (
    <Grid.Container className="sem-MilestonesTab">
      <Grid.Item xs={true}>
        <Grid.Container justify={'flex-start'}>
          <Grid.Item>
            <Popover
              type="clickOnly"
              location="bottom-left"
              size="expanded"
              className="sem-AddMilestonePopover"
              content={<CreateAdminMilestoneForm />}
              onRequestClose={closeMilestoneForm}
            >
              <Button
                type="ghost"
                fullWidth
                className="hc-mt-normal hc-ta-justify"
              >
                <EnterpriseIcon className="hc-mr-normal" icon={PlusIcon} />
                Add Milestone
              </Button>
            </Popover>
          </Grid.Item>

          <Grid.Item xs={12}>
            <Fragment>
              <Grid.Container className="hc-bg-grey06 rounded" spacing="none">
                <Grid.Item className="phase-GridItem" xs={3}>
                  <div className="hc-pa-dense phase-Container">
                    <p className="title-name hc-mb-none">
                      Strategy &amp; Planning
                    </p>
                    <Divider className="phase-Divider" width={3} />
                    {milestoneTemplates
                      .filter(
                        (milestone: MilestoneTemplate) =>
                          milestone.project_phase === 'Strategy & Planning',
                      )
                      .sort((a: any, b: any) => b['days_out'] - a['days_out'])
                      .map((filteredMilestone) => (
                        <AdminMilestoneCard
                          milestone={filteredMilestone}
                          key={filteredMilestone.milestone_id}
                        />
                      ))}
                  </div>
                </Grid.Item>

                <Grid.Item className="phase-GridItem" xs={3}>
                  <div className="hc-pa-dense phase-Container">
                    <p className="title-name hc-mb-none">
                      Creative Development
                    </p>
                    <Divider className="phase-Divider" width={3} />
                    {milestoneTemplates
                      .filter(
                        (milestone: MilestoneTemplate) =>
                          milestone.project_phase === 'Creative Development',
                      )
                      .sort((a: any, b: any) => b['days_out'] - a['days_out'])
                      .map((filteredMilestone) => (
                        <AdminMilestoneCard
                          milestone={filteredMilestone}
                          key={filteredMilestone.milestone_id}
                        />
                      ))}
                  </div>
                </Grid.Item>

                <Grid.Item className="phase-GridItem" xs={3}>
                  <div className="hc-pa-dense phase-Container">
                    <p className="title-name hc-mb-none">
                      Sign List Finalization
                    </p>
                    <Divider className="phase-Divider" width={3} />
                    {milestoneTemplates
                      .filter(
                        (milestone: MilestoneTemplate) =>
                          milestone.project_phase === 'Sign List Finalization',
                      )
                      .sort((a: any, b: any) => b['days_out'] - a['days_out'])
                      .map((filteredMilestone) => (
                        <AdminMilestoneCard
                          milestone={filteredMilestone}
                          key={filteredMilestone.milestone_id}
                        />
                      ))}
                  </div>
                </Grid.Item>

                <Grid.Item className="phase-GridItem" xs={3}>
                  <div className="hc-pa-dense phase-Container">
                    <p className="title-name hc-mb-none">
                      Production to Market
                    </p>
                    <Divider className="phase-Divider" width={3} />
                    {milestoneTemplates
                      .filter(
                        (milestone: MilestoneTemplate) =>
                          milestone.project_phase === 'Production to Market',
                      )
                      .sort((a: any, b: any) => b['days_out'] - a['days_out'])
                      .map((filteredMilestone) => (
                        <AdminMilestoneCard
                          milestone={filteredMilestone}
                          key={filteredMilestone.milestone_id}
                        />
                      ))}
                  </div>
                </Grid.Item>
              </Grid.Container>
            </Fragment>
          </Grid.Item>
        </Grid.Container>
      </Grid.Item>
    </Grid.Container>
  )
}

export default AdminMilestoneListTab
