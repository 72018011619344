export default class NotificationResponse {
  id: string
  type: string
  name: string
  description?: string
  header_text?: string
  long_text?: string
  footer_text?: string
  nav_link: string
  user?: string
  create_timestamp: string
  status: string
  project: NotificationProject
  entity_id: string
  entity_type: string
  author: string

  constructor(props: any = {}) {
    this.id = props.id
    this.type = props.type
    this.name = props.name
    this.description = props.description
    this.header_text = props.header_text
    this.long_text = props.long_text
    this.footer_text = props.footer_text
    this.nav_link = props.nav_link
    this.user = props.user
    this.create_timestamp = props.create_timestamp
    this.status = props.status
    this.project = props.project
    this.entity_id = props.entity_id
    this.entity_type = props.entity_type
    this.author = props.author
  }
}

export class NotificationProject {
  blueprint_id?: string
  blueprint_name?: string
  project_id?: string
  sap_project_id?: string
  project_name: string
  project_status: string

  constructor(props: any = {}) {
    this.blueprint_id = props.blueprint_id
    this.blueprint_name = props.blueprint_name
    this.project_id = props.project_id
    this.sap_project_id = props.sap_project_id
    this.project_name = props.project_name
    this.project_status = props.project_status
  }
}
