import React, { createContext, useContext, useEffect, useState } from 'react'
import {
  ColumnApi,
  EditableCallbackParams,
  FilterChangedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  SortChangedEvent,
  ValueGetterParams,
} from 'ag-grid-community'
import moment from 'moment'
import BlueprintNameLink from '../components/AgGridView/BlueprintNameLink'
import HealthChip from '../components/AgGridView/HealthChip'
import MilestoneOwnerAvatar from '../components/AgGridView/MilestoneOwnerAvatar'
import DueDateChip from '../components/AgGridView/DueDateChip'
import BudgetText from '../components/AgGridView/BudgetText'
import TableToggleComponent from '../../ProjectDetails/components/ProjectSignList/components/signListViews/components/TableToggleComponent'
import ProjectNameLink from '../components/AgGridView/ProjectNameLink'
import { useAppContext } from '../../App/context/appContext'
import { SortState } from '../../../models/agGrid/AgGridSortState.model'
import { TabName } from '../constants/dashboardConstants'
import CompleteToggle from '../components/AgGridView/CompleteToggle'
import CompleteToggleEditor from '../components/AgGridView/CompleteToggleEditor'
import {
  dateComparator,
  dateFilterParams,
  dateValueGetter,
} from '../helpers/dashboardHelper'
import ProjectMilestoneHealthChip from '../components/AgGridView/ProjectMilestoneHealthChip'
import ProjectMilestoneDueDateChip from '../components/AgGridView/ProjectMilestoneDueDateChip'
import { useUserContext } from '../../App/context/userContext'
import DepartmentsChips from '../components/AgGridView/DepartmentsChips'

type ContextType = {
  gridApi: GridApi | undefined
  setGridApi: Function
  gridColumnApi: ColumnApi | undefined
  setGridColumnApi: Function
  onGridReady: (event: GridReadyEvent) => void
  gridOptions: GridOptions
  onFirstDataRender: () => void
  onFilterChange: (event: FilterChangedEvent) => void
  onSortChanged: (event: SortChangedEvent) => void
  exportTab: (tabName: string) => void
  resetAllFilters: () => void
}

export const AgGridDashboardContext = createContext<ContextType | undefined>(
  undefined,
)

type Props = { children: React.ReactNode }

export const AgGridDashboardProviderComponent = ({ children }: Props) => {
  const {
    dashboardActiveTab,

    blueprintMilestoneSavedFilter,
    setBlueprintMilestoneSavedFilter,
    blueprintMilestoneSortState,
    setBlueprintMilestoneSortState,

    projectMilestoneSavedFilter,
    setProjectMilestoneSavedFilter,
    projectMilestoneSortState,
    setProjectMilestoneSortState,

    blueprintSummarySavedFilter,
    setBlueprintSummarySavedFilter,
    blueprintSummarySortState,
    setBlueprintSummarySortState,

    projectSummarySavedFilter,
    setProjectSummarySavedFilter,
    projectSummarySortState,
    setProjectSummarySortState,
  } = useAppContext()!

  const [gridApi, setGridApi] = useState<GridApi>()
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>()
  const { userPermissions } = useUserContext()!

  const onGridReady = (params: any) => {
    setGridApi(params.api)
    setGridColumnApi(params.columnApi)
  }

  useEffect(() => {
    gridApi?.setFilterModel(
      dashboardActiveTab === TabName.MY_BLUEPRINT_MILESTONES
        ? blueprintMilestoneSavedFilter
        : dashboardActiveTab === TabName.MY_PROJECT_MILESTONES
        ? projectMilestoneSavedFilter
        : dashboardActiveTab === TabName.BLUEPRINT_SUMMARY
        ? blueprintSummarySavedFilter
        : dashboardActiveTab === TabName.PROJECT_SUMMARY
        ? projectSummarySavedFilter
        : {},
    )
    // we only want this to be used when the tab is changed
    // eslint-disable-next-line
  }, [dashboardActiveTab])

  useEffect(() => {
    gridColumnApi?.applyColumnState(
      dashboardActiveTab === TabName.MY_BLUEPRINT_MILESTONES
        ? { state: blueprintMilestoneSortState, defaultState: { sort: null } }
        : dashboardActiveTab === TabName.MY_PROJECT_MILESTONES
        ? projectMilestoneSortState
          ? { state: projectMilestoneSortState, defaultState: { sort: null } }
          : {
              state: [
                {
                  colId: 'due_date',
                  sort: 'asc',
                  sortIndex: 0,
                },
              ],
              defaultState: { sort: null },
            }
        : dashboardActiveTab === TabName.BLUEPRINT_SUMMARY
        ? { state: blueprintSummarySortState, defaultState: { sort: null } }
        : dashboardActiveTab === TabName.PROJECT_SUMMARY
        ? projectSummarySortState
          ? { state: projectSummarySortState, defaultState: { sort: null } }
          : {
              state: [
                {
                  colId: 'set_date',
                  sort: 'asc',
                  sortIndex: 0,
                },
              ],
              defaultState: { sort: null },
            }
        : { defaultState: { sort: null } },
    )
    // we only want this to be used when the tab is changed
    // eslint-disable-next-line
  }, [dashboardActiveTab])

  const onFirstDataRender = () => {
    switch (dashboardActiveTab) {
      case TabName.MY_BLUEPRINT_MILESTONES:
        if (blueprintMilestoneSavedFilter) {
          gridApi?.setFilterModel(blueprintMilestoneSavedFilter)
        }
        if (blueprintMilestoneSortState) {
          gridColumnApi?.applyColumnState({
            state: blueprintMilestoneSortState,
            defaultState: { sort: null },
          })
        }
        break
      case TabName.MY_PROJECT_MILESTONES:
        if (projectMilestoneSavedFilter) {
          gridApi?.setFilterModel(projectMilestoneSavedFilter)
        }
        gridColumnApi?.applyColumnState(
          projectMilestoneSortState
            ? {
                state: projectMilestoneSortState,
                defaultState: { sort: null },
              }
            : {
                state: [
                  {
                    colId: 'due_date',
                    sort: 'asc',
                    sortIndex: 0,
                  },
                ],
                defaultState: { sort: null },
              },
        )
        break
      case TabName.BLUEPRINT_SUMMARY:
        if (blueprintSummarySavedFilter) {
          gridApi?.setFilterModel(blueprintSummarySavedFilter)
        }
        if (blueprintSummarySortState) {
          gridColumnApi?.applyColumnState({
            state: blueprintSummarySortState,
            defaultState: { sort: null },
          })
        }
        break
      case TabName.PROJECT_SUMMARY:
        if (projectSummarySavedFilter) {
          gridApi?.setFilterModel(projectSummarySavedFilter)
        }
        gridColumnApi?.applyColumnState(
          projectSummarySortState
            ? {
                state: projectSummarySortState,
                defaultState: { sort: null },
              }
            : {
                state: [
                  {
                    colId: 'set_date',
                    sort: 'asc',
                    sortIndex: 0,
                  },
                ],
                defaultState: { sort: null },
              },
        )
        break
      default:
        break
    }
  }

  const onFilterChange = (event: FilterChangedEvent) => {
    switch (dashboardActiveTab) {
      case TabName.MY_BLUEPRINT_MILESTONES:
        setBlueprintMilestoneSavedFilter(event.api.getFilterModel())
        break
      case TabName.MY_PROJECT_MILESTONES:
        setProjectMilestoneSavedFilter(event.api.getFilterModel())
        break
      case TabName.BLUEPRINT_SUMMARY:
        setBlueprintSummarySavedFilter(event.api.getFilterModel())
        break
      case TabName.PROJECT_SUMMARY:
        setProjectSummarySavedFilter(event.api.getFilterModel())
        break
      default:
        break
    }
  }

  const onSortChanged = (event: SortChangedEvent) => {
    switch (dashboardActiveTab) {
      case TabName.MY_BLUEPRINT_MILESTONES:
        setBlueprintMilestoneSortState(
          event.columnApi
            .getColumnState()
            .filter((s: Record<string, any>) => s.sort != null)
            .map((s: Record<string, any>) => new SortState(s)),
        )
        break
      case TabName.MY_PROJECT_MILESTONES:
        setProjectMilestoneSortState(
          event.columnApi
            .getColumnState()
            .filter((s: Record<string, any>) => s.sort != null)
            .map((s: Record<string, any>) => new SortState(s)),
        )
        break
      case TabName.BLUEPRINT_SUMMARY:
        setBlueprintSummarySortState(
          event.columnApi
            .getColumnState()
            .filter((s: Record<string, any>) => s.sort != null)
            .map((s: Record<string, any>) => new SortState(s)),
        )
        break
      case TabName.PROJECT_SUMMARY:
        setProjectSummarySortState(
          event.columnApi
            .getColumnState()
            .filter((s) => s.sort != null)
            .map((s) => new SortState(s)),
        )
        break
      default:
        break
    }
  }

  const exportTab = (tabName: string) => {
    gridApi?.exportDataAsCsv({
      fileName: `${tabName}_${moment(new Date()).format(
        'YYYY-MM-DD HH:mm:ss',
      )}.csv`,
    })
  }

  const resetAllFilters = () => {
    gridApi?.setFilterModel({})
    gridColumnApi?.applyColumnState(
      dashboardActiveTab === TabName.MY_PROJECT_MILESTONES ||
        dashboardActiveTab === TabName.PROJECT_SUMMARY
        ? {
            state: [
              {
                colId: 'set_date',
                sort: 'asc',
                sortIndex: 0,
              },
            ],
            defaultState: { sort: null },
          }
        : { defaultState: { sort: null } },
    )
  }

  const gridOptions = {
    animateRows: true,
    defaultColDef: {
      resizable: true,
      sortable: true,
      filter: true,
      width: 148,
      menuTabs: ['filterMenuTab'],
      filterParams: {
        buttons: ['clear', 'reset'],
        newRowsAction: 'keep',
      },
    },
    columnTypes: {
      fixed: {
        lockPinned: true,
        resizable: false,
        suppressSizeToFit: true,
        pinned: 'left',
      },
      blueprintNameLink: {
        cellRenderer: 'BlueprintNameLink',
      },
      projectNameLink: {
        cellRenderer: 'ProjectNameLink',
        cellClass: 'actions-button-cell',
      },
      setDateChip: {
        valueGetter: (params: ValueGetterParams) => dateValueGetter(params),
        unSortIcon: dashboardActiveTab === TabName.PROJECT_SUMMARY,
        filterParams: dateFilterParams,
        comparator: dateComparator,
      },
      healthChip: {
        cellRenderer: 'HealthChip',
      },
      projectMilestoneHealthChip: {
        cellRenderer: 'ProjectMilestoneHealthChip',
        cellClass: 'actions-button-cell',
      },
      completeToggle: {
        cellRenderer: 'CompleteToggle',
      },
      completeToggleEditor: {
        editable: (params: EditableCallbackParams) => {
          if (dashboardActiveTab === TabName.MY_BLUEPRINT_MILESTONES) {
            return (
              !params.data.current_milestone.auto_check ||
              userPermissions.isBlueprintAdmin
            )
          } else if (dashboardActiveTab === TabName.MY_PROJECT_MILESTONES) {
            return (
              !params.data.my_milestone.auto_check ||
              userPermissions.isProjectAdmin
            )
          } else {
            return false
          }
        },
        cellEditor: 'CompleteToggleEditor',
        cellEditorPopup: true,
      },
      milestoneOwnerAvatar: {
        cellRenderer: 'MilestoneOwnerAvatar',
        cellClass: 'actions-button-cell',
      },
      dueDateChip: {
        cellRenderer: 'DueDateChip',
        valueGetter: (params: ValueGetterParams) => dateValueGetter(params),
        filterParams: dateFilterParams,
        comparator: dateComparator,
      },
      departmentsChips: {
        cellRenderer: 'DepartmentsChips',
        cellClass: 'actions-button-cell',
      },
      projectMilestoneDueDateChip: {
        cellRenderer: 'ProjectMilestoneDueDateChip',
        cellClass: 'actions-button-cell',
        sortIcon: dashboardActiveTab === TabName.MY_PROJECT_MILESTONES,
        valueGetter: (params: ValueGetterParams) => dateValueGetter(params),
        filterParams: dateFilterParams,
        comparator: dateComparator,
      },
      // budgetText: {
      //   cellRenderer: 'BudgetText',
      // },
      tierOne: {
        cellRenderer: 'TableToggleComponent',
        cellClass: 'not-editable',
      },
    },
    frameworkComponents: {
      BlueprintNameLink,
      ProjectNameLink,
      HealthChip,
      ProjectMilestoneHealthChip,
      MilestoneOwnerAvatar,
      DepartmentsChips,
      DueDateChip,
      ProjectMilestoneDueDateChip,
      BudgetText,
      TableToggleComponent,
      CompleteToggle,
      CompleteToggleEditor,
    },
  }

  return (
    <AgGridDashboardContext.Provider
      value={{
        gridApi,
        setGridApi,
        gridColumnApi,
        setGridColumnApi,
        onGridReady,
        gridOptions,
        onFirstDataRender,
        onFilterChange,
        onSortChanged,
        exportTab,
        resetAllFilters,
      }}
    >
      {children}
    </AgGridDashboardContext.Provider>
  )
}

export const useAgGridDashboardContext = () =>
  useContext(AgGridDashboardContext)

export const AgGridDashboardProvider = AgGridDashboardProviderComponent
