import Axios from 'axios'
import React, { createContext, useState, useContext, useCallback } from 'react'
import { connect } from 'react-redux'
import { CampaignDetail } from '../../../../models/campaigns/CampaignDetail.model'
import { CampaignTypeResponse } from '../../../../models/campaigns/CampaignTypeResponse.model'
import { RosterElement } from '../../../../models/roster/RosterElement.model'
import {
  ADMIN_SERVICE_API_DOMAIN_URL,
  TOASTER_DEFAULTS,
} from '../../../App/constants/appConstants'
import { useEnv } from '@praxis/component-runtime-env'
import { useAppContext } from '../../../App/context/appContext'
import XLSX from 'xlsx'
import { useUserContext } from '../../../App/context/userContext'
import { DialogProps } from '../../../../models/app/DialogProps.model'
import { mapKeys } from 'lodash'
import { useToaster } from '@enterprise-ui/canvas-ui-react'
import moment from 'moment'

type ContextType = {
  allCampaignTypesList: CampaignTypeResponse[]
  setAllCampaignTypesList: Function
  getAllCampaignTypesList: () => void

  pyramidList: any[]
  setPyramidList: Function
  getPyramidList: () => void

  rosterTemplateList: RosterElement[]
  setRosterTemplateList: Function
  getRosterTemplateList: () => void

  handleFileUpload: Function
  campaignList: CampaignDetail[]
  setCampaignList: Function
  getCampaignList: (year: number | null) => void

  campaignYears: Number[]
  setCampaignYears: Function
  getCampaignYears: () => void

  selectedCampaignYear: string
  setSelectedCampaignYear: Function

  saveCampaign: (campiagn: CampaignDetail) => void
  updateCampaign: (campaignToUpdate: CampaignDetail) => void
  removeCampaign: (campaignId: string) => void
  createNewCampaign: (campaign: CampaignDetail) => void
  archiveCampaign: (campaignToArchive: CampaignDetail) => void
  restoreArchivedCampaign: (campaignToRestore: CampaignDetail) => void

  isCreateNewCampaign: boolean
  setIsCreateNewCampaign: Function
  isUploadFile: boolean
  setIsUploadFile: Function

  isOpen: boolean
  setIsOpen: Function
  closeCampaignForm: Function
}

export const AdminUploadContext = createContext<ContextType | undefined>(
  undefined,
)

type Props = {
  children: React.ReactNode
}

export const AdminUploadProviderComponent = ({ children }: Props) => {
  const { setFullPageLoadingMessage, setDialogProps } = useAppContext()!
  const { userName } = useUserContext()!
  const env = useEnv()
  const makeToast = useToaster()
  const [allCampaignTypesList, setAllCampaignTypesList] = useState<
    CampaignTypeResponse[]
  >([])
  const [pyramidList, setPyramidList] = useState<any[]>([])
  const [rosterTemplateList, setRosterTemplateList] = useState<RosterElement[]>(
    [],
  )
  const [campaignList, setCampaignList] = useState<CampaignDetail[]>([])
  const [campaignYears, setCampaignYears] = useState<Number[]>([])
  const [selectedCampaignYear, setSelectedCampaignYear] = useState('')
  const [isCreateNewCampaign, setIsCreateNewCampaign] = useState(false)
  const [isUploadFile, setIsUploadFile] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const getAllCampaignTypesList = useCallback(async () => {
    setFullPageLoadingMessage('Loading Campaigns...')
    try {
      const response = await Axios.get(
        `${
          env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
        }/campaign_types/search`,
      )
      setAllCampaignTypesList(
        response.data.map(
          (campaign: any) => new CampaignTypeResponse(campaign),
        ),
      )
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Get All Campaigns',
        message: err.response.data.message,
      })
    }
    setFullPageLoadingMessage('')
  }, [makeToast, setFullPageLoadingMessage, env.apiDomainUrl])

  const getPyramidList = useCallback(async () => {
    try {
      const response = await Axios.get(
        `${
          env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
        }/merchandise_hierarchy/pyramids`,
      )
      setPyramidList(
        response.data.map((pyramid: any) => ({
          value: pyramid.group_id,
          label: pyramid.group_name,
        })),
      )
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Get Pyramids',
        message: err.response.data.message,
      })
    }
  }, [makeToast, env.apiDomainUrl])

  const getRosterTemplateList = useCallback(async () => {
    try {
      const response = await Axios.get(
        `${
          env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
        }/campaign_types/roster_templates`,
      )
      setRosterTemplateList(
        response.data.map(
          (rosterElement: any) => new RosterElement(rosterElement),
        ),
      )
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Get Roster Template',
        message: err.response.data.message,
      })
    }
  }, [makeToast, env.apiDomainUrl])

  const batchloadCampaignList = async (campaignList: CampaignDetail[]) => {
    try {
      await Axios.post(
        `${
          env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
        }/campaigns/batch_loads`,
        campaignList,
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'success',
        heading: 'Uploaded Campaigns',
        message: 'Successfully uploaded campaigns',
      })
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Upload Campaign',
        message: err.response.data.message,
      })
    }
  }

  const getCampaignList = useCallback(
    async (year: number | null) => {
      setFullPageLoadingMessage('Loading Campaigns...')
      try {
        if (year === -1) {
          year = null
        }
        const response = await Axios.get(
          `${
            env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
          }/campaigns?year=${year}`,
        )
        setCampaignList(
          response.data.map((campaign: any) => new CampaignDetail(campaign)),
        )
      } catch (err: any) {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'error',
          heading: 'Failed to Get Campaign List',
          message: err.response.data.message,
        })
      }
      setFullPageLoadingMessage('')
    },
    [makeToast, setFullPageLoadingMessage, env.apiDomainUrl],
  )

  const getCampaignYears = useCallback(async () => {
    try {
      const response = await Axios.get(
        `${env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL}/campaigns/years`,
      )
      setCampaignYears(response.data.map((year: any) => year))
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Get Campaign Years',
        message: err.response.data.message,
      })
    }
  }, [makeToast, env.apiDomainUrl])

  const handleFileUpload = (file: any) => {
    const reader = new FileReader()
    reader.onload = (e: any) => {
      const data = e.target.result

      const workbook = XLSX.read(data, {
        type: 'binary',
        cellDates: true,
      })
      const sheetName = workbook.SheetNames[0]
      const worksheet = workbook.Sheets[sheetName]
      const excelJSON = XLSX.utils
        .sheet_to_json(worksheet)
        .map((row: any) =>
          mapKeys(row, (value, key) => key.trim().toLowerCase()),
        )
      const listOfCampaigns = excelJSON.map((campaign: any) => {
        return new CampaignDetail({
          campaign_id: campaign['campaign id'].toString().trim(),
          campaign_type: campaign['campaign type'].toString().trim(),
          year: campaign['campaign year'],
          updated_by: userName,
          fiscal_year_start_date: moment(
            campaign['fiscal year start date'],
          ).format('yyyy-MM-DD'),
          fiscal_year_end_date: moment(campaign['fiscal year end date']).format(
            'yyyy-MM-DD',
          ),
          campaign_plan_start_date: moment(
            campaign['campaign plan start date'],
          ).format('yyyy-MM-DD'),
          campaign_plan_end_date: moment(
            campaign['campaign plan end date'],
          ).format('yyyy-MM-DD'),
        })
      })
      const filteredList = listOfCampaigns.filter(
        (campaign: any) => campaign.campaign_id !== undefined,
      )
      batchloadCampaignList(filteredList)
    }
    reader.readAsBinaryString(file)
    setIsUploadFile(false)
  }

  const removeCampaign = async (campaignId: string) => {
    setFullPageLoadingMessage('Removing Campaign...')

    try {
      Axios.delete(
        `${
          env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
        }/campaigns/${campaignId}`,
      )
      setCampaignList((previousState: CampaignDetail[]) =>
        previousState.filter(
          (campaign: CampaignDetail) => campaign.id !== campaignId,
        ),
      )
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Remove Campaign',
        message: err.response.data.message,
      })
    }
    setDialogProps(new DialogProps())
    setFullPageLoadingMessage('')
  }

  const saveCampaign = async (campaign: CampaignDetail) => {
    try {
      await Axios.put(
        `${env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL}/campaigns/${
          campaign.id
        }`,
        campaign,
        { headers: { 'Content-Type': 'application/json' } },
      )
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Save Campaign',
        message: err.response.data.message,
      })
    }
  }

  const createNewCampaign = async (campaign: CampaignDetail) => {
    try {
      await Axios.post(
        `${env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL}/campaigns`,
        campaign,
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'success',
        heading: 'New Campaign Created',
        message: 'Successfully created new campaign',
      })
      setCampaignList((previousState: CampaignDetail[]) => [
        ...previousState,
        campaign,
      ])
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Create New Campaigns',
        message: err.response.data.message,
      })
    }
  }

  const updateCampaign = (campaignToUpdate: CampaignDetail) => {
    setCampaignList(
      campaignList.map((campaign: CampaignDetail) =>
        campaign.id === campaignToUpdate.id ? campaignToUpdate : campaign,
      ),
    )
  }

  const archiveCampaign = async (campaignToArchive: CampaignDetail) => {
    setFullPageLoadingMessage('Archiving Campaign...')

    try {
      await Axios.put(
        `${env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL}/campaigns/${
          campaignToArchive.id
        }`,
        {
          ...campaignToArchive,
          active: false,
        },
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      setCampaignList(
        campaignList.map((campaign: CampaignDetail) =>
          campaign.id === campaignToArchive.id
            ? new CampaignDetail({ ...campaignToArchive, acitve: false })
            : campaign,
        ),
      )
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Archive Campaign',
        message: err.response.data.message,
      })
    }
    setDialogProps(new DialogProps())
    setFullPageLoadingMessage('')
  }

  const restoreArchivedCampaign = async (campaignToRestore: CampaignDetail) => {
    setFullPageLoadingMessage('Restore Archived Campaign...')

    try {
      await Axios.put(
        `${env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL}/campaigns/${
          campaignToRestore.id
        }`,
        {
          ...campaignToRestore,
          active: true,
        },
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      setCampaignList(
        campaignList.map((campaign: CampaignDetail) =>
          campaign.id === campaignToRestore.id
            ? new CampaignDetail({ ...campaignToRestore, acitve: true })
            : campaign,
        ),
      )
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Archive Campaign',
        message: err.response.data.message,
      })
    }
    setDialogProps(new DialogProps())
    setFullPageLoadingMessage('')
  }

  const closeCampaignForm = () => {
    setIsOpen(false)
  }

  return (
    <AdminUploadContext.Provider
      value={{
        allCampaignTypesList,
        setAllCampaignTypesList,
        getAllCampaignTypesList,

        pyramidList,
        setPyramidList,
        getPyramidList,

        rosterTemplateList,
        setRosterTemplateList,
        getRosterTemplateList,

        handleFileUpload,
        campaignList,
        setCampaignList,
        getCampaignList,

        campaignYears,
        setCampaignYears,
        getCampaignYears,

        selectedCampaignYear,
        setSelectedCampaignYear,

        saveCampaign,
        updateCampaign,
        removeCampaign,
        createNewCampaign,
        archiveCampaign,
        restoreArchivedCampaign,

        isCreateNewCampaign,
        setIsCreateNewCampaign,
        isUploadFile,
        setIsUploadFile,

        isOpen,
        setIsOpen,
        closeCampaignForm,
      }}
    >
      {children}
    </AdminUploadContext.Provider>
  )
}

export const useAdminUploadContext = () => useContext(AdminUploadContext)

export const AdminUploadProvider = connect(
  null,
  null,
)(AdminUploadProviderComponent)
