import Axios from 'axios'
import React, { createContext, useState, useContext, useCallback } from 'react'
import { connect } from 'react-redux'
import {
  ADMIN_SERVICE_API_DOMAIN_URL,
  TOASTER_DEFAULTS,
} from '../constants/appConstants'
import { useEnv } from '@praxis/component-runtime-env'
import { MilestoneTemplate } from '../../../models/milestones/MilestoneTemplate.model'
import { ScheduleTemplate } from '../../../models/milestones/ScheduleTemplate'
import { TemplateResponse } from '../../../models/milestones/TemplateResponse.model'
import { useToaster } from '@enterprise-ui/canvas-ui-react'

type ContextType = {
  milestoneTemplates: MilestoneTemplate[]
  setMilestoneTemplates: Function
  getMilestoneTemplates: () => void

  allScheduleTemplates: ScheduleTemplate[]
  setAllScheduleTemplates: Function
  getAllScheduleTemplates: () => void
  isAllScheduleTemplatesLoading: boolean
  setIsAllScheduleTemplatesLoading: Function

  scheduleTypes: string[]
  setScheduleTypes: Function
  getScheduleTypes: () => void

  scheduleTemplatesByScheduleType: TemplateResponse[]
  setScheduleTemplatesByScheduleType: Function
  getScheduleTemplatesByScheduleType: (type: string) => void

  masterTemplate: ScheduleTemplate
  setMasterTemplateByScheduleType: Function
  getMasterTemplateByScheduleType: (scheduleType: string) => void

  scheduleTemplateByScheduleTemplateId: ScheduleTemplate
  setScheduleTemplateByScheduleTemplateId: Function
  getScheduleTemplateByScheduleTemplateId: (templateId: string) => void
  updateScheduleTemplateByScheduleTemplateId: (
    scheduleTemplate: ScheduleTemplate,
  ) => void

  milestoneFacets: any
  setMilestoneFacets: Function
  getMilestoneFacets: () => void

  modifiedMilestoneIds: Set<string>
  setModifiedMilestoneIds: Function
  addToModifiedMilestoneIds: (id: string) => void
  removeFromModifiedMilestoneIds: (ids: string[]) => void
  isDependency: (
    milestoneName: string,
    milestones: MilestoneTemplate[],
  ) => boolean
}

export const MilestoneContext = createContext<ContextType | undefined>(
  undefined,
)

type Props = {
  children: React.ReactNode
}

export const MilestoneProviderComponent = ({ children }: Props) => {
  const env = useEnv()
  const makeToast = useToaster()
  const [milestoneTemplates, setMilestoneTemplates] = useState<
    MilestoneTemplate[]
  >([])
  const [allScheduleTemplates, setAllScheduleTemplates] = useState<
    ScheduleTemplate[]
  >([])
  const [isAllScheduleTemplatesLoading, setIsAllScheduleTemplatesLoading] =
    useState(false)
  const [scheduleTypes, setScheduleTypes] = useState([])
  const [scheduleTemplatesByScheduleType, setScheduleTemplatesByScheduleType] =
    useState<TemplateResponse[]>([])
  const [masterTemplate, setMasterTemplateByScheduleType] = useState(
    new ScheduleTemplate(),
  )
  const [
    scheduleTemplateByScheduleTemplateId,
    setScheduleTemplateByScheduleTemplateId,
  ] = useState(new ScheduleTemplate())
  const [milestoneFacets, setMilestoneFacets] = useState({})
  const [modifiedMilestoneIds, setModifiedMilestoneIds] = useState<Set<string>>(
    new Set(),
  )

  const addToModifiedMilestoneIds = (id: string) =>
    !modifiedMilestoneIds.has(id) &&
    setModifiedMilestoneIds(
      (previousState: Set<string>) =>
        new Set([...Array.from(previousState), id]),
    )
  const removeFromModifiedMilestoneIds = (ids: string[]) =>
    setModifiedMilestoneIds(
      (previousState: Set<string>) =>
        new Set([
          ...Array.from(previousState).filter(
            (milestoneId) => !ids.includes(milestoneId),
          ),
        ]),
    )

  const getMilestoneTemplates = useCallback(async () => {
    let allMilestoneTemplates = []
    try {
      const res = await Axios.get(
        `${
          env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
        }/templates/milestone_templates`,
      )
      allMilestoneTemplates = res.data.map(
        (milestoneTemplate: any) => new MilestoneTemplate(milestoneTemplate),
      )
      setMilestoneTemplates(allMilestoneTemplates)
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Get Milestone Templates',
        message: err.response.data.message,
      })
    }
  }, [makeToast, env.apiDomainUrl])

  const getAllScheduleTemplates = useCallback(async () => {
    setIsAllScheduleTemplatesLoading(true)
    let scheduleTemplates = []
    try {
      const res = await Axios.get(
        `${
          env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
        }/templates/schedule_templates`,
      )
      scheduleTemplates = res.data.map(
        (scheduleTemplate: any) => new ScheduleTemplate(scheduleTemplate),
      )
      setAllScheduleTemplates(scheduleTemplates)
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Get All Schedule Templates',
        message: err.response.data.message,
      })
    }
    setIsAllScheduleTemplatesLoading(false)
  }, [makeToast, env.apiDomainUrl])

  const getScheduleTypes = useCallback(async () => {
    try {
      const res = await Axios.get(
        `${env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL}/schedules`,
      )
      setScheduleTypes(
        res.data.map((name: string) => ({
          value: name,
          label: name,
        })),
      )
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Get Schedule Types',
        message: err.response.data.message,
      })
    }
  }, [makeToast, env.apiDomainUrl])

  const getScheduleTemplatesByScheduleType = useCallback(
    async (scheduleType: string) => {
      try {
        const res = await Axios.get(
          `${
            env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
          }/schedules/${scheduleType}/templates`,
        )
        setScheduleTemplatesByScheduleType(
          res.data.map((template: any) => new TemplateResponse(template)),
        )
      } catch (err: any) {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'error',
          heading: 'Failed to Get Schedule Templates',
          message: err.response.data.message,
        })
      }
    },
    [makeToast, env.apiDomainUrl],
  )

  const getMasterTemplateByScheduleType = useCallback(
    async (scheduleType: string) => {
      try {
        // return type: ScheduleTemplate
        const res = await Axios.get(
          `${
            env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
          }/schedules/${scheduleType}/master_templates`,
        )
        setMasterTemplateByScheduleType(res.data)
      } catch (err: any) {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'error',
          heading: 'Failed to Get Master Template',
          message: err.response.data.message,
        })
      }
    },
    [makeToast, env.apiDomainUrl],
  )

  const getScheduleTemplateByScheduleTemplateId = useCallback(
    async (templateId: string) => {
      try {
        const res = await Axios.get(
          `${
            env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
          }/templates/schedule_templates/${templateId}`,
        )
        setScheduleTemplateByScheduleTemplateId(res.data)
      } catch (err: any) {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'error',
          heading: 'Failed to Get Schedule Template',
          message: err.response.data.message,
        })
      }
    },
    [makeToast, env.apiDomainUrl],
  )

  const updateScheduleTemplateByScheduleTemplateId = async (
    scheduleTemplate: ScheduleTemplate,
  ) => {
    try {
      await Axios.put(
        `${
          env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
        }/templates/schedule_templates/${scheduleTemplate.template_id}`,
        scheduleTemplate,
      )
      setScheduleTemplateByScheduleTemplateId(scheduleTemplate)
      setAllScheduleTemplates((previousState: ScheduleTemplate[]) =>
        previousState.map((template: ScheduleTemplate) =>
          template.template_id === scheduleTemplate.template_id
            ? new ScheduleTemplate(scheduleTemplate)
            : template,
        ),
      )
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'success',
        heading: 'Schedule Saved',
        message: 'Successfully saved the schedule',
      })
      setModifiedMilestoneIds(new Set())
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Update Schedule',
        message: err.response.data.message,
      })
    }
  }

  const getMilestoneFacets = useCallback(async () => {
    try {
      const res = await Axios.get(
        `${env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL}/facets/milestones`,
      )
      setMilestoneFacets(res.data)
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Get Milestone Facets',
        message: err.response.data.message,
      })
    }
  }, [makeToast, env.apiDomainUrl])

  const isDependency = (
    milestoneName: string,
    milestones: MilestoneTemplate[],
  ) => {
    return milestones.some(
      (milestone) => milestoneName === milestone.depends_on,
    )
  }

  return (
    <MilestoneContext.Provider
      value={{
        milestoneTemplates,
        setMilestoneTemplates,
        getMilestoneTemplates,

        allScheduleTemplates,
        setAllScheduleTemplates,
        getAllScheduleTemplates,
        isAllScheduleTemplatesLoading,
        setIsAllScheduleTemplatesLoading,

        scheduleTypes,
        setScheduleTypes,
        getScheduleTypes,

        scheduleTemplatesByScheduleType,
        setScheduleTemplatesByScheduleType,
        getScheduleTemplatesByScheduleType,

        masterTemplate,
        setMasterTemplateByScheduleType,
        getMasterTemplateByScheduleType,

        scheduleTemplateByScheduleTemplateId,
        setScheduleTemplateByScheduleTemplateId,
        getScheduleTemplateByScheduleTemplateId,
        updateScheduleTemplateByScheduleTemplateId,

        milestoneFacets,
        setMilestoneFacets,
        getMilestoneFacets,

        modifiedMilestoneIds,
        setModifiedMilestoneIds,
        addToModifiedMilestoneIds,
        removeFromModifiedMilestoneIds,
        isDependency,
      }}
    >
      {children}
    </MilestoneContext.Provider>
  )
}

export const useMilestoneContext = () => useContext(MilestoneContext)

export const MilestoneProvider = connect(null, null)(MilestoneProviderComponent)
