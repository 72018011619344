export class ProjectMilestoneCompleteRequest {
  project_id: string
  milestone_id: string
  completed: boolean
  updated_by: string
  update_status?: string
  error?: string

  constructor(props: any = {}) {
    this.project_id = props.project_id || ''
    this.milestone_id = props.milestone_id || ''
    this.completed = props.completed || false
    this.updated_by = props.updated_by || ''
    this.update_status = props.update_status
    this.error = props.error
  }
}
