import React from 'react'
import { Grid } from '@enterprise-ui/canvas-ui-react'
import MainSectionHeader from '../../App/components/MainSectionHeader'
import { useProjectSignListContext } from '../../ProjectDetails/components/ProjectSignList/context/projectSignListContext'
import { useAgGridSignImportContext } from '../context/agGridSignImportContext'
import ImportFilterCard from './SignImportFilterCard'
import ButtonRow from './SignImportButtonRow'
import SignImportTable from './SignLibraryImportTable'
import { SelectedSignsIds } from '../../../models/signLibrary'

const SignImportContainer = () => {
  const { setIsImportSignsFullScreen } = useProjectSignListContext()!
  const { gridApi, setSelectedSignIds } = useAgGridSignImportContext()!

  const handleClose = () => {
    gridApi?.deselectAll()
    setSelectedSignIds(new SelectedSignsIds())
    setIsImportSignsFullScreen(false)
  }

  return (
    <div className="hc-pa-normal">
      <MainSectionHeader
        sectionTitle="Sign Library Import"
        isModal={true}
        closeModal={handleClose}
      />
      <Grid.Container spacing="dense">
        <ImportFilterCard />
        <ButtonRow closeModal={handleClose} />
        <SignImportTable />
      </Grid.Container>
    </div>
  )
}

export default SignImportContainer
