import React, { Fragment } from 'react'
import { Anchor, Grid, List, Popover } from '@enterprise-ui/canvas-ui-react'
import { Link } from 'react-router-dom'
import EnterpriseIcon, {
  ProcessingIcon,
  VisibleShowIcon,
} from '@enterprise-ui/icons'
import SignResponse from '../../../../../../../models/signs/SignResponse.model'
import RubixChip from 'components/common/RubixChip'

export interface Props {
  data: SignResponse
  onDetailPage?: boolean
}

const FinalizeLevelCell = ({
  data = new SignResponse(),
  onDetailPage,
}: Props) => {
  const content = (
    <Grid.Container>
      <Grid.Item className="hc-pv-dense" xs={12}>
        <List>
          {data.workflow.reasons &&
            data.workflow.reasons.map((reason: string, index: number) => (
              <List.Item
                className="sem_ProductHighlights--ListItem"
                key={index}
                divider
              >
                {reason}
              </List.Item>
            ))}
          {!onDetailPage && (
            <List.Item className="sem_ProductHighlights--ListItem">
              <Anchor
                as={Link}
                to={`/projects/${data.project_id}/signs/${data.sign_id}`}
              >
                <EnterpriseIcon
                  className="hc-mr-dense"
                  icon={VisibleShowIcon}
                />
                View Sign Details
              </Anchor>
            </List.Item>
          )}
        </List>
      </Grid.Item>
    </Grid.Container>
  )

  if (data.workflow.reasons !== undefined && data.workflow.reasons.length > 0) {
    return (
      <Fragment>
        <Popover content={content} size="expanded" location="bottom-left">
          <RubixChip color={data.workflow.color}>
            {data.workflow.status}
          </RubixChip>
        </Popover>
        {data.distribution.location_update_status?.location_updated ===
          false && (
          <EnterpriseIcon icon={ProcessingIcon} pulse={true} size="inline" />
        )}
      </Fragment>
    )
  } else {
    return (
      <Fragment>
        <RubixChip color={data.workflow.color}>
          {data.workflow.status}
        </RubixChip>
        {data.distribution.location_update_status?.location_updated ===
          false && (
          <EnterpriseIcon icon={ProcessingIcon} pulse={true} size="inline" />
        )}
      </Fragment>
    )
  }
}

export default FinalizeLevelCell
