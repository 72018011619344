import React, { useEffect, Fragment } from 'react'
import { Card, Grid } from '@enterprise-ui/canvas-ui-react'
import MainSectionHeader from '../../../App/components/MainSectionHeader'
import AdminUploadFileForm from './AdminUploadFileForm'
import { useAdminUploadContext } from '../context/adminUploadContext'
import AdminCampaignTable from './AdminCampaignTable'

const AdminUpload = () => {
  const { getPyramidList, getRosterTemplateList, isUploadFile } =
    useAdminUploadContext()!

  useEffect(() => {
    getPyramidList()
  }, [getPyramidList])

  useEffect(() => {
    getRosterTemplateList()
  }, [getRosterTemplateList])

  return (
    <Fragment>
      <MainSectionHeader sectionTitle="Campaign Upload" />
      <Card className="hc-pa-normal">
        <Grid.Container>
          <Grid.Item xs={12}>
            {isUploadFile ? <AdminUploadFileForm /> : <AdminCampaignTable />}
          </Grid.Item>
        </Grid.Container>
      </Card>
    </Fragment>
  )
}

export default AdminUpload
