import React, { useEffect, useState } from 'react'
import {
  ExpandableSection,
  Grid,
  Divider,
  Chip,
  Form,
  Heading,
  Anchor,
  GridItem,
  GridContainer,
  Input,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, {
  CheckCircleIcon,
  ErrorIcon,
} from '@enterprise-ui/icons'
import ReactTooltip from 'react-tooltip'
import moment from 'moment'
import 'moment-timezone'
import { ProjectFund } from '../../../../../models/projects/ProjectFundInfo.model'
import { useProjectDetailsContext } from '../../../context/projectDetailsContext'
import { useUserContext } from 'components/App/context/userContext'
import { Department } from '../../../../../models/merchandise/hierarchy/Department.model'
import { Division } from '../../../../../models/merchandise/hierarchy/Division.model'

export const ProjectFormCard = () => {
  const { currentProject, projectFundInfo, refreshProjectFunds } =
    useProjectDetailsContext()!

  const [detailsExpanded, setDetailsExpanded] = useState(true)
  const { userPermissions } = useUserContext()!

  useEffect(() => {
    if (currentProject.set_date) {
      const formattedDate = moment(currentProject.set_date).format('YYYY-MM-DD')
      refreshProjectFunds(formattedDate)
    }
  }, [currentProject.set_date, refreshProjectFunds])

  const tacticValue = (tactic: string) =>
    tactic === 'Concept'
      ? 'Concept (Deprecated)'
      : tactic === 'Shoot'
      ? 'Shoot (Deprecated)'
      : tactic === 'Print'
      ? 'Print (Deprecated)'
      : tactic === 'Style Guide'
      ? 'Style Guide (Deprecated)'
      : tactic

  return (
    <Grid.Container justify="space-between" align="center">
      <Grid.Item className="hc-pv-none hc-ph-normal" xs={12}>
        <ExpandableSection
          expanded={detailsExpanded}
          onExpand={() => {
            setDetailsExpanded(!detailsExpanded)
          }}
          padding="none"
          className="sem_SignProjectCard"
        >
          <Grid.Container justify="space-between" align="center">
            <Grid.Item xs={12}>
              <Heading className="hc-clr-grey01 hc-ml-normal" size={6}>
                Project Details
              </Heading>
            </Grid.Item>
          </Grid.Container>
          <ExpandableSection.Content>
            <Divider className="hc-pa-dense" />
            <div className="hc-pa-dense">
              <Grid.Container className="hc-mb-normal">
                <Grid.Container className="hc-ma-dense">
                  <Grid.Item xs={3} className="hc-pv-dense">
                    <Form.Field
                      type="date"
                      id="set_date"
                      data-testid="setDate"
                      label="Set Date:"
                      disabled
                      value={moment(currentProject.set_date).format(
                        'YYYY-MM-DD',
                      )}
                    />
                  </Grid.Item>
                  <Grid.Item xs={3} className="hc-pv-dense">
                    <Form.Field
                      id="campaign"
                      disabled
                      data-testid="campaign"
                      name="campaign"
                      label="Campaign Type"
                      value={currentProject.campaign.name}
                    />
                  </Grid.Item>
                  <Grid.Item xs={3} className="hc-pv-dense">
                    <Form.Field
                      disabled
                      id="campaign_id"
                      data-testid="campaignId"
                      name="campaign_id"
                      label="Campaign ID"
                      value={currentProject.campaign_id}
                    />
                  </Grid.Item>
                  <Grid.Item xs={3} className="hc-pv-dense">
                    <Form.Field
                      id="sap_project_id"
                      data-testid="sapProjectId"
                      disabled
                      name="sap_project_id"
                      label="PID"
                      value={currentProject.sap_project_id}
                    />
                  </Grid.Item>
                  <Grid.Item xs={3} className="hc-pv-dense">
                    <Form.Field
                      type="date"
                      disabled
                      id="in_store_date"
                      label="In-Store Date"
                      value={moment(currentProject.in_store_date).format(
                        'YYYY-MM-DD',
                      )}
                    />
                  </Grid.Item>
                  <Grid.Item xs={2} className="hc-pv-dense">
                    <Form.Field
                      disabled
                      id="project_type"
                      data-testid="projectType"
                      name="project_type"
                      label="Project Type"
                      value={currentProject.project_type}
                    />
                  </Grid.Item>
                  {userPermissions.isProjectEditor && (
                    <Grid.Item xs={2} className="hc-pv-dense">
                      <Form.Field
                        type="number"
                        id="initial_budget"
                        disabled
                        data-testid="initialBudget"
                        name="initial_budget"
                        label="Initial Budget"
                        value={currentProject.initial_budget}
                      />
                    </Grid.Item>
                  )}
                  <Grid.Item xs={2} className="hc-pv-dense">
                    <Form.Field
                      id="tactic"
                      disabled
                      data-testid="tactic"
                      name="tactic"
                      label="Tactic"
                      value={tacticValue(currentProject.tactic)}
                    />
                  </Grid.Item>

                  <Grid.Item xs={3} className="hc-pv-dense">
                    <Form.Field
                      type="select"
                      id="fund_id"
                      data-testid="fundId"
                      name="fund_id"
                      label="Fund ID"
                      value={currentProject.fund_id}
                      disabled
                      options={
                        projectFundInfo &&
                        projectFundInfo.funds.map((fund: ProjectFund) => ({
                          value: fund.fund_id,
                          label: fund.fund_name,
                        }))
                      }
                    />
                  </Grid.Item>
                </Grid.Container>
                <Grid.Container align="center" className="hc-ma-dense">
                  <Grid.Item xs={3} className="hc-pv-dense">
                    <Form.Field
                      type="date"
                      disabled
                      id="nrsc_receive_date"
                      label="NRSC Receive Date"
                      value={moment(currentProject?.nrsc_receive_date).format(
                        'YYYY-MM-DD',
                      )}
                    />
                  </Grid.Item>
                  <GridItem className="hc-pv-dense">
                    <GridContainer justify="flex-start" align="center">
                      <Grid.Item className="hc-pr-none">
                        <Form.Field
                          type="checkbox"
                          className="hc-mt-normal sem-readonly-form-chip"
                          label="Creative Content"
                          id="creative_assets"
                          data-testid="creativeAssets"
                          checked={currentProject.creative_assets}
                          disabled
                        />
                      </Grid.Item>
                      {currentProject.creative_assets &&
                      currentProject.assethub_info.status === 'SUCCESS' ? (
                        <GridItem className="hc-pa-none">
                          <ReactTooltip html={true}>
                            Assethub Project Link
                          </ReactTooltip>
                          <Anchor
                            className="hc-fs-caption"
                            target="_blank"
                            href={currentProject.assethub_info.project_url}
                          >
                            <EnterpriseIcon
                              className="sem-assethubSymbol"
                              data-tip
                              icon={CheckCircleIcon}
                              color="green"
                            />
                          </Anchor>
                        </GridItem>
                      ) : (
                        currentProject.creative_assets && (
                          <GridItem className="hc-pa-none">
                            <ReactTooltip html={true}>
                              {currentProject.assethub_info.error_msg}
                            </ReactTooltip>
                            <EnterpriseIcon
                              className="sem-assethubSymbol"
                              data-tip
                              icon={ErrorIcon}
                              color="red"
                            />
                          </GridItem>
                        )
                      )}
                    </GridContainer>
                  </GridItem>
                  <Grid.Item className="hc-pv-dense">
                    <Form.Field
                      type="checkbox"
                      className="hc-mt-normal sem-readonly-form-chip"
                      label="Non-Adjacency Project"
                      id="non_adjacency_project"
                      data-testid="nonAdjacencyProject"
                      checked={currentProject.non_adjacency_project}
                      disabled
                    />
                  </Grid.Item>
                  <Grid.Item xs={12} className="hc-pv-none">
                    <Divider className="hc-pv-normal" />
                    <Input.Label>POG Selection Options</Input.Label>
                    <GridContainer
                      justify="flex-start"
                      className="hc-pb-none hc-pt-normal"
                      align="center"
                    >
                      <GridItem className="hc-pv-none">
                        <Form.Field
                          type="checkbox"
                          className="hc-pv-none sem-readonly-form-chip"
                          label="Use Project Set Date"
                          id="project_set_date"
                          data-testid="projectSetDate"
                          checked={
                            !(
                              currentProject.pog_set_date
                                .use_marketing_plan_date ||
                              currentProject.pog_set_date.use_multiple_set_date
                            )
                          }
                          disabled
                        />
                      </GridItem>
                      <GridItem className="hc-pv-none">
                        <Form.Field
                          type="checkbox"
                          className="hc-pv-none sem-readonly-form-chip"
                          label="Use Marketing Plan Date"
                          id="marketing_plan_date"
                          data-testid="marketingPlandate"
                          checked={
                            currentProject.pog_set_date.use_marketing_plan_date
                          }
                          disabled
                        />
                      </GridItem>
                      <GridItem className="hc-pv-none">
                        <Form.Field
                          type="checkbox"
                          className="hc-pv-none sem-readonly-form-chip"
                          label="Use Multiple Set Dates"
                          id="multiple_set_dates"
                          data-testid="multipleSetDates"
                          checked={
                            currentProject.pog_set_date.use_multiple_set_date
                          }
                          disabled
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer
                      justify="flex-start"
                      className="hc-pt-normal"
                      align="center"
                    >
                      <GridItem xs={12} className="sem-project-pog-set-dates">
                        {currentProject.pog_set_date.use_multiple_set_date &&
                          currentProject.pog_set_date.set_dates.map(
                            (pogSetDate: string, index: number) => (
                              <Chip
                                className="hc-mb-normal"
                                size="dense"
                                key={index}
                              >
                                {moment
                                  .tz(pogSetDate, 'America/Chicago')
                                  .format('MM/DD/YYYY')}
                              </Chip>
                            ),
                          )}
                      </GridItem>
                    </GridContainer>
                    <Divider className="hc-pt-expanded" />
                  </Grid.Item>
                </Grid.Container>
                <Grid.Container className="sem_ProjectDivision--Container">
                  <Grid.Item>
                    <label className="sem_ProjectDivision">
                      Project Divisions
                    </label>
                  </Grid.Item>
                  <Grid.Item xs={true}>
                    <Grid.Container>
                      <Grid.Item xs={12}>
                        {currentProject.divisions.map(
                          (division: Division, index: number) => (
                            <Chip
                              size="dense"
                              key={index}
                              className="hc-mb-dense"
                            >
                              {division.division_display_name}
                            </Chip>
                          ),
                        )}
                      </Grid.Item>
                    </Grid.Container>
                  </Grid.Item>
                </Grid.Container>

                <Grid.Container className="sem_ProjectDivision--Container">
                  <Grid.Item>
                    <label className="sem_ProjectDivision">
                      Project Departments
                    </label>
                  </Grid.Item>
                  <Grid.Item xs={true}>
                    <Grid.Container>
                      <Grid.Item xs={12}>
                        {currentProject.departments.map(
                          (department: Department, index: number) => (
                            <Chip
                              size="dense"
                              key={index}
                              className="hc-mb-dense"
                            >
                              {department.department_display_name}
                            </Chip>
                          ),
                        )}
                      </Grid.Item>
                    </Grid.Container>
                  </Grid.Item>
                </Grid.Container>
              </Grid.Container>
            </div>
          </ExpandableSection.Content>
        </ExpandableSection>
      </Grid.Item>
    </Grid.Container>
  )
}

export default ProjectFormCard
