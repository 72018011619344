import React, { useEffect } from 'react'
import { Grid, Divider, Card } from '@enterprise-ui/canvas-ui-react'
import MainSectionHeader from '../../App/components/MainSectionHeader'
import SignInfoSection from './SignInfoSection'
import SignPrintSection from './SignPrintSection'
import SignPricingSection from './SignPricingSection'
import SignDistributionSection from './DistributionSection'
import SignGallery from './SignGallery'
import SignPreview from './SignPreview'
import SignDetailsSidebar from './SignDetailsSidebar'
import { useSignDetailsContext } from '../context/signDetailsContext'
import { NEW_SIGN } from '../constants/signDetailsConstants'
import SignOverviewCard from './SignOverviewCard'
import MessagingSection from './MessagingSection'
import '../styles/signDetailsStyles.scss'
import { SIGN_PREVIEW_TYPES } from '../constants/signMessagingFacets'
import { NOTES_BUTTON } from '../../App/constants/appConstants'
import SignNotes from './SignNotes'
import { useSignFormsContext } from '../context/signFormsContext'
import ReasonCodeModal from '../../ProjectDetails/components/ProjectSignList/components/signListViews/components/ReasonCodeModal'
import { useParams } from 'react-router-dom'
const SignDetailsContainer = () => {
  //const { pageHasChanges } = useAppContext()!
  const {
    currentSign,
    refreshSignDetails,
    refreshNewSignDetails,
    setIsInProject,
    project,
    reasonCode,
    setReasonCode,
    signFacets,
    editReasonRequired,
    refreshSignFacets,
    deleteSign,
    actionType,
    setActionType,
    showReasonCodeModal,
    setShowReasonCodeModal,
    signIds,
  } = useSignDetailsContext()!
  const {
    signPreviewFormik,
    signInfoFormik,
    signMessagingFormik,
    signPrintFormik,
    signDistributionFormik,
  } = useSignFormsContext()!

  const { project_id, sign_id, type } = useParams()
  const isNew = currentSign.sign_id === NEW_SIGN
  useEffect(() => {
    sign_id === 'create'
      ? refreshNewSignDetails(project_id!!)
      : refreshSignDetails(sign_id!!, 'Loading Sign Details...')
  }, [refreshNewSignDetails, refreshSignDetails, project_id, sign_id, type])
  useEffect(() => {
    setIsInProject(project_id !== undefined)
  }, [project_id, setIsInProject])
  useEffect(() => {
    refreshSignFacets()
  }, [refreshSignFacets])

  useEffect(() => {
    if (
      editReasonRequired &&
      sign_id === 'create' &&
      (reasonCode === undefined || reasonCode === '') &&
      signFacets.new_sign_reason_code.length > 0
    ) {
      setActionType('Add')
      setShowReasonCodeModal(true)
    } else {
      setShowReasonCodeModal(false)
    }
  }, [
    editReasonRequired,
    sign_id,
    reasonCode,
    signFacets,
    refreshSignFacets,
    setActionType,
    setShowReasonCodeModal,
  ])

  return (
    <div className="sem_SignDetailsContainer">
      <MainSectionHeader
        sectionTitle={isNew ? 'Create New Sign' : currentSign.sign_name}
        crumbs={
          project_id
            ? [
                { name: 'Project Library', to: '/projects' },
                {
                  name: 'Project Details',
                  to: `/projects/${project_id}?tab=SIGN+LIST`,
                },
                { name: 'Sign Details' },
              ]
            : [{ name: 'Sign Library', to: '/signs' }, { name: 'Sign Details' }]
        }
        pagination={true}
        notesButton={isNew ? undefined : NOTES_BUTTON.SIGN}
      />

      {!isNew ? <SignNotes /> : null}

      {currentSign.sign_id ? (
        <Card className="hc-pt-normal hc-ph-normal hc-pb-expanded">
          <Grid.Container>
            {project_id ? <SignOverviewCard /> : null}
            <SignDetailsSidebar />
            <SignGallery />
            {(currentSign.sign_type === SIGN_PREVIEW_TYPES.DISPLAY ||
              currentSign.sign_type === SIGN_PREVIEW_TYPES.VALUE) &&
            signPreviewFormik.values.sign_size !== 'OTHER' &&
            signPreviewFormik.values.sign_size !== '' ? (
              <SignPreview />
            ) : null}
            <Divider className="hc-pt-normal" direction="vertical" />
            <Grid.Item className="hc-mt-normal" xs={true}>
              <Grid.Container>
                <SignInfoSection />
                {!isNew &&
                (currentSign.sign_type === SIGN_PREVIEW_TYPES.DISPLAY ||
                  currentSign.sign_type === SIGN_PREVIEW_TYPES.VALUE) ? (
                  <MessagingSection />
                ) : null}
                {isNew ? null : <SignPrintSection />}
                {isNew ? null : <SignDistributionSection />}
                {isNew ? null : <SignPricingSection />}
              </Grid.Container>
            </Grid.Item>
          </Grid.Container>
        </Card>
      ) : null}
      {/*TODO: Create custom modal as prompt isn't supported*/}
      {/*<Prompt*/}
      {/*  when={pageHasChanges}*/}
      {/*  message="You have unsaved changes. Are you sure you want to leave?"*/}
      {/*/>*/}
      <ReasonCodeModal
        projectId={project.project_id}
        actionType={actionType}
        showReasonCode={showReasonCodeModal}
        setShowReasonCode={setShowReasonCodeModal}
        setReasonCode={setReasonCode}
        signFacets={signFacets}
        deleteSigns={deleteSign}
        signIds={signIds}
        updateInfo={signInfoFormik.handleSubmit}
        updateMessaging={signMessagingFormik.handleSubmit}
        updatePrinting={signPrintFormik.handleSubmit}
        updateKitting={signDistributionFormik.handleSubmit}
      />
    </div>
  )
}

export default SignDetailsContainer
