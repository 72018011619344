import React from 'react'
import { ProjectDistributionProvider } from './context/projectDistributionContext'
import ProjectDistributionTab from './components/ProjectDistributionTab'

const DistributionTab = (props: any) => (
  <ProjectDistributionProvider>
    <ProjectDistributionTab {...props} />
  </ProjectDistributionProvider>
)

export default DistributionTab
