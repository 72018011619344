import React, { useEffect } from 'react'
import { Table, Grid, Button, Popover } from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import AdminCampaignTableItem from './AdminCampaignTableItem'
import { useAdminUploadContext } from '../context/adminUploadContext'
import { CampaignDetail } from '../../../../models/campaigns/CampaignDetail.model'
import CreateAdminCampaignForm from './CreateAdminCampaignForm'

const AdminCampaignTable = () => {
  const {
    campaignList,
    setCampaignList,
    getCampaignList,
    campaignYears,
    getCampaignYears,
    selectedCampaignYear,
    setSelectedCampaignYear,
    setIsUploadFile,
  } = useAdminUploadContext()!

  useEffect(() => {
    getCampaignYears()
  }, [getCampaignYears])

  const campaignYearsOptions = campaignYears.map((year: any) => ({
    id: year,
    value: year,
    label: year.toString(),
  }))

  return (
    <Grid.Container spacing="flex-end">
      <Grid.Item xs={12}>
        <Grid.Container justify="flex-start">
          <Grid.Item xs={2}>
            <Autocomplete
              label="Choose A Campaign Year"
              options={campaignYearsOptions}
              optionHeight="dense"
              onUpdate={(id: string, value: any) => {
                if (value != null) {
                  getCampaignList(value.id)
                  setSelectedCampaignYear(value.id)
                } else {
                  setCampaignList([])
                  setSelectedCampaignYear('')
                }
              }}
            />
          </Grid.Item>

          <Grid.Item>
            <Button
              className="sem-AdminUploadButton"
              fullWidth
              type="primary"
              onClick={() => setIsUploadFile(true)}
            >
              Upload File
            </Button>
          </Grid.Item>

          <Grid.Item>
            <Popover
              type="clickOnly"
              location="bottom-right"
              size="expanded"
              className="sem-AddMilestonePopover"
              content={<CreateAdminCampaignForm />}
            >
              <Button
                type="primary"
                fullWidth
                disabled={selectedCampaignYear === ''}
                className="sem-AdminUploadButton"
              >
                Add Campaign
              </Button>
            </Popover>
          </Grid.Item>

          {campaignList.length > 0 && (
            <Table className="sem-Table">
              <Table.Head>
                <Table.Row justify="space-between">
                  <Table.Header className="sem_Table--IconHeader hc-mr-normal">
                    {'   '}
                  </Table.Header>
                  <Table.Header
                    xs={1}
                    className="sem_Table--IconHeader hc-mr-normal"
                  >
                    Campaign Year
                  </Table.Header>
                  <Table.Header
                    xs={1}
                    className="sem_Table--IconHeader hc-mr-normal"
                  >
                    Campaign ID
                  </Table.Header>
                  <Table.Header
                    xs={2}
                    className="sem_Table--IconHeader hc-mr-normal"
                  >
                    Campaign TYPE
                  </Table.Header>
                  <Table.Header
                    xs={1}
                    className="sem_Table--IconHeader hc-mr-normal"
                  >
                    Fiscal Year Start Date
                  </Table.Header>
                  <Table.Header
                    xs={1}
                    className="sem_Table--IconHeader hc-mr-normal"
                  >
                    Fiscal Year End Date
                  </Table.Header>
                  <Table.Header
                    xs={1}
                    className="sem_Table--IconHeader hc-mr-normal"
                  >
                    Campaign Plan Start Date
                  </Table.Header>
                  <Table.Header
                    xs={1}
                    className="sem_Table--IconHeader hc-mr-normal"
                  >
                    Campaign Plan End Date
                  </Table.Header>
                  <Table.Header
                    xs={1}
                    className="sem_Table--IconHeader hc-mr-normal"
                  >
                    Active
                  </Table.Header>
                </Table.Row>
              </Table.Head>

              <Table.Body>
                {campaignList.map((campaign: CampaignDetail, index) => (
                  <AdminCampaignTableItem key={index} campaign={campaign} />
                ))}
              </Table.Body>
            </Table>
          )}
        </Grid.Container>
      </Grid.Item>
    </Grid.Container>
  )
}

export default AdminCampaignTable
