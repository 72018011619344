import React, { useEffect, useCallback, Fragment } from 'react'
import { Grid, Spinner } from '@enterprise-ui/canvas-ui-react'
import AgGridSignListTable from './signListViews/AgGridSignListTable'
import '../styles/projectSignListTabStyles.scss'
import { useProjectSignListContext } from '../context/projectSignListContext'
import { useProjectDetailsContext } from '../../../context/projectDetailsContext'
import { useSignBulkEditContext } from 'components/SignBulkEdit/context/signBulkEditContext'
import { useAgGridSignListContext } from '../context/agGridSignListContext'
import { useAppContext } from 'components/App/context/appContext'
import DuplicateSignModal from './modals/DuplicateSignModal'
import ReasonCodeModal from './signListViews/components/ReasonCodeModal'
import PogModal from '../../../../SignDetails/components/modals/PogModal'
import BulkEditModal from './modals/BulkEditModal'
import FinalizingSignsModal from './modals/FinalizingSignsModal'
import MoveSignsModal from './modals/MoveSignsModal'
import { JobStatusNames } from '../../../../../models/job/JobStatus.model'
import FullScreenTableEditor from './modals/FullScreenTableEditor'
import SignGroupModal from './modals/SignGroupModal'
import SignLibraryImportModal from './modals/SignLibraryImportModal'
import ExportImagesModal from './modals/ExportImagesModal'
import SignResponse from '../../../../../models/signs/SignResponse.model'

export const ProjectSignListTab = () => {
  const {
    currentProject,
    getProjectSigns,
    isLoadingSignList,
    projectSignList,
  } = useProjectDetailsContext()!

  const {
    isTableEditorFullScreen,
    refreshSignFacets,
    setAllSignsFinalized,
    setAllSignsReadyToFinalize,
    setAllSignsReadyForKitting,
    setIsImportSignsFullScreen,
    actionType,
    showReasonCodeModal,
    setShowReasonCodeModal,
    signIds,
    setReasonCode,
    deleteSigns,
    setSignForDuplicateModal,
    signToDuplicate,
    signFacets,
    pogs,
    isPogModalOpen,
    setIsPogModalOpen,
    isPogModalLoading,
    signImportResponse,
    getSignImportInfo,
    getDuplicateInfo,
    signDuplicateResponse,
    //signsModified,
    saveModifiedSigns,
    setModifiedSignIds,
    setSignsModified,
    includePogs,
    excludePogs,
    setIsBulkEditOpen,
    setSelectedStatus,
  } = useProjectSignListContext()!
  const { selectedRows, setSelectedRows } = useAgGridSignListContext()!

  const { saveBulkSigns, setSelectedFields } = useSignBulkEditContext()!

  const { setPageHasChanges, getPogCategoryList } = useAppContext()!

  useEffect(() => {
    getProjectSigns(currentProject.project_id)
  }, [currentProject.project_id, getProjectSigns])

  useEffect(() => {
    refreshSignFacets(currentProject)
  }, [currentProject, refreshSignFacets])

  const refreshImportAndSigns = useCallback(() => {
    getSignImportInfo(currentProject.project_id)
    getProjectSigns(currentProject.project_id)
  }, [currentProject.project_id, getSignImportInfo, getProjectSigns])

  const refreshDuplicateAndSigns = useCallback(() => {
    getDuplicateInfo(currentProject.project_id)
    getProjectSigns(currentProject.project_id)
  }, [currentProject.project_id, getDuplicateInfo, getProjectSigns])

  useEffect(() => {
    const interval = setInterval(refreshImportAndSigns, 10 * 1000)
    signImportResponse?.status?.name !== JobStatusNames.RUNNING &&
      clearInterval(interval)
    return () => {
      clearInterval(interval)
    }
  }, [refreshImportAndSigns, signImportResponse])

  useEffect(() => {
    const interval = setInterval(refreshDuplicateAndSigns, 10 * 1000)
    signDuplicateResponse?.status?.name !== JobStatusNames.RUNNING &&
      clearInterval(interval)
    return () => {
      clearInterval(interval)
    }
  }, [refreshDuplicateAndSigns, signDuplicateResponse])

  useEffect(() => {
    setAllSignsFinalized(
      projectSignList.filter(
        (sign: SignResponse) =>
          sign.sign_status === 'New' || sign.sign_status === 'Reissue',
      ).length > 0 &&
        projectSignList
          .filter(
            (sign: SignResponse) =>
              sign.sign_status === 'New' ||
              sign.sign_status === 'Reissue' ||
              sign.sign_status === 'Carry Forward',
          )
          .every((sign: SignResponse) => sign.finalized),
    )
  }, [projectSignList, setAllSignsFinalized])

  useEffect(() => {
    setAllSignsReadyToFinalize(
      projectSignList.length > 0 &&
        projectSignList
          .filter(
            (sign: SignResponse) =>
              sign.sign_status === 'New' || sign.sign_status === 'Reissue',
          )
          .every(
            (sign: SignResponse) => sign.workflow.status !== 'Sign Initiated',
          ),
    )
  }, [projectSignList, setAllSignsReadyToFinalize])

  useEffect(() => {
    setAllSignsReadyForKitting(
      projectSignList.length > 0 &&
        projectSignList
          .filter(
            (sign: SignResponse) =>
              (sign.sign_status === 'New' || sign.sign_status === 'Reissue') &&
              !(
                sign.sign_type === 'Value Sign' &&
                (sign.messaging.sign_size === 'ELEVEN_BY_ELEVEN' ||
                  sign.messaging.sign_size === 'SEVEN_BY_ELEVEN')
              ),
          )
          .every((sign: SignResponse) => sign.ready_for_kitting),
    )
  }, [projectSignList, setAllSignsReadyForKitting])

  useEffect(() => {
    getPogCategoryList()
  }, [getPogCategoryList])

  return (
    <Fragment>
      <Grid.Container
        className="sem_ProjectSignListTab"
        justify="space-between"
      >
        {isLoadingSignList ? (
          <Grid.Container
            className="sign-projects-loading-container"
            direction="column"
            justify="center"
            align="center"
          >
            <Grid.Item>Loading Signs...</Grid.Item>
            <Grid.Item>
              <Spinner />
            </Grid.Item>
          </Grid.Container>
        ) : isTableEditorFullScreen ? (
          <FullScreenTableEditor />
        ) : (
          <AgGridSignListTable />
        )}
        {isLoadingSignList ? null : <SignLibraryImportModal />}
        <BulkEditModal />
        <FinalizingSignsModal />
        <SignGroupModal />
        <ExportImagesModal />
        <DuplicateSignModal />
        <ReasonCodeModal
          projectId={currentProject.project_id}
          actionType={actionType}
          showReasonCode={showReasonCodeModal}
          setShowReasonCode={setShowReasonCodeModal}
          setIsImportSignsFullScreen={setIsImportSignsFullScreen}
          signIds={signIds}
          setReasonCode={setReasonCode}
          deleteSigns={deleteSigns}
          setSignForDuplicateModal={setSignForDuplicateModal}
          signToDuplicate={signToDuplicate}
          signFacets={signFacets}
          saveModifiedSigns={saveModifiedSigns}
          setModifiedSignIds={setModifiedSignIds}
          setSignsModified={setSignsModified}
          saveBulkSigns={saveBulkSigns}
          selectedSigns={selectedRows}
          closeBulkEdit={() => setIsBulkEditOpen(false)}
          setSelectedSigns={setSelectedRows}
          setSelectedStatus={setSelectedStatus}
          setSelectedFields={setSelectedFields}
          refreshSignList={() => getProjectSigns(currentProject.project_id)}
          setPageHasChanges={setPageHasChanges}
        />
        <PogModal
          pogDetails={pogs}
          isVisible={isPogModalOpen.open}
          toggleVisible={setIsPogModalOpen}
          isPogModalLoading={isPogModalLoading}
          currentSignId={isPogModalOpen.sign_id}
          includePogs={includePogs}
          excludePogs={excludePogs}
          refreshSignList={() => getProjectSigns(currentProject.project_id)}
        />
        <MoveSignsModal />
      </Grid.Container>
    </Fragment>
  )
}

export default ProjectSignListTab
