import React from 'react'
import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import { Grid, Spinner } from '@enterprise-ui/canvas-ui-react'
import { Column } from '../../../../models/agGrid/AgGridColumn.model'
import {
  BLUEPRINT_MILESTONE_VIEW_COLUMNS,
  BLUEPRINT_VIEW_COLUMNS,
} from '../../../../models/agGrid/AgGridDashboardView'
import { useAgGridDashboardContext } from '../../context/agGridDashboardContext'
import DashboardActions from '../DashboardActionsDropdown'
import { useDashboardContext } from '../../context/dashboardContext'
import DashboardViews from '../DashboardViewsButtonDropdown'
import { TabName } from '../../constants/dashboardConstants'
import { useAppContext } from '../../../App/context/appContext'
import { ErrorCard } from 'components/ErrorCard'
import DashboardEditButtons from 'components/Dashboard/components/DashboardEditButtons'

const BlueprintDashboard = () => {
  const { dashboardActiveTab, setPageHasChanges } = useAppContext()!
  const {
    isLoading,
    blueprintSummaryList,
    blueprintMilestoneSummaryList,
    dashboardErrors,
    modifiedBlueprintMilestones,
    setModifiedBlueprintMilestones,
  } = useDashboardContext()!
  const {
    onGridReady,
    gridOptions,
    onFirstDataRender,
    onFilterChange,
    onSortChanged,
    gridApi,
  } = useAgGridDashboardContext()!

  const handleCellValueChange = (e: any) => {
    setModifiedBlueprintMilestones([...modifiedBlueprintMilestones, e.data])
    setPageHasChanges(true)
    return (e.data.modified = true)
  }

  return (
    <div className="hc-pa-dense hc-pv-none">
      <Grid.Container>
        <DashboardActions />
        <DashboardViews />
        {modifiedBlueprintMilestones.length > 0 ? (
          <DashboardEditButtons gridApi={gridApi} />
        ) : null}
        {isLoading ? (
          <Grid.Container
            className="sign-projects-loading-container"
            direction="column"
            justify="center"
            align="center"
          >
            <Grid.Item>
              {dashboardActiveTab === TabName.BLUEPRINT_SUMMARY
                ? 'Loading Blueprint Summary...'
                : dashboardActiveTab === TabName.MY_BLUEPRINT_MILESTONES
                ? 'Loading Blueprint Milestone Summary...'
                : 'Loading...'}
            </Grid.Item>
            <Grid.Item>
              <Spinner />
            </Grid.Item>
          </Grid.Container>
        ) : (
          <Grid.Item xs={12}>
            <div className="ag-theme-rubix dashboard-table">
              {!!dashboardErrors.BLUEPRINT_SUMMARY_LIST &&
              dashboardActiveTab === TabName.BLUEPRINT_SUMMARY ? (
                <ErrorCard
                  text={`${dashboardErrors.BLUEPRINT_SUMMARY_LIST}. Please try again.`}
                />
              ) : dashboardErrors.BLUEPRINT_MILESTONE_SUMMARY_LIST &&
                dashboardActiveTab !== TabName.BLUEPRINT_SUMMARY ? (
                <ErrorCard
                  text={`${dashboardErrors.BLUEPRINT_MILESTONE_SUMMARY_LIST}. Please try again.`}
                />
              ) : (
                <AgGridReact
                  rowData={
                    dashboardActiveTab === TabName.BLUEPRINT_SUMMARY
                      ? blueprintSummaryList
                      : blueprintMilestoneSummaryList
                  }
                  gridOptions={gridOptions}
                  onGridReady={onGridReady}
                  onFirstDataRendered={onFirstDataRender}
                  applyColumnDefOrder={true}
                  enableRangeSelection
                  enableFillHandle
                  fillHandleDirection="y"
                  suppressRowClickSelection={true}
                  onFilterChanged={(e) => onFilterChange(e)}
                  onSortChanged={(e) => onSortChanged(e)}
                  onCellValueChanged={(e: any) => handleCellValueChange(e)}
                >
                  {dashboardActiveTab === TabName.BLUEPRINT_SUMMARY
                    ? BLUEPRINT_VIEW_COLUMNS.map(
                        (column: Column, index: number) => (
                          <AgGridColumn
                            field={column.field}
                            colId={column.colId}
                            headerName={column.headerName}
                            width={column.width || 148}
                            type={column.type}
                            filter={column.filter || 'agTextColumnFilter'}
                            key={index}
                          />
                        ),
                      )
                    : dashboardActiveTab === TabName.MY_BLUEPRINT_MILESTONES
                    ? BLUEPRINT_MILESTONE_VIEW_COLUMNS.map(
                        (column: Column, index: number) => (
                          <AgGridColumn
                            field={column.field}
                            colId={column.colId}
                            headerName={column.headerName}
                            width={column.width || 148}
                            type={column.type}
                            filter={column.filter || 'agTextColumnFilter'}
                            key={index}
                          />
                        ),
                      )
                    : null}
                </AgGridReact>
              )}
            </div>
          </Grid.Item>
        )}
      </Grid.Container>
    </div>
  )
}

export default BlueprintDashboard
