export class DropdownOption<T> {
  id: string
  value: T
  label: string

  constructor(props: any = {}) {
    this.id = props.id || ''
    this.value = props.value || ''
    this.label = props.label || ''
  }
}
