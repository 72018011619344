import { Column } from './AgGridColumn.model'

export const POG_VIEW_COLUMNS = [
  new Column({
    type: ['selectedRow', 'fixed'],
  }),
  new Column({
    field: 'pog_id',
    headerName: 'POG ID',
    type: ['fixed'],
  }),
  new Column({
    field: 'pog_description',
    headerName: 'POG Description',
    width: 280,
    type: ['fixed'],
  }),
  new Column({
    field: 'status',
    headerName: 'POG Status',
  }),
  new Column({
    field: 'excluded',
    headerName: 'POG Included',
    type: ['pogIncluded'],
  }),
  new Column({
    field: 'locations_set.length',
    headerName: 'Total Locations',
    type: ['tableChip'],
  }),
  new Column({
    field: 'quantity',
    headerName: 'Quantity',
    width: 177,
  }),
  new Column({
    field: 'width',
    headerName: 'Width (ft)',
  }),
  new Column({
    field: 'height',
    headerName: 'Height (in)',
  }),
  new Column({
    field: 'last_modified_date',
    headerName: 'POG Updated Time',
    type: ['date'],
    width: 210,
  }),
]
