import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { Input } from '@enterprise-ui/canvas-ui-react'

export interface Props {
  value: boolean
}

const ToggleCellEditor = forwardRef(({ value }: Props, ref) => {
  const [editValue, setEditValue] = useState(value)
  const refInput = useRef<HTMLInputElement>(null)

  const handleChange = (id: string, checked: boolean) => setEditValue(checked)

  useEffect(() => {
    setTimeout(() => refInput?.current?.focus())
  }, [])

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return editValue
      },
    }
  })

  return (
    <Input.Toggle
      id="toggle_cell"
      ref={refInput}
      checked={editValue}
      onChange={(e: any) => handleChange(e.target.id, e.target.checked)}
    />
  )
})

export default ToggleCellEditor
