import {
  ApplyColumnStateParams,
  ColDef,
  SetFilterValuesFuncParams,
  ValueGetterParams,
} from 'ag-grid-community'
import { get } from 'lodash'
import moment from 'moment'
import {
  Department,
  DropdownMenu,
  ProjectName,
  StatusChip,
} from '../components/SignLibraryImportTable/frameworkComponents'
import { BlueprintName } from '../../SignLibrary/components/SignLibraryTable/frameworkComponents'

export const dateValueGetter = (params: ValueGetterParams) =>
  get(params.data, params.column.getColId())
    ? moment(get(params.data, params.column.getColId())).format('MM/DD/YYYY')
    : ''

export const detailDateFilterParams = {
  browserDatePicker: true,
  filterOptions: ['inRange'],
  suppressAndOrCondition: true,
  comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
    if (cellValue === null) {
      return -1
    }
    const cellDate = new Date(cellValue)
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1
    }
  },
}

export const detailDateComparator = (value1: string, value2: string) => {
  const _dateToNum = (date: string) => {
    if (date === undefined || date === null || date.length !== 10) {
      return null
    }
    return (
      parseInt(date.substring(6, 10)) * 10000 +
      parseInt(date.substring(0, 2)) * 100 +
      parseInt(date.substring(3, 5))
    )
  }
  const date1 = _dateToNum(value1)
  const date2 = _dateToNum(value2)
  return date1 === null && date2 === null
    ? 0
    : date1 === null
    ? -1
    : date2 === null
    ? 1
    : date1 - date2
}

export const signImportGridComponents = {
  DropdownMenu: DropdownMenu,
  StatusChip: StatusChip,
  Department: Department,
  ProjectName: ProjectName,
  BlueprintName: BlueprintName,
}

/* === Sign Import Column Types === */
export const signImportColumnTypes: { [key: string]: ColDef } = {
  fixed: {
    lockPinned: true,
    resizable: false,
    suppressSizeToFit: true,
    pinned: 'left',
  },
  textFilter: {
    filterParams: {
      filterOptions: ['contains'],
      suppressAndOrCondition: true,
    },
  },
  numberFilter: {
    filterParams: {
      filterOptions: ['equals'],
      suppressAndOrCondition: true,
    },
  },
  nonFilterable: {
    sortable: false,
    suppressMenu: true,
    headerClass: 'ag-header-cell-non-filterable',
  },
  hiddenColumn: {
    hide: true,
  },
  selectedRow: {
    width: 50,
    maxWidth: 50,
    filter: false,
    sortable: false,
    suppressMenu: true,
    checkboxSelection: true,
  },
  signMenu: {
    width: 36,
    maxWidth: 36,
    filter: false,
    sortable: false,
    suppressMenu: true,
    cellRenderer: 'DropdownMenu',
    cellClass: 'actions-button-cell dropdown-menu',
  },
  dpci: {
    cellRenderer: 'agGroupCellRenderer',
    filterParams: {
      filterOptions: [
        {
          displayKey: 'dpciList',
          displayName: 'DPCI List (comma separated)',
          predicate: () => {},
        },
      ],
      suppressAndOrCondition: true,
    },
  },
  excludeDpci: {
    cellRenderer: 'agGroupCellRenderer',
    filterParams: {
      filterOptions: [
        {
          predicate: () => {},
        },
      ],
      suppressAndOrCondition: true,
    },
  },
  signType: {
    filterParams: {
      values: (params: SetFilterValuesFuncParams) =>
        params.success(
          Object.keys(
            params.api.getServerSideStoreState()[0].info.facets.sign_type,
          ),
        ),
    },
  },
  signItemStatus: {
    cellRenderer: 'StatusChip',
    filterParams: {
      values: ['Active', 'In Development', 'Obsolete'],
    },
    sortable: false,
    suppressMenu: true,
  },
  signStatus: {
    filterParams: {
      values: ['New', 'Carry Forward', 'Remove', 'Reissue'],
    },
    cellRenderer: 'StatusChip',
  },
  signTemplate: {
    filterParams: {
      values: (params: SetFilterValuesFuncParams) =>
        params.success(
          Object.keys(
            params.api.getServerSideStoreState()[0].info.facets.sign_template,
          ).map((facet: string) =>
            facet === 'null (0)' ? '-- No Department Selected --' : facet,
          ),
        ),
      refreshValuesOnOpen: true,
    },
  },
  selectedSignStatus: {
    cellRenderer: 'StatusChip',
  },
  date: {
    valueGetter: (params: ValueGetterParams) => dateValueGetter(params),
    filterParams: {
      filterOptions: ['inRange'],
      suppressAndOrCondition: true,
    },
  },
  detailDate: {
    valueGetter: (params: ValueGetterParams) => dateValueGetter(params),
    filterParams: detailDateFilterParams,
    comparator: detailDateComparator,
  },
  department: {
    cellRenderer: 'Department',
    filterParams: {
      values: (params: SetFilterValuesFuncParams) =>
        params.success(
          Object.keys(
            params.api.getServerSideStoreState()[0].info.facets.department,
          ).map((facet: string) =>
            facet === 'null (0)' ? '-- No Department Selected --' : facet,
          ),
        ),
      refreshValuesOnOpen: true,
    },
  },
  productVendor: {
    filterParams: {
      values: (params: SetFilterValuesFuncParams) =>
        params.success(
          Object.keys(
            params.api.getServerSideStoreState()[0].info.facets.product_vendor,
          ),
        ),
      refreshValuesOnOpen: true,
    },
  },
  separator: {
    filterParams: {
      values: (params: SetFilterValuesFuncParams) =>
        params.success(
          Object.keys(
            params.api.getServerSideStoreState()[0].info.facets.separator,
          ),
        ),
      refreshValuesOnOpen: true,
    },
  },
  pyramid: {
    filterParams: {
      values: (params: SetFilterValuesFuncParams) =>
        params.success(
          Object.keys(
            params.api.getServerSideStoreState()[0].info.facets.pyramid,
          ),
        ),
      refreshValuesOnOpen: true,
    },
    hide: true,
  },
  division: {
    filterParams: {
      values: (params: SetFilterValuesFuncParams) =>
        params.success(
          Object.keys(
            params.api.getServerSideStoreState()[0].info.facets.division,
          ),
        ),
      refreshValuesOnOpen: true,
    },
    hide: true,
  },
  projectType: {
    filterParams: {
      values: (params: SetFilterValuesFuncParams) =>
        params.success(
          Object.keys(
            params.api.getServerSideStoreState()[0].info.facets.project_type,
          ),
        ),
      refreshValuesOnOpen: true,
    },
    hide: true,
  },
  campaignType: {
    filterParams: {
      values: (params: SetFilterValuesFuncParams) =>
        params.success(
          Object.keys(
            params.api.getServerSideStoreState()[0].info.facets.campaign,
          ),
        ),
      refreshValuesOnOpen: true,
    },
    hide: true,
  },
  printVendor: {
    filterParams: {
      values: (params: SetFilterValuesFuncParams) =>
        params.success(
          Object.keys(
            params.api.getServerSideStoreState()[0].info.facets.printing_vendor,
          ),
        ),
      refreshValuesOnOpen: true,
    },
    hide: true,
  },
  kittingVendor: {
    filterParams: {
      values: (params: SetFilterValuesFuncParams) =>
        params.success(
          Object.keys(
            params.api.getServerSideStoreState()[0].info.facets.kitting_vendor,
          ),
        ),
      refreshValuesOnOpen: true,
    },
    hide: true,
  },
}

/* === Default Filter Models ===*/
export const defaultImportFilterModel = {
  'non_retail_item_info.sign_item_status': {
    filterType: 'set',
    values: ['Active'],
  },
  'project_sign_info_list.sign_status': {
    filterType: 'set',
    values: ['New', 'Carry Forward', 'Reissue'],
  },
}

export const defaultImportSortState = {
  state: [
    {
      colId: 'set_date',
      sort: 'desc',
    },
  ],
  defaultState: { sort: null },
} as ApplyColumnStateParams

export const detailGridDefaultFilterModel = {
  sign_status: {
    filterType: 'set',
    values: ['New', 'Carry Forward', 'Reissue'],
  },
}
