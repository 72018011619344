import React, { Fragment, useEffect } from 'react'
import { Grid, Spinner } from '@enterprise-ui/canvas-ui-react'
import '../styles/blueprintProjectListStyles.scss'
import AgGridProjectListTable from './projectListTable/AgGridProjectListTable'
import { useBlueprintDetailsContainerContext } from '../../../context/blueprintDetailsContainerContext'
import { useBlueprintProjectListContext } from '../context/blueprintProjectListContext'
import FullScreenProjectList from './modals/FullScreenProjectList'
import NewProjectModal from './modals/NewProjectModal'

const BlueprintProjectListTab = () => {
  const { currentBlueprint } = useBlueprintDetailsContainerContext()!
  const {
    loadProjectList,
    isLoadingProjectList,
    loadProjectFunds,
    loadDivisionOptions,
    //projectsModified,
    isProjectListFullScreen,
  } = useBlueprintProjectListContext()!

  useEffect(() => {
    currentBlueprint.blueprint_id && loadProjectList()
  }, [currentBlueprint.blueprint_id, loadProjectList])

  useEffect(() => {
    currentBlueprint.set_date && loadProjectFunds()
    // eslint-disable-next-line
  }, [currentBlueprint.set_date])

  useEffect(() => {
    currentBlueprint.campaign &&
      loadDivisionOptions(currentBlueprint.campaign.pyramids)
  }, [currentBlueprint.campaign, loadDivisionOptions])

  return (
    <Fragment>
      <Grid.Container
        className="sem_BlueprintProjectListTab"
        justify="space-between"
      >
        {isLoadingProjectList ? (
          <Grid.Container
            className="sign-project-loading-container"
            direction="column"
            justify="center"
            align="center"
          >
            <Grid.Item>Loading Projects...</Grid.Item>
            <Grid.Item>
              <Spinner />
            </Grid.Item>
          </Grid.Container>
        ) : (
          !isProjectListFullScreen && <AgGridProjectListTable />
        )}
        {isProjectListFullScreen && <FullScreenProjectList />}
        <NewProjectModal />
      </Grid.Container>
      {/*<Prompt*/}
      {/*  when={projectsModified}*/}
      {/*  message="You have unsaved changes. Are you sure you want to leave?"*/}
      {/*/>*/}
    </Fragment>
  )
}

export default BlueprintProjectListTab
