import { ProjectSignList } from '.'
import { SIGN_STATUS } from '../../components/App/constants/appConstants'

export interface Props {
  projectSignList: ProjectSignList[]
  pid: string
}

export default class SelectedProjectSignInfo {
  set_date?: string // formated date string: 'MM/DD/YYYY'
  sap_project_id?: string
  sign_id?: string
  sign_status?: SIGN_STATUS

  constructor({ projectSignList, pid }: Props) {
    const selectedSign = projectSignList.filter(
      (project: ProjectSignList) => project.sap_project_id === pid,
    )[0]

    this.set_date = selectedSign ? selectedSign?.set_date : ''
    this.sap_project_id = selectedSign ? selectedSign.sap_project_id : ''
    this.sign_id = selectedSign && selectedSign.sign_id
    this.sign_status = selectedSign && selectedSign.sign_status
  }
}
