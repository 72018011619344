import { KitScenarioStatus } from './KitScenarioStatus.model'

export class KitScenario {
  status: KitScenarioStatus
  no_adjustment?: boolean
  sign_roundup?: KitScenarioConfig[]
  max_kits?: KitScenarioConfig[]

  constructor(props: any = {}) {
    this.status = props.status || new KitScenarioStatus()
    this.no_adjustment = props.no_adjustment || undefined
    this.sign_roundup = props.sign_roundup || undefined
    this.max_kits = props.max_kits || undefined
  }
}

export class KitScenarioConfig {
  kit_vendor: string
  quantity?: number

  constructor(props: any = {}) {
    this.kit_vendor = props.kit_vendor
    this.quantity = props.quantity
  }
}
