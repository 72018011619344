import React from 'react'
import { Grid } from '@enterprise-ui/canvas-ui-react'
import { AgGridReact, AgGridColumn } from 'ag-grid-react'
import { useAppContext } from '../../../App/context/appContext'
import { useDashboardContext } from '../../context/dashboardContext'
import { useAgGridDashboardContext } from '../../context/agGridDashboardContext'
import { Column } from '../../../../models/agGrid/AgGridColumn.model'
import { PROJECT_MILESTONES_VIEW } from '../../../../models/agGrid/AgGridDashboardViewV2'
import { ErrorCard } from 'components/ErrorCard'

const ProjectMilestonesGrid = () => {
  const { setPageHasChanges } = useAppContext()!
  const { modifiedProjects, setModifiedProjects, dashboardErrors } =
    useDashboardContext()!
  const {
    onProjectMilestoneGridReady,
    gridOptions,
    onFirstDataRender,
    onFilterChange,
    onSortChanged,
  } = useAgGridDashboardContext()!

  const rowClassRules = {
    'ag-row-highlight': function (params: any) {
      return params.data?.dependency_complete === 'Yes'
    },
  }

  const handleCellValueChange = (e: any) => {
    setModifiedProjects([...modifiedProjects, e.data])
    setPageHasChanges(true)
    return (e.data.modified = true)
  }

  return (
    <Grid.Item xs={12}>
      <div className="ag-theme-rubix dashboard-table">
        {dashboardErrors.PROJECT_MILESTONE_SUMMARY_LIST ? (
          <ErrorCard
            text={`${dashboardErrors.PROJECT_MILESTONE_SUMMARY_LIST}. Please try again.`}
          />
        ) : (
          <AgGridReact
            gridOptions={gridOptions}
            onGridReady={onProjectMilestoneGridReady}
            rowModelType="serverSide"
            serverSideStoreType="partial"
            cacheBlockSize={50}
            onFirstDataRendered={onFirstDataRender}
            suppressRowClickSelection={true}
            rowClassRules={rowClassRules}
            enableRangeSelection
            enableFillHandle
            fillHandleDirection="y"
            onFilterChanged={(e) => onFilterChange(e)}
            onSortChanged={(e) => onSortChanged(e)}
            onCellValueChanged={(e: any) => handleCellValueChange(e)}
          >
            {PROJECT_MILESTONES_VIEW.map((column: Column, index: number) => (
              <AgGridColumn
                field={column.field}
                colId={column.colId}
                headerName={column.headerName}
                width={column.width || 200}
                type={column.type}
                filter={column.filter || 'agTextColumnFilter'}
                sort={column.sort}
                key={index}
              />
            ))}
          </AgGridReact>
        )}
      </div>
    </Grid.Item>
  )
}

export default ProjectMilestonesGrid
