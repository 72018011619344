import React, { useEffect } from 'react'
import { useAuth } from '@praxis/component-auth'
import { Dropdown } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { PlayIcon } from '@enterprise-ui/icons'
import { DialogProps } from '../../../../../models/app/DialogProps.model'
import { useAppContext } from 'components/App/context/appContext'
import { KitScenarioRequest } from '../../../../../models/kits/KitScenarioRequest.model'
import { useProjectDetailsContext } from '../../../context/projectDetailsContext'
import {
  RunKitScenarioProvider,
  useRunKitScenarioContext,
} from '../context/runKitScenarioContext'
import { get } from 'lodash'
import { KitScenario } from '../../../../../models/kits/KitScenario.model'
import { useNavigate } from 'react-router-dom'

export interface Props {
  disabled: boolean
}

const RunKitScenarioDropDownItem = ({ disabled }: Props) => {
  const { session } = useAuth()
  const username = get(session, 'userInfo.fullName', '')
  const { setDialogProps } = useAppContext()!
  const { currentProject } = useProjectDetailsContext()!
  const { runKitScenario, kitInfoResponse } = useRunKitScenarioContext()!
  const navigate = useNavigate()
  useEffect(() => {
    if (kitInfoResponse.id !== undefined) {
      navigate(
        `/projects/${currentProject.project_id}?tab=KITTIING/DISTRIBUTION`,
      )
      window.location.reload()
    }
  }, [kitInfoResponse, currentProject.project_id, navigate])

  return (
    <Dropdown.MenuItem
      className="sem_DropdownMenuItem--action divider"
      disabled={disabled}
      onClick={() => {
        setDialogProps(
          new DialogProps({
            headingText: `Are you sure you want to run default kit scenario?`,
            approveButtonText: 'Yes',
            onApprove: () => {
              const kitScenarioRequest = new KitScenarioRequest({
                project_id: currentProject.project_id,
                kit_scenario: new KitScenario({ no_adjustment: true }),
                updated_by: username,
                force: false,
                override_current_scenario: false,
              })
              runKitScenario(kitScenarioRequest)
              setDialogProps(new DialogProps())
            },
            refuseButtonText: 'Cancel',
            onRefuse: () => setDialogProps(new DialogProps()),
          }),
        )
      }}
    >
      <EnterpriseIcon className="hc-mr-normal" icon={PlayIcon} />
      Run Kit Scenario
    </Dropdown.MenuItem>
  )
}

const DropdownItemWrapper = (props: any) => (
  <RunKitScenarioProvider>
    <RunKitScenarioDropDownItem {...props} />
  </RunKitScenarioProvider>
)

export default DropdownItemWrapper
