import Axios from 'axios'
import React, { createContext, useContext } from 'react'
import { connect } from 'react-redux'
import {
  SEARCH_RESPONSE_SIZE,
  PROJECT_SERVICE_API_DOMAIN_URL,
  TOASTER_DEFAULTS,
} from '../../App/constants/appConstants'
import { useEnv } from '@praxis/component-runtime-env'
import { SearchRequest } from '../../../models/app/SearchRequest.model'
import { SearchResponse } from '../../../models/app/SearchResponse.model'
import { ProjectDetail } from '../../../models/projects/ProjectDetail.model'
import { ProjectSearchFacets } from '../../../models/projects/ProjectSearchFacets.model'
import { useProjectListContext } from './projectListContext'
import { useFilterContext } from '../../App/context/filterContext'
import { useToaster } from '@enterprise-ui/canvas-ui-react'

type ContextType = {
  setIncludeFilters: (filter: string, value: string[]) => void
  setExcludeFilters: (filter: string, value: string[]) => void
  setPartialFilters: (filter: string, value: string[]) => void
  setDateFilters: (id: string, date: string) => void
  refreshFiltersFromPyramid: (value: string[]) => void
  refreshFiltersFromDivision: (value: string[]) => void
  submitSort: (searchPath: string, direction: string) => void
}

export const ProjectFilterContext = createContext<ContextType | undefined>(
  undefined,
)

type Props = {
  children: React.ReactNode
}

export const ProjectFilterProviderComponent = ({ children }: Props) => {
  const { setProjectSearchResponse, submitProjectSearch } =
    useProjectListContext()!
  const { projectFilters, setProjectFilters } = useFilterContext()!
  const env = useEnv<any>()
  const makeToast = useToaster()

  const setIncludeFilters = (filter: string, value: string[]) => {
    setProjectFilters(
      new SearchRequest({
        ...projectFilters,
        include_filters: {
          ...projectFilters.include_filters,
          [filter]: value,
        },
      }),
    )
  }

  const setPartialFilters = (filter: string, value: string[]) =>
    setProjectFilters(
      new SearchRequest({
        ...projectFilters,
        partial_filters: {
          ...projectFilters.partial_filters,
          [filter]: value,
        },
      }),
    )

  const setDateFilters = (id: string, date: string) => {
    let rangeFilters
    switch (id) {
      case 'setDateFrom': {
        rangeFilters = {
          set_date: {
            ...projectFilters.range_filters?.set_date,
            from: date,
          },
        }
        break
      }
      case 'setDateTo': {
        rangeFilters = {
          set_date: {
            ...projectFilters.range_filters?.set_date,
            to: date,
          },
        }
        break
      }
    }

    setProjectFilters(
      new SearchRequest({
        ...projectFilters,
        range_filters: rangeFilters,
      }),
    )
  }

  const setExcludeFilters = (filter: string, value: string[]) => {
    setProjectFilters(
      new SearchRequest({
        ...projectFilters,
        exclude_filters: {
          ...projectFilters.exclude_filters,
          [filter]: value,
        },
      }),
    )
  }

  const refreshFiltersFromPyramid = async (facetValue: string[]) => {
    try {
      setProjectFilters(
        new SearchRequest({
          ...projectFilters,
          include_filters: {
            ...projectFilters.include_filters,
            [projectFilters.facets.pyramid]: facetValue,
            [projectFilters.facets.division]: [],
          },
        }),
      )

      const res = await Axios.post(
        `${
          env.apiDomainUrl + PROJECT_SERVICE_API_DOMAIN_URL
        }/projects/search?per_page=${SEARCH_RESPONSE_SIZE}`,
        new SearchRequest<ProjectSearchFacets>({
          facets: projectFilters.facets,
          include_filters: { [projectFilters.facets.pyramid]: facetValue },
        }),
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )

      setProjectSearchResponse(
        (previousState: SearchResponse<ProjectSearchFacets, ProjectDetail>) =>
          new SearchResponse({
            ...previousState,
            facets: {
              ...previousState.facets,
              division: res.data.facets.division,
              department: res.data.facets.department,
            },
          }),
      )
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Get Projects From Pyramid',
        message: err.response.data.message,
      })
    }
  }

  const refreshFiltersFromDivision = async (facetValue: string[]) => {
    setProjectFilters(
      new SearchRequest({
        ...projectFilters,
        include_filters: {
          ...projectFilters.include_filters,
          [projectFilters.facets.division]: facetValue,
          [projectFilters.facets.department]: [],
        },
      }),
    )

    try {
      const res = await Axios.post(
        `${
          env.apiDomainUrl + PROJECT_SERVICE_API_DOMAIN_URL
        }/projects/search?per_page=${SEARCH_RESPONSE_SIZE}`,
        new SearchRequest<ProjectSearchFacets>({
          facets: projectFilters.facets,
          include_filters: { [projectFilters.facets.division]: facetValue },
        }),
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      setProjectSearchResponse(
        (previousState: SearchResponse<ProjectSearchFacets, ProjectDetail>) =>
          new SearchResponse({
            ...previousState,
            facets: new ProjectSearchFacets({
              ...previousState.facets,
              department: res.data.facets.department,
            }),
          }),
      )
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Get Projects From Division',
        message: err.response.data.message,
      })
    }
  }

  const submitSort = async (searchPath: string, direction: string) => {
    const newFilters = {
      ...projectFilters,
      sort_fields: { [searchPath]: direction },
    }
    setProjectFilters(newFilters)
    submitProjectSearch(newFilters)
  }

  return (
    <ProjectFilterContext.Provider
      value={{
        setIncludeFilters,
        setExcludeFilters,
        setPartialFilters,
        setDateFilters,
        refreshFiltersFromPyramid,
        refreshFiltersFromDivision,
        submitSort,
      }}
    >
      {children}
    </ProjectFilterContext.Provider>
  )
}

export const useProjectFilterContext = () => useContext(ProjectFilterContext)

export const ProjectFilterProvider = connect(
  null,
  null,
)(ProjectFilterProviderComponent)
