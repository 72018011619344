import { get } from 'lodash'
import { KitGroupQuantity } from './DistributionResponse.model'

export default class SignBulkEditFields {
  on_pog_sign: boolean
  sign_status: boolean
  /* === sign info === */
  department?: BulkDepartmentInfo
  distribution?: BulkDistributionResponse
  end_date: Date
  non_retail_item_info?: BulkNonRetailItemInfo
  photography_needed: boolean
  printing?: BulkPrintingResponse
  pricing?: BulkPricingResponse
  sign_notes?: SignBulkNote[]
  separator: string
  sign_creator: string
  sign_language: string
  sign_type: string
  unit_of_measure: string
  set_quantity: number
  spt_sign_on_pog: boolean

  constructor(props: any = {}) {
    this.on_pog_sign = props.on_pog_sign
    this.sign_status = props.sign_status
    this.department = props.department
      ? new BulkDepartmentInfo(props.department)
      : undefined
    this.distribution = props.distribution
      ? new BulkDistributionResponse(props.distribution)
      : undefined
    this.end_date = props.end_date
    this.non_retail_item_info = props.non_retail_item_info
      ? new BulkNonRetailItemInfo(props.non_retail_item_info)
      : undefined
    this.photography_needed = props.photography_needed
    this.printing = props.printing
      ? new BulkPrintingResponse(props.printing)
      : undefined
    this.pricing = props.pricing
      ? new BulkPricingResponse(props.pricing)
      : undefined
    this.sign_notes = props.sign_notes
    this.separator = props.separator
    this.sign_creator = props.sign_creator
    this.sign_language = props.sign_language
    this.sign_type = props.sign_type
    this.unit_of_measure = props.unit_of_measure
    this.set_quantity = props.set_quantity
    this.spt_sign_on_pog = props.spt_sign_on_pog
  }
}

export class BulkDepartmentInfo {
  department_id: number
  department_name: string
  department_type_code: string
  division_id: number
  division_name: string
  group_id: number
  group_name: string
  sub_group_id: number
  sub_group_name: string

  constructor(props: any = {}) {
    this.department_id = props.department_id
    this.department_name = props.department_name
    this.department_type_code = props.department_type_code
    this.division_id = props.division_id
    this.division_name = props.division_name
    this.group_id = props.group_id
    this.group_name = props.group_name
    this.sub_group_id = props.sub_group_id
    this.sub_group_name = props.sub_group_name
  }
}

export class BulkNonRetailItemInfo {
  item_dimensions?: Dimensions
  nominal_dimensions?: Dimensions
  product_vendor: string

  constructor(props: any = {}) {
    this.item_dimensions = props.item_dimensions
      ? new Dimensions(props.item_dimensions)
      : undefined
    this.nominal_dimensions = props.nominal_dimensions
      ? new Dimensions(props.nominal_dimensions)
      : undefined
    this.product_vendor = props.product_vendor
  }
}

export class Dimensions {
  depth: string
  height: string
  width: string

  constructor(props: any = {}) {
    this.depth = props.depth
    this.height = props.height
    this.width = props.width
  }
}

export class BulkDistributionResponse {
  kitting_vendor: string
  kitting_vendor_id: string
  archway_quantity?: number
  quantity_per_store: number
  location_filter_info?: BulkLocationFilterCriteria
  kit_groups?: string[]
  kit_group_quantities?: KitGroupQuantity[]

  constructor(props: any = {}) {
    this.kitting_vendor = props.kitting_vendor
    this.kitting_vendor_id = props.kitting_vendor_id
    this.archway_quantity = props.archway_quantity
    this.quantity_per_store = props.quantity_per_store
    this.location_filter_info = props.location_filter_info
      ? new BulkLocationFilterCriteria(props.location_filter_info)
      : undefined
    this.kit_groups = props.kit_groups
    this.kit_group_quantities =
      props.kit_group_quantities &&
      get(props, 'kit_group_quantities').map(
        (kitGroup: any) => new KitGroupQuantity(kitGroup),
      )
  }
}

export class BulkLocationFilterCriteria {
  exclude_location_info?: BulkLocationInfo
  include_location_info?: BulkLocationInfo

  constructor(props: any = {}) {
    this.exclude_location_info = props.exclude_location_info
      ? new BulkLocationInfo(props.exclude_location_info)
      : undefined
    this.include_location_info = props.include_location_info
      ? new BulkLocationInfo(props.include_location_info)
      : undefined
  }
}

export class BulkLocationInfo {
  all_locations: boolean
  department_location_groups?: BulkDepartmentLocationGroup[]
  locations: string[]

  constructor(props: any = {}) {
    this.all_locations = props.all_locations
    this.department_location_groups =
      props.department_location_groups !== undefined &&
      props.department_location_groups.length > 0
        ? get(props, 'department_location_groups', [{}]).map(
            (item: any) => new BulkDepartmentLocationGroup(item),
          )
        : undefined
    this.locations = props.locations
  }
}

export class BulkDepartmentLocationGroup {
  department_id: string
  group_id: string
  sub_group_ids: string[]
  quantity: number

  constructor(props: any = {}) {
    this.department_id = props.department_id
    this.group_id = props.group_id
    this.sub_group_ids = props.sub_group_ids
    this.quantity = props.quantity
  }
}

export class BulkPrintingResponse {
  coating: string
  die_cut: string
  finishing: string
  number_of_colors: number
  sides_print: boolean
  planned_sides: number
  pre_assembled: boolean
  file_approved: boolean
  print_vendor: string
  print_vendor_id: string
  substrate1: string
  substrate2: string

  constructor(props: any = {}) {
    this.coating = props.coating
    this.die_cut = props.die_cut
    this.finishing = props.finishing
    this.number_of_colors = props.number_of_colors
    this.sides_print = props.sides_print
    this.planned_sides = props.planned_sides
    this.pre_assembled = props.pre_assembled
    this.file_approved = props.file_approved
    this.print_vendor = props.print_vendor
    this.print_vendor_id = props.print_vendor_id
    this.substrate1 = props.substrate1
    this.substrate2 = props.substrate2
  }
}

export class SignBulkNote {
  author: string
  timestamp: Date
  content: string
  content_type: string

  constructor(props: any = {}) {
    this.author = props.author || ''
    this.timestamp = props.timestamp
    this.content = props.content || ''
    this.content_type = props.content_type
  }
}

export class BulkPricingResponse {
  estimated_quantity?: number
  estimated_unit_price?: number
  final_unit_price?: number
  po_number: string

  constructor(props: any = {}) {
    this.estimated_quantity = props.estimated_quantity
      ? parseInt(props.estimated_quantity)
      : undefined
    this.estimated_unit_price = props.estimated_unit_price
      ? parseFloat(props.estimated_unit_price)
      : undefined
    this.final_unit_price = props.final_unit_price
      ? parseFloat(props.final_unit_price)
      : undefined
    this.po_number = props.po_number
  }
}
