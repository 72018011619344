import React from 'react'

export interface Props {
  value: string
}

const BudgetText = ({ value = '' }: Props) => {
  return <div>{value === undefined ? '' : '$' + value.toLocaleString()}</div>
}

export default BudgetText
