import React, { Fragment } from 'react'
import { Button, Form, Grid } from '@enterprise-ui/canvas-ui-react'
import { useUserContext } from '../../../App/context/userContext'
import { useAdminUploadContext } from '../context/adminUploadContext'
import { CampaignDetail } from '../../../../models/campaigns/CampaignDetail.model'
import { useNewAdminCampaignForm } from '../hooks/useNewAdminCampaignForm'
import { v4 as uuidv4 } from 'uuid'

const CreateAdminCampaignForm = () => {
  const { userName } = useUserContext()!
  const { selectedCampaignYear, createNewCampaign, closeCampaignForm } =
    useAdminUploadContext()!

  const newCampaignDetail = new CampaignDetail({
    id: uuidv4(),
    campaign_id: '',
    campaign_type: '',
    year: selectedCampaignYear,
    active: true,
    fiscal_year_start_date: new Date().toISOString(),
    fiscal_year_end_date: new Date().toISOString(),
    campaign_plan_start_date: new Date().toISOString(),
    campaign_plan_end_date: new Date().toISOString(),
    created_by: userName,
    created_date: new Date().toISOString(),
    updated_by: userName,
    updated_date: new Date().toISOString(),
  })
  const { setFieldValue, handleSubmit, values, errors } =
    useNewAdminCampaignForm(
      newCampaignDetail,
      createNewCampaign,
      closeCampaignForm,
    )

  return (
    <Fragment>
      <Form>
        <Grid.Container className="sem-CreateMilestoneForm" align="flex-end">
          <Grid.Item className="hc-pb-none" xs={12}></Grid.Item>
          <Grid.Item className="hc-pv-dense" xs>
            <Form.Field
              id="year"
              label="Campaign Year"
              value={newCampaignDetail.year}
              disabled
              error={errors.year !== undefined}
            />
          </Grid.Item>
          <Grid.Item className="hc-pv-dense" xs>
            <Form.Field
              id="campaignId"
              label="Campaign ID"
              value={values.campaign_id}
              error={errors.campaign_id !== undefined}
              onChange={(e: any) =>
                setFieldValue('campaign_id', e.target.value)
              }
              required
            />
          </Grid.Item>
          <Grid.Item className="hc-pv-dense" xs={12}>
            <Form.Field
              id="campaignType"
              label="Campaign Type"
              value={values.campaign_type}
              error={errors.campaign_type !== undefined}
              onChange={(e: any) =>
                setFieldValue('campaign_type', e.target.value)
              }
              required
            />
          </Grid.Item>
          <Grid.Item xs={6}>
            <Form.Field
              type="date"
              label="Fiscal Year Start Date"
              id="Fiscal Year Start Date"
              value={values.fiscal_year_start_date}
              onChange={(event: any) => {
                setFieldValue('fiscal_year_start_date', event.target.value)
              }}
              location="bottom-left"
            />
          </Grid.Item>
          <Grid.Item xs={6}>
            <Form.Field
              type="date"
              label="Fiscal Year End Date"
              id="Fiscal Year End Date"
              value={values.fiscal_year_end_date}
              onChange={(event: any) => {
                setFieldValue('fiscal_year_end_date', event.target.value)
              }}
              location="bottom-left"
            />
          </Grid.Item>
          <Grid.Item xs={6}>
            <Form.Field
              type="date"
              label="Campaign Plan Start Date"
              id="Campaign Plan Start Date"
              value={values.campaign_plan_start_date}
              onChange={(event: any) => {
                setFieldValue('campaign_plan_start_date', event.target.value)
              }}
              location="bottom-left"
            />
          </Grid.Item>
          <Grid.Item xs={6}>
            <Form.Field
              type="date"
              label="Campaign Plan End Date"
              id="campaign_plan_end_date"
              value={values.campaign_plan_end_date}
              onChange={(event: any) => {
                setFieldValue('campaign_plan_end_date', event.target.value)
              }}
              location="bottom-left"
            />
          </Grid.Item>
          <Grid.Item xs={12}>
            <Button
              fullWidth
              type="primary"
              className="save-Button"
              onClick={() => handleSubmit()}
            >
              Add Campaign
            </Button>
          </Grid.Item>
        </Grid.Container>
      </Form>
    </Fragment>
  )
}

export default CreateAdminCampaignForm
