import { SIGN_STATUS } from '../../components/App/constants/appConstants'
import ProjectSignList from './ProjectSignList.model'

export default class MostRecentProjectSignInfo {
  set_date?: string // formated date string: 'MM/DD/YYYY'
  sap_project_id?: string
  sign_id?: string
  sign_status?: SIGN_STATUS

  constructor(props: ProjectSignList[] = [new ProjectSignList()]) {
    const filteredProps = props
      .filter((project: ProjectSignList) => project.sap_project_id)
      .filter(
        (project: ProjectSignList) =>
          project.sign_status !== SIGN_STATUS.REMOVE,
      )
    const mostRecentSign =
      filteredProps.length === 0
        ? undefined
        : filteredProps.length === 1
        ? filteredProps[0]
        : filteredProps.sort((a: ProjectSignList, b: ProjectSignList) =>
            b.set_date > a.set_date ? -1 : 1,
          )[0]

    this.set_date = mostRecentSign ? mostRecentSign?.set_date : ''
    this.sap_project_id = mostRecentSign ? mostRecentSign.sap_project_id : ''
    this.sign_id = mostRecentSign && mostRecentSign.sign_id
    this.sign_status = mostRecentSign && mostRecentSign.sign_status
  }
}
