import React, { Fragment, useEffect } from 'react'
import { Card, Grid } from '@enterprise-ui/canvas-ui-react'
import MainSectionHeader from '../../App/components/MainSectionHeader'
import ProjectListTable from './ProjectListTable'
import ProjectFilterCard from './ProjectFilterCard'
import { useProjectListContext } from '../context/projectListContext'
import InfiniteScroll from '../../App/components/InfiniteScroll'
import { useAppContext } from '../../App/context/appContext'
import { useFilterContext } from '../../App/context/filterContext'

export interface Props {
  isModal?: boolean
  closeModal?: Function
}

const ProjectSearch = ({ isModal = false, closeModal }: Props) => {
  const {
    submitProjectSearch,
    projectSearchResponse,
    isLoadingMore,
    setIsLoadingMore,
    handleNewResults,
    loadMoreProjects,
  } = useProjectListContext()!

  const { projectFilters } = useFilterContext()!
  const { fullPageLoadingMessage } = useAppContext()!

  useEffect(() => {
    submitProjectSearch(projectFilters)
    // Need this so that useEffect only runs on page load
    // eslint-disable-next-line
  }, [submitProjectSearch])

  return (
    <Fragment>
      <MainSectionHeader
        sectionTitle="Project Library"
        isModal={isModal}
        closeModal={closeModal}
      />
      <Card className="hc-ph-normal">
        <Grid.Container>
          <Grid.Item xs={12} className="hc-mb-expanded">
            <ProjectFilterCard />
          </Grid.Item>
        </Grid.Container>
        <Grid.Container>
          <Grid.Item xs={12}>
            <ProjectListTable />
          </Grid.Item>
        </Grid.Container>
      </Card>

      <InfiniteScroll
        isAlreadyLoading={isLoadingMore || fullPageLoadingMessage !== ''}
        shouldShowSpinner={isLoadingMore}
        setIsLoadingMore={setIsLoadingMore}
        currentPageNumber={projectSearchResponse.page}
        totalNumberOfElements={projectSearchResponse.total_results}
        handleNewResults={handleNewResults}
        loadMore={() => loadMoreProjects(projectFilters)}
      />
    </Fragment>
  )
}

export default ProjectSearch
