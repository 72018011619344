import SignBulkEditFields from '../../../models/signs/SignBulkEditFields.model'
import { POST_KIT_FINALIZATION } from '../../App/constants/appConstants'

export const validateNoBulkExceptions = (
  fields: SignBulkEditFields,
  status: string,
) => {
  let keys = getObjectKeys(fields)
  const exceptedFields = [
    'pricing',
    'distribution',
    'spt_sign_on_pog',
    'on_pog_sign',
  ]
  const keyChecks = keys.map((key: string) => {
    if (exceptedFields.includes(key)) {
      switch (key) {
        case 'pricing': {
          return false
        }
        case 'distribution': {
          if (POST_KIT_FINALIZATION.includes(status)) {
            return true
          } else {
            return false
          }
        }
        case 'spt_sign_on_pog': {
          if (POST_KIT_FINALIZATION.includes(status)) {
            return true
          } else {
            return false
          }
        }
        case 'on_pog_sign': {
          if (POST_KIT_FINALIZATION.includes(status)) {
            return true
          } else {
            return false
          }
        }
        default: {
          return true
        }
      }
    } else {
      return true
    }
  })
  return keyChecks.some((check: boolean) => check)
}

const getObjectKeys = (fields: SignBulkEditFields) => {
  return Object.keys(fields).filter(
    (key: string) => (fields as any)[key] !== undefined,
  )
}
