import React from 'react'
import {
  Grid,
  Heading,
  ExpandableSection,
} from '@enterprise-ui/canvas-ui-react'
import ProductHighlights from './ProductHighlights'
import ProductSpecifications from './ProductSpecifications'
import { decode } from 'he'
import { useSignPreviewContext } from '../../../context/signPreviewContext'

export interface Props {
  isMpp: boolean
}

const ProductInfo = ({ isMpp }: Props) => {
  const { productInfoList } = useSignPreviewContext()!

  return (
    <Grid.Container>
      {isMpp ? (
        productInfoList.map((productInfo, index) => (
          <Grid.Item xs={12} key={index}>
            <ExpandableSection>
              <Heading className="hc-clr-grey01" size={6}>
                Product {index + 1}:{' '}
                {decode(productInfo.product_description.title)}
              </Heading>
              <ExpandableSection.Content>
                <Grid.Container className="hc-pl-expanded hc-pt-expanded">
                  <Grid.Item xs={12}>
                    <ProductHighlights productInfo={productInfo} />
                  </Grid.Item>
                  <Grid.Item xs={12}>
                    <ProductSpecifications productInfo={productInfo} />
                  </Grid.Item>
                </Grid.Container>
              </ExpandableSection.Content>
            </ExpandableSection>
          </Grid.Item>
        ))
      ) : productInfoList[0] ? (
        <Grid.Item xs={12}>
          <Heading className="hc-clr-grey02" size={6}>
            {decode(productInfoList[0].product_description.title)}
          </Heading>
          <Grid.Container className="hc-pl-expanded hc-pt-expanded">
            <Grid.Item xs={12}>
              <ProductHighlights productInfo={productInfoList[0]} />
            </Grid.Item>
            <Grid.Item xs={12}>
              <ProductSpecifications productInfo={productInfoList[0]} />
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
      ) : null}
    </Grid.Container>
  )
}

export default ProductInfo
