import React from 'react'
import { Grid, Divider } from '@enterprise-ui/canvas-ui-react'
import { RosterUser } from '../../../../../models/roster/RosterUser.model'
import EditableRoster from '../../../../Roster/components/EditableRoster'
import { RosterElement } from '../../../../../models/roster/RosterElement.model'
import ProjectFormCard from './ProjectFormCard'
import { useProjectDetailsContext } from '../../../context/projectDetailsContext'
import { useUserContext } from '../../../../App/context/userContext'

const ProjectDetailsTab = () => {
  const {
    currentProject,
    setCurrentProject,
    deleteFromRoster,
    saveProjectRoster,
  } = useProjectDetailsContext()!
  const { userPermissions, userId } = useUserContext()!

  const addToRoster = (user: RosterUser, userRole: string) => {
    setCurrentProject({
      ...currentProject,
      roster: currentProject.roster.map((rosterElement: RosterElement) => {
        return new RosterElement({
          type: rosterElement.type,
          title: rosterElement.title,
          users:
            rosterElement.title === userRole
              ? [...rosterElement.users, user]
              : rosterElement.users,
        })
      }),
    })
  }

  return (
    <Grid.Container className="hc-pb-expanded">
      <Grid.Item xs={8}>
        <ProjectFormCard />
      </Grid.Item>
      <Divider className="hc-pt-normal" direction="vertical" />
      <Grid.Item xs={true} className="sem-BpRosterTab open">
        <EditableRoster
          currentProject={currentProject}
          roster={currentProject.roster}
          deleteFromRoster={deleteFromRoster}
          saveRoster={() =>
            saveProjectRoster(
              currentProject.project_id,
              currentProject.roster,
              userId,
            )
          }
          addToRoster={addToRoster}
          hasEditPermission={userPermissions.isProjectEditor}
          resetTrigger={currentProject.name}
        />
      </Grid.Item>
    </Grid.Container>
  )
}

export default ProjectDetailsTab
