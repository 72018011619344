import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import moment from 'moment'
import { Form, Grid } from '@enterprise-ui/canvas-ui-react'
import { Column } from 'ag-grid-community'

export interface Props {
  value: string | null
  column: Column
}

const DateCellEditor = forwardRef(({ value, column }: Props, ref) => {
  const [editValue, setEditValue] = useState(value)
  const [dateError, setDateError] = useState(false)
  const minDate = moment(new Date()).add(1, 'd').format('YYYY-MM-DD')

  const refInput = useRef<HTMLInputElement>(null)

  const handleChange = (newDate: string | null) => {
    if (moment(newDate).isBefore(minDate)) {
      setDateError(true)
      newDate = null
    }
    setEditValue(newDate)
  }

  useEffect(() => {
    setTimeout(() => refInput?.current?.focus())
  }, [])

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return editValue && moment(editValue).format('YYYY-MM-DD')
      },
    }
  })

  return (
    <div className="hc-pa-dense">
      <Grid.Container className="sem_StandardForm error_messaging_enabled hc-pb-dense">
        <Grid.Item xs={12}>
          <Form.Field
            id="end_date_editor"
            ref={refInput}
            type="date"
            label={column.getColDef().headerName}
            value={editValue && moment(editValue).format('YYYY-MM-DD')}
            onChange={(e: any) => handleChange(e.target.value)}
            min={minDate}
            hintText="End Date must be blank or a future date"
            error={dateError}
            errorText="End Date must be set for a future date"
            size="dense"
          />
        </Grid.Item>
      </Grid.Container>
    </div>
  )
})

export default DateCellEditor
