import Axios from 'axios'
import React, { createContext, useState, useContext, useCallback } from 'react'
import { connect } from 'react-redux'
import {
  ADMIN_SERVICE_API_DOMAIN_URL,
  SIGN_SERVICE_API_DOMAIN_URL,
  TOASTER_DEFAULTS,
} from '../../App/constants/appConstants'
import { useEnv } from '@praxis/component-runtime-env'
import MscGroupsFacets from '../../../models/signs/MscGroupsFacets.model'
import SharedMscFacets from '../../../models/signs/SharedMscFacets.model'
import { Subject, scan } from 'rxjs'
import { merge, set } from 'lodash'
import { useToaster } from '@enterprise-ui/canvas-ui-react'

type ContextType = {
  isLoading: boolean
  isModalListLoading: boolean
  refreshSharedMscFacets: () => void
  sharedMscFacets: SharedMscFacets
  refreshStoreGroups: (type: string, dept_id: string, index: number) => void
  refreshSubGroups: (
    type: string,
    departmentId: string,
    departmentGroupId: string,
    index: number,
  ) => void
  includeFacets: MscGroupsFacets[]
  excludeFacets: MscGroupsFacets[]
}

export const MscLocationsContext = createContext<ContextType | undefined>(
  undefined,
)

type Props = {
  children: React.ReactNode
}

export const subject$ = new Subject()

export const deptGroupData$ = subject$.pipe(scan(merge))

const MscLocationsProviderComponent = ({ children }: Props) => {
  const env = useEnv()
  const makeToast = useToaster()
  const [isLoading, setIsLoading] = useState(false)
  const [isModalListLoading, setIsModalListLoading] = useState(false)
  const [sharedMscFacets, setSharedMscFacets] = useState(new SharedMscFacets())
  const [includeFacets, setIncludeFacets] = useState<MscGroupsFacets[]>([])
  const [excludeFacets, setExcludeFacets] = useState<MscGroupsFacets[]>([])

  const refreshSharedMscFacets = useCallback(async () => {
    setIsLoading(true)
    try {
      const locs: any = await Axios.get(
        `${env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL}/locations/all`,
      )
      const depts: any = await Axios.get(
        `${
          env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
        }/merchandise_hierarchy/departments`,
      )
      const locations = locs.data.sort(
        (a: any, b: any) => a.location_id - b.location_id,
      )
      const departments = depts.data.sort(
        (a: any, b: any) => a.department_id - b.department_id,
      )
      subject$?.next({ departments })
      setSharedMscFacets(
        new SharedMscFacets({
          locations,
          departments,
        }),
      )
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Get MSC Locations or Departments',
        message: err.response.data.message,
      })
    }
    setIsLoading(false)
  }, [makeToast, env.apiDomainUrl])

  const refreshStoreGroups = useCallback(
    async (category: string, dept_id: string, groupIndex: number) => {
      setIsModalListLoading(true)
      try {
        const res: any = await Axios.get(
          `${
            env.apiDomainUrl + SIGN_SERVICE_API_DOMAIN_URL
          }/location_groups/${dept_id}/groups`,
        )
        const store_groups = res.data.sort(
          (a: any, b: any) => a.group_id - b.group_id,
        )

        subject$.next({
          store_groups: set(
            {},
            `${category}Facets[${groupIndex}]`,
            store_groups,
          ),
        })
        category === 'include'
          ? setIncludeFacets([
              ...Array(groupIndex),
              new MscGroupsFacets({
                store_groups,
              }),
            ])
          : setExcludeFacets([
              ...Array(groupIndex),
              new MscGroupsFacets({
                store_groups,
              }),
            ])
      } catch (err: any) {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'error',
          heading: 'Failed to Get Store Groups',
          message: err.response.data.message,
        })
      }
      setIsModalListLoading(false)
    },
    [makeToast, env.apiDomainUrl],
  )

  const refreshSubGroups = useCallback(
    async (
      category: string,
      departmentId: string,
      departmentGroupId: string,
      groupIndex: number,
    ) => {
      setIsModalListLoading(true)
      try {
        const res: any = await Axios.get(
          `${
            env.apiDomainUrl + SIGN_SERVICE_API_DOMAIN_URL
          }/location_groups/${departmentId}/groups/${departmentGroupId}/sub_groups`,
        )
        const sub_groups = res.data.sort(
          (a: any, b: any) => a.group_id - b.group_id,
        )

        subject$.next({
          sub_groups: set({}, `${category}Facets[${groupIndex}]`, sub_groups),
        })
        category === 'include'
          ? setIncludeFacets([
              ...Array(groupIndex),
              new MscGroupsFacets({
                sub_groups,
              }),
            ])
          : setExcludeFacets([
              ...Array(groupIndex),
              new MscGroupsFacets({
                sub_groups,
              }),
            ])
      } catch (err: any) {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'error',
          heading: 'Failed to Get Store Sub Groups',
          message: err.response.data.message,
        })
      }
      setIsModalListLoading(false)
    },
    [makeToast, env.apiDomainUrl],
  )

  return (
    <MscLocationsContext.Provider
      value={{
        isLoading,
        isModalListLoading,
        refreshSharedMscFacets,
        sharedMscFacets,
        refreshStoreGroups,
        refreshSubGroups,
        includeFacets,
        excludeFacets,
      }}
    >
      {children}
    </MscLocationsContext.Provider>
  )
}

export const MscLocationsProvider = connect(
  null,
  null,
)(MscLocationsProviderComponent)

export const useMscLocationsContext = () => useContext(MscLocationsContext)
