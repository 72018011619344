import { SIGN_PREVIEW_TYPES } from 'components/SignDetails/constants/signMessagingFacets'
import appConfig from 'config/appConfig'
import { PreviewRequest } from 'models/signs/PreviewRequest.model'
import SignResponse from 'models/signs/SignResponse.model'
import _ from 'lodash'
import axios from 'axios'
import {
  ARRAY_BUFFER_CONFIG,
  SIGN_SERVICE_API_DOMAIN_URL,
} from 'components/App/constants/appConstants'
import { generatePreview } from 'components/SignDetails/helpers/previewHelper'

export const signHasPreview = (sign: SignResponse) =>
  (sign.sign_type === SIGN_PREVIEW_TYPES.DISPLAY ||
    sign.sign_type === SIGN_PREVIEW_TYPES.VALUE) &&
  sign.messaging.sign_size !== 'OTHER' &&
  sign.messaging.sign_size !== ''

export const getPreview = (sign: SignResponse) => {
  const prevReq = new PreviewRequest({
    set_date: sign.set_date,
    sign_type: sign.sign_type,
    sign_id: sign.sign_id,
    sign_dpci: _.get(sign, 'non_retail_item_info.dpci', ''),
    sign_tcin: _.get(sign, 'non_retail_item_info.tcin', ''),
    sign_barcode_number: _.get(sign, 'non_retail_item_info.upc', ''),
    locations: ['3'],
    ...sign.messaging,
    item_list: sign.messaging.item_info,
  })

  return generatePreview(prevReq)
}

export const getPreviewImageData = async (previewData: PreviewRequest) => {
  const config = await appConfig()
  const res = await axios.post(
    `${config.apiDomainUrl + SIGN_SERVICE_API_DOMAIN_URL}/preview_ess_signs`,
    previewData,
    // Response data will come back as an ArrayBuffer
    ARRAY_BUFFER_CONFIG,
  )

  const blob = new Blob([new Uint8Array(res.data)], { type: 'application/pdf' })
  const image = new File([blob], 'image.png', { type: 'application/pdf' })

  const imageUrl = await new Promise((resolve) => {
    const reader = new FileReader()

    reader.addEventListener(
      'load',
      () => {
        // convert image file to base64 string
        resolve(reader.result)
      },
      false,
    )

    if (image) {
      reader.readAsDataURL(image)
    }
  })

  return imageUrl
}
