import React from 'react'
import { Link } from 'react-router-dom'
import { Anchor } from '@enterprise-ui/canvas-ui-react'
import SignResponse from '../../../../../../../models/signs/SignResponse.model'

export interface Props {
  value: string
  data: SignResponse
}

const SignLinkDetail = ({ data, value }: Props) => {
  return (
    <div className="ag-cell-value">
      <Anchor
        as={Link}
        to={`/projects/${data?.project_id}/signs/${data?.sign_id}`}
      >
        {value}
      </Anchor>
    </div>
  )
}

export default SignLinkDetail
