import React from 'react'
import { Chip } from '@enterprise-ui/canvas-ui-react'
import { ICellRendererParams } from 'ag-grid-community'
import {
  getSignItemStatusChipColor,
  getSignStatusChipColor,
} from 'components/App/helpers/statusCalculation'

const SignStatusChip = (params: ICellRendererParams) =>
  params.value ? (
    <Chip
      size="dense"
      // @ts-ignore
      color={
        params.column?.getColId() === 'non_retail_item_info.sign_item_status'
          ? getSignItemStatusChipColor(params.value)
          : getSignStatusChipColor(params.value)
      }
    >
      {params.value}
    </Chip>
  ) : null

export default SignStatusChip
