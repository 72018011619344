import { KitComments } from './KitComments.model'
import { KitReceiveDates } from './KitReceiveDates.model'
import { KitSignResponse } from './KitSignResponse.model'

export enum KitStatus {
  CREATED = 'CREATED',
  FINALIZED = 'FINALIZED',
  DELETED = 'DELETED',
}

export class Carton {
  length?: number
  width?: number
  height?: number
  weight?: number

  constructor(props: any = {}) {
    this.length = props.length
    this.width = props.width
    this.height = props.height
    this.weight = props.weight
  }
}

export class KitResponse {
  kit_id: string
  kit_version: string
  kit_name: string
  kit_vendor: string
  kit_quantity?: number
  shipping_method?: string
  carton?: Carton
  stores?: string[]
  signs: KitSignResponse[]
  status: KitStatus
  pre_assembled: boolean
  oversized: boolean
  comments?: KitComments
  receive_dates?: KitReceiveDates
  created_by: string
  created_date: string
  updated_by: string
  updated_date: string

  constructor(props: any = {}) {
    this.kit_id = props.kit_id
    this.kit_version = props.kit_version
    this.kit_name = props.kit_name
    this.kit_vendor = props.kit_vendor
    this.kit_quantity = props.kit_quantity
    this.shipping_method = props.shipping_method
    this.carton = props.carton
    this.stores = props.stores
    this.signs = props.signs
    this.status = props.status
    this.pre_assembled = props.pre_assembled
    this.oversized = props.oversized
    this.comments = props.comments
    this.receive_dates = props.receive_dates
    this.created_by = props.created_by
    this.created_date = props.created_date
    this.updated_by = props.updated_by
    this.updated_date = props.updated_date
  }
}
