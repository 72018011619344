import { get } from 'lodash'
import {
  MostRecentInfo,
  NonRetailItemInfo,
  ProjectSignList,
  SelectedSignInfo,
} from './'

export default class SignLibraryResponse {
  id: string
  selected_project_sign_info: MostRecentInfo | SelectedSignInfo
  non_retail_item_info: NonRetailItemInfo
  project_sign_info_list: ProjectSignList[]

  constructor(props: any = {}) {
    this.id = props.id || ''
    this.selected_project_sign_info = props.pidFilter
      ? new SelectedSignInfo({
          projectSignList: props.project_sign_info_list,
          pid: props.pidFilter,
        })
      : new MostRecentInfo(props.project_sign_info_list)
    this.non_retail_item_info = new NonRetailItemInfo(
      props.non_retail_item_info,
    )
    this.project_sign_info_list = get(props, 'project_sign_info_list', [])
      .map((item: any) => new ProjectSignList(item))
      .filter(
        (projectSignItem: ProjectSignList) => projectSignItem.sap_project_id,
      )
  }
}
