import { get } from 'lodash'
import { RosterElement } from './RosterElement.model'

export class RosterRequest {
  roster: RosterElement[]
  updated_by: string

  constructor(props: any = {}) {
    this.roster = get(props, 'roster', []).map(
      (rosterElement: any) => new RosterElement(rosterElement),
    )
    this.updated_by = props.updated_by || ''
  }
}
