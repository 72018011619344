import React, { useState, useEffect } from 'react'
import { Modal, Button, Grid } from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { useProjectDistributionContext } from '../context/projectDistributionContext'
import { KitResponse } from '../../../../../models/kits/KitResponse.model'
import { useProjectDetailsContext } from '../../../context/projectDetailsContext'

const MergeKits = () => {
  const {
    isMergeKitsOpen,
    setIsMergeKitsOpen,
    mergeKits,
    kitInfoResponse,
    downloadMergePreview,
  } = useProjectDistributionContext()!
  const { currentProject } = useProjectDetailsContext()!
  const [kitVendorOptions, setKitVendorOptions] = useState<any>([])
  const [kitOptions, setKitOptions] = useState<any>([])
  const [selectedKits, setSelectedKits] = useState<any[]>([])
  const [kitValue, setKitValue] = useState('')

  useEffect(() => {
    if (kitInfoResponse.kits !== undefined) {
      const kitVendors = kitInfoResponse.kits.map(
        (kit: KitResponse) => kit.kit_vendor,
      )
      const uniqueKitVendors = Array.from(new Set(kitVendors))
      setKitVendorOptions(
        uniqueKitVendors.map((id: string) => {
          return { id: id, value: id, label: id }
        }),
      )
    }
  }, [kitInfoResponse.kits])

  const handleKitVendorChange = (kitVendor: string) => {
    if (kitInfoResponse.kits !== undefined) {
      const kitNumbers = kitInfoResponse.kits.filter(
        (kit: KitResponse) => kit.kit_vendor === kitVendor,
      )
      setKitOptions(
        kitNumbers.map((kit: KitResponse) => {
          return { id: kit.kit_id, value: kit.kit_id, label: kit.kit_id }
        }),
      )
    }
  }

  return (
    <Modal
      isVisible={isMergeKitsOpen}
      onRefuse={() => setIsMergeKitsOpen(false)}
      headingText="Merge Kits"
    >
      <div className="hc-pa-normal">
        <p className="hc-clr-error hc-pb-normal">
          Two or more kits from this project must be entered in order to merge.
        </p>
        <Grid.Container>
          <Grid.Item xs={10}>
            <Autocomplete
              id="kitIds"
              className="hc-mb-dense"
              label="Kit Vendor"
              required
              options={kitVendorOptions}
              onUpdate={(id: string, value: any) => {
                if (!value) {
                  setKitValue('')
                } else {
                  handleKitVendorChange(value.value)
                  setKitValue(value.value)
                }
              }}
              value={{
                id: kitValue,
                label: kitValue,
                value: kitValue,
              }}
            />
          </Grid.Item>
          <Grid.Item xs={10}>
            <Autocomplete
              id="kitNumbers"
              data-testid="kitNumbers"
              multiselect
              className="hc-mb-dense"
              label="Kits to Merge"
              required
              disabled={kitOptions.length === 0}
              options={kitOptions}
              onUpdate={(id: string, value: any) => {
                setSelectedKits(value)
              }}
              value={selectedKits}
            />
          </Grid.Item>
        </Grid.Container>
        <Grid.Container className="hc-pv-expanded" justify="center">
          <Grid.Item xs={true} className="hc-pl-expanded">
            <Button
              fullWidth
              type="secondary"
              disabled={selectedKits.length < 2}
              onClick={() =>
                downloadMergePreview(
                  selectedKits.map((selectedKit: any) => selectedKit.value),
                  `${currentProject.sap_project_id}_${currentProject.name}_PSF_Preview.xlsx`,
                )
              }
            >
              Preview
            </Button>
          </Grid.Item>
          <Grid.Item xs={true} className="hc-pr-expanded">
            <Button
              fullWidth
              type="primary"
              disabled={selectedKits.length < 2}
              onClick={() => {
                mergeKits(
                  selectedKits.map((selectedKit: any) => selectedKit.value),
                )
                setIsMergeKitsOpen(false)
              }}
            >
              Merge
            </Button>
          </Grid.Item>
        </Grid.Container>
      </div>
    </Modal>
  )
}

export default MergeKits
