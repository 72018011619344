import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { Grid } from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { useAddOnContext } from 'components/ProjectDetails/components/AddOnExpenses/context/addOnContext'

export interface Props {
  value: string
}

const AutocompleteEditor = forwardRef(({ value }: Props, ref) => {
  const { printVendorOptions } = useAddOnContext()!
  const [editValue, setEditValue] = useState(value)

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return editValue
      },
      isPopup() {
        return true
      },
    }
  })

  const handleChange = (id: string, value: string) => setEditValue(value)

  return (
    <div className="hc-pa-dense">
      <Grid.Container className="sem_StandardForm error_messaging_enabled wide-form">
        <Grid.Item xs={12}>
          <Autocomplete
            label="Print Vendor"
            value={{
              value: editValue,
              label: editValue,
            }}
            options={printVendorOptions}
            onUpdate={(id: string, value: any) => {
              if (value) handleChange(id, value.value)
            }}
          />
        </Grid.Item>
      </Grid.Container>
    </div>
  )
})

export default AutocompleteEditor
