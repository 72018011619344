import Axios from 'axios'
import React, { createContext, useState, useContext, useCallback } from 'react'
import moment from 'moment'
import { BlueprintDetail } from '../../../../../models/blueprints/BlueprintDetail.model'
import { RosterElement } from '../../../../../models/roster/RosterElement.model'
import { RosterUser } from '../../../../../models/roster/RosterUser.model'
import { useBlueprintDetailsContainerContext } from '../../../context/blueprintDetailsContainerContext'
import { BlueprintCampaign } from '../../../../../models/blueprints/BlueprintCampaign.model'
import {
  ADMIN_SERVICE_API_DOMAIN_URL,
  PROJECT_SERVICE_API_DOMAIN_URL,
  TOASTER_DEFAULTS,
} from 'components/App/constants/appConstants'
import { cloneDeep, remove } from 'lodash'
import { connect } from 'react-redux'
import { useEnv } from '@praxis/component-runtime-env'
import { useAppContext } from 'components/App/context/appContext'
import { BlueprintRequest } from '../../../../../models/blueprints/BlueprintRequest.model'
import { useToaster } from '@enterprise-ui/canvas-ui-react'

type ContextType = {
  getCampaignList: (date: string) => void
  isBlueprintModified: boolean
  setIsBlueprintModified: (isModified: boolean) => void
  campaignList: BlueprintCampaign[]
  setCampaignList: (list: BlueprintCampaign[]) => void
  isCampaignListLoading: boolean
  setIsCampaignListLoading: (isModified: boolean) => void
  deleteFromRoster: (user: RosterUser, title: string) => void
  updateBlueprint: (id: string, blueprint: BlueprintRequest) => void
  createBlueprint: (blueprint: BlueprintRequest) => void
  handleCampaignChange: (
    campaignId: string,
    blueprintFormValues: BlueprintRequest,
  ) => void
  saveBlueprintRoster: (
    id: string,
    roster: RosterElement[],
    updatedBy: string,
  ) => void
}

export const BlueprintDetailsTabContext = createContext<
  ContextType | undefined
>(undefined)

type Props = {
  children: React.ReactNode
}

export const BlueprintDetailsTabProviderComponent = ({ children }: Props) => {
  const { setFullPageLoadingMessage, setPageHasChanges } = useAppContext()!
  const { currentBlueprint, setCurrentBlueprint } =
    useBlueprintDetailsContainerContext()!
  const env = useEnv()
  const makeToast = useToaster()

  const [isBlueprintModified, setIsBlueprintModified] = useState(false)
  const [campaignList, setCampaignList] = useState<BlueprintCampaign[]>([])
  const [isCampaignListLoading, setIsCampaignListLoading] = useState(false)

  const updateBlueprint = async (id: string, blueprint: BlueprintRequest) => {
    setIsBlueprintModified(false)
    setPageHasChanges(false)
    setFullPageLoadingMessage('Updating Blueprint...')
    try {
      const res = await Axios.put(
        `${env.apiDomainUrl + PROJECT_SERVICE_API_DOMAIN_URL}/blueprints/${id}`,
        blueprint,
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )

      setCurrentBlueprint(new BlueprintDetail(res.data))
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'success',
        heading: 'Updated Blueprint',
        message: 'Successfully updated the blueprint',
      })
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Update Blueprint',
        message: err.response.data.message,
      })
    }
    setFullPageLoadingMessage('')
  }

  const createBlueprint = async (blueprint: BlueprintRequest) => {
    setIsBlueprintModified(false)
    setPageHasChanges(false)
    setFullPageLoadingMessage('Creating Blueprint...')
    try {
      const res = await Axios.post(
        `${env.apiDomainUrl + PROJECT_SERVICE_API_DOMAIN_URL}/blueprints`,
        blueprint,
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )

      setCurrentBlueprint(new BlueprintDetail(res.data))
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'success',
        heading: 'Created Blueprint',
        message: 'Successfully created the blueprint',
      })
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Create Blueprint',
        message: err.response.data.message,
      })
    }
    setFullPageLoadingMessage('')
  }

  const getCampaignList = useCallback(
    async (setDate: string) => {
      setIsCampaignListLoading(true)
      try {
        const res = await Axios.get(
          `${
            env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
          }/campaign_types/names?set_date=${moment(setDate).format(
            'yyyy-MM-DD',
          )}`,
        )
        setCampaignList(
          res.data.map((campaign: any) => new BlueprintCampaign(campaign)),
        )
      } catch (err: any) {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'error',
          heading: 'Failed to Get Campaign List',
          message: err.response.data.message,
        })
      }
      setIsCampaignListLoading(false)
    },
    [makeToast, env.apiDomainUrl],
  )

  const deleteFromRoster = (user: RosterUser, title: string) => {
    setCurrentBlueprint((previousState: BlueprintDetail) => {
      const clonedState = cloneDeep(previousState)
      const currentRosterElement = clonedState.roster.find(
        (rosterElement: RosterElement) => rosterElement.title === title,
      )
      currentRosterElement && remove(currentRosterElement.users, user)
      return clonedState
    })
  }

  const handleCampaignChange = async (
    campaignId: string,
    values: BlueprintRequest,
  ) => {
    setFullPageLoadingMessage('Updating Campaign...')
    try {
      const campaignRes = await Axios.get(
        `${
          env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
        }/campaign_types/${campaignId}`,
      )
      const rosterRes = await Axios.get(
        `${
          env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL
        }/campaign_types/${campaignId}/rosters?set_date=${moment(
          values.set_date,
        ).format('YYYY-MM-DD')}`,
      )

      setCurrentBlueprint(
        new BlueprintDetail({
          ...currentBlueprint,
          ...values,
          campaign: campaignRes.data,
          roster: rosterRes.data,
        }),
      )
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Get Blueprint Campaign',
        message: err.response.data.message,
      })
    }
    setFullPageLoadingMessage('')
  }

  const saveBlueprintRoster = async (
    id: string,
    roster: RosterElement[],
    updatedBy: string,
  ) => {
    setFullPageLoadingMessage('Saving Roster...')
    try {
      await Axios.put(
        `${
          env.apiDomainUrl + PROJECT_SERVICE_API_DOMAIN_URL
        }/blueprints/${id}/rosters`,
        {
          roster: roster,
          updated_by: updatedBy,
        },
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'success',
        heading: 'Roster Saved',
        message: 'Successfully saved the roster',
      })
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Save Roster',
        message: err.response.data.message,
      })
    }
    setFullPageLoadingMessage('')
  }

  return (
    <BlueprintDetailsTabContext.Provider
      value={{
        deleteFromRoster,
        getCampaignList,
        isBlueprintModified,
        setIsBlueprintModified,
        campaignList,
        setCampaignList,
        isCampaignListLoading,
        setIsCampaignListLoading,
        updateBlueprint,
        createBlueprint,
        handleCampaignChange,
        saveBlueprintRoster,
      }}
    >
      {children}
    </BlueprintDetailsTabContext.Provider>
  )
}

export const useBlueprintDetailsTabContext = () =>
  useContext(BlueprintDetailsTabContext)

export const BlueprintDetailsTabProvider = connect(
  null,
  null,
)(BlueprintDetailsTabProviderComponent)
