import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Form, Grid, Tooltip } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { MinusIcon, PlusIcon } from '@enterprise-ui/icons'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { map, scan, Observable, tap, withLatestFrom } from 'rxjs'
import { useSignFormsContext } from '../../../context/signFormsContext'
import { DepartmentLocationGroup } from '../../../../../models/signs/DistributionResponse.model'
import { useSignBulkEditContext } from 'components/SignBulkEdit/context/signBulkEditContext'
import {
  deptGroupData$,
  useMscLocationsContext,
} from '../../../context/mscLocationsContext'
import { DepartmentSubGroup } from '../../../../../models/signs/MscGroupsFacets.model'
import {
  useObservable,
  useSubscription,
  useObservableCallback,
} from 'observable-hooks'
import _ from 'lodash'

export interface Props {
  departmentGroup: any
  category: string
  index: number
  active: boolean
  handleChange: (id: string, value: any) => void
  errors?: any
  isBulkEdit?: boolean
}

type AutocompleteValue = {
  id: number | string
  value: number | string
  label: string
}

const mapToData = ($: Observable<any>) => $.pipe(map((x) => x))

const DepartmentGroupRow = ({
  departmentGroup,
  category,
  index,
  active,
  handleChange,
  errors,
  isBulkEdit = false,
}: Props) => {
  const { signDistributionFormik } = useSignFormsContext()!
  const { addBulkEditField } = useSignBulkEditContext()!
  const { sharedMscFacets } = useMscLocationsContext()!

  const {
    refreshStoreGroups,
    refreshSubGroups,
    refreshSharedMscFacets,
    includeFacets,
    excludeFacets,
  } = useMscLocationsContext()!
  const [storeGroups, setStoreGroups] = useState([] as any)
  const [mscGroups, setMscGroups] = useState([] as any)
  const [currentSubgroupIds, setCurrentSubgroupIds] = useState<
    AutocompleteValue[]
  >([])
  const [currentGroup, setCurrentGroup] = useState<AutocompleteValue>({
    id: '',
    value: '',
    label: '',
  })
  const [currentDept, setCurrentDept] = useState<Partial<AutocompleteValue>>({
    id: '',
    value: '',
    label: '',
  })

  const [loaded, setLoaded] = useState(false)

  const loaded$ = useObservable(mapToData, [loaded])

  useSubscription(loaded$)

  const { department_id, group_id, sub_group_ids, quantity } = departmentGroup
  useEffect(() => {
    const subscription = deptGroupData$
      .pipe(
        withLatestFrom(loaded$),
        tap(([{ store_groups, sub_groups, departments }, [loaded]]: any) => {
          const accessor = `${category}Facets[${index}]`

          if (
            _.get(store_groups, accessor) &&
            _.get(sub_groups, accessor) &&
            departments &&
            department_id &&
            !loaded
          ) {
            setLoaded(true)

            const d = departments.find(
              _.matchesProperty('department_id', parseInt(department_id)),
            )

            setCurrentDept({
              id: d?.department_id || '',
              value: d?.department_id || '',
              label: d?.department_display_name || '',
            })

            const g = _.get(store_groups, accessor).find(
              _.matchesProperty('group_id', group_id),
            )

            setCurrentGroup({
              id: g?.group_id || '',
              value: g?.group_id || '',
              label:
                g?.group_name && g?.group_id
                  ? `(${g?.group_id}) ${g?.group_name}`
                  : '',
            })
            const subGroupIds = _.get(sub_groups, accessor)
              ?.filter(({ sub_group_id }: DepartmentSubGroup) =>
                sub_group_ids.includes(sub_group_id),
              )
              .map(({ sub_group_id, sub_group_name }: DepartmentSubGroup) => ({
                id: sub_group_id,
                value: sub_group_id,
                label:
                  sub_group_id && sub_group_name
                    ? `(${sub_group_id}) ${sub_group_name}`
                    : '',
              }))

            setCurrentSubgroupIds(subGroupIds)
          }
        }),
      )
      .subscribe()

    return () => subscription.unsubscribe()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleAdd = () => {
    const locationGroupsPath = `location_filter_info[${category}_location_info].department_location_groups`
    signDistributionFormik.setFieldValue(locationGroupsPath, [
      ..._.get(signDistributionFormik.values, locationGroupsPath, []),
      new DepartmentLocationGroup(),
    ])
  }

  const refreshData = useCallback(() => {
    department_id && refreshStoreGroups(category, department_id, index)

    group_id && refreshSubGroups(category, department_id, group_id, index)
  }, [category, department_id, group_id, index]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    refreshData()
  }, [department_id, group_id, refreshData])

  useEffect(() => {
    refreshSharedMscFacets()
    refreshData()
  }, [category]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const facets = category === 'include' ? includeFacets : excludeFacets
    const { store_groups, sub_groups } = facets?.[index] || {}

    store_groups?.length && setStoreGroups(store_groups)
    sub_groups?.length && setMscGroups(sub_groups)
  }, [category, includeFacets, excludeFacets, index])

  const pathStr = useMemo(
    () =>
      `location_filter_info['${category}_location_info'].department_location_groups[${index}]`,
    [category, index],
  )

  const bulkEditTools$ = useObservable(
    (inputs$) => inputs$.pipe(map((x) => x)),
    [handleChange, pathStr, addBulkEditField],
  )

  const [onDistChange, distChanges$] = useObservableCallback<
    [Partial<DepartmentLocationGroup>, any],
    { path: keyof DepartmentLocationGroup; value: any }
  >((dist$) =>
    dist$.pipe(
      withLatestFrom(bulkEditTools$),
      tap(([dist, [handleChange, pathStr, addBulkEditField]]) => {
        handleChange(`${pathStr}.${dist.path}`, dist.value)
      }),
      scan(
        (accum, [dist]) => ({
          ...accum,
          [dist.path]: dist.value,
        }),
        {},
      ),
      withLatestFrom(bulkEditTools$),
      tap(([dist, [, pathStr, addBulkEditField]]) => {
        addBulkEditField(`distribution.${pathStr}`, dist)
      }),
    ),
  )

  useSubscription(distChanges$)
  useSubscription(bulkEditTools$)

  const setSubGroups = useCallback(
    (value: AutocompleteValue[]) => {
      setCurrentSubgroupIds(value)
      const ids = value.map((v) => v.id)
      onDistChange({
        path: 'sub_group_ids',
        value: ids as string[],
      })
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const setGroup = useCallback(
    (value?: AutocompleteValue) => {
      setCurrentGroup(value || { id: '', value: '', label: '' })
      onDistChange({
        path: 'group_id',
        value: value?.id.toString() || '',
      })
      setSubGroups([])
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const setDepartment = useCallback(
    (value?: AutocompleteValue) => {
      setCurrentDept(value || { id: '', value: '', label: '' })
      onDistChange({
        path: 'department_id',
        value: value?.id.toString() || '',
      })
      setGroup()
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const handleRemove = (itemIndex: number) => {
    const locationGroupsPath = `location_filter_info[${category}_location_info].department_location_groups`
    const filteredLocationGroups = _.get(
      signDistributionFormik.values,
      locationGroupsPath,
      [],
    ).filter((dept: any, index: number) => index !== itemIndex && dept)
    handleChange(locationGroupsPath, filteredLocationGroups)
  }

  return (
    <>
      <Grid.Container
        align="center"
        className="sem_DepartmentGroupRow hc-ph-expanded"
      >
        {/* === Department Sections === */}
        <Grid.Item xs={6} className="hc-pl-dense">
          <Autocomplete
            id={`${category}-includeDepartments`}
            options={sharedMscFacets.departments.map((dep) => ({
              id: dep.department_id,
              value: dep.department_id,
              label: dep.department_display_name,
            }))}
            value={currentDept}
            label="Choose a Department"
            errorText="Please select a department"
            onEnter={(event: React.KeyboardEvent, value: AutocompleteValue) => {
              setDepartment(value)
            }}
            onUpdate={(id: string, value: AutocompleteValue) => {
              setDepartment(value)
            }}
            disabled={!active}
          />
        </Grid.Item>
        {/* === Store Group Sections === */}
        <Grid.Item xs={6} className="hc-pl-dense">
          <Autocomplete
            id={`${category}-group`}
            options={storeGroups.map((group: any) => ({
              id: group.group_id,
              value: group.group_id,
              label: `(${group.group_id}) ${group.group_name}`,
            }))}
            value={currentGroup}
            disabled={!active || !department_id}
            label="Choose a Store Group"
            errorText="Please select a store group"
            onEnter={(event: React.KeyboardEvent, value: AutocompleteValue) => {
              setGroup(value)
            }}
            onUpdate={(id: string, value: AutocompleteValue) => {
              setGroup(value)
            }}
          />
        </Grid.Item>
      </Grid.Container>
      <Grid.Container
        align="center"
        className="sem_DepartmentGroupRow hc-ph-expanded"
      >
        {/* === MSC Subgroup Section === */}
        <Grid.Item xs={8} className="hc-pl-dense">
          <Autocomplete
            id={`${category}-group`}
            options={mscGroups.map((group: any) => ({
              id: group.sub_group_id,
              value: group.sub_group_id,
              label: `(${group.sub_group_id}) ${group.sub_group_name}`,
            }))}
            value={currentSubgroupIds}
            disabled={!active || !group_id}
            multiselect
            label="Choose Store Sub Groups"
            errorText="Please select store groups"
            onEnter={(
              event: React.KeyboardEvent,
              value: AutocompleteValue[],
            ) => {
              setSubGroups(value)
            }}
            onUpdate={(id: string, value: AutocompleteValue[]) => {
              setSubGroups(value)
            }}
          />
        </Grid.Item>

        {/* === Quantity Sections === */}
        {category === 'include' && (
          <Grid.Item xs={3} className="hc-ph-none">
            <Form.Field
              id={`location_filter_info.${category}_location_info.department_location_groups[${index}].quantity`}
              label="Quantity"
              type="number"
              value={quantity || ''}
              disabled={!active}
              // dense
              onChange={(e: any) => {
                handleChange &&
                  handleChange(e.target.id, parseInt(e.target.value))
                if (isBulkEdit) {
                  addBulkEditField(
                    'distribution.' + e.target.id,
                    parseInt(e.target.value),
                  )
                }
              }}
              error={
                active &&
                _.get(
                  errors,
                  `location_filter_info.${category}_location_info.department_location_groups[${index}].quantity`,
                ) !== undefined
              }
              errorText={_.get(
                errors,
                `location_filter_info.${category}_location_info.department_location_groups[${index}].quantity`,
              )}
            />
          </Grid.Item>
        )}
        {/* === Add or Remove MSC Group Section === */}
        <Grid.Item xs={1} className="hc-ph-none flex-column flex-center">
          <Tooltip
            location="bottom-left"
            content="Add another Department Group"
          >
            <Button
              aria-label="Add another Department Group"
              type="ghost"
              size="dense"
              iconOnly
              onClick={() => handleAdd()}
              disabled={!active}
            >
              <EnterpriseIcon icon={PlusIcon} />
            </Button>
          </Tooltip>
          <Tooltip location="bottom-left" content="Remove Department Group">
            <Button
              aria-label="Remove Department Group"
              size="dense"
              iconOnly
              type="destructive"
              onClick={() => handleRemove(index)}
              disabled={!active || index === 0}
            >
              <EnterpriseIcon icon={MinusIcon} />
            </Button>
          </Tooltip>
        </Grid.Item>
      </Grid.Container>
    </>
  )
}

export default DepartmentGroupRow
