import moment from 'moment'

export const isDateInRange = (
  dateString: string,
  startDateTimestamp: string,
  endDateTimestamp: string,
) => {
  const momentDate = moment(dateString).utc()
  const startDate = moment(startDateTimestamp).utc()
  const endDate = moment(endDateTimestamp).utc()

  return moment(momentDate).isBetween(startDate, endDate, 'day', '[]')
}
