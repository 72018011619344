export class BlueprintSearchFacets {
  [index: string]: any
  pyramid: string
  campaign: string
  type: string
  status: string

  constructor(props: any = {}) {
    this.campaign = props.campaign || 'campaign.name'
    this.pyramid = props.pyramid || 'campaign.pyramids.group_display_name'
    this.type = props.type || 'type'
    this.status = props.status || 'status'
  }
}
