import React, { useEffect } from 'react'
import { Grid } from '@enterprise-ui/canvas-ui-react'
import { AgGridReact, AgGridColumn } from 'ag-grid-react'
import { Column } from '../../../../models/agGrid/AgGridColumn.model'
import { useAgGridStandardSignAdminContext } from '../context/agGridStandardSignAdminContext'
import { useStandardSignAdminContext } from '../context/standardSignAdminContext'
import { STANDARD_SIGN_ADMIN_VIEW } from '../views/AgGridStandardSignAdminView'
import ActionsDropdown from './agGridTypes/ActionsDropdown'
import TableEditorButtons from './agGridTypes/TableEditorButtons'

const StandardSignTable = () => {
  const {
    rowData,
    setRowData,
    gridOptions,
    onGridReady,
    onCellValueChange,
    onFilterChange,
    onSortChanged,
    onSelectionChange,
    onFirstDataRender,
  } = useAgGridStandardSignAdminContext()!
  const { signTemplates } = useStandardSignAdminContext()!

  useEffect(() => {
    setRowData(signTemplates)
  }, [signTemplates, setRowData])

  return (
    <Grid.Container justify="flex-end">
      <ActionsDropdown />
      <TableEditorButtons />
      <Grid.Item className="hc-pt-none" xs={12}>
        <div className="ag-theme-rubix standard-sign-table">
          <AgGridReact
            gridOptions={gridOptions}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRender}
            suppressRowClickSelection
            rowSelection="multiple"
            rowHeight={48}
            rowData={rowData}
            rowDragManaged
            rowDragMultiRow
            enableRangeSelection
            enableFillHandle
            fillHandleDirection="y"
            onCellValueChanged={onCellValueChange}
            onFilterChanged={onFilterChange}
            onSortChanged={onSortChanged}
            onSelectionChanged={onSelectionChange}
          >
            {STANDARD_SIGN_ADMIN_VIEW.map((column: Column, index: number) => (
              <AgGridColumn
                headerName={column.headerName}
                field={column.field}
                width={column.width || 148}
                type={column.type}
                filter={column.filter || 'agTextColumnFilter'}
                colId={column.colId}
                key={index}
              />
            ))}
          </AgGridReact>
        </div>
      </Grid.Item>
    </Grid.Container>
  )
}

export default StandardSignTable
