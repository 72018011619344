export enum KitScenarioStatusNames {
  REQUESTED = 'REQUESTED',
  RUNNING = 'RUNNING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export class KitScenarioStatus {
  name: KitScenarioStatusNames
  description?: string

  constructor(props: any = {}) {
    this.name = props.name || KitScenarioStatusNames.REQUESTED
    this.description = props.description
  }
}
