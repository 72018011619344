import moment from 'moment'
import { MilestoneTemplate } from '../milestones/MilestoneTemplate.model'
import { HealthInfo } from './BlueprintSummary.model'
import { BlueprintCampaign } from '../blueprints/BlueprintCampaign.model'

export class ProjectSummary {
  blueprint_id: string
  blueprint_name: string
  budget: number
  campaign: BlueprintCampaign
  current_milestone?: MilestoneTemplate
  departments: string[]
  due_date?: string
  health: string
  health_info: HealthInfo
  milestone_health: string
  milestone_health_info: HealthInfo
  my_milestone: MilestoneTemplate
  my_milestone_due_date: string
  project_id: string
  name: string
  recreate_kits: boolean
  sap_project_id: string
  set_date: string
  in_store_date: string
  tier_one_project: boolean

  constructor(props: any = {}) {
    this.blueprint_id = props.blueprint_id || ''
    this.blueprint_name = props.blueprint_name || ''
    this.budget = props.budget
    this.campaign = props.campaign || ''
    this.current_milestone = new MilestoneTemplate(props.current_milestone)
    this.departments = props.departments || []
    this.due_date =
      props.current_milestone &&
      parseInt(
        moment(props.set_date)
          .subtract(props.current_milestone.days_out, 'days')
          .format('x'),
      ).toString()
    this.health = props.health || ''
    this.health_info = new HealthInfo(props.health)
    this.milestone_health = props.milestone_health || ''
    this.milestone_health_info = new HealthInfo(props.milestone_health)
    this.my_milestone = props.my_milestone
      ? new MilestoneTemplate(props.my_milestone)
      : new MilestoneTemplate({})
    this.my_milestone_due_date =
      props.my_milestone &&
      parseInt(
        moment(props.set_date)
          .subtract(props.my_milestone.days_out, 'days')
          .format('x'),
      ).toString()
    this.project_id = props.project_id || ''
    this.name = props.project_name || ''
    this.recreate_kits = props.recreate_kits
    this.sap_project_id = props.sap_project_id || ''
    this.set_date = props.set_date || ''
    this.in_store_date = props.in_store_date || ''
    this.tier_one_project = props.tier_one_project || false
  }
}

export class ProjectSummarySearchResult {
  result: ProjectSummary

  constructor(props: any = {}) {
    this.result = new ProjectSummary(props.result)
  }
}
