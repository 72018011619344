import React, { useState, useEffect } from 'react'
import { Modal, Grid, Button } from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { useStandardSignAdminContext } from '../../context/standardSignAdminContext'

const MoveSignsModal = () => {
  const {
    isMoveSignsModalOpen,
    setIsMoveSignsModalOpen,
    getSignTemplatesBySignType,
    moveStandardSignOptions,
    moveStandardSigns,
  } = useStandardSignAdminContext()!
  const [newStandardSignValue, setNewStandardSignValue] = useState<any>()

  useEffect(() => {
    getSignTemplatesBySignType()
  }, [getSignTemplatesBySignType])

  useEffect(() => {
    if (!isMoveSignsModalOpen) setNewStandardSignValue(undefined)
  }, [isMoveSignsModalOpen])

  const closeModal = () => {
    setIsMoveSignsModalOpen(false)
  }

  return (
    <Modal
      headingText="Select New Standard Sign"
      isVisible={isMoveSignsModalOpen}
      onRefuse={closeModal}
    >
      <div className="hc-pa-normal">
        <Grid.Container>
          <Grid.Item xs={12}>
            <Autocomplete
              hintText="All signs with the former standard sign template will now have the selected standard sign instead."
              label="New Standard Sign"
              onUpdate={(id: string, value: any) => {
                if (value) {
                  setNewStandardSignValue(value)
                }
              }}
              options={moveStandardSignOptions}
              required
              value={newStandardSignValue}
            />
          </Grid.Item>
          <Grid.Item xs={6}>
            <Button fullWidth type="secondary" onClick={closeModal}>
              Cancel
            </Button>
          </Grid.Item>
          <Grid.Item xs={6}>
            <Button
              disabled={
                newStandardSignValue === null ||
                newStandardSignValue === undefined
              }
              fullWidth
              type="primary"
              onClick={() => moveStandardSigns(newStandardSignValue!!.value)}
            >
              Move Signs
            </Button>
          </Grid.Item>
        </Grid.Container>
      </div>
    </Modal>
  )
}

export default MoveSignsModal
