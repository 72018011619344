import React from 'react'
import { Grid, Spinner } from '@enterprise-ui/canvas-ui-react'
import { useAgGridDashboardContext } from '../../context/agGridDashboardContext'
import DashboardActions from '../DashboardActionsDropdown'
import { useDashboardContext } from '../../context/dashboardContext'
import DashboardViews from '../DashboardViewsButtonDropdown'
import { TabName } from '../../constants/dashboardConstants'
import { useAppContext } from '../../../App/context/appContext'
import DashboardEditButtons from 'components/DashboardV2/components/DashboardEditButtons'
import BlueprintSummaryGrid from 'components/DashboardV2/components/BlueprintSummary/BlueprintSummaryGrid'
import BlueprintMilestoneGrid from 'components/DashboardV2/components/BlueprintSummary/BlueprintMilestoneGrid'

const BlueprintDashboard = () => {
  const { dashboardActiveTab } = useAppContext()!
  const { isLoading, modifiedBlueprintMilestones } = useDashboardContext()!
  const { blueprintGridApi, bpMilestoneGridApi } = useAgGridDashboardContext()!

  return (
    <div className="hc-pa-dense hc-pv-none">
      <Grid.Container>
        <DashboardActions />
        <DashboardViews />
        {modifiedBlueprintMilestones.length > 0 ? (
          <DashboardEditButtons
            gridApi={
              dashboardActiveTab === TabName.BLUEPRINT_SUMMARY
                ? blueprintGridApi
                : bpMilestoneGridApi
            }
          />
        ) : null}
        {isLoading ? (
          <Grid.Container
            className="sign-projects-loading-container"
            direction="column"
            justify="center"
            align="center"
          >
            <Grid.Item>
              {dashboardActiveTab === TabName.BLUEPRINT_SUMMARY
                ? 'Loading Blueprint Summary...'
                : dashboardActiveTab === TabName.MY_BLUEPRINT_MILESTONES
                ? 'Loading My Blueprint Milestones...'
                : 'Loading...'}
            </Grid.Item>
            <Grid.Item>
              <Spinner />
            </Grid.Item>
          </Grid.Container>
        ) : dashboardActiveTab === TabName.MY_BLUEPRINT_MILESTONES ? (
          <BlueprintMilestoneGrid />
        ) : (
          <BlueprintSummaryGrid />
        )}
      </Grid.Container>
    </div>
  )
}

export default BlueprintDashboard
