import React from 'react'
import { Layout } from '@enterprise-ui/canvas-ui-react'
import ProjectDetailsContainer from './components/ProjectDetailsContainer'
import { ProjectNotesProvider } from './components/ProjectNotes/context/projectNotesContext'
import { ProjectDetailsProvider } from './context/projectDetailsContext'
import { SignBulkEditProvider } from '../SignBulkEdit/context/signBulkEditContext'
import PageChangesWrapper from '../App/components/PageChangesWrapper'

const ProjectDetails = (props: any) => (
  <PageChangesWrapper>
    <ProjectDetailsProvider>
      {/* ProjectNotesProvider needs to wrap ProjectDetailsContainer so the button works */}
      <ProjectNotesProvider>
        <SignBulkEditProvider>
          <Layout.Body className="hc-pa-expanded" includeRail>
            <ProjectDetailsContainer {...props} />
          </Layout.Body>
        </SignBulkEditProvider>
      </ProjectNotesProvider>
    </ProjectDetailsProvider>
  </PageChangesWrapper>
)

export default ProjectDetails
