import React from 'react'
import EnterpriseIcon, {
  CautionIcon,
  CheckIcon,
  InfoIcon,
  TimerIcon,
} from '@enterprise-ui/icons'
import { ProjectSummary } from '../../../../models/dashboard/ProjectSummary.model'
import { BlueprintSummary } from '../../../../models/dashboard/BlueprintSummary.model'
import RubixChip from 'components/common/RubixChip'

export interface Props {
  value: string
  data: ProjectSummary | BlueprintSummary
}

const HealthChip = ({ value = '', data }: Props) => {
  const statusIcon =
    data.health_info.color === 'green'
      ? CheckIcon
      : data.health_info.color === 'yellow'
      ? TimerIcon
      : data.health_info.color === 'red'
      ? CautionIcon
      : InfoIcon
  return (
    <RubixChip color={data.health_info.color}>
      <EnterpriseIcon size="inline" className="hc-mr-dense" icon={statusIcon} />
      {value}
    </RubixChip>
  )
}

export default HealthChip
