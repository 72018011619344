import React from 'react'
import { Button } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { SaveIcon } from '@enterprise-ui/icons'
import { useAddOnContext } from 'components/ProjectDetails/components/AddOnExpenses/context/addOnContext'
import { RowNode } from 'ag-grid-community'
import { useAgGridAddOnContext } from 'components/ProjectDetails/components/AddOnExpenses/context/agGridAddOnContext'

const SaveButton = () => {
  const { expensesModified, setExpensesModified } = useAddOnContext()!
  const { gridApi, validateExpensesAndSave } = useAgGridAddOnContext()!

  const handleSave = () => {
    const modifiedRowNodes: RowNode[] = []
    gridApi?.forEachNode((node: RowNode) => modifiedRowNodes.push(node))
    validateExpensesAndSave(
      modifiedRowNodes.filter((node: RowNode) => node.data.modified),
    )
    setExpensesModified(false)
  }

  return (
    <Button type="ghost" disabled={!expensesModified} onClick={handleSave}>
      <EnterpriseIcon icon={SaveIcon} className="hc-mr-dense" />
      Save Changes
    </Button>
  )
}

export default SaveButton
