import React, { Fragment } from 'react'
import { Button, Dropdown, Grid } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, {
  CancelCircleIcon,
  CancelIcon,
  CaretDownIcon,
  FilesIcon,
  ImportIcon,
  MaximizeIcon,
  MinimizeIcon,
  RedoIcon,
  UndoIcon,
} from '@enterprise-ui/icons'
import { useUserContext } from '../../App/context/userContext'
import { useFilterContext } from '../../App/context/filterContext'
import { useAgGridSignImportContext } from '../context/agGridSignImportContext'
import { useProjectSignListContext } from '../../ProjectDetails/components/ProjectSignList/context/projectSignListContext'
import {
  SelectedSignsIds,
  SignImportRequest,
} from '../../../models/signLibrary'
import { useProjectDetailsContext } from '../../ProjectDetails/context/projectDetailsContext'

export interface Props {
  closeModal?: Function
}

const ActionButtonRow = ({ closeModal }: Props) => {
  const { userName } = useUserContext()!
  const { setSignLibraryFilterModel } = useFilterContext()!
  const { currentProject } = useProjectDetailsContext()!
  const { importSigns, setIsImportSignsFullScreen } =
    useProjectSignListContext()!
  const {
    resetAllFilters,
    importIsDisabled,
    selectedSignIds,
    setSelectedSignIds,
    deselectAll,
    sizeColumnsToFit,
    autoSizeAllColumns,
    resetColumnState,
  } = useAgGridSignImportContext()!

  const handleResetFilters = () => {
    setSignLibraryFilterModel({})
    resetAllFilters()
  }

  const handleImport = (shell: boolean) => {
    importSigns(
      new SignImportRequest({
        sign_ids: selectedSignIds.sign_ids,
        non_retail_item_ids: selectedSignIds.non_retail_item_ids,
        project_id: currentProject.project_id,
        shell: shell,
        updated_by: userName,
      }),
    )
    setIsImportSignsFullScreen(false)
    setSelectedSignIds(new SelectedSignsIds({}))
    resetAllFilters()
    setSignLibraryFilterModel({})
    deselectAll()
  }

  return (
    <Fragment>
      <Grid.Item className="hc-ph-dense" xs={true}>
        <Dropdown size="normal" location="bottom-left">
          <Button type="ghost">
            Actions
            <EnterpriseIcon className="hc-ml-dense" icon={CaretDownIcon} />
          </Button>
          <Dropdown.Menu>
            <Dropdown.MenuItem
              className="sem_DropdownMenuItem--action"
              disabled={importIsDisabled}
              onClick={() => handleImport(false)}
            >
              <EnterpriseIcon className="hc-mr-normal" icon={ImportIcon} />
              Import Selected Signs
            </Dropdown.MenuItem>
            <Dropdown.MenuItem
              className="sem_DropdownMenuItem--action"
              disabled={importIsDisabled}
              onClick={() => handleImport(true)}
            >
              <EnterpriseIcon className="hc-mr-normal" icon={FilesIcon} />
              Copy Selected Signs Without DPCIs
            </Dropdown.MenuItem>
            <Dropdown.MenuItem
              className="sem_DropdownMenuItem--action divider"
              onClick={closeModal}
            >
              <EnterpriseIcon
                className="hc-mr-normal"
                icon={CancelCircleIcon}
              />
              Cancel Import
            </Dropdown.MenuItem>
            <Dropdown.MenuItem onClick={() => handleResetFilters()}>
              <EnterpriseIcon className="hc-mr-normal" icon={UndoIcon} />
              Reset All Sign Filters
            </Dropdown.MenuItem>
            <Dropdown.MenuItem onClick={sizeColumnsToFit}>
              <EnterpriseIcon className="hc-mr-normal" icon={MinimizeIcon} />
              Size Columns to Fit
            </Dropdown.MenuItem>
            <Dropdown.MenuItem onClick={autoSizeAllColumns}>
              <EnterpriseIcon className="hc-mr-normal" icon={MaximizeIcon} />
              Auto Size All Columns
            </Dropdown.MenuItem>
            <Dropdown.MenuItem onClick={resetColumnState}>
              <EnterpriseIcon className="hc-mr-normal" icon={RedoIcon} />
              Reset Column Sizes
            </Dropdown.MenuItem>
          </Dropdown.Menu>
        </Dropdown>
      </Grid.Item>
      <Grid.Item className="hc-ph-dense hc-pb-dense">
        <Button type="ghost" onClick={closeModal}>
          <EnterpriseIcon className="hc-mr-dense" icon={CancelIcon} />
          Cancel Import
        </Button>
      </Grid.Item>
    </Fragment>
  )
}

export default ActionButtonRow
