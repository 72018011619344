import { KitScenario } from './KitScenario.model'

export class KitScenarioRequest {
  project_id: string
  kit_scenario: KitScenario
  override_current_scenario: boolean
  force: boolean
  updated_by: string

  constructor(props: any = {}) {
    this.project_id = props.project_id
    this.kit_scenario = props.kit_scenario || new KitScenario()
    this.override_current_scenario = props.override_current_scenario || false
    this.force = props.force || false
    this.updated_by = props.updated_by
  }
}
