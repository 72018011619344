import React, { useState } from 'react'
import { Grid, Button, Form } from '@enterprise-ui/canvas-ui-react'
import { useUserContext } from 'components/App/context/userContext'
import { ScheduleTemplate } from '../../../../../models/milestones/ScheduleTemplate'
import { useMilestoneContext } from 'components/App/context/milestoneContext'
import { useAdminMilestoneContext } from '../../context/adminMilestoneContext'

export const AdminScheduleTemplateModal = () => {
  const {
    isScheduleTemplateModalOpen,
    setIsScheduleTemplateOpen,
    addNewScheduleTemplate,
    isEditTemplateName,
  } = useAdminMilestoneContext()!
  const {
    scheduleTemplateByScheduleTemplateId,
    updateScheduleTemplateByScheduleTemplateId,
  } = useMilestoneContext()!
  const [templateName, setTemplateName] = useState('')
  const { userName } = useUserContext()!

  const updateScheduleTemplate = new ScheduleTemplate({
    template_id: scheduleTemplateByScheduleTemplateId.template_id,
    template_name: templateName,
    schedule_type: templateName,
    master_template: true,
    milestones: scheduleTemplateByScheduleTemplateId.milestones,
    created_by: scheduleTemplateByScheduleTemplateId.created_by,
    created_date: new Date().toISOString(),
    updated_by: userName,
    updated_date: new Date().toISOString(),
  })

  const newScheduleTemplate = new ScheduleTemplate({
    template_id: null,
    template_name: templateName,
    schedule_type: templateName,
    master_template: true,
    milestones: scheduleTemplateByScheduleTemplateId.milestones,
    created_by: userName,
    created_date: new Date().toISOString(),
    updated_by: userName,
    updated_date: new Date().toISOString(),
  })

  return (
    <div className="hc-pa-dense">
      <Grid.Container>
        <Grid.Item xs={12}>
          <Form.Field
            id="template_name"
            data-testid="templateName"
            label="Schedule Template Name"
            value={templateName}
            required
            onChange={(e: any) => setTemplateName(e.target.value)}
          />
        </Grid.Item>
      </Grid.Container>
      <Grid.Container direction="row-reverse" spacing="dense">
        <Grid.Item>
          <Button
            type="primary"
            fullWidth
            data-testid="saveScheduleTemplate"
            onClick={() => {
              isEditTemplateName
                ? updateScheduleTemplateByScheduleTemplateId(
                    updateScheduleTemplate,
                  )
                : addNewScheduleTemplate(newScheduleTemplate)
              setIsScheduleTemplateOpen(!isScheduleTemplateModalOpen)
              setTemplateName('')
            }}
          >
            Save Schedule Template
          </Button>
        </Grid.Item>
        <Grid.Item>
          <Button
            type="secondary"
            fullWidth
            data-testid="toggleTemplate"
            onClick={() => {
              setIsScheduleTemplateOpen(!isScheduleTemplateModalOpen)
              setTemplateName('')
            }}
          >
            Cancel
          </Button>
        </Grid.Item>
      </Grid.Container>
    </div>
  )
}

export default AdminScheduleTemplateModal
