import { get } from 'lodash'
import { SignElementFields } from '.'
import { BATCH_EDIT_ACTION } from '../../components/App/constants/appConstants'

export default class SignElementEditorRequest {
  project_id: string
  sign_elements: EditedSignElement[]
  updated_by: string

  constructor(props: any = {}) {
    this.project_id = props.project_id || ''
    this.sign_elements = get(props, 'sign_elements', []).map(
      (element: any) => new EditedSignElement(element),
    )
    this.updated_by = props.updated_by || 'System'
  }
}

export class EditedSignElement {
  action: BATCH_EDIT_ACTION
  fields: SignElementFields
  non_retail_item_id: string

  constructor(props: any = {}) {
    this.action = props.action || BATCH_EDIT_ACTION.UPDATE
    this.fields = new SignElementFields(props.fields)
    this.non_retail_item_id = props.non_retail_item_id || ''
  }
}
