import { ValueGetterParams } from 'ag-grid-community'
import { get } from 'lodash'
import moment from 'moment'

export const dateValueGetter = (params: ValueGetterParams) =>
  get(params.data, params.column.getColId())
    ? moment(get(params.data, params.column.getColId())).format('MM/DD/YYYY')
    : ''

export const dateFilterParams = {
  comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
    if (cellValue === null) {
      return -1
    }
    const cellDate = new Date(cellValue)
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1
    }
  },
  browserDatePicker: true,
  suppressAndOrCondition: true,
  filterOptions: ['inRange'],
}

export const dateComparator = (value1: string, value2: string) => {
  const _dateToNum = (date: string) => {
    if (date === undefined || date === null || date.length !== 10) {
      return null
    }
    return (
      parseInt(date.substring(6, 10)) * 10000 +
      parseInt(date.substring(0, 2)) * 100 +
      parseInt(date.substring(3, 5))
    )
  }
  const date1 = _dateToNum(value1)
  const date2 = _dateToNum(value2)
  return date1 === null && date2 === null
    ? 0
    : date1 === null
    ? -1
    : date2 === null
    ? 1
    : date1 - date2
}

export const getHealthFromLabel = (label: string): string => {
  return label === 'On Track'
    ? 'green'
    : label === 'Past Due'
    ? 'yellow'
    : label === 'At Risk'
    ? 'red'
    : label === 'Initialized'
    ? 'blue'
    : 'grey'
}

export const getLabelFromHealth = (health: string): string => {
  return health === 'green'
    ? 'On Track'
    : health === 'yellow'
    ? 'Past Due'
    : health === 'red'
    ? 'At Risk'
    : health === 'blue'
    ? 'Initialized'
    : 'N/A'
}
