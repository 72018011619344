import React from 'react'
import { useSignBulkEditContext } from '../context/signBulkEditContext'
import { Form } from '@enterprise-ui/canvas-ui-react'

export interface Props {
  fieldType: string
  rightContent?: boolean
  disabled?: boolean
}

const SignBulkCheckbox = ({
  fieldType,
  rightContent = false,
  disabled = false,
}: Props) => {
  const {
    selectedFields,
    setSelectedFields,
    addBulkEditField,
    removeBulkEditField,
  } = useSignBulkEditContext()!

  const toggleFields = [
    'on_pog_sign',
    'spt_sign_on_pog',
    'printing.file_approved',
  ]

  const handleChange = (e: any) => {
    if (e.target.checked) {
      setSelectedFields([...selectedFields, fieldType])
      if (toggleFields.includes(fieldType)) {
        addBulkEditField(fieldType, false)
      }
    } else {
      const newSelectedFields = selectedFields.filter(
        (selectedField: string) => fieldType !== selectedField,
      )
      setSelectedFields(newSelectedFields)
      removeBulkEditField(fieldType)
    }
  }

  return (
    <Form.Field
      id={`msc_checkbox ${fieldType}`}
      className={rightContent ? '' : 'sem_mscCheckbox'}
      type="checkbox"
      disabled={disabled}
      checked={selectedFields.includes(fieldType)}
      onChange={handleChange}
    />
  )
}

export default SignBulkCheckbox
