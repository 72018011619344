import React, { Fragment, useState } from 'react'
import {
  Button,
  Form,
  Grid,
  Heading,
  Tooltip,
  Spinner,
  Input,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, {
  OverflowMenuHorizontalIcon,
} from '@enterprise-ui/icons'
import { useSignDetailsContext } from '../../../context/signDetailsContext'
import { useMscLocationsContext } from '../../../context/mscLocationsContext'
import LocationsModal from '../../modals/LocationsModal'
import DepartmentGroupRow from './DepartmentGroupRow'
import {
  MSC_ROW,
  SIGN_DETAILS_SECTION,
} from '../../../constants/signDetailsConstants'
import { DialogProps } from '../../../../../models/app/DialogProps.model'
import { useAppContext } from 'components/App/context/appContext'
import { useSignFormsContext } from '../../../context/signFormsContext'
import { isEditable } from 'components/App/helpers/signEditHelper'
import { useUserContext } from 'components/App/context/userContext'
import SignResponse from '../../../../../models/signs/SignResponse.model'
import { useSignBulkEditContext } from 'components/SignBulkEdit/context/signBulkEditContext'

export interface Props {
  isBulkEdit?: boolean
  handleChange: (id: string, value: any) => void
  resetSelections: (category: string) => void
  selectedSigns?: SignResponse[]
}

const ExcludeLocations = ({
  isBulkEdit = false,
  handleChange,
  resetSelections,
  selectedSigns,
}: Props) => {
  const { setDialogProps } = useAppContext()!
  const {
    isInProject,
    currentSign,
    addToModifiedSections,
    selectedExcludeMscRow,
    setSelectedExcludeMscRow,
    project,
  } = useSignDetailsContext()!
  const { addBulkEditField, selectedFields } = useSignBulkEditContext()!
  const { signDistributionFormik } = useSignFormsContext()!
  const { userPermissions, userCompany, userType } = useUserContext()!
  // desctructoring for `department_location_groups` because it is so deeply nested
  const { department_location_groups, locations } =
    signDistributionFormik.values.location_filter_info.exclude_location_info

  const { isLoading } = useMscLocationsContext()!
  const [locationModalCategory, setLocationModalCategory] = useState('')

  const handleRadioSelect = (id: string, value: MSC_ROW) => {
    if (value === MSC_ROW.CLEAR) {
      addBulkEditField(
        'distribution.location_filter_info[exclude_location_info]',
        {},
      )
    }
    resetSelections('exclude')
    setSelectedExcludeMscRow(value)
    addToModifiedSections(SIGN_DETAILS_SECTION.DISTRIBUTION)
  }

  const isDisabled = (field: string) => {
    if (isBulkEdit) {
      if (selectedSigns && selectedSigns.length > 0) {
        for (var i = 0; i < selectedSigns.length; i++) {
          if (
            !isEditable(
              selectedSigns[i],
              field,
              userPermissions,
              userCompany,
              userType,
              isInProject,
              project.workflow.status,
              undefined,
              project,
            )
          ) {
            return true
          }
        }
        return false
      }
      return true
    } else {
      return !isEditable(
        currentSign,
        field,
        userPermissions,
        userCompany,
        userType,
        isInProject,
        project.workflow.status,
        undefined,
        project,
      )
    }
  }
  const checkFormIsEmpty = () => {
    let {
      all_locations: allLocations,
      locations: localLocations,
      department_location_groups: localDeptLocGroups,
    } = signDistributionFormik.values.location_filter_info.exclude_location_info
    return (
      !allLocations &&
      localLocations.length === 0 &&
      localDeptLocGroups[0].department_id.length === 0
    )
  }

  return (
    <Fragment>
      <Grid.Item xs={12} className="hc-pb-none">
        <Heading className="hc-clr-grey02" size={6}>
          Exclude Stores Selection
        </Heading>
      </Grid.Item>
      <Grid.Item xs={12} className="hc-pt-dense">
        <Grid.Container justify="center">
          <Grid.Item
            className={`hc-pr-none hc-pb-none ${isLoading ? 'sem_Fade' : ''}`}
          >
            <Input.Radio
              className="sem_MscFacetsRadio"
              id="exclude_msc"
              value={selectedExcludeMscRow}
              options={[
                { value: MSC_ROW.CLEAR, label: 'Clear Exclusions' },
                {
                  value: MSC_ROW.SPECIFIC_STORES,
                  label: 'Specific Store(s):',
                },
                { value: MSC_ROW.DEPARTMENT, label: 'Department MSC' },
              ]}
              disabled={
                isBulkEdit
                  ? !selectedFields.includes(
                      'distribution.location_filter_info',
                    )
                  : isDisabled('exclude_msc')
              }
              onUpdate={(id: string, value: MSC_ROW) => {
                if (!checkFormIsEmpty()) {
                  setDialogProps(
                    new DialogProps({
                      headingText:
                        'This action will clear any selected exclusions.',
                      approveButtonText: 'Okay',
                      refuseButtonText: 'Cancel',
                      onApprove: () => {
                        handleRadioSelect(id.split('_')[0], value)
                        setDialogProps(new DialogProps())
                      },
                      onRefuse: () => {
                        setDialogProps(new DialogProps())
                      },
                    }),
                  )
                } else {
                  handleRadioSelect(id.split('-')[0], value)
                }
              }}
            />
          </Grid.Item>
          <Grid.Item
            xs={true}
            className={`hc-pl-dense ${isLoading ? 'sem_Fade' : ''}`}
          >
            <Grid.Container
              style={{ marginTop: '40px', marginBottom: '-16px' }}
            >
              <Grid.Item xs={true} className="hc-pr-noner hc-mt-dense">
                <Form.Field
                  id="excludeSpecStores"
                  value={locations}
                  size="dense"
                  disabled
                />
              </Grid.Item>
              <Grid.Item className="hc-pl-dense hc-pr-expanded hc-mt-dense">
                <Tooltip location="bottom" content="Store Locations">
                  <Button
                    type="primary"
                    size="dense"
                    id="select_stores"
                    onClick={() => setLocationModalCategory('exclude')}
                    disabled={
                      selectedExcludeMscRow !== MSC_ROW.SPECIFIC_STORES ||
                      isDisabled('select_stores')
                    }
                  >
                    <EnterpriseIcon icon={OverflowMenuHorizontalIcon} />
                  </Button>
                  {locationModalCategory && (
                    <LocationsModal
                      modalCategory={locationModalCategory}
                      toggleVisible={setLocationModalCategory}
                      handleFormChange={handleChange}
                    />
                  )}
                </Tooltip>
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
          {department_location_groups.map((group: any, index: number) => (
            <DepartmentGroupRow
              departmentGroup={group}
              category="exclude"
              handleChange={handleChange}
              index={index}
              active={
                selectedExcludeMscRow === MSC_ROW.DEPARTMENT &&
                !isDisabled('selected_msc')
              }
              key={index}
            />
          ))}
          {isLoading ? (
            <Grid.Item style={{ position: 'absolute', maxHeight: '72px' }}>
              <Spinner />
            </Grid.Item>
          ) : null}
        </Grid.Container>
      </Grid.Item>
    </Fragment>
  )
}

export default ExcludeLocations
