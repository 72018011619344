import React, { useEffect } from 'react'
import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import {
  RowClassParams,
  SortChangedEvent,
  FilterChangedEvent,
  SelectionChangedEvent,
} from 'ag-grid-community'
import { Card, Grid } from '@enterprise-ui/canvas-ui-react'
import { NOTIFICATIONS_VIEW } from './views/NotificationsView'
import { Column } from '../../../models/agGrid/AgGridColumn.model'
import {
  dateComparator,
  dateFilterParams,
} from 'components/DashboardV2/helpers/dashboardHelper'
import DefaultChip from './views/agGridTypes/DefaultChip'
import IconLink from './views/agGridTypes/IconLink'
import FormattedDate from './views/agGridTypes/FormattedDate'
import DescriptionPopover from './views/agGridTypes/DescriptionPopover'
import { useNotificationsContext } from '../context/notificationsContext'
import NotificationsActions from './NotificationsActions'
import NotificationsViewDropdown from './NotificationsViewDropdown'
import { useNotificationSSRMServerContext } from '../context/notificationSSRMServerContext'

const NotificationsTable = () => {
  const {
    onSortChanged,
    onFilterChanged,
    getNotificationSettings,
    onSelectionChange,
    notificationsDatasource,
    gridApi,
    setGridApi,
    setGridColumnApi,
  } = useNotificationsContext()!
  const { notificationSSRMServer } = useNotificationSSRMServerContext()!

  const onGridReady = (params: any) => {
    setGridApi(params.api)
    setGridColumnApi(params.columnApi)
    params.api?.setFilterModel({
      status: {
        filterType: 'text',
        type: 'contains',
        filter: 'UNREAD',
      },
    })

    params.api.setServerSideDatasource(
      notificationsDatasource(notificationSSRMServer()),
    )
  }

  const gridOptions = {
    defaultColDef: {
      resizable: true,
      sortable: true,
      filter: true,
      width: 148,
      menuTabs: ['filterMenuTab'],
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true,
      },
    },
    columnTypes: {
      selectedRow: {
        width: 70,
        maxWidth: 70,
        filter: false,
        sortable: false,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
      },
      defaultChip: {
        cellRenderer: 'DefaultChip',
      },
      formattedDate: {
        filterParams: dateFilterParams,
        comparator: dateComparator,
        cellRenderer: 'FormattedDate',
      },
      iconLink: {
        cellRenderer: 'IconLink',
      },
      hiddenColumn: {
        hide: true,
      },
      descriptionPopover: {
        cellRenderer: 'DescriptionPopover',
        cellClass: 'actions-button-cell',
      },
      textFilter: {
        filterParams: {
          filterOptions: ['contains'],
          suppressAndOrCondition: true,
        },
      },
      notificationHeader: {
        filterParams: {
          values: [
            'New task assigned, Task completed',
            'Milestone is past due',
            'Milestone is ready to complete',
            'Milestone is complete',
            'Late New Sign Added',
            'Late Sign Removed',
            'Late Sign Updated',
            'Sign failed to print in ESS',
            'Kits Updated',
            'Project Updated',
            'Project Cancelled',
          ],
          refreshValuesOnOpen: true,
        },
      },
      notificationType: {
        filterParams: {
          values: [
            'Project Changes',
            'Blueprint Changes',
            'Sign Changes',
            'Kit Changes',
            'Milestone Updates',
          ],
          refreshValuesOnOpen: true,
        },
      },
    },
    frameworkComponents: {
      DefaultChip: DefaultChip,
      IconLink: IconLink,
      FormattedDate: FormattedDate,
      DescriptionPopover: DescriptionPopover,
    },
  }

  const rowClassRules = {
    'ag-row-bold': (params: RowClassParams) => params.data?.status === 'UNREAD',
  }

  useEffect(() => {
    getNotificationSettings()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card className="hc-pa-dense">
      <Grid.Container>
        <NotificationsActions />
        <NotificationsViewDropdown gridApi={gridApi} />
      </Grid.Container>
      <Grid.Container justify="space-between">
        <Grid.Item xs={12}>
          <div className="ag-theme-rubix notifications-table">
            <AgGridReact
              onGridReady={onGridReady}
              suppressCellSelection={true}
              suppressRowClickSelection={true}
              gridOptions={gridOptions}
              applyColumnDefOrder={true}
              rowSelection="multiple"
              rowClassRules={rowClassRules}
              animateRows={true}
              rowModelType="serverSide"
              serverSideStoreType="partial"
              cacheBlockSize={20}
              onSortChanged={(e: SortChangedEvent) => onSortChanged(e)}
              onFilterChanged={(e: FilterChangedEvent) => onFilterChanged(e)}
              onSelectionChanged={(e: SelectionChangedEvent) =>
                onSelectionChange(e)
              }
            >
              {NOTIFICATIONS_VIEW.map((column: Column, index: number) => (
                <AgGridColumn
                  field={column.field}
                  headerName={column.headerName}
                  width={column.width || 148}
                  type={column.type}
                  filter={column.filter || 'agTextColumnFilter'}
                  colId={column.colId}
                  key={index}
                />
              ))}
            </AgGridReact>
          </div>
        </Grid.Item>
      </Grid.Container>
    </Card>
  )
}

export default NotificationsTable
