import React from 'react'
import {
  Avatar,
  Card,
  Grid,
  Caption,
  Chip,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { CautionIcon } from '@enterprise-ui/icons'
import { CampaignTypeResponse } from '../../../../models/campaigns/CampaignTypeResponse.model'
import { useAdminCampaignsContext } from '../context/adminCampaignsContext'

export interface Props {
  campaign: CampaignTypeResponse
}

const CampaignCard = ({ campaign }: Props) => {
  const { setCurrentCampaign } = useAdminCampaignsContext()!

  return (
    <Card
      className="sem-CampaignCard"
      elevation={0}
      onClick={() => setCurrentCampaign(campaign)}
    >
      <div className="hc-pa-dense">
        <Grid.Container spacing="dense">
          <Grid.Item
            className={
              campaign.id === ''
                ? 'hc-clr-grey01 new-Campaign-Name'
                : 'hc-clr-grey01 campaign-Name'
            }
            xs
          >
            {campaign.name}
          </Grid.Item>
          <Grid.Item>
            {campaign.parent ? (
              <Avatar aria-label="group" size="dense" color="indigo">
                <span aria-hidden="true">G</span>
              </Avatar>
            ) : (
              <Avatar aria-label="child" size="dense" color="violet">
                <span aria-hidden="true">C</span>
              </Avatar>
            )}
          </Grid.Item>
        </Grid.Container>
        <Grid.Container align="center">
          <Grid.Item className="hc-clr-grey02" xs>
            {campaign.pyramids &&
              (campaign.pyramids.length > 1
                ? campaign.pyramids.map(
                    (pyramid: any) => pyramid.group_name + ' | ',
                  )
                : campaign.pyramids.map((pyramid: any) => pyramid.group_name))}
          </Grid.Item>
          {campaign.id === '' ? (
            <Caption
              above={
                <Chip color="alert" size="dense">
                  <EnterpriseIcon
                    size="inline"
                    className="hc-mr-dense"
                    icon={CautionIcon}
                  />
                  Not Saved
                </Chip>
              }
            ></Caption>
          ) : null}
        </Grid.Container>
      </div>
    </Card>
  )
}

export default CampaignCard
