import React, { useEffect, useState } from 'react'
import AddOnExpensesTable from 'components/ProjectDetails/components/AddOnExpenses/components/AddOnExpensesTable'
import {
  DEFAULT_VIEW,
  PRINT_VENDOR_VIEW,
} from 'components/ProjectDetails/components/AddOnExpenses/constants/colDefs'
import ActionsDropdown from 'components/ProjectDetails/components/AddOnExpenses/components/ActionsDropdown'
import { Grid, Spinner } from '@enterprise-ui/canvas-ui-react'
import SaveButton from 'components/ProjectDetails/components/AddOnExpenses/components/SaveButton'
import { useAddOnContext } from 'components/ProjectDetails/components/AddOnExpenses/context/addOnContext'
import { useProjectDetailsContext } from 'components/ProjectDetails/context/projectDetailsContext'
import CancelButton from 'components/ProjectDetails/components/AddOnExpenses/components/CancelButton'
import { useUserContext } from 'components/App/context/userContext'

const AddOnExpensesTab = () => {
  const { getProjectSigns, currentProject } = useProjectDetailsContext()!
  const { isLoadingExpenses, getAddOnExpenses } = useAddOnContext()!
  const { userPermissions } = useUserContext()!
  const [colDefs, setColDefs] = useState(DEFAULT_VIEW)

  useEffect(() => {
    getProjectSigns(currentProject.project_id)
  }, [currentProject.project_id, getProjectSigns])

  useEffect(() => {
    currentProject.project_id && getAddOnExpenses()
  }, [getAddOnExpenses, currentProject.project_id])

  useEffect(() => {
    if (userPermissions.isPrintVendor || userPermissions.isTPSVendor) {
      setColDefs(PRINT_VENDOR_VIEW)
    }
  }, [userPermissions.isPrintVendor, userPermissions.isTPSVendor])

  return (
    <Grid.Container spacing="none">
      <Grid.Item xs={12} style={{ marginBottom: 12 }}>
        <Grid.Container justify="space-between">
          <Grid.Item>
            <ActionsDropdown />
          </Grid.Item>
          <Grid.Item>
            <CancelButton />
            <SaveButton />
          </Grid.Item>
        </Grid.Container>
      </Grid.Item>
      <Grid.Item xs={12}>
        {isLoadingExpenses ? (
          <Grid.Container
            className="sign-project-loading-container"
            direction="column"
            justify="center"
            align="center"
          >
            <Grid.Item>Loading Add-On Expenses...</Grid.Item>
            <Grid.Item>
              <Spinner />
            </Grid.Item>
          </Grid.Container>
        ) : (
          <AddOnExpensesTable colDefs={colDefs} />
        )}
      </Grid.Item>
    </Grid.Container>
  )
}

export default AddOnExpensesTab
