import React from 'react'
import { Grid, Popover } from '@enterprise-ui/canvas-ui-react'
import Truncate from 'react-truncate'

export interface Props {
  value: string
}

const NotesCell = ({ value }: Props) => {
  const popoverContent = (
    <Grid.Container>
      <Grid.Item className="hc-pv-dense" xs={12}>
        <pre className="sem_NotesPopover">{value}</pre>
      </Grid.Item>
    </Grid.Container>
  )

  return value ? (
    <Popover
      onClick={() => {}}
      content={popoverContent}
      location="bottom"
      size="expanded"
    >
      <Truncate width={180}>{value || ''}</Truncate>
    </Popover>
  ) : null
}

export default NotesCell
