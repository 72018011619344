import React, { Fragment, useEffect } from 'react'
import {
  Button,
  Chip,
  Form,
  Grid,
  Heading,
  Card,
  ExpandableSection,
  Divider,
  Spinner,
  Input,
} from '@enterprise-ui/canvas-ui-react'
import ChildCampaignCard from './ChildCampaignCard'
import { useAdminCampaignsContext } from '../context/adminCampaignsContext'
import { CampaignTypeResponse } from '../../../../models/campaigns/CampaignTypeResponse.model'
import { ChildCampaignType } from '../../../../models/campaigns/ChildCampaignType.model'
import { useAppContext } from '../../../App/context/appContext'
import { CampaignTypeRequest } from '../../../../models/campaigns/CampaignTypeRequest.model'
import { useUserContext } from '../../../App/context/userContext'

const CampaignGroupForm = () => {
  const { setPageHasChanges } = useAppContext()!
  const { userName } = useUserContext()!
  const {
    pyramidList,
    parentCampaign,
    setParentCampaign,
    updateCampaign,
    availableChildCampaignList,
    getAvailableChildCampaignList,
    isLoadingParentCampaign,
  } = useAdminCampaignsContext()!

  useEffect(() => {
    const pyramidIds = parentCampaign.pyramids.map(
      (pyramid: any) => pyramid.group_id,
    )
    getAvailableChildCampaignList(pyramidIds)
  }, [getAvailableChildCampaignList, parentCampaign.pyramids])

  const filteredChildCampaigns =
    parentCampaign.child_types.length === 0
      ? availableChildCampaignList
      : availableChildCampaignList.filter(
          (availableCampaignTypes: CampaignTypeResponse) =>
            !parentCampaign.child_types.find(
              (child: CampaignTypeResponse) =>
                availableCampaignTypes.id === child.id,
            ),
        )

  const parentCampaignTypeRequest = new CampaignTypeRequest({
    name: parentCampaign.name,
    pyramids: parentCampaign.pyramids,
    roster: [],
    parent: true,
    non_adjacency: parentCampaign.non_adjacency,
    child_types: parentCampaign.child_types,
    updated_by: userName,
  })

  return (
    <Fragment>
      <Grid.Container className="sem-ParentCampaignForm">
        <Grid.Item align="right" xs>
          <Button
            type="primary"
            className="saveCampaign-Button"
            onClick={() => {
              updateCampaign(parentCampaign.id, parentCampaignTypeRequest)
              setPageHasChanges(false)
            }}
          >
            Save Campaign Group
          </Button>
        </Grid.Item>
        <Grid.Item xs={12}>
          <div className="hc-pa-dense">
            <Grid.Container>
              <Grid.Item xs={5}>
                <Form.Field
                  id="name"
                  label="Campaign Group Title"
                  value={parentCampaign.name}
                  onChange={(e: any) => {
                    setParentCampaign({
                      ...parentCampaign,
                      name: e.target.value,
                    })
                    setPageHasChanges(true)
                  }}
                  required
                />
              </Grid.Item>
              <Grid.Item xs={5}>
                <Card className={'sem-CampaignPyramidCard'} elevation={0}>
                  <ExpandableSection padding="dense">
                    <Heading className="sem-RosterTitle hc-pr-normal" size={6}>
                      Pyramids
                    </Heading>
                    <ExpandableSection.Content>
                      <Divider />
                      <div className="hc-pa-normal">
                        <Input.Checkboxes
                          id="campaign_pyramids"
                          options={pyramidList}
                          onUpdate={(e: any, value: any) => {
                            if (value.includes(0)) {
                              const withoutZero = pyramidList
                                .filter((pyramid: any) =>
                                  value.find(
                                    (id: number) => pyramid.value === id,
                                  ),
                                )
                                .map((pyramid: any) => ({
                                  group_id: pyramid.value,
                                  group_name: pyramid.label,
                                }))
                              const firstPyramid: any = {
                                group_id: 0,
                                group_name: 'NON RETAIL',
                              }
                              const selectedPyramids =
                                withoutZero.concat(firstPyramid)
                              setParentCampaign({
                                ...parentCampaign,
                                pyramids: selectedPyramids,
                              })
                              const pyramidIds = parentCampaign.pyramids.map(
                                (pyramid: any) => pyramid.group_id,
                              )
                              getAvailableChildCampaignList(pyramidIds)
                              setPageHasChanges(true)
                            } else {
                              let selectedPyramids = pyramidList
                                .filter((pyramid: any) =>
                                  value.find(
                                    (id: number) => pyramid.value === id,
                                  ),
                                )
                                .map((pyramid: any) => ({
                                  group_id: pyramid.value,
                                  group_name: pyramid.label,
                                }))
                              setParentCampaign({
                                ...parentCampaign,
                                pyramids: selectedPyramids,
                              })
                              const pyramidIds = parentCampaign.pyramids.map(
                                (pyramid: any) => pyramid.group_id,
                              )
                              getAvailableChildCampaignList(pyramidIds)
                              setPageHasChanges(true)
                            }
                          }}
                          value={parentCampaign.pyramids.map(
                            (pyramid: any) => pyramid.group_id,
                          )}
                        />
                      </div>
                    </ExpandableSection.Content>
                  </ExpandableSection>
                </Card>
              </Grid.Item>
            </Grid.Container>
          </div>
        </Grid.Item>
        {isLoadingParentCampaign ? (
          <Grid.Container
            className="sign-projects-loading-container"
            direction="column"
            justify="center"
            align="center"
          >
            <Grid.Item>Loading Campaign Types...</Grid.Item>
            <Grid.Item>
              <Spinner />
            </Grid.Item>
          </Grid.Container>
        ) : (
          <Fragment>
            <div className="hc-pa-normal">
              <Heading size={4} className="hc-clr-grey02">
                Campaign Types in Group:
              </Heading>
              {parentCampaign.child_types &&
                parentCampaign.child_types.map(
                  (childCampaign: ChildCampaignType) => (
                    <Chip
                      className="sem-RosterChip"
                      onRequestDelete={() => {
                        const childCampaigns =
                          parentCampaign.child_types.filter(
                            (child) => child.id !== childCampaign.id,
                          )
                        setParentCampaign({
                          ...parentCampaign,
                          child_types: childCampaigns,
                        })
                        setPageHasChanges(true)
                      }}
                      size="dense"
                      key={childCampaign.id}
                    >
                      {childCampaign.name}
                    </Chip>
                  ),
                )}
            </div>
            <Grid.Container align="flex-end">
              <Grid.Item xs>
                <div className="hc-pa-normal button-Container">
                  <Heading size={4} className="hc-clr-gresy02">
                    Available Campaign Types to Add:
                  </Heading>
                </div>
              </Grid.Item>
              <Grid.Item></Grid.Item>
              <Grid.Item></Grid.Item>
            </Grid.Container>

            <div className="hc-pa-normal card-Container">
              {filteredChildCampaigns &&
                filteredChildCampaigns.map(
                  (childCampaign: CampaignTypeResponse) => (
                    <ChildCampaignCard
                      campaign={childCampaign}
                      key={childCampaign.id}
                    />
                  ),
                )}
            </div>
          </Fragment>
        )}
      </Grid.Container>
    </Fragment>
  )
}

export default CampaignGroupForm
