export const EXPENSES_TYPE_OPTIONS = [
  {
    value: 'Kitting',
    label: 'Kitting',
  },
  {
    value: 'Freight',
    label: 'Freight',
  },
  {
    value: 'Prototyping',
    label: 'Prototyping',
  },
  {
    value: 'Instruction Sheets',
    label: 'Instruction Sheets',
  },
  {
    value: 'Misc',
    label: 'Misc',
  },
]
