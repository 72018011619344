import React, { Fragment, useState, useEffect } from 'react'
import {
  Grid,
  Button,
  Heading,
  Spinner,
  Dropdown,
  Modal,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, {
  CaretDownIcon,
  ExportIcon,
  ImportIcon,
  TrashIcon,
} from '@enterprise-ui/icons'

import { PogInfo } from '../../../../models/signs/SignResponse.model'
import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import {
  ColumnApi,
  GridApi,
  RowClassParams,
  RowNode,
  SelectionChangedEvent,
  ValueGetterParams,
} from 'ag-grid-community'
import { POG_VIEW_COLUMNS } from '../../../../models/agGrid/AgGridPogView'
import { Column } from '../../../../models/agGrid/AgGridColumn.model'
import TableChip from '../../../ProjectDetails/components/ProjectSignList/components/signListViews/components/TableChip'
import moment from 'moment'
import { useUserContext } from '../../../App/context/userContext'
import { useAppContext } from '../../../App/context/appContext'
import { DialogProps } from '../../../../models/app/DialogProps.model'
import { PogSelectionRequest } from '../../../../models/signs/PogSelectionRequest.modet'

export interface Props {
  pogDetails: PogInfo[]
  isVisible: boolean
  toggleVisible: Function
  isPogModalLoading: Boolean
  currentSignId: string
  currentSignDPCI?: String
  includePogs: Function
  excludePogs: Function
  refreshSignList?: Function
}

const PogModal = ({
  pogDetails,
  isVisible,
  toggleVisible,
  isPogModalLoading,
  currentSignId,
  currentSignDPCI,
  includePogs,
  excludePogs,
  refreshSignList = () => {},
}: Props) => {
  const [gridApi, setGridApi] = useState<GridApi>()
  const [, setGridColumnApi] = useState<ColumnApi>()
  const [rowData, setRowData] = useState<PogInfo[]>(pogDetails)
  const { userPermissions, userName } = useUserContext()!
  const { setDialogProps } = useAppContext()!
  const [selectedRows, setSelectedRows] = useState<PogInfo[]>([])

  const gridOptions = {
    defaultColDef: {
      resizable: true,
      sortable: true,
      filter: true,
      width: 120,
    },
    columnTypes: {
      tableChip: {
        cellRenderer: 'TableChip',
      },
      fixed: {
        lockPinned: true,
        resizable: false,
        suppressSizeToFit: true,
        pinned: 'left',
      },
      pogIncluded: {
        valueGetter: (params: ValueGetterParams) =>
          params.data.excluded ? 'No' : 'Yes',
      },
      selectedRow: {
        width: 50,
        maxWidth: 50,
        filter: false,
        sortable: false,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
      },
      date: {
        valueGetter: (params: ValueGetterParams) =>
          params.data.last_modified_date
            ? moment(params.data.last_modified_date).format('MM/DD/YYYY LT')
            : '',
      },
    },
    frameworkComponents: {
      TableChip: TableChip,
    },
  }

  useEffect(() => {
    setRowData(pogDetails)
  }, [pogDetails])

  const rowClassRules = {
    'ag-row-pog-excluded': (params: RowClassParams) => params.data.excluded,
  }

  const onGridReady = (params: any) => {
    setGridApi(params.api)
    setGridColumnApi(params.columnApi)
  }

  const onSelectionChange = (e: SelectionChangedEvent) => {
    if (e.api.getSelectedNodes().length > 0) {
      setSelectedRows(
        e.api.getSelectedNodes().map((node: RowNode) => new PogInfo(node.data)),
      )
    } else {
      setSelectedRows([])
    }
  }

  const isExcluded = (action: string) => {
    switch (action) {
      case 'exclude_pog':
        return (
          !userPermissions.isKitEditor ||
          selectedRows.length === 0 ||
          selectedRows.some((pog) => pog.excluded === true)
        ) // if in the selected rows some are already excluded

      case 'include_pog':
        return (
          !userPermissions.isKitEditor ||
          selectedRows.length === 0 ||
          selectedRows.some((pog) => pog.excluded === false)
        ) // if in the selected rows some are already included
      default:
        return false
    }
  }

  const handleClose = () => {
    toggleVisible(false)
    gridApi?.clearFocusedCell()
    gridApi?.deselectAll()
  }

  return (
    <Modal
      className="pog-list-modal"
      isVisible={isVisible}
      onRefuse={() => toggleVisible(false)}
      headingText="POG List"
    >
      <Grid.Container align="flex-start" justify="space-between">
        <Grid.Item>
          <Fragment>
            <Grid.Item className="hc-ph-dense hc-pb-dense">
              <Dropdown size="normal" location="bottom-left">
                <Button type="ghost">
                  Actions
                  <EnterpriseIcon
                    className="hc-ml-dense"
                    icon={CaretDownIcon}
                  />
                </Button>
                <Dropdown.Menu>
                  <Dropdown.MenuItem
                    disabled={isExcluded('exclude_pog')}
                    onClick={() => {
                      setDialogProps(
                        new DialogProps({
                          headingText:
                            'Are you sure you want to exclude these POGs?',
                          approveButtonText: 'Yes',
                          refuseButtonText: 'No',
                          onApprove: () => {
                            excludePogs(
                              currentSignId,
                              new PogSelectionRequest({
                                pog_ids: selectedRows.map(
                                  (pog: PogInfo) => pog.pog_id,
                                ),
                                excluded: true,
                                updated_by: userName,
                              }),
                            )
                            setSelectedRows([])
                            refreshSignList()
                            setDialogProps(new DialogProps())
                          },
                          onRefuse: () => {
                            setDialogProps(new DialogProps())
                          },
                        }),
                      )
                    }}
                  >
                    <EnterpriseIcon className="hc-mr-normal" icon={TrashIcon} />
                    Exclude Selected POGs
                  </Dropdown.MenuItem>

                  <Dropdown.MenuItem
                    className="sem_DropdownMenuItem--action divider"
                    disabled={isExcluded('include_pog')}
                    onClick={() => {
                      setDialogProps(
                        new DialogProps({
                          headingText:
                            'Are you sure you want to include these POGs?',
                          approveButtonText: 'Yes',
                          refuseButtonText: 'No',
                          onApprove: () => {
                            includePogs(
                              currentSignId,
                              new PogSelectionRequest({
                                pog_ids: selectedRows.map(
                                  (pog: PogInfo) => pog.pog_id,
                                ),
                                excluded: false,
                                updated_by: userName,
                              }),
                            )
                            setSelectedRows([])
                            refreshSignList()
                            setDialogProps(new DialogProps())
                          },
                          onRefuse: () => {
                            setDialogProps(new DialogProps())
                          },
                        }),
                      )
                    }}
                  >
                    <EnterpriseIcon
                      className="hc-mr-normal"
                      icon={ImportIcon}
                    />
                    Include Selected POGs
                  </Dropdown.MenuItem>

                  <Dropdown.MenuItem
                    className="sem_DropdownMenuItem--action divider"
                    onClick={() => {
                      gridApi?.exportDataAsCsv({
                        fileName: `POG Info.csv`,
                      })
                    }}
                  >
                    <EnterpriseIcon
                      className="hc-mr-normal"
                      icon={ExportIcon}
                    />
                    Export as CSV
                  </Dropdown.MenuItem>
                </Dropdown.Menu>
              </Dropdown>
            </Grid.Item>
          </Fragment>
        </Grid.Item>
      </Grid.Container>
      {isPogModalLoading ? (
        <Grid.Container justify="center">
          <Grid.Item>
            <Spinner />
          </Grid.Item>
        </Grid.Container>
      ) : (
        <Fragment>
          <Grid.Container className="hc-mv-normal">
            <Grid.Item xs={12}>
              <div className="ag-theme-rubix pog-table">
                <AgGridReact
                  gridOptions={gridOptions}
                  onGridReady={onGridReady}
                  rowData={rowData}
                  applyColumnDefOrder={true}
                  suppressCellSelection={true}
                  suppressRowClickSelection={true}
                  rowSelection="multiple"
                  rowClassRules={rowClassRules}
                  onSelectionChanged={(e: SelectionChangedEvent) =>
                    onSelectionChange(e)
                  }
                >
                  {POG_VIEW_COLUMNS &&
                    POG_VIEW_COLUMNS.map((column: Column, index: number) => (
                      <AgGridColumn
                        field={column.field}
                        headerName={column.headerName}
                        width={column.width || 148}
                        type={column.type}
                        colId={column.colId}
                        key={index}
                      />
                    ))}
                </AgGridReact>
              </div>
            </Grid.Item>
          </Grid.Container>
        </Fragment>
      )}
      <Grid.Container
        className="hc-mv-normal"
        align="center"
        direction="row-reverse"
      >
        <Grid.Item>
          <Button type="secondary" size="dense" onClick={() => handleClose()}>
            Close
          </Button>
        </Grid.Item>
        <Grid.Item xs={true}>
          <Heading className="hc-clr-grey02" size={6}>
            {`Sign DPCI: ${currentSignDPCI}`}
          </Heading>{' '}
        </Grid.Item>

        {!isPogModalLoading && (
          <Grid.Item xs={true}>
            <Heading className="hc-clr-grey02" size={6}>
              {`Total Pog Count: ${pogDetails.length}`}
            </Heading>
          </Grid.Item>
        )}
      </Grid.Container>
    </Modal>
  )
}

export default PogModal
