import appConfig from '../../../../../config/appConfig'
import {
  PROJECT_SERVICE_API_DOMAIN_URL,
  SEARCH_RESPONSE_SIZE,
} from '../../../../App/constants/appConstants'
import Axios from 'axios'
import { SearchRequest } from '../../../../../models/app/SearchRequest.model'
import { DropdownOption } from '../../../../../models/app/DropdownOption.model'

export const fetchPidList = async () => {
  const config = await appConfig()
  const result: any = await Axios.post(
    `${
      config.apiDomainUrl + PROJECT_SERVICE_API_DOMAIN_URL
    }/projects/search?per_page=${SEARCH_RESPONSE_SIZE}`,
    new SearchRequest({
      facets: {
        sapProjectId: 'sap_project_id',
      },
      exclude_filters: {
        'workflow.status': ['Complete', 'Archive', 'Cancel', 'Pending'],
      },
    }),
  )
  return Object.keys(result.data.facets.sapProjectId).map((key: string) => {
    return new DropdownOption({ id: key, label: key, value: key })
  })
}

export const getProjectBySapProjectId = async (sapProjectId: string) => {
  const config = await appConfig()
  const response: any = await Axios.post(
    `${
      config.apiDomainUrl + PROJECT_SERVICE_API_DOMAIN_URL
    }/projects/search?per_page=${SEARCH_RESPONSE_SIZE}`,
    new SearchRequest({
      include_filters: {
        sap_project_id: [sapProjectId],
      },
    }),
  )
  return response.data.search[0].result.project_id
}
