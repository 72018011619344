import { MilestoneTemplate } from './MilestoneTemplate.model'
import { get } from 'lodash'

export class BlueprintMilestone {
  template_name: string
  schedule_type: string
  master_template: boolean
  milestones: MilestoneTemplate[]
  updated_by: string

  constructor(props: any = {}) {
    this.template_name = props.template_name || ''
    this.schedule_type = props.schedule_type || ''
    this.master_template = props.master_template || false
    this.milestones = get(props, 'milestones', []).map(
      (milestone: any) => new MilestoneTemplate(milestone),
    )
    this.updated_by = props.updated_by || ''
  }
}
