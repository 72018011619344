import React from 'react'
import { useDashboardContext } from 'components/DashboardV2/context/dashboardContext'
import { useAgGridDashboardContext } from 'components/DashboardV2/context/agGridDashboardContext'
import { ErrorCard } from 'components/ErrorCard'
import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import { BLUEPRINT_VIEW_COLUMNS } from '../../../../models/agGrid/AgGridDashboardViewV2'
import { Column } from '../../../../models/agGrid/AgGridColumn.model'
import { Grid } from '@enterprise-ui/canvas-ui-react'

const BlueprintSummaryGrid = () => {
  const { dashboardErrors } = useDashboardContext()!
  const {
    gridOptions,
    onFirstDataRender,
    onFilterChange,
    onSortChanged,
    onBlueprintGridReady,
  } = useAgGridDashboardContext()!

  return (
    <Grid.Item xs={12}>
      <div className="ag-theme-rubix dashboard-table">
        {dashboardErrors.BLUEPRINT_SUMMARY_LIST ? (
          <ErrorCard
            text={`${dashboardErrors.BLUEPRINT_SUMMARY_LIST}. Please try again.`}
          />
        ) : (
          <AgGridReact
            gridOptions={gridOptions}
            onGridReady={onBlueprintGridReady}
            rowModelType="serverSide"
            serverSideStoreType="partial"
            cacheBlockSize={50}
            onFirstDataRendered={onFirstDataRender}
            suppressRowClickSelection={true}
            onFilterChanged={(e) => onFilterChange(e)}
            onSortChanged={(e) => onSortChanged(e)}
          >
            {BLUEPRINT_VIEW_COLUMNS.map((column: Column, index: number) => (
              <AgGridColumn
                field={column.field}
                colId={column.colId}
                headerName={column.headerName}
                width={column.width || 148}
                type={column.type}
                filter={column.filter || 'agTextColumnFilter'}
                key={index}
              />
            ))}
          </AgGridReact>
        )}
      </div>
    </Grid.Item>
  )
}

export default BlueprintSummaryGrid
