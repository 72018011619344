import React from 'react'
import { ProjectFund } from '../../../../../../../models/projects/ProjectFundInfo.model'
import SapTableProject from '../../../../../../../models/projects/SapTableProject.model'
import { useBlueprintProjectListContext } from '../../../context/blueprintProjectListContext'

export interface Props {
  value: string
  data: SapTableProject
}

const FundCellRender = ({ value }: Props) => {
  const { projectFunds } = useBlueprintProjectListContext()!

  return (
    <div className="ag-cell-value">
      {projectFunds.filter(
        (fund: ProjectFund) => value?.toString() === fund.fund_id,
      )[0]?.fund_name || ''}
    </div>
  )
}

export default FundCellRender
