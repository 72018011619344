import React, { KeyboardEvent, useState } from 'react'
import {
  ExpandableSection,
  Heading,
  Divider,
  Grid,
  Button,
  Form,
  Chip,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { FilterIcon } from '@enterprise-ui/icons'
import moment from 'moment'
import { get } from 'lodash'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { useProjectListContext } from '../context/projectListContext'
import { useProjectFilterContext } from '../context/projectFilterContext'
import { DropdownOption } from '../../../models/app/DropdownOption.model'
import { SearchRequest } from '../../../models/app/SearchRequest.model'
import { ProjectSearchFacets } from '../../../models/projects/ProjectSearchFacets.model'
import { useFilterContext } from '../../App/context/filterContext'

const ProjectFilterCard = () => {
  const { submitProjectSearch, projectSearchResponse } =
    useProjectListContext()!
  const {
    setIncludeFilters,
    setPartialFilters,
    setDateFilters,
    refreshFiltersFromPyramid,
    refreshFiltersFromDivision,
  } = useProjectFilterContext()!
  const { projectFilters, setProjectFilters } = useFilterContext()!

  const [hasFilters, setHasFilters] = useState(false)
  const [isExpanded, setIsExpanded] = useState(true)

  const createFacetDropdownOptions = (values: any) => {
    return values
      ? Object.keys(values).map(
          (key: string) =>
            new DropdownOption({ id: key, value: key, label: key }),
        )
      : []
  }

  const handleIncludeFilterUpdate = async (id: string, value: string[]) => {
    if (value[0] === '') {
      value = []
    }
    setHasFilters(true)

    if (id === projectFilters.facets.pyramid) {
      refreshFiltersFromPyramid(value)
    } else if (id === projectFilters.facets.division) {
      refreshFiltersFromDivision(value)
    } else {
      setIncludeFilters(id, value)
    }
  }

  const handlePartialFilterUpdate = async (id: string, value: string[]) => {
    if (value[0] === '') {
      value = []
    }
    setPartialFilters(id, value)
    setHasFilters(true)
  }

  const handleDateUpdate = (id: string, value: string) => {
    setDateFilters(id, value)
    setHasFilters(true)
  }

  const handleClear = () => {
    const emptyFilters = new SearchRequest<ProjectSearchFacets>({
      facets: new ProjectSearchFacets(),
      sort_fields: {
        set_date: 'desc',
      },
    })
    setProjectFilters(emptyFilters)
    submitProjectSearch(emptyFilters)
    setIsExpanded(false)
    setHasFilters(false)
  }

  const isEnterKey = (cb: () => void) => (e: KeyboardEvent<Element>) => {
    if (e.key === 'Enter') {
      cb()
    }
  }

  return (
    <ExpandableSection
      className={`hc-mt-none sem_FilterCard ${hasFilters ? 'modified' : ''}`}
      padding="none"
      expanded={isExpanded}
      onExpand={() => setIsExpanded(!isExpanded)}
    >
      <Grid.Container
        spacing="dense"
        justify="space-between"
        onKeyUp={isEnterKey(() => {
          submitProjectSearch(projectFilters)
          setHasFilters(false)
        })}
      >
        <Grid.Item xs={true}>
          <Heading className="sem_TransitionFilterCard--Heading" size={6}>
            <EnterpriseIcon
              size="inline"
              className="hc-clr-grey02 hc-mr-dense"
              icon={FilterIcon}
            />
            Project Filters
          </Heading>
        </Grid.Item>
        <Grid.Item justify="flex-end">
          <Chip className="hc-mt-none" size="dense">
            <span className="sem_OverviewTitle">
              {projectSearchResponse.total_results
                ? projectSearchResponse.total_results
                : '0'}{' '}
              Results
            </span>
          </Chip>
        </Grid.Item>
      </Grid.Container>
      <ExpandableSection.Content>
        <Divider className="hc-pa-dense" />
        {projectSearchResponse.facets !== null && (
          <div className="hc-pa-dense">
            <Grid.Container justify="space-between">
              <Grid.Item xs={5}>
                <Autocomplete
                  label="Pyramid"
                  options={createFacetDropdownOptions(
                    projectSearchResponse.facets.pyramid,
                  )}
                  optionHeight="dense"
                  multiselect
                  onUpdate={(e: any, value: DropdownOption<string>[]) =>
                    handleIncludeFilterUpdate(
                      projectFilters.facets.pyramid,
                      // filter values need to be kept in state as an array of string to conform with other filters
                      value.map((item) => item.value),
                    )
                  }
                  value={get(
                    projectFilters,
                    `include_filters["${projectFilters.facets.pyramid}"]`,
                    [],
                  ).map(
                    (item: string) =>
                      new DropdownOption({
                        id: item,
                        value: item,
                        label: item,
                      }),
                  )}
                />
              </Grid.Item>
              <Grid.Item xs={true}>
                <Form.Field
                  type="date"
                  className="sem_InlineDatePicker"
                  id="setDateFrom"
                  label="Set Date From"
                  value={
                    projectFilters.range_filters?.set_date?.from
                      ? moment(
                          projectFilters.range_filters?.set_date?.from,
                        ).format('YYYY-MM-DD')
                      : ''
                  }
                  onChange={(e: any) =>
                    handleDateUpdate(e.target.id, e.target.value)
                  }
                />
              </Grid.Item>
              <Grid.Item xs={true}>
                <Form.Field
                  type="date"
                  className="sem_InlineDatePicker"
                  id="setDateTo"
                  label="Set Date To"
                  value={
                    projectFilters.range_filters?.set_date?.to
                      ? moment(
                          projectFilters.range_filters?.set_date?.to,
                        ).format('YYYY-MM-DD')
                      : ''
                  }
                  onChange={(e: any) =>
                    handleDateUpdate(e.target.id, e.target.value)
                  }
                />
              </Grid.Item>
            </Grid.Container>
            <Grid.Container>
              <Grid.Item xs={5}>
                <Autocomplete
                  label="Division"
                  options={createFacetDropdownOptions(
                    projectSearchResponse.facets.division,
                  )}
                  optionHeight="dense"
                  multiselect
                  onUpdate={(e: any, value: DropdownOption<string>[]) =>
                    handleIncludeFilterUpdate(
                      projectFilters.facets.division,
                      // filter values need to be kept in state as an array of string to conform with other filters
                      value.map((item) => item.value),
                    )
                  }
                  value={get(
                    projectFilters,
                    `include_filters["${projectFilters.facets.division}"]`,
                    [],
                  ).map(
                    (item: string) =>
                      new DropdownOption({
                        id: item,
                        value: item,
                        label: item,
                      }),
                  )}
                />
              </Grid.Item>

              <Grid.Item xs={3}>
                <Form.Field
                  id="projectIdFilter"
                  label="Project Id"
                  onKeyDown={(e: any) => {
                    if (e.key === 'Enter') {
                      submitProjectSearch(projectFilters)
                      setHasFilters(false)
                    }
                  }}
                  value={projectFilters.partial_filters?.sap_project_id}
                  onChange={(e: any) =>
                    handlePartialFilterUpdate('sap_project_id', [
                      e.target.value,
                    ])
                  }
                />
              </Grid.Item>
              <Grid.Item xs={3}>
                <Form.Field
                  id="projectNameFilter"
                  label="Project Name"
                  onKeyDown={(e: any) => {
                    if (e.key === 'Enter') {
                      submitProjectSearch(projectFilters)
                      setHasFilters(false)
                    }
                  }}
                  value={projectFilters.partial_filters?.name}
                  onChange={(e: any) =>
                    handlePartialFilterUpdate('name', [e.target.value])
                  }
                />
              </Grid.Item>
            </Grid.Container>
            <Grid.Container>
              <Grid.Item xs={5}>
                <Autocomplete
                  label="Department"
                  options={createFacetDropdownOptions(
                    projectSearchResponse.facets.department,
                  )}
                  optionHeight="dense"
                  multiselect
                  onUpdate={(e: any, value: DropdownOption<string>[]) =>
                    handleIncludeFilterUpdate(
                      projectFilters.facets.department,
                      // filter values need to be kept in state as an array of string to conform with other filters
                      value.map((item) => item.value),
                    )
                  }
                  value={get(
                    projectFilters,
                    `include_filters["${projectFilters.facets.department}"]`,
                    [],
                  ).map(
                    (item: string) =>
                      new DropdownOption({
                        id: item,
                        value: item,
                        label: item,
                      }),
                  )}
                />
              </Grid.Item>
              <Grid.Item xs={2}>
                <Autocomplete
                  label="Type"
                  options={createFacetDropdownOptions(
                    projectSearchResponse.facets.type,
                  )}
                  optionHeight="dense"
                  multiselect
                  onUpdate={(e: any, value: DropdownOption<string>[]) =>
                    handleIncludeFilterUpdate(
                      projectFilters.facets.type,
                      // filter values need to be kept in state as an array of string to conform with other filters
                      value.map((item) => item.value),
                    )
                  }
                  value={get(
                    projectFilters,
                    `include_filters["${projectFilters.facets.type}"]`,
                    [],
                  ).map(
                    (item: string) =>
                      new DropdownOption({
                        id: item,
                        value: item,
                        label: item,
                      }),
                  )}
                />
              </Grid.Item>
              <Grid.Item xs={3}>
                <Form.Field
                  id="blueprintNameFilter"
                  label="Blueprint Name"
                  onKeyDown={(e: any) => {
                    if (e.key === 'Enter') {
                      submitProjectSearch(projectFilters)
                      setHasFilters(false)
                    }
                  }}
                  value={projectFilters.partial_filters?.blueprint_name}
                  onChange={(e: any) =>
                    handlePartialFilterUpdate('blueprint_name', [
                      e.target.value,
                    ])
                  }
                />
              </Grid.Item>
              <Grid.Item xs={2}>
                <Autocomplete
                  label="Campaign Type"
                  options={createFacetDropdownOptions(
                    projectSearchResponse.facets.campaignType,
                  )}
                  optionHeight="dense"
                  multiselect
                  onUpdate={(e: any, value: DropdownOption<string>[]) =>
                    handleIncludeFilterUpdate(
                      projectFilters.facets.campaignType,
                      // filter values need to be kept in state as an array of string to conform with other filters
                      value.map((item) => item.value),
                    )
                  }
                  value={get(
                    projectFilters,
                    `include_filters["${projectFilters.facets.campaignType}"]`,
                    [],
                  ).map(
                    (item: string) =>
                      new DropdownOption({
                        id: item,
                        value: item,
                        label: item,
                      }),
                  )}
                />
              </Grid.Item>
            </Grid.Container>

            {/* Apply Button */}
            <Grid.Container className="hc-mt-normal" direction="row-reverse">
              <Grid.Item className="hc-pb-dense hc-pt-normal">
                <Button
                  fullWidth
                  type="primary"
                  size="dense"
                  disabled={!hasFilters}
                  onClick={() => {
                    submitProjectSearch(projectFilters)
                    setHasFilters(false)
                  }}
                >
                  Apply Filters
                </Button>
              </Grid.Item>
              <Grid.Item className="hc-pb-dense hc-pt-normal">
                <Button
                  type="secondary"
                  fullWidth
                  size="dense"
                  onClick={() => {
                    handleClear()
                  }}
                >
                  Clear Filters
                </Button>
              </Grid.Item>
            </Grid.Container>
          </div>
        )}
      </ExpandableSection.Content>
    </ExpandableSection>
  )
}

export default ProjectFilterCard
