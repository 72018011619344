import React from 'react'
import { Button, Form, Grid, Modal } from '@enterprise-ui/canvas-ui-react'
import { useAdminCampaignsContext } from '../context/adminCampaignsContext'
import { CampaignTypeRequest } from '../../../../models/campaigns/CampaignTypeRequest.model'

export interface Props {
  childCampaignTypeRequest: CampaignTypeRequest
}

const CascadeModal = ({ childCampaignTypeRequest }: Props) => {
  const {
    cascadeModalOpen,
    setCascadeModalOpen,
    childCampaign,
    updateCampaign,
    rosterWillCascade,
    setRosterWillCascade,
  } = useAdminCampaignsContext()!

  return (
    <Modal
      headingText="Cascade Roster Changes"
      isVisible={cascadeModalOpen}
      onRefuse={() => {
        setCascadeModalOpen(false)
        setRosterWillCascade(false)
      }}
    >
      <div className="hc-pa-normal">
        <Form.Field
          id="cascadeRoster"
          type="radio"
          label="Roster Options"
          options={[
            {
              value: false,
              label: 'Only future rosters will have this change',
            },
            { value: true, label: 'Cascade changes to existing rosters' },
          ]}
          onUpdate={(id: string, value: boolean) => setRosterWillCascade(value)}
          value={rosterWillCascade}
        />
        <Grid.Container direction="row-reverse" spacing="dense">
          <Grid.Item>
            <Button
              type="primary"
              onClick={() => {
                updateCampaign(childCampaign.id, {
                  ...childCampaignTypeRequest,
                  cascade_roster: rosterWillCascade,
                })
                setCascadeModalOpen(false)
                setRosterWillCascade(false)
              }}
            >
              Save
            </Button>
          </Grid.Item>
          <Grid.Item>
            <Button
              onClick={() => {
                setCascadeModalOpen(false)
                setRosterWillCascade(false)
              }}
            >
              Cancel
            </Button>
          </Grid.Item>
        </Grid.Container>
      </div>
    </Modal>
  )
}

export default CascadeModal
