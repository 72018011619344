import React, { createContext, useState, useContext, useCallback } from 'react'
import { connect } from 'react-redux'
import {
  PROJECT_SERVICE_API_DOMAIN_URL,
  ADMIN_SERVICE_API_DOMAIN_URL,
  TOASTER_DEFAULTS,
} from 'components/App/constants/appConstants'
import { useEnv } from '@praxis/component-runtime-env'
import Axios from 'axios'
import { BlueprintCampaign } from '../../../../../models/blueprints/BlueprintCampaign.model'
import { ProjectDetail } from '../../../../../models/projects/ProjectDetail.model'
import { ProjectRequest } from '../../../../../models/projects/ProjectRequest.model'
import { NEW_SIGN_PROJECT } from '../constants/signProjectConstants'
import { useAppContext } from 'components/App/context/appContext'
import { useUserContext } from 'components/App/context/userContext'
import { useBlueprintDetailsContainerContext } from '../../../context/blueprintDetailsContainerContext'
import { RosterRequest } from '../../../../../models/roster/RosterRequest.model'
import { Transition } from '../../../../../models/transitions/Transition.model'
import { useToaster } from '@enterprise-ui/canvas-ui-react'

type ContextType = {
  expandedProjectId: string
  setExpandedProjectId: (id: string) => void
  blueprintCampaigns: BlueprintCampaign[]
  setBlueprintCampaigns: (campaigns: BlueprintCampaign[]) => void
  modifiedProjectIds: Set<string>
  setModifiedProjectIds: Function
  addToModifiedProjectIds: (id: string) => void
  removeFromModifiedProjectIds: (id: string) => void
  getProjectCampaigns: () => void
  cancelSignProject: (id: string) => void
  updateSignProject: (project: ProjectDetail) => void
  createSignProject: (project: ProjectDetail) => void
  expandedProjectTransitionFormValues: Transition[]
  setExpandedProjectTransitionFormValues: (transitions: Transition[]) => void
}

export const SignProjectContext = createContext<ContextType | undefined>(
  undefined,
)

type Props = {
  children: React.ReactNode
}

const SignProjectProviderComponent = ({ children }: Props) => {
  const { setFullPageLoadingMessage, setPageHasChanges } = useAppContext()!
  const { currentBlueprint, setSignProjects } =
    useBlueprintDetailsContainerContext()!
  const { userName } = useUserContext()!
  const env = useEnv()
  const makeToast = useToaster()

  const [expandedProjectId, setExpandedProjectId] = useState('')
  const [blueprintCampaigns, setBlueprintCampaigns] = useState<
    BlueprintCampaign[]
  >([])
  const [modifiedProjectIds, setModifiedProjectIds] = useState<Set<string>>(
    new Set(),
  )
  const [
    expandedProjectTransitionFormValues,
    setExpandedProjectTransitionFormValues,
  ] = useState<Transition[]>([])

  const addToModifiedProjectIds = (id: string) =>
    !modifiedProjectIds.has(id) &&
    setModifiedProjectIds(
      (previousState: Set<string>) =>
        new Set([...Array.from(previousState), id]),
    )
  const removeFromModifiedProjectIds = (id: string) =>
    setModifiedProjectIds(
      (previousState: Set<string>) =>
        new Set([
          ...Array.from(previousState).filter((projectId) => id !== projectId),
        ]),
    )

  const getProjectCampaigns = useCallback(async () => {
    let newProjectCampaignList = []
    if (currentBlueprint.campaign.type === 'CHILD') {
      newProjectCampaignList = [
        new BlueprintCampaign(currentBlueprint.campaign),
      ]
    } else if (currentBlueprint.campaign.id !== '') {
      try {
        const res = await Axios.get(
          `${env.apiDomainUrl + ADMIN_SERVICE_API_DOMAIN_URL}/campaign_types/${
            currentBlueprint.campaign.id
          }`,
        )
        newProjectCampaignList = res.data.child_types.map(
          (campaign: any) => new BlueprintCampaign(campaign),
        )
      } catch (err: any) {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'error',
          heading: 'Failed to Get Project Campaigns',
          message: err.response.data.message,
        })
      }
    }
    setBlueprintCampaigns(newProjectCampaignList)
  }, [currentBlueprint.campaign, makeToast, env.apiDomainUrl])

  const cancelSignProject = async (projectId: string) => {
    setFullPageLoadingMessage('cancelling Project...')

    try {
      await Axios.put(
        `${
          env.apiDomainUrl + PROJECT_SERVICE_API_DOMAIN_URL
        }/projects/cancel?project_id=${projectId}`,
      )
      setSignProjects((previousState: ProjectDetail[]) =>
        previousState.filter(
          (existingProject) => existingProject.project_id !== projectId,
        ),
      )
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'success',
        heading: 'Sign Project Cancelled',
        message: 'Successfully cancelled project',
      })
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Cancel Sign Project Error',
        message: err.response.data.message,
      })
    }
    setFullPageLoadingMessage('')
  }

  const updateSignProject = async (signProject: ProjectDetail) => {
    setFullPageLoadingMessage('Updating Project...')
    try {
      const res = await Axios.put(
        `${env.apiDomainUrl + PROJECT_SERVICE_API_DOMAIN_URL}/projects/${
          signProject.project_id
        }`,
        new ProjectRequest({
          ...signProject,
          division_ids: signProject.divisions.map(
            (division) => division.division_id,
          ),
          department_ids: signProject.departments.map(
            (department) => department.department_id,
          ),
        }),
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )

      modifiedProjectIds.size === 1 && setPageHasChanges(false)
      removeFromModifiedProjectIds(signProject.project_id)

      setSignProjects((previousState: ProjectDetail[]) =>
        previousState.map((project: ProjectDetail) =>
          project.project_id === signProject.project_id
            ? new ProjectDetail(res.data)
            : project,
        ),
      )
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'success',
        heading: 'Project Saved',
        message: 'Successfully saved project',
      })
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Save Project',
        message: err.response.data.message,
      })
    }
    setFullPageLoadingMessage('')
  }

  const createSignProject = async (signProject: ProjectDetail) => {
    setFullPageLoadingMessage('Creating Project...')
    try {
      const newProjectRes = await Axios.post(
        `${env.apiDomainUrl + PROJECT_SERVICE_API_DOMAIN_URL}/projects`,
        new ProjectRequest({
          ...signProject,
          division_ids: signProject.divisions.map(
            (division) => division.division_id,
          ),
          department_ids: signProject.departments.map(
            (department) => department.department_id,
          ),
        }),
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      const newProject = new ProjectDetail(newProjectRes.data)
      setExpandedProjectId(newProject.project_id)
      setExpandedProjectTransitionFormValues([])

      const newProjectRosterReq = new RosterRequest({
        roster: currentBlueprint.roster,
        updated_by: userName,
      })

      await Axios.put(
        `${env.apiDomainUrl + PROJECT_SERVICE_API_DOMAIN_URL}/projects/${
          newProject.project_id
        }/rosters`,
        newProjectRosterReq,
      )

      // we need to check modifiedProjectIds length before updating its state, otherwise it will not update in time to check its new length
      modifiedProjectIds.size === 1 && setPageHasChanges(false)
      // remove "new" project from list
      removeFromModifiedProjectIds(signProject.project_id)

      // add the newly created project to top of list
      setSignProjects((previousState: ProjectDetail[]) => [
        newProject,
        ...previousState.filter(
          (project) => project.project_id !== NEW_SIGN_PROJECT,
        ),
      ])
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'success',
        heading: 'Project Created',
        message: 'Successfully created project',
      })
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Create Project',
        message: err.response.data.message,
      })
    }
    setFullPageLoadingMessage('')
  }

  return (
    <SignProjectContext.Provider
      value={{
        expandedProjectId,
        setExpandedProjectId,
        blueprintCampaigns,
        setBlueprintCampaigns,
        modifiedProjectIds,
        setModifiedProjectIds,
        addToModifiedProjectIds,
        removeFromModifiedProjectIds,
        getProjectCampaigns,
        cancelSignProject,
        updateSignProject,
        createSignProject,
        expandedProjectTransitionFormValues,
        setExpandedProjectTransitionFormValues,
      }}
    >
      {children}
    </SignProjectContext.Provider>
  )
}

export const SignProjectProvider = connect(
  null,
  null,
)(SignProjectProviderComponent)

export const useSignProjectContext = () => useContext(SignProjectContext)
