import React, { Fragment } from 'react'
import {
  Button,
  Caption,
  Input,
  Grid,
  Table,
} from '@enterprise-ui/canvas-ui-react'
import { useAdminUploadContext } from '../context/adminUploadContext'

const UploadFileForm = () => {
  const { handleFileUpload, setIsUploadFile } = useAdminUploadContext()!

  return (
    <Fragment>
      <Grid.Container justify="space-between" align="center">
        <Grid.Item>
          <Input.DropArea
            id="campaign_upload"
            onUpdate={(event: any) => {
              handleFileUpload(event.target.files[0])
            }}
            dense
          />
        </Grid.Item>
        <Grid.Item xs>
          <Button type="primary" onClick={() => setIsUploadFile(false)}>
            Cancel File Upload
          </Button>
        </Grid.Item>
        <Grid.Item xs={12}></Grid.Item>
        <Grid.Item xs={12}>
          <Caption
            above="Example Format for the Excel File:"
            below="Headers must be exactly like the example above"
          >
            <Table className="sem-CampaignTable">
              <Table.Head>
                <Table.Row>
                  <Table.Header xs>Campaign Year</Table.Header>
                  <Table.Header xs={2}>Campaign ID</Table.Header>
                  <Table.Header xs>Campaign Type</Table.Header>
                  <Table.Header xs>Fiscal Year Start Date</Table.Header>
                  <Table.Header xs>Fiscal Year End Date</Table.Header>
                  <Table.Header xs>Campaign Plan Start Date</Table.Header>
                  <Table.Header xs>Campaign Plan End Date</Table.Header>
                </Table.Row>
              </Table.Head>

              <Table.Body>
                <Table.Row>
                  <Table.Data xs>2020</Table.Data>
                  <Table.Data xs={2}>107099</Table.Data>
                  <Table.Data xs>Example Campaign Type</Table.Data>
                  <Table.Data xs>02/01/2021</Table.Data>
                  <Table.Data xs>01/31/2022</Table.Data>
                  <Table.Data xs>04/30/2021</Table.Data>
                  <Table.Data xs>12/31/2021</Table.Data>
                </Table.Row>
                <Table.Row>
                  <Table.Data xs>2020</Table.Data>
                  <Table.Data xs={2}>075067</Table.Data>
                  <Table.Data xs>Example Campaign Type 2</Table.Data>
                  <Table.Data xs>02/01/2022</Table.Data>
                  <Table.Data xs>01/31/2023</Table.Data>
                  <Table.Data xs>03/15/2021</Table.Data>
                  <Table.Data xs>12/31/2021</Table.Data>
                </Table.Row>
              </Table.Body>
            </Table>
          </Caption>
        </Grid.Item>
      </Grid.Container>
    </Fragment>
  )
}

export default UploadFileForm
