import { useFormik } from 'formik'
import PrintingResponse from '../../../models/signs/PrintingResponse.model'
import PrintingRequest from '../../../models/signs/PrintingRequest.model'
import SignResponse from '../../../models/signs/SignResponse.model'
import { SIGN_DETAILS_SECTION } from '../constants/signDetailsConstants'
import { UserPermissions } from '../../../models/app/UserPermissions.model'

export const useSignPrintForm = (
  currentSign: SignResponse,
  userPermissions: UserPermissions,
  userCompany: string,
  updateSignSection: (
    req: PrintingRequest,
    slug: SIGN_DETAILS_SECTION,
    sign_id: string,
  ) => void,
) => {
  const currentPrintingInfo = new PrintingResponse(currentSign.printing)

  return useFormik({
    enableReinitialize: true,
    initialValues: new PrintingResponse({
      ...currentPrintingInfo,
      print_vendor:
        userPermissions.isPrintVendor || userPermissions.isTPSVendor
          ? userCompany
          : currentPrintingInfo.print_vendor,
    }),
    onSubmit(values) {
      updateSignSection(
        new PrintingRequest({ ...values, updated_by: currentSign.updated_by }),
        SIGN_DETAILS_SECTION.PRINT,
        currentSign.sign_id,
      )
    },
  })
}
