import React, { useEffect } from 'react'
import { useMilestoneContext } from '../../../../App/context/milestoneContext'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { ScheduleTemplate } from '../../../../../models/milestones/ScheduleTemplate'
import { useProjectMilestoneTabContext } from '../context/projectMilestoneTabContext'

const AddScheduleTemplate = () => {
  const { allScheduleTemplates, getAllScheduleTemplates } =
    useMilestoneContext()!
  const { setProjectMilestone } = useProjectMilestoneTabContext()!

  const templates = allScheduleTemplates
    .filter((template: ScheduleTemplate) => template.master_template === true)
    .map((template: ScheduleTemplate) => ({
      id: template.template_id,
      value: template.template_id,
      label: template.template_name,
    }))

  useEffect(() => {
    getAllScheduleTemplates()
  }, [getAllScheduleTemplates])

  return (
    <Autocomplete
      id="add_milestone"
      label="Choose a Master Template to add to this page: "
      options={templates}
      optionHeight="dense"
      onUpdate={(event: any, value: any) => {
        if (value !== null) {
          setProjectMilestone(
            allScheduleTemplates
              .find(
                (scheduleTemplate: ScheduleTemplate) =>
                  scheduleTemplate.template_id === value.id,
              )
              ?.milestones!.filter(
                (milestone: any) => milestone.completion_level === 'PROJECT',
              )!,
          )
        }
      }}
    />
  )
}

export default AddScheduleTemplate
