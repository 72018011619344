export default class AddOnExpensesRequest {
  add_on_expenses_id: string
  print_vendor: string
  expenses_type?: string
  cost?: number
  po_number?: string
  vendor_notes?: string
  expenses_status?: string
  updated_by: string

  constructor(props: any = {}) {
    this.add_on_expenses_id = props.add_on_expenses_id
    this.print_vendor = props.print_vendor
    this.expenses_type = props.expenses_type
    this.cost = props.cost
    this.po_number = props.po_number
    this.vendor_notes = props.vendor_notes
    this.expenses_status = props.expenses_status
    this.updated_by = props.updated_by
  }
}
