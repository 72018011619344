import React, { Fragment } from 'react'
import { Input, Tooltip, Button, Grid } from '@enterprise-ui/canvas-ui-react'
import SignResponse from '../../../../../../../models/signs/SignResponse.model'
import { useProjectSignListContext } from '../../../context/projectSignListContext'
import EnterpriseIcon, {
  OverflowMenuHorizontalIcon,
} from '@enterprise-ui/icons'

export interface Props {
  data: SignResponse
  value: string
}

const POGCell = ({ data, value }: Props) => {
  const { getPogs, setIsPogModalOpen } = useProjectSignListContext()!

  return (
    <Fragment>
      <Grid.Container>
        <Grid.Item className="hc-pr-none" xs={6}>
          <Input.Toggle
            aria-label="POG Cell Toggle"
            id="on_pog"
            className="hc-ma-none hc-pv-dense"
            checked={value}
            disabled
          />
        </Grid.Item>
        <Grid.Item xs={6}>
          <Tooltip location="bottom" content="Show all POGs">
            <Button
              className="hc-ma-none"
              type="primary"
              size="dense"
              onClick={() => {
                getPogs(data.sign_id)
                setIsPogModalOpen({ open: true, sign_id: data.sign_id })
              }}
            >
              <EnterpriseIcon icon={OverflowMenuHorizontalIcon} />
            </Button>
          </Tooltip>
        </Grid.Item>
      </Grid.Container>
    </Fragment>
  )
}

export default POGCell
