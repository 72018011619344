import React from 'react'
import { Button, Dropdown } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { OverflowMenuVerticalIcon } from '@enterprise-ui/icons'
import moment from 'moment'
import { RowNode } from 'ag-grid-community'
import { SignLibraryResponse } from '../../../../../models/signLibrary'

export interface Props {
  data: SignLibraryResponse
  node: RowNode
}

const TableDropdownMenu = ({
  data = new SignLibraryResponse(),
  node,
}: Props) => {
  return (
    <Dropdown size="expanded" location="bottom-left">
      <Button type="ghost" className="sem_IconBtn-dark" iconOnly size="dense">
        <EnterpriseIcon icon={OverflowMenuVerticalIcon} size="md" />
      </Button>
      <Dropdown.Menu>
        <Dropdown.MenuItem as="div" className="sem_DropdownMenuItem--info">
          <span className="sem_StrongText hc-mr-dense">Last Modified:</span>
          {moment(data.non_retail_item_info.updated_date).format(
            'MM/DD/YYYY hh:mm A',
          )}
        </Dropdown.MenuItem>
        <Dropdown.MenuItem
          as="div"
          className="sem_DropdownMenuItem--info hc-pb-normal"
        >
          <span className="sem_StrongText hc-mr-dense">Modified By:</span>
          {data.non_retail_item_info.updated_by}
        </Dropdown.MenuItem>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default TableDropdownMenu
