import { Pyramid } from '../merchandise/hierarchy/Pyramid.model'
import { get } from 'lodash'
import { RosterElement } from '../roster/RosterElement.model'

export class ChildCampaignType {
  id: string
  name: string
  pyramids: Pyramid[]
  parent: boolean
  roster: RosterElement[]
  created_by: string
  created_date: Date
  updated_by: string
  updated_date: Date
  type: string
  non_adjacency: boolean

  constructor(props: any = {}) {
    this.id = props.id || ''
    this.name = props.name || ''
    this.pyramids = get(props, 'pyramids', []).map(
      (pyramid: any) => new Pyramid(pyramid),
    )
    this.parent = false
    this.roster = get(props, 'roster', []).map(
      (rosterElement: any) => new RosterElement(rosterElement),
    )
    this.created_by = props.created_by || ''
    this.created_date = props.created_date || new Date()
    this.updated_by = props.updated_by || ''
    this.updated_date = props.updated_date || new Date()
    this.type = props.type || ''
    this.non_adjacency = props.non_adjacency || false
  }
}
