import React, { Fragment, useEffect } from 'react'
import { Button, Grid, Modal, Tooltip } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { PlusIcon } from '@enterprise-ui/icons'
import { useBlueprintNotesContext } from '../context/blueprintNotesContext'
import BlueprintNotesForm from './BlueprintNotesForm'
import BlueprintNotesItem from './BlueprintNotesItem'
import { useBlueprintDetailsContainerContext } from '../../../context/blueprintDetailsContainerContext'
import { BlueprintNote } from '../../../../../models/blueprints/BlueprintNote.model'

const BlueprintNotes = () => {
  const { currentBlueprint } = useBlueprintDetailsContainerContext()!
  const {
    getBlueprintNotes,
    isBlueprintNotesOpen,
    setIsBlueprintNotesOpen,
    isBlueprintNotesFormOpen,
    setIsBlueprintNotesFormOpen,
  } = useBlueprintNotesContext()!

  useEffect(() => {
    currentBlueprint.blueprint_id &&
      getBlueprintNotes(currentBlueprint.blueprint_id)
  }, [getBlueprintNotes, currentBlueprint.blueprint_id])

  return (
    <Fragment>
      <Modal
        isVisible={isBlueprintNotesOpen}
        onRefuse={() => {
          setIsBlueprintNotesOpen(false)
          setIsBlueprintNotesFormOpen(false)
        }}
        headingText="Blueprint Notes"
      >
        <Grid.Container className="sem_NotesTab hc-pa-dense">
          <Grid.Item xs={12}>
            <Grid.Container className="sem_NotesContainer" align="center">
              <Grid.Item align="left">
                {currentBlueprint.status !== 'Archive' && (
                  <Tooltip location="bottom" content="Add a new note.">
                    <Button
                      aria-label="add blueprint note"
                      type="ghost"
                      iconOnly
                      className={`sem_NotesBtnAdd ${
                        isBlueprintNotesOpen ? 'open' : ''
                      }`}
                      onClick={() =>
                        setIsBlueprintNotesFormOpen(!isBlueprintNotesFormOpen)
                      }
                    >
                      <EnterpriseIcon icon={PlusIcon} />
                    </Button>
                  </Tooltip>
                )}
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
          {isBlueprintNotesFormOpen && (
            <Grid.Item xs={12}>
              <BlueprintNotesForm />
            </Grid.Item>
          )}
          {currentBlueprint.notes && (
            <Grid.Item className="sem_NotesItem" xs={12}>
              {currentBlueprint.notes.map(
                (note: BlueprintNote, index: number) => (
                  <BlueprintNotesItem
                    blueprintId={currentBlueprint.blueprint_id}
                    note={note}
                    key={index}
                  />
                ),
              )}
            </Grid.Item>
          )}
        </Grid.Container>
      </Modal>
    </Fragment>
  )
}

export default BlueprintNotes
