import React, { Fragment, useState } from 'react'
import {
  Button,
  Table,
  Form,
  Tooltip,
  Dropdown,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, {
  ArchiveIcon,
  OverflowMenuVerticalIcon,
  PencilIcon,
  SaveIcon,
  UndoIcon,
} from '@enterprise-ui/icons'
import moment from 'moment'
import { CampaignDetail } from '../../../../models/campaigns/CampaignDetail.model'
import { useAdminUploadContext } from '../context/adminUploadContext'
import { useAppContext } from '../../../App/context/appContext'
import { DialogProps } from '../../../../models/app/DialogProps.model'
import RubixChip from 'components/common/RubixChip'

export interface Props {
  campaign: CampaignDetail
}

const AdminCampaignTableItem = ({ campaign }: Props) => {
  const {
    saveCampaign,
    updateCampaign,
    archiveCampaign,
    restoreArchivedCampaign,
  } = useAdminUploadContext()!
  const { setDialogProps, setPageHasChanges } = useAppContext()!

  const [isEditMode, setIsEditMode] = useState(false)
  const [isModified, setIsModified] = useState(false)

  return isEditMode ? (
    <Table.Row
      className={`hc-bg-grey05 hc-clr-grey01 sem_CampaignRow ${
        isModified ? 'modified' : ''
      }`}
      noWrap={true}
    >
      <Table.Data className="sem-editCampaignRowSave">
        <Tooltip location="bottom" content="Save">
          <Button
            type="ghost"
            iconOnly
            size="dense"
            onClick={() => {
              if (isModified) {
                saveCampaign(campaign)
              }
              setIsModified(false)
              setIsEditMode(false)
              setPageHasChanges(false)
            }}
          >
            <EnterpriseIcon icon={SaveIcon} size="md" />
          </Button>
        </Tooltip>
      </Table.Data>
      <Table.Data xs={1} className="sem-editCampaignRowYear">
        <Form.Field
          id="Campaign Year"
          value={campaign.year}
          onChange={(e: any) => {
            updateCampaign({ ...campaign, year: e.target.value })
            setIsModified(true)
            setPageHasChanges(true)
          }}
        />
      </Table.Data>
      <Table.Data xs={1} className="sem-editCampaignRow">
        <Form.Field
          id="Campaign Id"
          value={campaign.campaign_id}
          onChange={(e: any) => {
            updateCampaign({
              ...campaign,
              campaign_id: e.target.value,
            })
            setIsModified(true)
            setPageHasChanges(true)
          }}
        />
      </Table.Data>
      <Table.Data xs={2} className="sem-editCampaignRow">
        <Form.Field
          id="Campaign Type"
          value={campaign.campaign_type}
          onChange={(e: any) => {
            updateCampaign({
              ...campaign,
              campaign_type: e.target.value,
            })
            setIsModified(true)
            setPageHasChanges(true)
          }}
        />
      </Table.Data>
      <Table.Data xs={1} className="sem-editCampaignRow">
        <Form.Field
          type="date"
          id="Fiscal Year Start Date"
          value={campaign.fiscal_year_start_date}
          onChange={(event: any) => {
            updateCampaign({
              ...campaign,
              fiscal_year_start_date: event.target.value,
            })
            setIsModified(true)
            setPageHasChanges(true)
          }}
          location="bottom-left"
        />
      </Table.Data>
      <Table.Data xs={1} className="sem-editCampaignRow">
        <Form.Field
          type="date"
          id="Fiscal Year End Date"
          value={campaign.fiscal_year_end_date}
          onChange={(event: any) => {
            updateCampaign({
              ...campaign,
              fiscal_year_end_date: event.target.value,
            })
            setIsModified(true)
            setPageHasChanges(true)
          }}
          location="bottom-left"
        />
      </Table.Data>
      <Table.Data xs={1} className="sem-editCampaignRow">
        <Form.Field
          type="date"
          id="Campaign Plan Start Date"
          value={campaign.campaign_plan_start_date}
          onChange={(event: any) => {
            updateCampaign({
              ...campaign,
              campaign_plan_start_date: event.target.value,
            })
            setIsModified(true)
            setPageHasChanges(true)
          }}
          location="bottom-left"
        />
      </Table.Data>
      <Table.Data xs={1} className="sem-editCampaignRow">
        <Form.Field
          type="date"
          id="Campaign Plan End Date"
          value={campaign.campaign_plan_end_date}
          onChange={(event: any) => {
            updateCampaign({
              ...campaign,
              campaign_plan_end_date: event.target.value,
            })
            setIsModified(true)
            setPageHasChanges(true)
          }}
          location="bottom-left"
        />
      </Table.Data>
    </Table.Row>
  ) : (
    <Table.Row
      className="sem_TableCardItem"
      noWrap={true}
      justify="space-between"
    >
      <Table.Data className="right-align">
        <Dropdown location="bottom-left">
          <Button
            type="ghost"
            className="sem_IconBtn-dark"
            data-testid="openDropdown"
            iconOnly
            size="dense"
          >
            <EnterpriseIcon icon={OverflowMenuVerticalIcon} size="md" />
          </Button>
          <Dropdown.Menu>
            <Dropdown.MenuItem as="div" className="sem_DropdownMenuItem--info">
              <span className="sem_StrongText hc-mr-dense">Last Modified:</span>
              {moment(campaign.updated_date).format('MM/DD/YYYY')}
            </Dropdown.MenuItem>
            <Dropdown.MenuItem
              as="div"
              className="sem_DropdownMenuItem--info divider"
            >
              <span className="sem_StrongText hc-mr-dense">Modified By:</span>
              {campaign.updated_by}
            </Dropdown.MenuItem>
            <Dropdown.MenuItem
              data-testid="openProject"
              onClick={() => setIsEditMode(true)}
            >
              <EnterpriseIcon className="hc-mr-dense" icon={PencilIcon} />
              Edit Campaign Detail
            </Dropdown.MenuItem>
            <Dropdown.MenuItem
              onClick={() =>
                campaign.active
                  ? setDialogProps(
                      new DialogProps({
                        headingText:
                          'Are you sure you want to archive this campaign?',
                        approveButtonText: 'Yes',
                        onApprove: () => archiveCampaign(campaign),
                        refuseButtonText: 'Cancel',
                        onRefuse: () => setDialogProps(new DialogProps()),
                      }),
                    )
                  : setDialogProps(
                      new DialogProps({
                        headingText:
                          'Are you sure you want to restore this archived campaign?',
                        approveButtonText: 'Yes',
                        onApprove: () => restoreArchivedCampaign(campaign),
                        refuseButtonText: 'Cancel',
                        onRefuse: () => setDialogProps(new DialogProps()),
                      }),
                    )
              }
            >
              {campaign.active ? (
                <Fragment>
                  <EnterpriseIcon className="hc-mr-dense" icon={ArchiveIcon} />
                  Archive Campaign
                </Fragment>
              ) : (
                <Fragment>
                  <EnterpriseIcon className="hc-mr-dense" icon={UndoIcon} />
                  Restore Archived Campaign
                </Fragment>
              )}
            </Dropdown.MenuItem>
          </Dropdown.Menu>
        </Dropdown>
      </Table.Data>
      <Table.Data xs={1}>{campaign.year}</Table.Data>
      <Table.Data xs={1}>{campaign.campaign_id}</Table.Data>
      <Table.Data xs={2}>{campaign.campaign_type}</Table.Data>
      <Table.Data xs={1}>
        {campaign.fiscal_year_start_date
          ? moment(campaign.fiscal_year_start_date).format('MM/DD/YYYY')
          : ''}
      </Table.Data>
      <Table.Data xs={1}>
        {campaign.fiscal_year_start_date
          ? moment(campaign.fiscal_year_end_date).format('MM/DD/YYYY')
          : ''}
      </Table.Data>
      <Table.Data xs={1}>
        {campaign.fiscal_year_start_date
          ? moment(campaign.campaign_plan_start_date).format('MM/DD/YYYY')
          : ''}
      </Table.Data>
      <Table.Data xs={1}>
        {campaign.fiscal_year_start_date
          ? moment(campaign.campaign_plan_end_date).format('MM/DD/YYYY')
          : ''}
      </Table.Data>
      <Table.Data xs={1}>
        <RubixChip
          data-testid="statusChip"
          color={campaign.active ? 'green' : 'yellow'}
        >
          {campaign.active ? 'True' : 'False'}
        </RubixChip>
      </Table.Data>
    </Table.Row>
  )
}

export default AdminCampaignTableItem
