import { get } from 'lodash'
import { Column } from './AgGridColumn.model'

export class AgGridSignListView {
  viewKey: string
  label: string
  columns: Column[]
  exportColumns: string[]

  constructor(props: any = {}) {
    this.viewKey = props.viewKey || ''
    this.label = props.label || ''
    this.columns =
      props.columns ||
      get(props, 'columns', []).map((col: any) => new Column(col))
    this.exportColumns = props.exportColumns || ['']
  }
}
