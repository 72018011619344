export const SHIP_METHODS = [
  {
    value: 'NRSC',
    label: 'NRSC',
  },
  {
    value: 'DTS',
    label: 'DTS',
  },
  {
    value: 'NRSC DTS',
    label: 'NRSC DTS',
  },
  {
    value: 'Store Mail',
    label: 'Store Mail',
  },
  {
    value: '3rd Party',
    label: '3rd Party',
  },
  {
    value: 'VDN',
    label: 'VDN',
  },
  {
    value: 'Other',
    label: 'Other',
  },
]
