import Axios from 'axios'
import React, { createContext, useState, useContext, useCallback } from 'react'
import { connect } from 'react-redux'
import {
  SIGN_SERVICE_API_DOMAIN_URL,
  TOASTER_DEFAULTS,
} from 'components/App/constants/appConstants'
import { useEnv } from '@praxis/component-runtime-env'
import SignResponse from '../../../../../models/signs/SignResponse.model'
import { useSignDetailsContext } from '../../../context/signDetailsContext'
import { useAppContext } from 'components/App/context/appContext'
import { DialogProps } from '../../../../../models/app/DialogProps.model'
import { NoteType } from '../../../../../models/signs/SignNotes.model'
import { useToaster } from '@enterprise-ui/canvas-ui-react'

type ContextType = {
  isSignNotesOpen: boolean
  setIsSignNotesOpen: Function
  isSignNotesFormOpen: boolean
  setIsSignNotesFormOpen: (isOpen: boolean) => void
  addSignNote: (id: string, note: NoteType, noteType: string) => void
  editSignNote: (note: NoteType, signId: string, noteType: string) => void
  deleteSignNote: (note: NoteType, signId: string, noteType: string) => void
  getSignNotes: (id: string) => void
}

export const SignNotesContext = createContext<ContextType | undefined>(
  undefined,
)

type Props = {
  children: React.ReactNode
}

export const SignNotesProviderComponent = ({ children }: Props) => {
  const { setFullPageLoadingMessage, setDialogProps } = useAppContext()!
  const { setCurrentSign } = useSignDetailsContext()!
  const env = useEnv()
  const makeToast = useToaster()
  const [isSignNotesOpen, setIsSignNotesOpen] = useState(false)
  const [isSignNotesFormOpen, setIsSignNotesFormOpen] = useState(false)

  const getSignNotes = useCallback(
    async (id: string) => {
      try {
        const res = await Axios.get(
          `${env.apiDomainUrl + SIGN_SERVICE_API_DOMAIN_URL}/signs/${id}/notes`,
        )
        const notes = res.data

        setCurrentSign(
          (previousState: SignResponse) =>
            new SignResponse({
              ...previousState,
              sign_notes: notes,
            }),
        )
      } catch (err: any) {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'error',
          heading: 'Failed to Get Sign Notes',
          message: err.response.data.message,
        })
      }
    },
    [setCurrentSign, makeToast, env.apiDomainUrl],
  )

  const addSignNote = async (id: string, note: NoteType, noteType: string) => {
    try {
      setFullPageLoadingMessage('Saving note...')
      await Axios.put(
        `${
          env.apiDomainUrl + SIGN_SERVICE_API_DOMAIN_URL
        }/signs/${id}/notes?type=${noteType}`,
        note,
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      await getSignNotes(id)
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'success',
        heading: 'Note Added',
        message: 'Successfully added a sign note',
      })
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Add Note',
        message: err.response.data.message,
      })
    }
    setFullPageLoadingMessage('')
  }
  const editSignNote = async (
    note: NoteType,
    signId: string,
    noteType: string,
  ) => {
    await getSignNotes(signId)
    try {
      setFullPageLoadingMessage('Updating note...')
      await Axios.put(
        `${
          env.apiDomainUrl + SIGN_SERVICE_API_DOMAIN_URL
        }/signs/${signId}/notes?type=${noteType}`,
        note,
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      await getSignNotes(signId)
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'success',
        heading: 'Note Updated',
        message: 'Successfully edited a sign note',
      })
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Edit Note',
        message: err.response.data.message,
      })
    }
    setFullPageLoadingMessage('')
  }

  const deleteSignNote = async (
    note: NoteType,
    signId: string,
    noteType: string,
  ) => {
    note.content = ''
    try {
      setFullPageLoadingMessage('Deleting note...')
      await Axios.put(
        `${
          env.apiDomainUrl + SIGN_SERVICE_API_DOMAIN_URL
        }/signs/${signId}/notes?type=${noteType}`,
      )
      await getSignNotes(signId)
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'success',
        heading: 'Note Deleted',
        message: 'Successfully deleted a sign note',
      })
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Delete Note',
        message: err.response.data.message,
      })
    }
    setDialogProps(new DialogProps())
    setFullPageLoadingMessage('')
  }

  return (
    <SignNotesContext.Provider
      value={{
        isSignNotesOpen,
        setIsSignNotesOpen,
        isSignNotesFormOpen,
        setIsSignNotesFormOpen,
        addSignNote,
        editSignNote,
        deleteSignNote,
        getSignNotes,
      }}
    >
      {children}
    </SignNotesContext.Provider>
  )
}

export const useSignNotesContext = () => useContext(SignNotesContext)

export const SignNotesProvider = connect(null, null)(SignNotesProviderComponent)
