import { Form, Grid } from '@enterprise-ui/canvas-ui-react'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'

export interface Props {
  value: string
}

const NotesEditor = forwardRef(({ value }: Props, ref) => {
  const [editValue, setEditValue] = useState(value)
  const refInput = useRef<HTMLInputElement>(null)

  const handleChange = (value: string) => setEditValue(value)

  useEffect(() => {
    setTimeout(() => refInput?.current?.focus())
  }, [])

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return editValue
      },
    }
  })

  return (
    <div className="hc-pa-dense">
      <Grid.Container className="sem_StandardForm error_messaging_enabled wide-form">
        <Grid.Item xs={12}>
          <Form.Field
            id="notes_editor_addon"
            type="textarea"
            label="Vendor Notes"
            ref={refInput}
            value={editValue}
            onChange={(e: any) => handleChange(e.target.value)}
          />
        </Grid.Item>
      </Grid.Container>
    </div>
  )
})

export default NotesEditor
