import React, {
  Fragment,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react'
import {
  Button,
  Card,
  Chip,
  Form,
  Input,
  Grid,
  Tooltip,
} from '@enterprise-ui/canvas-ui-react'
import { KitInfoResponse } from '../../../../../models/kits/KitInfoResponse.model'
import { ProjectDetail } from '../../../../../models/projects/ProjectDetail.model'
import {
  KitResponse,
  KitStatus,
} from '../../../../../models/kits/KitResponse.model'
import { useProjectDistributionContext } from '../context/projectDistributionContext'
import EnterpriseIcon, {
  CautionIcon,
  CheckCircleFilledIcon,
} from '@enterprise-ui/icons'
import { useNavigate, useLocation } from 'react-router-dom'
import RubixChip from 'components/common/RubixChip'

export interface Props {
  kittingInfo: KitInfoResponse
  currentProject: ProjectDetail
}

const ProjectKitList = ({ kittingInfo }: Props) => {
  const navigate = useNavigate()
  const location = useLocation()

  const query = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  )

  const [kitVendor, setKitVendor] = useState('All')
  const clearKitVendorFilter = () => {
    setKitVendor('All')
    setFinalizedFilterValues([KitStatus.CREATED, KitStatus.FINALIZED])
    getFilteredKits(
      'All',
      [KitStatus.CREATED, KitStatus.FINALIZED],
      kittingInfo.kits,
    )
  }

  const {
    kittingVendors,
    filteredKits,
    getKittingVendors,
    getFilteredKits,
    kit,
    getKit,
    finalizeCheckBoxOptions,
    finalizedFilterValues,
    setFinalizedFilterValues,
    activeKit,
    setActiveKit,
  } = useProjectDistributionContext()!
  useEffect(() => {
    if (
      (filteredKits === undefined || filteredKits.length === 0) &&
      finalizedFilterValues.length === finalizeCheckBoxOptions.length
    ) {
      getKittingVendors(kittingInfo.kits)
      getFilteredKits(kitVendor, finalizedFilterValues, kittingInfo.kits)
    }
  }, [
    filteredKits,
    kittingInfo.kits,
    kitVendor,
    getKittingVendors,
    getFilteredKits,
    finalizedFilterValues,
    finalizeCheckBoxOptions,
    kittingInfo.id,
  ])

  const setCurrentKit = useCallback(
    (kitId: string) => {
      if (
        (kitId !== undefined || kitId !== null) &&
        (query.get('kit') === undefined || query.get('kit') !== kitId)
      ) {
        setActiveKit(kitId)
        query.set('tab', 'KITTIING/DISTRIBUTION')
        query.set('kit', kitId)
        navigate(`${location.pathname}?${query.toString()}`)
      }
    },
    [navigate, location.pathname, query, setActiveKit],
  )

  useEffect(() => {
    if (filteredKits !== undefined && filteredKits.length > 0) {
      if (
        query.get('kit') !== undefined &&
        query.get('kit') !== null &&
        kit.kit_id === undefined &&
        filteredKits.some((kit) => kit.kit_id === query.get('kit'))
      ) {
        const kit_id = query.get('kit')!!
        getKit(kit_id, kittingInfo.id)
        setCurrentKit(kit_id)
      } else if (kit.kit_id === undefined || activeKit === '') {
        getKit(filteredKits[0].kit_id, kittingInfo.id)
        setCurrentKit(filteredKits[0].kit_id)
        setActiveKit(filteredKits[0].kit_id)
      }
    }
  }, [
    filteredKits,
    kit.kit_id,
    getKit,
    activeKit,
    setActiveKit,
    kittingInfo.id,
    query,
    setCurrentKit,
  ])

  return (
    <Fragment>
      <Grid.Container align="flex-end" spacing="dense" className="hc-pv-normal">
        <Grid.Item xs={12}>
          <Form.Field
            label="Filter By Kitting Vendor"
            type="select"
            id="kitting_vendor"
            name="kitting vendor"
            value={kitVendor}
            options={kittingVendors}
            onUpdate={(e: any, kittingVendor: string) => {
              setKitVendor(kittingVendor)
              getFilteredKits(
                kittingVendor,
                finalizedFilterValues,
                kittingInfo.kits,
              )
            }}
          />
        </Grid.Item>
        <Grid.Item xs={8}>
          <Input.Checkboxes
            id="finalize_filter"
            value={finalizedFilterValues}
            options={finalizeCheckBoxOptions}
            onUpdate={(e: any, values: string[]) => {
              setFinalizedFilterValues(values)
              getFilteredKits(kitVendor, values, kittingInfo.kits)
            }}
          />
        </Grid.Item>
        <Grid.Item xs={4}>
          <Button
            type="primary"
            fullWidth
            onClick={() => {
              clearKitVendorFilter()
            }}
          >
            Clear Filters
          </Button>
        </Grid.Item>
      </Grid.Container>
      <Grid.Container className="hc-pb-expanded">
        <Grid.Item xs={true} className="sem_ProjectKitList">
          {filteredKits?.map((kit: KitResponse, index: any) => (
            <Card
              className={`sem-KitListCard ${
                activeKit === kit.kit_id ? 'active' : ''
              }`}
              elevation={0}
              onClick={() => {
                getKit(kit.kit_id, kittingInfo.id)
                setCurrentKit(kit.kit_id)
              }}
              key={kit.kit_id}
            >
              <div className="hc-pa-dense">
                <Grid.Container spacing="dense" align="center">
                  <Grid.Item xs={true} className="hc-pt-none kitId">
                    {kit.status === KitStatus.FINALIZED && (
                      <Tooltip location="right" content="Kit Finalized">
                        <EnterpriseIcon
                          size="sm"
                          className="green"
                          icon={CheckCircleFilledIcon}
                        />
                      </Tooltip>
                    )}
                    {kit.status !== KitStatus.FINALIZED &&
                      kittingInfo.kits_finalized && (
                        <Tooltip location="right" content="Kit Not Finalized">
                          <EnterpriseIcon
                            className="sem-kitting-alert"
                            icon={CautionIcon}
                          />
                        </Tooltip>
                      )}
                    <span> {kit.kit_id}</span>
                  </Grid.Item>
                </Grid.Container>
                <Grid.Container spacing="dense">
                  <Grid.Item xs={true} className="hc-pb-none">
                    <Tooltip location="bottom" content="Kitting Vendor">
                      <span className="kitVendor">{kit.kit_vendor}</span>
                    </Tooltip>
                  </Grid.Item>
                  <Grid.Item className="hc-pt-none">
                    <Tooltip location="bottom" content="# of Signs">
                      <RubixChip color="green">{kit.signs?.length}</RubixChip>
                    </Tooltip>
                  </Grid.Item>
                  <Grid.Item className="hc-pt-none">
                    <Tooltip location="bottom" content="# of Stores">
                      <Chip className="hc-mr-none" size="dense">
                        {kit.stores?.length}
                      </Chip>
                    </Tooltip>
                  </Grid.Item>
                </Grid.Container>
              </div>
            </Card>
          ))}
        </Grid.Item>
      </Grid.Container>
    </Fragment>
  )
}

export default ProjectKitList
