import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { Card, Divider, Grid, Layout } from '@enterprise-ui/canvas-ui-react'
import MainSectionHeader from '../../App/components/MainSectionHeader'
import SignFilterCard from './SignFilterCard'
import ButtonRow from './SignLibraryButtonRow'
import SignLibraryTable from './SignLibraryTable'
import ExportOptions from './ExportOptionsDrawer'
import { useAgGridSignLibraryContext } from '../context/agGridSignLibraryContext'

import '../styles/signLibraryStyles.scss'
import { useExportOptionsContext } from '../context/exportOptionsContext'
import { ColumnMovedEvent } from 'ag-grid-community'

const SignLibraryContainer = () => {
  const { loadFacets } = useAgGridSignLibraryContext()!
  const { exportOpen } = useExportOptionsContext()!

  useEffect(() => {
    loadFacets()
  }, [loadFacets])

  const [, update] = useState(0)

  const forceUpdate = useCallback(
    (cb) => (params: ColumnMovedEvent) => {
      cb(params)
      update((n) => ++n)
    },
    [update],
  )

  return (
    <Fragment>
      <Layout.SectionHeader className="hc-ph-expanded hc-pb-none">
        <MainSectionHeader sectionTitle="Sign Library" />
      </Layout.SectionHeader>
      <Layout.Body
        className="hc-pv-normal hc-ph-expanded"
        includeRail={!exportOpen}
      >
        <Card className="hc-pa-dense hc-pt-none">
          <Grid.Container spacing="dense" justify="space-between">
            <SignFilterCard />
            <Grid.Item className="hc-pr-expanded" xs={12}>
              <Grid.Container justify="space-between">
                <ButtonRow />
                <SignLibraryTable forceUpdate={forceUpdate} />
              </Grid.Container>
            </Grid.Item>
            {exportOpen ? <Divider direction="vertical" /> : null}
          </Grid.Container>
        </Card>
      </Layout.Body>
      {exportOpen ? <ExportOptions /> : null}
    </Fragment>
  )
}

export default SignLibraryContainer
