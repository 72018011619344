export class BlueprintNote {
  id: string
  author: string
  timestamp: string
  content: string

  constructor(props: any = {}) {
    this.id = props.id || ''
    this.author = props.author || ''
    this.timestamp = props.timestamp || ''
    this.content = props.content || ''
  }
}
