import React from 'react'
import { Chip, Grid } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { LockIcon } from '@enterprise-ui/icons'
import { useAgGridSignImportContext } from '../../context/agGridSignImportContext'

const ImportFilterChips = () => {
  const { filterList } = useAgGridSignImportContext()!
  return (
    <Grid.Item className="hc-ph-normal hc-pt-dense hc-pb-none" xs={true}>
      <Grid.Container className="hc-ma-none" align="center">
        {filterList.map((filter: any, index: number) =>
          filter.field === 'Sign Item Status' ? (
            <Chip
              size="dense"
              className="sem_SignLibraryFilterChip hc-bg-grey01"
            >
              <EnterpriseIcon
                size="inline"
                className="hc-mr-dense"
                icon={LockIcon}
              />
              <p className="sem_FilterChip--filter">
                {filter.field}:
                <span className="sem_FilterChip--value">{filter.value}</span>
              </p>
            </Chip>
          ) : (
            <Chip
              className="sem_SignLibraryFilterChip"
              size="dense"
              key={index}
            >
              <p className="sem_FilterChip--filter">
                {filter.field}:
                <span className="sem_FilterChip--value">{filter.value}</span>
              </p>
            </Chip>
          ),
        )}
      </Grid.Container>
    </Grid.Item>
  )
}

export default ImportFilterChips
