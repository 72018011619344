import { get } from 'lodash'

export default class MessagingResponse {
  sign_size: string
  item_info: MessagingItemInfo[]
  headline?: string
  mpp: boolean
  topper_brand?: string
  disclaimer?: string
  inclusions?: string
  exclusions?: string
  manual_price_override: boolean
  logo_url?: string

  constructor(props: any = {}) {
    this.sign_size = props.sign_size || ''
    this.item_info = get(props, 'item_info', [{}]).map(
      (item: any) => new MessagingItemInfo(item),
    )
    this.headline = props.headline
    this.mpp = props.mpp || false
    this.topper_brand = props.topper_brand || null
    this.disclaimer = props.disclaimer || ''
    this.inclusions = props.inclusions || ''
    this.exclusions = props.exclusions || ''
    this.manual_price_override = props.manual_price_override || false
    this.logo_url = props.logo_url || ''
  }
}

export class MessagingItemInfo {
  messaging_type: string
  messaging_value: MessagingValue
  manual_price_override: boolean
  product_dpci: string
  attributes: string[]
  product_description?: string
  product_tcin?: string

  constructor(props: any = {}) {
    this.messaging_type = props.messaging_type || 'Regular Price'
    this.messaging_value = new MessagingValue(props.messaging_value || {})
    this.manual_price_override = props.manual_price_override || false
    this.product_dpci = props.product_dpci || ''
    this.attributes = props.attributes || ['']
    this.product_description = props.product_description || ''
    this.product_tcin = props.product_tcin
  }
}

export class MessagingValue {
  price?: number
  quantity?: number
  highest_price?: number
  lowest_price?: number

  constructor(props: any = {}) {
    this.price = props.price
    this.quantity = props.quantity
    this.highest_price = props.highest_price
    this.lowest_price = props.lowest_price
  }
}

export enum SIGN_SIZES {
  FIVE_BY_THREE = 'FIVE_BY_THREE',
  SIX_BY_THREE = 'SIX_BY_THREE',
  EIGHT_BY_TWO = 'EIGHT_BY_TWO',
  SEVEN_BY_ELEVEN = 'SEVEN_BY_ELEVEN',
  ELEVEN_BY_ELEVEN = 'ELEVEN_BY_ELEVEN',
  OTHER = 'OTHER',
}
