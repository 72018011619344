import { Column } from '../../../../../models/agGrid/AgGridColumn.model'

export const MOVE_SIGNS_VIEW = [
  new Column({
    field: 'non_retail_item_info.title',
    headerName: 'Sign Name',
    width: 320,
  }),
  new Column({
    field: 'sign_type',
    headerName: 'Sign Type',
    width: 230,
  }),
  new Column({
    field: 'non_retail_item_info.dpci',
    headerName: 'DPCI #',
    width: 230,
  }),
]
