import React, { useState } from 'react'
import { Card, Grid, Layout } from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { DropdownOption } from '../../../models/app/DropdownOption.model'
import MainSectionHeader from '../../App/components/MainSectionHeader'
import { getUserPermissions } from '../../App/helpers/userPermissionsHelper'
import { PERMISSION_SETS } from '../../App/constants/appConstants'
import { useUserContext } from '../../App/context/userContext'

export const AdminRoles = () => {
  const { setUserPermissions, userInfo, setUserInfo } = useUserContext()!

  const [selectedRoles, setSelectedRoles] = useState<string[]>([])

  const updateRole = (roles: DropdownOption<string>[]) => {
    const selected = roles.map((role) => role.value)
    setSelectedRoles(selected)
    setUserPermissions(getUserPermissions(selected))
    setUserInfo({ ...userInfo, permissions: getUserPermissions(selected) })
  }

  const createRoleDropdownOptions = () => {
    const distinctUserGroups = new Set(
      PERMISSION_SETS.internalUsersGroup.concat(
        PERMISSION_SETS.externalUsersGroup,
      ),
    )

    return Array.from(distinctUserGroups).map(
      (key: string) => new DropdownOption({ id: key, value: key, label: key }),
    )
  }

  return (
    <Layout.Body className="hc-pa-expanded" includeRail>
      <MainSectionHeader sectionTitle="Roles" />
      <Card className="hc-pa-normal">
        <Grid.Container>
          <Grid.Item xs={3}>
            <Autocomplete
              label="Roles"
              options={createRoleDropdownOptions()}
              optionHeight="dense"
              multiselect
              onUpdate={(e: any, value: DropdownOption<string>[]) => {
                updateRole(value)
              }}
              value={selectedRoles.map(
                (key: string) =>
                  new DropdownOption({
                    id: key,
                    value: key,
                    label: key,
                  }),
              )}
            />
          </Grid.Item>
        </Grid.Container>
      </Card>
    </Layout.Body>
  )
}

export default AdminRoles
