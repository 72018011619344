import React from 'react'
import { createContext, useContext } from 'react'
import { useEnv } from '@praxis/component-runtime-env'
import { IServerSideGetRowsParams, SortModelItem } from 'ag-grid-community'
import axios from 'axios'
import {
  NOTIFICATION_SERVICE_API_DOMAIN_URL,
  TOASTER_DEFAULTS,
} from '../../App/constants/appConstants'
import { connect } from 'react-redux'
import { set } from 'lodash'
import { useToaster } from '@enterprise-ui/canvas-ui-react'

type ContextType = {
  notificationSSRMServer: () => void
}

export const NotificationSSRMServerContext = createContext<
  ContextType | undefined
>(undefined)

type Props = {
  children: React.ReactNode
}

export const NotificationSSRMServerComponent = ({ children }: Props) => {
  const env = useEnv()
  const makeToast = useToaster()

  const notificationSSRMServer = () => {
    const buildFetchRequest = (params: IServerSideGetRowsParams) => {
      const { request } = params
      const sortFields = request.sortModel.map((sortItem: SortModelItem) => ({
        direction: sortItem.sort.toUpperCase(),
        field: sortItem.colId,
      }))
      const filters = {}
      for (const [key, value] of Object.entries(request.filterModel)) {
        const { filter, values } = value as any
        if (key === 'description' || key === 'project') {
          set(filters, ['description'], filter)
        } else if (key === 'status') {
          if (filter === 'ALL') {
            set(filters, [key], ['READ', 'UNREAD'])
          } else {
            set(filters, [key], [filter])
          }
        } else {
          set(filters, [key], values)
        }
      }
      return {
        filters: filters,
        order: sortFields,
      }
    }

    return {
      getData: async (params: IServerSideGetRowsParams) => {
        const request = buildFetchRequest(params)
        try {
          const pageNumber =
            params.request.endRow !== undefined
              ? Math.ceil(params.request.endRow / 20)
              : null
          const res = await axios.post(
            env.apiDomainUrl +
              NOTIFICATION_SERVICE_API_DOMAIN_URL +
              `/notifications?page=${pageNumber}`,
            request,
            {
              headers: { 'Content-Type': 'application/json' },
            },
          )
          return {
            success: true,
            data: res.data,
            rows: res.data,
            headers: res.headers,
          }
        } catch (err: any) {
          makeToast({
            ...TOASTER_DEFAULTS,
            type: 'error',
            heading: 'Failed to Fetch Notifications',
            message: err.response.data.message,
          })
        }
      },
    }
  }

  return (
    <NotificationSSRMServerContext.Provider value={{ notificationSSRMServer }}>
      {children}
    </NotificationSSRMServerContext.Provider>
  )
}

export const NotificationSSRMServerProvider = connect(
  null,
  null,
)(NotificationSSRMServerComponent)

export const useNotificationSSRMServerContext = () =>
  useContext(NotificationSSRMServerContext)
