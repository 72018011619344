import React from 'react'
import { Input } from '@enterprise-ui/canvas-ui-react'

export interface Props {
  value: boolean
}

const DimsEditableToggle = ({ value }: Props) => (
  <Input.Toggle
    aria-label="Flat Dims Editable"
    id="dims_editable"
    className="hc-ma-none hc-pv-dense"
    checked={value}
    disabled
  />
)

export default DimsEditableToggle
