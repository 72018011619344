import React, { Fragment, useEffect } from 'react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { Grid, Spinner } from '@enterprise-ui/canvas-ui-react'
import { useMilestoneContext } from '../../../../../App/context/milestoneContext'
import { ScheduleTemplate } from '../../../../../../models/milestones/ScheduleTemplate'
import { useUserContext } from '../../../../../App/context/userContext'

export const AddCustomScheduleTemplate = () => {
  const { userName } = useUserContext()!
  const {
    allScheduleTemplates,
    getAllScheduleTemplates,
    isAllScheduleTemplatesLoading,
    setScheduleTemplateByScheduleTemplateId,
  } = useMilestoneContext()!

  const masterTemplates = allScheduleTemplates
    .filter(
      (scheduleTemplate: ScheduleTemplate) =>
        scheduleTemplate.master_template === false,
    )
    .filter(
      (scheduletemplate: ScheduleTemplate) =>
        scheduletemplate.created_by === userName,
    )
    .map((scheduleTemplate: ScheduleTemplate) => ({
      id: scheduleTemplate.template_id,
      value: scheduleTemplate.template_id,
      label: scheduleTemplate.template_name,
    }))

  useEffect(() => {
    getAllScheduleTemplates()
  }, [getAllScheduleTemplates])

  return (
    <Fragment>
      {isAllScheduleTemplatesLoading ? (
        <Grid.Container algin="center" justify="center">
          <Grid.Item>
            <Spinner />
          </Grid.Item>
        </Grid.Container>
      ) : (
        <Autocomplete
          id="add_milestone"
          label="Choose a Custom Template to add to this page: "
          options={masterTemplates}
          optionHeight="dense"
          onUpdate={(event: any, value: any) => {
            if (value !== null) {
              setScheduleTemplateByScheduleTemplateId(
                allScheduleTemplates.find(
                  (scheduleTemplate: ScheduleTemplate) =>
                    scheduleTemplate.template_id === value.id,
                ),
              )
            }
          }}
        />
      )}
    </Fragment>
  )
}

export default AddCustomScheduleTemplate
