import React, { Fragment, useEffect } from 'react'
import { Layout, Grid, Card } from '@enterprise-ui/canvas-ui-react'
import MainSectionHeader from '../../../App/components/MainSectionHeader'
import { useStandardSignAdminContext } from '../context/standardSignAdminContext'
import StandardSignTable from './StandardSignTable'
import MoveSignsModal from './agGridTypes/MoveSignsModal'

const StandardSignAdminContainer = () => {
  const { getSignTemplates /*signTemplatesModified*/ } =
    useStandardSignAdminContext()!

  useEffect(() => {
    getSignTemplates()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <Layout.SectionHeader>
        <MainSectionHeader sectionTitle="Standard Sign Templates" />
      </Layout.SectionHeader>
      <Layout.Body className="hc-pa-expanded" includeRail>
        <Card className="hc-pa-expanded">
          <Grid.Container justify="space-between">
            <StandardSignTable />
            <MoveSignsModal />
          </Grid.Container>
        </Card>
      </Layout.Body>
    </Fragment>
  )
}

export default StandardSignAdminContainer
