import React, { createContext, useCallback, useContext, useState } from 'react'
import {
  SortChangedEvent,
  FilterChangedEvent,
  RowNode,
  SelectionChangedEvent,
  IServerSideGetRowsParams,
  GridApi,
  ColumnApi,
} from 'ag-grid-community'
import { connect } from 'react-redux'
import { SortState } from '../../../models/agGrid/AgGridSortState.model'
import { useAppContext } from '../../App/context/appContext'
import { useEnv } from '@praxis/component-runtime-env'
import {
  NOTIFICATION_SERVICE_API_DOMAIN_URL,
  TOASTER_DEFAULTS,
} from '../../App/constants/appConstants'
import axios from 'axios'
import NotificationResponse from '../../../models/notifications/NotificationResponse.model'
import { useUserContext } from '../../App/context/userContext'
import NotificationSettingsResponse from '../../../models/notifications/NotificationSettingsResponse.model'
import { NotificationPreferences } from '../../../models/notifications/NotificationSettingsResponse.model'
import { useToaster } from '@enterprise-ui/canvas-ui-react'

type ContextType = {
  onSortChanged: (event: SortChangedEvent) => void
  onFilterChanged: (event: FilterChangedEvent) => void
  notificationSettings: NotificationSettingsResponse
  setNotificationSettings: (settings: NotificationSettingsResponse) => void
  getNotificationSettings: () => void
  setPreference: (type: string, checked: boolean) => void
  updateSettings: () => void
  updateNotificationsToRead: (ids: string[]) => void
  updateAllNotificationsToRead: () => void
  selectedRows: NotificationResponse[]
  setSelectedRows: (selectedNotifications: NotificationResponse[]) => void
  onSelectionChange: (event: SelectionChangedEvent) => void
  notificationsDatasource: (server: any) => void
  gridApi: GridApi | undefined
  setGridApi: (gridApi: GridApi) => void
  setGridColumnApi: (gridColumnApi: ColumnApi) => void
}

export const NotificationsContext = createContext<ContextType | undefined>(
  undefined,
)

type Props = {
  children: React.ReactNode
}

export const NotificationsContextProviderComponent = ({ children }: Props) => {
  const {
    setNotificationsSortState,
    setNotificationsFilter,
    setFullPageLoadingMessage,
  } = useAppContext()!
  const { userPermissions, userType } = useUserContext()!
  const env = useEnv()
  const makeToast = useToaster()
  const [selectedRows, setSelectedRows] = useState<NotificationResponse[]>([])
  const [notificationSettings, setNotificationSettings] = useState<any>({})
  const [gridApi, setGridApi] = useState<GridApi>()
  const [, setGridColumnApi] = useState<ColumnApi>()

  const refreshDatasource = useCallback(() => {
    gridApi!.refreshServerSideStore({ purge: true })
  }, [gridApi])

  const updateNotificationsToRead = async (ids: string[]) => {
    setFullPageLoadingMessage('Marking as read...')
    try {
      await axios.put(
        env.apiDomainUrl +
          NOTIFICATION_SERVICE_API_DOMAIN_URL +
          '/notifications',
        {
          ids: ids,
          status: 'READ',
        },
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      refreshDatasource()
      setSelectedRows([])
      gridApi!.deselectAll()
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to update notifications',
        message: err.response.data.message,
      })
    }
    setFullPageLoadingMessage('')
  }

  const updateAllNotificationsToRead = useCallback(async () => {
    setFullPageLoadingMessage('Marking as read...')
    try {
      await axios.put(
        env.apiDomainUrl +
          NOTIFICATION_SERVICE_API_DOMAIN_URL +
          '/notifications/all',
      )
      refreshDatasource()
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'success',
        heading: 'Marked as Read',
        message: 'Marked all notifications as read',
      })
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to update notifications',
        message: err.response.data.message,
      })
    }
    setFullPageLoadingMessage('')
  }, [
    env.apiDomainUrl,
    refreshDatasource,
    setFullPageLoadingMessage,
    makeToast,
  ])

  const onSelectionChange = (event: SelectionChangedEvent) => {
    if (event.api.getSelectedNodes().length > 0) {
      setSelectedRows(
        event.api
          .getSelectedNodes()
          .map((node: RowNode) => new NotificationResponse(node.data)),
      )
    } else {
      setSelectedRows([])
    }
  }

  const onSortChanged = (event: SortChangedEvent) => {
    setNotificationsSortState(
      event.columnApi
        .getColumnState()
        .filter((s) => s.sort != null)
        .map((s) => new SortState(s)),
    )
  }

  const onFilterChanged = (event: FilterChangedEvent) => {
    setNotificationsFilter(event.api.getFilterModel())
  }

  const getNotificationSettings = async () => {
    try {
      const res = await axios.get(
        `${env.apiDomainUrl}/notification_services/user_preferences`,
      )
      setNotificationSettings(res.data)
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to update notifications',
        message: err.response.data.message,
      })
    }
  }

  const setPreference = (type: string, checked: boolean) => {
    const newPreferences = notificationSettings.preferences.map(
      (preference: NotificationPreferences) => {
        if (preference.notification_type === type) {
          return {
            notification_type: type,
            email_opted: checked,
          }
        } else {
          return preference
        }
      },
    )
    setNotificationSettings({
      preferences: newPreferences,
      email: notificationSettings.email,
      last_updated_ts: notificationSettings.last_updated_ts,
    })
  }

  const updateSettings = async () => {
    setFullPageLoadingMessage('Saving Preferences...')
    try {
      await axios.put(
        `${env.apiDomainUrl}/notification_services/user_preferences`,
        {
          preferences: notificationSettings.preferences,
        },
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'success',
        heading: 'Saved Preferences',
        message: 'Successfully saved email preferences',
      })
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Update Preferences',
        message: err.response.data.message,
      })
    }
    setFullPageLoadingMessage('')
  }

  const notificationsDatasource = (server: any) => {
    return {
      getRows: async (params: IServerSideGetRowsParams) => {
        const res = await server.getData(params)
        const lastRowIndex = res.data.length - 1
        if (res?.success) {
          params.success({
            rowData: res.rows,
            rowCount: +res.headers['total-count'],
            storeInfo: {
              finalNotificationData:
                lastRowIndex > -1 ? res.data[lastRowIndex].result : undefined,
              userInfo: { userPermissions, userType },
            },
          })
        }
      },
    }
  }

  return (
    <NotificationsContext.Provider
      value={{
        onSortChanged,
        onFilterChanged,
        notificationSettings,
        setNotificationSettings,
        getNotificationSettings,
        setPreference,
        updateSettings,
        updateNotificationsToRead,
        updateAllNotificationsToRead,
        selectedRows,
        setSelectedRows,
        onSelectionChange,
        notificationsDatasource,
        gridApi,
        setGridApi,
        setGridColumnApi,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  )
}

export const useNotificationsContext = () => useContext(NotificationsContext)

export const NotificationsProvider = connect(
  null,
  null,
)(NotificationsContextProviderComponent)
