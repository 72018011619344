import { KitSignView } from '../../../../../models/kits/KitSignResponse.model'
import React, { Fragment, useEffect, useState, useCallback } from 'react'
import {
  Button,
  Grid,
  Heading,
  Card,
  Spinner,
} from '@enterprise-ui/canvas-ui-react'
import { KitResponse } from '../../../../../models/kits/KitResponse.model'
import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import { gridOptions, columns } from '../constants/AgGridConstants'
import { Column } from '../../../../../models/agGrid/AgGridColumn.model'
import { ColumnApi, GridApi } from 'ag-grid-community'
import EnterpriseIcon, {
  MaximizeIcon,
  MinimizeIcon,
} from '@enterprise-ui/icons'
import {
  KitRequest,
  SignQuantity,
} from '../../../../../models/kits/KitRequest.model'
import SignResponse from '../../../../../models/signs/SignResponse.model'

export interface Props {
  kit: KitResponse
  signs: SignResponse[]
  values: KitRequest
  setFieldValue: Function
}

const AgGridProjectKitSignsView = ({
  kit,
  signs,
  values,
  setFieldValue,
}: Props) => {
  const getKitSigns = useCallback(
    (kit: KitResponse): KitSignView[] => {
      return kit.signs.map((kitSign) => {
        const project_sign = signs.find(
          (sign) => kitSign.sign_id === sign.sign_id,
        )
        return new KitSignView({
          project_id: project_sign?.project_id,
          sign_id: project_sign?.sign_id,
          dpci: project_sign?.non_retail_item_info.dpci,
          sign_name: project_sign?.sign_name,
          sign_description: project_sign?.description,
          sign_status: project_sign?.sign_status,
          total_stores: kit.stores?.length,
          quantity_per_kit: kitSign.quantity,
          nrsc_quantity: (kit.stores?.length || 0) * (kitSign.quantity || 0),
          kit_by_itself: project_sign?.distribution.kit_by_itself,
          print_vendor: project_sign?.printing.print_vendor,
          flat_height:
            project_sign?.non_retail_item_info.nominal_dimensions.height,
          flat_width:
            project_sign?.non_retail_item_info.nominal_dimensions.width,
          flat_depth:
            project_sign?.non_retail_item_info.nominal_dimensions.depth,
        })
      })
    },
    [signs],
  )

  const [kitSigns, setKitSigns] = useState<KitSignView[]>([])
  const [gridApi, setGridApi] = useState<GridApi>()
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>()
  const onGridReady = (params: any) => {
    setGridApi(params.api)
    setGridColumnApi(params.columnApi)
    params.columnApi.autoSizeColumns(
      params.columnApi.getAllColumns().map((column: any) => column.colId),
      false,
    )
  }
  const handleCellValueChange = (e: any) => {
    setFieldValue(
      `sign_quantities[${e.rowIndex}]`,
      new SignQuantity({
        sign_id: e.data.sign_id,
        sign_quantity: +e.data.quantity_per_kit,
      }),
    )
    return (e.data.modified = true)
  }
  useEffect(() => {
    if (kit !== undefined && kitSigns.length === 0 && signs.length > 0) {
      const kitSigns = getKitSigns(kit)
      setKitSigns(kitSigns)
    }
  }, [kitSigns, setKitSigns, kit, signs, getKitSigns])

  return (
    <Fragment>
      <Card className="sem_ProjectKitSignCard hc-pa-normal hc-pt-expanded">
        <div className="hc-pa-normal hc-pa-dense">
          <Grid.Container className="hc-pa-dense">
            <Grid.Item xs={true} className="hc-pa-dense">
              <Heading size={5}>Signs</Heading>
            </Grid.Item>
            <Grid.Item className="hc-pa-dense">
              {gridApi && (
                <Button type="ghost" onClick={() => gridApi.sizeColumnsToFit()}>
                  <EnterpriseIcon
                    className="hc-mr-normal"
                    icon={MinimizeIcon}
                  />
                  Size Columns to Fit
                </Button>
              )}
            </Grid.Item>
            <Grid.Item className="hc-pa-dense">
              {gridColumnApi && (
                <Button
                  type="ghost"
                  onClick={() => {
                    const columns = gridColumnApi.getAllColumns()
                    if (columns !== null) {
                      gridColumnApi.autoSizeColumns(
                        columns.map((column: any) => column.colId),
                        false,
                      )
                    }
                  }}
                >
                  <EnterpriseIcon
                    className="hc-mr-normal"
                    icon={MaximizeIcon}
                  />
                  Auto Size All Columns
                </Button>
              )}
            </Grid.Item>
            <Grid.Item xs={12} className="hc-pa-none">
              {kitSigns.length === 0 && <Spinner />}
              {kitSigns.length > 0 && (
                <div
                  className="ag-theme-rubix kit-sign-list-table"
                  style={{ height: 50 + 48 * kit.signs.length }}
                >
                  <AgGridReact
                    rowData={kitSigns}
                    gridOptions={gridOptions}
                    onGridReady={onGridReady}
                    onCellValueChanged={(e: any) => handleCellValueChange(e)}
                    suppressRowClickSelection={true}
                  >
                    {columns.map((column: Column, index: number) => (
                      <AgGridColumn
                        field={column.field}
                        headerName={column.headerName}
                        width={column.width || 148}
                        type={column.type}
                        colId={column.colId}
                        key={index}
                      />
                    ))}
                  </AgGridReact>
                </div>
              )}
            </Grid.Item>
          </Grid.Container>
        </div>
      </Card>
    </Fragment>
  )
}

export default AgGridProjectKitSignsView
