import { ProjectDetail } from './ProjectDetail.model'

export class ProjectSearchResponse {
  total_pages: number
  total_results: number
  page: number
  per_page: number
  sort_fields: any
  search: ProjectSearchResult[]

  constructor(props: any = {}) {
    this.total_pages = props.total_pages || 0
    this.total_results = props.total_results || 0
    this.page = props.page || 0
    this.per_page = props.per_page || 0
    this.sort_fields = props.sort_fields || {}
    this.search = props.search || []
  }
}

export class ProjectSearchResult {
  result: ProjectDetail

  constructor(props: any = {}) {
    this.result = new ProjectDetail(props.result)
  }
}

export class ProjectChangeLog {
  identifier: string
  change_level: string
  change_type: string
  change_description: string
  created_by: string
  created_date: Date

  constructor(props: any = {}) {
    this.identifier = props.identifier || ''
    this.change_level = props.change_level || ''
    this.change_type = props.change_type || ''
    this.change_description = props.change_description || ''
    this.created_by = props.created_by || ''
    this.created_date = props.created_date || new Date()
  }
}
