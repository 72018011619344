import React, { Fragment, useState, useEffect } from 'react'
import { Button, Form, Grid, Input } from '@enterprise-ui/canvas-ui-react'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { useProjectMilestoneForm } from '../hooks/useProjectMilestoneForm'
import { useUserContext } from 'components/App/context/userContext'
import { MilestoneTemplate } from '../../../../../models/milestones/MilestoneTemplate.model'
import { useProjectDetailsContext } from '../../../context/projectDetailsContext'
import { useProjectMilestoneTabContext } from '../context/projectMilestoneTabContext'
import { useMilestoneContext } from 'components/App/context/milestoneContext'

const CreateProjectMilestoneForm = () => {
  const { currentProject } = useProjectDetailsContext()!
  const { userName } = useUserContext()!
  const { milestoneFacets, getMilestoneFacets } = useMilestoneContext()!
  const { addNewMilestone, closeMilestoneForm, projectMilestone } =
    useProjectMilestoneTabContext()!

  const today = moment()
  const [dueDate, setDueDate] = useState(today)
  const [riskDate, setRiskDate] = useState(today)
  const todayDaysOut = moment(currentProject.set_date).diff(today, 'days')

  const newMilestone = new MilestoneTemplate({
    milestone_id: uuidv4(),
    name: '',
    project_phase: '',
    role: '',
    completion_level: '',
    custom_milestone: true,
    created_date: new Date().toISOString(),
    updated_by: userName,
    updated_date: new Date().toISOString(),
    critical: false,
    risk: false,
    status: 'Upcoming',
    days_out: todayDaysOut,
    information_only: false,
  })

  const { setFieldValue, handleSubmit, values, errors } =
    useProjectMilestoneForm(newMilestone, addNewMilestone, closeMilestoneForm)

  useEffect(() => {
    getMilestoneFacets()
  }, [getMilestoneFacets])

  const dependsOnFacets = projectMilestone.map(
    (template: MilestoneTemplate) => {
      return { value: template.name, label: template.name }
    },
  )
  dependsOnFacets.unshift({ value: '', label: '' })

  return (
    <Fragment>
      <Form>
        <Grid.Container className="sem-CreateMilestoneForm" align="flex-end">
          <Grid.Item className="hc-pb-none" xs={12}></Grid.Item>
          <Grid.Item className="hc-pv-dense" xs>
            <Form.Field
              id="name"
              label="Milestone Name"
              value={values.name}
              error={errors.name !== undefined}
              onChange={(e: any) => setFieldValue('name', e.target.value)}
              required
            />
          </Grid.Item>
          <Grid.Item className="hc-pv-dense" xs={12}>
            <Form.Field
              type="select"
              id="phase"
              label="Project Phase"
              value={values.project_phase}
              error={errors.project_phase !== undefined}
              options={[
                {
                  value: 'Production to Market',
                  label: 'Production to Market',
                },
                {
                  value: 'Sign List Finalization',
                  label: 'Sign List Finalization',
                },
              ]}
              required
              onUpdate={(e: any, phase: string) => {
                setFieldValue('completion_level', 'PROJECT')
                setFieldValue('project_phase', phase)
              }}
            />
          </Grid.Item>
          <Grid.Item className="hc-pv-dense" xs={12}>
            <Form.Field
              type="select"
              id="dependsOn"
              label="Depends On"
              value={values.depends_on}
              error={errors.depends_on !== undefined}
              options={dependsOnFacets}
              onUpdate={(e: any, dependsOn: string) => {
                setFieldValue('depends_on', dependsOn)
              }}
            />
          </Grid.Item>
          <Grid.Item className="hc-pv-dense" xs={6}>
            <Form.Field
              type="select"
              id="role"
              label="Role"
              value={values.role}
              error={errors.role !== undefined}
              options={milestoneFacets.roles}
              onUpdate={(e: any, role: string) => setFieldValue('role', role)}
              required
            />
          </Grid.Item>
          <Grid.Item className="hc-pv-dense" xs={6}>
            <Form.Field
              type="date"
              className="sem_InlineDatePicker"
              id="due_date"
              label="Due Date:"
              value={dueDate}
              onChange={(event: any) => {
                setDueDate(event.target.value)
                const newDaysOut = moment(currentProject.set_date).diff(
                  event.target.value,
                  'days',
                )
                setFieldValue('days_out', newDaysOut)
              }}
              location="bottom-left"
            />
          </Grid.Item>
          <Grid.Item xs={6}>
            <Input.Checkbox
              id="info_only"
              label="Information Only"
              checked={values.information_only}
              error={errors.information_only !== undefined}
              onChange={(e: any) => {
                setFieldValue('information_only', !values.information_only)
              }}
            />
          </Grid.Item>
          {values.critical && (
            <Grid.Item className="hc-pv-dense" xs={6}>
              <Form.Field
                type="date"
                className="sem_InlineDatePicker"
                id="risk_date"
                label="Risk Date:"
                value={
                  moment(riskDate).isValid()
                    ? moment(riskDate).format('MM/DD/YYYY')
                    : ''
                }
                onUpdate={(id: string, value: string) => {
                  if (value !== '') {
                    const newDate = moment(value)

                    setRiskDate(newDate)
                    const newRiskDaysOut = moment(currentProject.set_date).diff(
                      newDate,
                      'days',
                    )
                    setFieldValue('risk_days_out', newRiskDaysOut)
                  }
                }}
                location="bottom-left"
              />
            </Grid.Item>
          )}
          <Grid.Item xs={12}>
            <Button
              fullWidth
              type="primary"
              className="save-Button"
              onClick={() => handleSubmit()}
            >
              Save Milestone
            </Button>
          </Grid.Item>
        </Grid.Container>
      </Form>
    </Fragment>
  )
}

export default CreateProjectMilestoneForm
