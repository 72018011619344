import React from 'react'
import { Grid, Heading, Button, Tooltip } from '@enterprise-ui/canvas-ui-react'
import { Gallery } from '@enterprise-ui/canvas-ui-react-gallery'
import { useEnv } from '@praxis/component-runtime-env'
import { useSignDetailsContext } from '../context/signDetailsContext'
import { SIGN_DETAILS_SIDEBAR } from '../constants/signDetailsConstants'
import EnterpriseIcon, { CancelIcon, ImagesIcon } from '@enterprise-ui/icons'

const SignGallery = () => {
  const { openSidebar, setOpenSidebar, currentSign } = useSignDetailsContext()!
  const env = useEnv()

  return (
    <Grid.Item
      className={`sem_GalleryTab ${
        openSidebar === SIGN_DETAILS_SIDEBAR.GALLERY ? 'open' : ''
      }`}
    >
      <Grid.Container justify="center" className="hc-mb-dense">
        <Grid.Item
          className="hc-pb-none"
          xs={openSidebar === SIGN_DETAILS_SIDEBAR.GALLERY ? 12 : false}
        >
          <div className="sem_GalleryBtn">
            <Button type="ghost" onClick={() => setOpenSidebar('')}>
              <EnterpriseIcon icon={ImagesIcon} size="lg" />
              <Heading className="hc-clr-grey02 hc-ml-normal" size={5}>
                Sign Gallery
              </Heading>
            </Button>
            <div style={{ marginLeft: 'auto' }}>
              <Tooltip location="bottom-left" content="Hide Gallery Panel">
                <Button
                  aria-label="Close Gallery"
                  type="ghost"
                  iconOnly
                  className="sem_GalleryBtnClose"
                  onClick={() => setOpenSidebar('')}
                >
                  <EnterpriseIcon icon={CancelIcon} />
                </Button>
              </Tooltip>
            </div>
          </div>
        </Grid.Item>
        <Grid.Item
          xs={openSidebar === SIGN_DETAILS_SIDEBAR.GALLERY ? 12 : false}
        >
          <Gallery
            className={`sem_Gallery ${
              openSidebar === SIGN_DETAILS_SIDEBAR.GALLERY ? 'open' : ''
            }`}
            mainItemHeight="55vh"
            // @ts-ignore
            items={
              currentSign.non_retail_item_info.storage_urls.length > 0
                ? currentSign.non_retail_item_info.storage_urls.map(
                    (url: string) => ({
                      alt: `${currentSign.non_retail_item_info.title} Sign Image`,
                      id: `sign_dpci_${currentSign.non_retail_item_info.dpci}`,
                      thumbUrl: `${url}?key=${env.assethubApiKey}`,
                      url: `${url}?key=${env.assethubApiKey}`,
                    }),
                  )
                : [
                    {
                      alt: 'No Preview Available',
                      id: 'sign_dpci_000-00-0000',
                      thumbUrl: null,
                      url: null,
                    },
                  ]
            }
          />
        </Grid.Item>
      </Grid.Container>
    </Grid.Item>
  )
}

export default SignGallery
