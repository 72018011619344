import React from 'react'
import { Button, Dropdown, Grid } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { CaretDownIcon } from '@enterprise-ui/icons'
import { useDashboardContext } from '../context/dashboardContext'
import { TabName } from '../constants/dashboardConstants'
import { useUserContext } from '../../App/context/userContext'
import { useAppContext } from '../../App/context/appContext'

const DashboardViews = () => {
  const { userPermissions } = useUserContext()!
  const { dashboardActiveTab } = useAppContext()!
  const {
    includeCompleteBlueprints,
    includeAllActiveBlueprints,
    includeCompleteProjects,
    includeAllActiveProjects,
    includeCompleteProjectMilestones,
    includeCompleteBpMilestones,
    setIncludeCompleteBlueprints,
    setIncludeAllActiveBlueprints,
    setIncludeCompleteBpMilestones,
    setIncludeCompleteProjects,
    setIncludeAllActiveProjects,
    setIncludeCompleteProjectMilestones,
  } = useDashboardContext()!

  const handleShowMyActive = () => {
    switch (dashboardActiveTab) {
      case TabName.MY_BLUEPRINT_MILESTONES:
        setIncludeCompleteBpMilestones(false)
        break
      case TabName.MY_PROJECT_MILESTONES:
        setIncludeCompleteProjectMilestones(false)
        break
      case TabName.BLUEPRINT_SUMMARY:
        setIncludeCompleteBlueprints(false)
        setIncludeAllActiveBlueprints(false)
        break
      case TabName.PROJECT_SUMMARY:
        setIncludeCompleteProjects(false)
        setIncludeAllActiveProjects(false)
        break
      default:
        break
    }
  }

  const handleShowMyActiveAndCompleted = () => {
    switch (dashboardActiveTab) {
      case TabName.MY_BLUEPRINT_MILESTONES:
        setIncludeCompleteBpMilestones(true)
        break
      case TabName.MY_PROJECT_MILESTONES:
        setIncludeCompleteProjectMilestones(true)
        break
      case TabName.BLUEPRINT_SUMMARY:
        setIncludeCompleteBlueprints(true)
        setIncludeAllActiveBlueprints(false)
        break
      case TabName.PROJECT_SUMMARY:
        setIncludeCompleteProjects(true)
        setIncludeAllActiveProjects(false)
        break
      default:
        break
    }
  }

  const handleShowAllActiveAreaAdmin = () => {
    switch (dashboardActiveTab) {
      case TabName.BLUEPRINT_SUMMARY:
        setIncludeAllActiveBlueprints(true)
        setIncludeCompleteBlueprints(false)
        break
      case TabName.PROJECT_SUMMARY:
        setIncludeAllActiveProjects(true)
        setIncludeCompleteProjects(false)
        break
      default:
        break
    }
  }

  const viewLabel = () => {
    switch (dashboardActiveTab) {
      case TabName.MY_BLUEPRINT_MILESTONES:
        return includeCompleteBpMilestones
          ? 'Active & Completed Milestones'
          : 'Active Milestones'
      case TabName.MY_PROJECT_MILESTONES:
        return includeCompleteProjectMilestones
          ? 'Active & Completed Milestones'
          : 'Active Milestones'
      case TabName.BLUEPRINT_SUMMARY:
        return includeCompleteBlueprints
          ? 'Active & Archived Blueprints'
          : includeAllActiveBlueprints
          ? 'All Active Area Blueprints - Admin'
          : 'Active Blueprints'
      case TabName.PROJECT_SUMMARY:
        return includeCompleteProjects
          ? 'Active & Archived Projects'
          : includeAllActiveProjects
          ? 'All Active Area Projects - Admin'
          : 'Active Projects'
      default:
        return 'Active Milestones'
    }
  }

  return (
    <Grid.Item className="hc-ph-dense hc-pb-dense" xs={true}>
      <Dropdown size="normal" location="bottom-left">
        <Button type="ghost">
          View: {viewLabel()}
          <EnterpriseIcon className="hc-ml-dense" icon={CaretDownIcon} />
        </Button>
        <Dropdown.Menu>
          <Dropdown.MenuItem onClick={() => handleShowMyActive()}>
            Active
          </Dropdown.MenuItem>
          <Dropdown.MenuItem onClick={() => handleShowMyActiveAndCompleted()}>
            Active &amp; Completed
          </Dropdown.MenuItem>
          {(dashboardActiveTab === TabName.BLUEPRINT_SUMMARY ||
            dashboardActiveTab === TabName.PROJECT_SUMMARY) &&
          userPermissions.isBlueprintAdmin &&
          userPermissions.isProjectAdmin &&
          userPermissions.isSignAdmin ? (
            <Dropdown.MenuItem onClick={() => handleShowAllActiveAreaAdmin()}>
              All Area Active - Admin
            </Dropdown.MenuItem>
          ) : null}
        </Dropdown.Menu>
      </Dropdown>
    </Grid.Item>
  )
}

export default DashboardViews
