import React, { Fragment } from 'react'
import { useNavigate } from 'react-router'
import moment from 'moment'
import { Button, Dropdown } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, {
  CancelCircleIcon,
  FilesIcon,
  OverflowMenuVerticalIcon,
  PlusCircleIcon,
  VisibleShowIcon,
} from '@enterprise-ui/icons'
import { ProjectDetail } from '../../../../../../../models/projects/ProjectDetail.model'
import { RowNode } from 'ag-grid-community'
import { useAgGridProjectListContext } from '../../../context/agGridProjectListContext'
import { NEW_SIGN_PROJECT } from '../../../../SignProjectTab/constants/signProjectConstants'
import { useAppContext } from 'components/App/context/appContext'
import { BLUEPRINT_PROJECT_LIST_VIEW } from 'components/App/constants/appConstants'
import { useBlueprintProjectListContext } from '../../../context/blueprintProjectListContext'

export interface Props {
  data: ProjectDetail
  node: RowNode
}

const RowDropdownMenu = ({ data = new ProjectDetail(), node }: Props) => {
  const { cancelSelectedProjects } = useAgGridProjectListContext()!
  const navigate = useNavigate()
  const handleViewProjectClick = (project_id: string) =>
    navigate(`/projects/${project_id}`)
  const { blueprintProjectListView } = useAppContext()!
  const { setNewProjectModal } = useBlueprintProjectListContext()!

  return (
    <Dropdown size="expanded" location="bottom-left">
      <Button
        aria-label="Row Dropdown Menu"
        type="ghost"
        className="sem_IconBtn-dark"
        data-testid="openSignDropdown"
        iconOnly
        size="dense"
      >
        <EnterpriseIcon icon={OverflowMenuVerticalIcon} size="sm" />
      </Button>
      <Dropdown.Menu>
        <Dropdown.MenuItem as="div" className="sem_DropdownMenuItem--info">
          <span className="sem_StrongText hc-mr-dense">Last Modified:</span>
          {moment(data.updated_date).format('MM/DD/YYYY hh:mm A')}
        </Dropdown.MenuItem>
        <Dropdown.MenuItem
          as="div"
          className="sem_DropdownMenuItem--info divider"
        >
          <span className="sem_StrongText hc-mr-dense">Modified By:</span>
          {data.updated_by}
        </Dropdown.MenuItem>
        <Dropdown.MenuItem
          className="sem_DropdownMenuItem--action"
          disabled={data.project_id?.split(':')[0] === NEW_SIGN_PROJECT}
          onClick={() => handleViewProjectClick(data.project_id)}
        >
          <EnterpriseIcon className="hc-mr-dense" icon={VisibleShowIcon} />
          View Project Details
        </Dropdown.MenuItem>
        {blueprintProjectListView === BLUEPRINT_PROJECT_LIST_VIEW.ACTIVE && (
          <Fragment>
            <Dropdown.MenuItem
              className="sem_DropdownMenuItem--action"
              onClick={() => setNewProjectModal({ type: 'add', node })}
            >
              <EnterpriseIcon className="hc-mr-normal" icon={PlusCircleIcon} />
              Insert Blank Project/SAP ID Requests
            </Dropdown.MenuItem>
            <Dropdown.MenuItem
              className="sem_DropdownMenuItem--action"
              disabled={data.project_id?.split(':')[0] === NEW_SIGN_PROJECT}
              onClick={() => setNewProjectModal({ type: 'duplicate', node })}
            >
              <EnterpriseIcon className="hc-mr-dense" icon={FilesIcon} />
              Duplicate Project/SAP ID Requests
            </Dropdown.MenuItem>
            <Dropdown.MenuItem
              className="sem_DropdownMenuItem--action"
              onClick={() => cancelSelectedProjects([node])}
            >
              <EnterpriseIcon className="hc-mr-dense" icon={CancelCircleIcon} />
              Cancel Project/SAP ID Request
            </Dropdown.MenuItem>
          </Fragment>
        )}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default RowDropdownMenu
