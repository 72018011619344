import React, { createContext, useContext } from 'react'
import { IServerSideGetRowsParams } from 'ag-grid-community'
import { connect } from 'react-redux'
import Axios from 'axios'
import { useEnv } from '@praxis/component-runtime-env'
import {
  HEADER_OBJECT,
  SEARCH_RESPONSE_SIZE,
  SIGN_SERVICE_API_DOMAIN_URL,
  TOASTER_DEFAULTS,
} from '../../App/constants/appConstants'
import { SearchRequest } from '../../../models/app/SearchRequest.model'
import { SearchResult } from '../../../models/app/SearchResponse.model'
import { get, isEmpty, set } from 'lodash'
import { useFilterContext } from '../../App/context/filterContext'
import { formatDpcis } from '../helpers/filterFormHelpers'
import {
  SignLibraryResponse,
  SignLibrarySearchFacets,
} from '../../../models/signLibrary'
import { useToaster } from '@enterprise-ui/canvas-ui-react'

type ContextType = {
  signLibrarySSRMServer: () => void
}

export const SignLibrarySSRMServerContext = createContext<
  ContextType | undefined
>(undefined)

type Props = {
  children: React.ReactNode
}

export const SignLibrarySSRMServerComponent = ({ children }: Props) => {
  const { signLibrarySearchRequest, setSignLibrarySearchRequest } =
    useFilterContext()!
  const env = useEnv()
  const makeToast = useToaster()

  const signLibrarySSRMServer = () => {
    const buildSearchRequest = (params: IServerSideGetRowsParams) => {
      const { api, request } = params
      const finalRowData =
        api.getServerSideStoreState()[0].info.finalSignData &&
        new SignLibraryResponse(
          api.getServerSideStoreState()[0].info.finalSignData,
        )
      const partialFilters = {}
      const includeFilters = {}
      const excludeFilters = {}
      const rangeFilters = {}
      const sortFields =
        request.sortModel.length > 0
          ? Object.assign(
              {},
              ...request.sortModel.map((sortItem: any) => ({
                [sortItem.colId]: sortItem.sort,
                id: 'asc',
              })),
            )
          : { id: 'asc' }
      const searchAfter =
        request.startRow === 0
          ? []
          : request.sortModel.length > 0
          ? [
              get(finalRowData, `${Object.keys(sortFields)[0]}`, ''),
              finalRowData.id,
            ]
          : [finalRowData.id]

      if (isEmpty(request.filterModel)) {
        return new SearchRequest<SignLibrarySearchFacets>({
          ...signLibrarySearchRequest,
          sort_fields: sortFields,
          search_after: searchAfter,
          partial_filters: undefined,
          include_filters: undefined,
          exclude_filters: undefined,
          range_filters: undefined,
        })
      } else {
        for (const [key, value] of Object.entries(request.filterModel)) {
          const { filterType, type, filter, values, dateFrom, dateTo } =
            value as any
          switch (filterType) {
            case 'text':
              type === 'dpciList'
                ? set(
                    partialFilters,
                    [key],
                    formatDpcis(filter.toString().replace(/ /g, '').split(',')),
                  )
                : set(partialFilters, [key], filter.toString().split(','))
              break
            case 'number':
              set(includeFilters, [key], [filter?.toString()])
              break
            case 'date':
              set(rangeFilters, [key], {
                from: dateFrom.substring(0, 10),
                to: dateTo.substring(0, 10),
              })
              break
            case 'set':
              key === 'non_retail_item_info.department'
                ? set(
                    includeFilters,
                    ['non_retail_item_info.department.department_display_name'],
                    values.map((value: string) => value),
                  )
                : set(includeFilters, [key], values)
              break
            default:
              break
          }
        }

        return new SearchRequest<SignLibrarySearchFacets>({
          ...signLibrarySearchRequest,
          sort_fields: sortFields,
          search_after: searchAfter,
          partial_filters: isEmpty(partialFilters)
            ? signLibrarySearchRequest.partial_filters
            : partialFilters,
          include_filters: isEmpty(includeFilters)
            ? signLibrarySearchRequest.include_filters
            : includeFilters,
          exclude_filters: isEmpty(excludeFilters)
            ? signLibrarySearchRequest.exclude_filters
            : excludeFilters,
          range_filters: isEmpty(rangeFilters)
            ? signLibrarySearchRequest.range_filters
            : rangeFilters,
        })
      }
    }

    return {
      getData: async (params: IServerSideGetRowsParams) => {
        const filters = buildSearchRequest(params)
        try {
          const res: any = await Axios.post(
            `${
              env.apiDomainUrl + SIGN_SERVICE_API_DOMAIN_URL
            }/sign_library/search?per_page=${SEARCH_RESPONSE_SIZE}`,
            filters,
            HEADER_OBJECT,
          )
          setSignLibrarySearchRequest(filters)
          return {
            success: true,
            data: res.data,
            rows: res.data.search.map(
              (searchResult: SearchResult<SignLibrarySearchFacets>) =>
                new SignLibraryResponse(searchResult.result),
            ),
          }
        } catch (err: any) {
          makeToast({
            ...TOASTER_DEFAULTS,
            type: 'error',
            heading: 'Failed to Search Signs',
            message: err.response.data.message,
          })
        }
      },
    }
  }

  return (
    <SignLibrarySSRMServerContext.Provider value={{ signLibrarySSRMServer }}>
      {children}
    </SignLibrarySSRMServerContext.Provider>
  )
}

export const SignLibrarySSRMServerProvider = connect(
  null,
  null,
)(SignLibrarySSRMServerComponent)

export const useSignLibrarySSRMServerContext = () =>
  useContext(SignLibrarySSRMServerContext)
