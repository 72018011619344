import { RosterElement } from '../roster/RosterElement.model'
import { Division } from '../merchandise/hierarchy/Division.model'
import { SAPProject } from './SAPProject.model'
import { AssethubInfo } from './AssethubInfo.model'
import { MilestoneTemplate } from '../milestones/MilestoneTemplate.model'
import { ProjectNote } from './ProjectNote.model'
import { get } from 'lodash'
import { SapProjectExecutionStatus } from '../../components/BlueprintDetails/components/SignProjectTab/constants/signProjectConstants'
import { ChildCampaignType } from '../campaigns/ChildCampaignType.model'
import { Department } from '../merchandise/hierarchy/Department.model'
import { Workflow } from '../signs/SignResponse.model'
import { ProjectChangeLog } from './ProjectSearchResponse.model'

export class ProjectDetail {
  blueprint_id: string
  blueprint_name: string
  project_id: string
  name: string
  short_name: string
  status: string = ''
  workflow: Workflow
  signs_workflow_status: Workflow
  change_log: ProjectChangeLog[]
  locked: boolean
  project_phase: string
  health: string
  campaign_id: string
  creative_assets: boolean
  non_adjacency_project: boolean
  set_date: string
  in_store_date: string
  nrsc_receive_date: string
  pog_set_date: PogSetDate
  divisions: Division[]
  departments: Department[]
  project_type: string
  tactic: string
  fund_id: string
  initial_budget: number
  sap_project?: SAPProject
  sap_project_id: string
  sap_project_execution_status: SapProjectExecutionStatus
  sap_project_execution_message: string
  signs_finalized: boolean
  campaign: ChildCampaignType
  roster: RosterElement[]
  milestones: MilestoneTemplate[]
  notes: ProjectNote[]
  merchant_contact: string
  assethub_info: AssethubInfo
  migrated: boolean
  created_by: string
  created_date: string
  updated_by: string
  updated_date: string

  constructor(props: any = {}) {
    this.blueprint_id = props.blueprint_id || ''
    this.blueprint_name = props.blueprint_name || ''
    this.project_id = props.project_id || ''
    this.name = props.name || ''
    this.short_name = props.short_name || ''
    this.status = props.workflow?.status || ''
    this.workflow = props.workflow || {}
    this.signs_workflow_status = props.signs_workflow_status || {}
    this.locked = props.locked || false
    this.change_log = get(props, 'change_log', []).map(
      (change_log: any) => new ProjectChangeLog(change_log),
    )
    this.project_phase = props.project_phase || ''
    this.health = props.health || ''
    this.campaign_id = props.campaign_id || ''
    this.creative_assets = props.creative_assets || false
    this.non_adjacency_project = props.non_adjacency_project || false
    this.set_date = props.set_date || ''
    this.in_store_date = props.in_store_date || ''
    this.nrsc_receive_date = props.nrsc_receive_date || ''
    this.pog_set_date = props.pog_set_date || new PogSetDate()
    this.divisions = get(props, 'divisions', []).map(
      (division: any) => new Division(division),
    )
    this.departments = get(props, 'departments', []).map(
      (department: any) => new Department(department),
    )
    this.project_type = props.project_type || ''
    this.tactic = props.tactic || ''
    this.fund_id = props.fund_id || ''
    this.initial_budget = props.initial_budget || 0
    this.sap_project = props.sap_project
    this.sap_project_id = props.sap_project_id
    this.sap_project_execution_status = props.sap_project_execution_status
    this.sap_project_execution_message =
      props.sap_project_execution_message || ''
    this.signs_finalized = props.signs_finalized || false
    this.campaign = props.campaign || new ChildCampaignType()
    this.roster = get(props, 'roster', []).map(
      (rosterElement: any) => new RosterElement(rosterElement),
    )
    this.milestones = get(props, 'milestones', []).map(
      (milesonte: any) => new MilestoneTemplate(milesonte),
    )
    this.notes = get(props, 'notes', []).map(
      (note: any) => new ProjectNote(note),
    )
    this.merchant_contact = props.merchant_contact || ''
    this.migrated = props.migrated || false
    this.created_by = props.created_by || ''
    this.created_date = props.created_date || ''
    this.updated_by = props.updated_by || ''
    this.updated_date = props.updated_date || ''
    this.assethub_info = props.assethub_info || {}
  }
}

export class PogSetDate {
  use_marketing_plan_date: boolean
  use_multiple_set_date: boolean
  set_dates: string[]
  constructor(props: any = {}) {
    this.use_marketing_plan_date = props.use_marketing_plan_date || false
    this.use_multiple_set_date = props.use_multiple_set_date || false
    this.set_dates = props.set_dates || []
  }
}
