import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  Grid,
  Form,
  Tooltip,
  Input,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, {
  CheckIcon,
  MinusIcon,
  PencilIcon,
  TrashIcon,
} from '@enterprise-ui/icons'
import { DateFormatter } from '@enterprise-ui/canvas-ui-react-date'
import moment from 'moment'
import { useBlueprintDetailsContainerContext } from '../../../../context/blueprintDetailsContainerContext'
import { useBlueprintMilestoneTabContext } from '../../context/blueprintMilestoneTabContext'
import { useUserContext } from 'components/App/context/userContext'
import { MilestoneTemplate } from '../../../../../../models/milestones/MilestoneTemplate.model'
import { getMilestoneStatusChipColor } from 'components/App/helpers/userColor'
import { useAppContext } from 'components/App/context/appContext'
import { useMilestoneContext } from 'components/App/context/milestoneContext'
import {
  InternalUser,
  RosterUser,
} from '../../../../../../models/roster/RosterUser.model'
import { BusinessPartner } from '../../../../../../models/app/BusinessPartner.model'
import { DialogProps } from '../../../../../../models/app/DialogProps.model'
import RubixChip from 'components/common/RubixChip'

export interface Props {
  milestone: MilestoneTemplate
  disabled: Boolean
}

export const BlueprintMilestoneCard = ({ milestone, disabled }: Props) => {
  const { currentBlueprint } = useBlueprintDetailsContainerContext()!
  const { removeMilestone, updateMilestone, blueprintMilestone } =
    useBlueprintMilestoneTabContext()!
  const { setPageHasChanges, setDialogProps } = useAppContext()!
  const {
    modifiedMilestoneIds,
    addToModifiedMilestoneIds,
    milestoneFacets,
    isDependency,
  } = useMilestoneContext()!
  const [isEditCustomMilestone, setIsEditCustomMilestone] = useState(false)

  useEffect(() => {
    setDueDate(
      moment(currentBlueprint.set_date)
        .subtract(milestone.days_out, 'days')
        .format('yyyy-MM-DD'),
    )
  }, [currentBlueprint.set_date, milestone.days_out])

  useEffect(() => {
    setRiskDate(
      moment(currentBlueprint.set_date).subtract(
        milestone.risk_days_out,
        'days',
      ),
    )
  }, [currentBlueprint.set_date, milestone.risk_days_out])

  const { userName, userPermissions, userEmail, userCompany } =
    useUserContext()!
  const [isExpanded, setIsExpanded] = useState(false)
  const [editDueDate, setEditDueDate] = useState(false)

  const [dueDate, setDueDate] = useState(
    moment(currentBlueprint.set_date)
      .subtract(milestone.days_out, 'days')
      .format('yyyy-MM-DD'),
  )
  const [riskDate, setRiskDate] = useState(
    moment(currentBlueprint.set_date).subtract(milestone.risk_days_out, 'days'),
  )

  const roleInfo = currentBlueprint.roster
    ? currentBlueprint.roster.filter(
        (roles: any) => roles.title === milestone.role,
      )[0]
    : null

  const weeksOut = milestone.days_out
    ? milestone.days_out < 0
      ? 0
      : Math.floor(Math.abs(milestone.days_out) / 7)
    : 0

  const milestoneTemplateFacets = blueprintMilestone.milestones.map(
    (template: MilestoneTemplate) => {
      return { value: template.name, label: template.name }
    },
  )
  milestoneTemplateFacets.unshift({ value: '', label: '' })

  const handleCardClick = () => {
    if (!isExpanded) {
      setIsExpanded(!isExpanded)
    }
  }

  return (
    <Card
      className={`sem-MilestoneTemplateCard ${
        modifiedMilestoneIds.has(milestone.milestone_id) ? 'modified' : ''
      } hc-ph-dense`}
      onClickCapture={() => handleCardClick()}
    >
      {/*Menu Bar*/}
      <div className="hc-pa-none toolbar-Container">
        <Grid.Container
          className={
            isExpanded
              ? `sem-BlueprintMilestoneToolBar`
              : `justify="flex-end" spacing = "none"`
          }
        >
          {/*Collapse Button*/}
          {isExpanded && (
            <Grid.Item xs>
              <Tooltip location="bottom-right" content="Collapse">
                <Button
                  aria-label="close milestone"
                  iconOnly
                  type="ghost"
                  size="dense"
                  className="sem-MilestoneBtnRemove"
                  onClick={() => {
                    setIsExpanded(false)
                  }}
                >
                  <EnterpriseIcon color="gray" icon={MinusIcon} />
                </Button>
              </Tooltip>
            </Grid.Item>
          )}

          {/*Edit Button for Custom Milestone*/}
          <Grid.Item className="sem-BlueprintMilestoneEdit">
            {isExpanded &&
              milestone.custom_milestone &&
              userPermissions.isBlueprintAdmin &&
              currentBlueprint.status !== 'Archive' && (
                <Tooltip location="bottom" content="Edit">
                  <Button
                    aria-label="Edit Milestone"
                    type="ghost"
                    iconOnly
                    data-testid="editCustomMilestone"
                    className="sem-MilestoneBtnRemove"
                    onClick={() =>
                      setIsEditCustomMilestone(!isEditCustomMilestone)
                    }
                  >
                    <EnterpriseIcon color="gray" icon={PencilIcon} />
                  </Button>
                </Tooltip>
              )}
          </Grid.Item>

          {/*Delete Button*/}
          <Grid.Item className="sem-BlueprintMilestoneDelete">
            {isExpanded &&
              userPermissions.isBlueprintAdmin &&
              currentBlueprint.status !== 'Archive' && (
                <Tooltip location="bottom" content="Delete">
                  <Button
                    aria-label="Remove Milestone"
                    iconOnly
                    type="ghost"
                    data-testid="removeMilestone"
                    className="sem-MilestoneBtnRemove"
                    onClick={() => {
                      if (
                        isDependency(
                          milestone.name,
                          blueprintMilestone.milestones,
                        )
                      ) {
                        setDialogProps({
                          headingText:
                            'Other milestones are dependent on this milestone.  Please remove it as a dependency from all relevant milestones, then try again.',
                          approveButtonText: 'Ok',
                          onApprove: () => setDialogProps(new DialogProps()),
                          onRefuse: () => setDialogProps(new DialogProps()),
                        })
                      } else {
                        setDialogProps(
                          new DialogProps({
                            headingText:
                              'Are you sure you want to remove this milestone?',
                            approveButtonText: 'Yes',
                            onApprove: () =>
                              removeMilestone(milestone.milestone_id),
                            refuseButtonText: 'Cancel',
                            onRefuse: () => setDialogProps(new DialogProps()),
                          }),
                        )
                      }
                    }}
                  >
                    <EnterpriseIcon color="gray" icon={TrashIcon} />
                  </Button>
                </Tooltip>
              )}
          </Grid.Item>
        </Grid.Container>

        <div className="hc-pa-dense">
          <Grid.Container spacing="dense">
            {/* Complete Button */}
            <Grid.Item xs={1}>
              {!milestone.information_only &&
                currentBlueprint.status !== 'Archive' &&
                (userPermissions.isBlueprintEditor ||
                  userPermissions.isProjectAdmin ||
                  (roleInfo &&
                    roleInfo.users &&
                    roleInfo.users.some((user: RosterUser) =>
                      user.type === 'INTERNAL'
                        ? (user as InternalUser).email === userEmail
                        : (user as BusinessPartner).name === userCompany,
                    ))) && (
                  <Tooltip
                    location="right"
                    content={
                      milestone.status === 'Completed'
                        ? 'Completed'
                        : 'Mark As Complete'
                    }
                  >
                    <Input.Checkbox
                      id={`${milestone.milestone_id} completed`}
                      checked={milestone.status === 'Completed'}
                      disabled={
                        (milestone.auto_check || disabled) &&
                        !userPermissions.isProjectAdmin
                      }
                      onChange={(e: any) => {
                        if (e.target.checked) {
                          updateMilestone({
                            ...milestone,
                            status: 'Completed',
                          })
                          addToModifiedMilestoneIds(milestone.milestone_id)
                          setPageHasChanges(true)
                        } else {
                          updateMilestone({
                            ...milestone,
                            status: undefined,
                          })
                          addToModifiedMilestoneIds(milestone.milestone_id)
                          setPageHasChanges(true)
                        }
                      }}
                    />
                  </Tooltip>
                )}
            </Grid.Item>
            <Grid.Item xs>
              <Grid.Container spacing="none">
                {/*Name*/}
                {isExpanded &&
                milestone.custom_milestone &&
                isEditCustomMilestone ? (
                  <Grid.Item className="hc-pv-dense" xs={8}>
                    <Form.Field
                      id="name"
                      value={milestone.name}
                      onChange={(e: any) => {
                        updateMilestone({ ...milestone, name: e.target.value })
                        addToModifiedMilestoneIds(milestone.milestone_id)
                      }}
                    />
                  </Grid.Item>
                ) : isExpanded && !milestone.custom_milestone ? (
                  <Grid.Item>
                    <p className="title">{milestone.name}</p>
                  </Grid.Item>
                ) : (
                  <Grid.Container spacing="none">
                    <Grid.Item xs={8}>
                      <p className="title">{milestone.name}</p>
                    </Grid.Item>
                    {milestone.information_only && (
                      <Grid.Item xs={12}>
                        <p className="hc-clr-grey03 italicized">
                          Information Only
                        </p>
                      </Grid.Item>
                    )}
                  </Grid.Container>
                )}
                {isExpanded &&
                milestone.custom_milestone &&
                isEditCustomMilestone ? (
                  <Grid.Item className="hc-pv-dense" xs={8}>
                    <Form.Field
                      type="select"
                      id="dependsOn"
                      value={milestone.depends_on}
                      options={milestoneTemplateFacets.filter(
                        (option: any) => option.value !== milestone.name,
                      )}
                      onUpdate={(e: any, dependsOn: string) => {
                        updateMilestone({ ...milestone, depends_on: dependsOn })
                        addToModifiedMilestoneIds(milestone.milestone_id)
                      }}
                    />
                  </Grid.Item>
                ) : isExpanded && !milestone.custom_milestone ? (
                  <Grid.Item xs={12}>
                    <p className="hc-clr-grey02">{milestone.depends_on}</p>
                  </Grid.Item>
                ) : (
                  <Grid.Item xs={12}>
                    <p className="hc-clr-grey02">{milestone.depends_on}</p>
                  </Grid.Item>
                )}
                {/*Role*/}
                {isExpanded &&
                milestone.custom_milestone &&
                isEditCustomMilestone ? (
                  <Grid.Item className="hc-pv-dense" xs={8}>
                    <Form.Field
                      type="select"
                      id="role"
                      value={milestone.role}
                      options={milestoneFacets.roles}
                      onUpdate={(e: any, role: string) => {
                        updateMilestone({ ...milestone, role: role })
                        addToModifiedMilestoneIds(milestone.milestone_id)
                      }}
                      required
                    />
                  </Grid.Item>
                ) : isExpanded && !milestone.custom_milestone ? (
                  <Grid.Item xs={12}>
                    <p className="hc-clr-grey02">
                      {roleInfo && roleInfo.users.length > 0
                        ? milestone.role
                        : null}
                    </p>
                  </Grid.Item>
                ) : null}
                <Grid.Item className="hc-clr-grey02" xs={12}>
                  <p className="hc-clr-grey02">
                    {roleInfo && roleInfo.users.length > 0
                      ? roleInfo.users.map((user: RosterUser) => {
                          return roleInfo.type === 'INTERNAL'
                            ? (user as InternalUser).email.length > 0 &&
                                (user as InternalUser).email.substr(
                                  0,
                                  (user as InternalUser).email.indexOf('@'),
                                ) + ' '
                            : (user as BusinessPartner).name.length > 0 &&
                                (user as BusinessPartner).name + ' '
                        })
                      : milestone.role}
                  </p>
                </Grid.Item>

                {/*Weeks Out*/}
                {weeksOut > 0 && (
                  <Grid.Item className="hc-pr-none hc-pl-none" xs={12}>
                    <p className="hc-clr-grey02 hc-fs-min">{`${weeksOut} weeks out`}</p>
                  </Grid.Item>
                )}

                {/*Status*/}
                {isExpanded ? (
                  <Grid.Item className="hc-pr-none hc-pl-none" xs={12}>
                    <p className="hc-clr-grey02 hc-fs-min">
                      Project Status:{' '}
                      {milestone.status === 'Past Due'
                        ? milestone.status_description
                        : milestone.status}
                      {milestone.status === 'Completed'
                        ? `${moment(milestone.updated_date).format(
                            ' MM/DD/YYYY',
                          )}`
                        : ''}
                    </p>
                  </Grid.Item>
                ) : null}

                {/*Days Out Chip*/}
                <Grid.Item className="sem-BlueprintMilestoneRiskDate" xs={6}>
                  <span
                    className={
                      userPermissions.isBlueprintAdmin &&
                      currentBlueprint.status !== 'Archive'
                        ? 'click-pointer'
                        : ''
                    }
                    onClick={() =>
                      setEditDueDate(
                        userPermissions.isBlueprintAdmin &&
                          currentBlueprint.status !== 'Archive' &&
                          milestone.status !== 'Completed',
                      )
                    }
                  >
                    <RubixChip
                      color={getMilestoneStatusChipColor(
                        milestone.information_only,
                        milestone.status,
                        milestone.status_description,
                      )}
                    >
                      <span className="riskDate-Span">Due:</span>
                      <DateFormatter
                        format="MM/DD/YY"
                        // @ts-ignore
                        date={
                          (milestone.days_out || milestone.days_out === 0) &&
                          dueDate
                        }
                      />
                    </RubixChip>
                  </span>
                </Grid.Item>

                {/*Risk Date Chip*/}
                <Grid.Item className="sem-BlueprintMilestoneRiskDate" xs={6}>
                  {milestone.risk && (
                    <RubixChip
                      color={
                        milestone.information_only
                          ? 'grey'
                          : milestone.status === 'Past Risk'
                          ? 'red'
                          : 'grey'
                      }
                    >
                      <span className="riskDate-Span">Risk:</span>
                      <DateFormatter
                        format="MM/DD/YY"
                        // @ts-ignore
                        date={
                          (milestone.risk_days_out ||
                            milestone.risk_days_out === 0) &&
                          riskDate
                        }
                      />
                    </RubixChip>
                  )}
                </Grid.Item>

                {/*Edit Due Date*/}
                {isExpanded && editDueDate && (
                  <Grid.Container
                    className="sem-BlueprintMilestoneEditDueDate"
                    align="center"
                    spacing="dense"
                  >
                    <Grid.Item
                      className="hc-pr-none hc-pt-normal hc-pb-normal"
                      xs={8}
                    >
                      <Form.Field
                        type="date"
                        className="sem_InlineDatePicker"
                        id="due_date"
                        label="Due Date:"
                        value={dueDate}
                        onChange={(event: any) => {
                          if (event.target.value !== '') {
                            setDueDate(event.target.value)
                          }
                        }}
                        location="bottom-left"
                      />
                    </Grid.Item>
                    <Grid.Item className="hc-pr-none hc-pl-dense hc-pt-normal hc-pb-normal">
                      <Button
                        type="ghost"
                        iconOnly
                        className="sem-MilestoneBtnRemove"
                        onClick={() => {
                          const newDaysOut = moment(
                            currentBlueprint.set_date,
                          ).diff(dueDate, 'days')

                          if (milestone.risk) {
                            updateMilestone({
                              ...milestone,
                              days_out: newDaysOut,
                              updated_by: userName,
                              updated_date: new Date().toISOString(),
                            })
                            addToModifiedMilestoneIds(milestone.milestone_id)
                            setPageHasChanges(true)
                          } else {
                            updateMilestone({
                              ...milestone,
                              days_out: newDaysOut,
                              updated_by: userName,
                              updated_date: new Date().toISOString(),
                            })
                            addToModifiedMilestoneIds(milestone.milestone_id)
                            setPageHasChanges(true)
                          }
                          setEditDueDate(false)
                        }}
                      >
                        <EnterpriseIcon color="blue" icon={CheckIcon} />
                      </Button>
                    </Grid.Item>
                  </Grid.Container>
                )}
              </Grid.Container>
            </Grid.Item>
          </Grid.Container>
        </div>
      </div>
    </Card>
  )
}

export default BlueprintMilestoneCard
