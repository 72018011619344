import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { Grid } from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { Column } from 'ag-grid-community'
import { useAgGridSignLibraryContext } from '../../../context/agGridSignLibraryContext'
import { SignLibraryElementResponse } from '../../../../../models/signLibrary'

export interface Props {
  value: string
  column: Column
  data: SignLibraryElementResponse
}

const AutocompletesubstrateCellEditor = forwardRef(
  ({ value, column, data }: Props, ref) => {
    const { editingFacets } = useAgGridSignLibraryContext()!
    const substrateOptions = editingFacets.sign_substrate
    const [editValue, setEditValue] = useState(value)

    const availableSubstrateOptions = () => {
      return column.getColId().split('.')[1] === 'substrate1'
        ? substrateOptions.filter(
            (option: any) =>
              option.value !==
              data.non_retail_item_info.printing_info?.substrate2,
          )
        : substrateOptions.filter(
            (option: any) =>
              option.value !==
              data.non_retail_item_info.printing_info?.substrate1,
          )
    }

    const handleChange = (id: string, vendor: string) => setEditValue(vendor)

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return editValue
        },
      }
    })

    return (
      <div className="hc-pa-dense">
        <Grid.Container className="sem_StandardForm error_messaging_enabled wide-form">
          <Grid.Item xs={12}>
            <Autocomplete
              label={column.getColDef().headerName}
              options={() => availableSubstrateOptions()}
              value={{
                value: editValue ? editValue : '',
                label: editValue ? editValue : '',
              }}
              onUpdate={(id: string, value: any) => {
                if (value) {
                  handleChange(id, value.value)
                } else {
                  handleChange(id, '')
                }
              }}
              allowCustomInputValue
            />
          </Grid.Item>
        </Grid.Container>
      </div>
    )
  },
)

export default AutocompletesubstrateCellEditor
