import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import {
  CellValueChangedEvent,
  ColumnApi,
  EditableCallbackParams,
  GridApi,
  GridReadyEvent,
  RowNode,
} from 'ag-grid-community'
import { connect } from 'react-redux'
import RowDropdownMenu from 'components/ProjectDetails/components/AddOnExpenses/components/frameworkComponents/RowDropdownMenu'
import NotesCell from 'components/ProjectDetails/components/AddOnExpenses/components/frameworkComponents/NotesCell'
import AutocompleteEditor from 'components/ProjectDetails/components/AddOnExpenses/components/editorComponents/AutocompleteEditor'
import SelectEditor from 'components/ProjectDetails/components/AddOnExpenses/components/editorComponents/SelectEditor'
import NumberEditor from 'components/ProjectDetails/components/AddOnExpenses/components/editorComponents/NumberEditor'
import TextEditor from 'components/ProjectDetails/components/AddOnExpenses/components/editorComponents/TextEditor'
import NotesEditor from 'components/ProjectDetails/components/AddOnExpenses/components/editorComponents/NotesEditor'
import AddOnExpenses from '../../../../../models/addOnExpenses/AddOnExpenses.model'
import { useToaster } from '@enterprise-ui/canvas-ui-react'
import {
  TOASTER_DEFAULTS,
  UserType,
} from 'components/App/constants/appConstants'
import { useAddOnContext } from 'components/ProjectDetails/components/AddOnExpenses/context/addOnContext'
import AddOnExpensesRequest from '../../../../../models/addOnExpenses/AddOnExpensesRequest.model'
import { clone, cloneDeep } from 'lodash'
import { useUserContext } from 'components/App/context/userContext'
import { isEditable } from 'components/ProjectDetails/components/AddOnExpenses/helpers/agGridHelpers'
import updateAddOnExpensesRequest from 'models/addOnExpenses/updateAddOnExpensesRequest.model'

type ContextType = {
  gridApi: GridApi | undefined
  gridOptions: any
  isAllExpenseSubmitted: boolean
  isAnyExpenseCreatedOrRejected: boolean
  onGridReady: (event: GridReadyEvent) => void
  resetAllFilters: () => void
  sizeColumnsToFit: () => void
  autoSizeAllColumns: () => void
  resetColumnState: () => void
  validateExpensesAndSave: (nodes: RowNode[]) => void
  onCellValueChange: (event: CellValueChangedEvent) => void
  addNewExpense: () => void
}

export const AgGridAddOnContext = createContext<ContextType | undefined>(
  undefined,
)

type Props = {
  children: React.ReactNode
}

export const AgGridAddOnProviderComponent = ({ children }: Props) => {
  const [gridApi, setGridApi] = useState<GridApi>()
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>()
  const makeToast = useToaster()
  const {
    updateExpenses,
    setExpensesModified,
    modifiedExpensesIds,
    setModifiedExpenses,
    setModifiedExpensesIds,
    setIsCreatingExpense,
    isCreatingExpense,
    createExpense,
  } = useAddOnContext()!
  const { userEmail, userType } = useUserContext()!

  const gridOptions = useMemo(
    () => ({
      defaultColDef: {
        filter: true,
        filterParams: {
          defaultOptions: 'filterMenuTab',
          buttons: ['clear', 'reset'],
          newRowsAction: 'keep',
        },
        menuTabs: ['filterMenuTab'],
        sortable: true,
        resizable: true,
        editable: true,
      },
      animateRows: true,
      columnTypes: {
        popUpCell: {
          cellClass: 'actions-button-cell',
        },
        selectedRow: {
          width: 70,
          maxWidth: 70,
          filter: false,
          sortable: false,
          headerCheckboxSelection: true,
          checkboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
        },
        menu: {
          cellRenderer: RowDropdownMenu,
          cellClass: 'actions-button-cell dropdown-menu',
        },
        notes: {
          cellRenderer: NotesCell,
          cellClass: 'actions-button-cell',
        },
        autocompleteEditor: {
          cellEditor: AutocompleteEditor,
          cellEditorPopup: true,
          cellClass: 'actions-button-cell',
          editable: (params: EditableCallbackParams) =>
            isEditable(
              params.data,
              params.context.isCreatingExpense,
              params.context.userPermissions,
              params.data.expenses_status,
            ),
        },
        selectEditor: {
          cellEditor: SelectEditor,
          cellEditorPopup: true,
          editable: (params: EditableCallbackParams) =>
            isEditable(
              params.data,
              params.context.isCreatingExpense,
              params.context.userPermissions,
              params.data.expenses_status,
            ),
        },
        numberEditor: {
          cellEditor: NumberEditor,
          cellEditorPopup: true,
          editable: (params: EditableCallbackParams) =>
            isEditable(
              params.data,
              params.context.isCreatingExpense,
              params.context.userPermissions,
              params.data.expenses_status,
            ),
        },
        textEditor: {
          cellEditor: TextEditor,
          cellEditorPopup: true,
          editable: (params: EditableCallbackParams) =>
            isEditable(
              params.data,
              params.context.isCreatingExpense,
              params.context.userPermissions,
              params.data.expenses_status,
            ),
        },
        notesEditor: {
          cellEditor: NotesEditor,
          cellEditorPopup: true,
          editable: (params: EditableCallbackParams) =>
            isEditable(
              params.data,
              params.context.isCreatingExpense,
              params.context.userPermissions,
              params.data.expenses_status,
            ),
        },
      },
    }),
    [],
  )

  const onGridReady = useCallback((event: GridReadyEvent) => {
    setGridApi(event.api)
    setGridColumnApi(event.columnApi)
  }, [])

  const resetAllFilters = () => {
    gridApi && gridApi.setFilterModel({})
    gridColumnApi &&
      gridColumnApi.applyColumnState({ defaultState: { sort: null } })
  }

  const sizeColumnsToFit = () => {
    gridApi && gridApi.sizeColumnsToFit()
  }

  const autoSizeAllColumns = () => {
    gridColumnApi && gridColumnApi.autoSizeAllColumns()
  }

  const resetColumnState = () => {
    gridColumnApi && gridColumnApi.resetColumnState()
  }

  const validateExpensesAndSave = (nodes: RowNode[]) => {
    let validatedRows: RowNode[] = []
    nodes.map((node: RowNode) => {
      const data: AddOnExpenses = node.data
      for (const [field, value] of Object.entries(data)) {
        switch (field) {
          case 'print_vendor':
            if (
              (value === '' || value === undefined) &&
              userType !== UserType.EXTERNAL
            ) {
              data.hasError = true
            }
            break
          default:
            break
        }
      }
      validatedRows.push(node)
      return validatedRows
    })
    const rowsWithErrors = validatedRows.filter(
      (row: RowNode) => row.data.hasError,
    )
    if (rowsWithErrors.length > 0) {
      gridApi?.applyTransaction({ update: rowsWithErrors })
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Missing Required Fields',
        message: 'Please fill out all required fields',
      })
    } else {
      if (isCreatingExpense) {
        createExpense()
      } else {
        updateExpenses()
      }
    }
  }

  const onCellValueChange = async (event: CellValueChangedEvent) => {
    if (event.newValue !== event.oldValue) {
      setExpensesModified(true)
      event.data.modified = true
      event.data.hasError = false
      const id = event.data.id ? event.data.id : event.node.id
      const expenses_status = event.data.expenses_status

      if (modifiedExpensesIds.includes(id)) {
        setModifiedExpenses((previousState: updateAddOnExpensesRequest) => {
          const clonedExpenses = cloneDeep(previousState)
          clonedExpenses.update_add_on_expenses_request_dto.map(
            (expense: AddOnExpensesRequest, index: number) =>
              expense.add_on_expenses_id === id
                ? (clonedExpenses.update_add_on_expenses_request_dto[index] =
                    new AddOnExpensesRequest({
                      add_on_expenses_id: id,
                      print_vendor: event.data.print_vendor,
                      expenses_type: event.data.expenses_type,
                      cost: event.data.cost,
                      po_number: event.data.po_number,
                      vendor_notes: event.data.vendor_notes,
                      updated_by: userEmail,
                    }))
                : (clonedExpenses.update_add_on_expenses_request_dto[index] =
                    new AddOnExpensesRequest(expense)),
          )
          return {
            update_add_on_expenses_request_dto:
              clonedExpenses.update_add_on_expenses_request_dto,
            expenses_status: expenses_status,
          }
        })
      } else {
        setModifiedExpenses((previousState: updateAddOnExpensesRequest) => {
          const clonedExpenses = cloneDeep(previousState)
          clonedExpenses.update_add_on_expenses_request_dto.push(
            new AddOnExpensesRequest({
              add_on_expenses_id: id,
              print_vendor: event.data.print_vendor,
              expenses_type: event.data.expenses_type,
              cost: event.data.cost,
              po_number: event.data.po_number,
              vendor_notes: event.data.vendor_notes,
              updated_by: userEmail,
            }),
          )
          return {
            update_add_on_expenses_request_dto:
              clonedExpenses.update_add_on_expenses_request_dto,
            expenses_status: expenses_status,
          }
        })
        setModifiedExpensesIds((previousState: string[]) => {
          const clonedIds = clone(previousState)
          if (!clonedIds.includes(id)) {
            clonedIds.push(id)
          }
          return clonedIds
        })
      }

      event.column.getColDef().cellClass = 'ag-cell-modified'
      event.api.refreshCells({
        force: true,
        columns: [event.column.getColId()],
        rowNodes: [event.node],
      })
    }
  }

  const addNewExpense = async () => {
    setExpensesModified(true)
    setIsCreatingExpense(true)

    gridApi?.applyTransaction({
      addIndex: 0,
      add: [
        new AddOnExpenses({
          modified: true,
        }),
      ],
    })
  }

  const isAllExpenseSubmitted = (() => {
    const allRowData: any[] = []
    gridApi?.forEachNode((node: any) => allRowData.push(node.data))
    return allRowData.every((row) => row?.expenses_status === 'Submitted')
  })()

  const isAnyExpenseCreatedOrRejected = (() => {
    const allRowData: any[] = []
    gridApi?.forEachNode((node: any) => allRowData.push(node.data))
    return allRowData.some(
      (row) =>
        row?.expenses_status === 'Created' ||
        row?.expenses_status === 'Rejected',
    )
  })()

  return (
    <AgGridAddOnContext.Provider
      value={{
        gridApi,
        gridOptions,
        isAllExpenseSubmitted,
        onGridReady,
        resetAllFilters,
        sizeColumnsToFit,
        autoSizeAllColumns,
        resetColumnState,
        validateExpensesAndSave,
        onCellValueChange,
        addNewExpense,
        isAnyExpenseCreatedOrRejected,
      }}
    >
      {children}
    </AgGridAddOnContext.Provider>
  )
}

export const useAgGridAddOnContext = () => useContext(AgGridAddOnContext)

export const AgGridAddOnProvider = connect(
  null,
  null,
)(AgGridAddOnProviderComponent)
